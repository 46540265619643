<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID" :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <label v-if="inputLabel" class="text-left text-xs ml-2 block group-focus:text-gray-600">{{inputLabel}}</label>
            <input :id="elementID" type="text" :class="classToAdd" class="form-control" :placeholder="placeholder">
        </div>
    </div>
</template>
<script>
    export default { 
    props:{
        placeholder: {
            type:       String,
            default:    "",
            required:   true,
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        inputLabel:{
            type:       String,
            default:    null,
            required:   true,
        },
        classToAdd:{
            type:       String,
            default:    'form-control',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        },
    },
}
</script>
