<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID"  :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500" v-if="isTitle">
            <h1 :class="classToAdd" :id="elementID">{{htmlContent}}</h1>
        </div>
        <div :id="'divContainer' + elementID" :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500" v-else>
            <label :class="classToAdd" :id="elementID">{{htmlContent}}</label>
        </div>
    </div>
</template>
<script>
export default { 
    props:{
        htmlContent: {
            type:       String,
            default:    "",
            required:   true,
        },
        classToAdd: {
            type:       String,
            default:    "",
            required:   true,
        },
        isTitle: {
            type:       Boolean,
            default:    false,
            required:   true,
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        }
    }
}
</script>
