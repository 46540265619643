<template>

    <div v-if="isLoading" class="LoadingScreen">
        <div class="text-center">
            <p class="font-italic font-weight-bold">Loading...</p>
            <p class="font-italic font-weight-bold">
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
            </p>              
        </div>
    </div>
        
    <div v-else>

        <div class="d-block text-center">
            <h3 class="font-sans text-4xl tracking-wide font-bold text-gray-700 mt-2">Create Activity</h3>
            <!-- -------------------------------------------------- -->
            <!-- Instructions to add new activities for this ticket -->
            <!-- -------------------------------------------------- -->
        </div>

        <div>
                <label class="text-gray-600 font-semibold text-lg mt-3 my-1 align-items-center justify-content-start leading-none">Current Information</label>
                <div class="align-items-center justify-content-start bg-gray-100">
                    <label class="p-2 m-0 text-gray-600 font-semibold text-md leading-relaxed">
                        <p class="m-0 pl-1 font-bold text-md">Current Queue:        <span class="m-0 pl-1 font-bold text-md text-capitalize">{{this.getTicketInfo.QueueID === null ? 'Not Specified' : this.getTicketInfo.queueName}}</span></p>
                        <p class="m-0 pl-1 font-bold text-md">Current Category:     <span class="m-0 pl-1 font-bold text-md text-capitalize">{{this.getTicketInfo.CategoryID === null ? 'Not Specified' : this.getTicketInfo.categoryName}}</span></p>
                        <p class="m-0 pl-1 font-bold text-md">Current subCategory:  <span class="m-0 pl-1 font-bold text-md text-capitalize">{{this.getTicketInfo.SubCategoryID === null ? 'Not Specified' : this.getTicketInfo.subCategoryName}}</span></p>
                    </label>
                </div>

                <!-- SubTickets information -->
                <label class="text-gray-600 font-semibold text-lg mt-3 my-1 align-items-center justify-content-start leading-none">Add to Subcategories</label>
                <div v-for="(input, index) in ticket_subtickets" :key="`queueInput-${index}`" class="align-items-center justify-content-start leading-none bg-gray-100 px-2">
                    <div class="flex flex-row align-items-center justify-content-start">
                        <label class="m-0 text-gray-600 font-semibold text-md leading-none tracking-tighter">
                            <label class="m-0 text-lg">{{index+1}}.</label>
                            Queue:          <label :class="{ 'bg-green-100 p-1 m-0 mt-1': ticket_subtickets[index].queue != null, 'bg-red-100 text-sm font-light p-1 m-0 mt-1': ticket_subtickets[index].queue == null }">{{ticket_subtickets[index].queue == null ? 'Empty' : ticket_subtickets[index].queue.queueName}}</label>
                            / Category:     <label :class="{ 'bg-green-100 p-1 m-0 mt-1': ticket_subtickets[index].category != null, 'bg-red-100 text-sm font-light p-1 m-0 mt-1': ticket_subtickets[index].category == null }">{{ticket_subtickets[index].category == null ? 'Empty' : ticket_subtickets[index].categoryName}}</label>
                            / Sub-Category: <label :class="{ 'bg-green-100 p-1 m-0 mt-1': ticket_subtickets[index].subCategory != null, 'bg-red-100 text-sm font-light p-1 m-0 mt-1': ticket_subtickets[index].subCategory == null }">{{ticket_subtickets[index].subCategory == null ? 'Empty' : ticket_subtickets[index].subCategoryName}}</label>
                        </label>
                        <div class="flex justify-end text-right m-0">
                            <svg v-show="ticket_subtickets[index].queue != null && ticket_subtickets[index].category != null && ticket_subtickets[index].subCategory != null && ticket_subtickets.length == index+1" @click="addqueueField()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22" class="ml-2 cursor-pointer">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path fill="#5E57E5" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                            </svg>

                            <svg v-show="ticket_subtickets.length > 1" @click="removequeueField(index, ticket_subtickets)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22" class="ml-2 cursor-pointer">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path fill="#C54673" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/>
                            </svg>
                        </div>
                    </div>
                    
                    <!-- Dropdowns -->
                    <div v-show="ticket_subtickets[index].queue === null || ticket_subtickets[index].category === null || ticket_subtickets[index].subCategory === null">
                        <div class="ml-4 text-sm mt-1">
                            <!-- Queues-->
                            <label class="text-gray-600 font-semibold text-sm mr-2">Queue:</label>
                            <select required :id="'queue'+index" v-model="ticket_subtickets[index].queue" @change="loadCategories(index)" class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700">
                                <optgroup label="Queue type">                
                                    <option hidden value="null">Select a queue...</option>
                                    <option v-for="queue in getQueuesList" :value="queue" :key="queue.id">{{queue.queueName}}</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="ml-4 text-sm">
                            <!-- Category-->
                            <label class="text-gray-600 font-semibold text-sm mr-2">Category:</label>
                            <select v-if="hideCategories" disabled required :id="'category'+index" @change="loadSubCategories(index, $event)" :v-model="'ticket_subtickets['+index+'].category'" class="ml-2 bg-transparent text-gray-400 active:text-gray-700">
                                <optgroup label="Category type">                
                                    <option hidden value="">Select a queue first...</option>
                                </optgroup>
                            </select>
                            <select v-else required :id="'category'+index" @change="loadSubCategories(index, $event)" :v-model="ticket_subtickets[index].category" class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700">
                                <optgroup label="Category type">                
                                    <option hidden value="null">Select a category...</option>
                                    <option v-for="category in getCategoriesList" :value="category.id" :key="category.id">{{category.categoryName.toUpperCase()}}</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="ml-4 text-sm">
                            <!-- subCategory-->
                            <label class="text-gray-600 font-semibold text-sm mr-2">subCategory:</label>
                            <select v-if="hideSubCategories" disabled required :id="'subCategory'+index" @change="setsubCategory(index, $event)" :v-model="'ticket_subtickets['+index+'].subCategory'" class="ml-2 bg-transparent text-gray-400 active:text-gray-700">
                                <optgroup label="Sub-Category type">
                                    <option hidden value="">Select a category first..</option>
                                </optgroup>
                            </select>
                            <select v-else required :id="'subCategory'+index" @change="setsubCategory(index, $event)" :v-model="'ticket_subtickets['+index+'].subCategory'" class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700">
                                <optgroup label="Sub-Category type">                
                                    <option hidden value="null">Select a sub-category...</option>
                                    <option v-for="subCategory in getSubCategoriesList" :value="subCategory.id" :key="subCategory.id">{{subCategory.subCategoryName.toUpperCase()}}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="px-4 py-2 text-right">
                    <button v-if="this.sending" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-600" style="cursor:default">
                    Sending...
                    </button>

                    <button v-else @click="editTiket" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                    Save
                    </button>
                </div>
        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
    export default {
        name: "SubTickets",
        data(){
            return{               
                hideCategories:     true,
                hideSubCategories:  true,
                showSubQuestions:   false,
                sending:            false,
                isLoading:          true,

                ticketQueue:    null,
                ticketCategory: null,
                ticketSubC:     null,

                ticket_subtickets: [{ 
                    queue:          null, // queueName:          null,
                    category :      null, // categoryName:       null,
                    subCategory:    null, // subCategoryName:    null
                }],
            }
        },
        computed: {
            ...mapState(['tickets']),
            ...mapGetters([
                'getPriorities',
                'getTicketInfo',
                'getAssings',
                "getQueuesList",
                "getCategoriesList",
                "getSubCategoriesList",
            ]),
        },
        props:{
          thicketId: Number,
          queueId : Number,
        },
        methods:{
            ...mapActions([
                'fetchPrioritiesList',
                'fetchThisTicketInfo',
                'fetchAssignList',
                'createChildTickets',
                "fetchTicketQueues", 
                "fetchTicketCategories", 
                "fetchTicketSubCategories",
            ]),

            addqueueField() {
                this.hideCategories     = true;
                this.hideSubCategories  = true;
                this.ticket_subtickets.push({ queue: null, category: null, subCategory: null });
            },

            removequeueField(index, ticket_subtickets) {
                ticket_subtickets.splice(index, 1);
            },

            loadCategories(index){ //}, event){
                this.hideCategories     = true;
                this.hideSubCategories  = true;

                // let target= event.target;
                // let questionId = target.options[target.selectedIndex].text;
                // this.ticket_subtickets[index].queueName = questionId;
                // this.ticket_subtickets[index].queue     = target.value;

                let objData = {
                    queueSelected: this.ticket_subtickets[index].queue // target.value
                }

                this.fetchTicketCategories(objData).then(()=>{
                    this.hideCategories = false;
                });
            },

            loadSubCategories(index, event){
                this.hideSubCategories  = true;
                let target= event.target;
                let questionId = target.options[target.selectedIndex].text;
                this.ticket_subtickets[index].categoryName = questionId;
                this.ticket_subtickets[index].category     = target.value;

                let objData = {
                    categorySelected: target.value
                }
                this.fetchTicketSubCategories(objData).then(()=>{
                    this.hideSubCategories  = false;
                });
            },

            setsubCategory(index, event){
                let target= event.target;
                let questionId = target.options[target.selectedIndex].text;
                this.ticket_subtickets[index].subCategoryName = questionId;
                this.ticket_subtickets[index].subCategory     = target.value;
            },

            editTiket(){
                let key = 0;
                let counter = [];
                [].forEach.call(this.ticket_subtickets, element => {
                    if(element.queue === null || element.queue === undefined || element.queue === ""){
                            document.getElementById('queue'+key).classList.add('border-2', 'border-red-500')
                            counter.push({queue : element.queue})
                    }else{
                        document.getElementById('queue'+key).classList.remove('border-2', 'border-red-500')
                    }

                    if(element.category === null || element.category === undefined || element.category === ""){
                            document.getElementById('category'+key).classList.add('border-2', 'border-red-500')
                            counter.push({category : element.category})
                    }else{
                        document.getElementById('category'+key).classList.remove('border-2', 'border-red-500')
                    }

                    if(element.subCategory === null || element.subCategory === undefined || element.subCategory === ""){
                            document.getElementById('subCategory'+key).classList.add('border-2', 'border-red-500')
                            counter.push({subCategory : element.subCategory})
                    }else{
                        document.getElementById('subCategory'+key).classList.remove('border-2', 'border-red-500')
                    }

                    key++;
                });

                if(counter.length > 0){
                    this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true
                    })
                }else{
                    this.sending = true;
                    let objData = {
                        ticketSelected:     this.thicketId,
                        ticketSubTickets:   this.ticket_subtickets
                    }
                    console.log(objData);
                    this.createChildTickets(objData).then(()=>{
                        this.$fire({
                            type: 'success',
                            title: "Information Sent!",
                            text: "Tickets Created",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(()=>{
                            this.$emit('closeModal', this.thicketId);
                        })
                    }).catch(()=>{
                        this.$fire({
                            type: 'warning',
                            title: "Something went wrong",
                            text: "Please try again or report to a administrator",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    })
                }
            }          
        },
        created() {
            let objData = {
                ticketSelected:     this.thicketId,
                queueId:            this.queueId
            }
            this.fetchThisTicketInfo(objData).then(()=>{
                this.ticketPriority = this.getTicketInfo.TicketPriorityID;
                this.ticketDate     = this.getTicketInfo.IssueDate;
                this.assignedTo     = this.getTicketInfo.AssignedTo;
                this.status         = this.getTicketInfo.isClose;
                this.ticketQueue    = this.getTicketInfo.QueueID;
                this.ticketCategory = this.getTicketInfo.CategoryID;
                this.ticketSubC     = this.getTicketInfo.subCategoryID;
                this.isLoading = false;
            })
            this.fetchTicketQueues();
            // this.fetchAssignList(objData);
            // this.fetchPrioritiesList(objData);
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
    ::placeholder {
        text-transform: capitalize;
    }
</style>