import axios from '@/config/axios';

// Create a new store instance.
const state = {
    trainees:[],
    error: false,
    list:[],
};

const getters = {
    getTraineesList: (state) => state.list
};

const actions = {
    async fetchTraineesAttritionRisk({commit},training) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/${training}/attrition-trainees`)
            commit('SET_USERS', response.data);
        }
        catch (error) {
            commit('SET_ERROR', true);
            console.log(error)
        }
    }
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users
    },
    SET_ERROR(state, status){
        state.error, status
    },
};

export default {
    state,
    getters,
    actions, 
    mutations
};