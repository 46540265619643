<template>
  <div align-self="center" class="mt-4">
    <div v-if="!isLoading" class="p-4">

        <!-- Búsqueda de empleados -->
        <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1 align-middle">
            <label for="searchEmp" class="text-gray-700 flex-nowrap align-middle">Search an Employee:</label>
            <multiselect id="searchEmp" class="flex justify-content-center text-left shadow-sm border-transparent bg-white rounded-md align-middle" v-model="employeeId" :custom-label="customFormat" :options="this.employeeList" 
            :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="false" placeholder="Select an Employee" label="RecID" track-by="RecID" @input="enableDates"></multiselect>

            <label for="searchDate" class="text-gray-700 flex-nowrap align-middle">Select a Date:</label>
            <input type="date" id="searchDate" v-model="dateSelected" @change="enableButton" class="bg-white p-2 mx-1 shadow-md rounded-md align-middle" :class="{ 'hover:text-gray-600' : !dateDisabled }" :disabled="dateDisabled">

            <button class="p-3 rounded-xl shadow-md text-white uppercase text-xs align-middle" :class="{ 'bg-gray-700 hover:bg-gray-800' : !searchDisabled , 'bg-gray-400 cursor-default' : searchDisabled }" :disabled="searchDisabled" @click="searchPayroll">search</button>
        </div>

        <div v-if="showTable">
            <!-- Tabla de búsqueda -->
            <div class="mt-4">
                <payroll-events @loadingComplete="loaded" class="overflow-y-hidden shadow-md" :key="renderTable" :employeeId="employeeId" :dateSelected="dateSelected"></payroll-events>
            </div>

            <!-- Botón agregar Request -->
            <div v-if="showEverything" class="mt-4">
                <button :disabled="isDisabled" :class="{ 'bg-gray-400 cursor-default' : isDisabled, 'bg-gray-700 hover:bg-gray-800' : !isDisabled }" class="p-3 rounded-xl shadow-md text-white uppercase text-xs align-middle" v-b-modal="'spcialPayrollModal'">New Request</button>
                <b-modal ref="spcialPayrollModal" :id="'spcialPayrollModal'" hide-footer hide-header>
                    <request-modal @closeModal="closeModal" :employeeId="employeeId" :dateSelected="dateSelected"></request-modal>
                </b-modal>
            </div>
            <div v-else class="flex flex-col align-items-center justify-content-start flex-1 align-middle w-full mt-4">
                <span><svg class="w-14 h-14 animate-spin mt-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
            </div>

            <!-- Tabla Special Requests -->
            <div class="mt-4">
                <special-request @loadingComplete="loaded" @DisableButton="disableButton" class="overflow-y-hidden shadow-md" :key="renderTable" :employeeId="employeeId" :dateSelected="dateSelected"></special-request>
            </div>
        </div>

    </div>
    <div v-else-if="isLoading" class="flex flex-col align-items-center justify-content-start flex-1 align-middle w-full">
        <span><svg class="w-14 h-14 animate-spin mt-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapState, mapActions }   from 'vuex'
import PayrollEvents        from '@/views/components/Payroll/special/elements/payrollEventsTable';
import RequestModal         from '@/views/components/Payroll/special/elements/specialPayrollModal'
import SpecialRequest       from '@/views/components/Payroll/special/elements/specialPayrollTable'
export default {
    name: '',
    components: {
        SpecialRequest,
        PayrollEvents,
        RequestModal
    },
    data(){
        return {
            employeeId:     null,
            dateSelected:   null,

            isDisabled:     false,
            searchDisabled: true,
            dateDisabled:   true,
            loadingTable:   false,
            showTable:      false,
            isLoading:      true,
            showEverything: false,

            loadedCount: 0,
            renderTable: 0,
        }
    },
    computed:{
        ...mapState([
            "employees",
        ]),
        ...mapGetters([
            "employeeList",
            
        ])
    },
    methods: {
        ...mapActions([
            "fetchAllEmployees",
        ]),

        loaded(){
            this.loadedCount++;
            if(this.loadedCount == 2){
                this.showEverything = true;
                this.loadedCount = 0;
            }
        },
        
        enableButton(){
            this.searchDisabled = false;
        },

        disableButton(){
            this.isDisabled = true;
        },

        enableDates(){
            this.dateDisabled = false;
        },

        customFormat({first_name, MiddleName, LastName1, LastName2}){
            let fullname = `${first_name.toUpperCase()} ${MiddleName.toUpperCase()} ${LastName1.toUpperCase()} ${LastName2.toUpperCase()}`
            return fullname.replace(/\s+/g, ' ');
        },

        searchPayroll(){
            this.showEverything = false;
            this.isDisabled = false;
            this.showTable = true;
            this.renderTable++;
        },

        closeModal(){
            this.$refs['spcialPayrollModal'].hide();
            this.showEverything = false;
            this.renderTable++;
        }
    },
    created() {
        this.isLoading = true;
        this.fetchAllEmployees().then(()=>{
            this.isLoading = false;
        })
    },
    mounted(){
        let title = 'Special Payroll';
        this.$emit('set-title',title);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
