<template>
    <div class="flex flex-row max-w-6xl bg-white shadow-lg rounded-lg mx-auto align-items-center justify-content-start">
        <table class="table w-full divide-y divide-gray-200 table-auto text-ellipsis" v-if="!getInventoryLoading">
            <thead class="bg-gray-50">              
                <tr>   
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Serial Number
                </th>  
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Label
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Manufacturer
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Model
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Type
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Site
                </th> 
                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                    Status
                </th> 
                <th style="max-width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase relative  text-ellipsis whitespace-nowrap">
                    Last Update
                </th>
                <th style="max-width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase relative  text-ellipsis whitespace-nowrap">
                    Tools
                </th>
                </tr>       
            </thead>
            <tbody class="bg-white divide-y divide-gray-200" v-if="tableReady">  
                <tr v-for="(item,key) in getFilteredInventoryItems" :key="key">
                    <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.serial_number}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.label}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.manufacturer.label}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.model.label}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.type.label}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.site}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                        <div class="text-sm text-gray-900 text-left">
                        {{item.status}}
                        </div>                    
                    </td>

                    <td class="px-6 py-3 w-1/8 text-ellipsis">
                        <div class="text-sm text-gray-900 capitalize text-left">
                        {{item.created_at}}
                        </div>                    
                    </td>
                    
                    <td class="px-6 py-3 w-1/8 text-ellipsis flex flex-row">
                        <vs-tooltip v-if="item.status === 'AVAILABLE' && item.benefactor === 'not_assigned' && item.executor === 'not_assigned'" 
                        title="Assign" class="flex flex-row items-center justify-center">
                            <a href="#" class="mx-1 text-gray-300 hover:text-green-400 p-2 rounded-full" @click.prevent="goToAssign(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
                                </svg>
                            </a>
                        </vs-tooltip>

                        <vs-tooltip v-if="item.status === 'IN-USE' && item.benefactor !== 'not_assigned' && item.executor === 'not_assigned' && item.use_type === 'off-site'" 
                        title="Upload Responsive Letter" class="flex flex-row items-center justify-center">
                            <a href="#" class="mx-1 text-gray-300 hover:text-green-400 p-2 rounded-full" @click.prevent="goToUpload(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                </svg>
                            </a>
                        </vs-tooltip>

                        <vs-tooltip v-if="item.status === 'IN-USE' && item.benefactor !== 'not_assigned' && (item.executor !== 'not_assigned' || item.use_type === 'on-site')" 
                        title="Return Item" class="flex flex-row items-center justify-center">
                            <a href="#" class="mx-1 text-gray-300 hover:text-green-400 p-2 rounded-full" @click.prevent="goToReturn(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                                </svg>
                            </a>
                        </vs-tooltip>

                        <vs-tooltip v-if="item.status === 'IN-USE' && item.benefactor === 'not_assigned' && item.executor !== 'not_assigned'" 
                        title="Confirm Reception" class="flex flex-row items-center justify-center">
                            <a href="#" class="mx-1 text-gray-300 hover:text-green-400 p-2 rounded-full" @click.prevent="goToEvaluate(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                </svg>
                            </a>
                        </vs-tooltip>

                        <vs-tooltip 
                        title="Edit Information" class="flex flex-row items-center justify-center">
                            <a href="#" class="mx-1 text-gray-300 hover:text-green-400 p-2 rounded-full" @click.prevent="goToEdit(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </a>
                        </vs-tooltip>
                    </td>
                
                </tr>                        
            </tbody> 
            <tbody v-else>
                <tr>
                    <td class="italic font-light">
                        No items found
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="my-2 flex mx-auto w-full justify-content-center text-center" v-else>
            <div class="flex mx-auto">
                We are loading this information. Please wait.
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
// import defineComponent from '@vue/composition-api'
import { mapGetters } from "vuex";
export default {
    data() {
        return{
            loading: false
        }
    },
    computed: {
        ...mapGetters([
            'getFilteredInventoryItems',
            'getInventoryLoading'
        ]),
        tableReady: function(){
            return this.getFilteredInventoryItems?.length > 0;
        }
    },
    methods: {
        goToAssign(val){
            this.$router.push({name: 'inventory.assigitem', params: { id: val.id }, props: { val: true }});
        },
        goToUpload(val){
            this.$router.push({name: 'inventory.upload', params: { id: val.id }, props: { val: true }});
        },
        goToReturn(val){
            this.$router.push({name: 'inventory.return', params: { id: val.id }, props: { val: true }});
        },
        goToEvaluate(val){
            this.$router.push({name: 'inventory.evaluate', params: { id: val.id }, props: { val: true }});
        },
        goToEdit(val){
            this.$router.push({name: 'inventory.edit', params: { id: val.id }, props: { val: true }});
        },
    },
    created() {
    }
}
</script>