<template>
    <div v-if="showTable && this.getTickets.length > 0">

        <!-- My Tickets Review List -->
        <ul class="divide-y flex flex-col-reverse">
            <li class="flex justify-between items-center" v-for="ticket in getTickets" v-bind:key="ticket.ID" >
                <button :class="ticket.ID == selectedTicket ? 'border-centris-secondary bg-centris-secondary text-white' : 'border-gray-50 hover:bg-centris-secondary/10 hover:text-centris-secondary hover:border-centris-secondary'"
                @click="selectOne(`${ticket.ID}`)" 
                class="border-l-[3px] w-full px-3 py-2">
                    <span class="float-left">Ticket ID: {{ticket.ID}} ({{ticket.CreatedAt | moment("YYYY/MM/DD")}}) | {{ticket.PriorityLabel}}</span>
                    <span :class="{ hide: ticket.ReplyFrom != idEmployee}" class="float-right bg-red-400 rounded-full p-1 text-white mr-1"><svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg></span>
                </button>
            </li>
        </ul> 

    </div>

    <div v-else class="p-10 text-center">
        <div class="alert alert-danger" role="alert">
            <p class="text-center">No tickets received yet</p>
        </div>

    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import auth                               from "@/config/user"
    export default {
        data(){
            return{
                showTable: false,
                idEmployee: null,
                selectedTicket: null
            }
        },
        created(){
            this.showTable = false
            this.idEmployee = auth.currentUser.EmpID;
            this.showTable = false
            this.fetchReviewTickets().then(()=>{
                this.showTable = true;
            })
        },
        computed:{
            ...mapState(['tickets']),
            ...mapGetters(['getTickets']) 
        },
        methods: {
            ...mapActions(['fetchReviewTickets']),

            selectOne: function(data){
                this.selectedTicket = data;
                this.$emit('ticket_selected', data);
            },
        }
    }
</script>
<style scoped>
.btn .badge {
    top: 3px !important;
}
</style>