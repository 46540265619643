var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mx-auto text-center text-sm pl-4 font-semibold text-gray-500"},[_vm._v(" Please select the incidence or change type ")]),_c('div',{staticClass:"flex justify-center mb-10"},[_c('div',{staticClass:"flex space-x-5"},[_c('button',{staticClass:"bg-c-primary hover:opacity-80 text-white p-6 rounded-xl transition-all",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ 
        name: 'incidences.create.termination', 
        params: { recid: _vm.rec_id, id: _vm.employee } 
        })}}},[_vm._m(0)]),_c('button',{staticClass:"bg-c-blue hover:opacity-80 text-white p-6 rounded-xl transition-all",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ 
        name: 'msl.create', 
        params: { recid: _vm.rec_id, id: _vm.employee } 
        })}}},[_vm._m(1)]),_c('button',{staticClass:"bg-c-alt hover:opacity-80 text-white p-6 rounded-xl transition-all",attrs:{"disabled":_vm.msl_requests > 0},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name:'schedules.edit', params: { empID: _vm.employee } })}}},[_vm._m(2)])])]),_c('p',{staticClass:"mx-auto text-center text-sm pl-4 font-semibold text-gray-500"},[_vm._v(" Buttons won't be available if there is open requests ")]),_c('h2',{staticClass:"text-3xl font-semibold mb-6"},[_vm._v("History")]),_c('history-table',{key:_vm.history_key,attrs:{"employee":_vm.employee},on:{"request_counter":_vm.countRequest}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-xl uppercase font-semibold"},[_c('i',{staticClass:"block text-6xl bi bi-person-slash mb-3"}),_vm._v(" Termination ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-xl uppercase font-semibold"},[_c('i',{staticClass:"block text-6xl bi bi-heart-pulse mb-3"}),_vm._v(" Medical Leave ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-xl uppercase font-semibold"},[_c('i',{staticClass:"block text-6xl bi bi-calendar-week mb-3"}),_vm._v(" Schedule Change ")])
}]

export { render, staticRenderFns }