import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    statusRequest:              null,
    currentShiftObject:         null,
    currentActivityObject:      null,
    todayActivityObject:        null,
    clockInObject:              null,
    clockOutObject:             null,
    startActivityObject:        null,
    endActivityObject:          null,
    activitiesObject:           null,
    activityCommentRequired:    null,
    currentTime:                null,
};
const getters = {
    getCurrentShiftObject:  (state) => state.currentShiftObject,
    getCurrentActivity:     (state) => state.currentActivityObject,
    getTodayActivity:       (state) => state.todayActivityObject,
    getStartActivityObject: (state) => state.startActivityObject,
    getEndActivityObject:   (state) => state.endActivityObject,
    getActivitiesObject:    (state) => state.activitiesObject,
    getIsCommentRequired:   (state) => state.activityCommentRequired,
    getStatusRequest:       (state) => state.statusRequest,
    getCurrentTime:         (state) => state.currentTime,
};
const actions = {  
    async loadCurrentActivity({commit}){
        const endpoint = API_URL + 'api/v2/clockerUtils/getCurrentActivity';
        await axios.get(endpoint).then((res) =>{
            commit('setCurrentActivity', res.data);         
        }); 
    },
    async loadCurrentShiftObject({commit}){
        const endpoint = API_URL + 'api/v2/clockerUtils/getCurrentShiftObject';
        await axios.get(endpoint).then((res) =>{
            commit('setCurrentShiftObject', res.data);
        }); 
    },
    async loadTodayActivities({commit}){
        const endpoint = API_URL + 'api/v2/clockerUtils/loadTodayActivities';
        await axios.get(endpoint).then((res) =>{
            commit('setTodayActivities', res.data.todayActivity); 
        }); 
    },
    async clockInAgent({commit}, form){
        const endpoint = API_URL + `api/v2/clockerUtils/${form.lob}/clock-in`;
        const response = await axios.post(endpoint, form).then((res) =>{
            commit('setClockInObject', res.data.shift);
            return res.data;
        });
        return response;
    },
    async clockOutAgent({commit}, data){
        const endpoint = API_URL + `api/v2/clockerUtils/${data.shift_id}/clock-out`;
        await axios.get(endpoint).then((res) =>{
            commit('setClockOutObject', res.data.msg);
        });
    },
    
    async startNewActivity({commit}, data){
        const endpoint = API_URL + `api/v2/clockerUtils/${data.shift_id}/start-event`;
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setStatusRequest', res.data.activity);
            return res.data;
        });
        return response;
    },
    async endNewActivity({commit}, shift_id){
        const endpoint = API_URL + `api/v2/clockerUtils/${shift_id}/end-activity`;
        const response = await axios.get(endpoint).then((res) =>{
            commit('setStatusRequest', res.data);
            return res.data;
        });
        return response;
    },

    async getTimeFromServer({commit}){
        const endpoint = API_URL + 'api/v2/clockerUtils/getTimeFromServer';
        await axios.get(endpoint).then((res) =>{
            commit('setCurrentTime', res.data );
        });
    },

};
const mutations = {
    setCurrentActivity:     (state, parameter)  =>  (state.currentActivityObject    = parameter),
    setCurrentShiftObject:  (state, parameter)  =>  (state.currentShiftObject       = parameter),
    setTodayActivities:     (state, parameter)  =>  (state.todayActivityObject      = parameter),
    setClockInObject:       (state, parameter)  =>  (state.clockInObject            = parameter),
    setClockOutObject:      (state, parameter)  =>  (state.clockOutObject           = parameter),
    setStartActivity:       (state, parameter)  =>  (state.startActivityObject      = parameter),
    setEndActivity:         (state, parameter)  =>  (state.endActivityObject        = parameter),
    setActivitiesObject:    (state, parameter)  =>  (state.activitiesObject         = parameter),
    setIsCommentRequired:   (state, parameter)  =>  (state.activityCommentRequired  = parameter),
    setStatusRequest:       (state, parameter)  =>  (state.statusRequest            = parameter),
    setCurrentTime:         (state, parameter)  =>  (state.currentTime              = parameter),
};
export default {
    state,
    getters,
    actions,
    mutations
};