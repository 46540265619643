<template>
  <div>
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

              <div class="flex items-start gap-4">
                <div class="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-award-fill h-6 w-6 text-red-600" viewBox="0 0 16 16">
                    <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                  </svg>
                </div>
                <div class="text-left my-auto">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 text-left justify-center my-auto" id="modal-title">Adding a new activity for this training</h3>
                </div>
              </div>
              
              <div class="space-y-4 p-3">
                <div class="text-left">
                  <span class="text-sm text-gray-500">Activity</span>
                  <input type="text" v-model="subject" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2">
                  <div class="flex flex-row w-full">
                      <pill :dirty="$v.subject.$dirty" :valid="!$v.subject.$invalid">
                        Min: 10 chars., max of 50 chars.
                      </pill>
                  </div>
                </div>
              </div>
              
              <div class="space-y-4 p-3">
                <div class="text-left">
                  <span class="text-sm text-gray-500">Write a comment</span>
                  <textarea v-model="textbox" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2" rows="4"></textarea>
                  <div class="flex flex-row w-full">
                      <pill :dirty="$v.textbox.$dirty" :valid="!$v.textbox.$invalid">
                        Min: 10 chars., max of 5000 chars.
                      </pill>
                  </div>
                </div>
              </div>

            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto my-auto"
              @click="answer(true)">
                Continue
              </button>

              <button class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto my-auto"
              @click="answer(false)">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Pill from '@/views/mockups/ValidationPill';
  export default {
    props: {
      comment: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'new'
      },
      title: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        COMMENT_TYPE: 'training_activity',
        training_id: null,
        subject: this.title,
        textbox: this.comment,
      }
    },
    validations () {
        return {
          subject: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(50)
          },
          textbox: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(5000)
          }
        }
    },
    components: {
      Pill,
    },
    methods: {
      answer (status) {
        if (status) {
          this.$v.$touch();
          if(this.$v.$invalid) return;
        }
        let obj = {
          confirmation: status,
          class_id: this.training_id,
          note_type: this.COMMENT_TYPE,
          title: this.subject,
          comment: this.textbox,
          modal_type: this.type
        }
        this.$emit('toggle', obj);
      },
    },
    created () {
        this.training_id = this.$route.params?.id;
    }
  }
  </script>