<template>
    <div class="relative inline-flex items-center justify-center rounded-full overflow-hidden" :style="{ backgroundColor: bgColor, color: textColor }">
        <div class="font-bold mt-1" :style="{color: textColor}">
            {{ firstLetter }}{{ secondLetter }}
        </div>
    </div>
</template>
<script>
export default{
    props: {
        employeeId: String
    },
    computed: {
        firstLetter () {
            return this.employeeId[0];
        },
        secondLetter () {
            return this.employeeId[2];
        },
        bgColor() {
            return this.getRandomColor();
        },
        textColor() {
            let color = this.calculateContrastColor(this.bgColor);
            return color;
        },
    }, methods:{
        getRandomColor () {
            const min = 200; 
            const max = 250;
            let color = '#';
            for (let i = 0; i < 3; i++) {
                const componentValue = Math.floor(Math.random() * (max - min + 1) + min);
                const hex = componentValue.toString(16).padStart(2, '0');
                color += hex;
            }
            return color;
        },
        calculateContrastColor(hexColor) {
            const darkenFactor = 0.2;
            const color = hexColor.substring(1);
            const num = parseInt(color, 16);

            const r = (num >> 16) * (1 - darkenFactor);
            const g = ((num >> 8) & 0x00FF) * (1 - darkenFactor);
            const b = (num & 0x0000FF) * (1 - darkenFactor);
            const darkerColor = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
            return darkerColor;
        },
    }
}
</script>