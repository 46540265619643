<template>
    <div class="bg-white">
    <table v-if="showTable" class="table-sm table bg-white rounded-md">
        <thead class="bg-gray-100">
            <tr>
                <th colspan="10">
                    <div class="text-center justify-content-center align-middle text-xs flex">
                        <svg class="w-4 h-4 mr-1 flex" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        Payroll Events Found
                    </div>
                </th>
            </tr>
            <tr>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Employee ID</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Campaign</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Date</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Event</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Billable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Payable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Std Billable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Grv Billable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Std Payable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Grv Payable</th>
            </tr>
        </thead>

        <tbody v-if="this.getPayrollAgentInfo.length > 0" class="h-full bg-white divide-y divide-gray-200">
            <tr v-for="(item) in this.getPayrollAgentInfo" :key="item.ID" :id="item.ID">
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.emp_id}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Campaign}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Event_date}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Event.toUpperCase()}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle" :class="{ 'bg-red-200' : item.Billable === 'FALSE' , 'bg-green-200' : item.Billable === 'TRUE' }">{{item.Billable}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle" :class="{ 'bg-red-200' : item.Payable === 'FALSE' , 'bg-green-200' : item.Payable === 'TRUE' }">{{item.Payable}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle" :class="{ 'bg-orange-200' : item.std_bill < 0 }">{{secondsToTime(item.std_bill)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-gray-100">{{secondsToTime(item.grav_bill)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{secondsToTime(item.std_pay)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-gray-100">{{secondsToTime(item.grav_pay)}}</td>
            </tr>
            <tr v-for="(item) in this.getPayrollSummary" :key="item.emp_id" :id="item.emp_id" class="bg-gray-100">
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle"></td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle"></td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle"></td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle"></td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle"></td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle font-bold align-middle">Subtotal</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-white">{{secondsToTime(item.std_bill)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{secondsToTime(item.grav_bill)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-white">{{secondsToTime(item.std_pay)}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{secondsToTime(item.grav_pay)}}</td>
            </tr>
        </tbody>

        <tbody v-else class="col-span-6">
            <tr>
                <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="10">
                    No records found.
                </td>
            </tr>
        </tbody>

    </table>
    </div> 

</template>
<script>
import { mapGetters, mapState, mapActions} from "vuex";
export default {

    data() {
        return {
            FilteredEmails: [],
            buttonLoading: false,
            buttonText: "Agree",

            loadingTable:   false,
            showTable:      false,
        }
    },

    computed: {
        ...mapState([
            "payroll"
        ]),
        ...mapGetters([
            "getPayrollAgentInfo",
            "getPayrollSummary"
        ])
    },

    props:{
        employeeId:     Object,
        dateSelected:   String
    },

    methods: {
        ...mapActions([
            "fetchAllPayrollPerEmpID"
        ]),

        secondsToTime(SECONDS){
            let sign = Math.sign(SECONDS) >= 0 ? '' : '-';
            let time = new Date(Math.abs(SECONDS) * 1000).toISOString().substr(11, 8)
            return sign+time;
        }

    },

    created(){
            this.loadingTable = true;
            let eventInfo = {
                EmpID:  this.employeeId.EmpID,
                date:   this.dateSelected
            }
            this.fetchAllPayrollPerEmpID(eventInfo).then( ()=>{
                this.$emit('loadingComplete', true);
                this.loadingTable = false;
                this.showTable    = true;
            });
    },
}

</script>

<style scoped>
.inactive-button{
    cursor: wait;
    display: hide;
}

</style>