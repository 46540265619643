<template>
  <div>
    <courses caption="Ticketing System">
      <template slot="video">
        <b-embed type="iframe"  aspect="16by9" src="https://player.vimeo.com/video/537101406" allowfullscreen></b-embed>
      </template>
      <b-container class="p-3 rounded-sm" style="width: 50%; position:relative; bottom:0; left: 0rem; right: 0rem; text-align: justify;">
        <p><i><b>"I have read and understood the process “EMS Ticketing System” and thereby commit to execute it to the best of my ability, and to escalate any questions I might have regarding the process I might have in the future"</b></i></p>

        <div v-if="already_answered == true && loadingFlag == false" class="alert alert-success" role="alert">You already agreed with this SOP, but you can send new feedbacks any time you want!</div>

        <b-form-group v-else-if="already_answered == false && loadingFlag == false" class="mt-2">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <b-form-checkbox v-model="Agreement" id="Agreement" value="Agree" required>I Agree</b-form-checkbox>
              </div>
            </div>
            <input type="text" v-if="username.toUpperCase() != EmployeeId.toUpperCase()" class="form-control is-invalid" aria-label="Input" placeholder="Write your username" v-model="username" required>
            <input type="text" v-else class="form-control is-valid" aria-label="Input" placeholder="Write your username" v-model="username" required>
            <div v-if="Agreement=='Agree' && username.toUpperCase() == EmployeeId.toUpperCase()" class="valid-feedback">*Please, mark the box and write your username to validate you read and agree the SOP</div>
            <div v-else class="invalid-feedback">*Please, mark the box and write your username to validate you read and agree the SOP</div>
          </div>
        </b-form-group>

        <b-form-group label="Pleace let us know if you have any feedback:" class="mb-5">
          <div class="input-group mb-1">
            <b-form-textarea v-if="feedback==null || feedback == ''" v-model="feedback" no-resize type="text" class="form-control is-invalid" rows="3" max-rows="3" aria-describedby="button-addon1" placeholder="Please write any comment you have" required></b-form-textarea>
            <b-form-textarea v-else v-model="feedback" no-resize type="text" class="form-control is-valid" rows="3" max-rows="3" aria-describedby="button-addon1" placeholder="Please write any comment you have" required></b-form-textarea>
            <div class="input-group-append">
              <button class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded" type="button" id="button-addon1" @click="sendFeedback()">Send</button>
            </div>
            <div class="invalid-feedback">*Write a comment to submit your agreement or a new feedback</div>
          </div>
        </b-form-group>
      </b-container>
    </courses>
  </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import Courses from "@/views/components/Layouts/Resources/Courses";
export default {
  name: "",
  components: {
    Courses
  },
  computed: {
    ...mapState(['clocker']),
    ...mapGetters([
      'getFeedbackStatus'
    ]),
    isMonterrey:function (){
      return this.location.includes('MTY') > 0;
    }
  },
  created(){
    this.confirmTerms();
  },
  data(){
    return{
      Agreement: null,
      feedback: null,
      feedback_name: 'SOP_Tickets',
      store_type: null,
      submitted: false,
      loadingFlag: true,
      already_answered: false,
      username: '',
      EmployeeId: JSON.parse(sessionStorage.getItem('EmpID')),
      location: JSON.parse(sessionStorage.getItem("location")),
      CurrentPermissions:[]
    }
  },
  methods:{
    ...mapActions([
      'checkFeedbackStatus',
      'storeFeedback'
    ]),

    confirmTerms: function(){
      this.checkFeedbackStatus(this.feedback_name).then( () => {
        if(this.getFeedbackStatus == 'already_answered'){
          this.already_answered = true;
          this.loadingFlag = false;
          this.store_type = 'concatenate';
          this.Agreement='Agree';
          this.username = this.EmployeeId;
        }else if(this.getFeedbackStatus == 'not_initialized'){
          this.already_answered = false;
          this.loadingFlag = false;
          this.store_type = 'new';
        }
        console.log(this.store_type);
      }, error => {
        console.error("Error fetching the exam: "+ error)
      });
    },

    sendFeedback: function(){
      if(this.Agreement!='Agree' || this.feedback == null || this.feedback == '' || this.username == null || this.username == ''){
        this.$fire({
          type: 'warning',
          title: 'There is information missing',
          showConfirmButton: false,
          timer: 1500
        })
      }else{
        let objectExam = {
          'feedback_name': this.feedback_name,
          'feedback': this.feedback,
          'store_type': this.store_type,
        };
        this.storeFeedback(objectExam).then( (res) =>{
          if (res.status == 200){
            this.$fire({
              type: 'success',
              title: 'The Feedback has been store successfully',
              showConfirmButton: false,
              timer: 1500
            }).then(()=>{
              this.$router.go(0);
            });
          }else{
            this.$fire({
              type: 'warning',
              title: 'There are some errors',
              showConfirmButton: false,
              timer: 1500
            }).then(()=>{
              this.$router.go(0);
            });
          }
        });
      }

    },

    getPermissions: function(){
      this.CurrentPermissions = JSON.parse(sessionStorage.getItem('roles'));
    },

    itemsContains(n) {
      return this.CurrentPermissions.indexOf(n) > -1
    }

  },
  mounted(){
    if(this.location.includes('MTY') < 0 && !this.itemsContains('SystemAdministrator')){
      this.$router.push({name:'base.dashboard'});
    }
  }

};
</script>

<style>
.back-button{
  right: 25rem;
  position:relative;
}
</style>