<template>
 <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1">

        <serial-number @toggle="updateFilters"/>
       
        <div class="mx-2 border-r-2 border-gray-200 p-2 px-3">
            <loading v-if="!filtersLoaded" msg="Loading" :active="!filtersLoaded" class="h-3 text-sm"/>
            <select v-else v-model="form.site" required >
                    <option value="">All Sites</option>
                    <option v-for="item in getLocations" :value="item" :key="item" class="uppercase">{{item.toUpperCase()}}</option>
                </select>
        </div>

        <div class="mx-2 border-r-2 border-gray-200 p-2 px-3">
            <loading v-if="!filtersLoaded" msg="Loading" :active="!filtersLoaded" class="h-3 text-sm"/>
            <select v-else v-model="form.status" required >
                    <option value="">All Status</option>
                    <option value="AVAILABLE">AVAILABLE</option>
                    <option value="IN-USE">IN-USE</option>
                    <option value="LOST">LOST</option>
                </select>
        </div>

        <div class="mx-2 border-r-2 border-gray-200 p-2 px-3">
            <loading v-if="!filtersLoaded" msg="Loading" :active="!filtersLoaded" class="h-3 text-sm"/>
            <select v-else v-model="form.type" required >
                    <option value="">All Types</option>
                    <option v-for="item in getNewItemsProperties.types" :value="item.Item_Type_ID" :key="item.Item_Type_Label">{{item.Item_Type_Label}}</option>
                </select>
        </div>
    
  </div>
                                           
    
</template>
<script>
import Debounce         from 'debounce';
import {mapGetters, mapActions} from 'vuex';
import Loading from '../../../modules/hm/components/Loading';
import serialNumber         from './serial_number';
export default {
    data(){
        return{
            filtersLoaded: false,
            form:{
                page: 1,
                site:'',
                status:'',
                serial_number:'',
                type: '',
            }
        }
    },
    components:{
        Loading,
        serialNumber
    },
    computed:{
        ...mapGetters([
            'getLocations',
            'getNewItemsProperties'
        ])
    },
    methods:{
        ...mapActions([
            'fetchSites',
            'fetchNewItemProperties'
        ]),
        clear:function(){
            this.form.idNumber = null;
        },
        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value;
            this.form.page = 1;
        },350),
    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    mounted(){
        this.$emit('toggle',this.form);
    },
    created(){
        this.filtersLoaded = false;
        this.fetchSites();
        this.fetchNewItemProperties().then(() => {
            this.filtersLoaded = true;
        })
    }
}
</script>