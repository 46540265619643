<template>
  <div class="flex-col space-y-5">
    <div class="flex">
      <div class="flex w-screen py-2 justify-between h-16 bg-white shadow-sm rounded-lg"
           @change="updateFields">
        <search class="flex grow">
          <div
              class="flex grow justify-between mx-3 rounded-lg overflow-hidden border divide-x">
            <select id="queueDropdown" v-model="queueSelected"
                    class="flex-1 w-1/3 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 uppercase"
                    :disabled="isLoading">
              <option value="" :hidden="isActive === 'questionsButton'">Select Queue</option>
              <option v-for="queue in getQueuesList" :key="queue.id" :value="queue"
                      class="uppercase">
                {{ queue.queueName }}
              </option>
            </select>
            <select id="categoriesDropdown" v-model="categorySelected"
                    :disabled="queueSelected === '' || isLoading"
                    class="flex-1 w-1/3 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 uppercase">
              <option value="">Select Category</option>
              <option v-for="category in getCategoriesList" :key="category.id" :value="category"
                      class="uppercase">
                {{ category.categoryName }}
              </option>
            </select>
            <select id="subCategoriesDropdown" v-model="subCategorySelected"
                    :disabled="isActive === 'queueButton' || categorySelected === '' || isLoading"
                    class="flex-1 w-1/3 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 uppercase">
              <option value="">Select Sub-Category</option>
              <option v-for="subcategory in getSubCategoriesList" :key="subcategory.id"
                      :value="subcategory"
                      class="uppercase">
                {{ subcategory.subCategoryName }}
              </option>
            </select>
          </div>
          <div class="flex w-48">
            <button :class="selectionButtons('queueButton')"
                    class="toggle-button px-1.5 flex-1 rounded-l-3xl"
                    @click="toggle('queueButton')"
                    :disabled="isLoading">
              Queues
            </button>
            <button :class="selectionButtons('questionsButton')"
                    class="toggle-button p-1.5 flex-1 rounded-r-3xl"
                    @click="toggle('questionsButton')"
                    :disabled="isLoading">
              Questions
            </button>
          </div>
        </search>
        <button type="submit"
                form="tableForm"
                :disabled="rowsHaveNotChanged() || isLoading"
                class="bg-gradient-to-r from-[#2d704a] to-[#4C956C] text-white rounded-3xl w-32 mx-3
                disabled:text-black disabled:from-[#EAEBEF] disabled:to-[#F7F8FC]">
          Save
        </button>
      </div>
    </div>
    <form class="flex" id="tableForm" @submit.prevent="save">
      <table class="w-screen light-table table-auto shadow-sm">
        <thead>
        <tr>
          <th class="w-20 rounded-tl-lg">Field</th>
          <th>Content</th>
          <th class="w-40">Data Type</th>
          <th class="w-40">Attached To</th>
          <th class="w-20 rounded-tr-lg">Options</th>
        </tr>
        </thead>
        <tbody>
        <tr :class="{ 'border-[#ff434e]': rowInfo.active === 0, 'border-[#4C956C]': rowInfo.id.toString().startsWith('newQuestion')}"
            class="border-l-4 rounded-lg h-12" v-for="(rowInfo, index) in newRows" :key="rowInfo.id">
          <td class="text-center [&&]:py-0">{{ index + 1 }}</td>
          <td class="[&&]:py-0">
            <input :class="columnHasChanged(rowInfo, 'content', originalRows)" required
                   class="grow focus:ring border rounded-lg p-2.5 bg-slate-100 w-full" type="text"
                   v-model="rowInfo.content" placeholder="Your question goes here."
                   :disabled="isLoading">
          </td>
          <td class="[&&]:py-0">
            <select required v-if="isActive === 'questionsButton'" v-model="rowInfo.dataType"
                    :class="columnHasChanged(rowInfo, 'dataType', originalRows)"
                    class="flex-1 focus:ring border rounded-lg bg-slate-100 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 capitalize"
                    :disabled="isLoading">
              <option v-for="qType in questionTypes" :key="qType" :value="qType" class="capitalize">
                {{ qType }}
              </option>
            </select>
            <div class="capitalize text-center" v-else> {{ rowInfo.dataType }}</div>
          </td>
          <td class="[&&]:py-0">
            <select required v-if="isActive === 'questionsButton'" v-model="rowInfo.attachedTo"
                    :class="columnHasChanged(rowInfo, 'attachedTo', originalRows)"
                    class="flex-1 focus:ring border rounded-lg bg-slate-100 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    :disabled="isLoading">
              <option value="queue">Queue</option>
              <option value="category" v-if="!['all', 'queue'].includes(findSearchLevel)">Category
              </option>
              <option value="subCategory" v-if="findSearchLevel === 'subCategory'">Sub-Category
              </option>
            </select>
            <div class="capitalize text-center" v-else-if="rowInfo.attachedTo === ''">Root</div>
            <select v-else required v-model="rowInfo.attachedTo"
                    :class="columnHasChanged(rowInfo, 'attachedTo', originalRows)"
                    class="flex-1 focus:ring border rounded-lg bg-slate-100 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 capitalize"
                    :disabled="isLoading">
              <option v-for="qAttachedTo in queueAttachedToOptions" :key="qAttachedTo.name"
                      :value="qAttachedTo.id" class="capitalize">
                {{ qAttachedTo.name }}
              </option>
            </select>
          </td>
          <td class="text-center relative group p-0 [&&]:py-0">
            <div id='options'
                    class="flex font-semibold items-center m-auto focus:ring rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="size-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </div>

            <div class="inline-block absolute -left-0">
              <div
                  class="bg-white shadow-md rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-200 ease-in-out origin-top min-w-32 -left-10 z-10">
                <button v-if="rowInfo.active"
                        class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                        @click.prevent="removeRow(rowInfo)"
                        :disabled="isLoading">
                  <i class="bi bi-x"></i>
                  Remove
                </button>
                <button v-else
                        class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                        @click.prevent="reinstateRow(rowInfo)"
                        :disabled="isLoading">
                  <i class="bi bi-arrow-counterclockwise"></i>
                  Reinstate
                </button>
                <button v-if="rowInfo.dataType === 'dropdown'"
                        :disabled="isLoading"
                        class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                        @click.prevent="editDropdown(rowInfo)">
                  <i class="bi bi-menu-button-wide-fill"></i>
                  Edit Dropdown
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan=5 class="p-2 text-center">
            <button @click.prevent="addNewRow"
                    :disabled="(findSearchLevel === 'all' && isActive === 'questionsButton') || isLoading"
                    class="w-full bg-gradient-to-r from-[#2146b5] to-[#397BEF] h-12 text-white rounded-lg text-lg focus:ring disabled:text-black disabled:from-[#EAEBEF] disabled:to-[#F7F8FC]">
              Add New Field
            </button>
          </td>
        </tr>
        </tfoot>
      </table>
    </form>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      queueAttachedToOptions: [],
      queueSelected: '',
      categorySelected: '',
      subCategorySelected: '',
      newQuestionIndex: 0,
      newRows: [],
      originalRows: [],
      isActive: 'questionsButton',
      questionTypes: [
        'text',
        'number',
        'dropdown',
        'date',
        'time',
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getQueuesList",
      "getCategoriesList",
      "getSubCategoriesList",
      "getSubQuestions",
    ]),
    lastDataType() {
      let mapping = {'all': 'queue', 'queue': 'category', 'category': 'subCategory'}
      return mapping[this.findSearchLevel]
    },
    lastAttachedTo() {
      let mapping = {all: '', queue: this.queueSelected, category: this.categorySelected,}
      return mapping[this.findSearchLevel].id
    },
    findSearchLevel() {
      if (this.queueSelected === '') {
        return 'all';
      }
      if (this.categorySelected === '') {
        return 'queue';
      }
      if (this.subCategorySelected === '') {
        return 'category';
      }
      return 'subCategory';
    },
  },
  methods: {
    ...mapActions([
      'fetchTicketQueues',
      'fetchTicketCategories',
      "fetchTicketSubCategories",
      "fetchTicketTemplate",
      "saveTicketTemplate",
    ]),
    toggle(button) {
      this.isActive = button;
      if (button === 'queueButton') {
        this.subCategorySelected = '';
      }
      this.updateFields();
    },
    rowsHaveNotChanged() {
      return JSON.stringify(this.newRows) === JSON.stringify(this.originalRows);
    },
    columnHasChanged(rowInfo, column, comparedToObject) {
      let comparedTo = comparedToObject.find(x => x.id === rowInfo.id);

      if (comparedTo == null) {
        return 'bg-[#f7f0bf] border-[#DDD7B1]'
      }

      return {
        'bg-[#f7f0bf] border-[#DDD7B1]': comparedTo[column] !== rowInfo[column],
      };
    },
    selectionButtons(button) {
      return {
        'bg-gradient-to-r from-[#2146b5] to-[#397BEF] text-white': this.isActive === button,
        'bg-gradient-to-r from-[#EAEBEF] to-[#F7F8FC] text-black': this.isActive !== button,
      };
    },
    addNewRow() {
      let dataType = ''
      let attachedTo = ''

      if (this.isActive === 'queueButton') {
        dataType = this.lastDataType;
        attachedTo = this.lastAttachedTo ?? '';
      }

      this.newRows.push(
          {
            id: `newQuestion${this.newQuestionIndex}`,
            content: '',
            dataType: dataType,
            attachedTo: attachedTo,
            dropdownOptions: [],
            active: 1,
          }
      );
      this.newQuestionIndex += 1;
    },
    removeRow(row) {
      if (row.id.toString().startsWith('newQuestion')) {
        this.newRows = this.newRows.filter(x => x.id !== row.id)
        return
      }

      row.active = 0;
    },
    reinstateRow(row) {
      row.active = 1;
    },
    editDropdown(row) {
      this.$fire(
          {
            title: 'Edit Dropdown Options',
            titleText: 'Introduce the dropdown options separated by `;`',
            input: 'textarea',
            inputValue: row.dropdownOptions.join(';'),
            showCancelButton: true,
          }
      ).then((result) => {
        row.dropdownOptions = result.value.trim().split(';');
      });
    },
    updateQueues() {
      let searchLevel = this.findSearchLevel;

      switch (searchLevel) {
        case 'all':
          this.queueAttachedToOptions = [];
          this.originalRows = this.getQueuesList.map((queue) => {
            return {
              id: queue.id,
              content: queue.queueName,
              dataType: 'queue',
              attachedTo: '',
              active: 1,
            };
          });
          break;
        case 'queue':
          this.queueAttachedToOptions = this.getQueuesList.map(x => {
            return {id: x.id, name: x.queueName}
          });
          this.originalRows = this.getCategoriesList.map((category) => {
            return {
              id: category.id,
              content: category.categoryName,
              dataType: 'category',
              attachedTo: this.queueSelected.id,
              active: parseInt(category.isActive),
            }
          });
          break;
        case 'category':
          this.queueAttachedToOptions = this.getCategoriesList.map(x => {
            return {id: x.id, name: x.categoryName}
          });
          this.originalRows = this.getSubCategoriesList.map((subCategory) => {
            return {
              id: subCategory.id,
              content: subCategory.subCategoryName,
              dataType: 'subCategory',
              attachedTo: this.categorySelected.id,
              active: subCategory.isActive,
            }
          });
          break;
      }
      this.newRows = JSON.parse(JSON.stringify(this.originalRows));
      this.isLoading = false;
    },
    updateFields() {
      this.isLoading = true;

      let searchLevel = this.findSearchLevel;

      if (['all', 'queue'].includes(searchLevel)) {
        this.categorySelected = '';
        this.subCategorySelected = '';
      }
      if (searchLevel === 'category') {
        this.subCategorySelected = '';
      }
      if (searchLevel === 'subCategory') {
        this.updateQuestions();
        return;
      }

      const fetchMapping = {
        all: {func: this.fetchTicketQueues, params: {}},
        queue: {func: this.fetchTicketCategories, params: {queueSelected: this.queueSelected}},
        category: {
          func: this.fetchTicketSubCategories,
          params: {categorySelected: this.categorySelected}
        },
      };

      const updateMapping = {
        questionsButton: this.updateQuestions,
        queueButton: this.updateQueues,
      }

      let fetchStrat = fetchMapping[searchLevel];

      if (this.isActive === 'questionsButton') {
        fetchStrat.func(fetchStrat.params);
        updateMapping[this.isActive]();
      } else {
        fetchStrat.func(fetchStrat.params).then(
            () => {
              updateMapping[this.isActive]();
            }
        );
      }
    },
    updateQuestions() {
      let searchLevel = this.findSearchLevel

      if (searchLevel === 'all') {
        this.originalRows = [];
        this.newRows = [];
        this.isLoading = false;
        return;
      }

      this.fetchTicketTemplate(
          {
            queue_id: this.queueSelected.id,
            category_id: this.categorySelected.id,
            subCategory_id: this.subCategorySelected.id,
            level: searchLevel,
          }
      ).then(() => {
        this.originalRows = this.getSubQuestions.map((question) => {
          return {
            id: question.ID,
            content: question.LabelTemplate,
            dataType: question.QuestionType,
            attachedTo: (question.QueueID ? 'queue' : (question.CategoryID ? 'category' : 'subCategory')),
            dropdownOptions: question.question_options.map((questionOptions) => questionOptions.optionLabel),
            active: question.IsActive,
          };
        });
        this.newRows = JSON.parse(JSON.stringify(this.originalRows));
        this.isLoading = false;
      });
    },
    save() {
      this.isLoading = true;

      let emptyDropdowns = this.newRows.filter(
          (row) => row.dataType === 'dropdown' && row.dropdownOptions.length === 0);

      if (emptyDropdowns.length > 0) {
        this.$fire({
          type: 'error',
          title: 'You have dropdowns with no options.'
        });
        return;
      }

      let strOriginalRows = this.originalRows.map(ogRow => JSON.stringify(ogRow));

      let changes = this.newRows.filter(row => !strOriginalRows.includes(JSON.stringify(row)));

      let payload = {
        type: this.isActive,
        queueId: this.queueSelected.id,
        categoryId: this.categorySelected.id,
        subCategoryId: this.subCategorySelected.id,
        rowChanges: changes,
      }
      this.saveTicketTemplate(payload).then(
          () => {
            this.isLoading = false;

            this.$fire({type: 'success', title: 'Done!', showConfirmButton: true});
            this.updateFields();
          }
      );
    },
  },
  beforeMount() {
    this.$emit('set-title', 'Ticket Queue Management');
  },
  created() {
    this.fetchTicketQueues();
  }
};
</script>