<template>
    <div>
        <div class="bg-white shadow-sm relative rounded-lg mb-10">
            <div class="flex items-center divide-x space-x-4 px-4">
                <div>
                    <i class="bi bi-search text-gray-400 py-3"></i>
                    <input type="search" placeholder="Search Title..." v-model="search" class="pl-3 py-3" />
                </div>
                <div class="flex">
                    <i class="bi bi-briefcase text-gray-400 pl-3"></i>
                    <multiselect class="multiselect-custom text-lg" placeholder="Select Campaign" select-label="" v-model="campaign" :options="getAllCampaigns">
                    </multiselect>
                </div>
                <div class="flex">
                    <i class="bi bi-geo-alt text-gray-400 pl-3"></i>
                    <multiselect class="multiselect-custom text-lg" placeholder="Select Site" select-label="" v-model="site" :options="site_options">
                    </multiselect>
                </div>
                <div class="flex">
                    <multiselect class="multiselect-custom text-lg" @input="onFilter" placeholder="Select Type" select-label="" v-model="type" :options="type_options">
                    </multiselect>
                </div>
                <div class="border-0">
                    <a class="inline-block bg-blue-50 hover:bg-blue-100 text-blue-700 px-6 py-1 rounded-full !no-underline cursor-pointer" @click="resetFilter()">Reset Filter</a>
                </div>
                <div class="flex-1"></div>
                <div class="border-0">
                    <a class="inline-block bg-blue-700 hover:bg-blue-800 text-white px-6 py-1 rounded-full !no-underline cursor-pointer" @click="createType()">Create</a>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-sm relative rounded-lg overflow-hidden">
            <table class="text-left w-full">
                <thead class="bg-slate-700 text-slate-800 text-white">
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th v-if="type=='Survey'">Interval</th>
                        <th v-if="type=='Quiz'">Attempts</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th v-if="type=='Quiz'">Duration</th>
                        <th>Apply To</th>
                        <th>Created At</th>
                        <th>Created By</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="this.loading" class="animate-pulse divide-y">
                    <tr v-for="i in 3" :key="i" class="hover:bg-slate-100">
                        <td v-for="i in (type=='Survey' ? 9:10)" :key="i"><div class="h-2 bg-slate-200 rounded my-2"></div></td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && getSurveysV2.length">
                    <tr class="hover:bg-slate-100 hover:cursor-pointer" v-for="survey in getSurveysV2" :key="survey.ID" @click="goToDetails(survey.ID)">
                        <td>{{ survey.ID }}</td>
                        <td>{{ survey.title }}</td>
                        <td v-if="type=='Survey'">{{ survey.interval }}</td>
                        <td v-if="type=='Quiz'">{{ survey.attempts }}</td>
                        <td>{{ survey.date_from }}</td>
                        <td>{{ survey.date_to }}</td>
                        <td v-if="type=='Quiz'">{{ survey.duration }}</td>
                        <td>-</td>
                        <td>{{ survey.CreatedAt }}</td>
                        <td>{{ survey.CreatedBy }}</td>
                        <td>{{ survey.status }}</td>
                        <td><a class="inline-block text-xl text-slate-300 hover:text-slate-800 hover:no-underline px-2 cursor-pointer" @click="editSurvey(survey.ID)"><i class="bi bi-pencil"></i></a></td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && !getSurveysV2.length">
                    <tr>
                        <td colspan="11" class="text-center italic text-gray-400">Empty List</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        components: {
            Multiselect
        },
        data(){
            return {
                loading: true,
                search: '',
                type: 'Survey',
                type_options: [
                    'Survey',
                    'Quiz',
                ],
                campaign: '',
                site: '',
                site_options: [
                    'MTY',
                    'AGS',
                    'QRO',
                ]
            }
        },
        computed: {
            ...mapState([
                'surveysv2'
            ]),
            ...mapGetters([
                'getAllCampaigns',
                'getSurveysV2'
            ])
        },
        created() {
            this.fetchCampaigns();
            this.fetchAllSurveys({ type: this.type.toLowerCase() }).then(() => {
                this.loading = false;
            });
        },
        methods: {
            ...mapActions([
                'fetchCampaigns',
                'fetchAllSurveys'
            ]),
            resetFilter:function() {
                this.search = '';
                this.campaign = '';
                this.site = '';
                this.filterSearch();
            },
            onFilter: function() {
                this.loading = true;
                this.fetchAllSurveys({ type: this.type.toLowerCase() }).then(() => {
                    this.loading = false;
                });
            },
            createType: function() {
                this.$fire({
                    title: "Select quiz type",
                    text: "",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonText: 'Survey',
                    cancelButtonText: 'Quiz',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'text-xl inline-block bg-blue-700 hover:bg-blue-800 text-white px-6 py-1 mx-2 rounded-full !no-underline',
                        cancelButton: 'text-xl inline-block bg-blue-700 hover:bg-blue-800 text-white px-6 py-1 mx-2 rounded-full !no-underline'
                    }
                }).then(r => {
                    if (r.value == true) {
                        this.$router.push({ name:'surveys.create', params: { type: 'survey' } });
                    } else if (r.dismiss == 'cancel') {
                        this.$router.push({ name:'surveys.create', params: { type: 'quiz' } });
                    }
                });
            },
            editSurvey: function(data) {
                this.$router.push({ name:'surveys.edit', params: { id: data } });
            },
            goToDetails: function(data) {
                this.$router.push({ name:'surveys.view', params: { id: data } });
            }
        },
        mounted() {
            let title = 'Surveys';
            this.$emit('set-title',title);
        }
    }
</script>
<style scoped>
table th {
  padding: 15px 15px;
  text-transform: uppercase;
  font-weight: bold;
}
table td {
  padding: 10px 15px;
}
.bi::before {
    vertical-align: middle !important;
}
</style>
