<template>
    <div>
        <p class="text-center text-gray-500">Review if the attendance list is correct</p>
        <div class="bg-white rounded shadow-sm">
            <ul class="divide-y">
                <li class="relative overflow-hidden py-3 px-5 text-left" v-for="(i, index) in getTrainingAttendance" :key="'row'+i.id">
                    <div class="absolute -top-2 left-0 text-5xl font-bold text-slate-100">{{ index + 1 }}</div>
                    <div class="relative flex justify-between uppercase">
                        {{ i.full_name }}
                        <div class="whitespace-nowrap">
                            <label class="switch align-middle">
                                <input type="checkbox" v-model="i.attendance" @change="updateAttendance(index)">
                                <span class="slider status round"></span>
                            </label>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
    },
    computed: {
        ...mapGetters([
            'getTrainingAttendance'
        ])
    },
    methods: {
        ...mapActions([
            'fetchAttendance',
            'updateTrainingAttendance'
        ]),

        updateAttendance (index) {
            let obj = {
                id: this.getTrainingAttendance[index].id,
                status: this.getTrainingAttendance[index].attendance
            };
            this.updateTrainingAttendance(obj);
        }
    },
    beforeMount(){
        this.$emit('set-title','Attendance & Attrition Risk');
    },
    created () {
        this.fetchAttendance(this.$route.params.id);
    }
}
</script>