<template>
  <div class="fixed z-20 w-full h-full top-0 left-0 flex flex-row items-center justify-center">
      <div class="absolute top-0 left-0 dropback w-full h-full bg-blue-200 opacity-50 z-20"></div>
      <div class="relative z-30 p-3  rounded-md shadow-faded bg-white w-1/3">
          <button class="absolute -right-2 -top-2 w-5 h-5 bg-red-300 text-white hover:bg-red-500 z-10 flex items-center ring-2 ring-gray-500 rounded-md justify-center shadow-faded" @click="close">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
          <slot name="content">
             
          </slot>
      </div>
  </div>
</template>

<script>
export default {
    methods:{
        close:function() {
            this.$emit('close');
        }
    }
}
</script>