<template>
    <select class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2" v-model="current" required>
        <option hidden value="">Select an Location...</option>
        <option value="AGS">Aguascalientes</option>
        <option value="MTY">Monterrey</option>
        <option value="QRO">Queretaro</option>
    </select>
</template>
<script>
export default {
     props:{
       val:String
    },
    data(){
        return{
            currentLocation:this.val
        }
    },
    watch:{
        currentLocation:function(){
            this.$emit('toggle',this.currentLocation);
        }
    },
    computed:{
        current:{
            get(){
                return this.currentLocation;
            },
            set(value){
                this.currentLocation = value;
            }
        }
    },
    created(){
        this.currentLocation = this.val;
    }
}
</script>