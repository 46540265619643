<template>
    <div v-if="isLoading" class="p-3 flex h-full w-full text-center justify-content-center align-items-center">
        <div class="flex h-full w-full text-center justify-content-center align-items-center">
            <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
        </div>
    </div>
    <div v-else class="relative p-6 space-x-6 w-full h-full flex justify-content-start align-items-start">
        <!-- Chat -->
        <div class="flex-1 h-full flex flex-col justify-content-start align-items-start">
            <h3>Messages</h3>
            <!-- Message Box  -->
            <!-- 08/13/2021 -->
            <div class="rounded-lg w-full h-full border border-gray-200 bg-white overflow-y-auto">
                <div class=" w-full h-full text-justify relative flex flex-1 px-3 ">
                    <component :key="ViewKey" :ticketSelected="this.ticketSelected" :is="'messageBox'"></component>
                </div>
            </div>

            <!-- Text Input if ticket is open -->
            <div v-if="!this.getTicketInfo.isClose" class="flex-1 w-full">
                <!-- Textarea -->
                <div class="mt-3">
                    <textarea class="resize-none w-full p-3 rounded-md bg-slate-100" :disabled="this.getTicketInfo.AssignedEmp == undefined" v-model="message" rows="3" :maxlength="255" placeholder="Add a reply to the ticket" no-resize></textarea>
                </div>
                <div class="flex mt-2 items-center justify-between">
                    <vs-tooltip text="Switch responsability" :disabled="this.getTicketInfo.ReplyFrom != this.EmployeeID">
                        <button :disabled="this.getTicketInfo.ReplyFrom != this.EmployeeID" @click="changeReply()" :class="{ 'hover:bg-green-700 focus:outline-none' : this.getTicketInfo.ReplyFrom == this.EmployeeID, 'cursor-def disabled' : this.getTicketInfo.ReplyFrom != this.EmployeeID}"
                        class="bg-blue-600 rounded-md text-white whitespace-nowrap p-2">Switch responsability</button>
                    </vs-tooltip>
                    <!-- Send Button -->
                    <button class="bg-blue-600 p-2 rounded-md text-white whitespace-nowrap border-none" :disabled="this.getTicketInfo.AssignedEmp == undefined" @click="sendMessage" :class="{ 'hover:bg-green-700 focus:outline-none' : this.getTicketInfo.ReplyFrom == this.EmployeeID, 'cursor-def disabled' : this.getTicketInfo.AssignedEmp == undefined}">Comment</button>
                </div>
            </div>

            <!-- If close, prevent writing -->
            <div v-else class="w-full p-3 bg-gray-100 rounded mt-3 text-center">
                <p class="text-gray-500 text-capitalize italic font-semibold m-0">This ticket was closed at {{ this.getTicketInfo.UpdatedAt | moment('YYYY/MM/DD')}}</p>
            </div>
        </div>

        <!-- Ticket Information -->
        <!-- Information -->
        <!-- 08/13/2021 -->
        <div class="h-full max-w-[370px] bg-gray-100 p-6 text-left overflow-auto rounded"> 
            <div id="headerInformation">
                <!-- Actions List -->
                <div class="relative mb-3">
                    <div class="grid grid-cols-3 gap-1 cursor-pointer" v-if="this.userType == 'moderator'">
                        <div class="rounded-sm py-1 text-gray-600 text-capitalize font-semibold text-sm cursor-pointer ">
                            <vs-tooltip text="Close Ticket">
                                <button v-b-modal="'closeTicketModal'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 bg-white fill-current rounded-full hover:border-2 hover:text-red-500 hover:border-gray-700 hover:shadow-lg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </vs-tooltip>
                        </div>
                        <div>
                            <vs-tooltip text="Modify Ticket">
                                <button class="rounded-sm py-1  text-gray-600 text-capitalize font-semibold text-sm" v-b-modal="'EditThisTicketModal'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 bg-white fill-current rounded-full  hover:border-2 hover:text-yellow-500 hover:border-gray-700 hover:shadow-lg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                        <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </vs-tooltip>
                        </div>
                        <div>
                            <vs-tooltip text="Create Activity">
                                <button class="rounded-sm  py-1  text-gray-600 text-capitalize font-semibold text-sm" v-b-modal="'NewTicketModal'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 bg-white fill-current rounded-full hover:text-blue-500  hover:border-2 hover:border-gray-700 hover:shadow-lg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </vs-tooltip>
                        </div>
                    </div>
                    <div v-if="this.userType == 'user'">
                        <div v-if="this.canReopen">
                            <button v-if="this.canReopen" class="rounded-sm px-2 py-1 hover:bg-gray-100 text-gray-600 text-capitalize font-semibold text-sm cursor-pointer" @click="reOpenTicket()">Re-Open</button>
                        </div>
                        <div v-else>
                            <div class="bg-red-100 text-red-800 text-xs text-center leading-none rounded-sm p-3"> * There is no options available for this ticket</div>
                        </div>
                    </div>
                </div>
                <!-- Title -->
                <h2 class="text-xl font-semibold mb-2">Ticket ID: {{this.getTicketInfo.ID}}</h2>
                <div class="space-y-1 mb-3">
                    <div class="leading-none text-sm"><b class="font-semibold">Queue:</b> {{this.getTicketInfo.queueName}}</div>
                    <div class="leading-none text-sm"><b class="font-semibold">Subcategory:</b> {{this.getTicketInfo.subCategoryName}}</div>
                </div>

                <!-- Modals -->
                <b-modal ref="closeTicket" :id="'closeTicketModal'" hide-footer centered>
                    <component @closeModal='hideModal()' :key="ViewKey" :ticketId="parseInt(this.ticketSelected)" :is="'closeticketmodal'"></component>
                </b-modal>
                <b-modal ref="EditModal" :id="'EditThisTicketModal'" hide-footer centered>
                    <component @closeModal='hideModal()' :key="ViewKey" :queueId="this.getTicketInfo.QueueID" :thicketId="parseInt(this.ticketSelected)" :is="'editTicketModal'"></component>
                </b-modal>
                <b-modal ref="subModal" :id="'NewTicketModal'" hide-footer centered size="lg">
                    <component @closeModal='hideModal()' :queueId="this.getTicketInfo.QueueID" :thicketId="parseInt(this.ticketSelected)" :is="'subTicketModal'"></component>
                </b-modal>
                <!-- End Modals -->
            </div>
            <div class="bg-gray-200 space-y-1 p-3 rounded mb-3">
                <div class="leading-none text-sm" :class="{'text-red-500' : !this.getTicketInfo.AssignedEmp}"><b class="font-semibold">Assigned To:</b> {{this.getTicketInfo.AssignedEmp ? this.getTicketInfo.AssignedEmp : "Not assigned yet"}}</div>
                <div class="leading-none text-sm"><b class="font-semibold">Opened By:</b> {{ this.getTicketInfo.ReportedBy }}</div>
                <div class="leading-none text-sm"><b class="font-semibold">Affected Employee:</b> {{ this.getTicketInfo.AffectedEmployee}}</div>
            </div>
            <div class="text-sm">
                <h4 class="bg-slate-700 text-white rounded p-2 text-lg leading-none mb-3">Details</h4>
                <div class="mb-3">
                    <b class="font-semibold">Ticket Description:</b> ({{this.getTicketInfo.IssueDate}})/{{this.getTicketInfo.TicketDescription}}
                </div>
                <!-- v-if="this.getTicketInfo.TicketTemplateContent" -->
                <div class="space-y-1">
                    <div class="leading-none" v-for="(details, index) in this.informationArray" :key="index" :label="details">
                        <i class="bi bi-chevron-right text-[60%] align-middle"></i> {{details}}
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <h4 class="bg-slate-700 text-white rounded p-2 text-lg leading-none mb-3">Files Attached</h4>
                <input @change="saveNewImage " ref="uploadInput" type='file' class="hidden" />
                <div v-if="imagesLoading" class="p-3 flex h-full w-full text-center justify-content-center align-items-center">
                    <div class="flex h-full w-full text-center justify-content-center align-items-center">
                        <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
                         <span class="italic">Loading images...</span>
                    </div>
                </div>
                <div v-else class="min-h-full w-full relative col-span-2 grid grid-cols-3 gap-2" v-show="getImages.length > 0">
                    <div class="items-center m-1 hover:shadow-lg cursor-pointer group" v-for="(img, idx) in getImages" :key="idx">
                        <div class="bg-transparent rounded-xl relative group-hover:border-black-800">
                            <b-img :src="img" thumbnail alt="Fluid image" v-b-modal="'imgModal'+idx"></b-img>
                        </div>
                        <b-modal size="lg" ref="displayImage" :id="'imgModal'+idx" hide-footer centered no-close-on-backdrop>
                            <div>
                                <b-img :src="img" thumbnail fluid-grow alt="Fluid image" v-b-modal="'displayImage'"></b-img>
                            </div>
                        </b-modal>
                    </div>
                    <div  @click="append" class="m-1 hover:shadow-lg cursor-pointer group bg-transparent border-dashed border-2 rounded-xl ">
                        <div class="relative group-hover:border-black-800 h-20">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div v-show="getImages.length === 0" class="min-h-full w-full relative col-span-2 grid grid-cols-3 gap-2">
                    <div @click="append" class="cursor-pointer border-dashed border-2 hover:border-gray-400 flex items-center justify-center h-16 rounded-xl">
                        <div class="relative group-hover:border-black-800">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
     
    </div>

</template>
<script>
    import {mapState, mapGetters, mapActions}   from 'vuex';
    import closeticketmodal                     from'@/views/components/Tickets/modals/closeTicket';
    import messageBox                           from '@/views/components/Tickets/tables/my_messages'
    import editTicketModal                      from '@/views/components/Tickets/modals/editTicket'
    import subTicketModal                       from '@/views/components/Tickets/modals/subTicket'
    import { required }                         from 'vuelidate/lib/validators';
    import auth                                 from "@/config/user"
    export default {
        validations: {
            message: { required }
        }, 
        components: {
            messageBox,
            editTicketModal,
            subTicketModal,
            closeticketmodal
        },
        created(){
            this.EmployeeID = auth.currentUser.EmpID;
            let ObjData = {
                ticketSelected: this.ticketSelected
            }
            this.fetchTicketProofImages(this.ticketSelected).then((response)=>{
                this.images = response.data.images;
            })
            this.fetchThisTicketInfo(ObjData).then(()=>{
                if(this.getTicketInfo.TicketTemplateContent){
                    this.informationArray = this.getTicketInfo.TicketTemplateContent.split('%%,%%')
                }

                if(this.getTicketInfo.isClose == true){
                    this.checkIfCanReopen(ObjData).then(()=>{
                        this.canReopen = true;
                        this.isLoading = false;
                    }).catch(()=>{
                        this.canReopen  = false;
                        this.isLoading  = false;
                    });
                }else{
                    this.isLoading = false;
                }
            })
            
        },
        data(){
            return {
                isLoading:  true,
                ViewKey:    0,
                message:    null,
                canReopen:  false,
                EmployeeID: null,
                TicketDesc:  null,
                informationArray: [],
                images:     [],
                imagesLoading: false,
            }
        },
        props:{
            ticketSelected: String,
            userType: String
        },
        computed:{
            ...mapState(['tickets','files']),
            ...mapGetters(['getTicketInfo','getTicketStatus','getImages']) 
        },
        methods: {
            ...mapActions(['fetchThisTicketInfo',
            'sendTicketMessage',
            'changeStatus',
            'changeWaiting',
            'changeStatus',
            'checkIfCanReopen',
            'fetchTicketProofImages',
            'uploadImgProof']),

            closeModal: function(){
                this.$refs['closeTicket'].hide();
                this.ViewKey++;
            },

            sendMessage(){
            this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                let objData = {
                    ticketSelected: this.ticketSelected,
                    message:        this.message
                }
                this.message = null;
                this.sendTicketMessage(objData).then(()=>{
                    this.ViewKey++
                })
            },
            saveNewImage(event){
                this.imagesLoading = true;
                let newFile = new FormData();
                newFile.append('image', event.currentTarget.files[0]);
                newFile.append('currentTicket', this.ticketSelected);
                this.uploadImgProof(newFile).then((response)=>{
                    console.log(response);
                    this.fetchTicketProofImages(this.ticketSelected).then((response)=>{
                        this.images = response.data.images;
                        this.imagesLoading = false;
                    })
                })
            },
            append() {
              this.$refs.uploadInput.click();
            },
            reOpenTicket(){
                this.$confirm("You'll be able to send messages after this", "Do you want to re-open this ticket?", "question").then((result) => {
                if (result){
                    let objData = { ticketSelected: this.ticketSelected }
                    this.changeStatus(objData).then(()=>{
                    this.$fire({
                        type: "success",
                        title: "Ticket Re-Opened",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(()=>{
                        this.$emit('updateAll', true);                
                    }); 
                    });
                }
                })
            },

            changeReply(){
                this.$confirm("You're about to change the reply assignation", "Are you sure?", "question").then((result) => {
                    if (result){
                        let objData = { ticketSelected: this.ticketSelected }
                        this.changeWaiting(objData).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Waiting user answer",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                            this.$emit('updateAll', true);                
                        }); 
                        });
                    }
                    })
            },

            hideModal: function(){
                this.$refs['EditModal'].hide();
                this.$refs['subModal'].hide();
                this.$refs['closeTicket'].hide();
                this.$emit('updateAll', true);
            }
        }
    }
</script>
<style scoped>
.btn .badge {
    top: 3px !important;
}
.primary{
    border: 2px solid #007bff !important;
}
.secondary{
 border-color: #6c757d;
}
.messages-body{
    text-align: justify;
    padding: initial;
    scroll-behavior:auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}
.cursor-def{
    cursor: default;
}
.disabled{
    background-color: rgb(136, 136, 136);
}

  li>ul                 { transform: translatex(100%) scale(0) }
  li:hover>ul           { transform: translatex(101%) scale(1) }
  li > button svg       { transform: rotate(-90deg) }
  li:hover > button svg { transform: rotate(-270deg) }

  .group:hover .group-hover\:scale-100 { transform: scale(1) }
  .group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
  .scale-0 { transform: scale(0) }
  .min-w-32 { min-width: 8rem }
</style>