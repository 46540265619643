<template>
  <div class="main">
    <b-container fluid>
      <b-row>
        <b-col align-self="center" class="mt-2 rounded shadow-sm" offset-md="2" md="8" lg="8" xl="8">
          <h1 class>Clocker Exam</h1>
        </b-col>
      </b-row>
    </b-container>
    <div class="container-fluid">
        <loading :active.sync="loadingFlag" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <div class="row">
            <div :class="{ hide: already_answered == true}" class="col-8 offset-2 mt-3 shadow p-3 bg-white rounded">
                <div>
                    <p style="font-style:italic">“Please read the following questions and select the best response. Note, you only have one attempt to complete this exam.”</p>
                </div>
                <div>
                    <form>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">1.- Which is the best browser to use clocker?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_one.$error }">
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q11" v-model="exam.question_one" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">Edge Chromium</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q12" v-model="exam.question_one" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Google Chrome</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q13"  v-model="exam.question_one" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Firefox</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q14"  v-model="exam.question_one" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Opera</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_one.required" class="invalid-feedback" >Please, pick an option for question one</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">2. What is the adress to access clocker?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_two.$error }">
                                <div class="col-3 col-lg-3 col-md-12">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" name='' v-model="exam.question_two" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">https://centrisinfo.net/Clocker</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_two" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">http://192.135.222.119:5000</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_two" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">http://198.135.222.119:5000</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_two" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">http://censtrisinfo.net/Clocker</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_two.required" class="invalid-feedback" >Please, pick an option for question two</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">3. You can only use clocker inside the Centris VDI or when working at the office?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_three.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="1">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">True</label>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="b">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">False</label>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_three.required" class="invalid-feedback" >Please, pick an option for question three</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">4. What should I do if I get an error when logging in?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_four.$error }" >
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Verify my password and username for extra spaces.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Check my password spelling since it is case sensitive.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Escalate to my supervisor.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">All of the above.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_four.required" class="invalid-feedback" >Please, pick an option for question four</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">5. Where can I begin my "phone" status?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_five.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Today's activity.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Start Activity.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Start Shift.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Start Interruption.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_five.required" class="invalid-feedback" >Please, pick an option for question five</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">6. If I close the clocker webpage or log out, my shift will end?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_six.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">True</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_six.required" class="invalid-feedback" >Please, pick an option for question six</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">7. The Clocker official Time will always match my computer time?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_seven.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">True</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_seven.required" class="invalid-feedback" >Please, pick an option for question seven</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">8. Clocker allows me to see how many hours are logged for me for the last day, week or month in the tab...?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_eight.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Today's activity.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Start Shift.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">User Settings.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">My Hours.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_eight.required" class="invalid-feedback" >Please, pick an option for question eight</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">9. The comments I set for my activities will be reviewed by Centris Management so it's important to keep them short, meaningfull and concise.</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_nine.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">True</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_nine.required" class="invalid-feedback" >Please, pick an option for question nine</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">10. If I am unable to use clocker on my pc...</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_ten.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">I wont be able to track my hours and I won't get paid.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">I should inmediately escalate to my supervisor.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">B and D.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">The BA team will be able to change my activities as needed real time.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_ten.required" class="invalid-feedback" >Please, pick an option for question ten</div> 
                        </div>
                        <div class="mt-5">
                            <button type="button" @click='storeInformation' class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Submit</button>
                        </div>
                        <input type="hidden" v-model="exam" value="">
                    </form>
                </div>
            </div>
            <div :class="{ hide: already_answered != true}" class="col-8 offset-2 mt-3 shadow p-3 bg-white rounded">
                <div>
                    <div class="alert alert-danger" role="alert">
                        You already answer this exam
                    </div>
                    <p class="font-weight-bold">Your final score is: <span style="text-decoration: underline;">{{this.getScore}}</span></p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: { Loading },
    computed: {
        ...mapState(['clocker']),
        ...mapGetters(['getScore', 'getExamStatus']),
    },
    validations: {
        exam: {
            question_one:   { required },
            question_two:   { required },
            question_three: { required },
            question_four:  { required },
            question_five:  { required },
            question_six:   { required },
            question_seven: { required },
            question_eight: { required },
            question_nine:  { required },
            question_ten:   { required },
        },
    }, 
     created(){
         this.confirmTerms();         
    },
    data(){
      return{
          exam: {},
          submitted: false,
          fullPage: true,
          color: "#F2F2F2",
          bgcolor: "#000000",
          loader: 'dots',
          already_answered: false,
          loadingFlag: true,
          exam_name: 'clocker_v1'
      }
    },
    methods: { 
        ...mapActions([
            'storeExamScore',
            'startExam',
            'checkExamStatus',
        ]),
        storeInformation: function(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$fire({
                    type: 'error',
                    title: 'Oops! there are some errors',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                })
                return;
            }
            let objectExam = {
                'exam_name': this.exam_name,
                'exam_answers': this.exam
            }
            this.storeExamScore(objectExam).then( res =>{
                this.$alert(
                    "Thanks for answer this exam. Your final score is " + res.data.score,
                    "You score has been saved successfully",
                    "success",
                    { allowOutsideClick: false, confirmButtonText: "Got it!" }
                ).then(() => {
                    this.$router.push('/dashboard');
                });
            }, error => {
                console.error("Error while updating the exam: "+ error)
            });
        },
        confirmTerms: function(){
            this.checkExamStatus(this.exam_name).then( () => {                
                if(this.getExamStatus == 'not_initialized'){
                    this.loadingFlag = false;
                    this.$confirm(
                        "You only have one attempt to answer this test. We will store your answers",
                        "Attention",
                        "warning", 
                        { allowOutsideClick: false }
                    ).then(() => {
                        //Store time when the user start
                        this.startExam(this.exam_name);
                    })
                    .catch(() => {
                        //if they clic on cancel, will be redirected to the dashboard
                        this.$router.push('/dashboard');
                    });
                }else if(this.getExamStatus == 'already_started'){
                    this.loadingFlag = false;
                }else if(this.getExamStatus == 'already_answered'){
                    this.loadingFlag = false;
                    this.already_answered = true;
                }
            }, error => {
                 this.loadingFlag = false;
                console.error("Error fetching the exam: "+ error)
            }); 
        }
    },
};
</script>

<style lang="scss" scoped>
    input[type="radio"], input[type="checkbox"] {
        cursor: pointer;
    }
    .invalid-feedback{
        font-weight: bold;
    }
    .hide{
        display: none !important;
    }
</style>