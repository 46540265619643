<template>
    <div class="border-l-2 border-gray-200">
        <select required class="bg-transparent hover:text-gray-600 focus:text-gray-600 w-32" v-model="selectedCampaign">  
          <optgroup label = "Select a Queue">
            <option hidden value="0">Campaign</option>
            <option v-for="(campaign,index) in getAllCampaigns" :key="index" :value="campaign">{{campaign}}</option>
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error: null,
            selectedCampaign : 0
        }
    },
    computed:{
        ...mapGetters(['getAllCampaigns']),
    },
    created(){
       this.fetchCampaigns().then(()=>{
           this.error =false
        });
    },
    methods:{
        ...mapActions(['fetchCampaigns'])
    },
    watch:{
      selectedCampaign: Debounce(function(){
          this.$emit('toggle',{
              key:'campaignName',
              value :this.selectedCampaign
          });
      },200)     

    }
}
</script>
