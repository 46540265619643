<template>
    <div class="rounded-lg bg-white p-3">
        <div class="flex flex-row align-items-center justify-content-start text-gray-300">
            <div class="flex-1 flex flex-row align-items-center justify-content-start group">
                <i class="bi bi-search"></i>
                <input type="text" v-model="text" class="uppercase p-2 flex-1 rounded-md hover:text-gray-600 focus:text-gray-600" placeholder="Employee ID...">
            </div>
            <div class="mx-2 border-2 border-gray-100 p-2 px-3 rounded-lg">
                <select v-model="form.site" required class="max-w-[200px] uppercase">
                    <option value="" hidden>Site...</option>
                    <option v-for="item in getLocations" :value="item" :key="item">{{item}}</option>
                </select>
            </div>

            <div class="mx-2 border-2 border-gray-100 p-2 px-3 rounded-lg">
                <select v-model="form.type" required class="max-w-[200px] uppercase">
                    <option value="" hidden>Document type...</option>
                    <option v-for="type in _documents_filters.types" :key="type.id" :value="type.id">{{ type.label }}</option>
                </select>
            </div>
                
            <div class="mx-2 border-2 border-gray-100 p-2 px-3 rounded-lg">
                <select v-model="form.status" required class="max-w-[200px] uppercase">
                    <option value="" hidden>Status...</option>
                    <option v-for="status in _documents_filters.status" :key="status" :value="status">{{ status }}</option>
                </select>
            </div>
            
            <div class="mx-2 border-2 border-red-100 bg-red-500 rounded-lg">
                <button type="button"  class="p-2 flex" @click="clear">
                    <span class="text-red-100 my-auto uppercase">
                        Clear
                    </span>
                    <svg class="text-red-100 w-5 h-5 my-auto" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd">
                        </path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import debounce         from 'debounce';
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return{
            text: '',
            form:{
                search: '',
                type:'',
                site: '',
                status:'pending'
            }
        }
    },
    computed: {
        ...mapGetters([
            '_documents_filters',
            'getLocations',
        ]),
    },
    methods:{
        ...mapActions([
            'fetchDocumentsFilters',
            'fetchSites',
        ]),
        clear:function(){
            this.text = null;
            this.form.type = '';
            this.form.site = '';
            this.form.status = '';
        },
    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle', this.form);
            },
            deep:true
        },
        text: debounce(function() {
            this.form.search = this.text;
        }, 1500)
    },
    components:{
    },
    mounted(){
        this.fetchDocumentsFilters();
        this.fetchSites().then(() => {
            this.$emit('toggle',this.form);
        })
    }

}
</script>