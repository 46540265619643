<template>
    <div>
        <div class="bg-white rounded-[10px] shadow-sm p-10 text-left">
            <div class="text-center">
                <h2 class="mb-6">{{ title }}</h2>
                <div class="block my-3 font-medium text-lg">
                    {{ message }}
                </div>
                <button v-if="is_available"
                    class="text-xl bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" type="button"
                    @click="startSurvey"><i class="bi bi-stopwatch mr-2"></i>Start</button>
            </div>
        </div>
        <div v-show="show_survey" class="fixed inset-0 bg-white p-10 z-50 overflow-auto">
            <img src="/img/centris-logo.png" alt="Logo" class="max-h-[70px] mx-auto mb-16">
            <h2 class="text-4xl text-blue-900 mb-10">{{ title }}</h2>
            <div class="container">
                <div class="rounded-full bg-gray-100 h-2 mb-10 overflow-hidden"><span class="block bg-c-primary h-full"
                        :class="'w-[' + ((100 / questions.length) * (formPosition + 1)) + '%]'"></span></div>
                <ol class="list-decimal-custom text-left">
                    <li :class="animation + ' before:content-[\'' + (formPosition + 1) + '\']'" v-if="conditionQuestion()">
                        <p>{{ questions[formPosition].text }}</p>
                        <input type="hidden" class="questionType" :value="questions[formPosition].type">
                        <div v-if="questions[formPosition].type == 'dropdown' || questions[formPosition].type === 'multiple'"
                            class="ml-14 mt-6">
                            <label v-for="(answer, i) in questions[formPosition].answers"
                                :key="'answer-' + formPosition + '-' + i" class="flex mb-0" :class="inputError">
                                <input v-if="questions[formPosition].type === 'dropdown'" type="radio"
                                    class="answer scale-125" :class="inputError" :value="answer"
                                    :name="'radio-group-' + formPosition" />
                                <input v-else-if="questions[formPosition].type === 'multiple'" type="checkbox"
                                    class="answer scale-125" :class="inputError" :value="answer"
                                    :name="'radio-group-' + formPosition" />
                                <p class="m-0 ml-2">{{ answer }}</p>
                            </label>
                        </div>
                        <div v-else-if="questions[formPosition].type === 'duration'" class="flex ml-6 mt-6">
                            <vue-timepicker :id="'duration-' + formPosition"
                                class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 mr-2"
                                format="HH:mm:ss" close-on-complete></vue-timepicker>
                        </div>
                        <div v-else class="flex ml-6 mt-6">
                            <input :type="questions[formPosition].type" value=""
                                :placeholder="questions[formPosition].type === 'number' ? '0' : '...'"
                                class="answer border-[1px] border-gray-200 rounded px-6 py-2 text-xl w-full"
                                :class="inputError">
                        </div>
                    </li>
                </ol>
                <div class="space-x-6 mt-10">
                    <button class="text-xl bg-blue-600 hover:bg-blue-700 text-white pl-8 pr-6 py-2 rounded-full"
                    :disabled="isSubmitting"
                        type="button" v-if="formPosition < questions.length - 1" @click="nextStep(false)">Next<i
                            class="bi bi-chevron-right ml-2"></i></button>
                    <button class="text-xl bg-blue-600 hover:bg-blue-700 text-white pl-8 pr-6 py-2 rounded-full"
                    :disabled="isSubmitting"
                        type="button" v-if="formPosition === questions.length - 1"
                        @click="nextStep(true)">Finish</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
    },
    data() {
        return {
            formPosition: 0,
            animation: 'animate-in',
            answers: [],
            inputError: '',
            isSubmitting: false,

            show_survey: false,
            title: 'Satisfaction Survey',
            interval: 0,
            attempts: 0,
            date_from: '',
            date_to: '',
            duration: '',
            instructions: '',
            type_quiz: 'quiz',
            site: '',
            campaign: '',
            jobtitle: '',
            message: '',
            is_available: false,
            questions: [
                {
                    parent: 0,
                    text: 'Assigned at birth Gender',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Male', 'Female']
                },
                {
                    parent: 0,
                    text: 'Birthdate',
                    type: 'date',
                    value: 0,
                    conditions: '',
                    answers: []
                },
                {
                    parent: 0,
                    text: 'What do you enjoy the best about your job?',
                    type: 'multiple',
                    value: 0,
                    conditions: '',
                    answers: ['Salary', 'Location', 'Work Environment', 'Development', 'Recognition Programas/events', 'Team', 'Company Events', 'Daily duties/Tasks', 'Organizational culture', 'Leadership', 'Weekly pay', 'Benefits']
                },
                {
                    parent: 2,
                    text: 'What is the benefit you like the most',
                    type: 'text',
                    value: 0,
                    conditions: 'Benefits',
                    answers: []
                },
                {
                    parent: 0,
                    text: 'How is the communication between you and your supervisor',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Excellent', 'Very Good', 'Average', 'Below Average', 'Poor']
                },
                {
                    parent: 0,
                    text: 'Please rate the communication between you and staff members (Supervisor, QA, AM)',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Excellent', 'Very Good', 'Average', 'Below Average', 'Poor']
                },
                {
                    parent: 0,
                    text: 'How satisfied are you with the Salary?',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Extremely Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'How satisfied are you with the Bonus',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Extremely Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'There is respect between my team members',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'I believe we as a company live our organizational values (Professionalism, Integrity, Passion, Responsibility, Kudos)',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'I feel confident with my actual product knowledge',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'How satisfied are you with the growth opportunities that Centris offers',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Extremely Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'How satisfied are you with the learning opportunities Centris provides',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Extremely Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'My Supervisor cares about my development',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'I feel motivated to do my best at work everyday',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'I see myself building a career in Centris',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'Would you recommend Centris to your family/friends',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'My Supervisor cares for me as a person',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'If there is a conflict in my area, I am sure my Account Manager and my Supervisor will find a fair solution',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'My Supervisor acknowledges when I do my work well',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'My QA acknowledges when I do my work well',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'My Account Manager cares for me as a person',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'How satisfied are you with the engagement activities in your team',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Extremely Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'Please support your previous answer with additional comments regarding the engagement activities.',
                    type: 'text',
                    value: 0,
                    conditions: '',
                    answers: []
                },
                {
                    parent: 0,
                    text: 'I have the tools and resources needed to do my job.',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'What would you recommend to improve your stay in our company (please be specific)',
                    type: 'text',
                    value: 0,
                    conditions: '',
                    answers: []
                },
                {
                    parent: 0,
                    text: 'Do you believe your opinion is valuable to the company?',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'I feel I am part of my team',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: "Do you feel you're able to maintain a reasonable balance between work and your personal life?",
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
                },
                {
                    parent: 0,
                    text: 'Overall, How satisfied are you working at Centris',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Extremely Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied']
                },
                {
                    parent: 0,
                    text: 'In the past 4 weeks, have you had an issue at work, due to an emotional problem, such as feeling depressed, sad or anxious?',
                    type: 'dropdown',
                    value: 0,
                    conditions: '',
                    answers: ['Yes', 'No', "I don't know"]
                },
                {
                    parent: 30,
                    text: 'Following up to the previous question, have you reach out to any of your leadership team to talk about this situation? And if so, please select with who?',
                    type: 'dropdown',
                    value: 0,
                    conditions: 'Yes',
                    answers: ['Supervisor', 'AM', 'Trainer', 'Wellness', 'QA', 'Engagement', 'HR', 'No one', 'N/A']
                },
                {
                    parent: 0,
                    text: 'Which of the following states do you experience regularly at work:',
                    type: 'multiple',
                    value: 0,
                    conditions: '',
                    answers: ['Engaged', 'Peaceful', 'Energetic', 'Sadness', 'Anger', 'Listlessness (lack of enthusiasm)', 'Anxious']
                },
                {
                    parent: 0,
                    text: 'Please provide any Final Comments you would like to express in this section.',
                    type: 'text',
                    value: 0,
                    conditions: '',
                    answers: []
                }
            ]
        }
    },
    computed: {
        ...mapGetters([
            'getSurveysV2Details'
        ])
    },
    created() {
        this.checkAvailabilitySatisfactionSurvey().then(() => {
            this.message = this.getSurveysV2Details?.message;
            this.is_available = this.getSurveysV2Details?.available;
        })
    },
    methods: {
        ...mapActions([
            'storeSatisfactionSurvey',
            'checkAvailabilitySatisfactionSurvey'
        ]),
        startSurvey() {
            this.show_survey = true;
        },
        closeSurvey() {
            this.show_survey = false;
            //this.$router.go(-1);
        },
        nextStep(final) {
            this.inputError = '';
            this.isSubmitting = true;
            let qType = document.getElementsByClassName("questionType")[0].value;
            let val = document.getElementsByClassName("answer")[0].value;
            let empty = true;
            if (qType === 'dropdown') {
                let thisAnswer = document.getElementsByClassName("answer");
                for (var i = 0; i < thisAnswer.length; i++) {
                    if (thisAnswer[i].checked) {
                        val = thisAnswer[i].value;
                        empty = false;
                    }
                }
            } if (qType === 'multiple') {
                var values = [];
                let thisAnswer = document.getElementsByClassName("answer");
                for (var m = 0; m < thisAnswer.length; m++) {
                    if (thisAnswer[m].checked) {
                        values.push(thisAnswer[m].value);
                        empty = false;
                    }
                }
                val = values;
            }
            if (qType === 'dropdown' && empty == false || qType === 'multiple' && empty == false || qType !== 'dropdown' && qType !== 'multiple' && val) {
                this.answers.push({ question: this.questions[this.formPosition].text, value: val });
                if (qType == 'dropdown' || qType == 'multiple') {
                    let q_answer = document.getElementsByClassName("answer");
                    for (var a = 0; a < q_answer.length; a++) {
                        q_answer[a].checked = false;
                    }
                } else {
                    document.getElementsByClassName("questionType")[0].value = '';
                }
                if (final) {
                    document.getElementById('pro-loading').style.display = 'flex';
                    let object = {
                        questions: this.answers
                    }
                    this.storeSatisfactionSurvey(object).then(() => {
                        this.$fire({
                            type: 'success',
                            title: "Survey has been stored",
                            text: 'You will be redirected',
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            document.getElementById('pro-loading').style.display = 'none';
                            this.$router.push({ name: 'base.dashboard' });
                        })
                    });
                } else {
                    this.animation = 'animate-out';
                    setTimeout(() => {
                        this.animation = 'animate-in';
                        this.formPosition++;
                        this.isSubmitting = false;
                    }, 600)
                }
            } else {
                this.inputError = 'border-red-600 text-red-600';
                this.isSubmitting = false;
            }
        },
        conditionQuestion() {
            let current = this.questions[this.formPosition];
            if (current.parent == 0) {
                return true;
            } else if (current.conditions == this.answers[current.parent]?.value) {
                return true;
            } else if (this.questions[current.parent].type == 'multiple' && this.answers[current.parent].value.includes(current.conditions)) {
                return true;
            } else {
                this.answers.push({ id: this.formPosition, value: '' });
                this.formPosition += 1;
                return false;
            }
        }
    },
    mounted() {
        let title = 'Surveys';
        this.$emit('set-title', title);
    }
}
</script>