<template>
    <div class="sm:max-w-lg sm:mx-auto flex-row align-items-center justify-content-center focus-within:text-gray-600 mt-3">
        <!-- page component -->
        <div class="p-3 px-5 bg-white rounded-md m-auto flex flex-row relative">
            <!-- search component -->
            <div class="focus-within:border-blue-400 border-b-2 border-gray-300 hover:border-blue-400 flex flex-1 py-1 pl-2">
                <input class="flex-1" type="text" placeholder="Device ID" v-model="device_id" pattern="[A-Za-z0-9]+" @keyup.enter="search()">
                <button type="button"  class="text-red-300 px-2" :class="{ 'opacity-0' : device_id === null}" @click="clear">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>

            <!-- group -->
            <button class="align-center group relative mx-2" @click="search()">
                <!-- tooltip -->
                <div class="absolute z-10 w-36 text-center bottom-full left-1/2 transform -translate-x-1/2">
                    <div class="p-0 mx-5">
                        <div class="opacity-0 group-hover:opacity-100 bg-black text-white text-xs rounded py-1 right-0 bottom-full">
                            search
                            <svg class="absolute h-2 w-full left-0 top-full" viewBox="0 0 255 255"><polygon points="0,0 127.5,127.5 255,0"/></svg>
                        </div>
                    </div>
                </div>
                <!-- element -->
                <div class="flex text-center justify-content-center">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
            </button>
        </div>
        <span class="text-xs">Write the id from the device and press enter or you can use the search icon</span>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
export default {
    data() {
        return {
            device_id: null
        }
    },
    computed:{
        ...mapGetters(['getDeviceInfo']),
    },
    methods:{
        ...mapActions(['searchDevice']),

        search: function() {
            if (this.device_id === null || this.device_id === '' || this.device_id === this.getDeviceInfo?.deviceId) return;
            this.$emit('updateAll', true);
            let id = this.device_id.toUpperCase();
            this.searchDevice(id).then(()=>{
                this.$emit('updateAll', true);
            })
        },

        clear(){
            if (this.device_id === null || this.device_id === '') return;
            // this.$emit('updateAll', true);
            this.device_id = null;
            // this.searchDevice(this.device_id);
        }
    },
    watch: {
        device_id(value) {
            if (this.device_id === null) return;
            this.device_id = value.replace(/\s/gi && /[^a-zA-Z0-9-_]+/gi, '');
        }
    },
    created() {
        this.search();
    }
}
</script>
<style scoped>
input { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>