<template>
    <div>
      <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

                <div class="flex items-start gap-4">
                  <div class="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-check h-6 w-6 text-red-600" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                    </svg>
                  </div>
                  <div class="text-left my-auto">
                    <h3 class="text-base font-semibold leading-6 text-gray-900 text-left justify-center my-auto" id="modal-title">Add a new subject for this training class, it will be added to all trainees to score</h3>
                  </div>
                </div>
                
                <div class="space-y-4 p-3">
                  <div class="text-left">
                    <span class="text-sm text-gray-500">Write the subject of this score</span>
                    <textarea v-model="subject" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2" rows="1"></textarea>
                    <div class="flex flex-row w-full">
                        <pill :dirty="$v.subject.$dirty" :valid="!$v.subject.$invalid">
                          Min: 2 chars., max of 45 chars.
                        </pill>
                    </div>
                  </div>
                </div>

              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto my-auto"
                @click="answer(true)">
                  Continue
                </button>
  
                <button class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto my-auto"
                @click="answer(false)">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Pill from '@/views/mockups/ValidationPill';
export default {
    data () {
        return {
            subject: "",
        }
    },
    validations () {
        return {
            subject: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
        }
    },
    components: {
        Pill,
    },
    methods: {
        answer (status) {
            if (status) {
                this.$v.$touch();
                if(this.$v.$invalid) return;
            }
            let obj = {
                confirmation: status,
                subject: this.subject,
            }
            this.$emit('toggle', obj);
        },
    },
}
</script>