<template>
    <div>
        <div class="bg-white rounded-[10px] shadow-sm p-10 text-left">
            <div class="text-center">
                <h2 class="mb-6">{{ title }}</h2>
                <div v-if="type_quiz=='quiz'">
                    <div class="flex space-x-6">
                        <div><b>Duration (minutes):</b> {{ duration }}</div>
                    </div>
                    <h2 class="text-3xl">Instructions</h2>
                    <p class="text-gray-500 italic px-12 mb-10">{{ instructions }}</p>
                </div>
                <div class="block my-2 text-semibold text-lg">
                    {{ getSurveysV2Message }}
                </div>
                <button v-if="canStartSurvey" class="text-xl bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" type="button" @click="handleStartSurvey"><i class="bi bi-stopwatch mr-2"></i>Start Survey</button>
            </div>
        </div>
        <div v-if="show_survey" class="fixed inset-0 bg-white p-10 z-50 overflow-auto">
            <img src="/img/centris-logo.png" alt="Logo" class="max-h-[70px] mx-auto mb-16">
            <h2 class="text-4xl text-blue-900 mb-20">{{ title }}</h2>
            <div class="container">
                <!-- <MultiForm :questions="questions" :id="id_surveyemp" @close="closeSurvey"></MultiForm> -->
                <view-survey :questions="questions" />
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    // import MultiForm from '../surveys/MultiForm'
    import ViewSurvey from '../surveys/viewSurvey'
    export default {
        components: {
            // MultiForm,
            ViewSurvey
        },
        data(){
            return {
                show_survey: false,
                title: '',
                interval: 0,
                attempts: 0,
                date_from: '',
                date_to: '',
                duration: '',
                instructions: '',
                type_quiz: 'quiz',
                site: '',
                campaign: '',
                job_title: '',
                questions: [],
                id_surveyemp: 0
            }
        },
        computed: {
            ...mapState([
            ]),
            ...mapGetters([
                'getSurveysV2Details',
                'getSurveysV2Message'
            ]),

            canStartSurvey () {
                return this.getSurveysV2Message?.toLowerCase() === 'you can make an attempt now.';
            }
        },
        created() {
            if(this.$route.params.id) {
                this.fetchDetailsSurveys(this.$route.params.id).then(() => {
                    this.title = this.getSurveysV2Details?.title;
                    this.interval = this.getSurveysV2Details?.interval;
                    this.attempts = this.getSurveysV2Details?.attempts;
                    this.date_from = this.getSurveysV2Details?.date_from?.split(' ')[0];
                    this.date_to = this.getSurveysV2Details?.date_to?.split(' ')[0];
                    this.duration = this.getSurveysV2Details?.duration;
                    this.instructions = this.getSurveysV2Details?.instructions;
                    this.type_quiz = this.getSurveysV2Details?.type;
                    this.questions = this.getSurveysV2Details?.questions.sort((a, b) => a.index - b.index);
                    document.getElementById('pro-loading').style.display = 'none';
                });
            }
        },
        methods: {
            ...mapActions([
                'fetchDetailsSurveys',
                'initSurvey',
            ]),
            handleStartSurvey: function() {
                document.getElementById('pro-loading').style.display = 'flex';
                if(this.$route.params.id) {
                    let dataObject = new FormData();
                    dataObject.append('id', this.$route.params.id);
                    this.initSurvey(dataObject).then((res) => {
                        this.id_surveyemp = res?.id
                        this.show_survey = true;
                        document.getElementById('pro-loading').style.display = 'none';
                    }).catch((err) => {
                        document.getElementById('pro-loading').style.display = 'none';
                        this.$fire({
                            type: 'error',
                            title: err?.msg
                        })
                    })
                }
            },
            closeSurvey(value) {
                this.show_survey = value;
                this.$router.go(-1);
            },
        },
        mounted() {
            if(this.$route.params.id) {
                document.getElementById('pro-loading').style.display = 'flex';
            }
            let title = 'Survey';
            this.$emit('set-title',title);
        }
    }
</script>