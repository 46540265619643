<template>
    <div class="border-l-2 border-gray-200">
        <select required class="bg-transparent hover:text-gray-600 focus:text-gray-600 w-32" v-model="selectedStatus">  
          <optgroup label = "Select a status">
            <option hidden value="null">Choose a status</option>
            <option value="0">Open</option> 
            <option value="1">Closed</option> 
          </optgroup>
        </select>
      </div>
</template>

<script>
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            selectedStatus: 0
        }
    },
    watch:{
      selectedStatus: Debounce(function(){
          this.$emit('toggle',{
              key:    'isClosed',
              value : this.selectedStatus
          });
      },200)     

    }
}
</script>
