<template>
    <div class="flex flex-row m-auto">
        <label class="col-sm-2 col-form-label text-xs">
            <a class="text-danger">*</a>Week:
        </label>
        <div class="col-sm-10">
            <div class="">
                <select class="text-xs bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-gray-700 focus:text-gray-700 h-8 mb-1"
                v-model="weekSelected">
                    <option :value="index" :key="index" v-for="index in 52">{{index}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
export default {
    data() {
        return {
            weekSelected: null,
        }
    },
    methods:{
        getWeekNumber: function(d) {
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())-1);
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
            return [d.getUTCFullYear(), weekNo];
        },
    },
    created(){
        let result = this.getWeekNumber(new Date());        
        this.weekSelected = result[1] > 1 ? result[1] - 1 : result[1];
    },
    watch:{
        weekSelected:debounce(function(){
            this.$emit('toggle',{key:'weekSelected', value: this.weekSelected});
        },200)
    },
}
</script>

<style scoped>
</style>