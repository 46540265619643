<template>
<div class="w-full">
     <material-loader class="px-3" v-if="isLoading"/>
     <select :disabled="true" v-else-if="this.currentDepartment === null" class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2">
        <option hidden value="">Choose a department first...</option>
     </select>
    <select v-else class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2" v-model="currentCampaign" @change="emitStatus"  required>
        <option hidden value="null">Choose a campaign...</option>
        <option v-for="(item,index) in getCampaigns" :key="index" :value="item.id">
            {{item.label}} 
        </option>
    </select>  
</div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import MaterialLoader from '@/views/mockups/MaterialLoader'
export default {
    components:{
        MaterialLoader
    },
     props:{
        department:[Number,String],
        val:[String, Number]
    },
    data(){
        return{
            currentCampaign:"",
            currentDepartment: null
        }
    },
   
    methods:{
        ...mapActions(['fetchCampaignsByDepartment']),
        emitStatus:function(){
           this.$emit('toggle',this.currentCampaign);
        }
    },
    computed:{
        ...mapGetters(['getCampaigns','getDefinitionStatus']),
        isLoading:function(){
            return this.getDefinitionStatus == 'loadingCampaigns';
        },    
    },
 
    watch:{
        department:function(val){            
            this.currentDepartment = val; 
            this.currentCampaign = null;   
            this.fetchCampaignsByDepartment(this.currentDepartment).then(()=>{              
                this.$emit('toggle',this.currentCampaign);         
            });            
        },
        
    },
    created(){        
        this.currentDepartment = this.department;  
        if(this.currentDepartment !== null){
            this.fetchCampaignsByDepartment(this.currentDepartment).then(()=>{
                this.currentCampaign = this.val
            }).then(()=>{
                this.$emit('ready');
            });
        }
    }
}
</script>