<template>
    <div>
        <div><span>New Datepicker Configuration</span></div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-md ml-2 block group-focus:text-gray-600">Datepicker Title Label:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newDatepicker.labelTitle">
        </div>
        <div class="w-full py-2">
            <button @click="sendNewComponent" class="ml-auto px-3 p-2 bg-gray-700 text-white shadow-sm rounded-sm w-full">Save</button>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
export default {
    data(){
        return{
            newDatepicker:{
                component: 'datepicker',
            },
        }
    },
    methods:{
        sendNewComponent: function(){
            EventBus.$emit('addNewComponent', this.newDatepicker);
        },
    },
}
</script>
<style scoped>

</style>
