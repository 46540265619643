<template>
    <div class="h-full container-fluid flex flex-1">
        <loading :active.sync="getFlagLoadingFeedback" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>

        <div class="w-1/3 py-2 px-5 flex flex-col h-full overflow-y-scroll">
            <MyFeedbackList :key="ListKey" @feedback_selected='loadFeedback'></MyFeedbackList>
        </div>

        <div v-if="feedbackLoaded" class="w-2/3 py-2 px-5 flex flex-col h-full overflow-hidden">
            <div class="rounded-md card p-2">

                <h5 class="bg-gray-200 rounded-md border-gray-300 border-2 shadow-sm py-3"> {{this.feedbackInfo.form_type}} </h5>

                <div class="bg-gray-100 overflow-y-scroll">
                    <h4 class="font-bold size-xl bg-gray-200 p-2">
                        Information:
                    </h4>
                    <label class="text-left px-4">
                        <p class="font-bold size-lg">
                            Auditor: {{this.feedbackInfo.auditor}}
                        </p>
                        <p class="font-bold size-lg">
                            Created At: {{this.feedbackInfo.created_at| moment("MM-DD-YYYY HH:mm:ss")}}
                        </p>
                        <p class="font-bold size-lg">
                            Feedback ID: {{this.audit_id}}
                        </p>
                    </label>

                    <h4 class="font-bold size-xl bg-gray-200 p-2">
                        Details:
                    </h4>
                    <div class="bg-gray-100">
                        <p class="text-left px-4 bg-gray-100" v-for="(feedback, index) in getFeedback" :key="feedback.index">
                        <span class="font-bold size-lg">
                            {{index+1}}. {{feedback.questionLabel}}
                        </span><br>
                        <span>
                            {{feedback.answer}}
                        </span>
                        </p>
                    </div>
                </div>

                <div class="">
                    <button v-b-modal="'sendMessageModal'" 
                    class="mt-3 mb-2 uppercase inline-flex justify-center py-1 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <div class="uppercase font-sans font-bold text-white text-center justify-center items-center p-2">Acknowledgement</div>
                    </button>

                    <b-modal size="lg" ref="sendMessage" :id="'sendMessageModal'" hide-footer centered no-close-on-backdrop>
                        <span class="text-left font-weight-bold"><a class="text-danger">*</a>Comments:</span>

                        <div v-if="this.commentsLoaded == null">
                            <textarea class="form-control" rows="3" style="resize: none;" :class="{'is-invalid': isAcknowledge && $v.agentComment.$error }" v-model="agentComment" placeholder="Write any comment here" ></textarea>
                            <div v-if="isAcknowledge && !$v.agentComment.required" class="invalid-feedback" >Please add a comment.</div>
                            <div v-if="isAcknowledge && !$v.agentComment.alphaWspace" class="invalid-feedback" >Only letters are supported.</div>
                        </div>
                        <div v-else>
                            <div class="p-3 mb-2 bg-light text-dark" >{{this.commentsLoaded}}</div>
                        </div>

                        <div class="text-right" v-if="this.commentsLoaded == null">
                            <button type="button" @click="sendInfo" 
                            class="right mt-2 uppercase inline-flex justify-center py-2 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Send Comments</button>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
        <div v-else-if="isLoading" class="w-2/3 p-5 flex flex-col h-full overflow-hidden">
            <div class="text-center">
                <p class="font-italic font-weight-bold">Loading...</p>
                <p class="font-italic font-weight-bold">
                    <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                    <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                    <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
                </p>              
            </div>
        </div>
    </div>

</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import MyFeedbackList   from "@/views/components/Feedback/List/my_feedback_list"
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: "",
        components: { MyFeedbackList, Loading },
        data(){
            return{
                fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isDisabled: true,
                isAcknowledge: false,
                ListKey: 0,
                isLoading: false,
                feedbackLoaded: false,
                agentComment: '',
                commentsLoaded: '',
                audit_id: '',
                feedbackInfo: null,
            }
        },
        validations: {
            agentComment: { required},
        }, 
        computed:{
            ...mapState(['feedbacks']),
            ...mapGetters(['getMyFeedbacks','getFeedback', 'getFlagLoadingFeedback']) 
        },
        methods:{
            ...mapActions(['fetchFeedbackByAuditID','addComments']), 
            loadFeedback: function(data){
                this.feedbackLoaded = false;
                this.isLoading = true;
                this.agentComment = '';
                this.audit_id = ''
                this.feedbackInfo = null;
                this.fetchFeedbackByAuditID(data.id).then(() => {
                    this.feedbackLoaded = true;
                    this.isLoading = false;
                    this.audit_id = data.id;
                    this.commentsLoaded = data.agent_comment;
                    this.feedbackInfo = data;
                }, error => {
                    console.error("Error fetching the feedback: "+ error)
                });
                
            },
            sendInfo: function(){
                this.isAcknowledge = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$fire({
                        type: 'error',
                        title: 'Oops! there are some errors',
                        showConfirmButton: true
                    })
                    return;
                }
                this.$store.commit('setLoadingFlag',true);
                const data = {
                    'audit_id': this.audit_id,
                    'agent_comment': this.agentComment
                }
                this.addComments(data).then(() => {
                    this.$fire({
                        type: 'success',
                        title: 'Comment Sent Successfully',
                        showConfirmButton: false,
                        timer: 1300
                    }).then(()=>{
                        this.$refs['sendMessage'].hide();
                        this.ListKey++;
                        this.feedbackLoaded = false;
                        // location.reload();               
                    });
                }, error => {
                    console.error("Error during store the comment: "+ error)
                });

            },
        },
        mounted(){
          let title = 'My feedbacks';
          this.$emit('set-title',title);
        }
    };

</script>
<style>
    .margins{
        margin-top: 1em !important;
        margin-left: 16% !important; 
        margin-right: 16% !important;
    }
    .card{
        max-height: 100% !important;
    }
    .full-height {
        min-height: 100% !important;
        height: 100% !important;
    }
    ::-webkit-scrollbar {
    width: 0.325rem;
    padding:5px;
    }

    ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 50px !important;
    background-color: #bdbdbd;
    }

    ::-webkit-scrollbar-track {
    border-radius:5px;
    background-color:rgba(0,0,0,0.075);
    }
</style>