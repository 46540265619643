<template>
    <div class="bg-white rounded-[10px] shadow-sm p-10 text-left">
        <div class="flex space-x-10">
            <div class="w-2/3 border rounded mb-4">
                <div class="flex flex-row flex-wrap border-bottom">
                    <div class="grow p-3 border-right">
                        <label class="text-xs mb-0 uppercase">Title</label>        
                        <input class="w-full" v-model="form.title" @keyup="$v.form.title.$touch()" placeholder="...">        
                        <div class="relative w-full" v-if="$v.form.title.$dirty" >
                            <p class="text-red-600 text-xs mb-0 mt-1" v-if="$v.form.title.$invalid">This field is mandatory</p>
                            <p class="text-green-600 text-xs mb-0 mt-1 mb-0 mt-1" v-else>Looks great.</p>
                        </div>
                    </div>
                    <div class="w-1/4 p-3">
                        <label class="text-xs mb-0 uppercase">Openings available</label>        
                        <input class="w-full" type="number" v-model="form.available" @keyup="$v.form.available.$touch()" placeholder="0">        
                        <div class="relative w-full" v-if="$v.form.available.$dirty" >
                            <p class="text-red-600 text-xs mb-0 mt-1" v-if="$v.form.available.$invalid">This field is mandatory</p>
                            <p class="text-green-600 text-xs mb-0 mt-1" v-else>Looks great.</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap">
                    <div class="w-1/2 p-3">
                        <label class="text-xs mb-0 uppercase">Role description</label>        
                        <textarea class="w-full" v-model="form.description" @keyup="$v.form.description.$touch()" rows="10" placeholder="..."></textarea>
                        <div class="relative w-full" v-if="$v.form.description.$dirty" >
                            <p class="text-red-600 text-xs mb-0 mt-1" v-if="$v.form.description.$invalid">This field is mandatory</p>
                            <p class="text-green-600 text-xs mb-0 mt-1" v-else>Looks great.</p>
                        </div>
                    </div>
                    <div class="w-1/2 p-3 border-left">
                        <label class="text-xs mb-0 uppercase">Qualifications Required</label>        
                        <div class="relative  mb-1 sm:w-full px-2 mb-2">                           
                            <input class="w-full" v-model="currentQualification" @keypress.enter="addQualification" placeholder="...">        
                            <p class="text-gray-400 text-xs text-left pt-2" >Fill the text box and press "enter" to add a Qualification to the list.</p>
                            <div class="pl-5 pr-3">
                                <ul class="list-none md:list-disc">                                
                                    <li class="capitalize text-left" v-for="(item,index) in form.qualifications" :key="index">
                                        <div class="capitalize group flex flex-row align-center justify-around w-full py-1">
                                            {{item}}
                                            <button class="text-gray-300 hover:text-red-500 ml-auto" @click="form.qualifications.splice(index,1)">
                                               <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/3">
                <label class="text-xs mb-2 uppercase">Announcement (Optional)</label>
                <upload-files @changed="handleImages" :isMultiple='isMultiple' :maxFiles="1" maxError="the max size of the files must be less than 2MB"></upload-files>
            </div>
        </div>
        <div class="text-center">
            <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" @click.prevent="submit">Save</button>
        </div>
    </div>
</template>
<script>
import {required}   from 'vuelidate/lib/validators'
import {mapActions} from 'vuex'
import uploadFiles  from '@/views/Utilities/upload_files/index'
export default {
    beforeMount(){
        this.$emit('set-title', 'New Position');
    },
    components: { 
        'upload-files' : uploadFiles,
    },
    data(){
        return{
            form:{
                title:'',
                description:'',
                available: '',
                qualifications:[],
            },
            isMultiple: false,
            fileImage: null,
            images: null,
            currentQualification:''
        }
    },
    validations:{
        form: {
            title: {
                required
            },
            available: {
                required
            },
            description: {
                required
            },            
        }
    },
    methods:{
        ...mapActions(['savePosition']),
        addQualification:function(){
            if(this.currentQualification.length <=0){
                return;
            }
            this.form.qualifications.push(this.currentQualification);
            this.currentQualification =  null;
        },
        submit:async function(){
            if(!this.passedValidation()){
               return this.showValidationErrorMessage(
                    'Some mandatory fields are missing. Please check again.'
                );  
            }
            //Si no hay ningun error guardamos.
            if(!this.emptyQualifications){
                return this.save();
            }
            
            //Si confirma que deasea guardar sin qualifications guardamos.
           this.$confirm(
                "Save without qualifications?",
                 "You're about to create a new position without qualifications.Do you want to continue?",
                  "warning"
                )
            .then(()=>{
                this.save();
            }).catch(()=>{
                
            });
        },
        passedValidation:function(){
            this.$v.form.$touch();
            
            return !this.$v.form.$invalid;
        },
        showValidationErrorMessage:function(message, type = 'error'){
             this.$fire({
              type: type,
              title: message,
              showConfirmButton: true
            });  
        },
        save: function(){
            let dataObject = new FormData();
            dataObject.append('title', this.form.title);
            dataObject.append('description', this.form.description);
            dataObject.append('available', this.form.available);
            dataObject.append('qualifications', JSON.stringify(this.form.qualifications));
            dataObject.append('image', this.fileImage);
            this.savePosition(dataObject)
                .then(()=>{
                    this.$fire({
                        type: 'success',
                        title:`The positon has been stored successfully`,
                        showConfirmButton: true,
                });
            })         
            .catch((error)=>{
              let message =  error.response.data.message ?? 'Something went wrong';
            
              this.$fire({
                  type: 'error',
                  title:`Something went wrong.Error message: ${message}`,
                  showConfirmButton: true,                
              });      
            }).then(()=>{
                this.$router.push({name:'positions.index'});
            });
        },
        readAsDataURL(file) {
            return new Promise(function (resolve, reject) {
                let fr = new FileReader();
                fr.onload = function () {
                    resolve(fr.result);
                };
                fr.onerror = function () {
                    reject(fr);
                };
                fr.readAsDataURL(file);
            });
        },
        handleImages(files) {
            let readers = [];
            this.fileImage = files[0];
            readers.push(this.readAsDataURL(files[0]));
            Promise.all(readers).then((values) => {
                this.images = values;
            });
        },
    },
    computed:{
        emptyQualifications:function(){
            return this.form.qualifications.length <= 0;
        }
    }
}
</script>