<template>
      <div class="bg-white rounded-[10px] shadow-sm overflow-hidden">
        <div class="bg-centris-secondary text-right text-white leading-none px-10 pt-6 pb-4">
          <h1 class="opacity-30 mb-0">{{welcome_message}}</h1>
          <img class="float-left h-24 w-24 -mt-4 border shadow border-white rounded-full" src="https://placekitten.com/300/300" alt="">
          <h5 class="text-[120%] whitespace-nowrap text-ellipsis overflow-hidden mb-0">{{ fullName }}</h5>
          <p class="text-[110%] mb-0"><i>{{ infoEmployee.Title }}</i></p>
        </div>
        <div class="px-10 pt-8 pb-6 text-left leading-none">
          <div class="grid grid-cols-7 gap-4">
            <div><h6 class="mb-0">{{ this.tenure }} Day(s)</h6><small class="text-gray-400">Tenure</small></div>
            <div class="col-start-2 col-end-4"><h6 class="mb-0">{{ infoEmployee.Campaign +'-'+ infoEmployee.Project }}</h6><small class="text-gray-400">Campaign/Project</small></div>
            <div><h6 class="mb-0">{{infoEmployee.Location}}</h6><small class="text-gray-400">Location/Site</small></div>
            <div><h6 class="mb-0">{{infoEmployee.LOB}}</h6><small class="text-gray-400">L.O.B.</small></div>
            <div class="col-start-6 col-end-8"><h6 class="mb-0">{{infoEmployee.ReportTo}}</h6><small class="text-gray-400">ReportTo</small></div>
            <div><h6 class="mb-0">{{infoEmployee.Team}}</h6><small class="text-gray-400">Team</small></div>
            <div class="col-start-4 col-end-8"><h6 class="mb-0">{{ infoEmployee.CertificationDate ? infoEmployee.CertificationDate:'N/A'}}</h6><small class="text-gray-400">Certification Date</small></div>
          </div>
        </div>
      </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import moment       from 'moment';
    import auth                                 from "@/config/user"
    export default {
        components: {
        },
        data() {
            return {
                tenure: '',
                'welcome_message': 'Welcome!',
                fullName: '',
            };
        },
        computed:{
            ...mapState(['employees']),
            ...mapGetters(['infoEmployee'])
        },
        methods:{
            ...mapActions(['loadMyProfileByEmpId']),
            getTenure(){
              var start = moment(new Date(this.infoEmployee.HireDate), "YYYY-MM-DD");
              var end =  moment(new Date(), "YYYY-MM-DD");
              this.tenure = end.diff(start,'days');
            },
        },
        created(){
            this.loadMyProfileByEmpId(auth.currentUser.EmpID).then(()=>{
              this.getTenure();
              this.fullName = this.infoEmployee.FirstName+' '+ (this.infoEmployee.MiddleName??'')+' '+this.infoEmployee.LastName1+' '+(this.infoEmployee.LastName2??'')
            });
        },
    }
</script>
<style scoped> 
</style>