<template>
    <div>
        <modal :show='open_modal'>           
            <edit-headcount @toggle="toggleModal" :info="info" v-if="edit_modal"/>
            <delete-headcount @toggle="toggleModal" :info="info" v-if="delete_modal"/>
        </modal>
        <div class="border-0 w-64">
            <div class="radiogroup flex text-center whitespace-nowrap border-2 border-blue-600 text-blue-600">
                <input type="radio" v-model="filter_status" value="filtered" id="sr-pending" @change="getHeadcountList" /><label class="flex-1" :class="filter_status=='filtered' ? 'bg-blue-600':''" for="sr-pending"><i class="bi bi-clock-history"></i> No zero</label>
                <input type="radio" v-model="filter_status" value="unfiltered" id="sr-approved" @change="getHeadcountList" /><label class="flex-1" :class="filter_status=='unfiltered' ? 'bg-blue-600':''" for="sr-approved"><i class="bi bi-check2"></i> All</label>
            </div>
        </div>
        <div class="overflow-y-scroll main p-2 shadow-drop-lg">
          <table class="light-table p-2 table-fixed overflow-hidden">
              <thead>
                <tr class="bg-gray-700">
                  <th class="text-white">
                      Campaign
                  </th>
                  <th class="text-white">
                      LOB
                  </th>
                  <th class="text-white whitespace-normal">
                    Approved Full Time Equivalent
                 </th>
                  <th class="text-white whitespace-normal">
                      Total Full Time Equivalent
                  </th>
                  <th class="text-white">
                    actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in getHeadcount" :key="i.Id" class="hover:bg-gray-300">
                    <td class="border-b-4 border-gray-50 text-ellipsis overflow-hidden whitespace-normal uppercase" :title="i.Campaign">
                        {{ i.Campaign }}
                    </td>
                    <td class="border-b-4 border-gray-50 text-ellipsis overflow-hidden whitespace-normal uppercase" :title="i.LOB">
                        {{ i.LOB }}
                    </td>
                    <td class="border-b-4 border-gray-50">
                        {{ i.Approved_FTEs }}
                    </td>
                    <td class="border-b-4 border-gray-50">
                        {{ i.FTE_Size }}
                    </td>
                    <td class="border-b-4 border-gray-50 space-x-2">
                        <button @click="editHeadcount(i)" class="justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 space-y-2">
                            Edit <i class="bi bi-pencil-square"></i>
                        </button>
                        <button @click="deleteHeadcount(i)" class="justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 space-y-2">
                            Delete <i class="bi bi-x-circle"></i>
                        </button>
                    </td>
                </tr>
              </tbody>
          </table>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex'
    import Modal from '@/views/Utilities/Modal'
    import EditHeadcount from '@/views/headcount/EditHeadcount'
    import DeleteHeadcount from '@/views/headcount/DeleteHeadcount'
    export default{
        components: {
            Modal,
            EditHeadcount,
            DeleteHeadcount
        },
        data(){
            return {
                open_modal: false,
                info:[], 
                edit_modal: false,
                delete_modal: false,
                filter_status: 'filtered'
            }
        },
        computed:{
            ...mapGetters([
                'getHeadcount'
            ])
        },
        methods:{
            ...mapActions([
                'fetchHeadcount'
            ]),
            toggleModal(data){
                this.info={
                    campaign: data.Campaign,
                    lob: data.LOB
                }
                this.open_modal=!this.open_modal;
                if(!this.open_modal){
                    this.edit_modal= false;
                    this.delete_modal= false;
                    this.getHeadcountList();
                }
            },
            deleteHeadcount(data){
                this.delete_modal= true;
                this.toggleModal(data);
            },
            editHeadcount(data){
                this.edit_modal= true;
                this.toggleModal(data);
            },
            getHeadcountList(){
                this.fetchHeadcount(this.filter_status);
            }

        },
        mounted(){
            let title = 'Headcount Management';
            this.$emit('set-title',title);
        },
        created(){
            this.getHeadcountList();
        }
    }

</script>
<style scoped>
.main {
        max-height: 70vh !important;
    }

  ::-webkit-scrollbar {
    width: 0.3 rem;
    height: 0.35 rem;
    padding:5px;
    }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 50px !important;
    background-color: #bdbdbd;
    }

  ::-webkit-scrollbar-track {
    border-radius:5px;
    background-color:rgba(0,0,0,0.075);
    }
    .radiogroup {
    margin: 10px 0;
    border-radius: 30px;
    }
    .radiogroup input[type="radio"] {
        display: none;
    }
    .radiogroup input[type="radio"] + label {
        display: inline-block;
        line-height: 2.3;
        padding: 0 25px;
        border-radius: 20px;
        margin: 0;
        cursor: pointer;
    }
    .radiogroup input[type="radio"]:checked + label {
        color: #fff;
    }
</style>