<template>
    <div class="main space-y-4">
        <div class="flex space-x-4" v-if="loaded">
            <div class="w-1/3 h-[600px] mx-auto bg-gray-50 rounded-xl p-2">
                <time-line :shifts="getClockerHours" />
            </div>
            <shifts-list
                @refresh="keepAlive"
                :events="getClockerHours"
                :employee="employee"
                class="w-2/3"
            />
        </div>
        <div v-else class="opacity-70">
            <illustration media="search_engines">
                <template slot="headline">Select an employee first</template>
                <template slot="subline">
                    Search for an employee to view their shift history for
                    today.
                </template>
            </illustration>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TimeLine from "@/views/clocker/adjustments/components/TimeLine";
import ShiftsList from "@/views/clocker/adjustments/components/ShiftsList";
import Illustration from "@/views/mockups/IllustrationTemplate";
import auth from "@/config/user";
export default {
    components: { TimeLine, ShiftsList, Illustration },
    computed: {
        ...mapGetters(["getClockerHours", "employeeList"]),
        loaded() {
            return this.getClockerHours;
        },
        employee() {
            return auth.currentUser.EmpID;
        },
    },
    methods: {
        ...mapActions(["fetchClockerHours"]),
        keepAlive() {
            if (auth.currentUser.EmpID) {
                this.fetchClockerHours(auth.currentUser.EmpID);
            }
        },
    },
    created() {
        this.keepAlive();
    },
    mounted() {
        let title = "Today's Activities";
        this.$emit("set-title", title);
    },
};
</script>