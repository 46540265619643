<template>
    <tr class="hover:bg-gray-100 dark:hover:bg-gray-700" v-if="!loading">
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
            <div>
               
                <select v-model="form.trainee_id" required class="border-b-4 border-gray-200 rounded-sm focus:border-blue-400 bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" >
                    <option hidden value="">Select a trainee...</option>
                    <option v-for="itm,id in _trainees" :key="'trainee' + id" :value="itm.trainee_id">{{itm.trainee_id}}</option>
                </select>
                <span class="text-red-400 block pl-3 mt-2 text-xs"  v-if="$v.form.$dirty && $v.form.trainee_id.$invalid">
                    This field is mandatory*.
                </span>
                <span class="text-green-500 block pl-3 mt-2 text-xs" v-if="$v.form.$dirty && !$v.form.trainee_id.$invalid">
                    Valid 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </span>
            </div>
        </td>
            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left w-1/2">
                <div>
                <input  v-model="form.comment" placeholder="Place your comment here" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-700">
                <span class="text-red-400 block pl-3 mt-2 text-xs"  v-if="$v.form.$dirty && $v.form.comment.$invalid">
                    This field is mandatory*.
                </span>
                <span class="text-green-500 block pl-3 mt-2 text-xs" v-if="$v.form.$dirty && !$v.form.comment.$invalid">
                    Valid 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </span>
            </div>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
            {{this.emp_id}}
        </td>

        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
            <div class="flex flex-row items-center justify-end">
                    <button @click.prevent="newAttrition" :disabled="saving" class="px-3 py-2 bg-gray-700 rounded-sm text-white">
                        <span v-if="!saving">
                            Save
                        </span>

                         <span v-else>
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 h-4 inline-block animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                        </span>
                        
                    </button>
            </div>
        </td>
    </tr>
     <tr class="hover:bg-gray-100 dark:hover:bg-gray-700" v-else>
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
            <div>
               Loading component
               <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 animate-bounce inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
            </div>
        </td>
            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left w-1/2">
                <div>
                <input  placeholder="Place your comment here" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-700">
            </div>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
            {{this.emp_id}}
        </td>

        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
            <div class="flex flex-row items-center justify-end">
                
            </div>
        </td>
    </tr>
</template>

<script>
// Pinia > Vuex
import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return{
            form:{
                trainee_id:"",
                comment:"",
                
            },
            loading:true,
            saving:false
        }
    },
    props:{
        options:{
            type:Array,
            default:()=> []
        }
    },
    computed:{
        ...mapGetters([
            '_trainees'
        ])
    },
    methods:{
         ...mapActions([
            'fetchTrainees',
            'saveAttritionRisk'
        ]),
        newAttrition:function(){
            this.$v.$touch()
            if(this.$v.$invalid){
                return this.$fire({type:'warning',title:'Fill the mandatory fields in order to continue.'})
            }
            this.saving = true;
            this.saveAttritionRisk({...this.form, training_id:this.$route.params.id}).then(()=>{
                this.$emit('new')
                this.saving = false;
            })
        }
    },
     validations:{
        form: {
           trainee_id:{
               required
           },
           comment:{
               required
           },
        }
    },
    async created(){
       await this.fetchTrainees(this.$route.params.id);
       this.loading = false;
    }
}
</script>