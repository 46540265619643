<template>
    <div class="flex flex-row items-center justify-start">
        <span class="material-icons transform rotate-45">
          search
        </span>
        <input placeholder="Search..." class="p-2 flex-1 border-b-2 border-gray-200 focus:border-gray-700" v-model="searchString"> 
        <button class="text-red-200 hover:text-red-600 text-sm" v-if="this.searchString" @click="searchString = null">
          <span class="material-icons">
            clear
          </span>
        </button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            searchString:this.val
        }
    },
    props:{
        val:{
            type:String,
            default:null
        }
    },
    watch:{
        searchString:function(val){
            this.$emit('searching',val);
        }
    }
}
</script>