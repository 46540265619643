<template>
    <div class="main p-4">
        <div class="mb-3 flex items-right">
            <button class="bg-gray-500 hover:bg-gray-700 px-3 py-2 rounded-2xl text-white text-xs mt-2 ml-auto my-auto relative overflow-hidden shadow-md" :hidden="!USER_VIEW"
                @click.prevent="$router.push({ name: 'myprofile.edit', params: { profileInfo: infoEmployee } })">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>
                <span class="uppercase font-semibold font-sans text-xs">
                    Edit my information
                </span>
            </button>
            <button class="bg-gray-500 hover:bg-gray-700 px-3 py-2 rounded-2xl text-white text-xs mt-2 ml-auto my-auto relative overflow-hidden shadow-md" :hidden="!ADMIN_VIEW"
                @click.prevent="$router.push({ name: 'profile.edit', params: { id: infoEmployee.RecID, profileInfo: infoEmployee } })">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>
                <span class="uppercase font-semibold font-sans text-xs">
                    Edit Information
                </span>
            </button>
        </div>
        <div class="grid grid-cols-2 gap-3">
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium lowercase bg-transparent text-center w-full" placeholder="not filled" readonly :value="personalEmail">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Personal e-mail</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" readonly :value="birthday">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Birthday</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" readonly :value="infoEmployee.Phone1">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Phone</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" readonly :value="infoEmployee.Phone2">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Phone 2</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" readonly :value="infoEmployee.Zip">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Zip Code</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Address1">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Address</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Address2">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Suburb</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.City">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">City</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.State">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">State</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Education">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Education</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Specialty">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Specialty</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Bilingual">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Bilingual</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.Marital_Status">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Marital Status</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="children">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Children</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center uppercase w-full" placeholder="not filled" readonly :value="infoEmployee.CURP">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">CURP</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" readonly :value="infoEmployee.SSN">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">SSN</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" readonly :value="infoEmployee.RFC">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">RFC</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full uppercase" placeholder="not filled" readonly :value="infoEmployee.Gender">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Gender</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters}   from 'vuex'
export default {
    props: {
        role:{
            default: 'e'
        }
    },
    computed: {
        ...mapGetters(['infoEmployee','loadingFlag']),
        fullName(){
            return (this.infoEmployee.FirstName?.toLowerCase() + " " + (this.infoEmployee.MiddleName?.toLowerCase() ?? '') + " " + this.infoEmployee.LastName1?.toLowerCase() + " " + (this.infoEmployee.LastName2?.toLowerCase() ?? '')).trim();
        },
        personalEmail(){
            return this.infoEmployee.Personal_email;
        },
        birthday(){
            return this.infoEmployee.Birthday;
        },
        children(){
            return this.infoEmployee.Kids;
        },
        ADMIN_VIEW (){
            return this.role === 'a';
        },
        USER_VIEW (){
            return this.role === 'e';
        }
    },
}
</script>
<style scoped>
.border-input{
    border-bottom: 1px solid rgb(150, 150, 150);
}
.text-xxs{
    font-size: 0.6rem;
}
.text-sm{
    font-size: 0.9rem;
}
.main{
    width: 100%;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: uppercase;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: uppercase;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: uppercase;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: uppercase;
}
::placeholder { /* Recent browsers */
    text-transform: uppercase;
}
</style>
