<template>
 <div  class="flex flex-row align-items-center justify-content-start text-gray-300">

      <employee-filter @toggle="updateFilters"/>

      <queue-list  class="ml-2" @toggle="updateFilters"/>

      <status-list class="ml-2" @toggle="updateFilters"/>

      <campaign-filter class="ml-2" @toggle="updateFilters"/>

      <select class="w-32 border-l-2 border-gray-200 bg-transparent hover:text-gray-600 focus:text-gray-600" required v-model="form.site">
          <option value="" hidden>
              Site
          </option>
          <option value="MTY">MONTERREY</option>
          <option value="QRO">QUERETARO</option>
          <option value="AGS">AGUASCALIENTES</option>
          <option value="">ALL SITES</option>
      </select>

      <assignation-filter class="ml-2" @toggle="updateFilters"/>
    
  </div>
                                           
    
</template>
<script>
import Debounce from 'debounce';
import QueueList from './QueueList';
import StatusList from './StatusList';
import EmployeeFilter from './EmployeeList';
import CampaignFilter from './CampaignFilter';
import AssignationFilter from './AssignationFilter';

export default {
    data(){
        return{
            form:{
                page: 1,
                employeeId:   null,
                queue:        null,
                isClosed:     null,
                campaignName: null,
                site:         "",
                isAssigned:   null
            }
        }
    },
    methods:{
        clear:function(){
            this.form.employeeId = null;
        },

        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value;
            this.form.page = 1;
        },350)

    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    components:{
        QueueList,
        StatusList,
        EmployeeFilter,
        CampaignFilter,
        AssignationFilter,
    },
    mounted(){
        this.$emit('toggle',this.form);
    }
}
</script>