<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID"  :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <label v-if="inputLabel" class="text-left text-xs ml-2 block group-focus:text-gray-600">{{inputLabel}}</label>
            <vue-timepicker :id='elementID'  class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
    </div>
</template>
<script>
    import VueTimepicker from 'vue2-timepicker'
    export default { 
    components: {VueTimepicker},
    props:{
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        inputLabel:{
            type:       String,
            default:    null,
            required:   true,
        },
        classToAdd:{
            type:       String,
            default:    'form-control',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        }
    },
}
</script>
