<template>

    <div class="overflow-y-scroll bg-white">
    <table v-if="showTable" class="table-sm table divide-y divide-gray-200 bg-white rounded-md">
        <thead class="bg-gray-100 header-fixed">
            <tr>
                <th colspan="7">
                    <div class="text-center justify-content-center align-middle text-xs flex">
                        <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path></svg>
                        {{this.FilteredEmails.length}} elements
                    </div>
                </th>
            </tr>
            <tr>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Subject</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Received At</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Last Update</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Status</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">User</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Age</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Actions</th>
            </tr>
        </thead>
        <tbody v-if="this.FilteredEmails.length > 0" class="h-full bg-white divide-y divide-gray-200">
            <tr v-for="(email, index) in this.FilteredEmails" :key="email.ID" :id="email.ID" :class="{ 'bg-red-300' : email.timeSec > 86400 }">
                <td class="p-2 text-sm text-gray-900 capitalize text-left   align-items-center justify-content-center align-middle w-2/5">{{email.Subject}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{email.Date_Time | moment('YYYY-MM-DD hh:mm:ss')}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{email.Updatedat ? email.Updatedat : "No Updates"}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{email.Status}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{email.User ? email.User : "Not Specified"}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{FilteredEmails[index].Days}} Days, {{FilteredEmails[index].timeD}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">
                    <button v-if="email.Status == 'Pending'"        v-b-modal="'acceptMN'+index"     class="align-items-center justify-content-center bg-gray-700 hover:bg-gray-900 text-center text-white p-2 rounded-md shadow-md">
                        Accept
                    </button>

                        <b-modal :ref="'acceptThisModal'+index" :id="'acceptMN'+index" hide-footer hide-header centered>
                            <div class="text-center justify-content-center">
                                <div class="text-center justify-content-center justify-items-center flex mb-2">
                                    <svg class="w-12 h-12" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </div>
                                <h4>Do you want to <b class="uppercase">accept</b> this email?</h4>
                                <p>This will change the current status <i class="text-red-700 font-semibold">{{email.Status}}</i> to <i class="text-green-700  font-semibold">In Progress</i> </p>
                                <p class="font-bold text-lg">Subject:</p>
                                <p class="font-semibold text-lg bg-gray-300 p-3 mx-10">{{email.Subject}}</p>
                                <button @click="acceptMail(email.ID, index)"
                                :class="{ 'inactive-button' : buttonLoading }" class="align-items-center justify-content-center bg-gray-700 hover:bg-gray-900 text-center text-white p-2 rounded-md shadow-md"
                                :disabled=" buttonLoading "
                                >
                                    {{buttonText}}
                                </button>
                            </div>
                        </b-modal>

                    <!-- Done Button -->
                    <button v-if="email.Status == 'In Progress'"        v-b-modal="'completeMN'+index"     class="align-items-center justify-content-center bg-gray-700 hover:bg-gray-900 text-center text-white p-2 rounded-md shadow-md">
                        Complete
                    </button>

                        <b-modal :ref="'completeThisModal'+index" :id="'completeMN'+index" hide-footer hide-header centered>
                            <div class="text-center justify-content-center">
                                <div class="text-center justify-content-center justify-items-center flex mb-2">
                                    <svg class="w-12 h-12" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </div>
                                <h4>Do you want to <b class="uppercase">complete</b> this email?</h4>
                                <p>This will change the current status <i class="text-red-700 font-semibold">{{email.Status}}</i> to <i class="text-green-700  font-semibold">Done</i> and will be no longer on the list </p>
                                <p class="font-bold text-lg">Subject:</p>
                                <p class="font-semibold text-lg bg-gray-300 p-3 mx-10">{{email.Subject}}</p>
                                <button @click="completeMail(email.ID, index)"
                                :class="{ 'inactive-button' : buttonLoading }" class="align-items-center justify-content-center bg-gray-700 hover:bg-gray-900 text-center text-white p-2 rounded-md shadow-md"
                                :disabled=" buttonLoading "
                                >
                                    {{buttonText}}
                                </button>
                            </div>
                        </b-modal>

                </td>
            </tr>
        </tbody>
        <tbody v-else class="col-span-6">
            <tr>
                <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="7">
                    No records found.
                </td>
            </tr>
        </tbody>
    </table>
    </div>

</template>
<script>
import { mapGetters, mapState, mapActions} from "vuex";
// import moment       from 'moment';
export default {

    data() {
        return {
            FilteredEmails: [],
            buttonLoading: false,
            showTable: false,
            buttonText: "Agree",
        }
    },

    computed: {
        ...mapState([
            'campaignStore'
        ]),
        ...mapGetters([
            // 'getAllEmails'
        ])
    },

    props:{
        AllEmails: Array,
    },

    methods: {
        ...mapActions([
            // 'fetchWestmedEmails'
            'changeEmailStatus'
        ]),

        filterItems: function (items){
            const result = items.filter(item =>
                item.emailType.toLowerCase().match(/normal/g)
            );
            this.FilteredEmails = result;
            setInterval(this.clockFunction, 1000);
            this.showTable = true;
        },

        acceptMail:function(id, index){
            this.buttonText = "Sending...";
            this.buttonLoading = true;
            let objectData = {
                idSelected: id,
                NewStatus: "In Progress",
            }
            this.changeEmailStatus(objectData).then(() => {
                this.$fire({
                    type: 'success',
                    title: 'Changes Applied!',
                    showConfirmButton: false,
                    timer: 2500
                }).then(() => {
                    this.$refs['acceptThisModal'+index][0].hide();
                    this.buttonLoading = false;
                    this.buttonText = "Agree";
                    this.$emit('Rerender', true);
                })
            })
        },

        completeMail:function(id, index){
            this.buttonText = "Sending...";
            this.buttonLoading = true;
            let objectData = {
                idSelected: id,
                NewStatus: "Done",
            }
            this.changeEmailStatus(objectData).then(() => {
                this.$fire({
                    type: 'success',
                    title: 'Changes Applied!',
                    showConfirmButton: false,
                    timer: 2500
                }).then(() => {
                    this.$refs['completeThisModal'+index][0].hide();
                    this.buttonLoading = false;
                    this.buttonText = "Agree";
                    this.$emit('Rerender', true);
                })
            })
        },

        clockFunction: function(){
            // this functions works same day only and using vmodel
            [].forEach.call(this.FilteredEmails, element => {
                var itemSelected = element.timeD;
                var dateFormat = element.Updatedat ? new Date(element.Updatedat) : new Date(element.Date_Time);
                let currentTime = itemSelected.split(":");
                let d0 = new Date();
                let d1 = new Date(d0.getFullYear(),d0.getMonth(),d0.getDay(),currentTime[0],currentTime[1],currentTime[2]);
                d1.setSeconds(d1.getSeconds() + 1);
                element.Days = Math.floor((d0.getTime() - dateFormat.getTime())/1000/60/60/24);
                element.timeD = d1.toLocaleTimeString('en-GB');
            });
        },
    },

    created(){
        this.filterItems(this.AllEmails);
        
    },
}

</script>

<style scoped>
.inactive-button{
    cursor: wait;
    display: hide;
}
.header-fixed {
  position: sticky;
}

</style>