
<template>
<div>
  <div>
    <modal v-if="showModal" @close="closeViewModal" :approval_id="approval_id"/>
      <div class="flex flex-col lg:w-10/12 mx-auto px-3 mb-5">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow-sm bg-white overflow-hidden border-b border-gray-200 sm:rounded-md">
              <table class="min-w-full divide-y divide-gray-200" :key="editKey">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Approval ID</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Employee ID</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Requested Title</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Event Date</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Campaign</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Site</th>       
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Reason</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Status</th>
                  <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody v-if="this.getPayrollRequests.length">
                  <tr :id="'row'+index" v-for="(currentRow, index) in getPayrollRequests" :key="index">
                      <td class="py-3 text-xs whitespace-nowrap">{{currentRow.Approval_id}}</td>
                      <td class="py-3 text-xs whitespace-nowrap">{{currentRow.Emp_id}}</td>
                      <td class="py-3 text-xs">{{currentRow.RequestedTitle}}</td>
                      <td class="py-3 text-xs whitespace-nowrap">{{currentRow.Event_date | moment("YYYY-MM-DD")}}</td>
                      <td class="py-3 text-xs whitespace-nowrap">{{currentRow.Campaign}}</td>
                      <td class="py-3 text-xs whitespace-nowrap">{{currentRow.Site_Location}}</td>
                      <td class="py-3 text-xs">{{currentRow.Reason}}</td>
                      <td class="py-3 text-xs">{{currentRow.RequestStatus}}</td>
                      <td style="cursor:pointer">
                        <div v-if="currentRow.RequestStatus == 'completed'">
                          <span class="material-icons" v-b-tooltip.hover title="Undo Request" @click='undoRequests(currentRow.Request_id)'>replay</span>
                        </div>
                        <div v-else-if="currentRow.RequestStatus == 'cancelled' || currentRow.RequestStatus == 'Rejected'">
                          <span>No Actions</span>
                        </div>
                        <div v-else>
                          <div class="flex">
                            <svg @click='showViewModal(currentRow)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 m-auto" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                              <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                            </svg>
                          </div>
                        </div>
                      </td>
                  </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="13">No records found.</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
  import {mapGetters,mapState, mapActions } from 'vuex'
  import Modal from '@/views/components/Payroll/modals/requestModal'
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        modalKey:0,
        selectedRow:null,
        showSlideOver:false,
        currentProof:null,
        approval_id: '',
        showModal:false,
        editKey: 0
      }
    },
    components: {
      Modal,
    },
    computed:{
      ...mapState(['payroll']),
      ...mapGetters(['getPayrollRequests']),
      showProof: function(){
        return this.showSlideOver;
      }
    },
      created(){
        this.filterPayrollRequest();
    },
    methods:{
      ...mapActions(['filterPayrollRequest','approvePayrollRequest','rejectPayrollRequest','undoPayrollRequest']), 

      hhmmss: function(secs) {
        var minutes   = Math.floor(secs / 60);
        secs          = secs%60;
        var hours     = Math.floor(minutes/60)
        minutes       = minutes%60;
        return `${this.pad(hours)}:${this.pad(minutes)}`;
      },
      pad: function(num) {
          return ("0" + num).slice(-2);
      },
      undoRequests: function(RequestId){
        this.$confirm("Do you want to continue?", "You're about to put In Revision a Request", "warning").then((result) => {
            if (result){
              this.undoPayrollRequest(RequestId).then(()=>{
                this.$emit('toggle');
                this.$fire({
                  type: "success",
                  title: "Request setted as In Revision",
                  showConfirmButton: false,
                  timer: 1300,
                })
              }); 
            }
        })
      },
      showViewModal:function(id){
          this.showModal =  true;
          this.approval_id = id;
      },
      closeViewModal:function(){
        this.showModal =  false;
        this.approval_id = '';
        let form = {
          refresh :false
        }
        this.filterPayrollRequest(form);
      },

    },
  }

</script>
<style lang="scss" scoped>

</style>