import { API_URL, IP_PROVIDER } from '../actions/config'
import axios from '@/config/axios'

const state = {
    passwordChanged: false,
    clientIP: '',
    authObject:{
        token:  '',
        status: '',
    },
    hasLoadedOnce: false
};

const getters = {
    getAuthObject: state => state.authObject,
    getStatusPassword: state => state.passwordChanged,
    getIP: state => state.clientIP,
};

const actions = {
    async getIPFromClient({commit}){
        const endpoint = IP_PROVIDER;
        fetch(endpoint).then(x => x.json()).then(({ ip }) => {
            commit('setIPClient', ip);
        });
    },
    async loginUser ({ commit }, objectData){
        const endpoint = API_URL + 'api/v1/login';
        const response = await axios.post(endpoint, objectData).then(resp => {
            sessionStorage.setItem('token', JSON.stringify(resp.data.token));
            sessionStorage.setItem('EmpID', JSON.stringify(resp.data.EmpID));
            sessionStorage.setItem('status', JSON.stringify(resp.data.status));
            sessionStorage.setItem('location', JSON.stringify(resp.data.location));
            sessionStorage.setItem('campaign', JSON.stringify(resp.data.campaign));
            sessionStorage.setItem('roles', JSON.stringify(resp.data.roles));
            sessionStorage.setItem('passwordReset', JSON.stringify(resp.data.changePassword))
            return resp;
        });
        commit('authObject',response.data);
    },

    async logoutUser  ({ commit }){
        const endpoint = API_URL + 'api/v1/logout';
        await axios.get(endpoint).then(()=> {
            sessionStorage.clear();
            commit('authObject', null);
        });
    },

    async changePassword  ({ commit }, userData){
        const endpoint = API_URL + 'api/v1/management/changePassword';
        await axios.post(endpoint, userData).then(()=> {
            commit('setPasswordStatus', 'changed');
        });
    }

};

const mutations = {
    authObject: (state, token) => { state.status = 'success', state.token = token},
    setPasswordStatus: (state, status) => { state.passwordChanged = status },
    setIPClient: (state, value) => { state.clientIP = value },
};

export default {
    state,
    getters,
    actions,
    mutations
};