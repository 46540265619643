<template>
    <div id="container" class="relative w-8 h-4 rounded-full" :class="!isActive ? 'bg-gray-200':'bg-green-300'">
       
        <button id="btn" class="relative w-4 h-4 ring-2 ring-white rounded-full -top-0.5" :class="isActive ? 'bg-green-400 ml-4':'bg-gray-400 -ml-1 '"  @click="toggle"></button>
    </div>
</template>

<script>
export default {
    props:{
        active:{
            default:false,
            type:Boolean,
        },
        id:{
            required:true,
            type:Number
        }
    },
    data(){
        return {
            val:false
        }
    },
    methods:{
        toggle(){
            this.val = !this.val;
            this.$emit('toggle', {id:this.id, value:this.val});
        }
    },
    mounted(){
        this.val = this.active;
    },
    computed:{
        isActive:function(){
            return this.active;
        }
    }
}
</script>

<style scoped>
    #btn{
        transition: margin 500ms;
    }
    #container{
        transition: background-color 700ms
    }
</style>