<template>
    <div>
        <div><span>New Multiple Configuration</span></div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-md ml-2 block group-focus:text-gray-600">Multiple Title Label:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newMultiple.multipleLabel">
        </div>
            <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-md ml-2 block group-focus:text-gray-600">Custom Style CSS:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newMultiple.classToAdd">
        </div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2">
            <div class="selectOptions">
                <label class="text-md ml-2 block group-focus:text-gray-600">Options:</label>
                <table id="optionsMultiple" class="table-fixed w-full">
                    <thead>
                        <tr >
                            <th class="w-1/2 p-1 text-center">Values</th>
                            <!-- <th class="w-1/2 p-1 text-center">Value</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-1 text-center">
                                <input name="multipleLabel" type="text" class="bg-gray-100">
                            </td>
                            <!-- <td class="p-1 text-center">
                                <input name="multipleValue" type="text" class="bg-gray-100">
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="relative w-full mx-auto mb-2 px-2 mt-2 flex flex-row group">
            <button class="w-1/2 p-1 bg-red-500 text-white shadow-sm rounded-sm" @click="removeLast">Remove Last Row</button>
            <button class="w-1/2 p-1 bg-blue-500 text-white shadow-sm rounded-sm" @click="addSelectOptions">Add Options</button>
        </div>
        <div class="w-full py-2">
            <button @click="sendNewComponent" class="ml-auto px-3 p-2 bg-gray-700 text-white shadow-sm rounded-sm w-full">Save</button>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
export default {
    data(){
        return{
            newMultiple:{
                component: 'multiple',
                multipleLabel: '',
                classToAdd: '',
                options: null,
            },
            optionID: 1,
        }
    },
    methods:{
        sendNewComponent: function(){
            let multipleOptions = [];
            let optionsLabels = document.querySelectorAll('input[name=multipleLabel]');
            let optionsValues = document.querySelectorAll('input[name=multipleLabel]'); // multipleValue
            for (let i = 0; i < optionsLabels.length; i++) {
                if(optionsValues[i].value !== ''){
                    let dropOpt = {
                        'label' : optionsLabels[i].value,
                        'values': false, // optionsValues[i].value,
                    }
                    multipleOptions.push(dropOpt);
                }
            }
            this.newMultiple.options = multipleOptions;
            EventBus.$emit('addNewComponent', this.newMultiple);
        },
        removeLast: function(){
            var tableBody =  document.getElementById("optionsMultiple").getElementsByTagName('tbody')[0]; 
            tableBody.deleteRow(-1);
        },
        addSelectOptions: function(){
            this.optionID++;
            var tableBody =  document.getElementById("optionsMultiple").getElementsByTagName('tbody')[0]; 
            let newRow = '<tr><td class="p-1 text-center"><input name="multipleLabel" type="text" class="bg-gray-100"></td> </tr>'; //<td class="p-1 text-center"><input name="multipleValue" type="text" class="bg-gray-100"></td>
            tableBody.insertRow(-1).innerHTML = newRow;
            document.querySelectorAll('.deleteRow').forEach( (button) => {
                button.addEventListener('click',this.removeRow);
            });
        }
    },
}
</script>
<style scoped>
</style>
