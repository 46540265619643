import axios from '@/config/axios'

const state = {
  attendance:{
    training_id:null,
    candidate_id:"",
    status:"",
    attendance:"",   
    comment:null,
  },
  default:{
    training_id:null,
    candidate_id:"",
    status:"",
    attendance:"",   
    comment:null
  },
  attendance_list:[]
};

const getters = {
    getAttendances: (state) => state.attendance_list,
    getCurrentAttendance: (state) => state.attendance
};

const actions = {
    async fetchTrainingAttendance({commit}, id){
        commit('setTrainingStatus','loading');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/attendance`).then((res)=>{
            commit('setTrainingAttendance',res.data.list);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;
    },
    async storeAttendance({commit},form){
        commit('setTrainingStatus','saving');   
        const response = await axios.post(`api/v2/recruitment/trainings/${form.training_id}/attendance/new`,form).then((res)=>{
            commit('setCurrentAttendance',res.data.attendance);
            commit('setTrainingStatus','saved');
        });
        return response;
    }
}

const mutations = {
  setTrainingAttendance: (state,attendances) =>(state.attendance_list = attendances),
  setCurrentAttendance:(state,attendance) => (state.attendance = attendance),
  setAttendanceCandidate:(state,candidate) => (state.attendance.candidate_id = candidate),
  setAttendanceStatus:(state,status) =>(state.attendance.status = status),  
  setAttendanceComment:(state,comment) =>(state.attendance.comment = comment),
  setAttendanceAsistence:(state,attendance) => (state.attendance.attendance = attendance),
  setAttendanceTraining:(state,training) => (state.attendance.training_id = training),
  resetAttendance:(state) =>(state.attendance = state.default)
};

export default {
    state,
    getters,
    actions,
    mutations
};