<template>
  <div class="flex flex-col w-full h-100 overflow-hidden">
    <div class="p-3">
      <div class="flex justify-between">
          <button disabled class="bg-gray-200 text-gray-400 rounded-[5px] px-6 m-2 cursor-not-allowed">
            <i class="bi bi-arrow-left mr-2"></i>Previous week
          </button>
          <div class="space-x-1">
            <button class="rounded-t-[5px] px-8 py-3"
            @click="window('payroll')"
            :class="{ 'bg-gray-200' : currentTab !== 'payroll', 'bg-white shadow-[0_-5px_5px_rgba(0,0,0,0.05)]' : currentTab === 'payroll' }">
                Payroll
            </button>
            <button class="rounded-t-[5px] px-8 py-3"
            @click="window('conformance')"
            :class="{ 'bg-gray-200' : currentTab !== 'conformance', 'bg-white shadow-[0_-5px_5px_rgba(0,0,0,0.05)]' : currentTab === 'conformance' }">
                Conformance
            </button>
          </div>
          <button class="bg-c-primary text-white rounded-[5px] px-6 m-2 cursor-not-allowed" @click.prevent="loadCurrentWeek">
            Current week<i class="bi bi-arrow-down ml-2"></i>
          </button>
      </div>
      <div class="bg-white">
        <div class="overflow-hidden px-2 flex flex-col py-5 align-center justify-start">
          <div class="flex-1 px-2 h-1/2 overflow-auto">

            <hours-table v-if="currentTab === 'payroll'" class="px-5">
              <approval-buttons :loadPrevious="previousWeek"/>
              <payable-hours :loadPrevious="previousWeek"/>
            </hours-table>
            
            <div v-if="currentTab === 'conformance'">
              <conformance-details :loadPrevious="previousWeek"/>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!--#right_end-->

    <!--#modal-->
    <notify-modal v-if="showModal" @close="closeModal" :key="modalKey"/>
    <!--#modal_end-->
  </div>
</template>

<script>
import HoursTable from '../components/HoursReviewTable'
import PayableHours from '../components/PayableHours'
import ApprovalButtons from '../components/ApprovalButtons'
import NotifyModal from '../components/NotifyModal'
import {mapGetters,mapActions} from 'vuex'
import ConformanceDetails from '../components/ConformanceDetails'
export default {
  components:{
    HoursTable,
    PayableHours,
    ApprovalButtons,
    NotifyModal,
    ConformanceDetails,
  },
  data(){
    return{
      previous:true,
      showModal:false,
      modalKey:0,
    }
  },
  methods:{
    ...mapActions(['fetchWeeklyDates']),

    window:function(data){
      this.$router.replace({params: {tab: data}});
    },

    closeModal:function(){
      this.showModal = false;
      this.modalKey ++;
    },

    loadCurrentWeek:function(){
      this.$router.replace({name:'hoursReview.current' })
    },
  },
  computed:{
    ...mapGetters(['getDateRange','getHoursManagmentStatus','getPreviousWeekFlag']),
    previousWeek:function(){
      return this.previous;
    },

    currentTab(){
      return this.$route.params.tab ?? 'payroll';
    }
  },
  mounted(){
      this.$emit('set-title','Employee Hours Management')
  },
  created(){
    if(this.$route.params.tab === undefined) this.$router.replace({params: {tab: 'payroll'}});
    var newloading = document.getElementById('pro-loading');
    if(newloading) {
      newloading.style.display = 'flex';
    }
    this.fetchWeeklyDates(this.previousWeek).then(()=> {
      newloading.style.display = 'none';
    });
  }
}
</script>