import Vue                      from 'vue'
import Vuex                     from 'vuex'
import employees                from './modules/employees'
import states                   from './modules/states'
import session                  from './modules/session'
import definitions              from './modules/definitions'
import cities                   from './modules/cities'
import mail                     from './modules/mail'
import clocker                  from './modules/exams/clocker'
import clockerUsers             from './modules/clocker'
import feedbacks                from './modules/feedbacks'
import clockerUtility           from './modules/clockerUtility'
import AccountManagerClocker    from './modules/AccountManagerClocker'
import fileState                from "./modules/files";
import axios                    from '../config/axios'
import payroll                  from './modules/payroll'
import surveys                  from './modules/surveys'
import tickets                  from './modules/tickets'
import schedules                from './modules/schedules'
import candidates               from './modules/recruitment/candidates'
import purchaseOrders           from './modules/recruitment/purchase-orders'
import trainings                from './modules/recruitment/trainings'
import attendance               from './modules/recruitment/attendance'
import imss                     from './modules/recruitment/imss'
import notifications            from './modules/notifications'
import candidatePayroll         from './modules/recruitment/payroll'
import incidences               from './modules/incidences'
import formBuilder              from './modules/formBuilder'
import campaignStore            from './modules/campaignStores'
import OpenPositions            from './modules/OpenPositions'
import HoursManagement          from './modules/hours-management/EmployeeHoursManagement'
import NewsFeed                 from './modules/newsFeed'
import rsa                      from './modules/rsa'
import reports                  from './modules/reports'
import RolesManagement          from './modules/roles'
import inventory                from './modules/inventory'
import resources                from './modules/resources'
import indicators               from './modules/dashboard/indicators'
import platform                 from './modules/dashboard/platform'
import messages                 from './modules/messages'
import teams                    from './modules/teams'
import msl                      from './modules/msl'
import SurveysV2                from './modules/surveysv2'
import documents                from './files/employee_documents'
import Terminations             from './modules/terminations'
import Items                    from './modules/items'

// Stores for Recruitment/Training
import activities from './recruitment/training/activities'
import attrition from './recruitment/training/attrition'
import details from './recruitment/training/details'
import staff from './recruitment/training/staff'
import attritionRisk from './recruitment/AttritionRisk'
import headcount from './modules/headcount'

Vue.use(Vuex, axios)
export default new Vuex.Store({
    modules: {
        states,
        employees,
        session,
        definitions,
        cities,
        mail,
        clocker,
        clockerUsers,
        feedbacks,
        clockerUtility,
        AccountManagerClocker,
        fileState,
        payroll,
        surveys,
        tickets,
        schedules,
        candidates,
        purchaseOrders,
        trainings,
        attendance,
        imss,
        notifications,
        candidatePayroll,
        incidences,
        formBuilder,
        campaignStore,
        OpenPositions,
        HoursManagement,
        NewsFeed,
        rsa,
        reports,
        RolesManagement,
        inventory,
        indicators,
        messages,
        resources,
        platform,
        details,
        activities,
        attrition,
        staff,
        attritionRisk,
        teams,
        msl,
        SurveysV2,
        documents,
        Terminations,
        Items,
        headcount
    }
});