<template>
<div class="w-3/5 m-auto py-3">
    <new-feedback />
</div>
</template>
<script>
import NewFeedback      from "@/views/feedbacks/modals/newFeedbackType"
export default{
    components: { NewFeedback },
    mounted(){
        let title = 'New Feedback Type';
        this.$emit('set-title',title);
    }
};
</script>