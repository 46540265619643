<template>
<div class="w-full">
     <material-loader class="px-3" v-if="isLoading"/>
     <select :disabled="true" v-else-if="this.currentCampaign === null" class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2">
        <option hidden value="">Choose a campaign first...</option>
     </select>
    <select v-else class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2" v-model="currentLOB" @change="emitStatus" required>
        <option hidden value="null">Select an LOB...</option>
        <option v-for="(lob,index) in getLob" :key="index" :value="lob.id">
            {{lob.label}}
        </option>
    </select>
   
</div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import MaterialLoader from '@/views/mockups/MaterialLoader'
export default {
    components:{
        MaterialLoader
    },
    props:{
        val:[String,Number],
        campaign:[String,Number],
    },
    data(){
        return{
            currentLOB:"",
            currentCampaign : null
        }
    },
    created(){
        this.currentCampaign = this.campaign;   
        if(this.currentCampaign !== null){
            this.fetchLobByCampaign(this.currentCampaign).then(()=>{
                this.currentLOB = this.val;
            });           
        }  
    },
    methods:{
        ...mapActions(['fetchLobByCampaign']),
        emitStatus:function(){
            this.$emit('toggle',this.currentLOB);
        }
    },
    computed:{
        ...mapGetters(['getLob','getDefinitionStatus']),
        isLoading:function(){
            return this.getDefinitionStatus == 'loadingLOB';
        }
    },
    watch:{
        campaign:function(value){
            this.currentCampaign = value;
            this.currentLOB = null;
            this.fetchLobByCampaign(value).then(()=>{
                this.emitStatus();
            });
        }
    }
}
</script>