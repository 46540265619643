var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{staticClass:"bg-white divide-y divide-gray-200 animate-pulse"},_vm._l((5),function(index){return _c('tr',{key:'test'+index},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true),_vm._m(8,true),_vm._m(9,true),_vm._m(10,true)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"}),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"text-sm font-medium text-gray-900"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-sm text-gray-500"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold"},[_c('div',{staticClass:"relative w-full h-2 bg-gray-200 rounded-full"})])
}]

export { render, staticRenderFns }