<template>
    <div class="bg-white rounded-[10px] shadow-sm overflow-hidden text-left mb-3">
        <h5 class="flex items-center justify-between py-3 px-6 mb-0 cursor-pointer hover:bg-slate-50" :class="{ 'bg-slate-50': isOpen, '': !isOpen }" @click="toggleAccordion()" :aria-expanded="isOpen" :aria-controls="`collapse${_uid}`">
            <slot name="title"/>
            <svg class="w-3 transition-all duration-300 transform" :class="{ '-rotate-90': isOpen, 'rotate-0': !isOpen }" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" aria-hidden="true">
              <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </h5>
        <div class="border-t border-gray-100 py-3 px-6" v-show="isOpen" :id="`collapse${_uid}`">
            <slot name="content" />
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>