<template>
    <hooper :settings="hooperSettings">
        <slide :key="entry.id" v-for="entry in orderedEntries">
            <newsfeed-slide :entryTitle="entry.title" :newsfeedImage="entry.image" :entryDescription="entry.description"></newsfeed-slide>
        </slide>
    </hooper> 
</template>
<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import newsfeedSlide from '@/views/newsfeed/slide/index'
import {mapState, mapGetters, mapActions} from 'vuex';
export default {
    components: {
        Hooper, Slide, 
        'newsfeed-slide': newsfeedSlide,
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1,
                vertical: true,
                autoPlay: true,
                mouseDrag: false,
                touchDrag: false
            }
        };
    },
    computed:{
        ...mapState(['NewsFeed']),
        ...mapGetters(['getAllEntries']),

        orderedEntries() {
            return this.getAllEntries.slice().reverse();
        },
    },
    methods:{
        ...mapActions(['fetchAllEntries'])
    },
    created(){
        this.fetchAllEntries();
    },
}
</script>