<template>
<div  v-if="isValidLocation" class="relative p-2 flex flex-row group w-full rounded-sm content-center justify-start overflow-hidden hover:text-gray-700" :class="isActive ? 'bg-[#7c4dff] text-white shadow-lg':'text-gray-500'">
    <button  @click="deleteCategory" :class="isActive? 'border-purple-200':'border-gray-400'" class="border-r-2 mr-1 inline-block cursor-pointer px-1 text-gray-400 hover:text-red-500"  v-if="isGranted('SystemAdministrator') && item.id >= 1">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" >
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
    </button>
    <button class="relative flex-1 flex flex-row overflow-hidden" @click="toggleActive" :title="item.name">
        <span class="capitalize ml-1 truncate text-sm" :class="item.id === 0 ? 'pr-3' : ''">{{item.name}}</span>
    </button>
</div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    props:{
        item:{
            type:Object,
            default:()=>{}
        },
        on:{
            default:false,
            type:Boolean,
        },
    },
    methods:{
        ...mapActions(['removeFolder']),
        toggleActive(){
            this.$emit('active',this.item.id);
        },
        async deleteCategory(){
            const {value} = await this.$fire({title:`Are you sure you want to delete the "${this.item.name}" category?`,text:'This action is ireversible.',showConfirmButton:true, confirmButtonText:'Yes proceed',showCancelButton:true})
            if(!value) return;

            await this.removeFolder(this.item.id);

            this.$fire({title:"Category removed successfully", type:"success"})
            .then(()=>{
                this.$router.go(0);
            })

            

       }
    },
    computed:{
        isActive:function(){
            return this.on;
        },
        isValidLocation:function(){
           if(this.isGranted('SystemAdministrator')) return true;

           const sites = this.item.sites;

           if(sites && sites.length === 0) return true;

           if(sites?.includes(this.location.toLowerCase())) return true;
           
           return false;
        }
    },     
}
</script>