<template>
    <!-- This example requires Tailwind CSS v2.0+ -->

<div class="flex flex-col">
  <div class="flex flex-row items-center content-around group text-gray-400 transition-colors rounded-md mt-2 align-items-center justify-content-start focus-within:text-blue-400">
    <div class="flex flex-row md:w-2/3 lg:w-1/2 bg-white rounded-md ml-2 py-1 mr-2 focus-within:bg-gray-500 p-2">      
      <input class="p-2 pl-3 pl-0 bg-transparent text-gray-400  flex-1 rounded-sm placeholder-gray-400 border-none border-none outline-none" placeholder="Search..." id="searchbox" name="searchbox" v-model="po" autocomplete="off"/>
      <select class="mr-2 border-l-2 border-gray-100 px-2" v-model="site">
        <option value="">Filter by site location ...</option>
        <option value="AGS">Aguascalientes</option>
        <option value="MTY">Monterrey</option>
      </select>
      <button type="button" class="p-2 text-white group-focus:shadow-none bg-indigo-400 hover:bg-indigo-600 shadow-md rounded-md" @click.prevent="filter">
          <svg class="w-6 h-6 stroke-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
      </button>
    </div>
     <button type="button" class="bg-red-300 hover:bg-red-500 text-white px-3 py-2 rounded-sm" @click.prevent="clear">
      Clear
    </button>
    <button type="button" class="bg-gray-700 hover:bg-gray-900 text-white px-3 py-2 rounded-sm shadow-lg mr-3 ml-3" @click="$router.push({name:'recruitment.po.create'})">
      New 
    </button>
  </div>
  <div class="-my-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="overflow-hidden border-b border-gray-50 sm:rounded-lg">
        <table class="min-w-full divide-y-2 divide-gray-50 mt-3">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Purchase Order
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Campaign
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Agents
              </th>
               <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Training date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Hours
              </th>
               <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                Status
              </th>              
              <th scope="col" class="relative px-6 py-3 text-left">
                 Actions
              </th>
              
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-blue-200">
            <tr v-for="(item,index) in list" :key="index">
              <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                <div class="text-sm font-medium text-gray-900 text-left">
                  {{item.title}}
                </div>                                   
              </td>
              <td class="px-6 py-4 whitespace-nowrap border-b-4 border-gray-50">                
                <div class="text-sm text-gray-500 text-left font-semibold">{{item.campaign}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-left border-b-4 border-gray-50">
                 <div class="text-sm text-gray-500 text-left font-semibold">{{item.location}}</div>                
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                <div class="text-sm text-gray-500 text-left font-semibold">{{item.agents}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                <div class="text-sm text-gray-500 text-left font-semibold">{{item.date}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                <div class="text-sm text-gray-500 text-left font-semibold">{{item.hours}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left border-b-4 border-gray-50">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" v-if="item.status">
                  Completed
                </span>
                 <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" v-else>
                  Pending
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium border-b-4 border-gray-50 text-left">            
                <a href="#"  @click.prevent="$router.push({ path: '/recruitment/purchase-orders/' + item.id})" class="text-indigo-600 text-left hover:text-indigo-900">Edit</a>
              </td>
            </tr>

            <!-- More items... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import {mapGetters,mapActions} from 'vuex';
export default {
    computed:{
      ...mapGetters(['getPurchaseOrders','getFilters']),
      list:{
        get(){
          return this.getPurchaseOrders;
        },
        set(value){
          this.$store.commit('setPurchaseOrders',value);
        }
      },
      site:{
        get(){
          return this.getFilters.site_location;
        },
        set(value){
          this.$store.commit('SET_SITE_LOCATION_FILTER',value)
        }
      },
      po:{
        get(){
          return this.getFilters.purchase_order;
        },
        set(value){
          this.$store.commit('SET_LABEL_FILTER',value);
        }
      }
    },
    methods:{
      ...mapActions(['fetchPurchaseOrders','filterPurchaseOrders']),
      filter:function(){
        this.filterPurchaseOrders(this.getFilters).then((res)=>{
          console.log(res);
        });
      },
      clear:function(){
        this.site = "";
        this.po = null;
        this.fetchPurchaseOrders();
      }
    },
    beforeMount(){
        this.$emit('set-title','Purchase Orders');
        this.fetchPurchaseOrders();
    },
    
}
</script>