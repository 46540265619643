<template>
<div class="main-container">
    <folder-modal v-if="createMode" :key="'modal' + modalKey" @reset="resetModal"/>
    <resource-modal v-if="resourceMode" :key="'resource' + modalKey" @reset="resetModal" :categories="resourceFolders" /> 
    <div class="relative w-1/3 xl:w-56">
        <div class="relative bg-white shadow-md p-3 max-h-96 overflow-y-auto py-3 w-full overflow-x-hidden">
            <new-category @create-category="newCategory"  v-if="isGranted('SystemAdministrator')"/>
            <category-tab v-for="x in resourceFolders" :key="x.id * resourceFolders.length" :item="x" @active="setActive" :on="activeCategory === x.id"/>
        </div> 
    </div>
     <div class="flex-1 flex flex-row flex-wrap items-start content-start mx-auto px-5 w-full" id="cards-slider" >
         <div v-if="isGranted('SystemAdministrator')" class="z-10 mx-2 w-72 h-44 bg-white shadow-sm rounded-md border-2 border-gray-200 p-2 group cursor-pointer" @click="newResource">
            <div class="w-full h-full border-dashed border-2 border-gray-300 group-hover:border-blue-500 flex flex-col items-center justify-center ">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-gray-300 group-hover:text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                </svg>
                <span class="text-gray-300 group-hover:text-gray-700">New Course</span>
            </div>
         </div>

         <resource-card :item="x" v-for="x in getCategories" :key="x.id" :data-category="x.category">
            {{x.description}}
         </resource-card>
    </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import FolderModal from '../components/NewCategoryTab'
import CategoryTab from '../components/CategoryTabs'
import NewCategory from '../components/NewCategoryButton'
import ResourceModal from  '../components/NewResource'
import ResourceCard from "../components/ResourceCard";
export default {
    components: { CategoryTab, ResourceCard, FolderModal, NewCategory , ResourceModal },
    data(){
        return{
            resourceFolders :[{'id':0, index:0,name:'No Category',order:1,sites:[]}],
            activeCategory:0,
            createMode:false,
            resourceMode:false,
            modalKey:0,
        }
    },
    computed:{
        ...mapGetters(['getFolders','getCategories']),
    },
    methods:{
        ...mapActions(['fetchResourceCategories','fetchResources']),
        setActive(id){
            this.activeCategory = id;

            const hidden  = document.querySelectorAll(`.resource-card:not([data-category="${id}"])`)
            const visible =  document.querySelectorAll(`[data-category="${id}"]`);

            visible.forEach(x => {
                x.classList.remove('hidden')
            });

            hidden.forEach(x =>{
                x.classList.add('hidden')
            })
        },
        newCategory(){
            this.createMode = true;
        },
        resetModal(){
            this.createMode = false;
            this.resourceMode = false;
            this.modalKey ++;
        },
        newResource(){
            this.resourceMode = true;
        }
    },
    mounted(){
        this.$emit('set-title','Training Resources')
        this.fetchResources().then(()=>{
            this.setActive(0);
        });
        this.fetchResourceCategories()
        .then(() =>{
            const folders = this.getFolders.map( f => {
                return {
                    id: f.id,
                    index:f.id +1,
                    name: f.name.toLowerCase(),
                    order: f.order_num + 1,
                    roles: f.roles,
                    sites: f.sites
                }
            });
            this.resourceFolders = [ ...this.resourceFolders, ...folders];        
        }); 
    }
}
</script>

<style>
    .main-container{
        z-index: 10 !important;
        position:relative;
        display:flex;
        max-height:calc(100% - 10%) !important;
        height: 100vh;
    }
</style>