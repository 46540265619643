<template>
    <div class="p-8">
        <div v-if="is_loading" class="text-center">
            <loader />
        </div>
        <div v-else>
            <div class="flex justify-center">
                <h3 class="font-bold">Does this look good?</h3>
            </div>
            <p class="text-gray-500 leading-normal text-xs">
                It is necessary for you to review your hours, If you see any
                untracked event, or any other issue, you can make some changes.
                Otherwise, it means you agree with the tracked time.
            </p>
            <div class="h-[500px] mx-auto bg-gray-50 rounded-xl p-2">
                <time-line :shifts="getClockerHours" />
            </div>
            <div class="flex justify-center gap-3 mt-2">
                <button
                    type="button"
                    class="bg-blue-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="approveDay"
                >
                    Approve
                </button>
                <button
                    type="button"
                    class="bg-red-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="reviewDay"
                >
                    Review
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/views/components/Loader";
import TimeLine from "@/views/clocker/adjustments/components/TimeLine";
import auth from "@/config/user";
export default {
    components: {
        Loader,
        TimeLine,
    },
    props: {
        shift_id: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            is_loading: true,
        };
    },
    computed: {
        ...mapGetters(["getClockerHours"]),
    },
    methods: {
        ...mapActions(["fetchShiftHours", "storeShiftReview"]),
        reviewDay() {
            this.$emit('toggle')
            this.$router.push({name:'clocker.review.shift', params: { shift_id: this.shift_id}})
        },
        approveDay() {
            this.$confirm(
                "This will set the shift as already reviewed.",
                "Approve Shift",
                "warning"
            ).then((result) => {
                if (result) {
                    this.storeShiftReview({
                        emp_id: auth.currentUser.EmpID,
                        shift_id: this.shift_id,
                    }).then(() => {
                        this.$fire({
                            type: "success",
                            title: "Thanks for reviewing this shift.",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            this.$emit('toggle');
                            this.$router.push({ name: "dashboard" });
                        });
                    });
                }
            });
        },
    },
    created() {
        this.fetchShiftHours(this.shift_id).then(() => {
            this.is_loading = false;
        });
    },
};
</script>