<template>
<div>
  <div id="EditTable" class="userTable">
    <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <b-container fluid class="TableSearch">
        <b-row>
            <b-col>
                <label class="mr-2"  for="inline-form-custom-select-pref">Search Employee:</label>
                <b-input-group v-if="!displayLoadingEmployee">
                    <b-form-input v-if="!agentExist"  v-model="employeeID" @keyup.enter="filterSearch" placeholder="Search by Employee ID" id='filterKeyword' type="text"></b-form-input>
                </b-input-group>
                <div v-if="!agentExist">
                    <div v-if="!displayLoadingEmployee">
                        <label>Please, type an user, press "Enter" to search.</label>
                    </div>
                    <div v-if="displayLoadingEmployee" class="mt-3">
                        <div class="d-flex align-items-center ">
                            <b-spinner class="ml-5"></b-spinner><strong class="ml-2">Searching Employee...</strong>
                        </div>
                    </div>
                </div>
                <div v-if="agentExist">
                    <label><strong>Employee Selected:</strong> {{ this. employeeID}}</label>
                </div>
            </b-col>
            <b-col align-self="center">
                <label class="mr-2"  for="inline-form-custom-select-pref">Event Date:</label>
                <b-form-datepicker v-if="agentExist" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :max='max' today-button reset-button close-button v-model="dateFilter" @context="getAgentData" class="mb-2"></b-form-datepicker>
                <b-form-datepicker v-else size="sm" disabled></b-form-datepicker>
                 <div v-if="!agentExist">
                     <label>Search an Employee First</label>
                 </div>
            </b-col>
        </b-row>
        <b-row class="ml-3 mt-3 mb-3">
            <b-col align-self="center" cols="6">
                <button @click="clearFilters" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Clear Filters</button>
            </b-col>   
             <b-col v-if="employeeLoaded">
                <button 
                :class="{ 'bg-gray-500 cursor-default' : getIfApprovedDay, 'hover:bg-gray-900 bg-gray-700' : !getIfApprovedDay }" class="align-items-center justify-content-center text-center text-white p-2 px-3 rounded-md shadow-md"
                :disabled=" getIfApprovedDay "
                v-b-modal="'newEventModal'" >
                  Correction
                </button>
                <b-modal ref="EventModal" :id="'newEventModal'" hide-footer hide-header>
                  <div>
                    <newEventModal @closeModal='closeAllModals' :EmployeeID="this.employeeID" :eventDate="this.dateFilter"></newEventModal> 
                  </div>
                </b-modal>
            </b-col>         
        </b-row>
        <b-row v-if="getIfApprovedDay && employeeLoaded" class="justify-content-center text-center">
          <p class="bold italic mt-2 p-2 px-3 bg-red-200 rounded-lg border-2 border-red-300">
            This day is already confirmed, you can't do any additional changes until an authorization.
          </p>
        </b-row>
        <b-row v-if="employeeLoaded">
            <b-col align-self="center" class="mt-2 rounded shadow-sm" md="12" lg="12" xl="12">
            <table class="table-sm table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-center" scope="col">Activity</th>
                        <th class="text-center" scope="col">Date</th>
                        <th class="text-center" scope="col">Clocker Std Time</th>
                        <th class="text-center" scope="col">Clocker Grv Time</th>
                        <th class="text-center" scope="col">Clocker Total Time</th>
                        <th class="text-center" scope="col">Standard Time</th>
                        <th class="text-center" scope="col">Graveyard Time</th>
                        <th class="text-center" scope="col">Total Time</th>
                        <th class="text-center" scope="col">Comment</th>
                        <th class="text-center" scope="col">Updated By</th>
                        <th class="text-center" scope="col">Updated At</th>
                        <th class="text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody v-if="getPayrollAgentInfo.length">
                <tr :id="index" v-for="(currentRow, index) in getPayrollAgentInfo" :key="index">
                    <td class="text-capitalize">{{currentRow.Event}}</td>
                    <td class="text-capitalize">{{currentRow.Event_date | moment("MM-DD-YYYY")}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Clocker_day)}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Clocker_gvy)}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Clocker_ttl)}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Daytime)}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Graveyard)}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Total_hrs)}}</td>
                    <td class="text-capitalize">{{currentRow.Comment ? currentRow.Comment : 'No Comment'}}</td>
                    <td class="text-capitalize">{{currentRow.Reviewer ? currentRow.Reviewer : 'NA'}}</td>
                    <td class="text-capitalize">{{currentRow.Review_time ? currentRow.Review_time : 'NA'}}</td>
                    <td class="text-capitalize">
                        <div>
                            <button 
                            :id="'correctionRequest'+index" 
                            :class="{ 'bg-gray-200 cursor-not-allowed' : getIfApprovedDay, 'bg-blue-500 hover:bg-blue-700' : !getIfApprovedDay }" class="align-items-center justify-content-center text-center py-1 px-3 rounded-md"
                            :disabled="getIfApprovedDay"
                            v-b-modal="'correctionRequest'+index">
                              Adjust
                            </button>
                            <b-modal :ref="'adjustmentModal'+index" :id="'correctionRequest'+index" hide-footer hide-header>
                                <div>
                                    <correctionModal @closeModal='closeAdjustmentModal(index)' :id="'correctionRequest'+index" :dataEvent="currentRow"></correctionModal> 
                                </div>
                            </b-modal>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="12">No records found.</td>
                </tr>
                </tbody>
            </table>
            </b-col>
        </b-row>
    </b-container>
  </div>
</div>
</template>
<script>
  import {mapGetters,mapState, mapActions } from 'vuex'
  import Loading          from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import correctionModal  from '@/views/components/Payroll/modals/payrollAdjustmentModal';
  import newEventModal    from '@/views/components/Payroll/modals/payrollCorrectionModal';
  
  export default {
    data() {
      return {

        isLoaderActive: false,
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        keyRender: 0,

        employeeID: null,
        employeeLoaded: false,
        displayLoadingEmployee: false,
        employeeFound: false,
        dateFilter: null,
        agentExist: false,
        // min: minDate,
        max: new Date()
      }
    },
    components: { Loading, correctionModal, newEventModal },
    computed:{
      ...mapState([
          'clockerUsers',
          'payroll'
        ]),
      ...mapGetters([
          'employeeList',
          'getAgentExist',
          'getPayrollAgentInfo',
          'getIfApprovedDay'
        ])
    },
    methods:{
      ...mapActions([
          'fetchAgentPayrollEventsPerEmpID',
          'fetchIfApprovedDay',
          'isValidAgent']),
      filterSearch() {
          this.displayLoadingEmployee = true;
          this.isValidAgent(this.employeeID).then(()=>{
              this.employeeID = this.employeeID.toUpperCase()
              this.displayLoadingEmployee = false;
              this.agentExist = true;
              if(this.getAgentExist == false){
                this.agentExist = false;
                this.$fire({
                    type: "error",
                    title: "Oops! We did not found the Employee, try again",
                    showConfirmButton: true,
                });
              }
          } );
      },
      transformDuration: function(Total_hrs){

        let hrs = parseInt(Math.floor(Total_hrs/3600));
        let mins = parseInt(Math.floor((Total_hrs - (hrs * 3600))/60));
        let secs = parseInt(Math.floor(Total_hrs % 60));

        let dHours = (hrs > 9 ? hrs : '0' + hrs);
        let dMins = (mins > 9 ? mins : '0' + mins);
        let dSecs = (secs > 9 ? secs : '0' + secs);

        return dHours + ":" + dMins + ":" + dSecs;
      
      },
      calculateNewDuration: function(Total_hrs, Adjustment){

        let adjusted = (Total_hrs + Adjustment);
        let hrs = parseInt(Math.floor(adjusted/3600));
        let mins = parseInt(Math.floor((adjusted - (hrs * 3600))/60));
        let secs = parseInt(Math.floor(adjusted % 60));

        let dHours = (hrs > 9 ? hrs : '0' + hrs);
        let dMins = (mins > 9 ? mins : '0' + mins);
        let dSecs = (secs > 9 ? secs : '0' + secs);

        return dHours + ":" + dMins + ":" + dSecs;
      
      },
      getAgentData: function(date){
        if(date.selectedYMD != ''){
          this.isLoaderActive = true;
          let eventInfo = {
            EmpID: this.employeeID,
            date: date.activeYMD
          }
          this.fetchIfApprovedDay(eventInfo);
          this.fetchAgentPayrollEventsPerEmpID(eventInfo).then( ()=>{
              this.employeeLoaded = true;
              this.isLoaderActive = false;
          });
        }
      },
      clearFilters(){
          this.employeeID               = null;
          this.employeeLoaded           = false;
          this.displayLoadingEmployee   = false;
          this.employeeFound            = false;
          this.dateFilter               = null;
          this.agentExist               = false;
      },
      closeAdjustmentModal(id){
        this.$refs['adjustmentModal'+id][0].hide();
        this.isLoaderActive = true;
        let eventInfo = {
            EmpID: this.employeeID,
            date: this.dateFilter
          }
          this.fetchAgentPayrollEventsPerEmpID(eventInfo).then( ()=>{
              this.employeeLoaded = true;
              this.isLoaderActive = false;
          });
      },
      closeAllModals: function(){
        this.$refs['EventModal'].hide();
        this.isLoaderActive = true;
        let eventInfo = {
            EmpID: this.employeeID,
            date: this.dateFilter
          }
          this.fetchAgentPayrollEventsPerEmpID(eventInfo).then( ()=>{
              this.employeeLoaded = true;
              this.isLoaderActive = false;
          });

      },
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>