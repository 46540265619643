<template>
    <div>
        <div><span>New Label Configuration</span></div>
        <div class="relative mb-2 sm:w-full md:w-full px-2  mt-2 flex flex-col group">
            <label class="text-left text-xs ml-2 block group-focus:text-gray-600">Text:</label>
            <input class="bg-gray-100 rounded-sm p-1 px-2" v-model="newLabel.htmlContent">
        </div>    
        <div class="relative sm:w-full md:w-full mb-2 my-auto px-2 mt-2 flex flex-row group">
            <label class="text-left pr-2 my-auto text-xs ml-2 block group-focus:text-gray-600">Set as Title</label>
            <input type="checkbox" class="form-checkbox my-auto" v-model="newLabel.isTitle">
        </div>     
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-xs ml-2 block group-focus:text-gray-600">CSS Class:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newLabel.classToAdd">
        </div>
        <div class="w-full py-2">
            <button @click="sendNewComponent" class="ml-auto px-3 p-2 bg-gray-700 text-white shadow-sm rounded-sm w-full">Save</button>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { mapActions, mapGetters, mapState} from 'vuex'

export default {
    data(){
        return{
            newLabel:{
                component: 'label',
                isTitle: false,
            },
        }
    },
    computed: {
    ...mapState(['formBuilder']),
    ...mapGetters(["getJsonForm"]),
  },
    methods:{
        ...mapActions(['addElementToForm']),
        sendNewComponent: function(){
            console.log(this.newLabel);
            EventBus.$emit('addNewComponent', this.newLabel);
        },
    },
}
</script>
<style scoped></style>
