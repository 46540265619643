import axios from '@/config/axios'
import { API_URL } from '../../actions/config'

const state = {
    platform:{
        'emails': ['Not Available'],
        'platformIds': [],
    }
};
const getters = {
   getEmails: state => state.platform.emails,
   getPlatformIds: state => state.platform.platformIds,
};
const actions = {
    async fetchEmails({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/platforms/'+ employeeInfo +'/emails';
        await axios.get(endpoint).then((response)=>{
            commit('setPlatformEmails',response.data.emails);
        });
    },
};
const mutations = {
    setPlatformEmails: (state, value) => {
        state.platform.emails = value;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};
