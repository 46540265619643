<template>
<div class="">
    <div v-if="isLoading" class="text-center p-3 my-auto mx-auto">
        Loading component 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 animate-bounce inline-block" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
            </svg>
    </div>
    <div v-else>

            <div class="flex flex-row h-full w-full p-1">
                <div class="relative flex-col flex h-full w-1/4">
                    <proof-file :proof="proof"/>
                </div>

                <div class="flex-col flex-1 ml-2 h-full overflow-x-hidden">
                    <requests-table :requests="requests"          class="my-1 overflow-y-hidden"/>
                    <old-payroll-table :old_payroll="old_payroll" class="my-1 overflow-y-hidden"/>
                    <new-payroll-table :new_payroll="new_payroll" class="my-1 overflow-y-hidden"/>
                </div>
            </div>

            <div class="absolute bottom-4">
                <button v-if="saving" class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2" :disabled="saving">
                    <span>
                        Saving 
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                            <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                    </span>
                </button>

                <div v-else id="saving-options" class="flex flex-col">

                    <div id="text-area-div" class="flex flex-col">
                        <label  class="text-gray-700 text-sm font-semibold" for="Comment"><span class="text-red-600 mr-1">*</span>Comments: </label>
                        <textarea id="text-area-box" v-model="comments" name="Comment" class="border-b-2 border-red-500 resize-none text-xs flex flex-row bg-white shadow-md mb-2 rounded-sm p-2" rows="3" maxlength="150" placeholder="Write here..."></textarea>
                        <span class="text-xs text-left mb-2 mt-0"><span class="text-red-600 mr-1">*</span>Maxlength: 150 characters</span>
                        <span class="text-xs text-left mb-2 mt-0"><span class="text-red-600 mr-1">*</span>Comment is mandatory</span>
                    </div>

                    <div id="buttons-area-div" class="animate-none flex flex-row">
                        <button class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mx-1" :disabled="saving" @click="approve">
                            <span>
                                Approve
                            </span>
                        </button>
                        <button class="bg-red-700 px-3 py-2 rounded-sm shadow-faded text-white mx-1" :disabled="saving" @click="decline">
                            <span>
                                Decline
                            </span>
                        </button>
                    </div>
                </div>
            </div>

    </div>
</div>
</template>
<script>
  import {mapGetters, mapActions } from 'vuex'
  import RequestsTable from '@/views/components/Payroll/tables/payrollRequestByApproval'
  import OldPayrollTable from '@/views/components/Payroll/tables/payrollOldEvents'
  import NewPayrollTable from '@/views/components/Payroll/tables/payrollNewEvents'
  import ProofFile from '@/views/components/Payroll/tables/viewProof'
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        modalKey:0,
        selectedRow:null,
        isLoading: true,
        saving:false,
        comments: '',
        requests: [],
        old_payroll: [],
        new_payroll: [],
        proof: null
      }
    },
    components: {
        RequestsTable,
        OldPayrollTable,
        NewPayrollTable,
        ProofFile
    },
    props: {
        approval_id:{
            required: true,
            type: Number
        },
        employee: {
            required: true,
            type: String
        }
    },
      created(){
          this.saving = false;
          this.isLoading = true;
          let obj = {
              approval_id: this.approval_id
          }
        this.detailedRequestByApproval(obj).then(()=>{
            this.requests = this.getCompareTables.requests;
            this.old_payroll = this.getCompareTables.old_payroll;
            this.new_payroll = this.getCompareTables.new_payroll;
            this.proof = this.getCompareTables.proof;
            if(typeof this.proof != 'string') this.proof = this.proof.toString();
            this.isLoading = false;
        });
    },
    computed:{
      ...mapGetters(['getCompareTables']),
    },
    methods:{
      ...mapActions(['detailedRequestByApproval', 'completeRequestByApproval']), 
        proofLink:function(){
            if(this.proof.startsWith("http")) return this.proof;
            return 'https://ems.centrisinfo.com:8081/api/v2/files/' + this.proof;
        },
        secToTime(t){
            return new Date(Math.abs(t) * 1000).toISOString().substr(11, 5);
             
        },
        approve:function(){
            if(this.comments === '' || this.comments?.trim() === '') {
               this.startAnimation();
               return;
           }

           this.saving = true;
           let object = {
               emp_id: this.employee,
               approval_id: this.approval_id,
               comment: this.comments,
               payroll_status: 'completed',
               clocker_status: 'Validation'
           }
           this.sendInformation(object, 'Requests Accepted Successfully!');
       },
       decline:function(){
           if(this.comments === '' || this.comments?.trim() === '') {
               this.startAnimation();
               return;
           }
           this.saving = true;
           let object = {
               emp_id: this.employee,
               approval_id: this.approval_id,
               comment: this.comments,
               payroll_status: 'Rejected',
               clocker_status: 'Validation'
           }
           this.sendInformation(object, 'Requests Rejected Successfully!');
       },
       sendInformation: function(object, text){
           this.completeRequestByApproval(object).then(() => {
               this.$fire({
                    type: 'success',
                    title: text,
                    showConfirmButton: false,
                    timer: 2500
               })
               this.$emit('close');
           }).catch(() => {
               this.$fire({
                    type: 'error',
                    title: 'Something went wrong',
                    text: 'Please try again or report this issue to a system administrator',
                    showConfirmButton: false,
                    timer: 2500
               })
           })
       },
       startAnimation: function(){
           document.getElementById('text-area-box').classList.add('shadow-lg', 'shadow-red-500/50')
           document.getElementById('text-area-div').classList.add('animate-bounce')
           setTimeout(this.clearAnimation, 1500)
       },
       clearAnimation: function(){
           document.getElementById('text-area-box').classList.remove('shadow-lg', 'shadow-red-500/50')
           document.getElementById('text-area-div').classList.remove('animate-bounce')
       }
    },
  }

</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
</style>