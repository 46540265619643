<template>
 <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1">

      <id-filter @toggle="updateFilters"/>

      <status-list  class="ml-2" @toggle="updateFilters"/>
    
  </div>
                                           
    
</template>
<script>
import Debounce         from 'debounce';
import StatusList    from './specialStatusFilter.vue';
import IdFilter         from './specialIdFilter';

export default {
    data(){
        return{
            form:{
                idNumber:   null,
                status:     null,
            }
        }
    },
    methods:{
        clear:function(){
            this.form.idNumber = null;
        },

        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value;
        },350)

    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    components:{
        IdFilter,
        StatusList
    },
    mounted(){
        this.$emit('toggle',this.form);
    }

}
</script>