<template>
<div class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 shadow bg-white rounded p-3 mb-5">
                <div class="row">
                    <div class="col-12">
                        <span>Displaying information as: {{ infoEmployee.Title }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <clockertable ></clockertable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import clockertable     from'@/views/components/Table/clocker_users';
    import {mapGetters,mapState, mapActions } from 'vuex'
    import auth       from "@/config/user"
    export default {
        components: { clockertable },
        computed: {
            ...mapState(['employees']),
            ...mapGetters(['infoEmployee'])
        },
        created(){
            this.loadInfoEmployee();
        },
        methods: {
            ...mapActions(['loadMyProfileByEmpId']),
            loadInfoEmployee(){
                let idEmployee = auth.currentUser.EmpID;
                this.loadMyProfileByEmpId(idEmployee);
            },
        },
      mounted(){
        let title = 'Clocker Password Management';
        this.$emit('set-title',title);
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>