<template>
  <div class="main py-2">
    <loading :active.sync="getFlagLoadingFeedback" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>

    <div class="w-9/12 mx-auto">

                <h5 v-if="infoEmployee.Campaign" class="rounded card-header">User Information: {{infoEmployee.Campaign}} ({{infoEmployee.Location}}) - {{infoEmployee.Title}}</h5>

                <span v-if="showLoader">
                    <div class="p-3 mt-2">
                        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                        <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                    </div>
                </span>

                <span v-if="showFeedbackDrop">
                    <label class="font-sans text-md tracking-wide font-semibold text-gray-700 mr-2" for="feedbackType">Select a feedback:</label>
                    <select id="feedbackType" required v-model="feedback_selected" @change="loadEmployees()"
                    class="bg-white text-gray-400 hover:text-gray-700 active:text-gray-700 mt-2 p-2 rounded-md shadow-sm capitalize">
                        <optgroup label="Select a feedback type...">                
                            <option hidden value="null">Select a feedback type...</option>
                            <option v-for="feedback in getMyFeedbacks" :value="feedback.id" :key="feedback.id">{{feedback.feedbackName}}</option>
                        </optgroup>
                    </select>
                </span>

                <span v-if="showEmployeeDrop">
                    <label class="font-sans text-md tracking-wide font-semibold text-gray-700 mr-2" for="employeeSelect">Select an Employee: </label>
                    <multiselect  @select=loadFeedbackForm() v-model="employee_selected" :custom-label="customFormat" :options="getEmployeesList" 
                    :close-on-select="true" :clear-on-select="true" :preserve-search="false" 
                    placeholder="Select an Employee..." value="EmpID" label="EmpID" track-by="EmpID"></multiselect>
                </span>

                <span v-if="showFeedbackForm" class="mt-2">
                    <span>
                        <button @click="closeModal"
                        class="m-3 p-2 uppercase inline-flex justify-center py-1 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd" />
                            </svg>
                            Change Selection
                        </button>
                    </span>
                    <FeedbackViewer @closeIt="closeModal" :feedback_selected="feedback_selected" :profileInfo="employee_selected.EmpID"></FeedbackViewer>
                </span>

    </div>
  </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment           from 'moment';
    import Loading          from 'vue-loading-overlay'; 
    import FeedbackViewer       from '@/views/feedbacks/modals/feedback_viewer'
    import Multiselect                          from 'vue-multiselect'
    // const numbers = helpers.regex('numbers', /^[0-9A-Za-z]*$/)
     import auth                                 from "@/config/user"

    export default {
        components: { 
            Loading,
            FeedbackViewer,
            Multiselect
        },
        data(){
            return{
                // Info loader
                fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isLoading: true,

                // Information
                feedback_selected: null,
                employee_selected: null,

                // Components loader
                showFeedbackDrop:   false,
                showEmployeeDrop:   false,
                showFeedbackForm:   false,
                showLoader:         true,

                //Infor Component
                auditor: 0,
                campaign: '',
                agent_selected: 0,
                second_feedback: false,
                fistCallSubmit: false,
                secondCallSubmit: false,
                firstCallDuration: 0,
                secondCallDuration: 0,

            }
        },
        created(){
            this.loadInfoEmployee();
            this.fetchMyFeedbackTypeList().then(()=>{
                this.showLoader         = false;
                this.showFeedbackDrop   = true;
            })
        },
        computed:{
            ...mapState(['employees','feedbacks']),
            ...mapGetters(['infoEmployee',
            'getFlagLoadingFeedback', 
            'getMyFeedbacks',
            'getEmployeesList'])
        },
        methods: {
            ...mapActions(['loadMyProfileByEmpId', 
            'fetchMyFeedbackTypeList',
            'fetchApplyEmployees',
            'createFeedback']),

            customFormatter(date) {
                return moment(date).format('DD/MM/YYYY');
            },

            customFormat({FirstName, LastName1, EmpID}){
                return `${FirstName?.toUpperCase()} ${LastName1?.toUpperCase()} (${EmpID?.toUpperCase()})`;
            },

            loadInfoEmployee(){
                let idEmployee = auth.currentUser.EmpID;
                this.loadMyProfileByEmpId(idEmployee).then(() => {
                    this.loadingFlag = false;
                }, error => {
                    console.error("Error fetching the projects: "+ error)
                });
            },

            loadEmployees: function(){
                this.showFeedbackDrop   = false;
                this.showLoader         = true;

                let objData = { 
                    feedbackId: this.feedback_selected
                }
                this.fetchApplyEmployees(objData).then(()=>{
                    this.showEmployeeDrop   = true;
                    this.showLoader         = false;
                })
                
            },

            loadFeedbackForm: function(){
                this.showFeedbackForm = true;
                this.showEmployeeDrop = false;
            },

            closeModal: function(data){
                if(data){
                    this.showEmployeeDrop = false;
                    this.showFeedbackForm = false;
                    this.feedback_selected = null;
                    this.employee_selected = null;
                    this.showFeedbackDrop = true;
                }
            },

            agentSelected: function(data){        
                this.agent_selected = data;

                let idEmployee = this.agent_selected;
                this.loadMyProfileByEmpId(idEmployee).then(() => {
                    this.loadingFlag = false;
                }, error => {
                    console.error("Error fetching the projects: "+ error)
                });
            },

            goToNextCall: function(){
                this.fistCallSubmit = true;
                this.$v.feedbackCallOne.$touch();
                if (this.$v.feedbackCallOne.$invalid) {
                    this.$fire({
                        type: 'error',
                        title: 'Oops! there are some errors',
                        showConfirmButton: true
                    })
                    return;
                }
                this.second_feedback = true;
            },
            
            saveFeedback:function (){
                this.$store.commit('setLoadingFlag',true);
                this.secondCallSubmit = true;
                this.$v.feedbackCallTwo.$touch();
                if (this.$v.feedbackCallTwo.$invalid) {
                    this.$store.commit('setLoadingFlag',false);
                    this.$fire({
                        type: 'error',
                        title: 'Oops! there are some errors',
                        showConfirmButton: true
                    })
                    return;
                }
                let idEmployee = auth.currentUser.EmpID; //Add
                let feedbackData = {
                    audit_form:{
                        form: 'supervisor',
                        empID: this.agent_selected,
                        campaign: this.infoEmployee.Campaign,
                        auditor: idEmployee,

                    },
                    callOneInfo: this.feedbackCallOne,
                    callTwoInfo: this.feedbackCallTwo
                }
                this.createFeedback(feedbackData).then((response) => {
                    this.$store.commit('setLoadingFlag',false);
                    if(response.status == 201){
                        this.$fire({
                            type: 'success',
                            title: 'The feedback has been send successfully',
                            showConfirmButton: false,
                            timer: 1300
                        }).then(()=>{
                            location.reload();               
                        });
                    }else{
                        this.$fire({
                            type: 'error',
                            title: 'This module is temporary unabled, please report this issue with the SysAdmin.',
                            showConfirmButton: false,
                            timer: 1300
                        }).then(()=>{
                            // location.reload();               
                        });
                    }
                }, error => {
                    console.error("Error: " + error)
                });
            }
        },
      mounted(){
        let title = 'Feedback Form';
        this.$emit('set-title',title);
      }
    };
</script>
<style>
    .feedback_container{
        margin-top: 1em !important;
        margin-left: 19em !important;
        margin-right: 19em !important;
    }
    .vdp-datepicker * {
        box-sizing: none;
        border: none;
        width: 100%;
    }
    .hide{
        display: none !important;
    }
    .vdatetime-input{
        width: 100% important;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
</style>