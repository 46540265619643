<template>
    <div>
        <new-item class="my-4"/>
    </div>
</template>
<script>
import NewItem from '@/views/inventory/Forms/new'

export default {
    components:{
        NewItem
    },
    data() {
        return{ 

        }
    },
    mounted(){
        let title = 'Inventory: New Item';
        this.$emit('set-title',title);
    },
}
</script>
