<template>
    <div class="terminationModalContainer">
        <div class="block text-center mb-6">
            <h3>Terminate an Employee</h3>
            <div class="flex flex-col">
                <div class="flex flex-row">
                    <div class="text-sm text-blue-600 font-semibold font-sans uppercase py-1 px-2 mx-auto tracking-wider">{{profileInfo.FirstName}} {{profileInfo.LastName1}}</div>
                </div>
                <div class="flex flex-row">
                    <div class="text-xs text-gray-600 font-semibold font-sans uppercase bg-gray-200 rounded-full py-1 px-2 mx-auto">{{profileInfo.Title}}</div>
                </div>
                <div class="flex flex-row">
                    <div class="flex p-2 ml-auto">
                        <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                        </div>
                        <span class="flex flex-col p-1">
                            <div class="text-sm text-gray-600 font-semibold font-sans uppercase">{{profileInfo.EmpID}}</div>
                            <span class="text-xxs text-gray-400 font-semibold uppercase">Employee ID</span>
                        </span>
                    </div>
                    <div class="flex p-2 mr-auto">
                        <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                            </svg>
                        </div>
                        <span class="flex flex-col p-1">
                            <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">{{profileInfo.Location}}</div>
                            <span class="text-xxs text-gray-400 font-semibold uppercase my-auto">Site</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <form @submit="terminateSubmit" class="space-y-3">
                <!-- Voluntary or Involuntary -->
                <div id="Voluntary">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Voluntary or Involuntary? 
                    </label>
                    <div class="radiogroup flex text-center">
                        <input type="radio" v-model="terminationData.voluntary" class="my-auto mr-1" value="Yes" id="rad-voluntary"
                        @change="bringReasons"><label class="flex-1" for="rad-voluntary">Voluntary</label>
                        <input type="radio" v-model="terminationData.voluntary" class="my-auto  mr-1" value="No" id="rad-involuntary"
                        @change="bringReasons"><label class="flex-1" for="rad-involuntary">Involuntary</label>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.voluntary.$dirty" :valid="!$v.terminationData.voluntary.$invalid"></pill>
                    </div>
                </div>

                <!-- Reasons -->
                <div id="Reasons">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Reason: 
                    </label>
                    <div class="flex text-center">
                        <select :disabled="disableReason" @change="setReasons($event)"  class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" required>
                            <option value="null" hidden>{{optionText}}</option>
                                <optgroup v-for="(elements, key, index) in getReasons" :key="index" :label="key" >
                                    <option v-for="(event,index) in elements" :value="event[0]" :reason="key" :key="index">{{event[0]}}</option>
                                </optgroup>
                        </select>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.reason.$dirty" :valid="!$v.terminationData.reason.$invalid"></pill>
                    </div>
                </div>

                <!-- Rehirable -->
                <div id="Rehirable">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Is Rehirable? 
                    </label>
                    <div class="radiogroup flex text-center">
                        <input type="radio" v-model="terminationData.rehirable" class="my-auto mr-1" value="Yes" id="rad-rehirable"
                       ><label class="flex-1" for="rad-rehirable">Yes</label>
                        <input type="radio" v-model="terminationData.rehirable" class="my-auto  mr-1" value="No" id="rad-nonRehirable"
                       ><label class="flex-1" for="rad-nonRehirable">No</label>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.rehirable.$dirty" :valid="!$v.terminationData.rehirable.$invalid"></pill>
                    </div>
                </div>

                <!-- Ticket Tag -->
                <div id="TicketId">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Select the Ticket ID: 
                    </label>
                    <div class="flex text-center">
                        <multiselect :disabled="!showTicketSearch" class="multiselect-select"
                        v-model="terminationData.incidentID" :custom-label="customFormat" :options="FilteredIncidences" 
                        :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="false" 
                        placeholder="Select a Ticket" label="ID" track-by="ID"></multiselect>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.incidentID.$dirty" :valid="!$v.terminationData.incidentID.$invalid"></pill>
                    </div>
                </div>

                <!-- Attrition -->
                <div id="AttritionDate">
                    <label class="text-gray-700 font-semibold text-left text-md flex">
                        Last Day Worked: 
                        <div class="flex px-2">
                            <vs-tooltip position="right" text="Último día laborado">
                                <i class="bi bi-info-circle-fill"></i>
                            </vs-tooltip>
                        </div>
                    </label>
                    <div class="flex text-center">
                        <date-picker id="attritionDate" class="!block !w-full" input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2"
                        v-model="terminationData.attritionDate"
                        :disabled-date="ableOnlyPastButToday"
                        placeholder="Select a date" valueType="format" :editable="false" format="YYYY-MM-DD">
                        </date-picker>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.attritionDate.$dirty" :valid="!$v.terminationData.attritionDate.$invalid"></pill>
                    </div>
                </div>

                <!-- Termination Date -->
                <div id="TerminationDate">
                    <label class="text-gray-700 font-semibold text-left text-md flex"> 
                        Termination Date:
                        <div class="flex px-2">
                            <vs-tooltip position="right" text="Día de finalización de relación laboral">
                                <i class="bi bi-info-circle-fill"></i>
                            </vs-tooltip>
                        </div>
                    </label>
                    <div class="flex text-center">
                        <date-picker id="terminationDate" class="!block !w-full" input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2"
                        v-model="terminationData.terminationDate"
                        :disabled-date="ableOnlyPastButToday"
                        placeholder="Select a date" valueType="format" :editable="false" format="YYYY-MM-DD">
                        </date-picker>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.terminationDate.$dirty" :valid="!$v.terminationData.terminationDate.$invalid"></pill>
                    </div>
                </div>

                <!-- Resignation Signed Date -->
                <div id="Resignation">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Signed Resignation:
                    </label>
                    <div class="flex text-center">
                        <input type="checkbox" id="resignationDate" v-model="terminationData.resignationDate"
                        class="h-4 w-4 mr-2 focus:ring-blue-600 focus:border-blue-400 rounded my-auto sm:text-sm border-gray-500">
                        This person signed the resignation
                    </div>
                </div>

                <!-- Comment -->
                <div id="Comment">
                    <label class="text-gray-700 font-semibold text-left text-md block">
                        Comment: 
                    </label>
                    <div class="text-center justify-content-center align-middle">
                        <textarea v-model="terminationData.comments" placeholder="Write here..." 
                        class="resize-none w-full mx-auto rounded-md  border-dashed border-2 border-gray-300 p-2" rows="3" max-rows="3"></textarea>
                    </div>
                    <div class="relative flex flex-row w-full">
                        <pill :dirty="$v.terminationData.comments.$dirty" :valid="!$v.terminationData.comments.$invalid"></pill>
                    </div>
                </div>
                
                <!-- Save -->
                <div id="Save">
                    <div class="row mb-3 px-3">
                        <div class="text-center justify-content-center align-middle">
                            <button :disabled="sendingRequest" :class="{ 'disabled' : sendingRequest , 'hover:bg-blue-700' : !sendingRequest}"  type="submit" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full">
                                {{buttonText}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker';
import Pill from '@/views/mockups/ValidationPill';
import { required } from 'vuelidate/lib/validators';
export default {
    components: {
        Multiselect,
        DatePicker,
        Pill,
    },
    data(){
        return{
            striped: true,
            animate: true,
            percentage: 0,
            isHide: false,
            callbacks: 0,
            sendingRequest: false,
            buttonText: "Save",
            disableReason: true,
            optionText: "Select if voluntary termination",
            showTicketSearch: false,
            FilteredIncidences: [],
            terminationData: {
                voluntary: '',
                reason: '',
                sub_reason: '',
                rehirable: '',
                attritionDate: '',
                resignationDate: false,
                terminationDate: '',
                incidentID: '',
                comments: '',
                RecID: this.profileInfo.RecID,
                employeeID: this.profileInfo.EmpID
            },

            ja_reason_id: 10,
            ja_rehirable: 'Yes'
        }
    },
    validations () {
        return {
            terminationData: {
                attritionDate: {
                    required
                },
                voluntary: {
                    required
                },
                reason: {
                    required
                },
                rehirable: {
                    required
                },
                terminationDate: {
                    required
                },
                resignationDate: {
                    required
                },
                comments: {
                    required
                },
                incidentID: {
                  required
                }
            }
        }
    },
    computed:{
        ...mapState(['definitions', 'tickets']),
        ...mapGetters([
            'getReasons',
            'getTickets'
        ]),
    },
    props:{
        profileInfo: Object,
    },
    methods:{
        ...mapActions([
            'fetchReasons',
            'sendTermination',
            'fetchEmployeeTickets'
        ]),

        ableOnlyPastButToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        customFormat({ID, subCategoryName, ReportedBy}){
            return `ID: ${ID} , ${subCategoryName} reported by ${ReportedBy}
            `;
        },

        bringReasons: function (){
            this.optionText = "Loading reasons...";
            this.disableReason = true;
            this.terminationData.reason = null;
            let type = this.terminationData.voluntary === 'No' ? 'Involuntary' : 'Voluntary'
            this.fetchReasons(type).then(()=>{
                this.optionText = "Select an Option";
                this.disableReason = false;
            })
        },

        bringTickets:function(){
            this.showTicketSearch = false;
            this.terminationData.incidentID = null;
            this.fetchEmployeeTickets(this.profileInfo.EmpID).then(() => {
                this.filterItems(this.getTickets);
            })
        },

        terminateSubmit(){
            event.preventDefault();
            this.$v.terminationData.$touch();
            if(this.$v.terminationData.$invalid) return;
            let objData = {
                employee_id:        this.profileInfo.EmpID,
                type:               'Termination',
                reason:             this.terminationData.reason,
                sub_reason:         this.terminationData.sub_reason,
                voluntary:          this.terminationData.voluntary,
                rehirable:          this.terminationData.rehirable,
                attrition_date:     this.terminationData.attritionDate,
                sign_letter:        this.terminationData.resignationDate ? 'Yes' : 'No',
                termination_date:   this.terminationData.terminationDate,
                ticked_id:          this.terminationData.incidentID,
                request_id:         '',
                comment:            this.terminationData.comments,
            };
            this.sendingRequest = true;
            this.buttonText = "Sending...";
            
            this.sendTermination(objData).then(() =>{
                this.$fire({
                    type: 'success',
                    title: 'The employee has been terminated successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    this.$router.push('/employees') // This will send user to the Edit Employees view
                    this.$emit('closeTermination', true);               
                });
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: 'Oops, something went wrong!',
                    text: 'Please report to the system administrator',
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    this.sendingRequest = false;
                    this.buttonText = "Save"               
                });
            })
       },

        filterItems: function (items){
            const result = items.filter(item =>
                item.TicketSubCategoryId == '51'
            );
            this.FilteredIncidences = result;
            this.showTicketSearch = true;
        },

        setReasons(event){
          let trg = event.target.selectedOptions[0];
          this.terminationData.reason = trg?.getAttribute("reason");
          this.terminationData.sub_reason = trg.value;
        },
    },
    created () {
        this.bringTickets();
    }
}
</script>
<style lang="scss" scoped>
.radiogroup {
    font-size: 90%;
    border-radius: 30px;
    border: 2px solid #2563eb;
}
.radiogroup input[type="radio"] {
    display: none;
}
.radiogroup input[type="radio"] + label {
    display: inline-block;
    color: #2563eb;
    line-height: 2;
    padding: 0 15px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
}
.radiogroup input[type="radio"]:checked + label {
    color: #fff;
    background-color: #2563eb;
}
.disabled {
    cursor: not-allowed;
}
.multiselect {
    width: 100% !important;
    padding: 0;
}
.multiselect-select :deep(.multiselect__tags) {
    border: 0px;
}
.multiselect-select :deep(.multiselect__option--highlight) {
    background: #6074ff;
    outline: none;
}
.multiselect-select :deep(.multiselect__option--highlight):after {
    content: attr(data-select);
    background: none;
}
.multiselect-select :deep(.multiselect__option--disabled) span {
    font-size: small;
    font-weight: 600;
}
.multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight) {
    background: #6074ff; 
    /* red: #FF6A6A; */
}
.multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight):after {
    background: none;
    content: attr(data-deselect);
}
</style>