import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
        // Account Manager Hours Review
        CWBillable: [],
        CWPayable:  [],
        CWSummary:  [],
        LWBillable: [],
        LWPayable:  [],
        LWSummary:  [],
        LWRequests: [],
        CWRequests:[],
        AMHRDetailedDay: [],
        AMHRLastWeekDates: [],
        AMHRCurrentWeekDates:[],
        approvedDays:[],
        CWApprovedDates:[],
        
        //Requests
        approveStatus: 'failed',
        newRequestStatus: 'failed',
};
const getters = {
    // Account Manager Hours Review
    getCWBillable:                  (state) => state.CWBillable,
    getCWPayable:                   (state) => state.CWPayable,
    getLWBillable:                  (state) => state.LWBillable,
    getLWPayable:                   (state) => state.LWPayable,
    getLWSummary:                   (state) => state.LWSummary,
    getLWRequests:                  (state) => state.LWRequests,
    getCWRequests:                  (state) => state.CWRequests,
    getCWSummary:                   (state) => state.CWSummary,
    getAMHRDetailedDay:             (state) => state.AMHRDetailedDay,
    getRequestStatus:               (state) => state.newRequestStatus,
    getAMHRLastWeekDates:           (state) => state.AMHRLastWeekDates,
    getAMHRCurrentWeekDates:        (state) => state.AMHRCurrentWeekDates,
    getApproveState:                (state) => state.approveStatus,
    getApprovedDates:               (state) => state.approvedDays,
    getCWApprovedDates:             (state) => state.CWApprovedDates,
};
const actions = {

    async fetchCurrentWeekBillable ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/HRCurrentWeekBillable';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setCWBillable', res.data.CurrentWeekBillable);   
            return res;
        });
        return response;
    },

    async fetchCurrentWeekPayable ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/HRCurrentWeekPayable';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setCWPayable', res.data.CurrentWeekPayable);   
            return res;
        });
        return response;
    },

    async fetchLastWeekBillable ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/HRLastWeekBillable';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setLWBillable', res.data.LastWeekBillable);   
            return res;
        });
        return response;
    },

    async fetchLastWeekPayable ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/HRLastWeekPayable';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setLWPayable', res.data.LastWeekPayable);   
            return res;
        });
        return response;
    },

    async fetchLastWeekRequests ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/LWRequests';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setLWRequests', res.data.LastWeekPayableRequests);   
            return res;
        });
        return response;
    },
    async fetchCurrentWeekRequests ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/CWRequests';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setCWRequests', res.data.LastWeekPayableRequests);   
            return res;
        });
        return response;
    },

    async fetchLastWeekSummary ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/LWSummary';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setLWSummary', res.data.LWSummary);   
            return res;
        });
        return response;
    },

    async fetchCurrentWeekSummary ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/CWSummary';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setCWSummary', res.data.CWSummary);   
            return res;
        });
        return response;
    },

    async fetchAMHRDetailedDay ( {commit}, arrayData){
        commit('setDayApprovalStatus', null)
        const endpoint = API_URL + 'api/v1/clocker/accountManager/AMHRDetailedDay';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setAMHRDetailedDay', res.data.AMHRDetailedDay);
            commit('setDayApprovalStatus', res.data.dayStatus)
            return res;
        });
        return response;
    },

    // Checar en payroll events Reviewer y Reviewer Time y en Clocker_Approval_hours AMStatus 'Reviewed'
    async fetchAMHRLastWeekDates  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/LastWeekDates';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setAMHRLastWeekDates', res.data.LastWeekDates);   
            return res;
        });
        return response;
    },

    async fetchAMHRCurrentWeekDates  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/CurrentWeekDates';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setAMHRCurrentWeekDates', res.data.LastWeekDates);   
            return res;
        });
        return response;
    },

    async storeNewPayrollRequest  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/addNewRequest';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setRequestStatus', res.data.error);   
            return res;
        });
        return response;
    },

    async storeNewMissingShift  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/saveMissingShift';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setRequestStatus', res.data.error);   
            return res;
        });
        return response;
    },

    async approveAllDay  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/approveDay';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setApproveState', res.data.response);   
            return res;
        });
        return response;
    },

    async fetchApprovedDays  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/fetchApprovedDayStatus';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setApprovedDays', res.data.approvedDays);   
            return res;
        });
        return response;
    },

    async fetchCWApprovedDayStatus  ( {commit}, arrayData){
        const endpoint = API_URL + 'api/v1/clocker/accountManager/fetchCWApprovedDayStatus';
        let response = await axios.post( endpoint, arrayData).then( (res) =>{
            commit('setCWApprovedDates', res.data.approvedDays);   
            return res;
        });
        return response;
    },

};
const mutations = {
    setCWBillable:                  (state, paramStatus)    => (state.CWBillable                = paramStatus),
    setCWPayable:                   (state, paramStatus)    => (state.CWPayable                 = paramStatus),
    setLWBillable:                  (state, paramStatus)    => (state.LWBillable                = paramStatus),
    setLWPayable:                   (state, paramStatus)    => (state.LWPayable                 = paramStatus),
    setLWSummary:                   (state, paramStatus)    => (state.LWSummary                 = paramStatus),
    setLWRequests:                  (state, paramStatus)    => (state.LWRequests                = paramStatus),
    setCWRequests:                  (state, paramStatus)    => (state.CWRequests                = paramStatus),
    setCWSummary:                   (state, paramStatus)    => (state.CWSummary                 = paramStatus),
    setAMHRDetailedDay:             (state, paramStatus)    => (state.AMHRDetailedDay           = paramStatus),
    setRequestStatus:               (state, paramStatus)    => (state.newRequestStatus          = paramStatus),
    setAMHRLastWeekDates:           (state, paramStatus)    => (state.AMHRLastWeekDates         = paramStatus),
    setAMHRCurrentWeekDates:         (state, paramStatus)    => (state.AMHRCurrentWeekDates      = paramStatus),
    setApproveState:                (state, paramStatus)    => (state.approveStatus             = paramStatus),
    setApprovedDays:                (state, paramStatus)    => (state.approvedDays              = paramStatus),
    setCWApprovedDates:            (state, paramStatus) => (state.CWApprovedDates = paramStatus),
    setAMHRWeekDates:               (state, paramStatus) => (state.AMHRLastWeekDates = paramStatus),
};

export default {
    state,
    getters,
    actions,
    mutations
};
