<template>
    <div class="h-full flex">
        <div class="bg-white w-1/3 m-auto p-3 rounded-lg shadow-md">
            <form v-if="showCard" class="font-semibold flex flex-col" @submit="submitButton">
                <div class="mt-2">

                    <div class="flex flex-col my-2">
                        <span class="text-xl font-bold flex text-center justify-content-center">
                            Item Information 
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
                    </span>
                        <div class="flex text-center justify-content-center p-2 m-auto">
                            <div class="flex flex-col">
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                    Benefactor: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.benefactor}">{{this.benefactor || 'Not found'}}</span>
                                </span>
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    Custodian: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.custodian}">{{this.custodian || 'Not found'}}</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <label>Upload the file:</label>
                    <div class="w-full">
                        <drag-and-drop @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
                        <span class="text-xs text-gray-600 mt-1 text-left">
                            <span class="text-red-700">*</span>
                            File size limit: 3 MB. 
                        </span>
                    </div>
                </div>      
                
                <div class="flex flex-col my-2">
                    <button type="submit"
                    :disabled="loading || this.proof === null"
                    :class="{ 'bg-gray-300 pointer-default' : this.proof === null, 'bg-gray-700' : this.proof !== null }"
                    class="px-3 py-2 rounded-sm shadow-faded text-white uppercase text-sm">
                        <span v-if="loading">
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                        </span>
                        <span v-else>
                            Change Status
                        </span>
                    </button>
                    <span :hidden="this.proof !== null" class="text-xs font-light italic text-gray-700 mt-2"><span class="text-red-300">*</span>Upload a file first</span>
                </div>

            </form>
            <div v-else class="flex justify-content-center flex-col my-auto">
                We are loading the required information. Please wait
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop';

export default {
    components: {
        DragAndDrop
    },
    data() {
       return{
            showCard: false,
            submitted: false,
            loading: false,
            modalResetKey: 0,
            
            itemId: null,
            benefactor: null,
            custodian: null,
            proof: null,
            obj: null
       } 
    },
    validations() {
        return {
            proof:    { required },
        }
    },
    props:{ 
        itemData: { 
            type:Object,
        }
    },
  methods: {
        ...mapActions(['letterUploaded', 'getInventoryItemInfo', 'uploadImgInventory']),

        resetFileModal(){
            this.modalResetKey ++;
            this.proof = null;
        },
        setFileName(file){
            this.proof = file;
        },

        submitButton: function(){
        event.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.obj = {
            id: this.itemId,
        };

        this.$confirm("You're about to assign yourself as executor for this item", "Do you want to continue?", "question").then((result) => {
          if (result){
            this.loading = true;
            this.submitted = true;
            this.uploadImage().then(() => {
                this.letterUploaded(this.obj).then(()=>{
                    this.$fire({
                        type:'success',
                        title:'Item assigned successfully.',
                        showConfirmButton:false,
                        timer: 1500
                    });
                    this.$router.go(-1);
                })
                .catch((error)=>{
                    let message =  error.response.data.message ?? 'Something went wrong';
                    let indexEnd = message.indexOf('(SQL:');
                    let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                    this.$fire({
                        type: 'warning',
                        title: 'Something went wrong',
                        text:`${substringed}`,
                        showConfirmButton: true,                
                    });
                    this.loading = false;
                })
            })
          }
        })
        },
        uploadImage: async function(){
            if(this.proof instanceof File){
                try{
                    let form = new FormData()
                    form.append("image", this.proof);
                    const response = await this.uploadImgInventory(form);
                    this.obj.proof_id = response.data.id;
                }catch(error){
                    return this.$fire({
                        type: "error",
                        title: "Something went wrong with the file provided",
                        text: "Please try again with a different file type or report this issue to an administrator",
                        showConfirmButton: true,
                    });
                }
            }
        }
    },
    created(){
        this.itemId = this.$route.params.id;
        this.getInventoryItemInfo(this.itemId).then(() => {
            this.custodian = this.getItemData.Custodian;
            this.benefactor = this.getItemData.Benefactor;
            this.showCard = true;
        })
    },
    computed:{
        ...mapGetters([
            'employeeList',
            'getItemData'
        ]),
    },
    mounted(){
        let title = 'Inventory: Upload Responsive Letter ';
        this.$emit('set-title',title);
    },
}
</script>
