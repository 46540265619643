<template>
<div class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="offset-md-2 col-md-10 col-lg-8">
                <h1>Manage Permissions</h1>
                <div class="shadow p-3 mb-5 bg-white rounded">
                    <div class="form-group row">
                        <label  class="col-sm-2 col-form-label">Select a system role:</label>
                        <div class="col-sm-10">
                            <select class="custom-select d-block w-100" id="isBilingualUser" v-model="employee.isbilingual">
                                <option>Choose an option...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        data(){
            return{
                employee: {},
                selectedState: 0,
                stateID : 0,
                selectedProject: 0,
                selectedLob: 0,
                selectedCity: 0,
                errors: {},
                responseMessage: '',
                submitted: false,
                defaultNull: null,
                hideSelectCity: true,
                hideLabelCity: false,
                showLoadingCity: false,
            }
        },
        computed:{
        },
        methods: {
        },
        created(){
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>