<template>
    <div class="fixed inset-0 flex flex-row items-center justify-center z-[9000] overflow-auto">
      <div class="sticky inset-0 bg-slate-800 opacity-80 size-full" @click="closeDefault(outsideclick)">
      </div>
        <div class="absolute z-[9001] p-10 rounded-xl drop-shadow-lg bg-white"
        :class="width">
            <button class="absolute right-3 top-3 size-8 text-slate-300 hover:bg-[#f0f1f3] rounded-lg flex items-center justify-center" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" class="size-5 text-slate-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
            <slot name='content'>
                Template not found
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    props:{
        width:{
            type:String,
            default: 'w-1/3'
        },
        outsideclick:{
            type:Boolean,
            default: true
        },
        date:String
    },
    methods:{
        closeDefault(){
            if (this.outsideclick) this.$emit('close');
        },
        close(){
            this.$emit('close');
        },
    },
    computed:{
    },

    created(){
    },

}
</script>

<style>
    .shadow-faded{
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
</style>