<template>
    <div class="w-full h-full mt-4">
        <div class="flex flex-row">
            <div class="text-left font-sans font-semibold uppercase text-sm my-auto">
                Scheduled Changes
            </div>
            <div class="my-auto ml-auto font-sans font-semibold uppercase text-sm">
                <button title="Cancel current changes for this date" type="button" @click.prevent="cleanChanges()"
                :hidden="scheduleChangesIsEmpty"
                class="p-2 bg-red-400 rounded-md text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
        <table class="min-w-full table-auto bg-white divide-y divide-blue-50">
            <thead class="bg-gray-50">
                <tr>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        id
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        emp id
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        event
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        effective date
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        start time
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        end time
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        payable
                    </th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        billable
                    </th>
                </tr>
            </thead>
            <tbody v-if="getLoadingSchedules">
                <tr v-for="i in 2" :key="i">
                    <td class="p-2 py-4 m-auto" v-for="e in 8" :key="e">
                        <div class="space-y-3 animate-pulse">
                            <div class="grid grid-cols-3 gap-4">
                            <div class="h-2 bg-slate-300 rounded col-span-2"></div>
                            <div class="h-2 bg-slate-300 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-slate-300 rounded"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-if="scheduleChangesIsEmpty">
                    <td class="p-2 py-4 m-auto uppercase text-sm italic" scope="">
                        Events not Found
                    </td>
                </tr>
                <tr v-for="(event, index) in getScheduleChanges" :key="index" :id="event.id" :hidden="event.event === 'deleted'">
                    <td class="p-2 py-4 m-auto uppercase">
                        {{event.id}}
                    </td>
                    <td class="p-2 py-4 m-auto uppercase">
                        {{event.EmpID}}
                    </td>
                    <td class="p-2 py-4 m-auto uppercase w-48">
                        {{event.event}}
                    </td>
                    <td class="p-2 py-4 m-auto">
                        {{event.effectiveDate}}
                    </td>
                    <td class="p-2 py-4 m-auto">
                        {{event.startAt}}
                    </td>
                    <td class="p-2 py-4 m-auto">
                        {{event.endAt}}
                    </td>
                    <td class="p-2 py-4 m-auto">
                        {{event.payable}}
                    </td>
                    <td class="p-2 py-4 m-auto">
                        {{event.billable}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default{
    computed:{
        ...mapGetters([
            'getSchedule',
            'getLoadingSchedules',
            'getScheduleChanges'
        ]),

        scheduleChangesIsEmpty(){
            return this.getScheduleChanges?.length === 0;
        }
    },
    methods: {
        ...mapActions([
            'cancelScheduleChange',
            'fetchScheduleChange'
        ]),

        cleanChanges: function(){
            this.$confirm("", "Do you want to cancel the programmed changes?", "question").then((result) => {
                if (result){                
                    let obj={
                        empid: this.$route?.params?.empid,
                        date: this.$route?.params?.date
                    };
                    this.cancelScheduleChange(obj).then(()=>{
                        this.$emit('toggle', true);
                    })
                }
            })
        }
        

    },
    created(){
        let obj={
            empid: this.$route?.params?.empid,
            date: this.$route?.params?.date
        };
        this.fetchScheduleChange(obj);
    },
}
</script>
<style scoped>
</style>