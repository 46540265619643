<template>  
  <div class="EditInformationModalContainer" id="modal-changepassword">
    <loading id='ModalLoader' :active.sync="getLoadingModal" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <div class="block border-b text-center">
      <h3>Change my Password</h3>
    </div>
    <div>
      <b-form ref="content" @submit="changePassword">
        <div class="mt-3 text-center">
          <label>Username: <b>{{ this.profileInfo.EmpID }}</b></label>
        </div>
        <div class="flex space-x-5">
          <div class="flex-1">
            <p class="uppercase text-sm text-slate-800 text-left mb-0">New password</p>
            <input class="border w-full rounded-lg px-6 py-2 text-lg" @input="scorepassword" maxlength="21" placeholder="..." :class="{'is-invalid': submitted && $v.newPassword.$error }" v-model="newPassword" type="password" />
            <div v-if="submitted && !$v.newPassword.required" class="invalid-feedback" >This field is required!</div>
            <p class="uppercase text-sm text-slate-500 text-right mb-0 mt-1">{{ newPassword.length }}/21</p>
          </div>
          <div class="flex-1">
            <p class="uppercase text-sm text-slate-800 text-left mb-0">Confirm the new password</p>
            <input class="border w-full rounded-lg px-6 py-2 text-lg" :maxlength="21" placeholder="..." :class="{'is-invalid': submitted && $v.confirmPassword.$error }" v-model="confirmPassword" type="password" />
            <div v-if="submitted && !$v.confirmPassword.required & !$v.confirmPassword.sameAsPassword" class="invalid-feedback" >Passwords doesn't match!</div>
          </div>
        </div>
        <div class="my-6 text-slate-400">
          <div class="relative w-full h-2 rounded-full bg-slate-100 overflow-hidden mb-3">
            <div class="absolute inset-0 bg-gradient-to-r from-red-600 via-yellow-400 to-green-600"></div>
            <div class="absolute inset-y-0 right-0 bg-slate-100" :class="'w-['+scoreporc+'%]'"></div>
          </div>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':newPassword.length >= 12}"><i class="bi" :class="newPassword.length >= 12 ? 'bi-check' : 'bi-x'"></i> Min. 12 characters</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_uppercase }"><i class="bi" :class="has_uppercase ? 'bi-check' : 'bi-x'"></i> Has a capital letter</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_lowercase }"><i class="bi" :class="has_lowercase ? 'bi-check' : 'bi-x'"></i> Has a lowercase letter</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_number }"><i class="bi" :class="has_number ? 'bi-check' : 'bi-x'"></i> Has a number</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_special }"><i class="bi" :class="has_special ? 'bi-check' : 'bi-x'"></i> Has a special character</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':!hasnt_user }"><i class="bi" :class="!hasnt_user ? 'bi-check' : 'bi-x'"></i> Must not contain your username</p>
        </div>
        <b-row class="mt-4 ml-5 mr-5" align-h="center">
            <button type="submit" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full" v-if="scoreporc==0 && confirmPassword==newPassword">Save</button>
        </b-row>
            </b-form>
        </div>
    </div>  
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, sameAs } from 'vuelidate/lib/validators';
export default {
    components: { Loading },
    data(){
        return {
        submitted: false,
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        newPassword: "",
        confirmPassword: "",
        postData: [],
        terminationData: [],
        scoreporc: 100,
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        hasnt_user: false

    };
  },
        computed: {
        ...mapState(["clockerUsers", "session"]),
        ...mapGetters(['getLoadingModal'])
    },
      validations: {
        newPassword:      { required },
        confirmPassword:  { 
        sameAsPassword: sameAs('newPassword')
      },
    },
    props: {
        profileInfo: Object
    },
    methods:{
      ...mapActions(["setNewPassword","logoutUser"]),
      
      scorepassword() {
        this.has_number = (/\d/).test(this.newPassword);
        this.has_lowercase = (/[a-z]/).test(this.newPassword);
        this.has_uppercase = (/[A-Z]/).test(this.newPassword);
        this.has_special = (/[ `!@#$%^&*()_+\-={};':"\\|,.<>?~]/).test(this.newPassword);
        this.hasnt_user = (this.newPassword.toLowerCase()).includes(this.profileInfo.EmpID.toLowerCase());

        var score = 0;
        score = score+(this.newPassword.length >= 12 ? 16.66:0);
        score = score+(this.has_number ? 16.66:0);
        score = score+(this.has_lowercase ? 16.66:0);
        score = score+(this.has_uppercase ? 16.66:0);
        score = score+(this.has_special ? 16.66:0);
        score = score+(this.hasnt_user ? 0:16.7);

        this.scoreporc = 100-score;
      },

      changePassword() {
        event.preventDefault();
        this.submitted = true;

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$fire({
            type: 'error',
            title: 'Oops! there are some errors',
            showConfirmButton: true
          })
          return;
        }

        const postData = {
          employeeID: this.profileInfo.EmpID,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
        };

        this.$confirm("This will change your password from EMS", "Are you sure you want to continue?", "warning").then((result) => {
          if (result) {
            this.setNewPassword(postData).then(response => {
              if (response.status == 200) {
                this.$fire({
                  type: "success",
                  title: "Password changed", 
                  text: "You'll be logged out",
                  showConfirmButton: false,
                  timer: 1500
                }).then(() => {
                  this.$emit('closePassword', true);
                  this.logout();
                });
              } else if(response.status == 400) {
                this.$fire({
                  type: 'error',
                  title: response.msg,
                  showConfirmButton: true
                })
              } else if(response.status == 201) {
                this.$fire({
                  type: 'error',
                  title: response.data.msg,
                  showConfirmButton: true
                })
              }
            });
          }
        })
      },
      logout: async function(){
          try {
            this.logoutUser().then(() => {
              setTimeout(window.location.reload.bind(window.location), 500);
            });
          } catch (error) {
            this.$fire({
              type: 'error',
              title: 'There was an error loging you out',
              showConfirmButton: true
            });
          }
      }

    }
};
</script>
<style lang="scss" scoped>
</style>