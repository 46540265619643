<template>
    <div class="bg-white">
    <table v-if="showTable" class="table-sm table bg-white rounded-md">
        <thead class="bg-gray-100">
            <tr>
                <th colspan="13">
                    <div class="text-center justify-content-center align-middle text-xs flex">
                        <svg class="w-4 h-4 mr-1 flex" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        Special Payroll Requests Found
                    </div>
                </th>
            </tr>
            <tr>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Employee ID</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Campaign</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Date</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Requested By</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Reason</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Comment</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Status</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Std Billable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Grv Billable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Std Payable</th>
                <th class="py-3 text-xs font-medium text-gray-700 uppercase tracking-wider text-center">Grv Payable</th>
            </tr>
        </thead>

        <tbody v-if="this.getSpecialRequests.length > 0" class="h-full bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in this.getSpecialRequests" :key="item.ID" :id="item.ID">
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Emp_id}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Campaign}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.event_date}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.RequestedBy}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{item.Reason === null ? 'Not Specified' : item.Reason}}</td>
                <td class="p-3 mt-1 align-items-center justify-content-center" style="cursor: pointer;">
                    <div class="flex flex-auto align-items-center justify-content-center" v-b-modal="'seeMessageModal'+index">
                        See<svg class="w-6 h-6 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
                    </div>
                    <b-modal :ref="'seeMessageModal'+index" :id="'seeMessageModal'+index" centered hide-footer>
                        {{item.Comment}}
                    </b-modal>
                </td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle font-bold">{{item.Status}}</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{secondsToTime(item.stdbill)}} hrs.</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-gray-100">{{secondsToTime(item.gravbill)}} hrs.</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle">{{secondsToTime(item.stdpay)}} hrs.</td>
                <td class="p-2 text-sm text-gray-900 capitalize text-center align-items-center justify-content-center align-middle bg-gray-100">{{secondsToTime(item.gvypay)}} hrs.</td>
            </tr>
        </tbody>

        <tbody v-else class="col-span-6">
            <tr>
                <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="13">
                    No records found.
                </td>
            </tr>
        </tbody>

    </table>
    </div> 

</template>
<script>
import { mapGetters, mapState, mapActions} from "vuex";
export default {

    data() {
        return {
            FilteredEmails: [],
            buttonLoading: false,
            buttonText: "Agree",

            loadingTable:   false,
            showTable:      false,
        }
    },

    computed: {
        ...mapState([
            "payroll"
        ]),
        ...mapGetters([
            "getSpecialRequests"
        ])
    },

    props:{
        employeeId:     Object,
        dateSelected:   String
    },

    methods: {
        ...mapActions([
            "fetchSpecialPayrollRequestsPerEmpID"
        ]),

        secondsToTime(SECONDS){
            let time = new Date(Math.abs(SECONDS) * 1000).toISOString().substr(11, 5)
            return time;
        }

    },

    created(){
            this.loadingTable = true;
            let eventInfo = {
                EmpID:  this.employeeId.EmpID,
                date:   this.dateSelected
            }
            this.fetchSpecialPayrollRequestsPerEmpID(eventInfo).then( ()=>{
                this.$emit('loadingComplete', true);
                this.loadingTable = false;
                this.showTable    = true;

                let disableButton = this.getSpecialRequests.length > 0 ? true : false;
                if (disableButton) {
                    this.$emit('DisableButton', true)
                }
            });
    },
}

</script>

<style scoped>
.inactive-button{
    cursor: wait;
    display: hide;
}

</style>