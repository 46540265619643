<template>
    <div class="mx-auto flex flex-row  align-items-center justify-content-center text-gray-200 focus-within:text-gray-600">
      <div class="bg-white  flex-1 p-3 px-2 flex flex-row align-items-center justify-content-center">
            <search class="flex-1" :val="formData.searchString" v-on:searching="setQuery"/>
        <div class="relative w-1/3 pt-3 ml-2 pb-3 pl-2 ">
             <select class="mr-2 border-2 border-gray-200 w-full p-2 rounded-sm bg-gray-100" v-model="formData.location" required>
                <option value="AGS">Aguascalientes</option>
                <option value="MTY">Monterrey</option>
                <option value="QRO">Queretaro</option>
                <option value="">All locations</option>
            </select>
        </div>      
         <a class="ml-4 bg-gray-700 shadow-md text-white rounded-sm hover:bg-gray-900 p-2 px-4" href="#" @click.prevent="$router.push({name:'trainings.create'})">
        New
      </a>
      </div>
     
  </div>
</template>
<script>

import debounce from 'debounce';
import Search from '@/views/mockups/SearchableInput'
export default {
    components:{   
        Search
    },
    data(){
        return{
            formData:{
                location:"",
                searchString:null
            }
        }
    },
    methods:{      
        setQuery:function(searchString){
            this.formData.searchString = searchString;            
        },
    },
    watch:{
        formData:{
            handler:debounce(function(){               
               this.$emit('toggle',this.formData);
            },500),
            deep:true
        }
    }
}
</script>