<template>
    <!--#Data-placeholder-->
    <row-placeholder v-if="panelsAreLoading" :cols="7"/>
    <!--#Data-display-->
    <tr v-else>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" v-for="(date,index) in getDateRange" :key="index">
               <span v-if="hasStatus(date,'future')"  class="cursor-pointer" title="Can only accept dates lower than today.">
                        Not available yet
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                    </svg>
                    .
                </span>
                <span v-else-if="hasStatus(date,'in_revision')">
                    In revision 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-500 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    .
                </span>

                 <span v-else-if="hasStatus(date,'verified')">
                     Verified
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500 ml-1 inline" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </span>
                
                <button class="px-3 py-2 bg-green-700 rounded-sm shadow-md  text-white" v-else @click.prevent="approveDay(date)" :disabled="getHoursManagmentStatus.buttons"> 
                    Accept
                </button>
        </td>
    </tr>
</template>

<script>
import RowPlaceholder from './HoursManagementRowPlaceholder.vue'
import {mapGetters,mapActions} from 'vuex'
import permissions from '@/config/mixins'
export default {
    mixins:[permissions],
    components:{
            RowPlaceholder,
    },
    methods:{
        
        ...mapActions(['fetchApprovalButtons','approveDate']),
         hasStatus:function(date , status){
             
            let agentStatus = this.getApprovalButtons[date];

            if(!Array.isArray(agentStatus)) return 'future';
      
            return agentStatus[0].AgentStatus.toLowerCase() == status.toLowerCase();
            
        },
        approveDay:async function(date){
            let event = this.getPayableHours.find(event => event.Event_Date == date);
            let  hours = event ? event?.Total_Hours : '00:00:00';
            let lob = this.getEmployeeDetails.lob;
            let campaign = this.getEmployeeDetails.campaign;

           const {value:response} = await this.$fire({
               type:'question',
               title:'Confirm action',
               text :`Are you sure you want to accept the "${hours}" hours for the day "${date}" and campaign "${campaign}" and  LOB "${lob}"`,
               showCancelButton: true,
                confirmButtonText: 'Yes, I accept',
                cancelButtonText: 'No, cancel!',
           })

           if(!response) return false;
            
            try{
                 let res = await this.approveDate(date);
                 
                 if(res.status >= 200 && res.status <= 290){

                    this.$fire({
                        type:'successs',
                        title:'Action completed.',
                         showConfirmButton: true,
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        }
                    }).then(() => this.$router.go(0));
                     
                 }else{
                     this.$fire({
                         type:'error',
                         title:'Something went wrong. Try to reload the page. If the error persist please contact someone from it team.',
                     })
                 }
            

            }catch(e){
                console.log(e);
            }
        }
       
    },
    props:{
        loadPrevious:{
            default:false,
            type:Boolean,
        }
    },
    computed:{
        ...mapGetters(['getApprovalButtons','getPayableHours','getEmployeeDetails','getHoursManagmentStatus','getDateRange']),
        panelsAreLoading:function(){
            let  pending = Object.values(this.getHoursManagmentStatus).filter(item => !!item).length > 0 ?? false;
        
            return pending;
        }   
    },
    created(){
        this.fetchApprovalButtons(this.loadPrevious);
    }
}
</script>