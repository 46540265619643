<template>
    <b-container v-if="this.showTable" class="py-3 rounded-md">
      <!-- <div class="custom-control custom-switch mt-2 mb-2">
          <input v-model="toggle" @change="toggleChange" type="checkbox" class="custom-control-input" id="customSwitch1">
          <label v-if="!toggle" class="custom-control-label" for="customSwitch1">Inactive Surveys</label>
          <label v-else class="custom-control-label" for="customSwitch1">Active Surveys</label>
      </div> -->
      <table class="divide-y divide-gray-200 border-b border-gray-200 w-full shadow-md">
        <thead class="bg-gray-50">
          <tr>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">ID</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">EmpID</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Campaign</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Site</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Date</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Duration</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Incidence</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Ticket Linked</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Created At</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody v-if="getIncidencesList.length > 0" class="bg-white divide-y divide-gray-200">
          <tr :id="item.id" v-for="(item, index) in this.getIncidencesList" :key="item.id">
              <td class="p-2">{{item.id}}</td>
              <td class="p-2">{{item.EmpID}}</td>
              <td class="p-2">{{item.Campaign}}</td>
              <td class="p-2">{{item.Site}}</td>
              <td class="p-2 flex">{{item.EventDate | moment("YYYY-MM-DD")}}</td>
              <td class="p-2">{{item.Duration == 0 ? 'Full Day' : secToTime(item.Duration)}}</td>
              <td class="p-2">{{item.IncidenceLabel}}</td>
              <td class="p-2">{{item.TicketID}}</td>
              <td class="p-2">{{item.CreatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
              <td v-if="item.Status === 'PENDING'" class="p-2 flex flex-row">
                <div class="ml-2 flex flex-auto align-items-center justify-content-center">
                  <vs-tooltip text="Edit Date"><svg v-b-modal="'EditModal'+index"  class="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg></vs-tooltip>
                  <b-modal :ref="'EditModal'+index" :id="'EditModal'+index" hide-footer hide-header centered>
                      <form class="text-center justify-content-center" @submit.prevent="sendRequest(item.id, index)">
                          <div class="text-center justify-content-center justify-items-center flex">
                              <svg class="w-12 h-12" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                          </div>
                          <h4>Edit Incidence ID: {{item.id}}</h4>
                          <!-- Edit Date -->
                            <div class="flex px-3">
                              <label class="bg-gray-200 p-2 rounded-full my-3 font-bold text-sm mr-1">
                                Current Date: {{item.EventDate | moment("YYYY-MM-DD")}}
                              </label>
                              <label class="bg-gray-200 p-2 rounded-full my-3 font-bold text-sm mr-1">
                                Current Type: {{item.IncidenceLabel}}
                              </label>
                              <label class="bg-gray-200 p-2 rounded-full my-3 font-bold text-sm">
                                Current Duration: {{item.Duration == 0 ? 'Full Day' : secToTime(item.Duration)}}
                              </label>
                            </div>

                          <p class="font-bold">Select a new Date for this incidence:</p>
                          <p>
                            <input type="date" v-model="newDate" class="shadow-md p-2 rounded-md border-b-2 border-blue-500 text-gray-400" @change="thisEdited('EventDate', item.EventDate)">
                          </p>

                          <!-- Edit Type -->
                          <p class="font-bold">Select a new Incident Type for this incidence:</p>
                          <p>
                            <select id="incidenceId" for="survey_description" class="text-gray-600 text-md border-b-2 border-blue-500 rounded-md bg-white py-1 px-2" v-model="newType" @change="thisEdited('IncidenceType', item.IncidenceType)">
                                <optgroup label="Select an Incidence Type">
                                    <option value="null" hidden>Select an Option</option>
                                    <option v-for="item in getCatalog" :value="item.Id" :key="item.Id">{{item.IncidenceLabel}}</option>
                                </optgroup>
                            </select>
                          </p>

                          <!-- Full or Partial Day-->
                          <p class="font-bold">Duration Type:</p>
                            <div class="w-full text-center align-middle flex-1 text-gray-600 text-lg rounded-md py-1 px-2 m-2">
                              <div class="flex">
                                  <div class="flex-1">
                                      <div class="flex text-center justify-content-center align-middle">
                                          <input type="radio"  v-model="timeType" name="incident_time_type" class="my-auto mr-1" value="Full" @change="fullDaySelected(item.Duration)"> Full Day
                                      </div>
                                  </div>
                                  <div class="flex-1">
                                      <div class="flex text-center justify-content-center align-middle">
                                          <input type="radio"  v-model="timeType" name="incident_time_type" class="my-auto  mr-1" value="Parcial"> Partial Day
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Edit Duration -->
                          <div v-if="timeType == 'Parcial'">
                            <p class="font-bold">Select a new Duration for this incidence:</p>
                            <p>
                              <vue-timepicker class="flex-1 border-b-2 border-blue-500 text-gray-600 text-lg shadow-md rounded-md bg-white py-1 px-2 m-2 z-20" v-model="newDuration" format="HH:mm" close-on-complete @change="thisEdited('Duration', item.Duration)"></vue-timepicker>
                            </p>
                          </div>

                          <!-- Send Button -->
                          <button type="submit"
                          :class="{ 'inactive-button' : buttonLoading }" class="align-items-center justify-content-center bg-gray-700 hover:bg-gray-900 text-center text-white p-2 rounded-md shadow-md"
                          :disabled=" buttonLoading ">
                              {{buttonText}}
                          </button>
                      </form>
                  </b-modal>
                </div>

                <div class="ml-2 flex flex-auto align-items-center justify-content-center">
                  <vs-tooltip text="Approve Incidence"><svg  style="cursor: pointer;" @click="ApproveItem(item.id)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></vs-tooltip>
                </div>

                <div class="ml-2 flex flex-auto align-items-center justify-content-center">
                  <vs-tooltip text="Decline Incidence"><svg  style="cursor: pointer;" @click="DeclineItem(item.id)" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>    </vs-tooltip>
                </div>
               </td>
              <td v-else>
                <div class="ml-2 flex flex-auto align-items-center justify-content-center">
                  <vs-tooltip text="Undo">
                    <svg style="cursor: pointer;" @click="UndoItem(item.id)" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                    </svg>
                  </vs-tooltip>
                </div>
              </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="12">No records found.</td>
          </tr>
        </tbody>
      </table>
    </b-container>

    <div v-else class="text-center py-5">
      <p class="font-italic font-weight-bold">Loading...</p>
      <p class="font-italic font-weight-bold">
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </p>              
        
    </div>
</template>
<script>

  import {mapGetters,mapState, mapActions } from 'vuex'
  import VueTimepicker from 'vue2-timepicker'
  import time from '@/mixins/time'
  export default {
    mixins:[time],
      data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        showTable: false,
        timeType:     null,
        newDate:      null,
        newType:      null,
        newDuration:  {
          "HH": "",
          "mm": "",
        },

        SurveysSelected: null,
        SurveyFilter: null,
        SurveysActive: null,
        SurveysInactive: null,
        toggle: true,

        buttonLoading: false,
        buttonText: "Agree",

        changes: [
          { value: "EventDate",       status: false,  previousValue: null,  newValue: null },
          { value: "Duration",        status: false,  previousValue: null,  newValue: null },
          { value: "IncidenceType",   status: false,  previousValue: null,  newValue: null }
        ],
      }
    },
    components: {
        VueTimepicker
    },
    computed:{
      ...mapState(['incidences']),
      ...mapGetters(['getIncidencesList',
      'getCatalog'
      ])
    },
    created(){
      // this.fetchIncidences().then(()=>{
        // this.filterItems(this.getSurveys);
        this.SurveysSelected = this.getIncidencesList;
        this.showTable = true;
      // })
    },
    methods:{
      ...mapActions(['fetchIncidences',
      'changeIncidenceStatus',
      'editIncidence',]),

      thisEdited: function(data, oldData){
        if(data == 'EventDate') {
            
            // Push to array
            if(oldData !== this.newDate) { 
              this.changes[0].status = true; 
              this.changes[0].previousValue = oldData;
              this.changes[0].newValue = this.newDate; 
            }else{ 
              this.changes[0].status = false; 
              this.changes[0].previousValue = null;
              this.changes[0].newValue = null; 
            }
         
         }else if(data == 'Duration'){
           let timeToSecs = 0;
           if(this.newDuration.HH !== '' && this.newDuration.mm !== ''){
              timeToSecs = (this.newDuration.HH * 60 * 60) + (this.newDuration.mm * 60)
              
              // Push to array
              if(oldData !== timeToSecs) { 
                this.changes[1].status = true; 
                this.changes[1].previousValue = oldData;
                this.changes[1].newValue = timeToSecs; 
              }else{ 
                this.changes[1].status = false; 
                this.changes[1].previousValue = null; 
                this.changes[1].newValue = null; 
              }
            
            }
         }else if(data == 'IncidenceType'){
           
            // Push to array
            if(parseInt(oldData) !== parseInt(this.newType)) { 
              this.changes[2].status = true; 
              this.changes[2].previousValue = parseInt(oldData);
              this.changes[2].newValue = this.newType; 
            }else{ 
              this.changes[2].status = false; 
              this.changes[2].previousValue = null; 
              this.changes[2].newValue = null; 
            }

         }
      },

      fullDaySelected(oldValue){ 
        this.changes[1].status = true; 
        this.changes[1].previousValue = oldValue;
        this.changes[1].newValue = 0; 
      },

      toggleChange: function(){
        this.SurveyFilter === 1 ? this.SurveysSelected = this.SurveysInactive : this.SurveysSelected = this.SurveysActive;
        this.SurveyFilter === 1 ? this.SurveyFilter = 0 : this.SurveyFilter = 1;
      },

      filterItems: function (items){
        const result = items.filter(item => item.Status === 1);
        this.SurveysActive = result;
        this.SurveysSelected = this.SurveysActive;
        this.SurveyFilter = 1;

        const result2 = items.filter(item => item.Status === 0);
        this.SurveysInactive = result2;

        this.showTable = true;
      },

      ApproveItem(data){
        this.$confirm("It will no longer be in the list", "Do you want to approve incidence ID: "+data+"?", 'question').then((result) => {
          if (result){
              let objData = { idSelected: data, newStatus: 'APPROVED' }
              this.changeIncidenceStatus(objData).then(()=>{
                this.$fire({
                  type: "success",
                  title: "Incidence Approved",
                  showConfirmButton: false,
                  timer: 2300,
                }).then(()=>{
                  this.$emit('updateAll', true);                
                }); 
              }).catch((error)=>{
                let message =  error.response.data.message ?? 'Something went wrong';
                let indexEnd = message.indexOf('(SQL:');
                let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                this.$fire({
                    type: 'warning',
                    title: 'Something went wrong',
                    text:`${substringed}`,
                    showConfirmButton: true,                
                });
              })
          }
        })
      },

      DeclineItem(data){
        this.$confirm("It will no longer be in the list", "Do you want to decline incidence ID: "+data+"?", 'question').then((result) => {
            if (result){
                let objData = { idSelected: data, newStatus: 'DECLINED' }
                this.changeIncidenceStatus(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Incidence Declined",
                      showConfirmButton: false,
                      timer: 2300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                }).catch((error)=>{
                  let message =  error.response.data.message ?? 'Something went wrong';
                  let indexEnd = message.indexOf('(SQL:');
                  let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                  this.$fire({
                      type: 'warning',
                      title: 'Something went wrong',
                      text:`${substringed}`,
                      showConfirmButton: true,                
                  });
                })
            }
            })
      },
      UndoItem(data){
        this.$confirm("This incidence will be sent back to pending", "Do you want to undo action taken in incidence ID: "+data+"?", 'question').then((result) => {
          if (result){
            let objData = { idSelected: data, newStatus: 'PENDING' };
            this.changeIncidenceStatus(objData).then(()=>{
                this.$fire({
                  type: "success",
                  title: "Incidence Recovered",
                  showConfirmButton: false,
                  timer: 2300,
                }).then(()=>{
                this.$emit('updateAll', true);                
                }); 
            });
          }
        })
      },

      sendRequest:function(id, index){
            this.buttonText = "Sending...";
            this.buttonLoading = true;
            let timeToSeconds = (this.newDuration.HH * 60 * 60) + (this.newDuration.mm * 60);
            
            let objectData = {
                idSelected: id,
                changes: this.changes,
                newDate: this.newDate,
                newType: this.newType,
                newDuration: timeToSeconds
            }
            console.log(objectData, index)
            this.editIncidence(objectData).then(() => {
                this.$fire({
                    type: 'success',
                    title: 'Changes Applied!',
                    showConfirmButton: false,
                    timer: 2500
                }).then(() => {
                    this.$refs['EditModal'+index][0].hide();
                    this.buttonLoading = false;
                    this.buttonText = "Agree";
                    this.$emit('updateAll', true);
                    this.newDate = null;
                    this.newType = null;
                    this.timeType = null;
                    this.newDuration = {
                       "HH": "",
                       "mm": "",
                     };
                     this.changes =  [
                        { value: "EventDate",       status: false,  previousValue: null,  newValue: null },
                        { value: "Duration",        status: false,  previousValue: null,  newValue: null },
                        { value: "IncidenceType",   status: false,  previousValue: null,  newValue: null }
                      ];
                })
            }).catch(() => {
              this.$fire({
                    type: 'warning',
                    title: 'There is some coflicts with the date you entered or something went wrong',
                    text: 'Please review your changes',
                    showConfirmButton: true
                }).then(() => {
                    this.buttonLoading = false;
                    this.buttonText = "Agree";
                })
            })
        },

    },
  }

</script>
<style lang="scss" scoped>
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>