<template>
  <div>
    <loading :active.sync="getFlagLoadingSuggestion" :loader="loader" :background-color="bgcolor" :color="color"></loading>
    <div class="flex flex-row">
        <div class="mr-3 w-[250px]">
            <suggestion-filter @toggle="updateList" />
            <my-suggestion-list :filter_data="filter_search"
            :key="listKey"
            @suggestion_selected='loadSuggestion' />
        </div>
        <div class="grow flex-1 space-y-2" v-if="suggestionLoaded">
            <!-- Card de informacion -->
            <div class="bg-white rounded-sm shadow-sm">
                <div class="flex border-bottom px-8 py-3 text-left">
                    <h5 class="flex-auto font-semibold m-0">Número de Reporte: <span class="font-normal">{{getSuggestion.id}}</span></h5>
                    <h5 class="flex-none font-semibold m-0">Clasificación: <span class="font-normal">{{getSuggestion.Sugg_Type === 'Complain' ? 'Queja' : 'Sugerencia'}}</span></h5>
                </div>
                <div class="p-8">
                    <div class="flex flex-row mb-3">
                        <div class="pr-6"><i class="bi bi-person-fill text-slate-700 text-[300%]"></i></div>
                        <div class="text-left">
                            <h6 class="font-semibold text-slate-700 px-3">{{getSuggestion.EmpID}}</h6>
                            <div class="relative font-medium p-3 bg-gray-100 rounded-b-md rounded-tr-md">
                                <div class="absolute top-0 right-full border-t-[15px] border-l-[15px] border-t-gray-100 border-l-transparent triangle-top-right"></div>
                                {{getSuggestion.Description}}
                            </div>
                        </div>
                    </div>
                    <span v-if="getSuggestion.viewed_at !== null && getSuggestion.updated_at !== null" class="inline-block bg-green-500 text-white text-xs uppercase font-semibold rounded-[5px] px-3 py-1">Resuelto el  {{getSuggestion.updated_at | moment('YYYY/MM/DD, h:mm:ss a')}}</span>
                    <span v-if="getSuggestion.viewed_at !== null && getSuggestion.updated_at === null" class="inline-block bg-blue-500 text-white text-xs uppercase font-semibold rounded-[5px] px-3 py-1">En Revisión desde {{getSuggestion.viewed_at | moment('YYYY/MM/DD, h:mm:ss a')}}</span>
                    <span v-if="getSuggestion.viewed_at === null && getSuggestion.updated_at === null" class="inline-block bg-c-primary text-white text-xs uppercase font-semibold rounded-[5px] px-3 py-1">Nuevo</span>
                </div>
                <!-- Enviar comentarios o ver los existentes -->
                <div class="border-top px-8 py-7 text-left">
                    <h6 class="font-semibold text-slate-700"><span class="text-red-400">*</span> Comentarios</h6>
                    <div v-if="this.commentsLoaded == null" class="flex space-x-2">
                        <textarea maxlength="1000" class="w-full bg-gray-100 px-3 py-2 rounded-md resize-none" :class="{'border-2 border-red-400': isAcknowledge && $v.agentComment.$error }" v-model="agentComment" placeholder="Por favor escriba aquí..."></textarea>
                        <button @click="sendInfo" 
                        :disabled="isLoading"
                        class="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-md leading-none">
                            <span v-if="!isLoading" class="text-white text-sm uppercase leading-none">
                                Enviar Respuesta
                            </span>
                            <span v-else class="text-white text-sm uppercase leading-none">
                                Enviando...
                            </span>
                        </button>
                    </div>
                    <div v-else>
                        <div class="p-3 mb-2 bg-light text-dark">{{this.commentsLoaded}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import Loading              from 'vue-loading-overlay';
    import MySuggestionList     from "@/views/engagement/review_suggestion_list"
    import SuggestionFilter    from '@/views/engagement/suggestion_filters'
    import { required }         from 'vuelidate/lib/validators';
    import auth                 from "@/config/user"
    export default {
        name: "",
        components: { 
            MySuggestionList,
            SuggestionFilter,
            Loading
         },
        data(){
            return{
                // fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isLoading: false,
                isAcknowledge: false,
                suggestionSelected: 0,

                suggestionLoaded: false,
                agentComment: '',
                commentsLoaded: '',
                audit_id: '',
                
                listKey: 0,
                filter_search: {
                    site: ''
                }
            }
        },
        validations: {
            agentComment: {required},
        }, 
        computed:{
            ...mapState(['feedbacks', 'employees']),
            ...mapGetters(['getMySuggestions','getSuggestion','getFlagLoadingSuggestion', 'infoEmployee']) 
        },
        created() {
            this.loadInfoEmployee();
        },
        methods:{
            ...mapActions(['fetchSuggestionByAuditIDforReview','addComments', 'loadMyProfileByEmpId']),

            loadSuggestion: function(data){
                this.suggestionLoaded = false;
                this.agentComment = '';
                this.audit_id = ''
                this.fetchSuggestionByAuditIDforReview(data).then(() => {
                    this.suggestionSelected = data;
                    this.suggestionLoaded = true;
                    this.audit_id = data;
                    this.commentsLoaded = this.getSuggestion.agent_comment;
                }, error => {
                    console.error("Error fetching the suggestion: "+ error)
                });
                
            },

            sendInfo: function(){
                this.isAcknowledge = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$fire({
                        type: 'error',
                        title: 'Oops! there are some errors',
                        showConfirmButton: true
                    })
                    return;
                }
                this.isLoading = true;
                this.$store.commit('setLoadingFlag',true);
                let data = {
                    'audit_id': this.audit_id,
                    'agent_comment': this.agentComment,
                }
                this.addComments(data).then(() => {
                    this.$fire({
                        type: 'success',
                        title: 'Comentario enviado exitosamente',
                        showConfirmButton: false,
                        timer: 1300
                    }).then(()=>{
                        this.suggestionLoaded = false;
                        this.listKey++;
                        this.isLoading = false;         
                    });
                }, error => {
                    console.error("Error during store the comment: "+ error)
                });

            },

            loadInfoEmployee(){
                let idEmployee = auth.currentUser.EmpID;
                this.loadMyProfileByEmpId(idEmployee).then(() => {
                    this.hideProgress = true;
                }, error => {
                    console.error("Error fetching the projects: "+ error)
                });
            },

            updateList(data){
                this.filter_search = data;
                this.listKey++;
            }

        },
        mounted(){
            let title = 'Buzón de Sugerencias y Quejas';
            this.$emit('set-title',title);
        }
    };
</script>