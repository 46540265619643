<template>
    <div class="promotionModalContainer">
        <!-- PROFILEPRJCT -->
        <div class="d-block text-center mb-3">
            <h3>Demote an Employee</h3>
        </div>
        <div v-if="isLoading" class="row">
            <div class="col text-center">
                <b-spinner
                    variant="primary"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
                <b-spinner
                    variant="secondary"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
                <b-spinner
                    variant="success"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
            </div>
        </div>
        <div v-else>
            <form @submit="submitDemotion" class="">
                <div
                    id="current_information"
                    class="flex flex-row space-x-2 w-full"
                >
                    <div class="pt-3 relative w-1/4">
                        <input
                            disabled
                            class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full"
                            placeholder="not filled"
                            readonly
                            :value="profileInfo.Department"
                        />
                        <span
                            class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap"
                            >Department</span
                        >
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input
                            disabled
                            class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full"
                            placeholder="not filled"
                            readonly
                            :value="profileInfo.Campaign"
                        />
                        <span
                            class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap"
                            >Campaign</span
                        >
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input
                            disabled
                            class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full"
                            placeholder="not filled"
                            readonly
                            :value="profileInfo.Title"
                        />
                        <span
                            class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap"
                            >Title</span
                        >
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input
                            disabled
                            class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full"
                            placeholder="not filled"
                            readonly
                            :value="profileInfo.LOB"
                        />
                        <span
                            class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap"
                            >LOB</span
                        >
                    </div>
                </div>

                <div>
                    <div
                        class="relative mb-2 sm:w-full px-2 flex flex-col group"
                    >
                        <label
                            class="text-gray-700 font-semibold text-left text-md ml-2 block group-focus:text-gray-600"
                        >
                            Effective date
                        </label>
                        <input
                            type="date"
                            :min="minDate"
                            class="mx-2 border rounded-md border-gray-200 px-3 py-1 focus:border-blue-400"
                            required
                            v-model="effective_date"
                        />
                    </div>
                </div>

                <div id="ticket_id">
                    <div class="row">
                        <label
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Select the Ticket ID
                            <label class="text-red-500">*</label>
                        </label>
                    </div>
                    <div class="row mb-3">
                        <div
                            class="col flex text-center justify-content-center align-middle p-1"
                        >
                            <multiselect
                                :disabled="!showTicketSearch"
                                class="flex justify-content-center text-left mx-4"
                                v-model="ticket_id"
                                :custom-label="customFormat"
                                :options="filteredTickets"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="false"
                                placeholder="Select a Ticket"
                                label="ID"
                                track-by="ID"
                            ></multiselect>
                        </div>
                    </div>
                </div>

                <div class="space-y-2">
                    <div name="department">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Department
                        </span>
                        <div name="department">
                            <select
                                v-model="newDepartment"
                                @change="loadCampaigns"
                                class="border w-full rounded-full py-2 px-3"
                                required
                            >
                                <option value="" hidden>
                                    Select the new department...
                                </option>
                                <option
                                    v-for="department in getDepartments"
                                    :key="department.id"
                                    :value="department"
                                >
                                    {{ department.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div name="campaign">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Campaign
                        </span>
                        <div v-if="!isLoadingCampaign">
                            <select
                                :disabled="!displayCampaign"
                                v-model="newCampaign"
                                @change="loadLobs"
                                :class="{ 'bg-gray-100': !displayCampaign }"
                                class="border w-full rounded-full py-2 px-3"
                                required
                            >
                                <option value="" hidden>
                                    Select the new campaign...
                                </option>
                                <option
                                    v-for="campaign in getCampaigns"
                                    :key="campaign.id"
                                    :value="campaign"
                                >
                                    {{ campaign.label }}
                                </option>
                            </select>
                        </div>
                        <div v-else>
                            <b-spinner
                                type="grow"
                                class="ml-4 small_spinner"
                            ></b-spinner
                            ><strong> Loading campaigns...</strong>
                        </div>
                    </div>

                    <div name="title">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Title
                        </span>
                        <div v-if="!isLoadingTitle">
                            <select
                                :disabled="!displayTitle"
                                v-model="newTitle"
                                :class="{ 'bg-gray-100': !displayTitle }"
                                class="border w-full rounded-full py-2 px-3"
                                required
                            >
                                <option value="" hidden>
                                    Select the new title...
                                </option>
                                <option
                                    v-for="title in getTitles"
                                    :key="title.id"
                                    :value="title"
                                >
                                    {{ title.label }}
                                </option>
                            </select>
                        </div>
                        <div v-else>
                            <b-spinner
                                type="grow"
                                class="ml-4 small_spinner"
                            ></b-spinner
                            ><strong> Loading Titles...</strong>
                        </div>
                    </div>

                    <div name="lob">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            LOB
                        </span>
                        <div v-if="!isLoadingLOB">
                            <select
                                :disabled="!displayLob"
                                v-model="newLOB"
                                :class="{ 'bg-gray-100': !displayLob }"
                                class="border w-full rounded-full py-2 px-3"
                                required
                            >
                                <option value="" hidden>
                                    Select the new LOB...
                                </option>
                                <option
                                    v-for="lob in getLob"
                                    :key="lob.id"
                                    :value="lob"
                                >
                                    {{ lob.label }}
                                </option>
                            </select>
                        </div>
                        <div v-else>
                            <b-spinner
                                type="grow"
                                class="ml-4 small_spinner"
                            ></b-spinner
                            ><strong> Loading campaigns...</strong>
                        </div>
                    </div>

                    <div name="report_to">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Report to
                        </span>
                        <div v-if="!isLoadingReportTo">
                            <select
                                :disabled="!displayReportTo"
                                v-model="newReportTo"
                                :class="{ 'bg-gray-100': !displayReportTo }"
                                class="border w-full rounded-full py-2 px-3"
                                required
                            >
                                <option value="" hidden>
                                    Select the new report to...
                                </option>
                                <option
                                    v-for="reportTo in getReportTo"
                                    :key="reportTo.Team_id"
                                    :value="reportTo.Emp_id"
                                >
                                    {{ reportTo.Emp_id }}
                                </option>
                            </select>
                        </div>
                        <div v-else>
                            <b-spinner
                                type="grow"
                                class="ml-4 small_spinner"
                            ></b-spinner
                            ><strong> Loading Report To...</strong>
                        </div>
                    </div>

                    <div name="comment">
                        <span
                            class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600"
                        >
                            Comment
                        </span>
                        <textarea
                            v-model="newComment"
                            placeholder="Please add a comment..."
                            rows="3"
                            max-rows="3"
                            class="w-full border rounded-md py-2 px-3 resize-none"
                            required
                        ></textarea>
                    </div>
                </div>

                <button
                    type="submit"
                    class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md w-full"
                >
                    Submit
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            newDepartment: "",
            newCampaign: "",
            newLOB: "",
            newTitle: "",
            newReportTo: "",
            newComment: "",
            ticket_id: "",

            displayCampaign: false,
            displayTitle: false,
            displayLob: false,
            displayReportTo: false,

            filteredTickets: [],
            showTicketSearch: false,

            isLoading: true,
            isLoadingCampaign: false,
            isLoadingTitle: false,
            isLoadingReportTo: false,
            isLoadingLOB: false,
            effective_date: this.$moment().add("days", 1).format("YYYY-MM-DD"),
            minDate: this.$moment().add("days", 1).format("YYYY-MM-DD"),
        };
    },
    computed: {
        ...mapState(["definitions", "employees"]),
        ...mapGetters([
            "getDepartments",
            "getTitles",
            "getCampaigns",
            "getReportTo",
            "getLob",
            "getPromoteStatus",
            "getTickets",
        ]),
    },
    props: {
        profileInfo: Object,
    },
    methods: {
        ...mapActions([
            "fetchDepartments",
            "fetchCampaignsByDepartment",
            "fetchTitlesByDepartment",
            "fetchLobByCampaign",
            "fetchReportToByCampaign",
            'setDemote',
            'fetchEmployeeTickets'
        ]),

        customFormat({ ID, subCategoryName, ReportedBy }) {
            return `ID: ${ID} , ${subCategoryName} reported by ${ReportedBy}
            `;
        },

        submitDemotion() {
            event.preventDefault();
            console.log({
                RecID: this.profileInfo.RecID,
                employee_id: this.profileInfo.EmpID,
                department: this.newDepartment.label,
                campaign: this.newCampaign.label,
                title: this.newTitle.label,
                lob: this.newLOB.label,
                report_to: this.newReportTo,
                ticket_id: this.ticket_id.ID,
                comment: this.newComment,
                effective_date: this.effective_date,
                rehirable: "Yes", //attrition helper
                voluntary: "Voluntary",
                type: "Demotion",
                reason: "Demotion",
                sub_reason: "Demotion",
            })

            if (!this.ticket_id) {
                this.$fire({
                    type: "warning",
                    title: "Missing information",
                    text: "Select the ticket related to this demotion, if does not exist, please submit one",
                    timer: 1500,
                });
                return;
            }

            this.isLoading = true;
            let ObjectData = {
                RecID: this.profileInfo.RecID,
                employee_id: this.profileInfo.EmpID,
                department: this.newDepartment.label,
                campaign: this.newCampaign.label,
                title: this.newTitle.label,
                lob: this.newLOB.label,
                report_to: this.newReportTo,
                ticket_id: this.ticket_id.ID,
                comment: this.newComment,
                effective_date: this.effective_date,
                rehirable: "Yes", //attrition helper
                voluntary: "Voluntary",
                type: "Demotion",
                reason: "Demotion",
                sub_reason: "Demotion",
            };

            this.setDemote(ObjectData)
                .then((response) => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$fire({
                            type: "success",
                            title: "The employee has been updated successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(() => {
                            this.$emit("closeDemotion", true);
                        });
                    }
                })
                .catch(() => {
                    this.$fire({
                        type: "error",
                        title: "Something went wrong",
                        text: "Please report this issue to a system administrator",
                        showConfirmButton: true,
                    });
                });
        },

        loadCampaigns: function () {
            // Clean variables
            this.newCampaign = "";
            this.newTitle = "";
            this.newReportTo = "";
            this.newLOB = "";
            this.displayReportTo = false;
            this.displayLob = false;
            // End Clean variables
            this.loadTitle();
            this.isLoadingCampaign = true;
            this.displayCampaign = false;
            this.fetchCampaignsByDepartment(this.newDepartment?.id).then(() => {
                this.isLoadingCampaign = false;
                this.displayCampaign = true;
            });
        },

        loadTitle: function () {
            this.isLoadingTitle = true;
            this.displayTitle = false;
            this.fetchTitlesByDepartment(this.newDepartment?.id).then(() => {
                this.isLoadingTitle = false;
                this.displayTitle = true;
            });
        },
        loadLobs: function () {
            // Clean variables
            this.newReportTo = "";
            this.newLOB = "";
            // End Clean variables
            this.loadReportTo();
            this.isLoadingLOB = true;
            this.displayLob = false;
            this.fetchLobByCampaign(this.newCampaign?.id).then(() => {
                this.isLoadingLOB = false;
                this.displayLob = true;
            });
        },
        loadReportTo: function () {
            this.isLoadingReportTo = true;
            this.displayReportTo = false;
            this.fetchReportToByCampaign(this.newCampaign?.id).then(() => {
                this.isLoadingReportTo = false;
                this.displayReportTo = true;
            });
        },

        filterItems: function (items) {
            const result = items.filter(
                (item) => item?.TicketSubCategoryId == "67"
            );
            this.filteredTickets = result;
            this.showTicketSearch = true;
        },
    },
    created() {
        this.fetchDepartments().then(() => {
            this.fetchEmployeeTickets(this.profileInfo.EmpID).then(() => {
                this.isLoading = false;
                this.filterItems(this.getTickets);
            });
        });
    },
};
</script>
<style lang="scss" scoped>
.formPromote {
    display: block !important;
}
.hide {
    display: none;
}
.text-xxs {
    font-size: 0.6rem;
}
.text-sm {
    font-size: 0.9rem;
}
.small_spinner {
    width: 1rem;
    height: 1rem;
}
</style>