import axios from '@/config/axios';

const state = {
    loading: false,
    deviceInfo: []
};

const getters = {
    getDeviceInfo: (state) => state.deviceInfo,
    getRSALoading: (state) => state.loading
};

const actions = {
    async searchDevice({commit}, device_id){
        commit('setRSALoading', true)
        const response = await axios.get(`api/v2/campaigns/rsa/getDevice/${device_id}`).then((res)=>{
            commit('setDeviceInfo', res.data);
            commit('setRSALoading',false)
            return res;
        });
        return response;
    },

    async checkinDevice({commit}, data){
        commit('setRSALoading', true)
        const response = await axios.post('api/v2/campaigns/rsa/assignDevice',data).then((res)=>{
            commit('setDeviceInfo', res.data.data);
            commit('setRSALoading', false)
            return res.data;
        });
        return response;
    },

    async checkoutDevice({commit}, data){
        commit('setRSALoading', true)
        const response = await axios.post('api/v2/campaigns/rsa/disposeDevice',data).then((res)=>{
            commit('setDeviceInfo', res.data.data);
            commit('setRSALoading', false)
            return res.data;
        });
        return response;
    },
};

const mutations = {
    setRSALoading: (state, loading)     => (state.loading = loading),
    setDeviceInfo: (state, deviceInfo)  => (state.deviceInfo = deviceInfo)
};

export default {
    state,
    getters,
    actions,
    mutations
};