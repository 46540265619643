<template>
    <div class="absolute top-0 left-0 w-full h-full bg-transparent flex items-center justify-center">
        <div class="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-50 z-50" @click="closeModal"></div>
        <div class="relative bg-white rounded-sm p-3 w-3/12 z-50">
            <h4 class="text-left">New Category</h4>
            <div class="mt-3 text-left">
                <label class="text-left pl-2">Display name:</label>
                <input class="w-full border-b-2 border-gray-400 px-3" v-model="form.name" />
            </div>
            <h5 class="text-left text-sm mt-4 mb-2">Select the available locations:</h5>
            <div class="flex items-center mt-2" v-for="x,y in getLocations" :key="y">
                <input v-model="form.locations[x.toLowerCase()]" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label class="ml-2 pt-2 text-sm font-medium text-gray-900"> {{x.toUpperCase()}}</label>
            </div>
            <div class="flex items-center justify-end">
                <button @click="createCategory" class="ml-auto px-3 py-2 rounded-sm bg-gray-700 text-white shadow-md">Save Category</button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
export default {
    props:{
        item:{
            type:Object,
            default:  ()=> {}
        }
    },
    data(){ 
        return{
            form:{
                name:'',
                locations:[]
            }
        }
    },
    methods:{
        ...mapActions(['removeFolder','fetchSites','createFolder']),
        async createCategory(){
            const self = this;
            if(!self.form.name) return this.$fire({text:'Please write a valid display name.',type:"warning"})

            const locations = Object.keys(self.form.locations).filter(x =>{
                if(self.form.locations[x]){
                    return x;
                }
            });
            
            await this.createFolder({name:this.form.name,sites:locations});
            this.$fire({title:"Category created successfully", type:"success"})
            .then(()=>{
                this.$router.go(0);
            })
        },
        closeModal:function(){
            this.$emit('reset');
        }
       
    },
    computed:{
        ...mapGetters(['getLocations'])
    },
    mounted(){
        this.fetchSites().then(()=>{
            const sites = {};
            Object.values(this.getLocations).map(x =>{
                sites[x.toLowerCase()] = false;
            });
            this.form.locations = sites;
        })
    }
}
</script>