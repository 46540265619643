<template>
    <div>
        <p class="text-center text-gray-500">Activate the surveys using the toggle button</p>
        <div class="bg-white rounded shadow-sm overflow-hidden">
            <table class="divide-y-2 divide-gray-50 w-full">
                <thead class="bg-gray-50">
                    <tr>
                        <th class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">#</th>
                        <th class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">Survey Name</th>
                        <th class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">Description</th>
                        <th class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y-2 divide-gray-100">
                    <tr class="text-left" v-for="item,index in 5" :key="'row'+index">
                        <td class="px-6 py-3 whitespace-nowrap">001</td>
                        <td class="px-6 py-3 whitespace-nowrap">Survey 1: The  First Survey</td>
                        <td class="px-6 py-3 leading-none">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque suscipit, sem nec lobortis consectetur, arcu metus vulputate ex, vel varius velit tortor ut lectus. Proin dapibus finibus libero
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap">
                            <label class="switch align-middle">
                                <input type="checkbox">
                                <span class="slider status round"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    computed: {
    },
    methods: {
    },
    beforeMount(){
        this.$emit('set-title','Surveys List');
    }
}
</script>