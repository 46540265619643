<template>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">
      <a class="text-danger">*</a>Team:
      </label>
      <div class="col-sm-10">
        <b-spinner v-if="loaderTeams"></b-spinner>
        <select v-else v-model="teamSelected" class="custom-select d-block w-100" id="projectUser" @change='selectTeam' required>
          <option :value="null" hidden>Choose a Team...</option>
          <option v-for="(team, idx) in getTeams" :value="team" :key="idx">{{team}}</option>
        </select>
      </div>
  </div>
</template>

<script>
import { mapGetters,mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            loaderTeams: true,
            teamSelected: null,
        }
    },
    props:{
    },
    computed:{
        ...mapState(['states']),  
        ...mapGetters(['getTeams']),
    },
    methods:{
        ...mapActions(['fetchTeams']),
        selectTeam: function(){
            this.$emit('loadTableByTeam', this.teamSelected);          
        },
    },
    created(){
        this.fetchTeams().then(()=>{
            this.loaderTeams = false;
        });
    },
}
</script>

<style scoped>
</style>