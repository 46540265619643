import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    messages: [],
    message: null,
    componentMessages: [],
    isDeleted: false,
};
const getters = {
    getMessages: (state) => state.messages,
    getMessage: (state) => state.message,
    getDeletedStatus: (state) => state.isDeleted,
    getComponentMessages: (state) => state.componentMessages,
};
const actions = {

    async newMessage ({commit}, data ){        
        const endpoint = API_URL + 'api/v4/messages/newMessage'
        const response = await axios.post(endpoint, data);
        commit('setMessages', null);
        return response
    },
    async fetchAllMessages ({commit},all){   
        const endpoint = API_URL + 'api/v4/messages/manager/' + all;
        await axios.get( endpoint).then((res) =>{
            commit('setMessages', res.data.msg);
        }); 
    },
    async fetchMessages ({commit},ack){
        const endpoint = API_URL + 'api/v4/messages/center/'+ack;
        await axios.get( endpoint).then((res) =>{
            commit('setMessages', res.data.msg);
        }); 
    },
    async viewMessage ({commit}, id){   
        const endpoint = API_URL + 'api/v4/messages/viewMessage/'+id;
        await axios.get( endpoint).then((res) =>{
            commit('setMessage', res.data.msg);
        }); 
    },
    async setAcknowledge ({commit}, messageid ){        
        const endpoint = API_URL + 'api/v4/messages/'+ messageid +'/acknowledge'
        const response = await axios.post(endpoint);
        commit('setMessage', null);
        return response
    },

    async setAsViewed ({commit}, messageid ){        
        const endpoint = API_URL + 'api/v4/messages/'+ messageid +'/viewed'
        const response = await axios.post(endpoint);
        commit('setMessages', null);
        return response
    },

    async deleteMessage ({commit}, MessageID ){        
        const endpoint = API_URL + 'api/v4/messages/'+ MessageID + '/delete'
        const response = await axios.post(endpoint, MessageID);
        commit('setDeletedStatus', true);
        return response
    },

    async newMessageBySite ({commit}, data ){        
        const endpoint = API_URL + 'api/v4/messages/newMessageBySite'
        const response = await axios.post(endpoint, data);
        commit('setMessages', null);
        return response
    },
    async newMessageByDepartment ({commit}, data ){        
        const endpoint = API_URL + 'api/v4/messages/newMessageByDepartment'
        const response = await axios.post(endpoint, data);
        commit('setMessages', null);
        return response
    },
    async newMessageByCampaign ({commit}, data ){        
        const endpoint = API_URL + 'api/v4/messages/newMessageByCampaign'
        const response = await axios.post(endpoint, data);
        commit('setMessages', null);
        return response
    },
    async newMessageByJobTitle ({commit}, data ){        
        const endpoint = API_URL + 'api/v4/messages/newMessageByJobTitle'
        const response = await axios.post(endpoint, data);
        commit('setMessages', null);
        return response
    },

    async fetchMessagesOnComponent ({commit}){   
        const endpoint = API_URL + 'api/v4/messages/menu/messages';
        await axios.get( endpoint).then((res) =>{
            commit('setComponentMessages', res.data.msg);
        }); 
    },

};
const mutations = {
    setMessages: (state, messages) => (state.messages = messages), 
    setMessage: (state, message) => (state.message = message),
    setDeletedStatus: (state, status) => (state.isDeleted = status),
    setComponentMessages: (state, status) => (state.componentMessages = status),
};
export default {
    state,
    getters,
    actions,
    mutations
};