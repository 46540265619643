<template>
<!-- DISCONTINUED -->
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Report Missing Event</h3>
        </div>
      </div>
    </div>
    <div :class="{ hide: !isLoading }" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div :class="{ hide: isLoading}" >
      <div class="row">
        <div class="col">
          <label> Current User: <b>{{ this.getInfoByApprovalID.Emp_id }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label> Date: <b>{{ this.getInfoByApprovalID.Date | moment("YYYY-MM-DD") }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col mb-1">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Event to Report:</label>
            <select  class="custom-select d-block w-100" v-model="eventSelected">
                <option :value="null">Choose an Event...</option>
                <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index" :label="key" >
                    <option v-for="(event,index) in elements" :value="event.break_name" :key="index">{{event.break_name}}</option>
                </optgroup>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mb-1">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Duration:</label>
            <vue-timepicker v-model="duration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="button" @click="registerEvent" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          <!-- <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
export default {
  components: { VueTimepicker },
  data() {
    return {
      time: null,
      isLoading: true,
      adjustment: {
        reason: null,
      },
      eventSelected: null,
      duration: null,
      allEvents: [],
    };
  },
  props: {
    approvalID: Number,
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters([
        "getInfoByApprovalID",
        "getAllEventsPerCampaign",
    ]),
  },
  created(){
    this.loadRequestInfo();
  },
  methods: {
    ...mapActions([ 
      'fetchInfoByApprovalID',
      'fetchAllEvents',
      'addMissingEvent',
      ]),
    registerEvent: function(){
      this.isLoading = true;
      let infoObj = {
        event_type :  this.eventSelected,
        event_date:   this.getInfoByApprovalID.Date,
        duration:     this.duration,
        Emp_id:       this.getInfoByApprovalID.Emp_id,
        Approval_id:  this.approvalID
      }
      this.addMissingEvent(infoObj).then(() =>{
        this.isLoading = false;
        this.$fire({
          type: 'success',
          title: 'The request has been created sucessfully',
          showConfirmButton: false,
          timer: 1500
        }).then(()=>{
            this.$router.go(0);                
        }); 
      });
    },
    loadRequestInfo: function(){
      this.fetchInfoByApprovalID(this.approvalID).then(() =>{
        this.EmpID = this.getInfoByApprovalID.Emp_id
        this.fetchAllEvents(this.EmpID).then(() =>{
        this.isLoading = false;
      });
      });

    }
  },
};
</script>
<style lang="scss">

</style>