<template>
    <div class="bg-white rounded-[10px] text-left shadow-sm overflow-hidden p-8">
        <h6 class="text-c-primary"><i class="bi bi-envelope"></i> Email</h6>
        <div v-for="(item, index) in getEmails " :key="index" class="leading-none pl-6">
            <b>Email {{index + 1}}:</b> {{item.PlatformID}}
        </div>
        <hr>
        <h6 class="text-c-primary"><i class="bi bi-person-badge"></i> PlatformID</h6>
        <div class="leading-none pl-6">
            <b>Primary:</b> Zulu
            <br/><b>Secondary:</b> Qmetrix
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import auth                                 from "@/config/user"
    export default {
        components: {
        },
        data() {
            return {
            };
        },
        computed:{
            ...mapState(['employees', 'platform']),
            ...mapGetters(['getEmails', 'getPlatformIds'])
        },
        methods:{
            ...mapActions(['fetchEmails',  'loadMyProfileByEmpId',]),
        },
        created(){
            console.log(auth.currentUser.EmpID)
            this.fetchEmails(auth.currentUser.EmpID);
        },
    }
</script>
<style scoped> 
</style>