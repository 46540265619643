<template>
    <div class="bg-white rounded-[10px] shadow-sm text-left p-10">
        <div class="border rounded">
            <div class="flex flex-col md:flex-row border-b">
                <div class="flex-1 p-3 border-r">
                    <label class="text-xs mb-0 uppercase">Campaign</label>
                    <multiselect v-model="campaign" :options="getAllCampaigns"></multiselect>
                </div>
                <div class="flex-1 p-3">
                    <label class="text-xs mb-0 uppercase">Team Leader</label>
                    <multiselect class="w-full" v-model="empid" :options="employees" @input="employeeDetails()"></multiselect>
                    <!-- <input required type="text" class="w-full" v-model="empid" placeholder="..." /> -->
                </div>
            </div>
            <div class="bg-gray-50 p-3" v-if="this.infoEmployee.Campaign">
                <h4>Employee Details</h4>
                <div class="flex">
                    <div class="pr-2 font-semibold">Name:</div>
                    <div class="flex-1">{{ this.infoEmployee.FirstName + ' ' + this.infoEmployee.LastName1 + ' ' + this.infoEmployee.LastName2 }}</div>
                </div>
                <div class="flex">
                    <div class="pr-2 font-semibold">Campaign:</div>
                    <div class="flex-1">{{ this.infoEmployee.Campaign }}</div>
                </div>
                <div class="flex">
                    <div class="pr-2 font-semibold">Title:</div>
                    <div class="flex-1">{{ this.infoEmployee.Title }}</div>
                </div>
                <div class="flex">
                    <div class="pr-2 font-semibold">Role:</div>
                    <div class="flex-1">{{ this.infoEmployee.Role }}</div>
                </div>
            </div>
        </div>
        <div class="text-center mt-4">
            <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" @click.prevent="saveTeam()"><i class="bi bi-plus-lg mr-2"></i>Create Team</button>
        </div>
    </div>
</template>
<script>
    //import auth from "@/config/user"
    import Multiselect from 'vue-multiselect'
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: {
            Multiselect
        },
        data(){
            return {
                empid: '',
                campaign: '',
            }
        },
        validations: {
        }, 
        computed: {
            ...mapState([
            ]),
            ...mapGetters([
                'getAllCampaigns',
                'employeeList',
                'infoEmployee'
            ]),
            employees: function () {
                return this.employeeList.map((e) => e.EmpID?.toUpperCase() );
            }
        },
        created() {
            this.fetchCampaigns();
            this.fetchAllEmployees();
        },
        methods: {
            ...mapActions([
                'fetchCampaigns',
                'fetchAllEmployees',
                'newTeam',
                'loadMyProfileByEmpId'
            ]),
            saveTeam:function() {
                if(!this.empid){
                    this.$fire({
                        type: 'error',
                        title: 'Missing fields',
                        text: 'Please set a Employee ID for the message',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return false;
                }

                if(!this.campaign){
                    this.$fire({
                        type: 'error',
                        title: 'Missing fields',
                        text: 'Please set a Campaign for the message',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return false;
                }

                let loader = document.getElementById("pro-loading");
                if (loader){
                    loader.style.display = "flex";
                }

                let dataObject = new FormData();
                dataObject.append('emp_id', this.empid);
                dataObject.append('campaign', this.campaign);

                this.newTeam(dataObject).then((res) =>{
                    this.$fire({
                        type: 'success',
                        title: res?.msg,
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(()=>{
                        let loader = document.getElementById("pro-loading");
                        if (loader){
                            loader.style.display = "none";
                        }
                        this.$router.push({ name: 'teams.index' });
                    })
                }).catch(()=>{
                    this.$fire({
                        type: 'warning',
                        title: 'Something wrong',
                        text: 'Please report to a system administrator',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                })
            },
            employeeDetails:function() {
                console.log(this.empid);
                this.loadMyProfileByEmpId(this.empid);
            }
        },
        mounted() {
            let title = 'New Team';
            this.$emit('set-title',title);
        }
    };
</script>