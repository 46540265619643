<template>
<div class="w-full">  
    <select :class="error ? 'bg-red-50 border-red-200' :''" class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 " v-model="sourceId">
        <option value="" hidden>
            Select a source...
        </option>
        <option class="capitalize" v-for="(source,index) in getSources" :key="index" :value="source.id">
            {{source.label}}
        </option>
        <option value="0" v-if="!strictMode">
            All sources
        </option>
    </select>
</div>
    
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    props:{
        source:[String,Number],
        error:Boolean,
        strict:{
            default:true,
            type:Boolean
        }
    },
    computed:{       
        ...mapGetters(['getSources','']),
        sourceId:{
            get(){
                return this.source;
            },
            set(value){
                this.$emit('change',value);
            }
        },
        strictMode:function(){ return  this.strict},        
        
    },
    methods:{
        ...mapActions(['fetchSources']),        
    },
    beforeMount(){
        this.fetchSources();
    }
}
</script>