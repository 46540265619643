<template>
    <div class="main space-y-4">
        <div class="flex space-x-2 rounded-xl py-3 px-6 bg-white">
            <div class="flex items-center text-nowrap gap-2">
                <i class="bi bi-person-fill"></i>
                Search Employee
            </div>
            <multiselect
                class="flex justify-content-center text-left"
                v-model="employee_selected"
                @input="updateUrl"
                placeholder="Select an Lob"
                label="EmpID"
                track-by="EmpID"
                :options="employee_list"
                :multiple="false"
                :allow-empty="false"
                :clear-on-select="false"
                :preserve-search="false"
                deselect-label=""
            ></multiselect>
        </div>
        <div class="flex space-x-4" v-if="loaded">
            <div class="w-1/3 h-[600px] mx-auto bg-gray-50 rounded-xl p-2">
                <time-line :shifts="getClockerHours" />
            </div>
            <shifts-list
                @refresh="keepAlive"
                :events="getClockerHours"
                class="w-2/3"
            />
        </div>
        <div v-else class="opacity-70">
            <illustration media="search_engines">
                <template slot="headline">Select an employee first</template>
                <template slot="subline">
                    Search for an employee to view their shift history for
                    today.
                </template>
            </illustration>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TimeLine from "@/views/clocker/adjustments/components/TimeLine";
import ShiftsList from "@/views/clocker/adjustments/components/ShiftsList";
import Illustration from "@/views/mockups/IllustrationTemplate";
export default {
    components: { TimeLine, ShiftsList, Illustration },
    computed: {
        ...mapGetters(["getClockerHours", "employeeList"]),
        loaded() {
            return (
                (this.$route.params.emp_id || this.employee_selected != null) &&
                this.getClockerHours
            );
        },
    },
    data() {
        return {
            employee_selected: null,
            employee_list: [],
        };
    },
    watch: {
        "$route.params.emp_id": {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.keepAlive();
                }
            },
        },
    },
    methods: {
        ...mapActions(["fetchClockerHours", "fetchEmployeesUsingRole"]),
        updateUrl() {
            if (this.employee_selected) {
                this.$router.push({
                    name: "clocker.adjustments",
                    params: { emp_id: this.employee_selected.EmpID },
                });
            }
        },
        keepAlive() {
            if (this.$route.params.emp_id) {
                this.fetchClockerHours(this.$route.params.emp_id);
            }
        },
    },
    created() {
        this.fetchEmployeesUsingRole().then(() => {
            this.employee_list = this.employeeList.sort((a, b) =>
                a.EmpID.localeCompare(b.EmpID)
            );
        });
    },
    mounted() {
        let title = "Clocker Adjustments & Corrections";
        this.$emit("set-title", title);
    },
};
</script>