<template>
    <div class="max-w-7xl mx-auto flex flex-row  align-items-center justify-content-center text-gray-200 focus-within:text-gray-600 mb-4 mt-0">
      <div class="bg-white  flex-1 p-2  px-2 flex flex-row align-items-center justify-content-center">
            <search class="flex-1" :val="formData.curp" v-on:searching="setQuery"/>
        <div class="w-1/4 text-left pr-2 ml-2">
            <select class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 "   v-model="formData.site">
                <option hidden value="">Select a location...</option>
                <option value="MTY">Monterrey</option>}
                <option value="QRO">Queretaro</option>
                <option value="AGS">Aguascalientes</option>
                <option value="LB">Longville</option>
            </select>
           
        </div>
        <div class="w-1/4 text-left pr-2 ml-2">
            <source-component @change="setSourceId" :source="formData.sourceId" :strict="false"></source-component>
        </div>      
         <a class="ml-4 bg-gray-700 shadow-md text-white rounded-sm hover:bg-gray-900 p-2 px-4" href="#" @click.prevent="$router.push({name:'candidates.create'})">
        New
      </a>
      </div>
     
  </div>
</template>
<script>
import SourceComponent from '../dropdowns/CandidateSource';
import debounce from 'debounce';
import Search from '@/views/mockups/SearchableInput'
export default {
    components:{
        SourceComponent,    
        Search
    },
    data(){
        return{
            formData:{
                sourceId:"",
                searchString:null,
                site:""
            }
        }
    },
    methods:{
        setSourceId:function(source){
            this.formData.sourceId = source;
        },         
        setQuery:function(searchString){
            this.formData.searchString = searchString;            
        },
    },
    watch:{
        formData:{
            handler:debounce(function(){               
               this.$emit('toggle',this.formData);
            },500),
            deep:true
        }
    }
}
</script>