<template>
  <div class="w-full py-2">
       <button @click="$router.push({name:'forms.create'})" class="bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">New Form</button>
       <div class="px-5 py-2">
           <table class="divide-y divide-gray-200 border-b border-gray-200 w-full shadow-md">
                <thead class="bg-gray-50">
                <tr>
                    <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Name</th>
                    <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">isActive</th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                    <td class="p-2">1</td>
                    <td class="p-2">1</td>
                </tr>
                </tbody>
            </table>      
       </div>
  </div>
</template>
<script>
export default {
    data(){
        return {
            title : "Forms Dashboard"
        }
    },
    components: {},
    mounted(){
        this.$emit('set-title',this.title); 
    }
}
</script>
<style scoped>
</style>