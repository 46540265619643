<template>
    <div class="container">
        <div class="rounded-full bg-gray-100 h-2 mb-10 overflow-hidden"><span class="block bg-c-primary h-full"
                :class="'w-[' + ((100 / questions.length) * (formPosition + 1)) + '%]'"></span></div>
        <ol class="list-decimal-custom text-left">
            <li :class="animation + ' before:content-[\'' + (formPosition + 1) + '\']'" v-if="conditionQuestion()">
                <p>{{ questions[formPosition].text }}</p>
                <input type="hidden" class="questionType" :value="questions[formPosition].type">
                <!-- Dropdown -->
                <div v-if="questions[formPosition].type == 'dropdown' || questions[formPosition].type === 'multiple'"
                    class="ml-14 mt-6">
                    <label v-for="(answer, i) in questions[formPosition].answers"
                        :key="'answer-' + formPosition + '-' + i" class="flex mb-0 cursor-pointer" :class="inputError">
                        <!-- Dropdown -->
                        <input v-if="questions[formPosition].type === 'dropdown'" type="radio" class="answer scale-125"
                            :class="inputError" :value="answer.text" :name="'radio-group-' + formPosition" />
                        <!-- Multiple options -->
                        <input v-else-if="questions[formPosition].type === 'multiple'" type="checkbox"
                            class="answer scale-125" :class="inputError" :value="answer.text"
                            :name="'radio-group-' + formPosition" />
                        <p class="m-0 ml-2">{{ answer.text }}</p>
                    </label>
                </div>
                <!-- Duration -->
                <div v-else-if="questions[formPosition].type === 'duration'" class="flex ml-6 mt-6">
                    <vue-timepicker :id="'duration-' + formPosition" v-model="time_duration"
                        class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 mr-2"
                        format="HH:mm:ss" close-on-complete></vue-timepicker>
                </div>
                <!-- Any input type: text, number, date, time... -->
                <div v-else class="flex ml-6 mt-6">
                    <input :type="questions[formPosition].type" value="" :name="'input-' + formPosition"
                        :id="'input-' + formPosition"
                        :placeholder="questions[formPosition].type === 'number' ? '0' : '...'"
                        class="answer border-[1px] border-gray-200 rounded px-6 py-2 text-xl w-full"
                        :class="inputError">
                </div>
            </li>
        </ol>
        <div class="space-x-6 mt-10">
            <button class="text-xl bg-blue-600 hover:bg-blue-700 text-white pl-8 pr-6 py-2 rounded-full"
                :disabled="isSubmitting" type="button" v-if="formPosition < questions.length - 1"
                @click="nextStep(false)">Next<i class="bi bi-chevron-right ml-2"></i></button>
            <button class="text-xl bg-blue-600 hover:bg-blue-700 text-white pl-8 pr-6 py-2 rounded-full"
                :disabled="isSubmitting" type="button" v-if="formPosition === questions.length - 1"
                @click="nextStep(true)">Finish</button>
        </div>
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        VueTimepicker
    },
    props: {
        questions: []
    },
    data() {
        return {
            formPosition: 0,
            animation: 'animate-in',
            answers: [],
            inputError: '',
            isSubmitting: false,
            time_duration: '',

            show_survey: false,
            title: 'Satisfaction Survey',
            interval: 0,
            attempts: 0,
            date_from: '',
            date_to: '',
            duration: '',
            instructions: '',
            type_quiz: 'quiz',
            site: '',
            campaign: '',
            job_title: '',
            message: '',
            is_available: false,
        }
    },
    computed: {
        ...mapGetters([
            'getSurveysV2Details'
        ])
    },
    created() {
    },
    methods: {
        ...mapActions([
            'surveyAnswers',
        ]),
        startSurvey() {
            this.show_survey = true;
        },
        closeSurvey() {
            this.show_survey = false;
        },
        nextStep(final) {
            this.inputError = '';
            this.isSubmitting = true;
            let qType = document.getElementsByClassName("questionType")[0]?.value;
            let val = document.getElementsByClassName("answer")[0]?.value;
            let empty = true;

            if(qType === 'duration') {
                val = this.time_duration;
            }

            if (qType === 'dropdown') {
                let thisAnswer = document.getElementsByClassName("answer");
                for (var i = 0; i < thisAnswer.length; i++) {
                    if (thisAnswer[i].checked) {
                        val = thisAnswer[i].value;
                        empty = false;
                    }
                }
            }

            if (qType === 'multiple') {
                var values = [];
                let thisAnswer = document.getElementsByClassName("answer");
                for (var m = 0; m < thisAnswer.length; m++) {
                    if (thisAnswer[m].checked) {
                        values.push(thisAnswer[m].value);
                        empty = false;
                    }
                }
                val = values;
            }

            if (qType === 'dropdown' && empty == false || qType === 'multiple' && empty == false || qType !== 'dropdown' && qType !== 'multiple' && val) {
                this.answers.push({ id: this.questions[this.formPosition].ID, question: this.questions[this.formPosition].text, value: val });
                if (qType == 'dropdown' || qType == 'multiple') {
                    let q_answer = document.getElementsByClassName("answer");
                    for (var a = 0; a < q_answer.length; a++) {
                        q_answer[a].checked = false;
                    }
                } else {
                    if (document.getElementsByClassName("answer")[0]) document.getElementsByClassName("answer")[0].value = '';
                    this.time_duration = '';
                    val = '';
                    qType = '';
                }

                if (final) {
                    document.getElementById('pro-loading').style.display = 'flex';
                    let object = new FormData();
                    object.append('id', this.$route.params.id);
                    object.append('answers', JSON.stringify(this.answers));
                    this.surveyAnswers(object).then(() => {
                        this.$fire({
                            type: 'success',
                            title: "Survey has been stored",
                            text: 'You will be redirected',
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            document.getElementById('pro-loading').style.display = 'none';
                            this.$router.push({ name: 'base.dashboard' });
                        })
                    });
                } else {
                    this.animation = 'animate-out';
                    setTimeout(() => {
                        this.animation = 'animate-in';
                        this.formPosition++;
                        this.isSubmitting = false;
                    }, 600)
                }
            } else {
                this.inputError = 'border-red-600 text-red-600';
                this.isSubmitting = false;
            }
        },
        conditionQuestion() {
            let current = this.questions[this.formPosition];
            let answer = this.answers.find((answer) => answer.id === current.parent);
            let choices = answer?.value || [];
            let conditions = JSON.parse(current.conditions) || [];

            if (current.parent === 0 || conditions === choices || conditions.includes(choices) || conditions.some(condition => choices.includes(condition))) {
                return true;
            } else {
                this.answers.push({ id: 'skip', value: '' });
                this.formPosition += 1;
                return false;
            }
        }
    },
    mounted() {
        let title = 'Surveys';
        this.$emit('set-title', title);
    }
}
</script>
<style>
input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}
</style>