<template>
    <div class="flex h-full w-full">
        <div v-if="isLoading" class="p-3 flex h-full w-full text-center justify-content-center align-items-center">
            <span><svg class="w-8 h-8 ml-2 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
            <i>Loading Messages</i>
        </div>
        <div v-if="this.showMessages" class="py-3 flex flex-col w-full h-full space-y-1">
            <div v-for="message in getTicketMsgs" :value="message.contentMessage" :key="message.ID" >
                <div class="flex" :class="{ 'justify-end': message.createdBy == thisUser, 'justify-start': message.createdBy != thisUser }">
                    <div>
                        <div :class="{ 'bg-blue-500 rounded-tr-none': message.createdBy == thisUser, 'bg-gray-500 rounded-tl-none': message.createdBy != thisUser }" class="rounded-3xl text-white py-2 px-4">
                            {{message.contentMessage}}
                        </div>
                        <p :class="{ 'text-left': message.createdBy == thisUser, 'text-left': message.createdBy != thisUser }" class="message-content "> * {{message.createdBy}} added this comment at {{message.createdAt}}</p> 
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!this.showMessages && !isLoading" class="p-3 flex h-full w-full text-center justify-content-center align-items-center">
            <div>
                <h2>Oh, no!</h2>
                <i>There's no messages here...</i>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions}   from 'vuex';
    import auth                                 from "@/config/user"
    export default {
        data(){
            return {
                isLoading: true,
                showMessages: false,
                thisUser: null
            }
        },
        props:{
            ticketSelected: String,
        },
        computed:{
            ...mapState(['tickets']),
            ...mapGetters(['getTicketMsgs']) 
        },
        methods: {
            ...mapActions(['fetchThisTicketMessages']),
        },
        created(){
            this.isLoading = true;
            this.thisUser = auth.currentUser.EmpID;
            let ObjData = {
                ticketSelected: this.ticketSelected
            }
            this.fetchThisTicketMessages(ObjData).then(()=>{
                this.isLoading      = false;
                this.showMessages   = this.getTicketMsgs.length > 0 ? true : false;
            })
        },
    }
</script>
<style scoped>
.btn .badge {
    top: 3px !important;
}
.primary{
    border: 2px solid #007bff !important;
}
.secondary{
 border-color: #6c757d;
}
.no-chat{ 
    color: rgb(17, 17, 17);
    padding: 10px;
    align-items: center;
    text-justify: auto;
    -webkit-text-stroke-width: thin;
    text-align: -webkit-center;
    margin-bottom: 3rem;
}
.message-content{
    font-size:10px; 
    color: rgb(77, 75, 75);
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 5px;
    padding-bottom: 1px;
    padding-right: 8px;
    padding-top: 1px;
}
.speech-bubble-sender {
	position: relative;
	background: rgb(69, 128, 255);
    color: white;
	border-radius: 0.2em;
    margin: 0.5rem;
    padding-bottom: 1px;
    padding-right: 8px;
    padding-top: 1px;
    -webkit-user-modify: read-write-plaintext-only;
}
.speech-bubble-receiver {
	position: relative;
	background: #7b7b7b;
    color: white;
	border-radius: 0.2em;
    margin: 0.5rem;
    padding-bottom: 1px;
    padding-left: 8px;
    padding-top: 1px;
    -webkit-user-modify: read-write-plaintext-only;
}
/* .speech-bubble-sender:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 22px solid transparent;
	border-left-color: rgb(69, 128, 255);
	border-right: 0;
	border-top: 0;
	margin-top: -11px;
	margin-right: -22px;
    padding: 0px;
}
.speech-bubble-receiver:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 22px solid transparent;
	border-right-color: #7b7b7b;
	border-left: 0;
	border-top: 0;
	margin-top: -11px;
	margin-left: -22px;
    padding: 0px;
} */
</style>