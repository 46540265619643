<template>
  <div>
    <div class="flex flex-row size-full">
      <select @change="updateValue" v-model="selectValue"
        :class="selectClass"
      >
        <option value="" hidden selected>
          {{ placeholder }}
        </option>
        <option value="" disabled>The option you need isn't available? Add it!</option>
        <option v-for="(option, index) in dropdownOptions" :value="option" :key="index">
          {{ option }}
        </option>
      </select>
      <button :class="buttonClass" @click.prevent="toggleModal"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="size-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
        </svg>
      </button>
    </div>
    <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center overflow-auto">
      <div class="sticky inset-0 bg-gray-800 opacity-10 size-full" @click="toggleModal"/>
      <div class="absolute top-1/3 flex flex-col items-start justify-start rounded-full
      bg-white px-6 py-3 shadow-lg w-[40rem] transition-all ease-in-out duration-500 overflow-hidden"
           :class="{'h-24': !repetitionError, 'h-[7.3rem]': repetitionError}"
      >
        <div class="flex flex-row w-full relative space-x-4">
          <input id="add-dropdown-option"
                 :type="inputType" class="w-full text-xl border-b-[1px] offset"
                 :class="{'border-gray-600': !repetitionError, 'border-red-600': repetitionError}"
                 placeholder="New option"
                 v-model="optionLabel"
                 @input="checkForRepetition"
          />
          <button
            :disabled="disableAddButton" @click.prevent="save"
            class="h-8 w-36 content-center rounded-full disabled:bg-gray-200 disabled:text-gray-400
            p-1 text-center text-xl bg-slate-600 text-[#ffffff] hover:bg-slate-800 transition
            duration-500 ease-in-out"
          >
            Add
          </button>
        </div>
        <span v-if="repetitionError"
              class="text-xs text-red-600 text-left"
        >
          This option is already present in the dropdown.
        </span>
        <span class="text-left text-xs mt-1">
          Please take care of your spelling, capitalization; avoid signs and punctuation.
          Keep in mind options are only saved when a user is created with them,
          so you can only create one option at a time.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewHireDropdown",
  props: {
    placeholder: {
      required: false,
      default: 'Select...',
      type: String,
    },
    value: {
      required: true,
    },
    column: {
      required: true,
      type: String,
    },
    inputType: {
      required: false,
      type: String,
      default: 'text',
    },
    selectClass: {
      required: false,
      type: String,
      default: "rounded-l-md border-gray-300 bg-white p-2 text-left size-full border-[1px] " +
        "h-[42px]",
    },
    buttonClass: {
      required: false,
      type: String,
      default: "flex w-10 items-center justify-center rounded-r-md border-gray-300 bg-[#ffffff] " +
        "hover:bg-slate-800 hover:text-white border-y-[1px] border-r-[1px] transition " +
        "ease-in-out duration-500"
    },
  },
  data() {
    return {
      selectValue: "",
      dropdownOptions: [],
      optionLabel: "",
      isOpen: false,
      repetitionError: false,
      lastAddedOption: "",
    };
  },
  computed: {
    ...mapGetters([
      "getEmployeeFieldOptions"
    ]),
    disableAddButton() {
      return this.optionLabel === '' || this.repetitionError;
    }
  },
  methods: {
    ...mapActions([
      "fetchEmployeeFieldOptions"
    ]),
    save() {
      this.dropdownOptions = this.dropdownOptions.filter(x => x !== this.lastAddedOption)
      this.dropdownOptions.push(this.optionLabel);
      this.lastAddedOption = this.optionLabel;
      this.selectValue = this.optionLabel;
      this.updateValue();
      this.closeModal();
    },
    checkForRepetition() {
      const stringOptions = this.dropdownOptions.map(x => String(x));
      this.repetitionError = stringOptions.includes(String(this.optionLabel));
    },
    keyShortcuts(event) {
      if ( !this.isOpen ) {
        return;
      }
      switch (event.key) {
        case 'Escape':
          this.isOpen = false;
          break;
        case 'Enter':
          event.preventDefault()
          if ( !this.disableAddButton ) {
            this.save();
          }
          break;
      }
    },
    toggleModal() {
      if ( this.isOpen ) {
        this.closeModal();
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.isOpen = true;
      this.optionLabel = "";
      this.repetitionError = false;

      this.$nextTick(
        () => {
          document.getElementById('add-dropdown-option').focus();
        }
      );
    },
    closeModal() {
      this.isOpen = false;
    },
    updateValue() {
      this.$emit('input', this.selectValue);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.keyShortcuts);
    this.fetchEmployeeFieldOptions({params: {column: this.column}}).then(
      () => {this.dropdownOptions = JSON.parse(JSON.stringify(this.getEmployeeFieldOptions));}
    );
  }
}
</script>