<template>
    <div class="relative">
        <span v-if="this.getUnreadMessages.length" class="absolute block -top-1 -right-1 px-1 bg-red-600 text-white text-xs font-bold rounded-full">{{ this.getUnreadMessages.length }}</span>
        <button v-on:click="this.toggleNotifications" class="text-xl p-1 hover:text-c-primary" v-b-tooltip.hover.top="'Notifications'">
            <i class="bi bi-bell"></i>
        </button>
    </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
export default {
    data(){
        return{
            unreadNotifications: 0,
            username: '',
        }
    },
    computed:{
        ...mapState([
            "notifications"
        ]),
        ...mapGetters([
            "getUnreadMessages",
        ]),
    },
    created: function(){
        this.updateMessages();
        this.getUsername();
        window.Echo.channel("ChatNotifications").listen(".unread-message", e => {
            if(e.EmployeeReceiver === this.username){
                this.fetchUnreadNotifications().then(()=>{
                    this.$emit('updateNotifications', true);
                });
            }
        });
        window.Echo.channel("TicketsNotifications").listen(".ticket-updated", e => {
            if(e.EmployeeReceiver === this.username ){
                this.fetchUnreadNotifications().then(()=>{
                    this.$emit('updateNotifications', true);
                });
            }
        });
        window.Echo.channel("LogOutNotification").listen(".logOut-User", e => {
            if(e.EmployeeReceiver === this.username ){
                sessionStorage.clear();
                setTimeout(window.location.reload.bind(window.location), 500);
            }
        });
        window.Echo.channel("PositionsNotifications").listen(".new-position", e => {
            this.$toasted.show("New Position: " +e.position.title, {
                theme: "toasted-primary", 
                icon : 'error',
                position: "bottom-right", 
                duration : null,
                action : [
                    {
                        text : 'Open Positions',
                        onClick : (e, toastObject) => {
                            this.$router.push({name:'positions.index'});
                            toastObject.goAway(0);
                        }
                    }, {
                        text : 'Ok',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                ],
            });
        });
    },
    methods:{
         ...mapActions([
          'changeNotificationStatus',
          'fetchUnreadNotifications',
        ]),
        getUsername: function(){
            let EmpID = JSON.parse(sessionStorage.getItem("EmpID"));
            if (EmpID) { 
                this.username = EmpID; 
            }
        },
        toggleNotifications: function(){
            var notificationElement = document.getElementById("NotificationsComponent");
            if(notificationElement.style.display === "none"){
                notificationElement.style.display = 'block';
            }else{
                notificationElement.style.display = 'none';
            }
        },
      
        updateMessages: function(){
            this.fetchUnreadNotifications();
        },
    },
}
</script>