<template>
  <div class="contianer-fluid py-3">  
    <div class="flex flex-row max-w-8xl bg-white shadow-lg rounded-lg p-3 my-3 mx-4 align-items-center justify-content-start">
      <adjustments-searchbar :key="resetKey" @toggle="filterUpdate"/>

      <button class="p-2 bg-gray-500 text-white shadow-md rounded-md mx-2 hover:bg-gray-700"  @click.prevent="clearFilters">
        Clear filters
      </button>
    </div>
   
    <div class="row mt-5">
      <div class="col align-self-center">
        <component :key="this.keyPayrollRequests" :is="'payrollRequests'" @toggle="keepQueryAlive"></component>
      </div>
    </div>
  </div>
</template>
<script>
import payrollRequests  from '@/views/components/Payroll/tables/payrollRequests';
import {mapActions} from 'vuex'
import AdjustmentsSearchbar from '@/views/Utilities/Payroll/AdjustmentsSearchbar.vue'
export default {
  data() {
    return {
      resetKey:0,
      keyPayrollRequests: 0,
      form:{
        refresh :false
      }
    }
  },
  components: { 
    payrollRequests,
    AdjustmentsSearchbar
  },
  methods:{
    ...mapActions(['filterPayrollRequest']),
    clearFilters: function(){
      this.resetKey ++;
    },
    filterUpdate:function(form){
      this.form = form;
    },
    keepQueryAlive:function(){    
      this.filterPayrollRequest(this.form);
    }
    ,clearEmpId:function(){
      this.form.employeeId = null;
    }
  },
  watch:{
    form:{
      handler:function(){        
         this.filterPayrollRequest(this.form);
      },
      deep:true      
    }
  },
  beforeMount(){
    this.$emit('set-title','Watchmen Dashboard');
  }
}
</script>
<style scoped>
.card-body{
  height: 100% !important;
}
</style>
