<template>
    <div class="main overflow-auto p-4">
        <div class="grid md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
            <div v-for="(document, index) in documents" :key="index" class="">
                <div v-if="document.status === 'pending'" class="relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" 
                    class="bi bi-hourglass-split absolute -right-1 -top-1 text-white drop-shadow-md z-30 bg-blue-600 rounded-full p-1" viewBox="0 0 16 16">
                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                    </svg>
                </div>
                <div v-else-if="document.status" class="relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" 
                    class="bi bi-check-circle-fill absolute -right-1 -top-1 text-green-500 drop-shadow-md z-30 bg-white rounded-full" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                </div>
                <button :class="`rounded-[15px] p-2 w-full h-[160px] bg-[${document.color}] drop-shadow-lg overflow-hidden`"
                @click.prevent="$router.push({ 
                name: 'documents.view', 
                params: { rec_id: employee, doc_type: document.id } 
                })"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    :class="`bi bi-file-earmark-text w-[100px] h-[100px] absolute opacity-30 -top-4 -left-6 text-[${document.text}]`" viewBox="0 0 16 16">
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                    </svg>
                    <span id="button-name" :class="`indent-3 w-[50px] text-xl font-bold text-ellipsis my-auto text-[${document.text}] leading-[0.5]`">
                        {{ document.label }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data () {
        return {
            documents: [],
            colors: [
                {bg: '#0D3A63', text: '#fff'},
                {bg: '#1E84E3', text: '#fff'},
                {bg: '#1F8BF0', text: '#fff'},
                {bg: '#2B4863', text: '#fff'},
                {bg: '#145C9E', text: '#fff'},
            ],
        }
    },
    props: {
        employee: {
            default: null,
            type: Number,
        }
    },
    computed: {
        ...mapGetters([
            '_employee_file_list'
        ]),
    },
    mounted() {
        this.fetchDocumentsList(this.employee).then(() => {
            this.documents = this._employee_file_list.map(obj => {
                let color = this.randomColor();
                return {
                    ...obj,
                    color: color.bg,
                    text: color.text
                };
            });
        });
    },
    created(){
        if(this.employee === null) this.employee = this.$route.params.id;
    },
    methods: {
        ...mapActions([
            'fetchDocumentsList'
        ]),
        randomColor() {
            return this.colors[Math.floor(Math.random() * this.colors.length)];
        },
    },
}
</script>
<style scoped>
.main{
    height: 100%;
    /* padding: 25px; */
}
</style>