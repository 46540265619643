<template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class="bg-white px-2 focus:text-gray-600 active:text-gray-600" v-model="selectedReason">  
          <optgroup label = "Select a reason">
            <option hidden value="0">Choose a reason</option>
            <option value='clocker'>Clocker Issues</option>
            <option value='misuse'>Agent misuse</option>
            <option value='internet'>Internet Issues</option>
            <option value='misssed'>Not tracked</option>
          </optgroup>
        </select>
      </div>
</template>

<script>
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error:null,
            selectedReason: 0
        }
    },   
    watch:{
      selectedReason: Debounce(function(){
          this.$emit('toggle',{
              key:'reason',
              value :this.selectedReason
          });
      },200)     

    }
}
</script>
