<template>
  <div class="">
      <a id="toggler" class="flex text-left text-ellipsis text-nowrap text-body hover:bg-gray-100 hover:no-underline hover:cursor-pointer px-2 py-2 rounded" :class="{'bg-gray-100 active' :isOpen}" @click="toggle">
         <slot name="title">Sample text</slot>
        <span class="content-center ml-auto text-xs float-right" :class="{'transform -rotate-90':!isOpen}"><i class="bi bi-chevron-down"></i></span>
      </a>
    <transition name="slide-fade">
      <div v-if="open" class="">
        <slot></slot>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: "Accordion.vue",
  data(){
    return{
      open:false
    }
  },
  methods:{
    toggle:function(){
      this.open = !this.open;
    }
  },
  computed:{
    isOpen:function(){
      return this.open;
    }
  }
}
</script>

<style scoped>
/* Enter and leave animations can use different */
  /* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .35s ease;
}
.slide-fade-leave-active {
  transition: all .35s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-15px);
  opacity: 0;
}
*{
  transition:all ease-in-out 0.25s;
}
button span:not(:last-child){
  font-variant: small-caps;
  font-size:10px !important;
}

button.active,button:hover{
  color:#37474f !important;
}
button{
  color:#78909c !important;
}
</style>