<template>
  <div class="container-fluid">

    <div class="row text-center justify-content-center">
        <h3>Special Payroll Request</h3>
        <div class="text-sm leading-3 my-3 align-middle rounded-md bg-blue-200 p-2 pt-4">
            <p>Employee: {{this.employeeId.EmpID}}</p>
            <p>Date: {{this.dateSelected}}</p>
        </div>
    </div>

    <div>

      <div>

        <div class="text-center justify-content-center w-full">
            <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
              <b-progress-bar :value="this.currentDuration" variant="warning">
                <div class="text-center justify-content-center text-gray-700">{{this.newPayTime}} Payable Hrs, {{this.newBillTime}} Billable Hrs</div>
              </b-progress-bar>
                <!-- <div class="text-center justify-content-center text-gray-700 mt-3 flex-1">{{this.newPayTime}} Payable Hrs</div> -->
            </b-progress>
        </div>

        <div class="my-4">
          <label for="durations"><label class="text-red-500">*</label> Please, enter the requested information: </label>
          <div id="durations" class="text-center justify-content-center w-full flex">
              <div class="flex-row bg-gray-200 p-2 rounded-l-md">
                  <div class="flex-col mr-1">
                      <label>Standard Billing Time</label>
                      <vue-timepicker :disabled="timerDisabled" v-model="billableStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                  </div>
                  <div class="flex-col mr-1 mt-2">
                      <label>Graveyard Billing Time</label>
                      <vue-timepicker  :disabled="timerDisabled" v-model="billableGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                  </div>
              </div>

              <div class="flex-row bg-gray-200 p-2 rounded-r-md">
                  <div class="flex-col ml-1">
                      <label>Standard Payroll Time</label>
                      <vue-timepicker :disabled="timerDisabled"  v-model="payableStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                  </div>
                  <div class="flex-col ml-1 mt-2">
                      <label>Graveyard Payroll Time</label>
                      <vue-timepicker  :disabled="timerDisabled" v-model="payableGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                  </div>
              </div>
          </div>
        </div>

        <!-- Reasons section -->
        <div class="text-left justify-content-center w-full mb-3">
            <label for="reasonDrop"><label class="text-red-500">*</label> Select a Reason: </label>
            <select name="reasonDrop" id="reasonDrop" v-model="reason" class="form-control">
              <option value="null">Choose an option...</option>
              <option v-for="reason in getSPRReasons" :value="reason.Value" :key="reason.value">{{reason.Comment}}</option>
            </select>
        </div>

        <!-- Comment section -->
        <div class="text-left justify-content-center w-full mb-3">
            <label><label class="text-red-500">*</label> Comment:</label>
            <b-form-textarea :disabled="disableComment" v-model="eventComment" placeholder="Please add a comment..." rows="3" max-rows="3" class="resize-none"></b-form-textarea>
        </div>
        <p class="text-xs text-red-500">
                        * Mandatory fields
                    </p>

        <!-- Buttons -->
        <div class="row mb-3">
          <div class="col">
            <button type="button" @click="calculateTime" class="bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded">Calculate</button>
          </div>
          <div class="col">
            <button type="button" @click="fixTime" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded" :disabled="!canFix" :class="{ 'cursor-default' : !canFix }">Fix Time</button>
          </div>
          <div class="col">
            <button type="button" @click="clearTime" class="bg-gray-200 px-6 py-2 rounded">Clear</button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button type="button" @click="saveTime" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded" :disabled="!canSave || sending" :class="{ 'cursor-default' : !canSave }">Save</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from "vue2-timepicker";
// import moment from "moment";
// import Loading          from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: { VueTimepicker },
  data() {
    return {
      isLoading: true,
      maxDuration:    0,
      currentDuration: 0,

      payableStdTime:   { HH: "00", mm: "00" },
      payableGrvTime:   { HH: "00", mm: "00" },
      billableStdTime:  { HH: "00", mm: "00" },
      billableGrvTime:  { HH: "00", mm: "00" },

      newBillTime: 0,

      newPayTime:   0,
      newPStandard:    0,
      newPGraveyard:   0,
      newDuration:    0,

      eventComment:   null,
      reason:         null,
      canSave:        false,
      timerDisabled:  false,
      canFix:         false,
      eventSelected:  null,
      disableEvent:   false,
      disableComment: false,
      sending:        false,
      //Loader Data
      isLoaderActive: false,
      fullPage: true,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: 'dots',

      //No Negative shifts
      shiftStdTime: 0,
      shiftGrvTime: 0,
      activitiesStdTime: 0,
      activitiesGrvTime: 0
    };
  },
  props: {
    employeeId:     Object,
    dateSelected:   String,
  },
  computed: {
    ...mapState(["payroll"]),
    ...mapGetters(["getInfoByApprovalID", "getSPRReasons", "getSpecialRequests"]),
  },
  created(){
    this.fetchPRReasonsCatalog();
  },
  methods: {
    ...mapActions(["saveSpecialRequest", "fetchPRReasonsCatalog", "fetchSpecialPayrollRequestsPerEmpID"]),

    async saveTime(){
        if(await this.thereIsRequestsActive()){
          this.$fire({
            title: 'Oops! Seems there is active request for this user',
            text: "Please, cancel the existing request to make a new one. If there is no request on the table, please report to an administrator",
            showConfirmButton: true,
          })  
          this.$emit('closeModal', true);
          return;
        }

        var newPStandard     = ((this.payableStdTime.HH*60) + (this.payableStdTime.mm*1))*60;
        var newPGraveyard    = ((this.payableGrvTime.HH*60) + (this.payableGrvTime.mm*1))*60;
        var newBStandard     = ((this.billableStdTime.HH*60) + (this.billableStdTime.mm*1))*60;
        var newBGraveyard    = ((this.billableGrvTime.HH*60) + (this.billableGrvTime.mm*1))*60;

        let dataObj = {
            EmpID:              this.employeeId.EmpID,
            dateSelected:       this.dateSelected,
            payableStdTime:     newPStandard,
            payableGrvTime:     newPGraveyard,
            billableStdTime:    newBStandard,
            billableGrvTime:    newBGraveyard,
            eventComment:       this.eventComment,
            reason:             this.reason,
        };
        this.sending = true;
        await this.saveSpecialRequest(dataObj).then(()=>{
                this.$fire({
                    type: 'success',
                    title: 'The request has been created successfully',
                    showConfirmButton: false,
                    timer: 2500
                }).then(()=>{ 
                    this.$emit('closeModal', true);       
                });
        }).catch(()=>{
            this.$fire({
                    type: 'error',
                    title: 'Oops! Something went wrong',
                    text: 'Please, report to the system administrator',
                    showConfirmButton: false,
                    timer: 1500
                })
        })
    },

    async thereIsRequestsActive(){
      let params = {
          EmpID:  this.employeeId.EmpID,
          date:   this.dateSelected
      };
      await this.fetchSpecialPayrollRequestsPerEmpID(params);
      return this.getSpecialRequests?.length > 0;
    },

    calculateTime: function () {
        // To Create a moment object only for time we need to add a fake Date
        var newPStandard     = (this.payableStdTime.HH*60) + (this.payableStdTime.mm*1);
        var newPGraveyard    = (this.payableGrvTime.HH*60) + (this.payableGrvTime.mm*1);
        var newBStandard     = (this.billableStdTime.HH*60) + (this.billableStdTime.mm*1);
        var newBGraveyard    = (this.billableGrvTime.HH*60) + (this.billableGrvTime.mm*1);
        var payMins = newPGraveyard + newPStandard;
        var billMins = newBStandard + newBGraveyard;

        if(this.eventComment === null || this.eventComment === '' || this.reason === null) {
            this.$fire({
                type: "error",
                title: "Comment and Reason are mandatory",
                showConfirmButton: true,
            })
        }else{
            // Here we're checking if the shift is not less than the other events
            this.newPayTime   = Math.round(payMins / 60 * 100) / 100; // total value of new event
            this.newBillTime   = Math.round(billMins / 60 * 100) / 100; // total value of new event
            this.maxDuration        = 10 // payMins >= billMins ? this.newPayTime : this.newBillTime;
            this.currentDuration    = 10 // payMins >= billMins ? this.newPayTime : this.newBillTime;
            this.newDuration        = payMins * 60;
            this.newPStandard       = newPStandard  * 60;
            this.newPGraveyard      = newPGraveyard * 60;
            this.newBStandard       = newBStandard  * 60;
            this.newBGraveyard      = newBGraveyard * 60;
            if (billMins > payMins || newBStandard > newPStandard || newBGraveyard > newPGraveyard){
                let reason = billMins > payMins ? "Billable time is bigger than payable time" : newBStandard > newPStandard ? "Billable Standard time is bigger than Payable Standard time" : "Billable Graveyard time is bigger than Payable Graveyard time";
                this.$fire({
                    type: "warning",
                    title: "Please Verify",
                    text: "Reason: "+reason,
                    showConfirmButton: true,
                }).then(() => {
                    this.canFix = false;
                })
            }else{
                this.canFix = true;
            }
        }
    },

    fixTime: function () {
      this.timerDisabled = true;
      this.canSave = true;
      this.disableComment = true;
    },

    clearTime: function () {
      this.payableStdTime = { HH: "00", mm: "00" };
      this.payableGrvTime = { HH: "00", mm: "00" };
      this.billableStdTime = { HH: "00", mm: "00" };
      this.billableGrvTime = { HH: "00", mm: "00" };

      this.maxDuration = 0;
      this.currentDuration = 0;

      this.newPayTime = null;
      this.newBillTime = null;
      this.timerDisabled = false;
      this.canSave = false;
      this.disableEvent = false;
      this.disableComment = false;
      this.eventComment = null;
    },

    }
}
</script>
<style lang="scss">
</style>