<template>
    <div>
        <div class="flex justify-start my-4">
            <button
                class="bg-c-primary disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                @click="approve()"
            >
                Approve this shift
            </button>
        </div>
        <div class="flex space-x-4" v-if="getClockerHours">
            <div class="w-1/3 h-[600px] mx-auto bg-gray-50 rounded-xl p-2">
                <time-line :shifts="getClockerHours" />
            </div>
            <shifts-list
                @refresh="keepAlive"
                :events="getClockerHours"
                :employee="employee"
                class="w-2/3"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TimeLine from "@/views/clocker/adjustments/components/TimeLine";
import ShiftsList from "@/views/clocker/adjustments/components/ShiftsList";
import auth from "@/config/user";
export default {
    components: { TimeLine, ShiftsList },
    props: {
        shift_id: {
            type: Number,
            required: false,
            default() {
                return this.$route.params.shift_id;
            },
        },
    },
    computed: {
        ...mapGetters(["getClockerHours"]),
        employee() {
            return auth.currentUser.EmpID;
        },
    },
    methods: {
        ...mapActions(["fetchShiftHours", "storeShiftReview"]),
        keepAlive() {
            this.fetchShiftHours(this.shift_id)
                .then(() => {
                    // console.log("Good");
                })
                .catch((error) => {
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true
                    });
                    this.$router.push({ name: "dashboard" });
                });
        },
        approve() {
            this.$confirm(
                "This will set the shift as already reviewed.",
                "Approve Shift",
                "warning"
            ).then((result) => {
                if (result) {
                    this.storeShiftReview({
                        emp_id: auth.currentUser.EmpID,
                        shift_id: this.shift_id,
                    }).then(() => {
                        this.$fire({
                            type: "success",
                            title: "Thanks for reviewing this shift.",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            this.$router.push({ name: "dashboard" });
                        });
                    });
                }
            });
        },
    },
    created() {
        this.keepAlive();
    },
    mounted() {
        let title = "Shift Review";
        this.$emit("set-title", title);
    },
};
</script>