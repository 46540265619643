<template>
    <div class="h-full w-full rounded-xl bg-gradient-to-r from-gray-800 to-gray-600">
        <div class="flex flex-col align-middle h-full text-white text-center justify-content-center">
            <div class="my-3">
                <span class="flex text-center justify-content-center">
                    <div class="flex my-blue px-3 pt-2 rounded-3xl align-middle">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd"></path></svg>
                        <h4>Device ID:</h4>
                    </div>
                </span>
                <span class="border-b-2 border-color-gray-700">{{this.deviceNumer || 'Not found'}}</span>
            </div>
            <div class="my-3">
                <span class="flex text-center justify-content-center">
                    <div class="flex my-blue px-3 pt-2 rounded-3xl align-middle">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd"></path></svg>
                        <h4>Status:</h4> 
                    </div>
                </span>
                <span class="border-b-2 border-color-gray-700">{{this.deviceStatus || 'Not found'}}</span>
            </div>
            <div class="my-3">
                <span class="flex text-center justify-content-center">
                    <div class="flex my-blue px-3 pt-2 rounded-3xl align-middle">
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z" clip-rule="evenodd"></path></svg>
                        <h4>Type:</h4>
                    </div>
                </span>
                <span class="border-b-2 border-color-gray-700">{{this.deviceType || 'Not found'}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    data() {
        return{
            showInfo: true
        }
    },
    methods: {

    },
    computed: {
        ...mapGetters(['getDeviceInfo', 'getRSALoading']),
        deviceNumer: function() {
            return this.getDeviceInfo?.deviceId
        },
        deviceStatus: function() {
            return this.getDeviceInfo?.status?.toUpperCase()
        },
        deviceType: function() {
            return this.getDeviceInfo?.itemType?.toUpperCase()
        }
    },
    created() {

    }
}
</script>
