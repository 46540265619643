<template>
    <div class="pdf-container">
      <object :data="pdf" type="application/pdf">
        <p>
          It seen we can't visualize this file.
          You can <a :href="pdf">click here to open the PDF file.</a>
        </p>
      </object>
    </div>
</template>
<script>
  export default {
    props: {
      pdf: {
        type: String,
        required: true
      },
    }
  };
  </script>
<style scoped>
.pdf-container {
  height: 100%;
  min-height: inherit;
}

.pdf-container object {
  min-height: inherit;
  height: 100%;
  width: 100%;
}
</style>