<template>
    <div class="bg-gray-200 rounded-lg p-2 m-2 h-1/3 overflow-hidden">
        <div class="overflow-y-scroll h-full p-2">
            <div v-for="item in getVault" :key="item.id" class="text-left text-gray-700 border-b-[1px] border-gray-700 p-2 w-full
            hover:bg-gray-600 hover:text-white cursor-pointer flex flex-row" @click="selectAPassword(item)">
                <div class="text-left w-1/2">
                    {{ item.label }}
                </div>
                <i class="bi bi-caret-right-fill text-right w-1/2"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default{
    methods: {
        ...mapActions([
            'fetchPasswordVault'
        ]),
        selectAPassword(item){
            this.$emit('selection', item);
        },
    },
    computed: {
        ...mapGetters([
            'getVault'
        ])
    },
    created(){
        this.fetchPasswordVault();
    }
}
</script>