<template>
  <div class="py-2">
      <div class="flex flex-col">
            <div class="flex  w-5/12 mx-auto p-3">
                <div class="w-8/12 p-1 my-auto text-left bg-white shadow-md rounded-md relative">
                    <span   class="w-2/12 ">Form Title:</span>
                    <input  class="w-9/12 py-1 px-1 mx-1 bg-gray-200 rounded-md" type="text" v-model="formTitle">
                </div>
                <div class="mx-2 my-1 p-0">
                    <button @click="saveForm" class="p-1 bg-green-700 text-white rounded-md shadow-md" style="cursor:pointer">Save Form</button>
                </div>
            </div> 
      </div>
      <div class="flex flex-row">
        <div v-if="elementClicked"  class="w-3/12 mx-auto">
            <div class="bg-white shadow-md rounded-md relative">
                <span>Options</span>
            </div>
        </div>          
        <div class="w-9/12 mx-auto">
            <div id="formTemplate" class="flex flex-col bg-white shadow-md rounded-md relative">
                <div class="text-center justify-center items-center py-3 px-3">
                    <div v-if="this.isEmpty" class="p-1 w-full border-dashed border-4 border-light-blue-500">Insert an element</div>
                    <draggable ref="draggable" :disabled="!enabled" @elementSelected="elementSelected" class="w-full list-group cursor-pointer" ghost-class="ghost" @start="dragging=true" @end="dragging=false"></draggable>
                </div>
            </div>
        </div>
        <div class="w-2/12 mx-auto"> 
            <div class="bg-white shadow-md rounded-md p-2 relative">
                <span class="py-1">HTML Elements</span>
                <div class="flex-row py-2">
                    <button @click="addElement('label')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
                        </svg>Label
                    </button>
                    <button @click="addElement('text')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>Input Text
                    </button>
                    <button @click="addElement('datepicker')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>Date
                    </button>
                    <button @click="addElement('time')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>Time
                    </button>
                    <button @click="addElement('dropdown')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                        </svg>Dropdown
                    </button>
                    <button @click="addElement('multiple')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>Multiple Options
                    </button>
                    <button @click="addElement('checkbox')" class="w-full flex items-center  bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" style="cursor:pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>Checkbox
                    </button>
                </div>
            </div>
        </div>
      </div>
      <div>
           <div class="w-full mx-auto p-3">
                <div class="w-full bg-white shadow-md rounded-md relative">
                    <span>JSON Content</span>
                    <div>{{getCurrentForm}}</div>
                </div>
            </div> 
      </div>
      <b-modal ref="newelements" hide-footer hide-header>
          <form-builder-label       v-if="this.newElement == 'label'"></form-builder-label>
          <form-builder-text        v-if="this.newElement == 'text'"></form-builder-text>
          <form-builder-datepicker  v-if="this.newElement == 'datepicker'"></form-builder-datepicker>
          <form-builder-dropdown    v-if="this.newElement == 'dropdown'"></form-builder-dropdown>
          <form-builder-time        v-if="this.newElement == 'time'"></form-builder-time>
          <form-builder-checkbox    v-if="this.newElement == 'checkbox'"></form-builder-checkbox>
          <form-builder-multiple    v-if="this.newElement == 'multiple'"></form-builder-multiple>
      </b-modal>
      
  </div>
</template>
<script>
    import Vue from 'vue';
    import {mapState, mapActions, mapGetters } from 'vuex'
    import { EventBus } from '@/event-bus'
    import draggable from 'vuedraggable'

    //Modal components
    import labelModal       from    '@/views/formbuilder/modalComponent/label';
    import inputModal       from    '@/views/formbuilder/modalComponent/input';
    import datepickerModal  from    '@/views/formbuilder/modalComponent/datepicker';
    import dropdownModal    from    '@/views/formbuilder/modalComponent/dropdown';
    import timeModal        from    '@/views/formbuilder/modalComponent/time';
    import checkboxModal    from    '@/views/formbuilder/modalComponent/checkbox';
    import multipleModal    from    '@/views/formbuilder/modalComponent/multiple';

    //HTML Components
    import labelComponent       from    '@/views/formbuilder/components/label';
    import inputComponent       from    '@/views/formbuilder/components/input';
    import dateComponent        from    '@/views/formbuilder/components/datepicker';
    import dropdownComponent    from    '@/views/formbuilder/components/dropdown';
    import timeComponent        from    '@/views/formbuilder/components/time';
    import checkboxComponent    from    '@/views/formbuilder/components/checkbox';
    import multipleComponent    from    '@/views/formbuilder/components/multiple';

    export default {
        components: {
            draggable,
            'form-builder-label'        : labelModal,
            'form-builder-text'         : inputModal,
            'form-builder-datepicker'   : datepickerModal,
            'form-builder-dropdown'     : dropdownModal,
            'form-builder-time'         : timeModal,
            'form-builder-checkbox'     : checkboxModal,
            'form-builder-multiple'     : multipleModal,
        },
        computed: {
            ...mapState(['formBuilder']),
            ...mapGetters(['getCurrentForm']),
        },
        data(){
            return { 
                title : "Form Builder", 
                isEmpty : true, 
                enabled: true,
                dragging: false,
                elementClicked: false,
                newElement: '',
                newComponentIdx: 1,
                formTitle: "",
            }
        }, 
        mounted(){
            this.$emit('set-title',this.title); 
            EventBus.$on('addNewComponent', this.injectNewComponent );
        },
        methods:{
            ...mapActions(['addElementToForm','storeForm']),
            saveForm: function(){
                let dataForm = {
                    formName: this.formTitle,
                    jsonForm: JSON.stringify(this.getCurrentForm)
                }
                this.storeForm(dataForm).then(()=>{
                    this.$fire({
                            type: 'success',
                            title: 'Form Created!',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            console.log("All good")                
                        });
                }).catch(()=>{
                    this.$fire({
                            type: 'warning',
                            title: 'Oops! Something went wrong. Please try again',
                            text: 'If the problem persist, please report this issue at the SupportHero! chat',
                            showConfirmButton: true
                        })
                });
            },
            addElement: function(data){
                this.$refs['newelements'].show();
                this.newElement = data;
            },
            addLabel: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(labelComponent);
                var instance = new newComponent({
                    propsData: {
                        htmlContent :   params.htmlContent,
                        isTitle:        params.isTitle,
                        classToAdd:     params.classToAdd,
                        elementID:      "cLabel" + this.newComponentIdx,
                        elementKey:     "cLabel" + this.newComponentIdx,
                    }
                });
                var labelToInsert = {
                    component:  'label',
                    isTitle:    params.isTitle,
                    class:      params.classToAdd,
                    id:         "cLabel" + this.newComponentIdx,
                    key:        "cLabel" + this.newComponentIdx,
                    HTMLText:   params.htmlContent
                }
                this.addElementToForm(labelToInsert);
                this.newComponentIdx++;
                instance.$mount();
                instance.$el.addEventListener('click', this.elementSelected);
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addInput: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(inputComponent);
                var instance = new newComponent({
                    propsData: {
                        placeholder:    params.placeholder,
                        inputLabel:     params.labelTitle,
                        classToAdd:     params.classToAdd,
                        elementID:      "cInput" + this.newComponentIdx,
                        elementKey:     "cInput" + this.newComponentIdx,
                    }
                });
                var inputToInsert = {
                    component:      'input',
                    label:          params.labelTitle,
                    placeholder:    params.placeholder,
                    class:          params.classToAdd,
                    id:             "cLabel" + this.newComponentIdx,
                    key:            "cLabel" + this.newComponentIdx,
                }
                this.addElementToForm(inputToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addDatepicker: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(dateComponent);
                var instance = new newComponent({
                    propsData: {
                        inputLabel:     params.labelTitle,
                        classToAdd:     params.classToAdd,
                        elementID:      "cDatepicker" + this.newComponentIdx,
                        elementKey:     "cDatepicker" + this.newComponentIdx,
                    }
                });
                var elementToInsert = {
                    component:      'datepicker',
                    label:          params.labelTitle,
                    class:          params.classToAdd,
                    id:             "cDatepicker" + this.newComponentIdx,
                    key:            "cDatepicker" + this.newComponentIdx,
                }
                this.addElementToForm(elementToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addDropdown: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(dropdownComponent);
                var instance = new newComponent({
                    propsData: {
                        classToAdd:     params.classToAdd,
                        dropdownLabel:  params.dropdownLabel,
                        elementID:      "cDropdown" + this.newComponentIdx,
                        elementKey:     "cDropdown" + this.newComponentIdx,
                        optionsToAdd:   params.options,
                    }
                });
                 var elementToInsert = {
                    component:      'dropdown',
                    label:          params.dropdownLabel,
                    class:          params.classToAdd,
                    id:             "cDropdown" + this.newComponentIdx,
                    key:            "cDropdown" + this.newComponentIdx,
                    options:        params.options,
                }
                this.addElementToForm(elementToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addTimeInput: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(timeComponent);
                var instance = new newComponent({
                    propsData: {
                        inputLabel:     params.labelTitle,
                        classToAdd:     params.classToAdd,
                        elementID:      "cTime" + this.newComponentIdx,
                        elementKey:     "cTime" + this.newComponentIdx,
                    }
                });
                var elementToInsert = {
                    component:      'time',
                    label:          params.labelTitle,
                    class:          params.classToAdd,
                    id:             "cTime" + this.newComponentIdx,
                    key:            "cTime" + this.newComponentIdx,
                }
                this.addElementToForm(elementToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addCheckboxInput: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(checkboxComponent);
                var instance = new newComponent({
                    propsData: {
                        chkboxValue:    params.value,
                        checkboxText:   params.text,
                        classToAdd:     params.classToAdd,
                        elementID:      "cCheckbox" + this.newComponentIdx,
                        elementKey:     "cCheckbox" + this.newComponentIdx,
                    }
                });
                var elementToInsert = {
                    component:      'checkbox',
                    value:          params.value,
                    text:           params.text,
                    class:          params.classToAdd,
                    id:             "cCheckbox" + this.newComponentIdx,
                    key:            "cCheckbox" + this.newComponentIdx,
                }
                this.addElementToForm(elementToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            addMultiple: function(params){
                this.isEmpty = false;
                var newComponent = Vue.extend(multipleComponent);
                var instance = new newComponent({
                    propsData: {
                        classToAdd:     params.classToAdd,
                        multipleLabel:  params.multipleLabel,
                        elementID:      "cMultiple" + this.newComponentIdx,
                        elementKey:     "cMultiple" + this.newComponentIdx,
                        optionsToAdd:   params.options,
                    }
                });
                 var elementToInsert = {
                    component:      'multiple',
                    label:          params.multipleLabel,
                    class:          params.classToAdd,
                    id:             "cMultiple" + this.newComponentIdx,
                    key:            "cMultiple" + this.newComponentIdx,
                    options:        params.options,
                }
                this.addElementToForm(elementToInsert);
                this.newComponentIdx++;
                instance.$mount();
                document.getElementsByClassName('list-group')[0].appendChild(instance.$el);
            },
            elementSelected: function(element){
                console.log(element);
            },
            injectNewComponent: function(dataObject){
                let newComponent = dataObject.component;
                switch(newComponent){
                    case 'label':
                        this.addLabel(dataObject);
                        break;
                    case 'text':
                        this.addInput(dataObject);
                        break;
                    case 'datepicker':
                        this.addDatepicker(dataObject);
                        break;
                    case 'dropdown':
                        this.addDropdown(dataObject);
                        break;
                    case 'time':
                        this.addTimeInput(dataObject);
                        break;
                    case 'checkbox':
                        this.addCheckboxInput(dataObject);
                        break;
                    case 'multiple':
                        this.addMultiple(dataObject);
                        break;
                    default:
                        break;
                }
                this.$refs['newelements'].hide();
            }
        },
    }
</script>
<style scoped>
</style>