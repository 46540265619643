import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    actionList:     [],
    reasonList:     [],
    locationList:   [],
    roleList:       [],
    titleList:      [],
    teamList:       [],
    lobList:        [],
    departmentList: [],
    statusList:     [],
    projectList:     [],
    reportToList:   [],
    loadingFlag: true,
    status:'loading',
    loadingDepartments:false,
    campaignList: [],
    supervisorList:[],
    organizationStructure: [],
    campaignDefinitionsList: [],
    sitesList: [],
    titleDefinitionsList: [],
    lobDefinitionsList: [],
    projectDefinitionsList: [],
    filteredDefinitionNames: [],
    definitionParents: [],
    departmentChildren: [],
    allCampaigns: []
};
const getters = {
    getDepartmentChildren: (state) => state.departmentChildren,
    getFilteredDefinitionNames: (state) => state.filteredDefinitionNames,
    getLobDefinitions: (state) => state.lobDefinitionsList,
    getProjectDefinitions: (state) => state.projectDefinitionsList,
    getTitleDefinitions: (state) => state.titleDefinitionsList,
    getCampaignDefinitions: (state) => state.campaignDefinitionsList,
    getOrganizationStructure: (state) => state.organizationStructure,
    getActions: (state)     => state.actionList,
    getReasons: (state)     => state.reasonList,
    getLocations: (state)   => state.locationList,
    getCampaigns: (state)   => state.campaignList,
    getRoles: (state)       => state.roleList,
    getTitles: (state)      => state.titleList,
    getTeams: (state)       => state.teamList,
    getLob: (state)         => state.lobList,
    getDepartments: (state) => state.departmentList,
    getStatus: (state)      => state.statusList,
    getReportTo: (state)    => state.reportToList,
    getDefinitionStatus: (state) => state.status,
    getDepartmentStatus:(state) => state.loadingDepartments,
    getSupervisorList:(state) => state.supervisorList,
    getSites: (state) => state.sitesList,
    getDefinitionParents: (state) => state.definitionParents,
    getAllCampaigns: (state) => state.allCampaigns
};
const actions = {
    async fetchActions({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/actions';
        const response = await axios.get(endpoint);
        commit('setActionList',response.data.actions);
    },

    async fetchReasons({ commit }, id){
        const endpoint = API_URL + 'api/v1/definitions/'+id+'/reasons';
        const response = await axios.get(endpoint);
        commit('setReasonList',response.data.reasons);
    },

    async fetchSites({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/sites';
        const response = await axios.get(endpoint);
        commit('setLocationList',response.data.locations);
    },
    async fetchCampaigns({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/campaigns';
        const response = await axios.get(endpoint);
        commit('setAllCampaigns',response.data.campaigns);
    },

    async fetchCampaignBySite({ commit }, site){
        const endpoint = API_URL + 'api/v1/definitions/campaignBySite/'+site;
        const response = await axios.get(endpoint);
        commit('setCampaignList',response.data.msg);
    },

    async fetchLobByCampaignSite({ commit }, data){
        const endpoint = API_URL + `api/v1/definitions/lobByCampaignSite/${data.location}/${data.campaign}`;
        const response = await axios.get(endpoint);
        commit('setLOBsList',response.data.msg);
    },
    
    async fetchLobs({ commit }){
        const endpoint = API_URL + `api/v4/definitions/lobs`;
        const response = await axios.get(endpoint);
        commit('setLOBsList',response.data);
    },
    
    async fetchLobsByEmployee({ commit }, emp_id){
        const endpoint = API_URL + `api/v4/definitions/lobs-by-employee/${emp_id}`;
        const response = await axios.get(endpoint);
        commit('setLOBsList',response.data);
    },

    async fetchLocations({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/locations';
        const response = await axios.get(endpoint);
        commit('setLocationList',response.data.locations);
    },

    async fetchRoles({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/roles';
        const response = await axios.get(endpoint);
        commit('setRolesList',response.data.roles);
    },

    async fetchTitlesByDepartment({commit}, id){
        const endpoint = API_URL + 'api/v4/definitions/'+id+'/titles-by-department';
        const response = await axios.get(endpoint);
        commit('setTitlesList',response.data.titles);
    },

    async fetchTeams({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/teams';
        const response = await axios.get(endpoint);
        commit('setTeamsList',response.data.teams);
    },

    async fetchSupervisors({ commit }){
        const endpoint = API_URL + 'api/v1/definitions/supervisors';
        const response = await axios.get(endpoint);
        commit('setSupervisorsList',response.data.supervisors);
    },

    async fetchLobByCampaign({ commit }, id){
        const endpoint = API_URL + 'api/v4/definitions/' + id + '/lobs-by-campaign';
        await axios.get(endpoint).then( (response)=>{
            commit('setLOBsList', response.data.lobs);
            commit('setDefinitionStatus','done');
        });
    },

    async fetchDepartments({ commit }){
        commit('setDepartmentStatus',true)
        const endpoint = API_URL + 'api/v1/definitions/departments';
        const response = await axios.get(endpoint);
        commit('setDepartmentsList',response.data.departments);
        commit('setCampaignList', []);
        commit('setDepartmentStatus',false)
    },

    async fetchReportToByCampaign({ commit }, idCampaign){
        const endpoint = API_URL + 'api/v4/definitions/'+idCampaign+'/report-to-by-campaign';
        await axios.get(endpoint).then((response)=>{
            commit('setReportToList',response.data.supervisors);
        });
    },

    async fetchReportToByCampaignName({ commit }, idCampaign){
        const endpoint = API_URL + 'api/v4/definitions/'+idCampaign+'/report-to-by-campaign-name';
        await axios.get(endpoint).then((response)=>{
            commit('setReportToList',response.data.reportTo);
        });
    },

    async fetchCampaignsByDepartment({ commit }, departmentID ){
        commit('setDefinitionStatus','loadingCampaigns')
        const endpoint = API_URL + 'api/v4/definitions/'+ departmentID +'/campaigns-by-department';
        await axios.get(endpoint).then((response)=>{
            commit('setCampaignList', response.data.campaigns);
            commit('setDefinitionStatus','loaded')
        });
    },

    async fetchAllProjects({ commit } ){
        const endpoint = API_URL + 'api/v1/definitions/getAllCampaigns';
        await axios.get(endpoint).then((response)=>{
            commit('setProjectList',response.data.projects);
        });
    },

    async fetchTitles({ commit } ){
        const endpoint = API_URL + 'api/v1/definitions/titles';
        await axios.get(endpoint).then((response)=>{
            commit('setTitleList',response.data.titles);
        });
    },

    async fetchCampaignsByDepartmentName({ commit }, departmentName ){
        const endpoint = API_URL + 'api/v4/definitions/'+ departmentName +'/campaigns-by-department-name';
        let response = await axios.get(endpoint).then((response)=>{
            commit('setCampaignList',response.data.campaigns);
            return response;
        });
        return response;
    },

    async fetchOrganizationStructure({ commit }){
        const endpoint = `${API_URL}api/v4/definitions/all`;
        let response = await axios.get(endpoint);
        commit('setDefinitions', response.data);

        return response;
    },

    async fetchCampaignDefinitions({ commit }){
        const endpoint = `${API_URL}api/v4/definitions/campaigns`;
        let response = await axios.get(endpoint);
        commit('setCampaignDefinitions', response.data);

        return response;
    },

    async saveDefinitions (_, payload) {
        const endpoint = `${API_URL}api/v4/definitions/store`;
        return await axios.post(endpoint, payload);
    },

    async editDefinition(_, payload) {
        const endpoint = `${API_URL}api/v4/definitions/edit`;
        return await axios.post(endpoint, payload);
    },

    async fetchSiteDefinitions({commit}) {
        const endpoint = `${API_URL}api/v4/definitions/sites`;
        let response = await axios.get(endpoint);
        commit('setSites', response.data);

        return response;
    },

    async fetchTitleDefinitions({commit}) {
        const endpoint = `${API_URL}api/v4/definitions/titles`;
        let response = await axios.get(endpoint);
        commit('setTitles', response.data);

        return response;
    },
    async fetchDefinitionNamesByType({commit}, payload) {
        const endpoint = `${API_URL}api/v4/definitions/names`;
        let response = await axios.get(endpoint, payload);
        commit('setFilteredDefinitionNames', response.data.names);

        return response;
    },
    async fetchDepartmentChildren({commit}, payload) {
        const endpoint = `${API_URL}api/v4/definitions/department-children`;
        let response = await axios.get(endpoint, payload);
        commit('setDepartmentChildren', response.data.children);

        return response;
    },

    async setDefinitionActiveStatus(_, payload) {
        const endpoint = `${API_URL}api/v4/definitions/set-active`;
        return await axios.post(endpoint, payload);
    },

    async saveDepartmentRelationships(_, payload) {
        const endpoint = `${API_URL}api/v4/definitions/store-relationship`;
        return await axios.post(endpoint, payload);
    },

    async fetchProjectsByCampaignName({ commit }, campaignName) {
        const endpoint = `${API_URL}api/v4/definitions/${campaignName}/projects-by-campaign-name`;
        let response = await axios.get(endpoint);
        commit('setProjects', response.data.projects);

        return response;
    }
};
const mutations = {
    setDepartmentChildren:      (state, departmentChildren) => (state.departmentChildren = departmentChildren),
    setDefinitionParents:       (state, definitionParents) => (state.definitionParents = definitionParents),
    setFilteredDefinitionNames: (state, filteredDefinitionNames) => (state.filteredDefinitionNames = filteredDefinitionNames),
    setProjects:                (state, projectDefinitionsList) => (state.projectDefinitionsList = projectDefinitionsList),
    setLobs:                    (state, lobDefinitionsList) => (state.lobDefinitionsList = [ lobDefinitionsList ]),
    setTitles:                  (state, titleDefinitionsList) => (state.titleDefinitionsList = [ titleDefinitionsList ]),
    setSites:                   (state, sitesList) => (state.sitesList = [ sitesList ]),
    setCampaignDefinitions:     (state, campaignDefinitionsList) => (state.campaignDefinitionsList = [ campaignDefinitionsList ]),
    setDefinitions:             (state, definitionsList) => (state.organizationStructure = [ definitionsList ]),
    setActionList:              (state, setActionList) => (state.setActionList = setActionList),
    setReasonList:              (state, reasonList) => (state.reasonList = reasonList),
    setLocationList:            (state, locationList) => (state.locationList = locationList),
    setRolesList:               (state, roleList) => (state.roleList = roleList),
    setTitlesList:              (state, titleList) => (state.titleList = titleList),
    setTeamsList:               (state, teamList) => (state.teamList = teamList),
    setProjectList:             (state, projectList) => (state.projectList = projectList),
    setTitleList:               (state, titleList) => (state.titleList = titleList),
    setLOBsList:                (state, lobList) => (state.lobList = lobList),
    setDepartmentsList:         (state, departmentList) => (state.departmentList = departmentList),
    setStatusList:              (state, statusList) => (state.statusList = statusList),
    setReportToList:            (state, reportToList) => (state.reportToList = reportToList),
    setDefinitionStatus:        (state, status) => (state.status = status),
    setDepartmentStatus:        (state, status) => (state.loadingDepartments = status),
    setCampaignList:            (state, campaignList) => (state.campaignList = campaignList),
    setSupervisorsList:         (state, supervisorList) => (state.supervisorList = supervisorList),
    setAllCampaigns:            (state, allCampaigns) => (state.allCampaigns = allCampaigns)
};
export default {
    state,
    getters,
    actions,
    mutations
};