|<template>
    <div>
        <div><span>New Input Configuration</span></div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-md ml-2 block group-focus:text-gray-600">Checkbox Text:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newChkInput.text">
        </div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-md ml-2 block group-focus:text-gray-600">Checkbox Value:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newChkInput.value">
        </div>
        <div class="relative sm:w-full md:w-full mb-2 px-2 mt-2 flex flex-col group">
            <label class="text-left text-xs ml-2 block group-focus:text-gray-600">CSS Class:</label>
            <input class="bg-gray-100  rounded-sm p-1 px-2" v-model="newChkInput.classToAdd">
        </div>
        <div class="w-full py-2">
            <button @click="sendNewComponent" class="ml-auto px-3 p-2 bg-gray-700 text-white shadow-sm rounded-sm w-full">Save</button>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
export default {
    data(){
        return{
            newChkInput:{
                component: 'checkbox',
            },
        }
    },
    methods:{
        sendNewComponent: function(){
            EventBus.$emit('addNewComponent', this.newChkInput);
        },
    },
}
</script>
<style scoped></style>
