<template>
  <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
            <span>{{item.trainee_id}}</span>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left w-1/2">
            <span v-if="!editMode">{{item.comment}}</span>
            <div v-else>
                <input  v-model="form.comment" placeholder="Place your comment here" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-700">
            </div>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
            {{item.created_by}}
        </td>

        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
             <div class="flex flex-row items-center justify-end" v-if="editMode">
                <button title="Cancel" class="p-2 rounded-full text-gray-400 hover:text-white hover:bg-red-400" @click="()=> editMode = false" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>          
                </button>

                <button title="Save changes" class="p-2 rounded-full text-gray-400 hover:text-white hover:bg-green-400" :disabled="editing" @click="editRow">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>        
                </button>

            </div>
            
            <div class="flex flex-row items-center justify-end" v-else>
                    

                    <button title="Edit row" class="p-2 rounded-full text-gray-400 hover:text-white hover:bg-yellow-400" @click="()=> editMode = true" >
                       
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                        </svg>               
                    </button>
                    <button :disabled="saving" title="Delete row" class="relative p-2 rounded-full text-gray-400 hover:text-white hover:bg-red-400" @click="deleteRow" >
                        <span class="absolute w-2 h-2 top-2 right-2 rounded-full bg-red-400 animate-pulse" v-if="saving"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>                 
                    </button>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    data(){
        return {
            form:{
                trainee_id: "",
                comment:"",
                author:""
            },
            saving: false,
            editing:false,
            editMode:false,
            old_comment:""
            
        }
    },
    props:{
        item:{
            type:Object,
            default:()=> {}
        }
    },
    mounted(){
        this.form.trainee_id = this.item.trainee_id;
        this.form. comment = this.item.comment;
        this.form.author = this.item.created_by;
        this.old_comment = this.item.comment;
    },
    methods:{
        deleteRow:async function(){
            const response = await this.$fire({type:'question','title':'Are you sure you want to proceed? This action can not be reverted in any way.',showCancelButton:true,confirmButtonText: 'Proceed'});
            if(!response?.value){
                return null;
            }
            this.saving = true;
            this.$emit('delete',this.item.id);
        },
        editRow:async function(){
            this.editing = true;
            if(this.form.comment === this.old_comment){
               return this.$fire({type:'warning','title':'Nothing to update.'});
            }
            await this.$emit('update',{id:this.item.id, comment:this.form.comment});
            this.editMode = false;
             this.editing = false;
        }
    }
}
</script>

<style>

</style>