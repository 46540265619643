<template>
    <div class="p-8">
        <div class="flex justify-center">
            <h3 class="font-bold">Editing {{ event.event }}</h3>
        </div>
        <div class="space-y-6 mt-3" v-if="!loading">
            <div class="space-y-3">
                <div class="flex items-center justify-center">
                    <small class="text-gray-500"
                        >If this {{ event.event }} is on-going please do not
                        fill the end time</small
                    >
                </div>

                <div class="flex gap-3 items-center justify-center" v-if="!justOneDate">
                    <div class="relative w-1/2">
                        <div
                            class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                        >
                            Start Date
                        </div>
                        <div class="py-2 px-3 rounded-xl border-[1px]">
                            <date-picker
                                v-model="start_date"
                                placeholder="YYYY-MM-DD"
                                value-type="format"
                                format="YYYY-MM-DD"
                                :input-class="['border-none']"
                                :disabled-date="disabledBeforeStartAndAfterEnd"
                                :clearable="false"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="relative w-1/2">
                        <div
                            class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                        >
                            End Date
                        </div>
                        <div class="py-2 px-3 rounded-xl border-[1px]">
                            <date-picker
                                v-model="end_date"
                                placeholder="YYYY-MM-DD"
                                value-type="format"
                                format="YYYY-MM-DD"
                                :input-class="['border-none']"
                                :disabled-date="disabledBeforeStartAndAfterEnd"
                            >
                            </date-picker>
                        </div>
                    </div>
                </div>

                <div class="flex gap-3 items-center justify-center">
                    <div class="relative w-1/2">
                        <div
                            class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                        >
                            Start time
                        </div>
                        <div class="py-2 px-3 rounded-xl border-[1px]">
                            <i class="bi bi-clock mr-1 text-gray-500"></i>
                            <vue-timepicker
                                class="peer"
                                v-model="start_time"
                                format="HH:mm"
                                hide-clear-button
                            />
                            <!-- :hour-range="availableStartHourRange"
                                :minute-range="availableStartMinuteRange" -->
                        </div>
                    </div>
                    <div class="relative w-1/2">
                        <div
                            class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                        >
                            End time
                        </div>
                        <div class="py-2 px-3 rounded-xl border-[1px]">
                            <i class="bi bi-clock mr-1 text-gray-500"></i>
                            <vue-timepicker
                                v-model="end_time"
                                format="HH:mm"
                                :disabled="endTimeIsDisabled"
                            />
                            <!-- :hour-range="availableEndHourRange"
                                :minute-range="availableEndMinuteRange" -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="space-y-3">
                <div class="flex items-center justify-center">
                    <small class="text-gray-500"
                        >This is required to add a comment for this
                        modification. Please be specific</small
                    >
                </div>
                <textarea
                    v-model="comment"
                    class="w-full p-3 resize-none rounded-lg border-[1px]"
                    placeholder="Write a comment"
                    maxlength="255"
                    rows="6"
                ></textarea>
                <small
                    class="text-gray-500 text-xs ml-2"
                    :class="{ 'text-red-500': commentIsInvalid }"
                >
                    {{ commentLength }} / 255.
                </small>
            </div>

            <div class="space-x-3 text-right mt-4">
                <button
                    type="button"
                    class="bg-c-secondary text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    :disabled="commentIsInvalid || endIsNotRight"
                    type="submit"
                    class="bg-c-primary disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="sendRequest"
                >
                    Continue
                </button>
            </div>
        </div>
        <div v-else class="text-center">
            <loader />
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import VueTimepicker from "vue2-timepicker";
import DatePicker from "vue2-datepicker";
import Loader from "@/views/components/Loader";
export default {
    components: {
        DatePicker,
        VueTimepicker,
        Loader,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        shift: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            start_time: this.event.start_time,
            end_time: this.event.end_time,
            start_date: this.event.start_date,
            end_date: this.event.end_date,
            comment: null,
            loading: false,
        };
    },
    watch: {
        end_time: {
            handler(value) {
                if (
                    typeof value === "object" &&
                    value.HH &&
                    value.mm !== undefined
                ) {
                    this.end_time = `${value.HH}:${value.mm.padStart(2, "0")}`;
                }
            },
        },

        start_time: {
            handler(value) {
                if (
                    typeof value === "object" &&
                    value.HH &&
                    value.mm !== undefined
                ) {
                    this.start_time = `${value.HH}:${value.mm.padStart(
                        2,
                        "0"
                    )}`;
                }
            },
        },
    },
    computed: {
        justOneDate(){
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const startDate = new Date(this.shift.start_date + "T00:00:00");
            const endDate = this.shift.end_date
                ? new Date(this.shift.end_date + "T00:00:00")
                : now;
            return startDate.getDate() === endDate.getDate();
        },

        endIsNotRight() {
            return (
                !this.justOneDate 
                &&
                (
                    (this.end_date !== null && (this.end_time === null || this.end_time === "")) 
                        ||
                    (this.end_date === null && (this.end_time !== null && this.end_time !== ""))
                )
            );
        },

        nowDate() {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            return now.toISOString().split("T")[0];
        },

        commentLength() {
            return this.comment?.length ?? 0;
        },

        commentIsInvalid() {
            return (
                this.comment === null ||
                this.comment.trim() === "" ||
                this.comment === undefined ||
                this.comment === "" ||
                this.comment?.length < 5
            );
        },

        endTimeIsDisabled() {
            return (
                this.event.event === "shift" &&
                this.event.events.some((event) => event.end_time === null)
            );
        },

        availableStartHourRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(24).keys()];
            return [...Array(Number(this.end_time.split(":")[0]) + 1).keys()];
        },

        availableStartMinuteRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(60).keys()];
            const endHour = Number(this.end_time.split(":")[0]);
            const endMinute = Number(this.end_time.split(":")[1]);
            const startHour = this.isValidTime(this.start_time)
                ? Number(this.start_time.split(":")[0])
                : null;
            if (startHour === endHour) {
                return [...Array(endMinute + 1).keys()];
            }
            return [...Array(60).keys()];
        },

        availableEndHourRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(24).keys()];
            const startHour = Number(this.start_time.split(":")[0]);
            return [...Array(24).keys()].filter((hour) => hour >= startHour);
        },

        availableEndMinuteRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(60).keys()];
            const startHour = Number(this.start_time.split(":")[0]);
            const startMinute = Number(this.start_time.split(":")[1]);
            const endHour = this.isValidTime(this.end_time)
                ? Number(this.end_time.split(":")[0])
                : null;
            if (startHour === endHour) {
                return [...Array(60).keys()].filter(
                    (minute) => minute >= startMinute
                );
            }
            return [...Array(60).keys()];
        },
    },
    methods: {
        ...mapActions(["editClockerHours"]),

        timeHelper(time) {
            return time === "" ? "00" : time;
        },

        sendRequest() {
            this.loading = true;
            this.editClockerHours({
                id: this.event.id,
                start_time: this.start_time,
                end_time: this.end_time === "" ? null : this.end_time,
                start_date: this.start_date,
                end_date: this.end_time === "" ? null : (this.end_date ?? this.nowDate),
                comment: this.comment,
            })
                .then(() => {
                    this.$fire({
                        type: "success",
                        title: "Success",
                        text: "Event successfully updated!",
                        showConfirmButton: true,
                    }).then(() => {
                        this.$emit("close");
                    });
                })
                .catch((error) => {
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        isValidTime(time) {
            return typeof time === "string" && time.includes(":");
        },

        disabledBeforeStartAndAfterEnd(date) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const startDate = new Date(this.shift.start_date + "T00:00:00");
            const endDate = this.shift.end_date
                ? new Date(this.shift.end_date + "T00:00:00")
                : now;
            return date < startDate || date > endDate;
        },
    },
};
</script>
<style>
.mx-datepicker {
    width: inherit;
}
</style>