<template>
<div class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 rounded p-3">

                <div  class="row text-center justify-center items-center mt-2">

                    <div v-if="this.isLoading && this.showTable" class="col-12 text-center">
                        <p class="font-italic font-weight-bold">Loading...</p>
                        <p class="font-italic font-weight-bold">
                            <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                            <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                            <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
                        </p>
                    </div>

                    <div v-if="!this.isLoading && this.showTable" class="col-12">
                        <div class="flex flex-row max-w-7xl bg-white shadow-lg rounded-lg p-3 my-3 mx-auto align-items-center justify-content-start">
                            <ticket-searchbar :key="resetKey" @toggle="filterUpdate"/>
                            <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700"  @click.prevent="clearFilters">
                                Clear <i class="bi bi-funnel-fill"></i>
                            </button>
                        </div>
                        <TailwindPagination :data="getFilterTicketsPagination" @pagination-change-page="getResults" :limit="limit" :keep-length="keepLength"/>
                        <itemstable @updateTable="keepQueryAlive" :key="componentKey" @toggle="keepQueryAlive"></itemstable>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
</template>
<script>
    import itemstable        from'@/views/components/Tickets/tables/tickets_per_queue';
    import TicketSearchbar from '@/views/components/Tickets/TicketSearchBar/TicketsSearchBar'
    import {mapGetters,mapState, mapActions } from 'vuex'
    import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
    export default {

        components: {
            TailwindPagination,
            itemstable,
            TicketSearchbar
        },
        
        data(){
            return{
                limit:          2,
                keepLength:     true,
                componentKey:   0,
                resetKey:       0,
                itemSelected:   null,
                isLoading:      true,
                showTable:      false,
                form:{
                    page: 1,
                    refresh :false
                }
            }
        },

        computed: {
            ...mapState([
            ]),
            ...mapGetters([
                'getFilterTicketsPagination'
                ])
        },

        created(){
            this.OpenTable();
        },

        methods: {

            ...mapActions([
                'filterTicketsRequest'
            ]),

            OpenTable: function () {
                this.isLoading = true

                this.filterTicketsRequest(this.form).then(() => {
                    this.isLoading = false;
                    this.showTable = true;
                })
            },

            filterUpdate: function (form) {
                this.form = form;
            },

            keepQueryAlive: function () {
                this.componentKey++;
            },

            forceRerender() {
                this.OpenTable();
            },

            clearFilters: function () {
                this.resetKey++;
            },

            getResults(page) {
                this.form.page = page;
                this.filterTicketsRequest(this.form);
            },

        },
        watch:{
            form:{
            handler:function(){
                this.filterTicketsRequest(this.form);
            },
            deep:true      
            }
        },

      mounted(){
        let title = 'Tickets Management';
        this.$emit('set-title',title);
      }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>