<template>
    <div>
        <div class="bg-white shadow-sm relative rounded-lg mb-10">
            <div class="flex items-center divide-x space-x-6 px-4">
                <div>
                    <i class="bi bi-search text-gray-400 py-3"></i>
                    <input type="search" placeholder="Search Employee ID..." @change="filterSearch()" v-model="search" class="pl-3 py-3" />
                </div>
                <div class="flex">
                    <i class="bi bi-briefcase text-gray-400 pl-2"></i>
                    <multiselect class="multiselect-custom text-lg" v-model="campaign" :options="getAllCampaigns" @input="filterSearch()">
                    </multiselect>
                </div>
                <div class="flex">
                    <i class="bi bi-geo-alt text-gray-400 pl-2"></i>
                    <multiselect class="multiselect-custom text-lg" v-model="site" :options="site_options" @input="filterSearch()">
                    </multiselect>
                </div>
                <div class="border-0">
                    <a class="inline-block bg-blue-50 hover:bg-blue-100 text-blue-700 px-6 py-1 rounded-full !no-underline cursor-pointer" @click="resetFilter()">Reset Filter</a>
                </div>
                <div class="flex-1"></div>
                <div class="border-0">
                    <div class="radiogroup flex text-center whitespace-nowrap border-2" :class="status_request=='approved' ? 'border-green-600 text-green-600':(status_request=='declined' ? 'border-red-600 text-red-600':'border-blue-600 text-blue-600')">
                        <input type="radio" v-model="status_request" value="pending" id="sr-pending" @change="filterSearch" /><label class="flex-1" :class="status_request=='pending' ? 'bg-blue-600':''" for="sr-pending"><i class="bi bi-clock-history"></i> Pending</label>
                        <input type="radio" v-model="status_request" value="approved" id="sr-approved" @change="filterSearch" /><label class="flex-1" :class="status_request=='approved' ? 'bg-green-600':''" for="sr-approved"><i class="bi bi-check2"></i> Approved</label>
                        <input type="radio" v-model="status_request" value="declined" id="sr-declined" @change="filterSearch" /><label class="flex-1" :class="status_request=='declined' ? 'bg-red-600':''" for="sr-declined"><i class="bi bi-x-lg"></i> Declined</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-3">
          <TailwindPagination
          :data="getMslPagination"
          @pagination-change-page="getResults"/>
        </div>
        <div class="bg-white shadow-sm relative rounded-lg overflow-hidden">
            <table class="text-left w-full">
                <thead class="bg-slate-700 text-slate-800 text-white">
                    <tr>
                        <th>ID</th>
                        <th>Classification</th>
                        <th>Periodicity</th>
                        <th>Start Date</th>
                        <th>Days Duration</th>
                        <th>Employee ID</th>
                        <th>Campaign</th>
                        <th class="text-center">File Delivered</th>
                        <th class="text-center">Approved Incidences</th>
                        <th>Created At</th>
                        <th>Created By</th>
                    </tr>
                </thead>
                <tbody class="animate-pulse divide-y" v-if="this.loading">
                    <tr v-for="i in 3" :key="i" class="hover:bg-slate-100">
                        <td v-for="i in 10" :key="i"><div class="h-2 bg-slate-200 rounded my-2"></div></td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && getMsl.length">
                    <tr class="hover:bg-slate-100 hover:cursor-pointer" v-for="msl in getMsl" :key="msl.id" @click="goToDetails(msl.id)">
                        <td>{{ msl.id }}</td>
                        <td>{{ msl.classification }}</td>
                        <td>{{ msl.periodicity }}</td>
                        <td>{{ msl.start_date }}</td>
                        <td>{{ msl.days_duration }}</td>
                        <td>{{ msl.employee }}</td>
                        <td>{{ msl.campaign }}</td>
                        <td class="text-center">
                            <i v-if="msl.file_delivered==0" class="bi text-xl bi-x-circle-fill text-red-500"></i>
                            <i v-else-if="msl.file_delivered==1" class="bi text-xl bi-check-circle-fill text-green-500"></i>
                        </td>
                        <td class="text-center">
                            <i v-if="msl.approved_incidences>0" class="bi text-xl bi-check-circle-fill text-green-500"></i>
                            <i v-else class="bi text-xl bi-x-circle-fill text-red-500"></i>
                        </td>
                        <td>{{ msl.created_at }}</td>
                        <td>{{ msl.created_by }}</td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && !getMsl.length">
                    <tr class="hover:bg-slate-100 hover:cursor-pointer">
                        <td colspan="10" class="text-center italic text-gray-400">Empty List</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    //import auth from "@/config/user"
    import Multiselect from 'vue-multiselect'
    import { mapGetters, mapState, mapActions } from 'vuex'
    import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
    export default {
        name: "",
        components: {
            Multiselect,
            TailwindPagination
        },
        data(){
            return {
                page: 1,
                loading: true,
                search: '',
                campaign: '',
                site: '',
                status_request: 'pending',
                site_options: [
                    'MTY',
                    'AGS',
                    'QRO',
                ],
            }
        },
        validations: {

        },
        computed: {
            ...mapState(['msl']),
            ...mapGetters([
                'getMsl',
                'getAllCampaigns',
                'getMslPagination',
            ]),
            filters() {
                return {
                    emp_id: this.search,
                    campaign: this.campaign,
                    site: this.site,
                    Status: this.status_request,
                    page: this.page,
                }
            }
        },
        created() {
            this.fetchCampaigns();
            this.fetchAllMsl({ Status: this.status_request, page: this.page}).then(() => {
                this.loading = false;
            });
        },
        methods: {
            ...mapActions([
                'fetchAllMsl',
                'fetchCampaigns'
            ]),
            getResults(page = 1) {
                this.loading = true;
                this.filters.page = page;
                this.fetchAllMsl(this.filters).then(() => {
                    this.loading = false;
                });
            },
            resetFilter:function() {
                this.search = '';
                this.campaign = '';
                this.site = '';
                this.filterSearch();
            },
            filterSearch:function() {
                this.loading = true;
                this.fetchAllMsl(this.filters).then(() => {
                    this.loading = false;
                });
            }
            ,goToDetails: function(data){
                this.$router.push({name:'msl.details', params: {id: data}});
            }
        },
        mounted() {
            let title = 'Medical Leaves Management';
            this.$emit('set-title',title);
        }
    };
</script>
<style scoped>
table th {
  padding: 15px 15px;
  text-transform: uppercase;
  font-weight: bold;
}
table td {
  padding: 10px 15px;
}
.radiogroup {
    margin: 10px 0;
    border-radius: 30px;
}
.radiogroup input[type="radio"] {
    display: none;
}
.radiogroup input[type="radio"] + label {
    display: inline-block;
    line-height: 2.3;
    padding: 0 25px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
}
.radiogroup input[type="radio"]:checked + label {
    color: #fff;
}
</style>
<style>
.multiselect-custom {
    min-height: auto;
}
.multiselect-custom .multiselect__tags {
    font-size: 100%;
    min-height: 100%;
    padding: 0 40px 0 20px;
    border: none;
}
.multiselect-custom .multiselect__tag {
    margin-right: 5px !important;
    margin-bottom: 0 !important;
}
.multiselect-custom .multiselect__tags .multiselect__placeholder {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}
.multiselect-custom .multiselect__content-wrapper {
    min-width: 200px !important;
    width: auto;
    border: none;
    overflow-x: hidden;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1) !important;
}
.multiselect-custom  .multiselect__select {
    width: auto;
    height: auto;
    padding: 0;
    right: 10%;
    top: 50%;
    margin-top: -13px;
}
.multiselect-custom  .multiselect__select:before {
    display: inline-block;
    font-family: 'bootstrap-icons' !important;
    content: '\F282';
    border: none;
}
.multiselect-custom .multiselect__single {
    margin-bottom: 0;
    vertical-align: middle;
}
.multiselect-custom .multiselect__input {
    font-size: 100%;
    line-height: inherit;
    min-height: 100%;
    margin: 0;
    padding: 0;
}
.multiselect-custom .multiselect__option.multiselect__option--highlight,
.multiselect-custom .multiselect__option.multiselect__option--highlight:after {
    color: #fff;
    background-color: #2563eb;
}
.multiselect-custom .multiselect__input, .multiselect-custom .multiselect__single {
    background: transparent;
}
</style>