<template>
  <div class="main py-2">
    <loading :active.sync="getFlagLoadingFeedback" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    
    <div class="container-fluid">

        <div class="w-full px-3">
            <button @click.prevent="$router.push({ path: '/feedbacks/create/' })" 
            class="uppercase inline-flex justify-center py-1 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div class="uppercase font-sans font-bold text-white text-center justify-center items-center m-1">New Feedback</div>
            </button>
        </div>

        <div class="w-full px-3">
            <component :key="ListKey" @updateAll="ForceRender" :is="'feedbackTable'" class="text-center justify-center items-center mt-2 w-full"></component>
        </div>

    </div>
  </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import feedbackTable    from "@/views/feedbacks/tables/all_feedbacks"
    import { required }     from 'vuelidate/lib/validators';

    export default {
        name: "",
        components: { Loading, feedbackTable },
        data(){
            return{
                fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isDisabled: true,
                isAcknowledge: false,
                ListKey: 0,

                feedbackLoaded: false,
                agentComment: '',
                commentsLoaded: '',
                audit_id: '',
            }
        },
        validations: {
            agentComment: { required},
        }, 
        computed:{
            ...mapState(['feedbacks']),
            ...mapGetters(['getFlagLoadingFeedback']) 
        },
        methods:{
            ...mapActions([]), 


            closeModal: function(){
            this.$refs['newFeedbackModal'].hide()
            this.ListKey++;
            },

            ForceRender: function(){
                this.ListKey++;
            },

        },
        mounted(){
          let title = 'Feedback Management';
          this.$emit('set-title',title);
        }
    };

</script>
<style>
    .margins{
        margin-top: 1em !important;
        margin-left: 16% !important; 
        margin-right: 16% !important;
    }
    .card-body {
        max-height: 250px !important;
    }
</style>