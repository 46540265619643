<template class="w-full h-full">
    <div class="m-4">
    <button @click="logout" class="uppercase text-xs bg-gray-600 hover:bg-gray-700 p-2 px-3 mb-3 rounded-full text-white">Log out</button>
        <div class="m-4 d-flex justify-content-center text-left">
            <div class="flex-col w-1/2 mr-4 px-4">
                <h3>Welcome to Our Exit Survey</h3>
                <p>We value your feedback! Please take a moment to share your experience working with us. Your insights will help us make improvements and create a better environment for everyone. Thank you for your time!</p>
            </div>
            <form-viewer v-if="canAnswer" @afterAnswer="logout" @alreadyAnswered="blockForm" :formSelected="4"/>
            <div v-else>
                <span><svg class="w-10 h-10 ml-2 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FormViewer from '../formbuilder/visualization/formViewer.vue';
export default {
    components: {
        FormViewer
    },
    data(){
        return {
            AnswersData:    [],
            canAnswer:      true
        }
    },
    computed: {
        ...mapState(["session"]),
        ...mapGetters([])
    },
    methods: {
        ...mapActions(["logoutUser"]),

        logout: async function(){
            try {
                this.logoutUser().then(() => {
                    setTimeout(window.location.reload.bind(window.location), 500);
                });
            } catch (error) {
                this.$confirm({
                    type: 'error',
                    title: 'There was an error loging you out',
                    showConfirmButton: true
                });
            }
        },

        blockForm: function(){
            this.canAnswer = false;
            this.$fire({
                type: 'warning',
                title: 'You already solved this!',
                text: "Thanks for your participation, we will log you out",
                showConfirmButton: false,
                timer: 1500
            }).then(()=>{
                this.logout();               
            });
        },
    },
    mounted() {
        let title = 'Predismissal Survey'
        this.$emit('set-title',title);
    }
}
</script>
<style scoped>

</style>