<template>
  <div class="text-center">
    <p class="mx-auto text-center text-sm pl-4 font-semibold text-gray-500">
      Please select the incidence or change type
    </p>
    <div class="flex justify-center mb-10">
      <div class="flex space-x-5">
        <button class="bg-c-primary hover:opacity-80 text-white p-6 rounded-xl transition-all"
        @click.prevent="$router.push({ 
          name: 'incidences.create.termination', 
          params: { recid: rec_id, id: employee } 
          })"
        >
          <span class="text-xl uppercase font-semibold">
            <i class="block text-6xl bi bi-person-slash mb-3"></i>
            Termination
          </span>
        </button>
        <!--
        <button class="bg-c-secondary hover:opacity-80 text-white p-6 rounded-xl transition-all"
        :disabled="msl_requests > 0"
        @click.prevent="$router.push({ 
          name: 'inventory.employee', 
          params: { id: employee } 
          })"
        >
          <span class="text-xl uppercase font-semibold">
            <i class="block text-6xl bi bi-box2 mb-3"></i>
            Inventory
          </span>
        </button>
        
        <button class="bg-c-secondary hover:opacity-80 text-white p-6 rounded-xl transition-all"
        :disabled="msl_requests > 0"
        @click.prevent="$router.push({ 
          name: 'incidences.create.job_abandonment', 
          params: { recid: rec_id, id: employee } 
          })"
        >
          <span class="text-xl uppercase font-semibold">
            <i class="block text-6xl bi bi-person-exclamation mb-3"></i>
            Job Abandonment
          </span>
        </button>
        -->
        <button class="bg-c-blue hover:opacity-80 text-white p-6 rounded-xl transition-all"
        @click.prevent="$router.push({ 
          name: 'msl.create', 
          params: { recid: rec_id, id: employee } 
          })"
        >
          <span class="text-xl uppercase font-semibold">
            <i class="block text-6xl bi bi-heart-pulse mb-3"></i>
            Medical Leave
          </span>
        </button>
        <button class="bg-c-alt hover:opacity-80 text-white p-6 rounded-xl transition-all"
        :disabled="msl_requests > 0"
        @click.prevent="$router.push({name:'schedules.edit', params: { empID: employee } })"
        >
          <span class="text-xl uppercase font-semibold">
            <i class="block text-6xl bi bi-calendar-week mb-3"></i>
            Schedule Change
          </span>
        </button>
      </div>
    </div>
    <p class="mx-auto text-center text-sm pl-4 font-semibold text-gray-500">
      Buttons won't be available if there is open requests
    </p>
    <h2 class="text-3xl font-semibold mb-6">History</h2>
    <history-table :employee="employee" :key="history_key" @request_counter="countRequest"/>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import HistoryTable from './tables/IncidencesHistoryTable.vue'
  export default {
    data() {
      return {
        history_key: 0,
        employee: null,
        rec_id: null,
        loading: false,
        accessApproved: false,
        request_type: null,
        msl_requests: 0,
      }
    },
    components: {
      HistoryTable
    },
    computed:{
        ...mapGetters([
          'getTickets',
        ]),
    },
    methods:{
        ...mapActions([
          'fetchEmployeeTickets',
        ]),

        verifyValidRequest(){
          document.getElementById("pro-loading").style.display = "flex";
          this.fetchEmployeeTickets(this.employee).then(() => {
              document.getElementById("pro-loading").style.display = "none";
              this.accessApproved = false;
              if(this.getTickets?.length === 0) this.accessApproved = true;
          });
        },

        countRequest(count){
          this.msl_requests = count;
        }
      
    },
    mounted(){
        this.$emit('set-title','Employee Change');
        this.verifyValidRequest();
    },
    created(){
        this.employee = this.$route.params.id;
        this.rec_id = this.$route.params.recid;
    },
  }
</script>