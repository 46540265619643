<template>
    <div class="bg-white shadow-sm relative rounded-lg">
      <table class="text-left table-fixed w-full overflow-x-hidden">
        <thead class="bg-slate-700 text-slate-800 text-white">
          <tr>
            <th>Classification</th>
            <th>Periodicity</th>
            <th>Start Date</th>
            <th>Days</th>
            <th>File</th>
            <th>Status</th>
            <th>Requested At</th>
            <th>Requested By</th>
            <th>Last Update</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody v-if="isNotEmpty" >
          <tr v-for="item in getMsl" :key="item.Id">
            <td>{{ item.classification }}</td>
            <td>{{ item.periodicity }}</td>
            <td class="whitespace-nowrap">{{ item.start_date }}</td>
            <td>{{ item.days_duration }}</td>
            <td>
              <a :href="fileLink(item.file)" class="flex flex-row" target="_blank">
                OPEN <i class="bi bi-box-arrow-up-right ml-1"></i>
              </a>
            </td>
            <td>{{ item.status }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.created_by }}</td>
            <td>{{ item.updated_at }}</td>
            <td>{{ item.updated_by }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="10" class="w-full p-4 flex text-center justify-content-center">
              No results found 🍃 
          </td>
        </tbody>
      </table>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props:{
        employee: {
            type: String,
            required: true
        }
    },
    created () {
        this.getMSLRequestById(this.employee).then((res) => {
            this.$emit('request_counter', res.count);
        });
    },
    computed: {
        ...mapGetters([
          'getMsl',
        ]),

        isNotEmpty () {
          return this.getMsl?.length > 0
        },
    },
    methods: {
        ...mapActions([
            'getMSLRequestById'
        ]),

        fileLink:function(url){
          if(url?.startsWith("http")) return url;
            return 'https://centris.website-us-southeast-1.linodeobjects.com/linode_requests/' + url;
        },
    }
}
</script>
<style scoped>
  table th {
    padding: 15px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: bold;
  }
  table td {
    padding: 10px 25px;
    text-transform: uppercase;
    font-size: 80%;
  }
  </style>