<template>
    <div class="editInfoContainer">
        <div class="d-block text-center">
            <h3 class="font-sans text-4xl tracking-wide bg-gray-50 font-bold text-gray-700 mt-2">Add a New Survey</h3>
        </div>
        
        <div>
            <div class="md:grid md:grid-cols-6">
                <div class=" mt-2 md:mt-0 md:col-span-6">

                    <form action="#">
                        <h4 class="font-medium leading-6 text-gray-700">Information</h4>
                        <div class="bg-gray-50 px-4 py-4 space-y-6 sm:p-6">
                            <!-- Title -->
                            <div>
                                <label for="survey_description" class="text-gray-600 font-semibold text-lg">
                                    Title
                                </label>

                                <div class="items-center mt-1 flex">
                                    <input id="titleId" v-model="survey_info.survey_title" type="text" name="survey_title"
                                    class="ml-2 pl-2 focus:ring-indigo-600 h-10 focus:border-indigo-400 flex-1 block w-full rounded-r-md sm:text-sm border-gray-500 rounded-md shadow-md"
                                    placeholder="Write here...">
                                </div>

                                <p class="font-semibold mt-2 text-sm text-gray-500">Please provide details about this survey.</p>
                            </div>
                            <!-- Details -->
                            <div>
                                <label for="survey_description" class="text-gray-600 font-semibold text-lg">
                                    Details
                                </label>

                                <div class="items-center mt-1 flex">
                                    <textarea id="descriptionId" v-model="survey_info.survey_description" name="survey_description" rows="4" 
                                    class="ml-2 pl-2 focus:ring-indigo-600 h-10 focus:border-indigo-400 flex-1 block w-full rounded-r-md sm:text-sm border-gray-500 rounded-md shadow-md" placeholder="Write here..." style="resize: none;"></textarea>
                                </div>

                                <p class="font-semibold mt-2 text-sm text-gray-500">Please provide details about this survey.</p>
                            </div>
                            <!-- Interval Time -->
                            <div>
                                <label for="survey_datefrom" class="text-gray-600 font-semibold text-lg">
                                    Interval time
                                </label>

                                <div class="items-center mt-1 flex">
                                    <select v-model="survey_info.survey_interval" id="intervalId"
                                    class="ml-2 bg-white text-gray-700 hover:text-gray-700 active:text-gray-700 h-7 mb-1 rounded-md shadow-md">
                                        <optgroup label="Select an answer...">
                                            <option value="1">Daily</option>
                                            <option value="7">Weekly</option>
                                            <option value="14">Twice a Month</option>
                                            <option value="30">Monthly</option>
                                            <option value="90">Every Three Months</option>
                                            <option value="120">Every 6 Months</option>
                                            <option value="365">Every Year</option>
                                        </optgroup>
                                    </select>
                                </div>

                                <p class="font-semibold mt-2 text-sm text-gray-500">Specify the interval time this survey needs to be taken</p>
                            </div>
                            <!-- Active Period -->
                            <div>
                                <label for="survey_datefrom" class="text-gray-600 font-semibold text-lg">
                                    Active Period
                                </label>

                                <div class="items-center mt-1 flex">
                                    <p class="font-semibold size-sm text-gray-500 px-2 py-1 flex m-1">From</p>
                                    <input type="date" id="dateFromId" v-model="survey_info.survey_dateFrom" name="survey_datefrom"
                                    class="ml-2 p-1 focus:ring-indigo-600 h-10 focus:border-indigo-400 shadow-md flex-1 block w-full rounded-lg rounded-r-md sm:text-sm border-gray-500">
                                    <p class="font-semibold size-sm text-gray-500 px-2 py-1 flex m-1">To</p>
                                    <input type="date" id="dateToId" v-model="survey_info.survey_dateTo" name="survey_dateto"
                                    class="ml-2 p-1 focus:ring-indigo-600 h-10 focus:border-indigo-400 shadow-md flex-1 block w-full rounded-lg rounded-r-md sm:text-sm border-gray-500">
                                </div>

                                <label class="inline-flex items-center pl-2 pt-2">
                                    <input v-model="survey_info.survey_dateto_checkbox" type="checkbox"
                                    class="focus:ring-blue-400 h-4 w-4 rounded">
                                    <span class="ml-2 font-semibold text-md text-gray-500">This survey lasts indefinitely</span>
                                </label>

                                <p class="font-semibold mt-2 text-sm text-gray-500">Please provide the active period time of this survey.</p>
                            </div>
                            <!-- Apply To -->
                            <div>
                                <label for="survey_datefrom" class="text-gray-600 font-semibold text-lg">
                                    Apply to
                                </label>

                                <label class="items-center mt-1 flex">
                                    <input v-model="survey_info.survey_applyto_checkbox" type="checkbox" id="allCampaignsId"
                                    class="focus:ring-blue-400 h-4 w-4 rounded">
                                    <span class="ml-2 font-semibold text-lg text-gray-500">Apply this survey to all campaigns, sites and titles</span>
                                </label>

                                <div v-if="!this.survey_info.survey_applyto_checkbox" class="items-center mt-1 flex">
                                    <div id="selectorId" class="w-full items-center mt-1 flex align-items-center justify-content-start rounded-md shadow-md">
                                        <multiselect v-model="survey_info.survey_applyto" :custom-label="customFormat" :options="this.getCampaignList" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select the campaigns to apply" label="Campaign" track-by="RecID"></multiselect>
                                    </div>
                                </div>

                                <p class="font-semibold mt-2 text-sm text-gray-500">Please select the public will be able to see the content of this survey.</p>
                            </div>

                        </div>
                    </form>

                    <form action="#">
                        <h4 class="font-medium leading-6 text-gray-700">Questions</h4>
                        <div class="px-4 py-2 bg-gray-50 text-right md:px-6">
                            <div v-for="(input, index) in survey_questions" :key="`questionInput-${index}`" class="my-3 align-items-center justify-content-start">

                                <div class="flex flex-row align-items-center justify-content-start">
                                    <label class="text-gray-600 font-semibold text-md mr-2">{{index+1}}. Question:</label>
                                    <input v-model="input.question" :id="'question'+index" placeholder="Type your question..." class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700  focus:placeholder-gray-700" type="text">

                                    <select required :id="'type'+index" v-model="input.question_type" class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700">
                                        <optgroup label="Question type">                
                                            <option hidden value="">Select a type..</option>
                                            <option value="text">Text</option>
                                            <option value="dropdown">Dropdown</option>
                                            <option value="date">Date</option>
                                            <option value="time">Time</option>
                                            <option value="multiple">Multiple Answers</option>
                                        </optgroup>
                                    </select>

                                    <svg @click="addQuestionField(input, survey_questions)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path fill="#5E57E5" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                    </svg>

                                    <svg v-show="survey_questions.length > 1" @click="removeQuestionField(index, survey_questions)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path fill="#C54673" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/>
                                    </svg>
                                </div>

                                <div class="ml-5 flex flex-row align-items-center justify-content-start">
                                    <div v-if="input.question_type == 'dropdown' || input.question_type == 'multiple'">
                                        <div v-for="(question_answers, inndex) in input.question_answers" :key="`answerInput-${inndex}`" class="flex flex-row my-3 align-items-center justify-content-start">

                                            <label class="text-gray-600 font-semibold text-md mr-2">Answer {{inndex+1}}:</label>
                                            <input :id="index+'answer'+inndex" v-model="question_answers.answer" placeholder="Type your answer..." class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700  focus:placeholder-gray-700" type="text">

                                            <svg @click="addAnswerField(question_answers, index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                <path fill="#5E57E5" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <svg v-show="survey_questions[index].question_answers.length > 1" @click="removeAnswerField(inndex, index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#C54673" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/>
                                            </svg>
                                        
                                        </div>
                                    </div>
                                </div>

                                <!-- Respuestas -->
                                
                                <!-- Fin Respuestas -->

                            </div>
                            <div class="row">
                                <p class="font-semibold mt-2 text-sm text-gray-500">Please, list the questions will be part of your your survey.</p>
                            </div>
                        </div>
                    </form>

                    <div class="px-4 py-2 text-right md:px-6">
                        <button v-if="this.sending" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600" style="cursor:default">
                        Sending...
                        </button>

                        <button v-else @click="sendSurvey" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Save
                        </button>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Multiselect                          from 'vue-multiselect'
    export default {
        components: { 
            Multiselect
        },
        data(){
            return{
                survey_info : {},
                survey_questions: [{ 
                    question: "",
                    question_type : "",
                    question_answers: [{ answer: "" }]
                }],
                questions: 1,
                sending: false,

            }
        },
        computed: {
            ...mapState(["surveys"]),
            ...mapGetters([ 'getCampaignList' ]),
        },
        methods:{
            ...mapActions(['createSurvey',
            'fetchCampaignsGroups']),

            customFormat({Campaign, Location, Title}){
                return `${Campaign} (${Location}) - [${Title}]`;
            },

            addQuestionField(value, survey_questions) {
                survey_questions.push({ question: "", question_type: "", question_answers: [{ answer: "" }] });
            },

            removeQuestionField(index, survey_questions) {
                survey_questions.splice(index, 1);
            },

            addAnswerField(value, index) {
                this.survey_questions[index].question_answers.push({ answer: "" });
            },

            removeAnswerField(inndex, index) {
                this.survey_questions[index].question_answers.splice(inndex, 1);
            },

            sendSurvey () {
                event.preventDefault();

                // Starts validation from values inside QUESTIONS
                let key = 0;
                let counter = [];
                [].forEach.call(this.survey_questions, element => {
                    if(element.question === "" ){
                            document.getElementById('question'+key).classList.add('border-2', 'border-red-500')
                            counter.push({question : element.question})
                    }else{
                        document.getElementById('question'+key).classList.remove('border-2', 'border-red-500')
                    }
                    
                    if(element.question_type === ""){
                            document.getElementById('type'+key).classList.add('border-2', 'border-red-500')
                            counter.push({type : element.question_type})
                    }else{
                        document.getElementById('type'+key).classList.remove('border-2', 'border-red-500')
                    }

                    if(element.question_type === "dropdown" || element.question_type === "multiple"){
                        let answerKey = 0;
                        [].forEach.call(this.survey_questions[key].question_answers, element => {
                            if(element.answer === undefined || element.answer === null || element.answer === ""){
                                    document.getElementById(key+'answer'+answerKey).classList.add('border-2', 'border-red-500')
                                    counter.push({answer : element.answer})
                            }else{
                                document.getElementById(key+'answer'+answerKey).classList.remove('border-2', 'border-red-500')
                            }
                            answerKey++;
                        });
                    }

                    key++;
                });
                // Ends validation from values inside QUESTIONS

                // Starts validation from values inside INFORMATION
                if(this.survey_info.survey_title === undefined || this.survey_info.survey_title === null || this.survey_info.survey_title === "" ){
                    document.getElementById('titleId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_title : "empty"})
                }else{
                    document.getElementById('titleId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_description === undefined || this.survey_info.survey_description === null || this.survey_info.survey_description === "" ){
                    document.getElementById('descriptionId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_description : "empty"})
                }else{
                    document.getElementById('descriptionId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_interval === undefined || this.survey_info.survey_interval === null || this.survey_info.survey_interval === "" ){
                    document.getElementById('intervalId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_interval : "empty"})
                }else{
                    document.getElementById('intervalId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_dateFrom === undefined || this.survey_info.survey_dateFrom === null || this.survey_info.survey_dateFrom === "" ){
                    document.getElementById('dateFromId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_dateFrom : "empty"})
                }else{
                    document.getElementById('dateFromId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_dateto_checkbox){ this.survey_info.survey_dateTo = null; }
                if(!this.survey_info.survey_dateto_checkbox && (this.survey_info.survey_dateTo === undefined || this.survey_info.survey_dateTo === null || this.survey_info.survey_dateTo === "")){
                    document.getElementById('dateToId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_dateTo : "empty"})
                }else{
                    document.getElementById('dateToId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_applyto_checkbox){ this.survey_info.survey_applyto = this.getCampaignList; }
                if(!this.survey_info.survey_applyto_checkbox && (this.survey_info.survey_applyto === undefined || this.survey_info.survey_applyto === null || this.survey_info.survey_applyto === "" || this.survey_info.survey_applyto === [])){
                    document.getElementById('selectorId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_applyto : "empty"})
                }else{
                    if(!this.survey_info.survey_applyto_checkbox){
                        document.getElementById('selectorId').classList.remove('border-2', 'border-red-500')
                    }
                }
                // Ends validation from values inside INFORMATION

                if (counter.length > 0) {
                    this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true,
                        confirmButtonColor: '#5E57E5',
                        allowOutsideClick: false,
                        background: '#FFFFFFD9',
                    })
                }else{
                    this.sending = true;
                    let objRequest = {
                        survey_info:        this.survey_info,
                        survey_questions:   this.survey_questions,
                        survey_public:      this.survey_info.survey_applyto
                    }
                    this.createSurvey(objRequest).then(() =>{
                        this.$fire({
                            type: 'success',
                            title: 'The survey has been saved successfully',
                            showConfirmButton: false,
                            timer: 1500
                            }).then(()=>{
                                this.$emit('closeAddModal', true);
                            })
                    });
                }
            }
           
        },
        created() {
            this.fetchCampaignsGroups();
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
</style>