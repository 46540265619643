import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    payrollAdjustments: [],
    payrollEvents:      [],
    agentInfo: [],
    detailedDay: [],
    table_loading: true,
    shiftValidation: true,
    titlesAtRequest: [],
    specialPayrollRequests: [],
    specialList: [],
    specialRequestStatus: false,
    SPRReasons: [],
    payrollSummary: [],
    ifApprovedDay: false,
    approvedDays: [],
    countApprovedDays: [],
    approvedDow: [],
    loadingPayroll: false,
    dayData: null,
    searchable: false,
    compareTables: []
};
const getters = {
    // Account Manager Hours Review
    getPayrollRequests:     (state) => state.payrollAdjustments,
    getPayrollEvents:       (state) => state.payrollEvents,
    getPayrollAgentInfo:    (state) => state.agentInfo,
    getPayrollDetailedDay:  (state) => state.detailedDay,
    getShiftValidation:     (state) => state.shiftValidation,
    getTitlesAtRequests:    (state) => state.titlesAtRequest,
    getSpecialRequests:     (state) => state.specialPayrollRequests,
    getSpecialList:         (state) => state.specialList,
    getSPRReasons:          (state) => state.SPRReasons,
    getPayrollSummary:      (state) => state.payrollSummary,
    getIfApprovedDay:       (state) => state.ifApprovedDay,
    getApprovedDays:        (state) => state.approvedDays,
    getCountApprovedDays:   (state) => state.countApprovedDays,
    getApprovedDow:         (state) => state.approvedDow,
    getLoadingPayroll:      (state) => state.loadingPayroll,
    getDayData:             (state) => state.dayData,
    getSearchable:          (state) => state.searchable,
    getCompareTables:       (state) => state.compareTables,
};
const actions = {
    
    async getPayrollEvents  ( {commit}){
        const endpoint = API_URL + 'api/v2/payroll/filters/getEvents/';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollEvents);   
            return res;
        });
        return response;
    },

    async getPayrollRequestsByEmpID  ( {commit}, data){
        const endpoint = API_URL + `api/v2/payroll/filters/getRequestsByEmpID/${data.empId}/${data.nowParam}`;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);   
            return res;
        });
        return response;
    },
    async getPayrollRequestsByDate ( {commit}, data){
        const endpoint = API_URL + `api/v2/payroll/filters/${data.empId}/getRequestsByDate/${data.date}`;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);   
            return res;
        });
        return response;
    },

    async cancelPayrollRequestsByDate({commit}, data){
        const endpoint = API_URL + `api/v2/payroll/cancel-requests`;
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setLoadingTable',true);        
            return res;
        });
        return response;
    },

    async getPayrollRequestsByReason  ( {commit}, reason){
        const endpoint = API_URL + 'api/v2/payroll/filters/getRequestsByReason/'+ reason;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },

    async getPayrollRequestsByStatus  ( {commit}, status){
        const endpoint = API_URL + 'api/v2/payroll/filters/getRequestsByStatus/'+ status;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },

    async getPayrollRequestsByCampaign  ( {commit}, campaign){
        const endpoint = API_URL + 'api/v2/payroll/filters/getRequestsByCampaign/'+ campaign;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },


    async getFetchPayrollRequests({commit}){
        const endpoint = API_URL + 'api/v2/payroll/getPayrollRequests';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },

    async approvePayrollRequest({commit}, idRequest){
        const endpoint = API_URL + 'api/v2/payroll/requestID/'+ idRequest +'/approveRequest';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },

    async denyPayrollRequest({commit}, dataset){
        const endpoint = API_URL + 'api/v2/payroll/requestID/'+ dataset.idRequest + '/denyRequest';
        let response = await axios.post( endpoint, dataset).then( (res) =>{
            commit('setLoadingTable',true);        
            return res;
        });
        return response;
    },

    async rejectPayrollRequest({commit}, dataset){
        const endpoint = API_URL + 'api/v2/payroll/requestID/'+ dataset.idRequest + '/rejectRequest';
        let response = await axios.post( endpoint, dataset).then( (res) =>{
            commit('setLoadingTable',true);        
            return res;
        });
        return response;
    },
    async undoPayrollRequest({commit}, idRequest){
        const endpoint = API_URL + 'api/v2/payroll/requestID/'+ idRequest + '/undoRequest';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setPayrollRequests', res.data.payrollRequests);    
            return res;
        });
        return response;
    },
    async fetchAgentPayrollEventsPerEmpID( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/getAgentPayrollData';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setPayrollAgentInfo', res.data.data);     
            return res.status;
        });
    },
    async savePayrollAdjustment( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/saveAdjustment';
        let response = await axios.post( endpoint, data ).then( (res) =>{
            commit('setLoadingTable', false);     
            return res;
        });
        return response;
    },
    async savePayrollCorrection( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/saveCorrection';
        let response = await axios.post( endpoint, data ).then( (res) =>{
            commit('setLoadingTable', false);     
            return res;
        });
        return response;
    },
    async shiftValidation( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/shiftValidation';
        let response = await axios.post( endpoint, data ).then( (res) =>{
            commit('setShiftValidation', false);     
            return res;
        });
        return response;
    },
    async filterPayrollRequest({commit},data){
        let formData =  Object.keys(data).length !== 0 && data.constructor === Object ? filterObject(data, item => item !== 0 && item !== null): data;
        let response= await axios.post('api/v2/payroll/filters/getFilteredRequest',formData).then((res)  =>{
            commit('setPayrollRequests',res.data.payrollRequests);
            return res;
        });
    return response;
    },

    async fetchTitlesAtRequests({commit}){
        const endpoint = API_URL + 'api/v2/payroll/getTitlesAtRequests';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setTitlesAtRequests', res.data.titles);    
            return res;
        });
        return response;
    },


    // This section will be for SPECIAL PAYROLL requests
    async saveSpecialRequest( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/special/saveSpecialRequest';
        let response = await axios.post( endpoint, data ).then( (res) =>{
            commit('setLoadingTable', false);     
            return res;
        });
        return response;
    },

    async fetchAllPayrollPerEmpID( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/special/getAgentAllPayroll';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setPayrollAgentInfo', res.data.data); 
            commit('setPayrollSummary', res.data.summary);    
            return res.status;
        });
    },

    async fetchSpecialPayrollRequestsPerEmpID( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/special/getSpecialRequests';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setSpecialPayrollRequests', res.data.data);     
            return res.status;
        });
    },

    async filterSpecialReq({commit},data){
        let formData =  Object.keys(data).length !== 0 && data.constructor === Object ? filterObject(data, item => item !== 0 && item !== null): data;
        let response= await axios.post('api/v2/payroll/special/filterSpecialReq',formData).then((res)  =>{
            commit('setSpecialList',res.data.msg);
            return res;
        });
    return response;
    },

    async changeSpecialRequestStatus({commit},data){
        const endpoint = API_URL + 'api/v2/payroll/special/changeSpecialStatus';
        let response = await axios.post( endpoint, data).then( (res) =>{    
            commit('setSpecialRequestStatus', true);     
            return res;
        });
        return response;
    },

    async fetchPRReasonsCatalog({commit}){
        const endpoint = API_URL + 'api/v2/payroll/special/specialRequestReasons';
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setSPRReasons', res.data);
            return res;
        });
        return response;
    },

    async fetchIfApprovedDay( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/getIfApprovedDay';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setIfApprovedDay', res.data.data);     
            return res.status;
        });
    },

    async filterApprovedDays({commit},data){
        commit('setLoadingPayroll', true);
        let formData =  Object.keys(data).length !== 0 && data.constructor === Object ? filterObject(data, item => item !== 0 && item !== null): data;
        let response= await axios.post('api/v2/payroll/utilities/watchmen/filterApprovedDays',formData).then((res)  =>{
            commit('setApprovedDays',res.data.data);
            commit('setCountApprovedDays',res.data.count);
            commit('setApprovedDow',res.data.dates);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    return response;
    },

    async fetchApprovedPayrollDays( {commit}, data ){
        const endpoint = API_URL + 'api/v2/payroll/utilities/watchmen/filterApprovedDays';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);   
            return res.status;
        });
    },

    async updateCloseDay({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateCloseDay';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateOpenDay({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateOpenDay';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateCloseThisWeek({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateCloseThisWeek';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateOpenThisWeek({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateOpenThisWeek';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateCloseDate({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateCloseDate';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateOpenDate({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateOpenDate';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateCloseWeek({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateCloseWeek';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async updateOpenWeek({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/approval/updateOpenWeek';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setApprovedDays', res.data.data);
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async fetchDetailedDay({commit}, form){
        const endpoint = API_URL + 'api/v2/payroll/detailedDay/' + form['emp_id'] +'/' + form['date'];
        const response = await axios.get(endpoint).then((res) =>{
            commit('setPayrollDetailed', res.data.detailedDay);
            commit('setPayrollAgentInfo', res.data.approvalId);
            let obj = {
                dayStatus: res.data.dayStatus,
                searchable: res.data.searchable,
                approvalId: res.data.approvalId.Approval_id,
                approvalStatus: res.data.approvalId.SupervisorStatus,
                approvalEmployee: res.data.approvalId.AgentStatus,
            }
            commit('setDayData', obj);
        }); 
        return response
    },

    async requestHoursReview({commit}, data){
        commit('setLoadingPayroll', true);
        const endpoint = API_URL + 'api/v2/payroll/detailedDay/Requests';
        await axios.post( endpoint, data ).then( (res) =>{
            commit('setLoadingPayroll', false);
            return res.status;
        });
    },

    async detailedRequestByApproval({commit}, data){
        const endpoint = API_URL + 'api/v2/payroll/request/detailedRequestByApproval';
        let response = await axios.post(endpoint, data).then((res)=>{
            commit('setCompareTables', res.data);    
            return res;
        });
        return response;
    },

    async completeRequestByApproval({commit}, data){
        const endpoint = API_URL + 'api/v2/payroll/request/complete';
        let response = await axios.post(endpoint, data).then((res)=>{
            commit('setCompareTables', res.data);    
            return res;
        });
        return response;
    },
};
const mutations = {
     setPayrollRequests:            (state, paramStatus)            => (state.payrollAdjustments = paramStatus),
     setPayrollEvents:              (state, paramStatus)            => (state.payrollEvents = paramStatus),
     setPayrollDetailed:            (state, paramStatus)            => (state.detailedDay = paramStatus),
     setPayrollAgentInfo:           (state, paramStatus)            => (state.agentInfo = paramStatus),
     setLoadingTable:               (state, newFlag)                => (state.table_loading = newFlag),
     setShiftValidation:            (state, paramStatus)             => (state.shiftValidation = paramStatus),
     setTitlesAtRequests:           (state, titlesAtRequest)        => (state.titlesAtRequest = titlesAtRequest),
     setSpecialPayrollRequests:     (state, specialPayrollRequests) => (state.specialPayrollRequests = specialPayrollRequests),
     setSpecialList:                (state, specialList)            => (state.specialList = specialList),
     setSpecialRequestStatus:       (state, specialRequestStatus)   => (state.specialRequestStatus = specialRequestStatus),
     setSPRReasons:                 (state, SPRReasons)             => (state.SPRReasons = SPRReasons),
     setPayrollSummary:             (state, payrollSummary)         => (state.payrollSummary = payrollSummary),
     setLoadingPayroll:             (state, loadingPayroll)         => (state.loadingPayroll = loadingPayroll),
     setIfApprovedDay:              (state, ifApprovedDay)          => (state.ifApprovedDay = ifApprovedDay),
     setApprovedDays:               (state, approvedDays)           => (state.approvedDays = approvedDays),
     setCountApprovedDays:          (state, countApprovedDays)      => (state.countApprovedDays = countApprovedDays),
     setApprovedDow:                (state, approvedDow)            => (state.approvedDow = approvedDow),
     setDayData:                    (state, dayData)                => (state.dayData = dayData),
     setSearchable:                 (state, searchable)             => (state.searchable = searchable),
     setCompareTables:              (state, compareTables)          => (state.compareTables = compareTables)
};

export default {
    state,
    getters,
    actions,
    mutations
};



let filterObject = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key])).reduce( (res, key) => (res[key] = obj[key], res), {} );