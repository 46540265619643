<template>
    <div class="flex flex-col space-y-2 mx-4 my-2">
        <div class="">
            <h4 class="font-sans font-semibold">Are you sure you want to mark this item as lost?</h4>
        </div>

        <div class="flex flex-col">
            <div class="mx-auto">
                <div class="text-left"><span class="font-semibold">Serial Number:</span> {{itemData.Serial_Number}}</div>
                <div class="text-left"><span class="font-semibold">Label:</span> {{itemData.Label}}</div>
                <div class="text-left"><span class="font-semibold">Last Benefactor:</span> {{itemData.Benefactor}}</div>
            </div>
        </div>

        <div class="text-left">
            <label for="text">Please write the word <span class="italic font-sans">"ACCEPT"</span> to confirm this action:</label>
            <div class="inline relative">
                <input name="text" id="text" v-model="text" placeholder="Write Here..." autocomplete="disable" maxlength="6"
                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 h-7 my-1 mx-auto"
                :class="{ 'border-red-400' : !isTheSame, 'border-green-400' : isTheSame }"
                />    
            </div>
        </div>

        <button @click="markAsLost"
        :class="{ 'bg-gray-500' : !isTheSame, 'bg-red-700' : isTheSame }"
        :disabled="!isTheSame || loading"
        class="px-3 py-2 rounded-sm shadow-faded text-white mx-auto">
            <span v-if="loading">
                <span class="uppercase text-xs font-semibold">
                    Mark Item as lost
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                </svg>
            </span>
            <span v-else class="uppercase text-xs font-semibold">
                Mark Item as lost
            </span>
        </button>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            loading: false,
            text: ''
        }
    },
    props:{
        itemData: {
            required: true,
            type: Object
        }
    },
    computed:{
        isTheSame(){
            return this.text?.toLowerCase() === 'accept'
        }
    },
    methods: {
        ...mapActions(['markItemAsLost']),
        markAsLost: function(){
            let obj = {
                id: this.itemData?.Catalog_ID,
                status: 'LOST'
            }
            this.loading = true;
            this.markItemAsLost(obj).then(() => {
                this.$fire({
                    type: 'success',
                    title: "Item marked as lost",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    this.$router.push({name: 'inventory.search'});
                })
            })
        }
    }
}
</script>
<style scoped>
#text { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>