<template>
<div class="">
    <div class="flex flex-col px-2 shadow-sm bg-white">
        <div class="border-2  shadow-sm rounded-md flex p-1 px-3 mb-2">
            <button @click="switchVisibility()" class="flex w-full">
                <h6 class="my-auto">Old Payroll Events</h6>
                <svg v-if="showTable" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                </svg>
            </button>
        </div>

        <div v-if="showTable" class="flex flex-col mx-auto px-3 mb-2">
            <div class="-my-2 overflow-y-auto">
                <div class="py-2 align-middle inline-block min-w-full">
                    <div class="shadow-sm bg-white border-b border-gray-200 sm:rounded-md">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Event</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Standard</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Graveyard</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Total</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Std Bill</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Std Pay</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Grav Bill</th>
                                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Grav Pay</th>
                            </tr>
                            </thead>
                            <tbody v-if="this.old_payroll.length">
                                <tr :id="'row'+index" v-for="(currentRow, index) in old_payroll" :key="index">
                                    <td class="px-3 py-2 text-xs uppercase">{{currentRow.Event}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.Daytime)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.Graveyard)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.Total_hrs)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.std_bill)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.std_pay)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.grav_bill)}}</td>
                                    <td class="px-3 py-2 text-xs whitespace-nowrap">{{secToTime(currentRow.grav_pay)}}</td>
                                </tr>
                            </tbody> 
                            <tbody v-else>
                            <tr>
                                <td colspan="7" class="px-3 py-2 text-xs whitespace-nowrap">No records found.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>
<script>
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
          showTable: false
      }
    },
    components: {

    },
    props: {
        old_payroll:{
            required: true,
            type: Array
        }
    },
      created(){

    },
    computed:{
    },
    methods:{
        secToTime(t){
            return new Date(Math.abs(t) * 1000).toISOString().substr(11, 5);
             
        },
        switchVisibility: function(){
            this.showTable = !this.showTable;
        }
    },
  }

</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
</style>