<template>
    <div class="flex flex-row m-auto">
        <label class="col-sm-2 col-form-label text-xs">
            <a class="text-danger">*</a>Year:
        </label>
        <div class="col-sm-10">
            <input type="number" v-model="yearSelected" class="text-xs bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-gray-700 focus:text-gray-700 h-8 mb-1">
        </div>
    </div>
</template>

<script>
import debounce from 'debounce'
export default {
    data() {
        return {
            yearSelected: null,
        }
    },
    methods:{

    },
    created(){
        let currentdate = new Date();
        this.yearSelected = currentdate.getFullYear();
    },
    watch:{
        yearSelected:debounce(function(){
            if(this.yearSelected < 2000) return;
            this.$emit('toggle',{key:'yearSelected',value:this.yearSelected});
        },500)
    }
}
</script>