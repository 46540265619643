import axios from '@/config/axios'

const state = {
   delivery_list: [],
   candidates_list:[],
   sources_list:[],
   hire_count:[],
   status :'pending',
   candidate:{
    first_last_name:null,
    second_last_name:null,
    middle_name:null,
    name:null,
    curp:null,
    source_id:"",
    emp_id: null
   },
  defaultCandidate:{
    first_last_name:null,
    second_last_name:null,
    middle_name:null,
    name:null,
    curp:null,
    source_id:"",
    emp_id: null
  },
  uniqueCurp:null,
  recruitersList:[],
  status_list:[],
  termination_list:[],
  team_members:[]
};

const getters = {
    getDeliverables: (state) => state.delivery_list,
    getCandidates: (state) => state.candidates_list,
    getCandidate: (state) => state.candidate,
    getSources: (state) => state.sources_list,
    getPreviousHires: (state) => state.hire_count > 0,
    getCandidateStatus:(state) => state.status,
    getCurp:(state)  => state.candidate.curp,
    getDeliveries :(state) => state.deliveries,
    getCandidateKeys:(state) => state.keys,
    getCurpStatus:(state) => state.uniqueCurp,
    getRecruitersList: (state) => state.recruitersList,
    getStatusList: (state) => state.status_list,
    getTerminationReasons: (state) => state.termination_list,
    getTeamMembers: (state) => state.team_members

};

const actions = {
    async fetchDeliverables({ commit }){        
        
        const response = await axios.get('api/v2/recruitment/getDeliverables');
        commit('setDeliverables',response.data.delivery_list);
    },
    
    async fetchCandidates({ commit }){                
        const response = await axios.get('api/v2/recruitment/fetchCandidates');
        commit('setCandidates',response.data.candidate);
    },
    async updateCandidateStatus({ commit }, form){                
        const response = await axios.post('api/v3/candidates/status/' +form.id,form);
        commit('setCandidates',response.data.candidate);
    },


    async fetchSources({ commit }){                
        const response = await axios.get('api/v2/recruitment/getSources');
        commit('setSources',response.data.sources_list);
    },

    async filterCandidates({ commit },formData){                
        const response = await axios.post('api/v2/recruitment/filterCandidates',formData);
        commit('setCandidates', response.data.candidate);
    },

    
    async getCandidateProfile({commit},id){
        commit('setCandidateStatus','loading');
        const response = await axios.get('api/v2/recruitment/getCandidateProfile/'+id).then((res)=>{
            commit('setCandidateStatus','loaded');
            commit('setCandidate',res.data.candidate);     
            return res;
        });
      return response;
    },  

    registerEMSCandidate:  ({ commit },form) => {
       commit('setCandidateStatus','saving')
       let response = axios.post('api/v2/recruitment/registerEMSCandidate',form).then(res => {
            commit('setCandidateStatus','saved');          
            commit('setCandidates', res.data.candidates)
            return res;            
        })

        return response;
    },

    registerCandidate:  ({ commit },form) => {
        commit('setCandidateStatus','saving')
        let response = axios.post('api/v2/recruitment/registerCandidate',form).then(res => {
             commit('setCandidateStatus','saved');          
             commit('setCandidates', res.data.candidates)
             return res;            
         })
 
         return response;
     },

    async terminateCandidate({commit},form){
        const response = await axios.post(`api/v2/recruitment/candidate/${form.id}/terminate`,form);
        commit('setCandidate',response.data.candidates);
    },  

     updateCandidate: async({ commit },form) => {
        const response = await axios.post(`api/v2/recruitment/candidate/${form.id}/profile-update`,form);
        commit('setCandidate',response.data.candidate);
    },
    fetchTeamMembers: async({ commit }) => {
        const response = await axios.get(`api/v2/recruitment/candidates/team-employees`);
        commit('setTeamMembers',response.data.list);
    },

   
    fetchTerminationReasons: async({ commit }) => {
        const response = await axios.get(`api/v2/recruitment/termination-reasons`);
        commit('setTerminationReason',response.data.list);
    },

    updateDeliveries:({ commit },form) => {
        return new Promise((resolve, reject) => {
            commit('savingCandidate');
            axios.post(`api/v2/recruitment/candidate/${form.id}/updateDeliveries`,form).then(res => {
                commit('setCandidates', res.data.candidates)                
                resolve(res);
            }).catch(err => {            
                reject(err)
            });
        })
    },
    
    fetchCurpStatus:async ({commit}, {curp, phone}) =>{
        const response = await axios.get('api/v2/recruitment/check-status',{params:{curp:curp,phone:phone}});
        commit('setCurpStatus',response.data.status =='clear');
    },
    
    fetchRecruiters:async ({commit}) =>{
        const response = await axios.get('api/v3.1/recruitment/recruiters');
        commit('setRecruitersList',response.data.recruiters);
    },
    fetchCandidateStatus:async ({commit}) =>{
        const response = await axios.get('api/v2/recruitment/candidate-status');
        commit('setStatusList',response.data.list);
    }
    
}

const mutations = {
    setDeliverables: (state, delivery_list) => (state.delivery_list = delivery_list),
    setCandidates: (state, candidates) => (state.candidates_list = candidates),
    setCandidate: (state, candidate) => (state.candidate = candidate),
    setSources: (state, sources) => (state.sources_list = sources),
    setCandidateStatus :(state,status) =>(state.status = status),
    resetCandidate:(state)=>(state.candidate = state.defaultCandidate),
    setCurpStatus:(state, isUnique)=>(state.uniqueCurp = isUnique),
    setRecruitersList: (state, recruiters) => (state.recruitersList = recruiters),
    setStatusList: (state, list) => (state.status_list = list),
    setTerminationReason: (state, list) => (state.termination_list = list),
    setTeamMembers: (state, list) => (state.team_members = list),
};

export default {
    state,
    getters,
    actions,
    mutations
};