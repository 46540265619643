<template>
    <div class="EditInformationModalContainer">

        <div v-if="isLoading">
            <span><svg class="w-10 h-10 ml-2 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5">
                    </path>
                </svg></span>
        </div>

        <div v-else class="bg-white rounded-md p-4">
            <b-form v-if="showSurvey" @submit="sendTheAnswers">
                <!-- questions here -->
                <div v-for="(question, Qindex) in getFormContent" :key="Qindex" :id="question.id"
                    class="my-3 align-items-center justify-content-start ml-4 mr-4">

                    <div v-if="question.component !== 'label'" class="mb-1 capitalize" :id="question.id">
                        {{ question.label }}
                    </div>
                    <!-- label (no input type) -->
                    <div v-if="question.component === 'label'" class="flex flex-col">
                        <div :id="question.id" class="w-full text-2xl font-semibold" :class="question.class"
                            v-if="question.isTitle == 'true'">{{ question.HTMLText }}</div>
                        <div :id="question.id" class="w-full text-xl font-semibold mt-4" :class="question.class" v-else>
                            {{ question.HTMLText }}</div>
                    </div>
                    <!-- Text -->
                    <div v-if="question.component == 'input'" class="flex flex-col">
                        <textarea required rows="3" :id="question.id" :placeholder="question.placeholder"
                            :class="question.class" v-model="form_answers[Qindex].answer_label" style="resize: none"
                            class="border-b-2 border-gray-300 focus:border-blue-500 hover:border-blue-500 active:border-blue-500 focus:outline-none font-medium text-sm px-3 py-2.5 mx-auto w-full"></textarea>
                    </div>
                    <!-- Dropdown -->
                    <div v-if="question.component == 'dropdown'" class="">
                        <select required :id="question.id" :class="question.class"
                            v-model="form_answers[Qindex].answer_label"
                            class="border-b-2 border-gray-300 focus:border-blue-500 hover:border-blue-500 active:border-blue-500 focus:outline-none font-medium text-sm px-5 py-2.5 text-center inline-flex items-center mx-auto w-full">
                            <option hidden value="">Select an answer...</option>
                            <option v-for="(Answer, index) in question.options" :value="Answer.values" :key="index">
                                {{ Answer.label }}</option>
                        </select>
                    </div>
                    <!-- Date -->
                    <div v-if="question.component == 'datepicker'" class="flex flex-col">
                        <input required type="date" :id="question.id"
                            class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700 focus:placeholder-gray-700"
                            v-model="form_answers[Qindex].answer_label">
                    </div>
                    <!-- Time -->
                    <div v-if="question.component == 'time'" class="flex flex-col">
                        <input required type="time" :id="question.id" :class="question.class"
                            v-model="form_answers[Qindex].answer_label">
                    </div>
                    <!-- Checkbox (not written yet) -->
                    <div v-if="question.component == 'multiple'" class="grid grid-cols-3 gap-3">
                        <div v-for="(Answer, index) in question.options" :value="Answer.values" :key="index"
                            class="flex text-left items-center">
                            <input type="checkbox" :value="Answer.values" v-model="form_answers[Qindex].answer_label[index].values" :id="'answer' + Qindex + 'subAnswer' + index" class="h-5 w-5 flex gap-3 text-left items-center">
                            <label :for="'answer' + Qindex + 'subAnswer' + index" class="items-center flex my-auto gap-3 text-left">
                                <span class="ml-2 text-gray-700">{{ Answer.label }}</span>
                            </label>
                        </div>
                    </div>

                </div>
                <!-- ends questions -->

                <b-row class="text-center justify-center items-center m-3">
                    <button type="submit"
                        class="uppercase inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
                        Save
                    </button>
                </b-row>

            </b-form>

        </div>

    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
    components: {},
    data() {
        return {
            striped: true,
            animate: true,
            percentage: 0,
            isHide: false,
            callbacks: 0,
            isLoading: true,
            showSurvey: true,
            ReminingDays: 0,
            displayTitle: false,
            displayLob: false,
            displayReportTo: false,
            form_answers: [],
        }
    },
    computed: {
        ...mapState([
            "formBuilder"
        ]),
        ...mapGetters([
            "getFormContent",
            "getFormInfo",
            "getFormAnswer"
        ]),
    },
    props: {
        formSelected: Number
    },
    methods: {
        ...mapActions([
            "fetchFormContent",
            "sendFormAnswers"
        ]),

        loadSurveyInformation() {
            this.fetchFormContent(this.formSelected).then((res) => {
                this.form_answers = res.data.answers;
                if (res.data.answered !== null) {
                    this.$emit('alreadyAnswered', true)
                }
                this.isLoading = false;
            }, error => {
                this.$fire({
                    type: 'warning',
                    title: 'Oops! Something went wrong: ' + error.message,
                    text: 'If the problem persist, please report this issue to the system administrator',
                    showConfirmButton: true
                })
            });
        },

        sendTheAnswers() {
            event.preventDefault();
            this.isLoading = true;
            let objData = {
                form_id: this.formSelected,
                form_answers: this.form_answers,
            };
            this.sendFormAnswers(objData).then(() => {
                this.$fire({
                    type: 'success',
                    title: 'Answers Sent!',
                    text: "Thanks for your participation",
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    this.isLoading = false;
                    this.$emit('afterAnswer', true);
                });
            }).catch(() => {
                this.$fire({
                    type: 'warning',
                    title: 'Oops! Something went wrong. Please try again',
                    text: 'If the problem persist, please report this issue at the SupportHero! chat',
                    showConfirmButton: true
                }).then(() => {
                    this.isLoading = false;
                });
            });
        },

    },

    created() {
        this.loadSurveyInformation();
    },

    updated() {

    },

    mounted() {
        console.log('mounted');
    }

}
</script>
<style lang="scss" scoped>
.formEdit {
    display: block !important;
}

.hide {
    display: none;
}

.hideSelectCity,
.hideLabelCity {
    display: none;
}

.invalid-feedback {
    font-weight: bold;
}

.mystyle {
    border: 1px;
    border-color: brown;
}
</style>