
import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    items: [],
    status: false,
    details: []
};

const getters = {
    getItemsEmployee: (state) => state.items,
    getItemDetails: (state) => state.details,
};

const actions = {
    async requestItem({commit}, data){
        const endpoint = API_URL + 'api/v4/items/request';
        const response = await axios.post(endpoint, data).then((res) =>{
            commit('setItem', true); 
            return res.data;
        });
        return response;
    },
    async updateItem({commit}, data){
        const endpoint = API_URL + 'api/v4/items/update';
        const response = await axios.post(endpoint, data).then((res) =>{
            commit('setItem', true); 
            return res.data;
        });
        return response;
    },
    async getItemsRequest({commit}, employee){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/items/request/' + employee;
        const response = await axios.get(endpoint).then((res) => {
            commit('setItems', res.data.msg);
            return res.data;
        })
        return response;
    },
};

const mutations = {
    setItems: (state, items) => (state.items = items),
    setItem: (state, details) => (state.items = details)
};

export default {
    state,
    getters,
    actions,
    mutations
};