import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    surveys: [],
    status: false,
    message: '',
    details: []
};

const getters = {
    getSurveysV2: (state) => state.surveys,
    getSurveysV2Status: (state) => state.status,
    getSurveysV2Message: (state) => state.message,
    getSurveysV2Details: (state) => state.details,
    getSurveysV2Answers: (state) => state.answers
};

const actions = {

    async fetchAllSurveys ({commit}, data) {   
        const endpoint = API_URL + 'api/v4/surveys/filtered';
        const response = await axios.post(endpoint, data);
        commit('setSurveysV2', response.data);
    },

    async fetchDetailsSurveys ({commit}, id) {
        const endpoint = API_URL + 'api/v4/surveys/details/' + id;
        const response = await axios.get(endpoint).then((res) => {
            commit('setSurveysV2Details', res.data.details);
            commit('setSurveysV2Message', res.data.attempt_message);
            return res.data;
        })
        return response;
    },

    async storeSurveys({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/create';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setSurveysV2Status', true); 
            return res.data;
        });
        return response;
    },

    async editSurveys({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/edit';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setSurveysV2Status', true); 
            return res.data;
        });
        return response;
    },

    async initSurvey({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/init';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setSurveysV2Status', true); 
            return res.data;
        });
        return response;
    },

    async surveyAnswers({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/saveAnswers'
        const response = await axios.post(endpoint, data);
        commit('setSurveysV2Answers', null);
        return response.data
    },

    async fetchMyActiveSurveys ({commit}) {
        const endpoint = API_URL + 'api/v4/surveys';
        const response = await axios.get(endpoint).then((res) => {
            commit('setSurveysV2', res.data.surveys);
            return res.data;
        })
        return response;
    },

    async storeSatisfactionSurvey({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/saveSatisfactionSurvey';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setSurveysV2Status', true); 
            return res.data;
        });
        return response;
    },
    
    async checkAvailabilitySatisfactionSurvey({commit}) {
        const endpoint = API_URL + 'api/v4/surveys/checkSatisfactionSurvey';
        const response = await axios.get( endpoint).then((res) =>{
            commit('setSurveysV2Details', res.data);
            return res.data;
        });
        return response;
    },
    
    async storeMedicalSurvey({commit}, data) {
        const endpoint = API_URL + 'api/v4/surveys/storeMedicalSurvey';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setSurveysV2Status', true); 
            return res.data;
        });
        return response;
    },

    async checkMedicalSurvey({commit}) {
        const endpoint = API_URL + 'api/v4/surveys/checkMedicalSurvey';
        const response = await axios.get( endpoint).then((res) =>{
            commit('setSurveysV2Details', res.data);
            return res.data;
        });
        return response;
    },

    async reviewMedicalSurvey({commit}, employee) {
        const endpoint = API_URL + `api/v4/surveys/reviewMedicalSurvey/${employee}`;
        const response = await axios.get( endpoint).then((res) =>{
            commit('setSurveysV2Answers', res.data.survey);
            return res.data;
        });
        return response;
    },
};

const mutations = {
    setSurveysV2: (state, surveys) => (state.surveys = surveys),
    setSurveysV2Status: (state, status) => (state.status = status),
    setSurveysV2Message: (state, message) => (state.message = message),
    setSurveysV2Details: (state, details) => (state.details = details),
    setSurveysV2Answers: (state, answers) => (state.answers = answers)
};

export default {
    state,
    getters,
    actions,
    mutations
};