<template>
    <div class="p-8">
        <div class="flex justify-center">
            <h3 class="font-bold">Adding new shift for today</h3>
        </div>
        <div v-if="!loading">
            <div class="flex justify-center items-center relative">
                <div
                    class="inline-flex justify-center space-x-3 items-center rounded-full bg-gray-50 p-2"
                >
                    <div class="flex flex-col justify-center items-center">
                        <span class="font-semibold">
                            {{ start_date }}
                        </span>
                        <span class="font-semibold">
                            {{ toStringTime(start_time) }}
                        </span>
                    </div>
                    <span class="font-semibold"> </span>
                    <span class="h-4 w-4 rounded-full bg-c-alt"> </span>
                    <div>
                        <div
                            class="min-w-[100px] border-b border-x-secondary border-gray-200"
                        ></div>
                    </div>
                    <span class="relative flex h-4 w-4" v-if="isOngoing">
                        <span
                            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600/70 opacity-75"
                        ></span>
                        <span
                            class="relative inline-flex rounded-full h-4 w-4 bg-green-500/70"
                        ></span>
                    </span>
                    <span v-else class="h-4 w-4 rounded-full bg-c-alt"> </span>
                    <div class="flex flex-col justify-center items-center">
                        <span class="font-semibold" v-if="!isOngoing">
                            {{ end_date }}
                        </span>
                        <span class="font-semibold uppercase">
                            {{ endTime }}
                        </span>
                    </div>
                    <span class="uppercase font-semibold"> </span>
                </div>
            </div>
            <div class="block mt-10 space-y-4">
                <div class="space-y-2">
                    <div v-if="!onlyHasOneLob">
                        <span class="font-medium text-center"
                            >Select an Lob to continue</span
                        >
                        <multiselect
                            class="flex justify-content-center text-left"
                            v-model="lob_selected"
                            placeholder="Select an Lob"
                            label="label"
                            track-by="id"
                            :options="getLob"
                            :multiple="false"
                            :allow-empty="false"
                            :clear-on-select="false"
                            :preserve-search="false"
                            deselect-label=""
                        ></multiselect>
                    </div>
                    <div
                        class="flex"
                        :class="{
                            'justify-center': onlyHasOneLob,
                            'justify-end': !onlyHasOneLob,
                        }"
                    >
                        <div>
                            <input
                                v-model="home_switch"
                                type="checkbox"
                                id="customSwitch1"
                                class="hidden"
                            />
                            <label
                                for="customSwitch1"
                                class="flex items-center cursor-pointer"
                            >
                                <div
                                    class="relative flex items-center justify-center w-5 h-5 rounded-full border border-gray-300 transition-colors duration-300"
                                    :class="{
                                        'bg-blue-500 border-blue-500':
                                            home_switch,
                                    }"
                                >
                                    <i
                                        class="bi bi-check text-white mt-1 text-lg"
                                        v-if="home_switch"
                                    ></i>
                                </div>
                                <span class="ml-2">Is working from HOME.</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="space-y-3">
                    <small class="text-gray-500"
                        >If this event is on-going please do not fill the end
                        time</small
                    >
                    <div class="flex gap-3 items-center justify-center">
                        <div class="relative">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-500"
                            >
                                Start time
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <i class="bi bi-clock mr-1 text-gray-500"></i>
                                <vue-timepicker
                                    class="peer"
                                    v-model="start_time"
                                    format="HH:mm"
                                    hide-clear-button
                                    :hour-range="availableStartHourRange"
                                    :minute-range="availableStartMinuteRange"
                                />
                            </div>
                        </div>
                        <div class="relative">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-500"
                            >
                                End time
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <i class="bi bi-clock mr-1 text-gray-500"></i>
                                <vue-timepicker
                                    v-model="end_time"
                                    format="HH:mm"
                                    :hour-range="availableEndHourRange"
                                    :minute-range="availableEndMinuteRange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-y-1">
                    <small class="text-gray-500"
                        >This is required to add a comment for this
                        modification. Please be specific</small
                    >
                    <textarea
                        v-model="comment"
                        class="w-full p-3 resize-none rounded-lg border-[1px]"
                        placeholder="Write a comment"
                        maxlength="255"
                        rows="5"
                    ></textarea>
                    <small
                        class="text-gray-500 text-xs ml-2"
                        :class="{ 'text-red-500': commentIsInvalid }"
                    >
                        {{ commentLength }} / 255.
                    </small>
                </div>
                <div class="text-center">
                    <button
                        :disabled="
                            lob_selected === null ||
                            commentIsInvalid ||
                            startTimeIsInvalid
                        "
                        type="button"
                        class="bg-blue-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                        @click="createEvent"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <loader />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueTimepicker from "vue2-timepicker";
import Loader from "@/views/components/Loader";
export default {
    props: {
        employee: {
            type: String,
            required: true,
        },
    },
    components: {
        VueTimepicker,
        Loader,
    },
    data() {
        return {
            loading: true,
            start_time: {
                HH: "",
                mm: "",
            },
            end_time: null,
            start_date: this.formatDate(new Date()),
            end_date: this.formatDate(new Date()),
            campaign_selected: null,
            lob_selected: null,
            home_switch: false,
            comment: null,
        };
    },
    watch: {
        end_time: {
            handler(newValue) {
                if (
                    typeof newValue === "object" &&
                    newValue?.HH === "" &&
                    newValue?.mm === ""
                ) {
                    this.end_time = null;
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["getEventsByLob", "getAllCampaigns", "getLob"]),

        onlyHasOneLob() {
            return this.getLob.length === 1;
        },

        startTimeIsInvalid() {
            return !this.start_time || !this.isValidTime(this.start_time);
        },

        commentIsInvalid() {
            return (
                this.comment === null ||
                this.comment.trim() === "" ||
                this.comment === undefined ||
                this.comment === "" ||
                this.comment?.length < 5
            );
        },

        commentLength() {
            return this.comment?.length ?? 0;
        },

        availableStartHourRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(24).keys()];
            const endHour = Number(this.end_time.HH);
            return [...Array(endHour + 1).keys()];
        },

        availableStartMinuteRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(60).keys()];

            const endHour = Number(this.end_time.HH);
            const endMinute = Number(this.end_time.mm);

            const startHour = this.isValidTime(this.start_time)
                ? Number(this.start_time.HH)
                : null;

            if (startHour === endHour) {
                return [...Array(endMinute + 1).keys()];
            }

            return [...Array(60).keys()];
        },

        availableEndHourRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(24).keys()];

            const startHour = Number(this.start_time.HH);
            return [...Array(24).keys()].filter((hour) => hour >= startHour);
        },

        availableEndMinuteRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(60).keys()];

            const startHour = Number(this.start_time.HH);
            const startMinute = Number(this.start_time.mm);

            const endHour = this.isValidTime(this.end_time)
                ? Number(this.end_time.HH)
                : null;

            if (startHour === endHour) {
                return [...Array(60).keys()].filter(
                    (minute) => minute >= startMinute
                );
            }

            return [...Array(60).keys()];
        },

        endTime() {
            return this.end_time === null
                ? "on-going"
                : this.toStringTime(this.end_time);
        },

        isOngoing() {
            return this.end_time === null;
        },
    },
    methods: {
        ...mapActions(["createClockerHours", "fetchLobsByEmployee"]),

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        isValidTime(time) {
            return (
                time &&
                time.HH !== undefined &&
                time.mm !== undefined &&
                !isNaN(Number(time.HH)) &&
                !isNaN(Number(time.mm))
            );
        },

        timeHelper(time) {
            return time === "" ? "00" : time;
        },

        createEvent() {
            this.loading = true;
            this.createClockerHours({
                emp_id: this.employee,
                lob_id: this.lob_selected.id,
                start_time: this.start_time
                    ? this.start_time.HH + ":" + this.timeHelper(this.start_time.mm)
                    : this.start_time,
                end_time: this.end_time
                    ? this.end_time.HH + ":" + this.timeHelper(this.end_time.mm)
                    : this.end_time,
                comment: this.comment,
                is_wfh: this.home_switch,
            })
                .then(() => {
                    this.$fire({
                        type: "success",
                        title: "Success",
                        text: "Event successfully created!",
                        showConfirmButton: true,
                    }).then(() => {
                        this.$emit("close");
                    });
                })
                .catch((error) => {
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        toStringTime(time) {
            return `${time?.HH != "" ? time?.HH : "00"}:${
                time?.mm != "" ? time?.mm : "00"
            }`;
        },
    },
    created() {
        this.fetchLobsByEmployee(this.employee).then(() => {
            if (this.onlyHasOneLob) {
                this.lob_selected = this.getLob[0];
            }
            this.loading = false;
        });
    },
};
</script>
<style scoped>
</style>