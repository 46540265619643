<template>
    <div class="min-h-full w-full">
        <div class="w-full border-3 border-dashed border-light-indigo-500" v-show="dropped === 2"></div>
        <div v-show="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong class="font-bold">Oops there is an error! </strong>
            <span class="block sm:inline">{{ error }}.</span>
            <span class="absolute top-0 bottom-0 right-0 px-4 py-3" @click.prevent="closeError()">
                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
        </div>

        <div class="bg-gray-50 p-3 rounded-xl group hover:bg-gray-100 cursor-pointer" v-show="Imgs.length === 0" 
        @dragover.prevent="dragOver" @dragleave.prevent="dragOver" @drop.prevent="drop($event)">
            <div v-show="Imgs.length == 0" class=" border-4 border-dashed group-hover:border-gray-300 cursor-pointer">
                <label class="p-3 w-full text-blue-400 text-center group-hover:opacity-100 opacity-50 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <h4 class="text-center text-sm font-black tracking-wide p-1">Click to upload or drop your files here</h4>
                    <input v-if="isMultiple" @change="previewImgs" ref="uploadInput" :accept="fileTypes" type='file' class="hidden" :disabled="disabled" multiple />
                    <input v-else @change="previewImgs" ref="uploadInput" type='file' :accept="fileTypes" class="hidden" :disabled="disabled" />
                </label>
            </div>
        </div>

        <div v-if="isMultiple" class="bg-gray-50 p-3 rounded-xl" v-show="Imgs.length > 0">
            <div class="relative rounded-xl border-4 border-blue-100 p-4 grid lg:grid-cols-3 xl:grid-cols-5 gap-4 px-5">
                <vs-tooltip class="absolute top-0 right-0" text="Clear All">
                    <button class="m-1 px-2 border-1 bg-red-500 text-white rounded-md col-start-5 top-0 right-0" @click.prevent="reset">
                        <i class="bi bi-trash3-fill"></i>
                    </button>
                </vs-tooltip>
                <div class="items-center my-auto m-1 rounded-xl cursor-pointer" v-for="(img, idx) in Imgs" :key="idx">
                    <div class="bg-transparent rounded-xl relative" @click.prevent="deleteImg(idx)">
                        <div class="-top-2 -right-2 bg-transparent rounded-full cursor-pointer absolute text-red-500">
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 bg-white fill-current rounded-full border-2 border-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>                        
                        
                        <img v-if="isImage(files[idx].type)" class="image-size content-center rounded-xl" :src="img" :title="files[idx].name"/>
                        <div v-else class="image-size flex">
                            <i class="icon-size m-auto" :class="'bi bi-filetype-'+fileExtension(files[idx].name)"></i>
                        </div>
                        <p class="image-label truncate overflow-hidden">{{ files[idx].name }}</p>
                    </div>
                </div>
                <div @click="append" v-if="Imgs.length" class="m-1 p-2 cursor-pointer group bg-transparent border-dashed border-2 hover:border-gray-400 rounded-xl add-button">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-300 m-auto group-hover:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            </div>
        </div>
        <div v-else class="bg-gray-50 p-3 rounded-xl" v-show="Imgs.length > 0">
            <div class="relative rounded-xl border-4 border-blue-100 p-3 flex">
                <div class="items-center m-1 my-auto hover:border-red-300 rounded-xl cursor-pointer" v-for="(img, idx) in Imgs" :key="idx">
                    <div class="bg-transparent rounded-xl relative" @click.prevent="deleteImg(idx)">
                        <div class="-top-2 -right-2 bg-transparent rounded-full cursor-pointer absolute text-red-500">
                            <button @click.prevent="deleteImg(idx)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 bg-white fill-current rounded-full border-2 border-red-400 group-hover:border-red-700" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>         
                        <img v-if="isImage(files[idx].type)" class="max-h-[300px] mx-auto content-center rounded-xl" :src="img" :title="files[idx].name" />
                        <i v-else class="icon-size m-auto" :class="'bi bi-filetype-'+fileExtension(files[idx].name)"></i>
                        <p class="image-label truncate overflow-hidden">{{ files[idx].name }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>    
</template>
<script>
export default {
    data(){
        return{
            error: "",
            files: [],
            dropped: 0,
            Imgs: [],
            mb_ratio: 1000000
        }
    },
    props: {
        maxFiles: {
            type: Number,
            default: 1
        },
        fileTypes: {
            type: Array,
            default () {
                return [ 'image/jpeg', 'image/png', 'image/jpg' ];
            }
        },
        maxSize: {
            type: Number,
            default: 3
        },
        maxError: {
            type: String,
            default () {
                return  'Maximum files reached: ' + this.$props.maxFiles;
            },
        },
        fileError: {
            type: String,
            default: 'Unsupported file type'
        },
        sizeError: {
            type: String,
            default () {
                return  'Maximum size of files reached: ' + this.$props.maxSize + ' MB';
            },
        },
        isMultiple: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        isImage (filetype) {
            let imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            return imageTypes.includes(filetype);
        },

        fileExtension (filename) {
            var ext = /^.+\.([^.]+)$/.exec(filename);
            return ext == null ? "" : ext[1];
        },

        dragOver() {
            this.dropped = 2;
        },

        drop(dropEvent) {
            let validFileType = true;
            let validSize = true;
            let files = Array.from(dropEvent.dataTransfer.files);
            if (dropEvent && files) {
                files.forEach((file) => {
                    validFileType = this.fileTypes.includes(file.type);
                    validSize = file.size < (this.maxSize * this.mb_ratio);
                });

                if (this.maxFiles && files.length + this.files.length > this.maxFiles) {
                    this.error = this.maxError;
                    return;
                }

                if (!validFileType) {
                    this.error = this.fileError;
                    return;
                }

                if (!validSize) {
                    this.error = this.sizeError;
                    return;
                }

                this.files.push(...files);
                this.previewImgs();
            }
            this.dropped = 0;
        },

        closeError () {
            this.error = "";
        },

        append() {
            this.$refs.uploadInput.click();
        },

        readAsDataURL(file) {
            return new Promise(function (resolve, reject) {
                let fr = new FileReader();
                fr.onload = function () {
                    resolve(fr.result);
                };
                fr.onerror = function () {
                    reject(fr);
                };
                fr.readAsDataURL(file);
            });
        },

        deleteImg(index) {
            if(this.disabled) return;
            this.Imgs.splice(index, 1);
            this.files.splice(index, 1);
            this.$emit("changed", this.files);
            this.$refs.uploadInput.value = null;
        },

        previewImgs(event) {
            if (event && event.currentTarget.files.length + this.files.length > this.maxFiles) {
                this.error = this.maxError;
                return;
            }

            if (event && event.currentTarget.files[0].size > (this.maxSize * this.mb_ratio)) {
                this.error = this.sizeError;
                return;
            }

            if (this.dropped === 0) this.files.push(...event.currentTarget.files);
            this.error = "";
            this.$emit("changed", this.files);
            let readers = [];

            if (!this.files.length) return;
            
            for (let i = 0; i < this.files.length; i++) {
                readers.push(this.readAsDataURL(this.files[i]));
            }
            Promise.all(readers).then((values) => {
                this.Imgs = values;
            });
        },

        reset() {
            this.$refs.uploadInput.value = null;
            this.Imgs = [];
            this.files = [];
            this.$emit("changed", this.files);
        },
    }
}
</script>
<style scoped>
.image-size {
    width: 100% !important;
    height: 100% !important;
    margin: auto;
    object-fit: cover;
}
.icon-size {
    font-size: 65px !important;
}
.add-button {
    height: 120px !important;
}
.image-label {
    width: 100px !important;
    margin: 0 auto 0 auto !important;
    font-size: x-small;
}
</style>