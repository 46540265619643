<template>
    <div class="w-full h-full py-4">
         <div class="max-w-3xl w-auto p-3 px-2  mx-auto flex flex-row items-center justify-start">
            <training-details/>
         </div>
         <div class="max-w-7xl mx-auto">
 
             <div class="flex flex-col">
                 <div class="shadow-md sm:rounded-lg">
                     <div class="inline-block min-w-full align-middle">
                         <div class="">
                             <table class="min-w-full divide-y divide-gray-200 table-fixed table-even dark:divide-gray-700">
                                 <thead class="bg-gray-100 dark:bg-gray-700">
                                     <tr>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400 w-3/5">
                                             Staff Member
                                         </th>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                             Date
                                         </th>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                             Author
                                         </th>
                                         
                                         <th scope="col" class="p-4 text-right">
                                             Actions
                                         </th>
                                     </tr>
                                 </thead>
                                 <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700" v-if="!loading">
                                 
                                     <tr class="hover:bg-gray-100 dark:hover:bg-gray-700" v-for="item in _staff" :key="item.id"> 
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left ">
                                             {{item.emp_id}}
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
                                            {{$moment(item.created_at).format('L')}}
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                                             {{item.created_by}}
                                         </td>
                                          <td class="px-6 py-4 whitespace-nowrap flex flex-row align-center justify-end">
                                             <button class="bg-red-500 text-white px-3 py-2 rounded-sm" @click.prevent="deleteRow({training_id:item.training_id, id:item.id})">
                                         
                                                  <span >
                                                         Delete 
                                                         <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                         <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                         </svg>
                                                 </span>
                                                
                                             </button>
                                         </td>
                                     </tr>   
 
                                     <new-staff :key="resetKey"/>
 
 
                                    
                                     
                                 </tbody>
                                 <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"  v-if="loading">
                                 
                                     <tr class="hover:bg-gray-100 dark:hover:bg-gray-700 animate-pulse" v-for="index in 4" :key="index"> 
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left ">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                        
                                          <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                             <div class="flex flex-row items-center justify-end">
                                                 <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                             </div>
                                         </td>
                                     </tr>
 
                                     
                                 </tbody>
                             </table>
                             <div class="flex flex-row items-center justify-end pr-3 py-3">
                                 <button class="bg-gray-400 px-3 py-2 text-white rounded-sm shadow-sm" @click="$router.push({name:'recruitment.trainings.attrition'})">
                                     Previous
                                 </button>
                                  <button class="ml-2 bg-gray-700 px-3 py-2 text-white rounded-sm shadow-sm" @click="$router.push({name:'recruitment.trainings.activities'})">
                                     Next
                                 </button>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
    </div>
     <!---
     1.- Al cargar el componente traernos todos los miembros del training que esten en attrition.
     2.- Esos miembros los vamos  a guardar en local storage. 
     3.- Al agregar o quitar un componente se modificara en local storage.
     4.- Agregar un boton para guardar bloque y para avanzar al siguiente step.
    -->
 </template>
 
 <script>
 import {mapActions, mapGetters} from 'vuex'
// Pinia > Vuex
 import TrainingDetails from '../../components/misc/trainings/TrainingDetails.vue'
 import NewStaff from '../../components/misc/trainings/NewStaff.vue'
 export default {
     components:{
         NewStaff, 
         TrainingDetails,
     },
     data(){
         return{
             loading:true,
             resetKey:0,
             deleting:false
         }
     },
     methods:{
        ...mapActions([
            'fetchTrainingStaffById',
            'deleteTrainingStaff',
            'editTrainingStaff']
        ),
         resetFactoryKey:function(){
             this.resetKey ++; 
         },
         deleteRow:async function(form){
             const response = await this.$fire({type:'question','title':'Are you sure you want to proceed? This action can not be reverted in any way.',showCancelButton:true,confirmButtonText: 'Proceed'});
             if(!response?.value){
                 return null;
             }
             this.deleting = true;
             await this.deleteTrainingStaff(form);
             this.resetKey ++;
             this.deleting = false;
         },
         updateRow:async function(form){
            await this.editTrainingStaff(form);
             this.resetKey ++;
             await this.fetchTrainingStaffById(this.$route.params.id);
         }
     },
     computed:{
         ...mapGetters([
            '_staff',
         ]),
         
     },
      async created(){
         this.$emit('set-title','Staff Attendance');  
         await this.fetchTrainingStaffById(this.$route.params.id);
         this.loading = false;
     },
 }
 </script>
 <style>
 .shadow-soft{
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }
 </style>