<template>
<div class="relative z-10 w-full  h-full border-2 rounded-sm p-1 h-full flex flex-col align-items-center justify-content-around"
      draggable="true"
     @dragenter.prevent="hoverDropZone"
     @dragleave.prevent ="leaveDropZone"
     @drop.prevent="addFile"
     @dragover.prevent
     :class="styles"
     id="dropzone"
>
    <span class="text-blue-300">
      <svg class="w-16 h-16" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
      </svg>
    </span>

    <h4 class="text-gray-400 text-sm font-medium">
      Drag & drop your file here
    </h4>
</div>
</template>

<script>
export default {
  name: "Dropzone",
  data(){
    return {
      file:null,
      status:null
    }
  },
  props:{
    types:{
      type:Array,
      default:()=> [
          'image/png',
          'image/jpg',
          'application/pdf'
      ]
    },
    size:{
      default:10000,
      type:Number
    }
  },
  computed:{

    validFile:function(){
      return this.valid;
    },
    styles:function(){
      let styles;
      switch(this.status) {
        case 'drag-enter':
          styles = 'bg-gray-50 border-blue-400 bg-blue-50 border-dashed';
          break;
        case 'drop':
          styles ='bg-green-50 border-green-200 border-solid';
          break;
        default:
          styles = 'bg-gray-50 border-blue-200 border-dashed';
      }
      return styles;
    }
  },
  methods:{
    addFile:function(evt){
      this.status = 'drop';
      this.file = evt.dataTransfer.items[0].getAsFile();
      this.$emit('file-dragged',this.file);
    },
    hoverDropZone:function(){
      this.status = 'drag-enter';
    },
    leaveDropZone:function(){
      if(!this.file){
        this.status = null;
      }
    }
  },
}
</script>
<style scoped>
  #drop_zone:before{
    content:'';
    position:absolute;
    z-index:+1;
    left:-2px;
    top:-2px;
    bottom:-2px;
    right:100%;
    opacity:0.5;
    background-color:transparent;
    border:dashed transparent 2px;
    border-radius:0.375rem;
    transition:right 0.05s ease-in-out;
  }
  #drop_zone.filled::before{
    right: -2px;
    border-color:lightgreen;
  }
</style>
