<template>
    <div class="main">
        <div class="m-auto">
            <div class="relative avatar-img bg-red-200">
                <img class="rounded-xl m-auto" src="https://placekitten.com/300/300">
                <div class="text-xs text-gray-600 font-semibold font-sans uppercase rounded-md py-1 px-2 mx-auto absolute -bottom-1 -right-1 shadow-md"
                :class="{
                    'bg-green-300' : isActive,
                    'bg-gray-300' : isInactive,
                    'bg-gray-300' : isSomethingElse
                }">
                    {{infoEmployee.EmpStatus}}
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-3">
            <div class="flex flex-row">
                <div class="text-sm text-blue-600 font-semibold font-sans uppercase py-1 px-2 mx-auto tracking-wider">{{fullName}}</div>
            </div>
            <div class="flex flex-row">
                <div class="text-xs text-gray-600 font-semibold font-sans uppercase bg-gray-200 rounded-full py-1 px-2 mx-auto">{{infoEmployee.Title}}</div>
            </div>
            <div class="flex flex-row mt-2">
                <div class="flex flex-1 justify-end p-2">
                    <span class="flex flex-col text-right p-1 pr-2">
                        <div class="text-sm text-gray-600 font-semibold font-sans uppercase">{{infoEmployee.EmpID}}</div>
                        <span class="text-xs text-gray-400 font-semibold uppercase">Employee ID</span>
                    </span>
                    <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-1 p-2">
                    <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </div>
                    <span class="flex flex-col p-1 pl-2">
                        <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">{{infoEmployee.Location}}</div>
                        <span class="text-xs text-gray-400 font-semibold uppercase my-auto">Site</span>
                    </span>
                </div>
            </div>
            <div class="flex flex-row" v-if="isTermination">
                <div class="flex flex-1 justify-end p-2">
                    <span class="flex flex-col p-1 pr-2">
                        <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">{{ formatDate(infoEmployee.HireDate) }}</div>
                        <span class="text-xs text-gray-400 font-semibold uppercase my-auto">Hire Date</span>
                    </span>
                    <div class="bg-red-200 rounded-md p-1 icon flex items-center justify-center">
                        <i class="text-red-500 bi bi-calendar-check text-xl"></i>
                    </div>
                </div>
                <div class="flex flex-1 p-2" v-if="infoEmployee.survey">
                    <div class="rounded-md p-1 icon flex items-center justify-center" :class="infoEmployee.survey.length>0 ? 'bg-green-200':'bg-red-200'">
                        <i class="bi text-xl" :class="infoEmployee.survey.length>0 ? 'text-green-500 bi-check':'text-red-500 bi-x'"></i>
                    </div>
                    <span class="flex flex-col p-1 pl-2">
                        <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">Exit Survey</div>
                    </span>
                </div>
            </div>
        </div>

        <span class="inline-block text-xxs text-gray-400 uppercase text-center w-full">
            {{getEditableInfo.last_update}}
        </span>
    </div>
</template>
<script>
import { mapGetters }   from 'vuex'
import moment from "moment";

export default {
    props: {
        isTermination: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['infoEmployee','getEditableInfo','loadingFlag']),

        fullName(){
            return (this.infoEmployee.FirstName?.toUpperCase() + " " + (this.infoEmployee.MiddleName?.toUpperCase() ?? '') + " " + this.infoEmployee.LastName1?.toUpperCase() + " " + (this.infoEmployee.LastName2?.toUpperCase() ?? '')).trim();
        },

        isActive: function(){
            return this.infoEmployee.EmpStatus?.toLowerCase() === 'active';
        },
        isInactive: function(){
            return this.infoEmployee.EmpStatus?.toLowerCase() === 'inactive';
        },
        isSomethingElse: function(){
            return !this.isActive && !this.isInactive;
        },
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD-MM-YYYY')
        }
    },
}
</script>
<style scoped>
.main{
    widows: 100% !important;
}
.avatar-img{
    height: 10rem !important;
    width: 10rem !important;
    border-radius: 2rem !important;
    margin: auto !important;
}
.border-input{
    border-bottom: 1px solid rgb(150, 150, 150);
}
.text-xxs{
    font-size: 0.5rem;
}
.text-xs{
    font-size: 0.7rem;
}
.text-sm{
    font-size: 0.8rem;
}
.icon{
    width: 2.5rem !important;
    height: 2.5rem !important;
}
</style>
