<template>
    <div class="border-l-2 border-gray-200">
        <select required class="bg-transparent hover:text-gray-600 focus:text-gray-600 w-32" v-model="selectedQueue">  
          <optgroup label = "Select a Queue">
            <option hidden value="0">Queue</option>
            <option v-for="(item,index) in getQueuesList" :key="index" :value="item.id">{{item.queueName}}</option>
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error: null,
            selectedQueue : 0
        }
    },
    computed:{
        ...mapGetters(['getQueuesList']),
    },
    created(){
       this.fetchTicketQueues().then(()=>{
           this.error =false
        });
    },
    methods:{
        ...mapActions(['fetchTicketQueues'])
    },
    watch:{
      selectedQueue: Debounce(function(){
          this.$emit('toggle',{
              key:'queue',
              value :this.selectedQueue
          });
      },200)     

    }
}
</script>
