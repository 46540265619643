const currentUser = JSON.parse(JSON.stringify({
    'token':        (sessionStorage.getItem('token')            !== undefined) ? JSON.parse(sessionStorage.getItem('token')) : undefined,
    'EmpID':        (sessionStorage.getItem('EmpID')            !== undefined) ? JSON.parse(sessionStorage.getItem('EmpID')) : undefined,
    'roles':        (sessionStorage.getItem('roles')            !== undefined) ? JSON.parse(sessionStorage.getItem('roles')) : undefined,
    'location':     (sessionStorage.getItem('location')         !== undefined) ? JSON.parse(sessionStorage.getItem('location')) : undefined,
    'isNewUser':    (sessionStorage.getItem('passwordReset')    !== 'undefined') ? JSON.parse(sessionStorage?.getItem('passwordReset')) : undefined,
    'status':       (sessionStorage.getItem('status')           !== 'undefined') ? JSON.parse(sessionStorage?.getItem('status')) : undefined,
    'campaign':     (sessionStorage.getItem('campaign')         !== 'undefined') ? JSON.parse(sessionStorage?.getItem('campaign')) : undefined,
}));

export default {
    currentUser
};
