<template>
  <div class="ml-3 relative">
    <modal v-if="showChangePassword" @close="closeModals" :width="'w-2/5'">
        <template slot="content">
            <change-password-modal :profileInfo="infoEmployee"></change-password-modal>
        </template>  
    </modal>
    <button class="flex items-center" id="user-menu" aria-haspopup="true" @click="toggle">
      <slot name="title">Open</slot>
    </button>

    <transition name="bounce">
      <div class="origin-top-right absolute right-0 mt-3 rounded shadow-sm text-left bg-white min-w-[250px] px-1 py-3" role="menu" aria-orientation="vertical" aria-labelledby="user-menu" v-show="open">
        <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="redirect('myProfile.personal')"><i class="bi bi-person mr-3"></i>My Profile</a>
        <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="showPasswordModal"><i class="bi bi-key mr-3"></i>Change Password</a>
        <!-- <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a> -->
        <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="logout"><i class="bi bi-box-arrow-right mr-3"></i>Sign out</a>
      </div>
    </transition>
  </div>
</template>
<script>
import debounce from 'debounce';
import {mapState, mapActions, mapGetters } from 'vuex';
import modal from '@/views/components/Modal/modalBase';
import ChangePasswordModal from '@/views/components/Modal/changePassword';
export default {
  components: {
    modal,
    ChangePasswordModal,
  },
  name: "Dropdown",
  data(){
    return {
      open:false,
      showChangePassword: false,
    }
  },
    computed:{
      ...mapState(['session']),
      ...mapGetters(['infoEmployee']),
    },
  methods:{
    ...mapActions(['logoutUser']),
    toggle:debounce(function(){
      this.open = !this.open;
    },350),
    redirect:function(route){
      if(this.$route.name !== route) {
        this.$router.push({name: route});
      }
    },
    deleteSession: function(){
      this.logoutUser().then(() => {
        this.isLoading = false;
        setTimeout(window.location.reload.bind(window.location), 500);
      });
    },
    logout: debounce(async function(){
      this.deleteSession();
    },200),
    doSomething:debounce(function() {
      alert('focus lost');
    },750),

    showPasswordModal:function(){
      window.scrollTo(0, 0)
      this.showChangePassword =  true;
    },
    closeModals: function(){
      this.showChangePassword = false;
    },
  },
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}


</style>