<template>
    <div class="flex">
        <div class="flex align-items-center justify-content-start text-gray-300 p-2 bg-white rounded-lg shadow-sm">
            <employee @toggle="updateFilters"/>
        
            <div class="border-l-2 border-gray-200 p-2 px-3 flex">
                
                <multiple-dropdown :options="getTitles" v-model="form.title">
                    <i class="bi bi-briefcase-fill my-auto"></i>
                </multiple-dropdown>
            </div>

            <div class="border-l-2 border-gray-200 p-2 px-3 flex">
                
                <multiple-dropdown :options="getLocations" v-model="form.site">
                    <i class="bi bi-geo-alt-fill my-auto"></i>
                </multiple-dropdown>
            </div>

            <div class="border-l-2 border-gray-200 p-2 px-3 flex" v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Payroll')">
                
                <multiple-dropdown :options="departmentNames" v-model="form.department">
                    <i class="bi bi-building-fill my-auto"></i>
                </multiple-dropdown>
            </div>
    
            <div class="border-l-2 border-gray-200 p-2 px-3 flex" v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Payroll')">
                
                <multiple-dropdown :options="getAllCampaigns" v-model="form.campaign">
                    <i class="bi bi-house-fill my-auto"></i>
                </multiple-dropdown>
            </div>
    
            <div class="border-l-2 border-gray-200 p-2 px-3 flex" v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Payroll')">
                
                <multiple-dropdown :options="status_options" v-model="form.status">
                    <i class="bi bi-person-fill-exclamation my-auto"></i>
                </multiple-dropdown>
            </div>
        
            <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700"  @click.prevent="clear">
                Clear <i class="bi bi-funnel-fill"></i>
            </button>
        </div>
    </div>
</template>
<script>
import Debounce from 'debounce';
import {mapGetters, mapActions} from 'vuex';
import Employee         from './employee';
import permissions from '@/config/mixins'
import MultipleDropdown from '@/views/Utilities/MultipleDropdown.vue'
export default {
    data(){
        return{
            CurrentPermissions:'',
            filtersLoaded: false,
            form:{
                site: [],
                employee: '',
                status:  ['Active'],
                department: [],
                campaign: [],
                title: []
            },
            status_options: ['Active', 'Job Abandonment', 'Inactive', 'Predismissal'],
        }
    },
    mixins:[permissions],
    components:{
        Employee,
        MultipleDropdown
    },
    computed:{
        ...mapGetters([
            'getLocations',
            'getAllCampaigns',
            'getDepartments',
            'getTitles'
        ]),
        departmentNames(){
            return this.getDepartments.map((department) => department?.label)
        },
        formHasChanged() {
            return JSON.stringify(this.form) !== JSON.stringify(this.original_form);
        }
    },
    methods:{
        ...mapActions([
            'fetchSites',
            'fetchCampaigns',
            'fetchDepartments',
            'fetchTitles',
        ]),
        itemContains(n) {
            return this.hasPermission(n)
        },
        clear:function(){
            this.$emit('clear', true);
        },

        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value?.toUpperCase();
        },350),
    },
    watch:{
        form:{
            handler(){
                this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    async created(){
        this.filtersLoaded = false;
        await this.fetchSites();
        await this.fetchDepartments();
        await this.fetchTitles();
        await this.fetchCampaigns().then(() => {
            this.filtersLoaded = true;
        })
    }

}
</script>