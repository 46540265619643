<template>
    <div class="py-2">
        <div class="grid grid-cols-3 gap-4 ml-4 mr-4">
            <div>
                <div class="w-full mr-3 text-center bg-white shadow-md rounded-md">
                    <div class="d-block text-center">
                        <h3 class="m-1">Add a new image</h3>
                        <div class="ml-2 mt-2">
                            <span class="text-gray-700">Location</span>
                            <div class="mt-2">
                                <label class="inline-flex items-center">
                                    <input v-model="dpLocation" type="radio" class="form-radio" name="dpLocation" value="AGS">
                                    <span class="ml-2">AGS</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input v-model="dpLocation" type="radio" class="form-radio" name="dpLocation" value="MTY">
                                    <span class="ml-2">MTY</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input v-model="dpLocation" type="radio" class="form-radio" name="dpLocation" value="QTO">
                                    <span class="ml-2">QTO</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input v-model="dpLocation" type="radio" class="form-radio" name="dpLocation" value="ALL">
                                    <span class="ml-2">All</span>
                                </label>
                            </div>
                            <div>
                                <label class="items-center w-full p-2 rounded-lg">
                                    <input class="w-full border p-2" v-model="imageURL" type="text" placeholder="Write the path to redirect when click">
                                </label>
                                <div>
                                    <div v-if="this.file">
                                        <span><p>Current File: {{this.file.name}}</p></span>
                                    </div>                        
                                    <div class="mr-2 pb-2" v-else>
                                        <dropzone class="w-full " v-on:file-dragged="setFile" v-if="!fileIsLoaded"></dropzone>
                                    </div>
                                </div>
                                <div class="p-4">
                                    <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded w-full" :disabled="this.btnClicked"  @click="processImage">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-2">
                <h3 class="text-center">Current Images on Dashboard</h3>
                <div v-if="this.getImages" class="flex flex-row flex-wrap">
                    <div class="items-center p-3 w-1/3" v-for="image in getImages" :key="image.id">
                        <div class="m-1">
                            <span><strong>File name: </strong>{{ image.file_name || "No redirect" }}</span>
                        </div>
                        <div class="m-1">
                            <span><strong>Redirect to: </strong>{{ image.url_to_redirect || "No redirect" }}</span>
                        </div>
                        <div class="bg-white rounded-xl p-8 relative">
                            <div style="margin: -10px;" class="top-0 right-0 bg-transparent rounded-full cursor-pointer absolute text-red-500 group hover:shadow-lg">
                                <button @click="removeImage(image)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 fill-current rounded-full border-2 border-red-400 group-hover:border-2 group-hover:border-gray-700 hover:shadow-lg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>                        
                            <img class="inline-block object-scale-down h-full" :src="image.imgURL">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="m-1">
                        <span><strong>Please, upload images first</strong></span>
                    </div>
                </div>          
            </div>
      </div>
    </div>
</template>
<script>
import Dropzone from "@/views/Utilities/Dropzone";
import {VALID_FILE_TYPES} from '@/store/actions/file';
import {mapState,mapGetters,mapActions} from 'vuex';
export default {
    components: {
       Dropzone,
    },
    data(){
        return {
            title : "Dashboard Manager",
            dpLocation: null,
            file : null,
            maxSize : 6000000,
            convertRate : 1048576,
            btnClicked: false,
            imageURL: '',
        }
    },
    mounted(){
        this.$emit('set-title',this.title);
        this.fetchArrayImages();
    },
    methods:{
        ...mapActions(['uploadImgDashboard','fetchDashboardManagerImages','deleteImage']),
        setFile:function(file){
            this.file = file;
        },
        reset:function(){
            this.$emit('clear');
        },
        fetchArrayImages: function(){
            this.fetchDashboardManagerImages();
        },
        removeImage: function(data){
            this.$confirm("Do you want to delete this image?", "You're about to delete an image", "warning").then((result) => {
                if (result){
                    this.infoLoaded = false;
                    this.deleteImage(data).then((response)=>{
                        if(response.data.error == false){
                            setTimeout(location.reload(), 3000)
                        }
                    });
                }
            });
        },
        processImage:function(){
            if(this.dpLocation == null || this.dpLocation == ''){
                this.$fire({
                  type: "error",
                  title: "There are some errors, please select the location.",
                  showConfirmButton: false,
                }).then(()=>{
                    this.btnClicked = false;
                    return false;
                });
            }else{
                this.btnClicked = true;
                let form = new FormData();
                form.append('image', this.file);
                form.append('source','dashboard');
                form.append('location',this.dpLocation);
                form.append('imageURL',this.imageURL);
                this.uploadImgDashboard(form).then((res)=>{
                    if(res.data.error == false){
                        setTimeout(location.reload(), 3000)
                    }
                });
            }
            
        },
    },
    computed:{
        ...mapState(["fileState"]),
        ...mapGetters(['getImages']),
        fileIsLoaded:function(){
            return this.file !== null && this.file instanceof File;
        },
        validType:function(){
            return !!VALID_FILE_TYPES.includes(this.file.type);
        },
        validSize:function(){
            return this.file.size <= this.maxSize;
        },
        validFile:function(){
            return this.fileIsLoaded && this.validSize && this.validType;
        },
        isPdf:function(){
            return this.file.type ==='application/pdf';
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
