<template>
  <div class="main">
    <b-container fluid class="containerMargins">
        <b-row >
          <b-col align-self="center" class="mt-4">
            <div>
                <adjustmentsTable></adjustmentsTable> 
            </div>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>
<script>
import adjustmentsTable from '@/views/components/Payroll/tables/payrollAdjustmentsAndCorrections';
export default {
  name: '',
  components: { adjustmentsTable },
  mounted(){
    let title = 'Payroll Adjustments & Corrections';
    this.$emit('set-title',title);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
