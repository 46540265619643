<template>
    <div class="h-full flex">
        <div class="bg-white w-1/3 m-auto p-3 rounded-lg shadow-md">
            <form v-if="showCard" class="font-semibold flex flex-col" @submit="submitButton">
                <div class="mt-2">
                    <label>Select an employee to assign this device:</label>
                    <div class="w-full">
                        <multiselect v-model="benefactor" @input="employeeSeleted" :custom-label="customFormat" :options="employeeList" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Select an employee" label="EmpID" track-by="EmpID" ></multiselect>
                    </div>
                    <div class="flex flex-col my-4">
                        <span class="text-xl font-bold flex text-center justify-content-center">
                            Employee Info 
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                        </span>
                        <div class="flex text-center justify-content-center p-2 m-auto">
                            <div class="flex flex-col">
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Campaign: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.benefactor}">{{this.campaign || 'Not found'}}</span>
                                </span>
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    EmpID: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.benefactor}">{{this.empid || 'Not found'}}</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col my-4">
                        <span class="text-xl font-bold flex text-center justify-content-center">
                            Use Location
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                            </svg>
                        </span>
                        <div class="flex text-center justify-content-center p-2 m-auto">
                            <div class="inline relative">
                                <select name="use_type" id="use_type" v-model="use_type"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.use_type.$error) && ( use_type === null ),
                                'border-blue-300': !((submitted && $v.use_type.$error) && ( use_type === null )) }">
                                    <option value="on-site">On-Site</option>
                                    <option value="off-site">Off-Site</option>
                                </select>
                                <span class="flex flex-row text-xs text-gray-600 mt-1 text-center font-light"><span class="text-red-700">*</span> Specify if this equipment will be used outside or within Centris facilities</span>
                                <span v-if="submitted && !$v.use_type.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col" v-if="isOffSite">
                        <label class="text-left">Upload a file:</label>
                        <div class="w-full">
                            <drag-and-drop @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
                            <span class="text-xs text-gray-600 mt-1 text-left font-light">
                                <span class="text-red-700">*</span>
                                This file is mandatory. File size limit: 3 MB. 
                            </span>
                        </div>
                    </div>
                </div>      
                
                <div class="flex flex-col mb-2">
                    <button type="submit"
                    :disabled="loading || this.benefactor === null || (isOffSite && this.proof === null)"
                    :class="{ 'bg-gray-300 pointer-default' : (this.benefactor === null || (isOffSite && this.proof === null)), 
                    'bg-gray-700' : !(this.benefactor === null || (isOffSite && this.proof === null)) }"
                    class="px-3 py-2 rounded-sm shadow-faded text-white uppercase text-sm">
                        <span v-if="loading">
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                        </span>
                        <span v-else>
                            Change Status
                        </span>
                    </button>
                    <span :hidden="this.benefactor !== null" class="text-xs font-light italic text-gray-700 mt-2"><span class="text-red-300">*</span>Select a benefactor first</span>
                </div>

            </form>
            <div v-else class="flex justify-content-center flex-col my-auto">
                We are loading the required information. Please wait
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import Multiselect  from 'vue-multiselect';
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop';
export default {
    components: {
        Multiselect,
        DragAndDrop
    },
    data() {
       return{
            showCard: false,
            submitted: false,
            loading: false,
            modalResetKey: 0,
            itemId: null,
            benefactor: null,
            use_type: 'on-site',
            campaign: 'Select an employee first',
            empid: 'Select an employee first',
            name: null,
            lastname: null,
            fullname: null,
            proof: null,
            obj: null,
       } 
    },
    validations() {
        if(this.isOffSite){
            return {
                benefactor:    { required },
                proof:         { required },
                use_type:      { required }
            }
        }else{
            return {
                benefactor:    { required },
                use_type:      { required }
            }
        }
    },
    props:{ 
        itemData: { 
            type:Object,
        }
    },
  methods: {
        ...mapActions(['assignItem', 'fetchAllEmployees', 'uploadImgInventory']),
        setFileName(file){
            this.proof = file;
        },
        resetFileModal(){
            this.modalResetKey ++;
            this.proof = null;
        },
        customFormat({first_name, LastName1}){
            return `${first_name.toUpperCase()} ${LastName1.toUpperCase()}`;
        },
        employeeSeleted:function() {
            this.campaign = this.benefactor?.Campaign;
            this.empid = this.benefactor?.EmpID;
            this.fullname = this.benefactor?.first_name + " " + this.benefactor?.LastName1 + " " + this.benefactor?.LastName2;
        },
        submitButton(){
        event.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.obj = {
            id: this.itemId,
            benefactor: this.benefactor?.EmpID,
            use_type: this.use_type
        };
        this.$confirm("You're about to assign this item to "+this.fullname, "Do you want to continue?", "warning").then((result) => {
          if (result){
            this.loading = true;
            this.submitted = true;
            // console.log(Obj)
            this.uploadImage().then(() => {
                this.assignItem(this.obj).then(()=>{
                    this.$fire({
                        type:'success',
                        title:'Item assigned successfully.',
                        showConfirmButton:false,
                        timer: 1500
                    });
                    this.$router.push({name: 'inventory.search'});
                })
                .catch((error)=>{
                    let message =  error.response.data.message ?? 'Something went wrong';
                    let indexEnd = message.indexOf('(SQL:');
                    let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                    this.$fire({
                        type: 'warning',
                        title: 'Something went wrong',
                        text:`${substringed}`,
                        showConfirmButton: true,                
                    });
                    this.loading = false;
                })
            })
          }
        })
        },
        uploadImage: async function(){
            if(this.proof instanceof File){
                try{
                    let form = new FormData()
                    form.append("image", this.proof);
                    const response = await this.uploadImgInventory(form);
                    this.obj.proof_id = response.data.id;
                    return response.data.id;
                }catch(error){
                    return this.$fire({
                        type: "error",
                        title: "Something went wrong with the file provided",
                        text: "Please try again with a different file type or report this issue to an administrator",
                        showConfirmButton: true,
                    });
                }
            }
        }
    },
    created(){
        this.itemId = this.$route.params.id;
        this.fetchAllEmployees().then(() => {
            this.showCard = true;
        })
    },
    computed:{
        ...mapGetters(['employeeList',]),
        isOffSite(){
            return this.use_type === 'off-site'
        }
    },
    mounted(){
        let title = 'Inventory: Assign ';
        this.$emit('set-title',title);
    },
}
</script>
<style scoped>
#serial_number { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>