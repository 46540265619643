z|                                                                                                                              <template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class=" bg-white px-2 focus:text-gray-600 active:text-gray-600" v-model="selectedStatus">  
          <optgroup label = "Select a status">
            <option hidden value="0">Choose a status</option>
            <option value='In_revision'>In review</option> 
            <option value='cancelled'>Cancelled</option>
            <opTION value='completed'>Completed</option>    
            <option value='Rejected'>Rejected</option>    
          </optgroup>
        </select>
      </div>
</template>

<script>
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            selectedStatus : 0
        }
    },
    watch:{
      selectedStatus: Debounce(function(){
          this.$emit('toggle',{
              key:'status',
              value :this.selectedStatus
          });
      },200)     

    }
}
</script>
