<template>
    <div class="w-full mx-auto">
        <div class="flex justify-content-end mb-2 mx-4">
            <div class="text-center justify-content-center">
                <button :disabled="this.getCountApprovedDays && this.getCountApprovedDays.opened === '0'" 
                    class="text-xs p-2 px-3 rounded-full text-white mx-auto uppercase w-30" @click="closeWholeWeek"
                    :class="{'cursor-default bg-blue-200': this.getCountApprovedDays && this.getCountApprovedDays.opened === '0',
                    'bg-blue-500 hover:bg-blue-600': this.getCountApprovedDays && this.getCountApprovedDays.opened !== '0'}">
                Close week selected
                </button>
                <br>
                <span :class="{'opacity-0' : this.getCountApprovedDays && this.getCountApprovedDays.opened !== '0' }"
                    class="text-xs text-gray-600">
                    <span class="text-red-600">*</span> The day has been already closed
                </span>
            </div>

            <div class="text-center justify-content-center">
                <button :disabled="this.getCountApprovedDays && this.getCountApprovedDays.closed === '0'" 
                    class="text-xs p-2 px-3 rounded-full text-white mx-auto uppercase w-30" @click="openWholeWeek"
                    :class="{'cursor-default bg-blue-200': this.getCountApprovedDays && this.getCountApprovedDays.closed === '0',
                    'bg-blue-500 hover:bg-blue-600': this.getCountApprovedDays && this.getCountApprovedDays.closed !== '0'}">
                Open week selected
                </button>
                <br>
                <span :class="{'opacity-0' : this.getCountApprovedDays && this.getCountApprovedDays.closed !== '0'}"
                    class="text-xs text-gray-600">
                    <span class="text-red-600">*</span> The day has been already opened
                </span>
            </div>
        </div>
        <table class="md:max-w-5xl sm:max-w-5 mx-auto table-auto divide-y divide-blue-50">
            <thead class="">
                <tr class=" bg-transparent">
                    <th class="py-2 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col"></th>
                    <th class="py-2 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col"></th>
                    <!-- <th class="py-2 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col"></th> -->

                    <th v-if="getAction0()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(1)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10 " @click="openDate(1)">OPEN DATE</button></th>

                    <th v-if="getAction1()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10" @click="closeDate(2)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10" @click="openDate(2)">OPEN DATE</button></th>

                    <th v-if="getAction2()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(3)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10" @click="openDate(3)">OPEN DATE</button></th>

                    <th v-if="getAction3()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(4)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10" @click="openDate(4)">OPEN DATE</button></th>

                    <th v-if="getAction4()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(5)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10 " @click="openDate(5)">OPEN DATE</button></th>

                    <th v-if="getAction5()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(6)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10 " @click="openDate(6)">OPEN DATE</button></th>

                    <th v-if="getAction6()" class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto h-10 " @click="closeDate(7)">CLOSE DATE</button></th>
                    <th v-else class="py-2 text-center" scope="col"><button class="text-xs tracking-wider px-2 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto h-10 " @click="openDate(7)">OPEN DATE</button></th>

                    <th class="py-2 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col"></th>
                </tr>
                <tr class="bg-gray-50">
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Location</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Campaign</th>
                    <!-- <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">LOB</th> -->
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Sunday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Monday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Tuesday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider w-1/2" scope="col">Wednesday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Thursday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Friday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col">Saturday</th>
                    <th class="px-4 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider" scope="col"><svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg></th>
                </tr>
            </thead>
            <tbody v-if="getLoadingPayroll">
                <tr>
                    <td class="px-6 py-3 text-center text-xs font-bolder text-gray-700 uppercase tracking-wider" colspan="11">
                        <div class="flex text-center justify-content-center">
                            <span><svg class="w-10 h-10 ml-2 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="this.getApprovedDays && this.getApprovedDays.length > 0">
                <tr :id="index" v-for="(currentRow, index) in this.getApprovedDays" :key="index">
                    <td class="text-md text-gray-700 text-center text-xs text-capitalize py-2 border-b-2 border-gray-200">{{currentRow.Location}}</td>
                    <td class="text-md text-gray-700 text-center text-xs text-capitalize py-2 border-b-2 border-gray-200">{{currentRow.Campaign}}</td>
                    <!-- <td class="text-md text-gray-700 text-center text-xs text-capitalize py-2 border-b-2 border-gray-200">{{currentRow.LOB}}</td> -->
                    <td class="py-2 border-b-2 border-gray-200">
                        <span v-if="currentRow.Sunday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Sunday" @click="changeDay(currentRow.WeekJson, 1)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Sunday === 'open', 
                        'bg-red-100' : currentRow.Sunday === 'closed'}">
                            {{currentRow.Sunday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Monday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Monday" @click="changeDay(currentRow.WeekJson, 2)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Monday === 'open', 
                        'bg-red-100' : currentRow.Monday === 'closed'}">
                            {{currentRow.Monday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Tuesday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Tuesday" @click="changeDay(currentRow.WeekJson, 3)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Tuesday === 'open', 
                        'bg-red-100' : currentRow.Tuesday === 'closed'}">
                            {{currentRow.Tuesday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Wednesday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Wednesday" @click="changeDay(currentRow.WeekJson, 4)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Wednesday === 'open', 
                        'bg-red-100' : currentRow.Wednesday === 'closed'}">
                            {{currentRow.Wednesday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Thursday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Thursday" @click="changeDay(currentRow.WeekJson, 5)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Thursday === 'open', 
                        'bg-red-100' : currentRow.Thursday === 'closed'}">
                            {{currentRow.Thursday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Friday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Friday" @click="changeDay(currentRow.WeekJson, 6)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Friday === 'open', 
                        'bg-red-100' : currentRow.Friday === 'closed'}">
                            {{currentRow.Friday}}
                        </button>
                    </td>
                    <td class="py-2 border-b-2 border-gray-100">
                        <span v-if="currentRow.Saturday === null" class="text-xs text-gray-700 text-center text-uppercase">Not found</span>
                        <button v-else :hidden="!currentRow.Saturday" @click="changeDay(currentRow.WeekJson, 7)" class="text-xs text-md text-gray-700 text-center text-uppercase p-2 hover:bg-blue-300 rounded-full mx-1"
                        :class="{'bg-green-100' : currentRow.Saturday === 'open', 
                        'bg-red-100' : currentRow.Saturday === 'closed'}">
                            {{currentRow.Saturday}}
                        </button>
                    </td>
                    <td class="text-md text-gray-700 text-center text-capitalize p-2 border-b-2 border-gray-200">
                        <button v-if="currentRow.OpenDays < 4 || currentRow.OpenDays === '0'" class="text-xs uppercase p-2 px-3 bg-blue-500 hover:bg-blue-600 rounded-xl text-white mx-auto" @click="openWeek(currentRow.WeekJson, currentRow.Campaign, currentRow.Location, currentRow.WeekOfYear)">
                            Open Week
                        </button>
                        <button v-else class="text-xs uppercase p-2 px-3 bg-gray-500 hover:bg-gray-600 rounded-xl text-white mx-auto" @click="closeWeek(currentRow.WeekJson, currentRow.Campaign, currentRow.Location, currentRow.WeekOfYear)">
                            Close Week
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td class="px-6 py-3 text-center text-xs font-bolder text-gray-700 uppercase tracking-wider" colspan="11">
                        No records found.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            getAction0(){
                return this.getApprovedDow[0]?.action === 'close'
            },
            getAction1(){
                return this.getApprovedDow[1]?.action === 'close'
            },
            getAction2(){
                return this.getApprovedDow[2]?.action === 'close'
            },
            getAction3(){
                return this.getApprovedDow[3]?.action === 'close'
            },
            getAction4(){
                return this.getApprovedDow[4]?.action === 'close'
            },
            getAction5(){
                return this.getApprovedDow[5]?.action === 'close'
            },
            getAction6(){
                return this.getApprovedDow[6]?.action === 'close'
            },
        }
    },
    props:{
        weekData: Object,
    },
    components: { },
    computed: {
        ...mapState(['payroll']),
        ...mapGetters(['getApprovedDays', 'getCountApprovedDays', 'getApprovedDow', 'getLoadingPayroll']),
    },
    created() {
    },
    methods: {
        ...mapActions(['updateCloseDay', 'updateOpenDay',
        'updateCloseThisWeek', 'updateOpenThisWeek',
        'updateCloseWeek', 'updateOpenWeek', 
        'updateCloseDate', 'updateOpenDate']),
        getWeekNumber: function(d) {
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
            return [d.getUTCFullYear(), weekNo];
        },
        closeWeek: function(data, campaign, location, week) {
            let array = JSON.parse(data);            
            this.$fire({
            title: "Do you want to continue?",
            type: "question",
            html:"You're about to <strong>CLOSE</strong> the open days for <br>Week: <strong>"
            +week
            +"<br></strong>Campaign: <strong>"
            +campaign
            +"</strong>"
            +"<br></strong>Site: <strong>"
            +location
            +"</strong>"
            ,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText:
                '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
            cancelButtonText:
                '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((result) => {
            if (result?.value){
                this.updateCloseThisWeek(array).then(()=>{
                    this.$fire({
                        type: "success",
                        title: "Week has been closed successfully",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(()=>{
                        this.$emit('toggle', true);
                    });
                }).catch(()=>{
                    this.$fire({
                        type: "error",
                        title: "Something went wrong",
                        text: "Seems all days are already closed, if this is an error, please try again or report to the system administrator",
                        showConfirmButton: true,
                    })
                })
            }
            })
        },
        openWeek: function(data, campaign, site, week) {
            let array = JSON.parse(data);
            this.$fire({
            title: "Do you want to continue?",
            type: "question",
            html:"You're about to <strong>OPEN</strong> the closed days for <br>Week: <strong>"
            +week
            +"<br></strong>Campaign: <strong>"
            +campaign
            +"</strong>"
            +"<br></strong>Site: <strong>"
            +site
            +"</strong>"
            ,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText:
                '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
            cancelButtonText:
                '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((result) => {
            if (result?.value){
                this.updateOpenThisWeek(array).then(()=>{
                    this.$fire({
                        type: "success",
                        title: "Week has been opened successfully",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(()=>{
                        this.$emit('toggle', true);
                    });
                }).catch(()=>{
                    this.$fire({
                        type: "error",
                        title: "Something went wrong",
                        text: "Seems all days are already closed, if this is an error, please try again or report to the system administrator",
                        showConfirmButton: true,
                    })
                })
            }
            })
        },
        changeDay: function(data, day){
            var array = JSON.parse(data).filter(obj => {
            return obj.dayofweek === day
            })
            if(array[0].status === "open"){
                this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>CLOSE</strong> day <br>"
                    +"<strong>"
                    +array[0].dayname
                    +", "
                    +array[0].date
                    +"</strong><br>"
                    +"Campaign: "
                    +"<strong>"
                    +array[0].campaign
                    +"</strong>"
                    +" from "
                    +"<strong>"
                    +array[0].location
                    +"</strong><br><br>"
                    ,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText:
                    '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText:
                    '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
                }).then((isConfirm) => {
                    if(isConfirm?.value){
                        this.updateCloseDay(array).then(()=>{
                            this.$fire({
                                type: "success",
                                title: "Day has been closed successfully",
                                showConfirmButton: false,
                                timer: 1300,
                            }).then(()=>{
                                this.$emit('toggle', true);
                            });
                        }).catch(()=>{
                            this.$fire({
                                type: "error",
                                title: "Something went wrong",
                                text: "Please try again, or report to the system administrator",
                                showConfirmButton: true,
                            })
                        })
                    }
                    return false;
                })
            }else{
                this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>OPEN</strong> day <br>"
                    +"<strong>"
                    +array[0].dayname
                    +", "
                    +array[0].date
                    +"</strong><br>"
                    +"Campaign: "
                    +"<strong>"
                    +array[0].campaign
                    +"</strong><br><br>"
                    +`<table class='border-2 text-center justify-content-center mx-auto table-auto divide-y divide-blue-50'>
                        <thead>
                            <tr>
                                <th class='text-center justify-content-center font-semibold'>Day</th>
                                <th class='text-center justify-content-center font-semibold'>Approved By AM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='uppercase text-xs p-1'>`+array[0].dayname+`</td>
                                <td class='uppercase text-xs p-1'>`+array[0].approved+`</td>
                            </tr>
                        </tbody>
                    </table>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText:
                    '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText:
                    '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
                }).then((isConfirm) => {
                    if(isConfirm?.value){
                        this.updateOpenDay(array).then(()=>{
                            this.$fire({
                                type: "success",
                                title: "Day has been open successfully",
                                showConfirmButton: false,
                                timer: 1300,
                            }).then(()=>{
                                this.$emit('toggle', true);
                            });
                        }).catch(()=>{
                            this.$fire({
                                type: "error",
                                title: "Something went wrong",
                                text: "Please try again, or report to the system administrator",
                                showConfirmButton: true,
                            })
                        })
                    }
                    return false;
                })
            }
        },
        closeDate: function(day){
            var array = this.getApprovedDow.filter(obj => {
                return obj.dayofweek === day
            });
            if (array[0]?.dayname === undefined) return;
            this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>CLOSE</strong> this day <br>"
                    +"<strong>"
                    +array[0].dayname
                    +", "
                    +array[0].date
                    +"</strong><br>"
                    +" for all the Campaigns"
                    +"<br><br>"
                    +`<table class='border-2 text-center justify-content-center mx-auto table-auto divide-y divide-blue-50'>
                        <thead>
                            <tr>
                                <th class='text-center justify-content-center font-semibold'>Approved Days</th>
                                <th class='text-center justify-content-center font-semibold'>Non Approved Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='uppercase text-xs p-1'>`+array[0].approved+`</td>
                                <td class='uppercase text-xs p-1'>`+array[0].non_approved+`</td>
                            </tr>
                        </tbody>
                    </table>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((res) => {
                if(res?.value){
                    this.updateCloseDate(array[0]).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Date has been closed successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                            this.$emit('toggle', true);
                        });
                    }).catch(()=>{
                        this.$fire({
                            type: "error",
                            title: "Something went wrong",
                            text: "Please try again, or report to the system administrator",
                            showConfirmButton: true,
                        })
                    })
                }
            })
        },
        openDate: function(day){
            var array = this.getApprovedDow.filter(obj => {
                return obj.dayofweek === day
            });
            this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>OPEN</strong> this day <br>"
                    +"<strong>"
                    +array[0].dayname
                    +", "
                    +array[0].date
                    +"</strong><br>"
                    +" for all the Campaigns"
                    +"<br><br>"
                    +`<table class='border-2 text-center justify-content-center mx-auto table-auto divide-y divide-blue-50'>
                        <thead>
                            <tr>
                                <th class='text-center justify-content-center font-semibold'>Approved Days</th>
                                <th class='text-center justify-content-center font-semibold'>Non Approved Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='uppercase text-xs p-1'>`+array[0].approved+`</td>
                                <td class='uppercase text-xs p-1'>`+array[0].non_approved+`</td>
                            </tr>
                        </tbody>
                    </table>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((res) => {
                if(res?.value){
                    this.updateOpenDate(array[0]).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Date has been opened successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                            this.$emit('toggle', true);
                        });
                    }).catch(()=>{
                        this.$fire({
                            type: "error",
                            title: "Something went wrong",
                            text: "Please try again, or report to the system administrator",
                            showConfirmButton: true,
                        })
                    })
                }
            })
        },
        closeWholeWeek: function(){
            let objForm = new Object();
            Object.assign(objForm, this.weekData)
            
            let result = this.getWeekNumber(new Date());
            if(objForm?.weekSelected === null){
                objForm.weekSelected = result[1] > 1 ? result[1] - 1 : result[1];
            }
            this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>CLOSE</strong><br>"
                    +"Week: "
                    +"<strong>"
                    +objForm?.weekSelected
                    +"</strong><br><br>"
                    +`<table class='border-2 text-center justify-content-center mx-auto table-auto divide-y divide-blue-50'>
                        <thead>
                            <tr>
                                <th class='text-center justify-content-center font-semibold'>Approved Days</th>
                                <th class='text-center justify-content-center font-semibold'>Non Approved Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='uppercase text-xs p-1'>`+this.getCountApprovedDays?.approved+`</td>
                                <td class='uppercase text-xs p-1'>`+this.getCountApprovedDays?.non_approved+`</td>
                            </tr>
                        </tbody>
                    </table>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((result) => {
                if(result?.value){
                    this.updateCloseWeek(objForm).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Week has been closed successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                            this.$emit('toggle', true);
                        });
                    }).catch(()=>{
                        this.$fire({
                            type: "error",
                            title: "Something went wrong",
                            text: "Please try again, or report to the system administrator",
                            showConfirmButton: true,
                        })
                    })
                }
            })
        },
        openWholeWeek: function(){
            let objForm = new Object();
            Object.assign(objForm, this.weekData)
            
            let result = this.getWeekNumber(new Date());
            if(objForm?.weekSelected === null){
                objForm.weekSelected = result[1] > 1 ? result[1] - 1 : result[1];
            }
            this.$fire({
                title: '<strong>Do you want to continue?</strong>',
                type: 'question',
                html:
                    "You're about to <strong>OPEN</strong><br>"
                    +"Week: "
                    +"<strong>"
                    +objForm?.weekSelected
                    +"</strong><br><br>"
                    +`<table class='border-2 text-center justify-content-center mx-auto table-auto divide-y divide-blue-50'>
                        <thead>
                            <tr>
                                <th class='text-center justify-content-center font-semibold'>Approved Days</th>
                                <th class='text-center justify-content-center font-semibold'>Non Approved Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class='uppercase text-xs p-1'>`+this.getCountApprovedDays?.approved+`</td>
                                <td class='uppercase text-xs p-1'>`+this.getCountApprovedDays?.non_approved+`</td>
                            </tr>
                        </tbody>
                    </table>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
                cancelButtonText: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
            }).then((result) => {
                if(result?.value){
                    this.updateOpenWeek(objForm).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Week has been opened successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                            this.$emit('toggle', true);
                        });
                    }).catch(()=>{
                        this.$fire({
                            type: "error",
                            title: "Something went wrong",
                            text: "Please try again, or report to the system administrator",
                            showConfirmButton: true,
                        })
                    })
                }
            })
        },
    }
}
</script>
<style scoped>
tbody {
    color: white;
    background-color: #ffffff !important
}
</style>