<template>
    <div class="p-3 mx-3 rounded-xl h-full bg-white">
        <div class="my-4">
            <div class="flex flex-col text-left p-2">
                <label class="text-xl font-bold flex text-center justify-content-center">This device is already assigned</label>
                <span class="flex">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd"></path></svg>
                    Device ID: {{this.deviceId || 'Not found'}}
                </span>
                <span class="flex">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd"></path></svg>
                    Status: {{this.deviceStatus || 'Not found'}}
                </span>
                <span class="flex">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                    Assigned At: {{this.deviceAt || 'Not found'}}
                </span>
                <span class="flex">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                    Assigned To: {{this.deviceEmp || 'Not found'}}
                </span>
                <span class="flex">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                    Custodian: {{this.deviceCustodian || 'Not found'}}
                </span>
            </div>
            <button class="mx-5 bg-gray-600 hover:bg-gray-700 text-white text-sm mt-2 p-2 px-3 rounded-md" @click="dispose">
                Release
            </button>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';

export default {
    components: {
    },
    data() {
        return {
            campaign: null,
            empid: null,
            name: null,
            lastname: null
        }
    },
    computed:{
        ...mapGetters(['getDeviceInfo',]),

        deviceStatus: function() {
            return this.getDeviceInfo?.status?.toUpperCase()
        },
        deviceId: function() {
            let assignedInfo = this.getDeviceInfo?.assigned_to;
            return assignedInfo?.deviceId
        },
        deviceAt: function() {
            let assignedInfo = this.getDeviceInfo?.assigned_to;
            return assignedInfo?.createdAt
        },
        deviceEmp: function() {
            let assignedInfo = this.getDeviceInfo?.assigned_to;
            return assignedInfo?.employeeId?.toUpperCase()
        },
        deviceCustodian: function() {
            let assignedInfo = this.getDeviceInfo?.assigned_to;
            return assignedInfo?.custodianId?.toUpperCase()
        },
    },
    methods: {
        ...mapActions(['checkoutDevice']),

        hide(){
            this.$emit('closeAssign', true);
        },
        customFormat({first_name, LastName1}){
            return `${first_name.toUpperCase()} ${LastName1.toUpperCase()}`;
        },
        dispose: function() {
            this.$confirm("", "Are you sure you want to return device with ID "+this.deviceId+"?", "warning").then((result) => {
                if (result){               
                    let objectData = {
                        deviceId: this.deviceId,
                        empId: this.deviceEmp
                    };
                    this.checkoutDevice(objectData).then((res) =>{
                        this.$fire({
                            type: 'success',
                            title: res.message,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            this.hide();
                        });
                    }).catch(() => {
                        this.$fire({
                            type: 'error',
                            title: "Something went wrong",
                            text: "Please report to a system administrator",
                            showConfirmButton: true,
                        })
                    })
                }
            })
        }
    }
}
</script>