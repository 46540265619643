<template>  
    <div class="EditInformationModalContainer">

        <div v-if="isLoading" class="row">
            <div class="col text-center">
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <div v-else>

            <b-form v-if="showSurvey" @submit="sendTheAnswers">
                <!-- Title and description of the survey -->
                <div class="text-center justify-center items-center d-block bg-gray-100">
                    <h2 class="font-sans text-4xl tracking-wide font-bold text-gray-700">{{getSurveyInfo.Title}}</h2>
                    <label class="text-gray-700 font-semibold text-md mr-2">{{getSurveyInfo.Description}}</label>
                </div>
                
                <!-- Agent information -->
                <div class="flex flex-1 text-center justify-center items-center d-block mt-2 mb-4">
                    <div class="text-gray-700">Name: {{profileInfo.FirstName + " " + profileInfo.LastName1}}</div>
                    <div class="text-gray-700">Campaign: {{this.profileInfo.Campaign}} - {{this.profileInfo.Location}}</div>
                    <div class="text-gray-700">Supervisor: {{this.profileInfo.ReportTo}}</div>
                </div>
                
                <!-- questions here -->
                <div v-for="(question, Qindex) in getQuestionsInfo" :key="Qindex" :id="question.id" class="my-3 align-items-center justify-content-start ml-4 mr-4">

                    <div class="mb-1" :id="'question'+Qindex">
                        {{question.Question_number}}. {{question.Question_name}}
                    </div>
                    <!-- Text -->
                    <div v-if="question.Answer_type == 'text'" class="flex flex-col">
                        <input type="text" @change="editTextAnswer(Qindex, $event)" :id="'answer'+Qindex" value="" placeholder="Type your answer..." 
                        class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700 focus:placeholder-gray-700 h-8 mb-1" maxlength="500">
                    </div>
                    <!-- Dropdown -->
                    <div v-if="question.Answer_type == 'dropdown'" class="flex flex-col">
                        <select @change="editDropAnswer(Qindex, $event)" :id="'answer'+Qindex"
                        class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1">
                            <optgroup label="Select an answer...">                
                                <option hidden value="0">Select an answer...</option>
                                <option v-for="(Answer, index) in question.question_options" :value="Answer.id" :key="index" @change="editAnswerValue(Qindex, index)">{{Answer.Answer}}</option>
                            </optgroup>
                        </select>
                    </div>
                    <!-- Date -->
                    <div v-if="question.Answer_type == 'date'" class="flex flex-col">
                        <input type="date" @change="editDateAnswer(Qindex, $event)" :id="'answer'+Qindex" class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700 focus:placeholder-gray-700">
                    </div>
                    <!-- Time -->
                    <div v-if="question.Answer_type == 'time'" class="flex flex-col">
                        <input type="time" @change="editDateAnswer(Qindex, $event)" :id="'answer'+Qindex" class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-indigo-700 focus:placeholder-gray-700">
                    </div>
                    <!-- Checkbox -->
                    <div v-if="question.Answer_type == 'multiple'">
                        <div class="grid grid-cols-3 gap-2">
                            <div v-for="(Answer, index) in question.question_options" :value="Answer.id" :key="index" class="flex">
                                <label :for="'answer'+Qindex" class="items-center px-1 flex">
                                    <input type="checkbox" :value="Answer.Answer" @change="editCheckboxAnswer(Qindex, index, $event)"
                                    :id="'answer'+Qindex+'subAnswer'+index"
                                    class="focus:ring-blue-400 h-6 w-6 rounded">
                                <span class="flex-1 ml-2 text-gray-500">{{Answer.Answer}}</span>
                                </label>
                            </div>
                        </div>
                        <label :id="'answer'+Qindex" class="size-xs text-gray-300">Select at least one of the options</label>
                    </div>

                </div>
                <!-- ends questions -->

                <b-row class="text-center justify-center items-center mt-4 ml-4 mr-4">
                    <button type="submit" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Save
                    </button>
                </b-row>

            </b-form>

            <b-form v-else>
                <div class="col">
                    <div class="w-full flex flex-col text-center justify-center items-center">
                    <div class='max-w-lg bg-white overflow-hidden mx-auto'>
                        <div class="py-4 px-8 mb-2">

                            <div class="flex flex-col mb-2">
                                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10 mb-2">
                                    <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                </div>
                                <h3 class="text-gray-700 font-semibold mb-2">Oops!</h3>
                                <h2 class="text-gray-700 font-semibold text-2xl tracking-wide mb-2">It seems you actually solved this survey</h2>
                            </div>

                            <div class="bg-gray-100 rounded-lg">
                                <div class="py-4 px-4">
                                    <h4 class="text-lg font-semibold">Please, try again when requested</h4>
                                </div>
                            </div>

                            <div class="py-4">
                                <a href="#" @click="closeModal()" class="block tracking-widest uppercase text-center shadow bg-green-600 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded">Close survey</a>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>
            </b-form>

        </div>

    </div>  
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";
// import Datepicker from "vuejs-datepicker";
export default {
    components: { },
    data(){
        return{
            striped:            true,
            animate:            true,
            percentage:         0,
            isHide:             false,
            callbacks:          0,
            isLoading:          true,
            showSurvey:         true,
            ReminingDays:       0,
            displayTitle:       false,
            displayLob:         false,
            displayReportTo:    false,
            survey_answers: [],
        }
    },
    computed:{
        ...mapState([
            "surveys"
        ]),
        ...mapGetters([
            "getSurveyInfo",
            "getSurveyStatus",
            "getQuestionsInfo",
            "getQuestionsArray"
        ]),
    },
    props:{
        profileInfo: Object,
        surveySelected: Number
    },
    methods:{
        ...mapActions([
            "fetchSurveyStatus",
            "fetchSurveyInfo",
            "fetchQuestionsInfo",
            "fetchQuestionsArray",
            "sendSurveyAnswers",
        ]),

        loadSurveyInformation(){
            let survey_id = this.surveySelected
            this.fetchQuestionsInfo(survey_id);
            this.fetchSurveyInfo(survey_id);
            this.fetchQuestionsArray(survey_id).then((res) => {
                this.survey_answers = res;
                this.isLoading  = false;
            }, error => {
                console.error("Error fetching the projects: "+ error)
            });
        },

        customFormatter(date) {
            return moment(date).format("DD/MM/YYYY");
        },

        editDropAnswer: function(Qindex, event) {
            let target= event.target;
            let questionId = target.options[target.selectedIndex].text;

            this.survey_answers[Qindex]['answer_id'] = questionId;
        },

        editTextAnswer: function(Qindex, event) {
            let questionId = event.target.value;

            this.survey_answers[Qindex]['answer_id'] = questionId;
        },

        editDateAnswer: function(Qindex, e) {
            let questionId = e.target.value;
            this.survey_answers[Qindex]['answer_id'] = questionId;
        },

        editCheckboxAnswer: function(Qindex, index, e) {
            if(e.target.checked){
                this.survey_answers[Qindex]['answer_id'][index]['status'] = 'true';
            }else{
                this.survey_answers[Qindex]['answer_id'][index]['status'] = 'false';
            }
        },

        closeModal(){
            this.$emit('closeIt', true); 
        },

        sendTheAnswers(){
            let key = 0;
            let counter = [];
            let empty   = [];
            [].forEach.call(this.survey_answers, element => {
                if(element.answer_id === null || element.answer_id === undefined || element.answer_id === ""){
                        document.getElementById('answer'+key).classList.add('border-2', 'border-red-500')
                        counter.push({answer : element.answer_id})
                }else{
                    document.getElementById('answer'+key).classList.remove('border-2', 'border-red-500')
                }

                if(element.answer_type === 'multiple'){
                    [].forEach.call(element.answer_id, e => {
                        if(e.status === "true"){ empty.push({answer : e.value}) }
                    })

                    if(empty.length < 1){
                                document.getElementById('answer'+key).classList.add('text-red-500', 'bg-yellow-300', 'rounded-md', 'px-1')
                                counter.push({answer : element.answer_type})
                    }else{
                        document.getElementById('answer'+key).classList.remove('text-red-500', 'bg-yellow-300', 'rounded-md', 'px-1')
                    }
                }
                empty   = [];
                key++;
            });
            
            if(counter.length > 0){
                event.preventDefault();
                this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true,
                        confirmButtonColor: '#009688',
                        allowOutsideClick: false,
                        background: '#FFFFFFD9',
                    })
            }else{
                event.preventDefault();
                this.isLoading = true;
                let objData = {
                    survey_id: this.getSurveyInfo.id,
                    survey_answers: this.survey_answers,
                };
                // console.log(objData)

                this.sendSurveyAnswers(objData).then( ()=>{
                    this.$fire({
                            type: 'success',
                            title: 'Survey completed!',
                            text: "Thanks for your participation",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            this.isLoading = false;
                            this.$emit('closeIt', true);                 
                        });
                }).catch(()=>{
                    this.$fire({
                            type: 'warning',
                            title: 'Oops! Something went wrong. Please try again',
                            text: 'If the problem persist, please report this issue at the SupportHero! chat',
                            showConfirmButton: true
                        }).then(()=>{
                            this.isLoading = false;                 
                        });
                });
            }

        },

    },

    created() {
        let ObjectData = { survey_id : this.surveySelected };
        this.fetchSurveyStatus(ObjectData).then(()=>{
            this.loadSurveyInformation();
        }).catch(()=>{
            this.isLoading  = false;
            this.showSurvey = false;
        });

      var todaydate = moment();
      var daysinM = moment(todaydate, "YYYY-MM").daysInMonth()
      var daysToday = todaydate.format('DD') * 1;
      this.ReminingDays = daysinM - daysToday + 1;

    },

    updated(){
        
    },

  mounted() {
      console.log('mounted');
  }

}
</script>
<style lang="scss" scoped>
.formEdit{
    display: block !important;
}
.hide{
    display: none;
}
.hideSelectCity,
.hideLabelCity {
  display: none;
}
.invalid-feedback{
        font-weight: bold;
    }
.mystyle{
    border: 1px;
    border-color:brown;
}
</style>