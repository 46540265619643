<template>
    <div>
        <h1>Summary</h1>
        <div v-if="componentLoaded" class="align-items-center">
            <b-spinner class="ml-auto"></b-spinner>
            <span class="ml-3 font-weigth-bold">Loading Summary...</span>
        </div>
        <div v-else>
            <table class="marginSummary table-sm table table-striped mb-5">
                <thead>
                    <tr>
                        <th class="text-center" scope="col">Std_Payable_Hours</th>
                        <th class="text-center" scope="col">Grv_Payable_Hours</th>
                        <th class="text-center" scope="col">Total_Payable_Hours</th>
                        <th class="text-center" scope="col">Std_Billable_Hours</th>
                        <th class="text-center" scope="col">Grv_Billable_Hours</th>
                        <th class="text-center" scope="col">Total_Billable_Hours</th>
                    </tr>
                </thead> 
                <tbody>
                    <tr :id="'row'+index" v-for="(currentRow, index) in getLWSummary" :key="index">
                        <td class="-text-capitalize">{{currentRow.Std_Payable_Hours}}</td>
                        <td class="text-capitalize">{{currentRow.Grv_Payable_Hours}}</td>
                        <td class="text-capitalize">{{currentRow.Total_Payable_Hours}}</td>
                        <td class="text-capitalize">{{currentRow.Std_Billable_Hours}}</td>
                        <td class="text-capitalize">{{currentRow.Grv_Billable_Hours}}</td>
                        <td class="text-capitalize">{{currentRow.Total_Billable_Hours}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            componentLoaded: true,
        }
    },
    props:{
        queryInfo: {
            type: String,
            required: true,
        },
    },
    computed:{
        ...mapState(['AccountManagerClocker']),
        ...mapGetters(['getLWSummary']),
    },
    methods:{
         ...mapActions(['fetchLastWeekSummary']),
    },
    created(){
        var objectData = JSON.parse(this.queryInfo);
        this.fetchLastWeekSummary(objectData).then(()=>{
            this.componentLoaded = false;
        });
    },
}
</script>
<style lang="stylus" scoped>

</style>