<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<div class="flex md:w-full pb-3 sm:w-full mx-auto relative bg-gray-200">    
    <div class="relative w-full px-3">            
        <div class="flex my-2 px-3">
            <h4 class="text-left text-gray-500 w-full text-sm border-t border-gray-200 my-auto">Active Adjustment Requests 
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </h4>
            <vs-tooltip v-if="getPayrollRequests.length > 0" text="Cancel Request(s)" position="left" class="ml-auto">
                <button @click="cancelRequests()" class="text-center p-2 rounded-lg uppercase text-xs text-white bg-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                </button>
            </vs-tooltip>
        </div>

        <table class="min-w-full divide-y divide-gray-50 bg-gray-100">
            <thead class="bg-gray-100">
            <tr>              
                <th scope="col" class="px-6 py-4 text-md text-left text-xs font-semibold text-gray-900 uppercase tracking-wider ">
                    ID
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider ">
                Requested By
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                Event
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                Reason
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider ">
                Standard
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider ">
                Graveyard
                </th>
                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider ">
                Duration
                </th> -->
                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                    Status
                </th>        -->
            </tr>
            </thead>
            <tbody class="bg-gray-100 divide-y divide-gray-100" v-if="getPayrollRequests.length > 0">
                <tr v-for="(item,index) in getPayrollRequests" :key="index"  class="border-t border-gray-200">
                    <td class="px-6 py-4 whitespace-nowrap text-md text-left text-gray-700">
                    {{item.Request_id}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left uppercase">
                    {{item.RequestedBy}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left uppercase">
                    {{item.Event_type}}
                    </td>               
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left uppercase">
                    {{item.Reason}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left">
                        {{toTime(item.Std)}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left">
                        {{toTime(item.Graveyard)}}
                    </td>  
                    <!-- <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left">
                        {{item.Duration.substring(0,5)}}
                    </td>  -->
                    <!-- <td class="px-6 py-4 whitespace-nowrap text-md text-gray-700 text-left">
                        {{item.RequestStatus}}
                    </td>             -->
                </tr>

            <!-- More items... -->
            </tbody>
            <tbody class="bg-gray-100 divide-y divide-gray-100" v-else>
                <tr class="border-t border-gray-200">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                        No Data.
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"></td>
                </tr>

            </tbody>
        </table>
    </div>
</div>

</template>

<script>
import {mapGetters,mapActions} from 'vuex';
export default {
    computed:{
        ...mapGetters(['getPayrollRequests'])
    },
    props:{
        empId:String,
        date:String,
        approvalId: Number
    },
    methods:{
        ...mapActions(['getPayrollRequestsByDate', 'cancelPayrollRequestsByDate']),
        fetchRequests(){           
            this.getPayrollRequestsByDate({
                empId:this.empId,
                date:this.date
            }).then(()=>{
                this.$emit('pending_requests');
            })
        },
        toTime: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = new Date(Math.abs(t) * 1000).toISOString().substr(11, 5)
            return sign+time;
        },
        cancelRequests: function(){
            this.$confirm("This action will cancel the requests bellow", "Do you want to proceed?", "question").then((result) => {
                if (result){                
                    this.cancelPayrollRequestsByDate({
                        empId:this.empId,
                        date:this.date,
                        approvalId: this.approvalId
                    }).then(()=>{
                        this.$fire({
                            type: 'success',
                            title: 'Requests canceled successfully',
                            timer: 5000
                        })
                        this.$emit('rerender')
                    }).catch(()=>{
                        this.$fire({
                            type: 'error',
                            title: 'Something went wrong',
                            text: 'Please try again or report this error to a system administrator',
                            showConfirmButton: true
                        })
                    })
                }
            })
        }
    },
    beforeMount(){
        this.fetchRequests();
    },
}
</script>