<template>
    <div class="main">
        <div class="flex mb-3">
            <div class="flex flex-row space-x-4 ml-auto">
                <button :disable="!availablePrevious" @click="previousWeek" class="flex p-2 px-4 rounded-lg text-white" :class="{ 'bg-gray-300' : !availablePrevious, 'bg-gray-600' : availablePrevious }">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="my-auto uppercase font-sans font-semibold text-sm">Previous Week</span>
                </button>

                <button :disable="!availableNext" @click="nextWeek" class="flex p-2 px-4 rounded-lg text-white" :class="{ 'bg-gray-300' : !availableNext, 'bg-gray-600' : availableNext }">
                    <span class="my-auto uppercase font-sans font-semibold text-sm">Next Week</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
        <table class="min-w-full table-auto bg-white divide-y divide-blue-50">
            <thead class="bg-gray-50">
                <tr>
                    <th v-for="date,index in dow" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div class="text-center">
                        <span class="font-semibold text-gray-700 text-sm">
                            {{getVisibleDates[index + index_helper]}}
                        </span>
                        <span class="block text-gray-700 font-xs font-medium mt-1 uppercase">
                            {{date}}
                        </span>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody v-if="getLoadingSchedules || loading_helper">
                <tr>
                    <td class="p-2 py-4 m-auto" v-for="x in 7" :key="x">
                        <div class="space-y-3 animate-pulse">
                            <div class="grid grid-cols-3 gap-4">
                            <div class="h-2 bg-slate-300 rounded col-span-2"></div>
                            <div class="h-2 bg-slate-300 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-slate-300 rounded"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td v-for="date,index in dow" :key="index" class="p-2 py-4 m-auto">
                        <div class="text-center space-y-4">
                            <div class="flex flex-col">
                                <span class="text-gray-700 text-xl font-bold uppercase">
                                    {{findScheduleStart(getVisibleDates[index + index_helper])}}
                                </span>
                                to
                                <span class="text-gray-700 text-xl font-bold uppercase">
                                    {{findScheduleEnd(getVisibleDates[index + index_helper])}}
                                </span>
                                <span class="text-xs font-medium tracking-wide uppercase">
                                    Schedule
                                </span>
                            </div>
                            <div class="flex flex-col">
                                <span class="text-gray-700 text-xl font-bold uppercase">
                                    {{secToTime(findPayableHours(getVisibleDates[index + index_helper]))}}
                                </span>
                                <span class="text-xs font-medium tracking-wide uppercase">
                                    Payable Hours
                                </span>
                            </div>
                            <button class="flex p-2 rounded-lg text-white uppercase font-sans font-semibold text-xs mx-auto" 
                            :disabled="beforeToday(getVisibleDates[index + index_helper])"
                            :class="{'bg-gray-600' : !beforeToday(getVisibleDates[index + index_helper]),
                            'bg-gray-300' : beforeToday(getVisibleDates[index + index_helper])}"
                            @click.prevent="$router.push({ name: 'schedules.edit.date', params: { empid: employee, date: getVisibleDates[index + index_helper] } })" >
                                Make changes
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-xs text-gray-600 text-left m-1">
            * Making changes available only for future dates
        </div>

        <div class="flex" v-if="getLoadingSchedules || loading_helper">
            <div class="text-center p-3 rounded-md bg-white mx-auto my-4">
                <span class="text-xs font-medium block text-red-500" :class="{'invisible' : !summaryOver}">
                    This weekly schedule is over 57 hours
                </span>
                <span class="h1 font-bold block" :class="{'text-red-500': summaryOver}">
                    <div class="space-y-3 animate-pulse">
                        <div class="h-2 bg-slate-300 rounded col-span-2"></div>
                        <div class="h-2 bg-slate-300 rounded"></div>
                    </div>
                </span>
                <span class="text-sm font-medium tracking-wide uppercase block">
                    <div class="space-y-3 animate-pulse">
                        <div class="h-2 bg-slate-300 rounded"></div>
                    </div>
                </span>
            </div>
        </div>
        <div class="flex" v-else>
            <div class="text-center p-3 rounded-md bg-white mx-auto my-4">
                <span class="text-xs font-medium block text-red-500" :class="{'invisible' : !summaryOver}">
                    This weekly schedule is over 57 hours
                </span>
                <span class="h1 font-bold block" :class="{'text-red-500': summaryOver}">
                    {{secToTime(summaryHours)}}
                </span>
                <span class="text-sm font-medium tracking-wide uppercase block">
                    Weekly Payable Hours
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default{
    data() {
        return {
            employee: null,
            savedByDay: [],
            pages: 1,
            current: 1,
            index_helper: 0,
            loading_helper: false,
            dow:['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
        }
    },
    computed: {
        ...mapGetters([
            'getLoadingSchedules',
            'getVisibleDates',
            'getSchedule',
            'getEmployees',
            'getSummary'
        ]),
        availablePrevious(){
            return !(this.current === 1);
        },
        availableNext(){
            return !(this.current === this.pages);
        },
        summaryHours(){
            let summary = 0;
            for(let i = this.index_helper; i < (this.index_helper + 7); i++){
                summary = summary + parseInt(this.findPayableHours(this.getVisibleDates[i]));
            }
            return summary;
        },
        summaryOver(){
            return this.summaryHours > 205200;
        },
        currentDate(){
            let cd = new Date();
            return this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getDate(), 2);
        },
    },
    methods: {
        ...mapActions([
            'fetchScheduleSummary',
            'fetchEmployeesWSchedule'
        ]),
        // helpers
        beforeToday: function(d){
            return d <= this.currentDate;
        },
        findPayableHours: function(date){
            let find = this.getSummary.find((d)=> d.date === date);
            return find?.Payable;
        },
        findScheduleStart: function(date){
            let find = this.getSummary.find((d)=> d.date === date);
            return find?.startAt?.substring(0, 5);
        },
        findScheduleEnd: function(date){
            let find = this.getSummary.find((d)=> d.date === date);
            return find?.endAt?.substring(0, 5);
        },
        secToTime(t){
            let hours = parseInt( t / 3600 );
            let minutes = parseInt( (t - (hours * 3600)) / 60 );
            let result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
            return result;
        },
        zeroPadding: function(num, digit) {
            var zero = '';
            for(var i = 0; i < digit; i++) { 
            zero += '0'; 
            }
            return (zero + num).slice(-digit);
        },
        // actions
        previousWeek(){
            if(!this.availablePrevious) return;
            this.loading_helper = true;
            this.index_helper-=7;
            this.current--;
            setTimeout(this.stopLoading, 1500)
        },
        nextWeek(){
            if(!this.availableNext) return;
            this.loading_helper = true;
            this.index_helper+=7;
            this.current++;
            setTimeout(this.stopLoading, 1500);
        },
        stopLoading(){
            this.loading_helper = false;
        },
    },
    created(){
        this.employee = this.$route.params.empID;
        this.fetchScheduleSummary(this.employee).then(()=>{
            this.pages = this.getVisibleDates.length/7;
        });
    }
}
</script>