<template>
    <div class="bg-white shadow-faded px-3 py-2">
         <div class="sm:w-full text-left mt-2">
            <label class="px-2 text-gray-500 text-xs font-thin m-0">Termination reason:*</label>
            
            <select :class="error ? errorClass: ''"  v-model="reason" class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 " >
                <option hidden value="">Select a referral...</option>
                <option v-for="itm,index in getTerminationReasons" :value="itm.id" :key="'rsn' + index">{{itm.reason}}</option>
            </select>   
            <small class="text-gray-400 text-xs ml-3" v-if="error">Select a reason for the candidate termination.</small>

            <button type="button" class="w-full px-4 py-3 bg-red-500 text-white shadow-md rounded-sm mt-2" @click="terminate">
                Terminate
            </button>
                    
        </div>
    </div>
   
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
export default {
   data(){
       return {
           candidate:'',
           reason:'',
           error:false,
           saving:false
       }
   },
   created(){
       this.candidate = this.$route.params.id
       this.fetchTerminationReasons();
   },
   computed:{
        ...mapGetters(['getTerminationReasons']), 
        errorClass:function(){
            return 'bg-red-50 border-red-200';
        },
   },
   methods:{
       ...mapActions(['fetchTerminationReasons','terminateCandidate']), 
       terminate:function()
       {
           this.saving = true;
           if(this.reason == ''){
                this.error = true;
                 this.saving = false;
                return this.$fire({
                        type: 'warning',
                        title:`Select a termination reason in order to proceed.`,
                        showConfirmButton: true,                  
                }); 
           }

           if(window.confirm('Are you sure you weant to terminate this candidate?') == false){
                this.saving = false;
               return ;
           }

           this.terminateCandidate({id:this.candidate, reason:this.reason}).then(()=>{  
               this.saving = false;
                this.$router.push({name:'recruitment.candidates'});     
            });
       }
   }
}
</script>