<template>
<div class="main">
    <div class="container-fluid">
        <div class="shadow bg-white rounded p-4 py-5 mb-5">
            <clocker-users />
        </div>
    </div>
</div>
</template>
<script>
    import ClockerUsers     from'@/views/components/Clocker/tables/usersclocker';
    export default {
        components: {ClockerUsers},
      mounted(){
        let title = 'Clocker Management';
        this.$emit('set-title',title);
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>