<template>

    <div v-if="isLoading" class="LoadingScreen">
        <div class="text-center">
            <p class="font-italic font-weight-bold">Loading...</p>
            <p class="font-italic font-weight-bold">
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
            </p>              
        </div>
    </div>

    <div class="editInfoContainer" v-else>

        <div class="d-block text-center">
            <h3 class="font-sans text-4xl tracking-wide font-bold text-gray-700 mt-2">Edit Ticket</h3>
        </div>

        <div>
            <div class=" mt-2 md:mt-0 md:col-span-6">

                <form action="#">
                    <!-- <h4 class="font-medium leading-6 text-gray-700">Information</h4> -->

                    <!-- <div class="bg-gray-50 px-4 py-4" v-if="this.status">
                        <div class="row">
                            <button  
                            class="uppercase inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600" style="cursor:default">
                                Reopen this ticket
                            </button>
                        </div>
                    </div> -->

                    <div class="bg-gray-50 px-4 py-4" >
                        <div class="row text-gray-600 font-semibold leading-tight">Current Queue:<p class="pl-1 font-bold">{{this.getTicketInfo.QueueID == null ? 'Not Specified' : this.getTicketInfo.queueName}}</p></div>
                        <div class="row text-gray-600 font-semibold leading-tight">Current Subcategory:<p class="pl-1 font-bold">{{this.getTicketInfo.SubCategoryID == null ? 'Not Specified' : this.getTicketInfo.subCategoryName}}</p></div>
                        <div class="row text-gray-600 font-semibold leading-tight">Current Priority:<p class="pl-1 font-bold">{{this.getTicketInfo.TicketPriorityID == null ? 'Not Specified' : this.getTicketInfo.PriorityLabel}}</p></div>
                        <div class="row text-gray-600 font-semibold leading-tight">Current Assigner:<p class="pl-1 font-bold">{{this.getTicketInfo.AssignedTo == null ? 'Not Specified' : this.getTicketInfo.AssignedEmp}}</p></div>
                        <div class="row text-gray-600 font-semibold leading-tight">Issue Date:<p class="pl-1 font-bold">{{this.getTicketInfo.IssueDate == null ? 'Not Specified' : this.getTicketInfo.IssueDate}}</p></div>
                    </div>

                    <label class="text-gray-600 font-semibold text-lg mt-1">Assign</label>
                    <div class="bg-gray-50 px-4 py-4" >
                        <div class="row">
                            <label for="selectPriority" class="text-gray-600 font-semibold text-lg pr-1">Select a Priority</label>
                            <select id="selectPriority" v-model="ticketPriority"
                            class="pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1">
                                <option hidden value="null">Select a Priority</option>
                                <option v-for="priority in getPriorities" :key="priority.isDisabled" :value="priority.ID" :id="priority.ID">{{priority.PriorityLabel}}</option>
                            </select>
                        </div>
                        <div class="row mt-3">
                            <label for="selectAssignation" class="text-gray-600 font-semibold text-lg pr-1">Assign to</label>
                            <select id="selectAssignation" v-model="assignedTo"
                            class="pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1">
                                <option hidden value="null">Select an Employee</option>
                                <option v-for="assign in getAssings" :key="assign.ID" :value="assign.ID" :id="assign.ID">{{assign.EmpID}}</option>
                            </select>
                        </div>
                    </div>

                    <label class="text-gray-600 font-semibold text-lg mt-1">Edit Information</label>
                    <div class="bg-gray-50 px-4 py-4" >
                        <div class="row">
                            <label for="editDate" class="text-gray-600 font-semibold text-lg pr-1">Edit Date</label>
                            <input type="date" id="editDate" v-model="ticketDate" class="pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1">
                        </div>
                    </div>

                    <label class="text-gray-600 font-semibold text-lg mt-1">Change Queue</label>
                    <div class="bg-gray-50 px-4 py-4" >
                        <div class="row">
                            <label for="select0" class="col-6 text-gray-600 font-semibold text-lg pr-1">Change Department</label>

                            <select id="select0" v-model="ticketQueue" @change="loadCategories"
                            class="col-6  pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1 mr-2">
                                <option hidden value="null">Select a Department</option>
                                <option v-for="queue in getQueuesList" :value="queue" :key="queue.id">{{queue.queueName}}</option>
                            </select>
                        </div>

                        <div class="row">
                            <label for="select1" class="col-6 text-gray-600 font-semibold text-lg pr-1">Change Category</label>

                            <select id="select1" v-if="!this.showCategories" disabled required @change="loadSubCategories"
                            class="col-6  pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1 mr-2">
                                <option hidden value="null">Select a Department First</option>
                            </select>
                            <select id="select1" v-else v-model="ticketCategory" @change="loadSubCategories"
                            class="col-6  pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1 mr-2">
                                <option hidden value="null">Select a Category</option>
                                <option v-for="category in getCategoriesList" :value="category.id" :key="category.id">{{category.categoryName.toUpperCase()}}</option>
                            </select>
                        </div>

                        <div class="row">
                            <label for="select2" class="col-6 text-gray-600 font-semibold text-lg pr-1">Change Sub-Category</label>

                            <select id="select2" v-if="!this.showSubCategories" disabled required
                            class="col-6  pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1  mr-2">
                                <option hidden value="null">Select a Category First</option>
                            </select>
                            <select id="select2" v-else v-model="ticketSubC" @change="changeEditType"
                            class="col-6  pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1  mr-2">
                                <option hidden value="null">Select a Sub-Category</option>
                                <option v-for="subCategory in getSubCategoriesList" :value="subCategory.id" :key="subCategory.id">{{subCategory.subCategoryName.toUpperCase()}}</option>
                            </select>
                        </div>
                    </div>

                </form>

                <div class="px-4 py-2 text-right md:px-6">
                    <button v-if="this.sending" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600" style="cursor:default">
                    Sending...
                    </button>

                    <button v-else @click="editTiket" class="uppercase inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Save
                    </button>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
    export default {
        name: "EditTicket",
        data(){
            return{                
                showCategories:     false,
                showSubCategories:  false,
                showSubQuestions:   false,
                sending:            false,
                isLoading:          true,
                editType:           'info',

                ticketPriority: null,
                ticketDate:     null,
                assignedTo:     null,
                status:         false,
                newStaus:       null,

                ticketQueue:    null,
                ticketCategory: null,
                ticketSubC:     null
            }
        },
        computed: {
            ...mapState(['tickets']),
            ...mapGetters([
                'getPriorities',
                'getTicketInfo',
                'getAssings',
                "getQueuesList",
                "getCategoriesList",
                "getSubCategoriesList",
            ]),
        },
        props:{
          thicketId: Number,
          queueId : Number,
        },
        methods:{
            ...mapActions([
                'fetchPrioritiesList',
                'fetchThisTicketInfo',
                'fetchAssignList',
                'EditTicket',
                "fetchTicketQueues", 
                "fetchTicketCategories", 
                "fetchTicketSubCategories",
            ]),

            changeEditType(){
                this.editType = 'queue';
            },

            loadCategories(){
                this.showSubQuestions   = false
                this.showCategories     = false;
                this.showSubCategories  = false;
                this.ticketCategory     = null;
                this.ticketSubC         = null;

                let objData = {
                    queueSelected: this.ticketQueue
                }
                this.fetchTicketCategories(objData).then(()=>{
                    this.showCategories = true;
                });
            },

            loadSubCategories(){
                this.showSubQuestions   = false
                this.showSubCategories  = false;
                this.ticketSubC         = null;

                let objData = {
                    categorySelected: this.ticketCategory
                }
                this.fetchTicketSubCategories(objData).then(()=>{
                    this.showSubCategories = true;
                });
            },

            editTiket(){
                let counter = [];
                if(this.ticketQueue != this.getTicketInfo.QueueID){
                    if(this.ticketQueue == null){
                        document.getElementById('select0').classList.add('border-2', 'border-red-400')
                        counter.push({'error':'select0'})
                    }else{
                        document.getElementById('select0').classList.remove('border-2', 'border-red-400')
                    }

                    if(this.ticketCategory == null){
                        document.getElementById('select1').classList.add('border-2', 'border-red-400')
                        counter.push({'error':'select1'})
                    }else{
                        document.getElementById('select1').classList.remove('border-2', 'border-red-400')
                    }

                    if(this.ticketSubC == null){
                        document.getElementById('select2').classList.add('border-2', 'border-red-400')
                        counter.push({'error':'select2'})
                    }else{
                        document.getElementById('select2').classList.remove('border-2', 'border-red-400')
                    }
                }

                if(this.ticketPriority === null && this.assignedTo !== null){
                    document.getElementById('selectPriority').classList.add('border-2', 'border-red-400')
                    counter.push({'error':'selectPriority'})
                }else{
                    document.getElementById('selectPriority').classList.remove('border-2', 'border-red-400')
                }

                if(this.ticketPriority !== null && this.assignedTo === null){
                    document.getElementById('selectAssignation').classList.add('border-2', 'border-red-400')
                    counter.push({'error':'selectAssignation'})
                }else{
                    document.getElementById('selectAssignation').classList.remove('border-2', 'border-red-400')
                }

                if(counter.length > 0){
                    this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true
                    })
                }else{
                    this.editType = this.ticketPriority === null && this.assignedTo === null && this.getTicketInfo.IssueDate !== this.ticketDate ? 'date' : this.editType;
                    this.sending = true;
                    let objData = {
                        editType:       this.editType,
                        ticketSelected: this.thicketId,
                        issueDate:      this.ticketDate,
                        ticketPriority: this.ticketPriority,
                        ticketAssigned: this.assignedTo,
                        ticketQueue:    this.ticketQueue,
                        ticketCategory: this.ticketCategory,
                        ticketSubC:     this.ticketSubC
                    }
                    // console.log(objData)
                    this.EditTicket(objData).then(()=>{
                        this.$fire({
                            type: 'success',
                            title: "Information Sent!",
                            text: "Changes applied to the ticket",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(()=>{
                            this.$emit('closeModal', true); 
                        })
                    }).catch(()=>{
                        this.$fire({
                            type: 'warning',
                            title: "Something went wrong",
                            text: "Please try again or report to a administrator",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(() => {
                            this.sending = false;
                        })
                    })
                }
            }          
        },
        created() {
            let objData = {
                ticketSelected:     this.thicketId,
                queueId:            this.queueId
            }
            this.fetchThisTicketInfo(objData).then(()=>{
                this.ticketPriority = this.getTicketInfo.TicketPriorityID;
                this.ticketDate     = this.getTicketInfo.IssueDate;
                this.assignedTo     = this.getTicketInfo.AssignedTo;
                this.status         = this.getTicketInfo.isClose;
                this.ticketQueue    = this.getTicketInfo.queueInfo;
                this.ticketCategory = this.getTicketInfo.CategoryID;
                this.ticketSubC     = this.getTicketInfo.SubCategoryID;
                this.isLoading = false;
            })
            this.fetchTicketQueues();
            this.fetchAssignList(objData);
            this.fetchPrioritiesList(objData);
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
    ::placeholder {
        text-transform: capitalize;
    }
</style>