<template>
    <div v-if="isActive" class="relative flex flex-row items-center justify-start p-3 text-md">
        {{msg}}
     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce ml-2" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
    </svg>
 </div>
</template>

<script>
export default {
    props:{
        active:{ default:false, type:Boolean},
        msg:{default: 'We are loading your team information. Please wait', type:String}
    },
    computed:{
        isActive:function(){
            return this.active;
        }
    }
}
</script>