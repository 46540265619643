<template>
    <div class="w-full h-full">
        <div class="sm:max-w-5xl sm:m-auto">
            <!-- search the devide id -->
            <search-bar @updateAll="refresh()"/>

            <!-- Information -->
            <information @updateAll="refresh()" :key="key"/>
        </div>
    </div>
</template>
<script>
import SearchBar    from '@/views/components/campaigns/RSA/elements/rsa_searchbar'
import Information  from '@/views/components/campaigns/RSA/elements/rsa_information'

export default {
    components: {
        SearchBar,
        Information
    },
    data() {
        return {
            key: 1
        }
    },
    methods:{
        refresh: function() {
            this.key++;
        }
    },
    mounted(){
        let title = 'RSA Management';
        this.$emit('set-title',title);
    },
}
</script>