<template>
    <div>
        <div class="bg-white rounded-[10px] shadow-sm mb-10" >
            <div class="flex items-center divide-x space-x-6 px-4">
                <div><i class="bi bi-search text-gray-400 py-3"></i><input type="search" placeholder="Search employee id..." @change="filterSearch()" v-model="search" class="pl-3 py-3" /></div>
                <div class="flex" v-if="getAllCampaigns && getAllCampaigns.length">
                    <i class="bi bi-briefcase text-gray-400 py-3 pl-4 pr-2"></i>
                    <multiselect v-model="campaign" :options="getAllCampaigns" @input="filterSearch()">
                    </multiselect>
                </div>
                <div class="flex-1"></div>
                <div class="border-0">
                    <button @click.prevent="goAddNew" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-1 rounded-full"><i class="bi bi-people mr-2"></i>Add New</button>
                </div>
            </div>
        </div>
        <div v-if="getAllCampaigns && getAllCampaigns.length">
            <div v-for="campaign in getAllCampaigns" :key="campaign">
                <AccordionSimple v-if="teamsCampaign(campaign).length">
                    <template v-slot:title><div class="flex items-center">{{ campaign }} <span class="bg-blue-600 rounded-md text-[80%] text-white font-normal py-1 px-2 ml-6">{{ teamsCampaign(campaign).length + ' Team'+(teamsCampaign(campaign).length>1 ? 's':'') }}</span></div></template>
                    <template v-slot:content>
                        <table class="w-full">
                            <thead class="border-b border-gray-500">
                                <tr>
                                    <th width="22.5%" class="text-xs font-bold uppercase">Team Name</th>
                                    <th width="22.5%" class="text-xs font-bold uppercase">Employee ID</th>
                                    <th width="22.5%" class="text-xs font-bold uppercase">Title</th>
                                    <th width="22.5%" class="text-xs font-bold uppercase">Assigned At</th>
                                    <th width="10%"></th>
                                </tr>
                            </thead>
                            <tbody class="divide-y">
                                <tr class="hover:bg-gray-100" v-for="team in teamsCampaign(campaign)" :key="team.rec_id">
                                    <td>{{ team.Team_name }}</td>
                                    <td>{{ team.Emp_id }}</td>
                                    <td>{{ team.Title }}</td>
                                    <td>{{ team.Assigned_at }}</td>
                                    <td class="text-center">
                                        <a class="text-red-500 hover:text-red-700 cursor-pointer" @click.prevent="confirmDeleteTeam(team.rec_id)" style="text-decoration: none;">
                                            <i class="bi bi-trash3"></i> Delete
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </AccordionSimple>
            </div>
        </div>
        <div v-else>
            <p class="text-center">No results found</p>
        </div>
    </div>
</template>
<script>
    //import auth from "@/config/user"
    import Multiselect from 'vue-multiselect'
    import AccordionSimple from "@/views/Utilities/AccordionSimple";
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: {
            Multiselect,
            AccordionSimple
        },
        data(){
            return {
                filtersLoaded: false,
                campaign: '',
                search: '',
                toggleLabel: 'active',
                isOpen: false,
            }
        },
        validations: {
            
        }, 
        computed: {
            ...mapState(['teams']),
            ...mapGetters([
                'getAllCampaigns',
                'getTeamsFilter'
            ]),
        },
        created() {
            this.filtersLoaded = false;
            this.fetchAllTeams();
            this.fetchCampaigns().then(() => {
                this.filtersLoaded = true;
            })
        },
        methods: {
            ...mapActions([
                'fetchAllTeams',
                'fetchCampaigns',
                'deleteTeam'
            ]),
            toggleAccordion() {
                this.isOpen = !this.isOpen;
            },
            goAddNew:function(){
                this.$router.push({ name: 'teams.create' });
            },
            confirmDeleteTeam: function(value){
                this.$confirm("", "Are you sure you want to delete the team?", "question").then((result) => {
                    if(result) {     
                        this.deleteTeam(value).then((res)=>{
                            this.$fire({
                                type: 'success',
                                title: "Done",
                                text: res.msg
                            });
                            this.fetchAllTeams();
                        });
                    }
                })
            },
            teamsCampaign:function(camp) {
                return this.getTeamsFilter.filter(function(item) {
                    return item.Campaign?.toUpperCase() === camp?.toUpperCase();
                })
            },
            filterSearch:function() {
                let obj = {
                    emp_id: this.search,
                    campaign: this.campaign,
                };
                this.fetchAllTeams(obj);
            },
        },
        mounted() {
            let title = 'Teams';
            this.$emit('set-title',title);
        }
    };
</script>
<style scoped>
    th {
        padding: 10px;
    }
    td {
        padding: 4px 10px;
    }
    .multiselect {
        min-height: auto;
        max-width: 250px;
        width: 200px;
        margin: auto;
    }
    .multiselect__tags {
        border: none;
    }
    .multiselect__option.multiselect__option--highlight {
        background: #007bff !important;
    }
</style>