import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    loadingSchedules:   false,
    employees:          [],
    schedule:           [],
    scheduleChanges:    [],
    nextEvent:          [],
    visibleDates:       [],
    summary:            [],
};

const getters = {
    getLoadingSchedules:    state => state.loadingSchedules,
    getVisibleDates:        state => state.visibleDates,
    getEmployees:           state => state.employees,
    getSchedule:            state => state.schedule,
    getScheduleChanges:     state => state.scheduleChanges,
    getNextEvent:           state => state.nextEvent,
    getSummary:             state => state.summary,
};

const actions = {
    async fetchEmployeesWSchedule({commit }, employeeID){
        let endpoint = API_URL + 'api/v2/schedule/getEmployeeID/'+ employeeID;
        await axios.get(endpoint).then((response)=>{
            commit('setEmployees',response.data.employees);
        });
    },

    async fetchScheduleSummary({commit }, employeeID){
        commit('setLoadingSchedules', true);
        let endpoint = API_URL + 'api/v2/schedule/get-schedule-summary/'+ employeeID;
        await axios.get(endpoint).then((response)=>{
            commit('setSchedule',response.data.schedule);
            commit('setVisibleDates',response.data.dates);
            commit('setSummary',response.data.summary);
            commit('setLoadingSchedules', false);
        });
    },

    async fetchSchedule({commit}, data){
        commit('setLoadingSchedules', true);
        let endpoint = API_URL + 'api/v2/schedule/get-schedule/'+ data.empid + '/' + data.date;
        await axios.get(endpoint).then((response)=>{
            commit('setSchedule',response.data.schedule);
            commit('setLoadingSchedules', false);
        });
    },

    async fetchScheduleChange({commit}, data){
        commit('setLoadingSchedules', true);
        let endpoint = API_URL + 'api/v2/schedule/get-schedule-changes/'+ data.empid + '/' + data.date;
        await axios.get(endpoint).then((response)=>{
            commit('setScheduleChanges',response.data.schedule);
            commit('setLoadingSchedules', false);
        });
    },

    async cancelScheduleChange({commit}, data){
        commit('setLoadingSchedules', true);
        let endpoint = API_URL + 'api/v2/schedule/cancel-schedule-changes';
        let response = await axios.post(endpoint, data).then((response)=>{
            commit('setLoadingSchedules', false);
            return response;
        });
        return response;
    },

    async fetchChangesByEmployee({commit }, employeeID){
        let endpoint = API_URL + 'api/v2/schedule/getSchedulesChanges/'+ employeeID;
        await axios.get(endpoint).then((response)=>{
            commit('setScheduleChanges',response.data.scheduleChanges);
        });
    },

    async clearEmployees({commit }){
        commit('setEmployees', []);
    },

    async saveScheduleChanges({commit}, data){
        commit('setLoadingSchedules', true);
        let endpoint = API_URL + 'api/v2/schedule/saveScheduleChanges';
        let response = await axios.post(endpoint,data).then((response)=>{
            commit('setScheduleChanges',response.data.scheduleChanges);
            commit('setLoadingSchedules', false);
            return response;
        });
        return response;
    },

    async fetchNextEvent({commit}, empObject){
        const endpoint = API_URL + 'api/v2/schedule/triggerReminder';
        let response = await axios.post( endpoint, empObject).then((res) =>{
            commit('setNextEvent', res.data.nextEvent);
            return res;
        });
        return response;
    },
    
};
const mutations = {
    setLoadingSchedules: (state, loadingSchedules) =>{
        state.loadingSchedules = loadingSchedules;
    },
    setVisibleDates: (state, visibleDates) =>{
        state.visibleDates = visibleDates;
    },
    setSchedule: (state, schedule) => {
        state.schedule = schedule;
    },
    setEmployees: (state, employees) => {
        state.employees = employees;
    },
    setScheduleChanges: (state, changes) => {
        state.scheduleChanges = changes;
    },
    setNextEvent: (state, nextEvent) => {
        state.nextEvent = nextEvent;
    },
    setSummary: (state, summary) =>{ 
        state.summary = summary
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};