<template>
<div class="relative">

    <div class="shadow-sm rounded-xl overflow-auto">
        <table class="border-collapse table-fixed w-full text-sm">
            <thead>
                <tr class="bg-gray-600">
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Employee</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Emp ID</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Site</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Document type</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Status</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Requested By</th>
                    <th class="border-b p-4 text-white font-semibold uppercase text-left">Requested At</th>
                </tr>
            </thead>
            <tbody class="bg-white" v-if="listIsEmpty">
                <tr class="group hover-gray-100 cursor-default">
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase">There are no request in here... 🍃 </td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase"></td>
                </tr>
            </tbody>
            <tbody class="bg-white" v-else>
                <tr v-for="request in _employee_file_requests" :key="request.id" class="group hover-gray-100 cursor-pointer"
                @click.prevent="$router.push({name: 'documents.detailed', params: { id: request.id } })">
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100"> {{ request.full_name }} </td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100">{{ request.emp_id }}</td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100"> {{ request.site }} </td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100">{{ request.document_type }}</td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100">{{ request.status }}</td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100">{{ request.requested_by }}</td>
                    <td class="border-b border-slate-100 p-4 text-slate-500 text-left uppercase group-hover:bg-blue-100">{{ readableDate(request.requested_at) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default{
    computed: {
        ...mapGetters([
            '_employee_file_requests'
        ]),
        listIsEmpty () {
            return this._employee_file_requests.length === 0;
        }
    },
    methods: {
        readableDate(date) {
            let new_date = new Date(date);
            return new_date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
        ...mapActions([
            'fetchDocumentsRequests'
        ])
    },
}</script>