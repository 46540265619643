<template>
  <div class="main">
    <b-container fluid>
      <b-row>
        <b-col align-self="center" class="mt-2 rounded shadow-sm" offset-md="2" md="8" lg="8" xl="8">
          <h1 class>Clocker Supervisor Exam</h1>
        </b-col>
      </b-row>
    </b-container>
    <div class="container-fluid">
        <loading :active.sync="loadingFlag" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <div class="row">
            <div :class="{ hide: already_answered == true}" class="col-8 offset-2 mt-3 shadow p-3 bg-white rounded">
                <div>
                    <p style="font-style:italic">“Please read the following questions and select the best response. Note, you only have one attempt to complete this exam.”</p>
                </div>
                <div>
                    <form>

                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">1.- What section you can use to clock out agents from the same campaign as you?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_one.$error }">
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q11" v-model="exam.question_one" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">Today's Activity</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q12" v-model="exam.question_one" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Event Utility</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q13"  v-model="exam.question_one" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Adjustments</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" id="q14"  v-model="exam.question_one" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Users Settings</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_one.required" class="invalid-feedback" >Please, pick an option for question one</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">2. Where can I find training resources about Clocker?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_two.$error }">
                                <div class="col-3 col-lg-3 col-md-12">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" name='' v-model="exam.question_two" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">Clocker</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_two" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">EMS</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_two" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Watchmen> Docs & Files>Training Material in Basecamp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-lg-3 col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_two" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">http://censtrisinfo.net/Clocker</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_two.required" class="invalid-feedback" >Please, pick an option for question two</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">3. Dashboard used to monitor what is happening in real time</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_three.$error }">
                                
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="a">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">Payroll Hours</label>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="1">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">RealTime Events Monitor</label>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="c">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">Today's Activity</label>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio"  v-model="exam.question_three" value="d">
                                            </div>
                                        </div>
                                        <label class="ml-2 form-check-label">Billing Hours</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_three.required" class="invalid-feedback" >Please, pick an option for question three</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">4. What type of events you can view at the RealTime Events Monitor Dashboard?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_four.$error }" >
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Shifts in progress.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Activities in progress.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Service Interruption in progress.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_four" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">All of the above.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_four.required" class="invalid-feedback" >Please, pick an option for question four</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">5. What do the 3 colors in the dashboards represent?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_five.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Locations</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Campaings</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Violations</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_five" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Campaings</label>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_five.required" class="invalid-feedback" >Please, pick an option for question five</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">6. What is the meaning of the 3 colors?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_six.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Green: On time, Orange: Slightly over, Red: Late</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Green: Late, Orange: Late, Red: Late</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Green: On time, Orange: Late, Red: Slighly over</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_six" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Green: On time, Orange: On time, Red: Late</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_six.required" class="invalid-feedback" >Please, pick an option for question six</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">7. Which of the following events are billable?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_seven.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">Lunch, Break, Shift</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Break, Product Training, Phone</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Product Training, Back Office, Client Platform Issue</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seven" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Lunch, PC Issue, Centris Training</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_seven.required" class="invalid-feedback" >Please, pick an option for question seven</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">8. What is the Clocker Events Details Dashboard for?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_eight.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Check all activities for a single or multiple agent in an specific day</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Check all activities for a single or multiple agents in a time range</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Check a specific activity for a single or multiple agents in a time range</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eight" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">All of the Above</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_eight.required" class="invalid-feedback" >Please, pick an option for question eight</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">9. From the Clocker Events Details, what information do you use for requesting adjustments?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_nine.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="a">
                                            </div>
                                            <label class="ml-2 form-check-label">Activity ID</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Employee</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">Activity</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nine" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">All of the Above</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_nine.required" class="invalid-feedback" >Please, pick an option for question nine</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">10. What does the auto-adjuster do?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_ten.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Apply adjustment depending on the operation rules provided.</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Substract Excess Break from Break only</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Substract Excess Break from Shift only</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_ten" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Add Excess Break to Lunch only</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_ten.required" class="invalid-feedback" >Please, pick an option for question ten</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">11. Where can I reset a clocker password for an employee?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_eleven.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eleven" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Clocker</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eleven" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">EMS</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eleven" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">e-mail</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eleven" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Basecamp</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_eleven.required" class="invalid-feedback" >Please, pick an option for question eleven</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">12. What is the Custom Hours Review Dashboard for?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_twelve.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twelve" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Check Billing and Payroll hours</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twelve" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">Check Activities duration</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twelve" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Check Standard and Graveyard hours</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twelve" value="1">
                                            </div>
                                            <label class="ml-2 form-check-label">All of the above</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_twelve.required" class="invalid-feedback" >Please, pick an option for question twelve</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">13. I can export information from Grafana for reporting purposes</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_thirteen.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_thirteen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_thirteen" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_thirteen.required" class="invalid-feedback" >Please, pick an option for question question_thirteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">14. Where can I report issues?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_forteen.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_forteen" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">e-mail</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_forteen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Watchmen's Campfire on Basecamp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_forteen" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">Whatsapp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_forteen" value="d">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_forteen.required" class="invalid-feedback" >Please, pick an option for question forteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">15. I can use the Watchmen's Message board for feedbacks, suggestions, and announcements on Basecamp</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_fifteen.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_fifteen" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_fifteen" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_fifteen.required" class="invalid-feedback" >Please, pick an option for question fifteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">16. Where do I post individual Adjustmets and Corrections?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_sixteen.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_sixteen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Watchmen's To-Do List on Basecamp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_sixteen" value="b">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">Watchmen's Campire on Basecamp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_sixteen" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">e-mail</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_sixteen" value="d">
                                            </div>
                                            <label class="ml-2 form-check-label">Whatsapp</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_sixteen.required" class="invalid-feedback" >Please, pick an option for question sixteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">17. An adjustment is a modification to the duration of an event</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_seventeen.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seventeen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_seventeen" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_seventeen.required" class="invalid-feedback" >Please, pick an option for question seventeen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">18. A Correction is the creation of a new event</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_eighteen.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eighteen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_eighteen" value="b">
                                            </div>
                                            <label class="ml-2 form-check-label">False</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_eighteen.required" class="invalid-feedback" >Please, pick an option for question eighteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">19. What information can be used as froop for adjustments or corrections?</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_nineteen.$error }">
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nineteen" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">An e-mail with time-stamp of the issue or spice-works ticket</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nineteen" value="b">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">An screenshot from the client platform</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nineteen" value="c">
                                            </div>
                                            <label class="ml-2 form-check-label">An screenshot from Xcally</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_nineteen" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">All of the above</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_nineteen.required" class="invalid-feedback" >Please, pick an option for question nineteen</div> 
                        </div>
                        <div class="form-group mt-5">
                            <label style="align-items: start; display: flex;" class="rounded border p-3 mb-3 bg-secondary text-white">20. The Clocker Official Time displays the time from my PC</label>
                            <div class="row" :class="{'is-invalid': submitted && $v.exam.question_twenty.$error }">
                                <div class="col-3"></div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twenty" value="a">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">True</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                     <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <input type="radio" v-model="exam.question_twenty" value="1">
                                            </div>
                                            <label style="mw-80 font-size: 16px" class="ml-2 form-check-label">False</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div v-if="submitted && !$v.exam.question_twenty.required" class="invalid-feedback" >Please, pick an option for question twenty</div> 
                        </div>

                        <div class="mt-5">
                            <button type="button" @click='storeInformation' class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Submit</button>
                        </div>
                        <input type="hidden" v-model="exam" value="">
                    </form>
                </div>
            </div>
            <div :class="{ hide: already_answered != true}" class="col-8 offset-2 mt-3 shadow p-3 bg-white rounded">
                <div>
                    <div class="alert alert-danger" role="alert">
                        You already answer this exam
                    </div>
                    <p class="font-weight-bold">Your final score is: <span style="text-decoration: underline;">{{this.getScore}}</span></p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: { Loading },
    computed: {
        ...mapState(['clocker']),
        ...mapGetters(['getScore', 'getExamStatus']),
    },
    validations: {
        exam: {
            question_one:       { required },
            question_two:       { required },
            question_three:     { required },
            question_four:      { required },
            question_five:      { required },
            question_six:       { required },
            question_seven:     { required },
            question_eight:     { required },
            question_nine:      { required },
            question_ten:       { required },
            question_eleven:    { required },
            question_twelve:    { required },
            question_thirteen:  { required },
            question_forteen:   { required },
            question_fifteen:   { required },
            question_sixteen:   { required },
            question_seventeen: { required },
            question_eighteen:  { required },
            question_nineteen:  { required },
            question_twenty:    { required },
        },
    }, 
     created(){
        this.confirmTerms();          
    },
    data(){
      return{
          exam: {},
          submitted: false,
          fullPage: true,
          color: "#F2F2F2",
          bgcolor: "#000000",
          loader: 'dots',
          already_answered: false,
          loadingFlag: true,
          exam_name: 'clocker_supervisor_v1' 
      }
    },
    methods: { 
        ...mapActions([
            'storeExamScore',
            'startExam',
            'checkExamStatus',
        ]),
        storeInformation: function(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$fire({
                    type: 'error',
                    title: 'Oops! there are some errors',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                })
                return;
            }
            let objectExam = {
                'exam_name': this.exam_name,
                'exam_answers': this.exam
            }
            this.storeExamScore(objectExam).then( res =>{
                this.$alert(
                    "Thanks for answer this exam. Your final score is " + res.data.score,
                    "You score has been saved successfully",
                    "success",
                    { allowOutsideClick: false, confirmButtonText: "Got it!" }
                ).then(() => {
                    this.$router.push('/dashboard');
                });
            }, error => {
                console.error("Error while updating the exam: "+ error)
            });
        },
        confirmTerms: function(){
            this.checkExamStatus(this.exam_name).then( () => {
                if(this.getExamStatus == 'not_initialized'){
                    this.loadingFlag = false;
                    this.$confirm(
                        "You only have one attempt to answer this test. We will store your answers",
                        "Attention",
                        "warning", 
                        { allowOutsideClick: false }
                    ).then(() => {
                        //Store time when the user start
                        this.startExam(this.exam_name);
                    })
                    .catch(() => {
                        //if they clic on cancel, will be redirected to the dashboard
                        this.$router.push('/dashboard');
                    });
                }else if(this.getExamStatus == 'already_started'){
                    this.loadingFlag = false;
                }else if(this.getExamStatus == 'already_answered'){
                    this.loadingFlag = false;
                    this.already_answered = true;
                }
            }, error => {
                 this.loadingFlag = false;
                console.error("Error fetching the exam: "+ error)
            }); 
        }
    },
};
</script>

<style lang="scss" scoped>
    input[type="radio"], input[type="checkbox"] {
        cursor: pointer;
    }
    .invalid-feedback{
        font-weight: bold;
    }
    .hide{
        display: none !important;
    }
</style>