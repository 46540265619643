<template>
    <div>
        <search-bar @toggle="filterUpdate"/>
        <div class="text-gray-500 font-semibold mt-3">
            Click on a request to see details
        </div>
        <request-list />
    </div>
</template>
<script>
import RequestList from '@/views/documents/management/documents_table';
import SearchBar from '@/views/documents/management/document_search_bar';
import {mapActions} from 'vuex';
export default {
    data(){
        return {
            form: {
                refresh :false
            }
        }
    },
    components: {
        RequestList,
        SearchBar
    },
    methods: {
        ...mapActions([
            'fetchDocumentsRequests'
        ]),
        clear:function(){
           this.search = '';
        },
        filterUpdate:function(form){
            this.form = form;
        },
    },
    created () {
        this.$emit('set-title', 'Documents Management');
    },
    watch:{
        form:{
            handler:function(){        
                this.fetchDocumentsRequests(this.form);
            },
            deep:true
        }
    },
}
</script>