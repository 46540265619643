<template>
    <div class="main p-4">
        <div class="grid grid-cols-2 gap-3">
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Clinic">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Number of Clinic</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Contact_Name">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Emergency Contact Name</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Contact_Email">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Emergency Conctact Email</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Contact_Phone">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Emergency Contact Phone</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Benef_Name">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Benefactor Name</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Benef_Email">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Benefactor Email</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Benef_Phone">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Benefactor Phone</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters}   from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'infoEmployee', 
            'loadingFlag'
        ]),
    },
}
</script>
<style scoped>
.border-input{
    border-bottom: 1px solid rgb(150, 150, 150);
}
.text-xxs{
    font-size: 0.6rem;
}
.text-sm{
    font-size: 0.9rem;
}
.main{
    width: 100%;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: uppercase;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: uppercase;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: uppercase;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: uppercase;
}
::placeholder { /* Recent browsers */
    text-transform: uppercase;
}
</style>