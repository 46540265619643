<template>
    <div v-if="isReady" >
        <p class="mb-0 text-gray-500 text-sm">Current Activity</p>
        <strong>{{ this.currentActivity !== "None" ? this.currentActivity : 'No activities ongoing' }}</strong>
        <hr class="my-0">
        <p class="mb-0 text-gray-500 text-sm">Ongoing Act. Duration</p>
        <strong>{{ this.currentActivityDuration !== "None" ? this.currentActivityDuration : 'No activities ongoing' }}</strong>
    </div>
    <div v-else>
        <p class="mb-0 text-gray-400 text-sm">Loading Current Activity...</p>
        <span class="loader-mini"></span>
    </div>
</template>
<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    export default {
    data(){
        return{
            currentActivity: null,
            currentActivityDuration: null,
            isReady: false,
        };
    },
    computed: {
        ...mapState([
            "clockerUtility",
        ]),
        ...mapGetters([
            "getCurrentActivity",
        ]),
    },
    methods:{
        ...mapActions(['loadCurrentActivity']),
        ongActDuration: function(){
            let currentTimeOngAct = this.currentActivityDuration.split(":");
            let d0 = new Date();
            let d1 = new Date(d0.getFullYear(),d0.getMonth(),d0.getDay(),currentTimeOngAct[0],currentTimeOngAct[1],currentTimeOngAct[2]);
            d1.setSeconds(d1.getSeconds() + 1);
            this.currentActivityDuration = d1.toLocaleTimeString('en-GB');
        },
    },
    created: function () {
        this.loadCurrentActivity().then(()=>{
            var currentActivityObj = this.getCurrentActivity;
            this.currentActivity = currentActivityObj.currentActivity;
            this.currentActivityDuration = currentActivityObj.currentActivityDuration;
            if(this.currentActivityDuration !== "None"){
                setInterval(this.ongActDuration, 1000);
            }
            this.isReady = true;
        });
    }
}
</script>