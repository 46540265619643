import axios from '@/config/axios'

const state = {
    folders: [],
    categories: [],
    resource:{}
};
const getters = {
    getFolders: (state) => state.folders,    
    getCategories: (state) => state.categories,    
    getResource: (state) => state.resource
};
const actions = {
    async fetchResourceCategories ({commit}){        
        const response = await axios.get('api/v3.1/resources/folders')
        commit('setFolders',response.data.folders);

        return response;
    },

    async createFolder ({commit},folder){        
        const response = await axios.post('api/v3.1/resources/folders/new',folder)
        commit('setFolders',response.data.folders);

        return response;
    },
    async removeFolder ({commit},id){        
        const response = await axios.delete('api/v3.1/resources/folders/' + id)
        commit('setFolders',response.data.folders);

        return response;
    },
    async createResource ({commit},data){        
        const response = await axios.post('api/v3.1/resources/categories/new',data,{
            headers: {
              'accept': 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data;`,
            }
          })
        commit('setCategories',response.data.resources);

        return response;
    },
    async fetchResources ({commit}){        
        const response = await axios.get('api/v3.1/resources')
        commit('setCategories',response.data.resources);

        return response;
    },

    async loadResource({commit},slug){
        const response = await axios.get('api/v3.1/resources/courses/' + slug)
        commit('setResource',response.data.resource);

        return response;
    }
};
const mutations = {
    setFolders: (state,folders) => state.folders = folders,
    setCategories: (state,categories) => state.categories = categories,
    setResource: (state,resource) => state.resource = resource,
};

export default {
    state,
    getters,
    actions,
    mutations
};