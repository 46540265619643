<template>
    <div>
        <div class="bg-white shadow-sm relative rounded-lg overflow-hidden">
            <table class="text-left w-full">
                <thead class="bg-slate-700 text-slate-800 text-white">
                    <tr>
                        <th>ID</th>
                        <th>Item Type</th>
                        <th>Serial Number</th>
                        <th>Label</th>
                        <th>Manufacturer</th>
                        <th>Model</th>
                        <th>Status</th>
                        <th>Updated By</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody class="animate-pulse divide-y" v-if="this.loading">
                    <tr v-for="i in 3" :key="i" class="hover:bg-slate-100">
                        <td v-for="i in 9" :key="i"><div class="h-2 bg-slate-200 rounded my-2"></div></td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && getInventoryEmployee.length">
                    <tr class="hover:bg-slate-100 hover:cursor-pointer" v-for="inventory in getInventoryEmployee" :key="inventory.Catalog_ID">
                        <td class="p-2">{{ inventory.Catalog_ID }}</td>
                        <td class="p-2">{{ inventory.Item_Type_Label }}</td>
                        <td class="p-2">{{ inventory.Serial_Number }}</td>
                        <td class="p-2">{{ inventory.Label }}</td>
                        <td class="p-2">{{ inventory.Manufacturer }}</td>
                        <td class="p-2">{{ inventory.Model }}</td>
                        <td class="p-2">{{ inventory.Status }}</td>
                        <td class="p-2">{{ inventory.Updated_By }}</td>
                        <td class="p-2">{{ inventory.Updated_At }}</td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && !getInventoryEmployee.length">
                    <tr class="hover:bg-slate-100 hover:cursor-pointer">
                        <td colspan="9" class="text-center italic text-gray-400">Empty List</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            loading: true,
            employee: null
        }
    },
    components: {
    },
    computed: {
        ...mapState(['inventoryEmployee']),
        ...mapGetters(['getInventoryEmployee'])
    },
    methods: {
        ...mapActions(['fetchInventoryEmployee']),
    },
    created() {
        this.employee = this.$route.params.id;
        this.rec_id = this.$route.params.recid;
        this.$emit('set-title', 'Inventory '+this.employee);

        this.fetchInventoryEmployee(this.employee).then(() => {
            this.loading = false;
        });
    }
}
</script>