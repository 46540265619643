<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Payroll Adjustment</h3>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col text-center">
          <label> Event type:</label>
          <div>
            <b>{{this.dataEvent.Event}}</b>
          </div>
      </div>
    </div>
    <div class="row mt-3 mb-3 ">
      <div style="text-align:center" class="col">
        <label>Standard Time</label>
        <div>
          <strong>{{ transformDuration(this.dataEvent.Daytime) }}</strong>
        </div>
      </div>
      <div style="text-align:center" class="col">
        <label>Graveyard Time</label>
        <div>
          <strong>{{ transformDuration(this.dataEvent.Graveyard) }}</strong>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
            <b-progress-bar :value="durationNormal" variant="primary"><b>Duration: {{durationNormal}} Hours</b></b-progress-bar>
          </b-progress>
          <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
            <b-progress-bar :value="this.newTotalTime" variant="warning"><b style="color:black">New Duration: {{this.newTotalTime}} Hours</b></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col">
          <label>New Standard Time</label>
          <vue-timepicker v-if="!timerDisabled"  v-model="newStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          <vue-timepicker v-else disabled v-model="newStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
        <div class="col">
          <label>New Graveyard Time</label>
          <vue-timepicker  v-if="!timerDisabled" v-model="newGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          <vue-timepicker v-else disabled v-model="newGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <b-form-textarea :disabled="disableComment" v-model="eventComment" placeholder="Please add a comment..." rows="3" max-rows="3" ></b-form-textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <button type="button" @click="calculateTime" class="bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded">Calculate</button>
        </div>
        <div class="col">
          <button v-if="canFix" type="button" @click="fixTime" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
          <button v-else disabled type="button" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
        </div>
        <div class="col">
          <button type="button" @click="clearTime" class="bg-gray-200 px-6 py-2 rounded">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button v-if="canSave" type="button" @click="saveTime" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          <button v-else type="button" disabled class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
// import moment from "moment";
import Loading          from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {VueTimepicker, Loading},
  data() {
    return {
      maxDuration:    Math.round(( (this.dataEvent.Total_hrs / 3600) + Number.EPSILON) * 100) / 100,
      durationNormal: Math.round(( (this.dataEvent.Total_hrs / 3600) + Number.EPSILON) * 100) / 100,
      removeTime:     null,
      addTime:        null,
      newStdTime:     null,
      newGrvTime:     null,
      newTotalTime:   0,
      newStandard:    0,
      newGraveyard:   0,
      newDuration:    0,
      canSave:        false,
      timerDisabled:  false,
      canFix:         false,
      eventComment:   null,
      disableComment: false,

      //Loader Data
      isLoaderActive: false,
      fullPage: true,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: 'dots',

      //No Negative shifts
      shiftStdTime: 0,
      shiftGrvTime: 0,
      activitiesStdTime: 0,
      activitiesGrvTime: 0
    };
  },
  props: {
    dataEvent: Object,
  },
  computed: {
    ...mapState(["clockerUsers", "payroll"]),
    ...mapGetters(["getInfoByApprovalID"]),
  },
  methods: {
    ...mapActions(['savePayrollAdjustment', "shiftValidation"]),

    saveTime: function () {
      this.isLoaderActive = true;
      let dataObj = {
        RecId :         this.dataEvent.rec_id,
        newStdTime:     this.newStandard,
        newGrvTime:     this.newGraveyard,
        newDuration:    this.newDuration,
        adjustmentComment: this.eventComment,
      }
      this.savePayrollAdjustment(dataObj).then( (response)=>{
        this.isLoaderActive = false;
        if (response.status == 200){
            this.$fire({
                type: 'success',
                title: 'The adjustment has been applied successfully',
                showConfirmButton: false,
                timer: 1500
              }).then(()=>{ 
                this.$emit('closeModal', true);
              }); 
          }
      });
    },
    transformDuration: function(duration){

        let hrs = parseInt(Math.floor(duration/3600));
        let mins = parseInt(Math.floor((duration - (hrs * 3600))/60));
        // let secs = parseInt(Math.floor(duration % 60));

        let dHours = (hrs > 9 ? hrs : '0' + hrs);
        let dMins = (mins > 9 ? mins : '0' + mins);
        // let dSecs = (secs > 9 ? secs : '0' + secs);

        return dHours + ":" + dMins;
      
      },
    calculateTime: function () {
      // To Create a moment object only for time we need to add a fake Date
      var standardTime     = (this.newStdTime.HH*60) + (this.newStdTime.mm*1);
      var GraveyardTime    = (this.newGrvTime.HH*60) + (this.newGrvTime.mm*1);
      var TotalTime        = standardTime+GraveyardTime;
      let useComment       = this.eventComment;

      if( useComment == null || useComment == '' ){
        this.$fire({
          type: "error",
          title: "Comment is mandatory",
          showConfirmButton: true,
        });
      }else{
          // Here we're checking if the shift is not less than the other events
        if(this.dataEvent.Event == 'shift'){
          this.shiftStdTime = standardTime * 60;
          this.shiftGrvTime = GraveyardTime * 60;
        }else{
          this.activitiesStdTime = standardTime * 60;
          this.activitiesGrvTime = GraveyardTime * 60;
        }
        let dataObj = {
        shiftStdTime:       this.shiftStdTime,
        shiftGrvTime:       this.shiftGrvTime,
        activitiesStdTime:  this.activitiesStdTime,
        activitiesGrvTime:  this.activitiesGrvTime,
        rec_id:             this.dataEvent.rec_id,
        EmployeeID:         this.dataEvent.emp_id,
        eventDate:          this.dataEvent.Event_date
        };
        this.isLoaderActive = true;
        this.shiftValidation(dataObj).then((res)=>{
          this.isLoaderActive = false;
            if(res.data.msg[0].total >= 0 && (res.data.msg[0].Standard < 0 || res.data.msg[0].Graveyard < 0))
            {
              this.$fire({
              type: 'warning',
              title: 'Oops! Standard and Graveyard time do not match',
              text: 'Please review the available time in Standard and Graveyard',
              showConfirmButton: true
              }).then(()=>{
              this.activitiesStdTime = 0;
              this.activitiesGrvTime = 0;
              this.shiftStdTime = 0;
              this.shiftGrvTime = 0;
              this.canFix = false;   
              });
            }else{
                  if(res.data.msg[0].total < 0)
                  {
                      this.$fire({
                      type: 'warning',
                      title: 'Oops! The activities are bigger than the shift',
                      text: 'Please make sure to reduce the activities, or increase the shift first',
                      showConfirmButton: true
                      }).then(()=>{
                      this.activitiesStdTime = 0;
                      this.activitiesGrvTime = 0;
                      this.shiftStdTime = 0;
                      this.shiftGrvTime = 0;
                      this.canFix = false;   
                      });
                  }else
                  {
                    this.activitiesStdTime = 0;
                    this.activitiesGrvTime = 0;
                    this.shiftStdTime = 0;
                    this.shiftGrvTime = 0;
                    this.canFix = true;   
                  }
                 }
        
          this.newTotalTime   = Math.round(TotalTime / 60 * 100) / 100;
          this.newDuration    = TotalTime * 60;
          this.newStandard    = standardTime * 60;
          this.newGraveyard   = GraveyardTime * 60;
        })

          // End
      }
    },
    fixTime: function(){
      this.timerDisabled = true;
      this.canSave       = true;
      this.disableComment = true;
    },
    clearTime: function () {
      this.newStdTime = { HH: "", mm: "" };
      this.newGrvTime  =  { HH: "", mm: "" };
      this.newTotalTime = null;
      this.timerDisabled = false;
      this.canSave      = false;
      this.disableComment = false;
      this.eventComment = null;
    },
  },
};
</script>
<style lang="scss">
</style>