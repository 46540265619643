<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Confirm Request</h3>
        </div>
      </div>
    </div>
    <div :class="{ hide: !isLoading}" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div :class="{ hide: isLoading}">
      <div>
        <b-table stacked :items="tableItems"></b-table>
      </div>
      <div>

          <b-form-group class="mb-3" label="Select the new status for this request:" description="*Please select the new status">
           <select v-model="adjustment.status" class="form-control" id="exampleFormControlSelect1">
            <option value="null">Select a new status</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Canceled</option>
           </select>
          </b-form-group>
          
          <b-form-group class="mb-3" v-if="adjustment.status == 'cancelled'" label="Select the new status for this request:" description="*Please select why is this request being canceled">
           <select v-model="adjustment.reason" class="form-control" id="exampleFormControlSelect" >
            <option value="null">Select a reason</option>
            <option value="no_proof">No Proof</option>
            <option value="incomplete_proof">Incomplete proof</option>
            <option value="wrong_request">Wrong Request</option>
           </select>
          </b-form-group>

      </div>
      <div class="row">
        <div class="col">
          <button type="button" @click="completeData" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Complete Request</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      time: null,
      adjustment: {
        status: null,
        reason: null,
      },
      isLoading: false,
      tableItems: [],
    };
  },
  created(){
      let itemsToDisplay = [
        { 
          employee_id:  this.requestInfo.Emp_id, 
          created_date: this.requestInfo.EventDate, 
          duration:     this.requestInfo.EventDuration,
          event:        this.requestInfo.EventType,
        }
      ];
      this.tableItems = itemsToDisplay;
  },
  props: {
    requestInfo: Object,
    currentIndex: Number,
    currentRequest: Number,
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters(["loadingFlag","getRequestInfo"]),
  },
  methods: {
    ...mapActions(['fetchRequestInfo','completeRequest']),
    completeData: function () {
      if(this.adjustment.status == null || (this.adjustment.status == 'cancelled' && this.adjustment.reason == null)){
        this.$fire({
            type: 'warning',
            title: 'There is information missing',
            showConfirmButton: true
          })
      }else{
        let requestInfo = {
        newStatus: this.adjustment.status,
        newReason: this.adjustment.reason,
        requestObj: this.requestInfo
      }
      console.log(requestInfo);
      this.completeRequest(requestInfo).then(()=>{
          this.$fire({
            type: 'success',
            title: 'The request has been completed sucessfully',
            showConfirmButton: false,
            timer: 1500
          }).then(()=>{
            let identificadores = {
              index: this.currentIndex,
              request: this.currentRequest,
            }
               this.$emit('requestCompleted', identificadores);                        
          }); 
      });
      }
    },
  },
};
</script>
<style lang="scss">
.display-time {
  border: none !important;
  cursor: pointer !important;
  height: auto !important;
  padding: 0 !important;
  width: 100% !important;
}
</style>