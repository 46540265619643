<template>
    <div class="container mt-5 bg-white border border-gray-100 rounded-2xl p-4" v-if="!loading">
        <span v-if="getSurveysV2Answers === null"
            class="text-red-700 bg-red-300 font-medium border-red-700 rounded-full px-2 py-1">
            This user has not provided any answers.
        </span>
        <div v-else class="space-y-4">
            <div class="w-full">
                <span class="grid grid-cols-2 p-4 border-[1px] border-blue-500/25 bg-blue-50 rounded-2xl">
                    <div>
                        <div class="inline-flex flex-col relative mx-auto">
                            <avatar :employeeId="getSurveysV2Answers.emp_id"
                                :id="'avatar' + getSurveysV2Answers.emp_id"
                                class="h-20 w-20 text-3xl mx-auto relative" />
                            <div class="text-xs text-gray-600 font-sans uppercase rounded-full px-1 mx-auto inline-block shadow-md absolute -bottom-2 right-0 border-[1px] border-green-700/25"
                                :class="{ 'bg-green-200/55': isActive, 'bg-gray-200/55': isInactive, 'bg-gray-200/55': isSomethingElse }">
                                {{ employee.EmpStatus }}
                            </div>
                        </div>

                        <div class="flex flex-col mt-3">
                            <div class="flex flex-row">
                                <div
                                    class="text-sm text-blue-600 font-semibold font-sans uppercase py-1 px-2 mx-auto tracking-wider">
                                    {{ fullName }}</div>
                            </div>
                            <div class="flex flex-row">
                                <div
                                    class="text-xs text-gray-600 font-semibold font-sans uppercase bg-gray-200 rounded-full py-1 px-2 mx-auto">
                                    {{ employee.Title }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="flex flex-col mt-2">
                            <div class="flex p-2">
                                <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                </div>
                                <span class="flex flex-col text-left p-1 pr-2">
                                    <div class="text-sm text-gray-600 font-semibold font-sans uppercase">
                                        {{ employee.Location }}</div>
                                    <span class="text-xs text-gray-400 font-semibold uppercase">Site</span>
                                </span>
                            </div>
                            <div class="flex p-2">
                                <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-buildings h-6 w-6 m-auto text-red-500" viewBox="0 0 16 16">
                                        <path
                                            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                                        <path
                                            d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                    </svg>
                                </div>
                                <span class="flex flex-col text-left p-1 pr-2">
                                    <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">
                                        {{ employee.Campaign }}</div>
                                    <span class="text-xs text-gray-400 font-semibold uppercase">Campaign</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-col mt-2">
                            <div class="flex p-2">
                                <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-hospital h-6 w-6 m-auto text-red-500" viewBox="0 0 16 16">
                                        <path
                                            d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25z" />
                                        <path
                                            d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1zm2 14h2v-3H7zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm0-14H6v1h4zm2 7v7h3V8zm-8 7V8H1v7z" />
                                    </svg>
                                </div>
                                <span class="flex flex-col text-left p-1 pr-2">
                                    <div class="text-sm text-gray-600 font-semibold font-sans uppercase">
                                        {{ employee.SSN }}</div>
                                    <span class="text-xs text-gray-400 font-semibold uppercase">SSN</span>
                                </span>
                            </div>
                            <div class="flex p-2">
                                <div class="bg-red-200 rounded-md p-1 icon flex my-auto">
                                    <svg v-if="isFemale(employee.Gender)" xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" fill="currentColor"
                                        class="bi bi-gender-female h-6 w-6 m-auto text-red-500" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-gender-male h-6 w-6 m-auto text-red-500" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8" />
                                    </svg>
                                </div>
                                <span class="flex flex-col text-left p-1 pr-2">
                                    <div class="text-sm text-gray-600 font-semibold font-sans uppercase my-auto">
                                        {{ employee.Gender }}</div>
                                    <span class="text-xs text-gray-400 font-semibold uppercase">Sex</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <div class="grid grid-cols-2 gap-2">
                <div v-for="(answer, index) in answers" :key="index"
                    class="text-left w-full flex flex-row align-center">
                    <span class="px-2 border-[1px] border-blue-300 bg-blue-200 rounded-full mr-2 text-nowrap my-auto">
                        {{ camelCaseToNormal(index) }}
                    </span>
                    <span class="border-b-[1px] border-blue-300 w-full my-auto"
                        v-if="typeof (answer) === 'object' && answer !== null">
                        <div v-if="answer.length === 0">
                            'N/A'
                        </div>
                        <div v-else>
                            <div v-for="(item, index) in answer" :key="index">
                                <i class="bi bi-heart-pulse mr-1"></i> {{ item }}
                            </div>
                        </div>
                    </span>
                    <span class="border-b-[1px] border-blue-300 w-full my-auto" v-else>
                        {{ theAnswer(answer) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div v-else>Loading...</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Avatar from '@/views/components/Utilities/avatar'
export default {
    components: {
        Avatar
    },
    data() {
        return {
            loading: true,
            answers: null,
            employee: null,
        }
    },
    computed: {
        ...mapGetters([
            'getSurveysV2Answers'
        ]),
        fullName() {
            return (this.employee.FirstName?.toUpperCase() + " " + (this.employee.MiddleName?.toUpperCase() ?? '') + " " + this.employee.LastName1?.toUpperCase() + " " + (this.employee.LastName2?.toUpperCase() ?? '')).trim();
        },
        isActive: function () {
            return this.employee.EmpStatus?.toLowerCase() === 'active';
        },
        isInactive: function () {
            return this.employee.EmpStatus?.toLowerCase() === 'inactive';
        },
        isSomethingElse: function () {
            return !this.isActive && !this.isInactive;
        },
    },
    methods: {
        ...mapActions([
            'reviewMedicalSurvey'
        ]),

        camelCaseToNormal: function (camelCaseWord) {
            let normalWords = camelCaseWord
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, function (str) { return str.toUpperCase(); });

            return normalWords;
        },
        isFemale: function (gender) {
            return gender?.toLowerCase() === 'female';
        },
        theAnswer: function (answer) {
            return answer ?? 'N/A';
        }
    },
    created() {
        this.$emit('set-title', 'Medical Survey Review');
        this.reviewMedicalSurvey(this.$route.params.id).then(() => {
            if (this.getSurveysV2Answers?.answer) {
                this.answers = JSON.parse(this.getSurveysV2Answers.answer);
                this.employee = this.getSurveysV2Answers.employee
            }
            this.loading = false;
        })
    }
}
</script>