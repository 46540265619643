<template>
<div class="main">
  <b-container fluid>
      <b-row>
          <b-col class="mt-2" offset-md="1" md="4" lg="4" xl="5">
           <div>
                <b-card>
                        <b-form-select v-model="selected" :options="options" class="mb-3"></b-form-select>                         
                </b-card>
            </div>
          </b-col>
      </b-row>
      <b-row>
          <b-col class="mt-2" offset-md="1" md="10" lg="10" xl="10">
            <div>
                <b-card bg-variant="light" header="I.DelAngel1 Schedule's" class="text-left">
                   <blockquote class="blockquote ml-0 mb-2">Current Schedule</blockquote>
                   <b-row>                       
                        <b-col>
                             <FullCalendar
                                class='demo-app-calendar'
                                ref="fullCalendar"
                                defaultView="timeGridWeek"
                                :header="{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: ''
                                }"
                                :eventResizableFromStart="eventResizable"
                                :editable="editable"
                                :plugins="calendarPlugins"
                                :weekends="calendarWeekends"
                                :events="calendarEvents"
                                @dateClick="handleDateClick"
                                />
                        </b-col>
                   </b-row>
                </b-card>
            </div>
          </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import FullCalendar         from '@fullcalendar/vue'
import dayGridPlugin        from '@fullcalendar/daygrid'
import timeGridPlugin       from '@fullcalendar/timegrid'
import interactionPlugin    from '@fullcalendar/interaction'

export default {
  name: '',
  components: {
      FullCalendar
    },
  data() {
      return {
          selected: null, 
          options: [
              { value: null, text: 'Please select an employee' },
              { value: 'a', text: 'I.DelAngel1' },
              { value: 'b', text: 'I.DelAngel2' },
            ],
          startTime: '08:00:00',
          endTime: '17:00:00',
          eventResizable: true,
          editable: true,
          calendarPlugins: [
               dayGridPlugin,
               timeGridPlugin,
               interactionPlugin
            ],
          calendarWeekends: true,
          calendarEvents: [
                { 
                title: 'Event Now', 
                start  : '2020-04-26T08:00:00',
                end: '2020-04-26T17:00:00',
                },
                { 
                title: 'Event Now', 
                start  : '2020-04-27T08:00:00',
                end: '2020-04-27T17:00:00',
                },
                { 
                title: 'Event Now', 
                start  : '2020-04-28T08:00:00',
                end: '2020-04-28T17:00:00',
                },
                { 
                title: 'Event Now', 
                start  : '2020-04-29T08:00:00',
                end: '2020-04-29T17:00:00',
                },
                { 
                title: 'Event Now', 
                start  : '2020-04-30T10:00:00',
                end: '2020-04-30T20:00:00',
                }
            ],
          title:'Employee Schedule Manager'
        }
    },
      methods: {
          toggleWeekends() {
              this.calendarWeekends = !this.calendarWeekends // update a property
            },
            gotoPast() {
            let calendarApi = this.$refs.fullCalendar.getApi() // from the ref="..."
            calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
            },
            handleDateClick(arg) {
            if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
                this.calendarEvents.push({ // add new event data
                title: 'New Event',
                start: arg.date,
                allDay: arg.allDay
                })
            }
        }
    },
  mounted() {
    this.$emit('set-title',this.title);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.startTime{

}
.endTime{

}
.fields{
    float: left !important;
}
.alignIcon{
    top: 4px;
    position: relative;
}
.nav-link > a{
    color: gray;
}
</style>
