<template>
    <div class="flex bg-white shadow-sm rounded-lg max-h-[650px] overflow-hidden mb-10">
        <div class="relative max-h-fit min-w-[350px] border-r overflow-auto">
            <component :key="ListKey" @ticket_selected="openTicket" :is="'ticketsTable'" class="mt-3"></component>
        </div>
        <div v-if="this.showViewer" class="max-h-fit flex-1">
            <component :key="ViewKey" @updateAll="forceRerender" :ticketSelected="this.ticketSelected" :userType="this.thisUserType" :is="'ticketViewer'"></component>
        </div>
    </div>
</template>
<script>
    import ticketsTable                         from'@/views/components/Tickets/tables/review_tickets_table';
    import ticketViewer                         from'@/views/components/Tickets/tables/my_ticket_viewer'
    import {mapGetters,mapState, mapActions }   from 'vuex'
    import auth                                 from "@/config/user"
    export default {
        components: { 
            ticketsTable,
            ticketViewer
        },
        data(){
            return{
                ListKey: 0,
                ViewKey: 0,
                ticketSelected: 0,
                showViewer: false,
                thisUserType: "moderator"
            }
        },
        computed: {
            ...mapState([
            'employees'
            ]),
            ...mapGetters([
            'infoEmployee'
            ])
        },
        created(){
            let idEmployee = auth.currentUser.EmpID;
            this.loadMyProfileByEmpId(idEmployee);
        },
        methods: {
        ...mapActions([
        'loadMyProfileByEmpId'
        ]),

            closeModal: function(){
                this.$refs['AddNewTicket'].hide()
                this.ListKey++;
            },
            
            openTicket: function(data){
                this.ViewKey++;
                this.ticketSelected = data;
                this.showViewer = true;
            },

            forceRerender(){
                this.ListKey++;
                this.ViewKey++;
                this.showViewer = false
            }
        },

        mounted(){
            let title = 'Tickets Review';
            this.$emit('set-title',title);
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>