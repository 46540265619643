<template>
    <div>
        <training-details/>
        <div class="flex space-x-10 mt-10">
            <div class="space-y-2">
                <button type="button" class="bg-gray-700 text-white text-sm px-3 py-2 rounded-sm flex gap-2 shadow-sm" id="menu-button" aria-expanded="true" aria-haspopup="true" @click.prevent="$router.push({ path:`/recruitment/trainings/${training_id}/attendance`})" ><i class="bi bi-arrow-return-left"></i>Back to class</button>
                <div class="sticky top-20 flex flex-col bg-white rounded-lg shadow-sm px-3 py-6">
                    <a v-for="item in tabs" :key="item.window" :class="{'bg-blue-50 !text-blue-600' : window === item.window}" @click="changeWindow(item.window)" class="group text-left tracking-tight flex items-center justify-between hover:text-blue-600 text-slate-600 rounded px-3 py-2 !no-underline whitespace-nowrap cursor-pointer">
                        <span class="mr-2"><i class="bi mr-2" :class="window === item.window ? 'bi-'+item.icon+'-fill':'bi-'+item.icon"></i>{{ item.title }}</span>
                        <!-- <span :class="{'!bg-blue-600 text-white' : window === item.window}" class="bg-slate-100 text-slate-400 group-hover:bg-blue-600 group-hover:text-white text-sm px-3 rounded-full">{{ item.badge }}</span> -->
                    </a>
                </div>
                <button type="button" class="bg-gray-700 ml-auto text-white text-sm px-3 py-2 rounded-sm flex gap-2 shadow-sm" id="menu-button" aria-expanded="true" aria-haspopup="true" @click.prevent="showStepperComponent"><i class="bi bi-send-fill"></i>Save</button>
            </div>
            <div class="flex-1 overflow-auto">
                <attendance v-if="window === 'attendance'" />
                <training-hours v-if="window === 'training_hours'" />
                <attrition-risk v-if="window === 'attrition_risk'" />
                <class-note v-if="window === 'class_note'" />
                <staff-attendance v-if="window === 'staff_attendance'" />
                <class-activities v-if="window === 'class_activities'" />
                <notes v-if="window === 'trainee_notes'" />
                <commitments v-if="window === 'trainee_commitments'" />
                <observations v-if="window === 'trainee_observations'" />
            </div>
        </div>
    </div>
</template>
<script>
import TrainingDetails      from '../../../components/misc/trainings/TrainingDetails';
import ClassNote            from '../components/ClassNote';
import StaffAttendance      from '../components/StaffAttendance';
import AttritionRisk        from '../components/AttritionRisk';
import Notes                from '../components/Notes';
import Commitments          from '../components/Commitments';
import Observations         from '../components/Observations';
import ClassActivities      from '../components/ClassActivities';
import Attendance           from '../components/Attendance';
import TrainingHours        from '../../../components/misc/TrainingHours';
import { mapActions }       from 'vuex';
import debounce             from 'debounce'

export default {
  components: {
    TrainingDetails,
    Attendance,
    TrainingHours,
    AttritionRisk,
    ClassNote,
    StaffAttendance,
    ClassActivities,
    Notes,
    Commitments,
    Observations,
  },
  data(){   
    return {
        tabs: [
            { title: 'Attendance', window: 'attendance', icon: 'journal-bookmark', badge: 'In Progress' },
            { title: 'Training Hours', window: 'training_hours', icon: 'clock', badge: 'In Progress' },
            { title: 'Attrition Risk (notes)', window: 'attrition_risk', icon: 'journal-bookmark', badge: 'Pending' },
            { title: 'Class Notes', window: 'class_note', icon: 'journal-bookmark', badge: 'In Progress' },
            { title: 'Trainee Notes', window: 'trainee_notes', icon: 'journal-bookmark', badge: 'Pending' },
            { title: 'Trainee Commitments', window: 'trainee_commitments', icon: 'check-circle', badge: 'Pending' },
            { title: 'Trainee Observations', window: 'trainee_observations', icon: 'info-circle', badge: 'Pending' },
            { title: 'Class Activities', window: 'class_activities', icon: 'info-circle', badge: 'Pending' },
            { title: 'Staff Attendance', window: 'staff_attendance', icon: 'people', badge: 'Pending' },
        ],
        training_id: null
    }
  },
  computed:{
        window () {
            return this.$route.name.split('.').pop();
        }
    },
  methods: {
    ...mapActions([
        'checkTrainingStatus',
        'saveTrainingDaily'
    ]),
    changeWindow: function(id){
        this.$router.push({ name: `daily.${id}` });
    },

    showStepperComponent: debounce( async function(){
      const needsToClockOutTrainees = await this.needsToClockOutTrainees();
      if(needsToClockOutTrainees){
        return this.$fire({
          type:'warning',
          title:'Clock out every trainee before sending the daily summary.'
        })
      }
      this.completeSummary();
    },750),
    
    needsToClockOutTrainees: function(){
      return this.checkTrainingStatus(
        this.$route.params.id
      ).then((res)=>{
          return res.data.status;
      });
    },

    completeSummary:async function(){
        let {value :confirmation} =await  this.$fire({
            type:'question',
            'title':'Are you sure you want to submit? You wont be able to modify this daily afterwards',
            showConfirmButton:'true',
            showCancelButton: true,
            confirmButtonText: `Submit`,
            cancelButtonText: `Cancel`,
        });
        if(!confirmation) return;
        this.saveTrainingDaily({training_id:this.$route.params.id})
        .then(() =>{
            this.$router.push({ path:`/recruitment/trainings/${this.$route.params.id}/attendance`});
        });
    }
  },
  created() {
    this.$emit('set-title', `Trainings - Daily`);
    this.training_id = this.$route.params?.id;
  },
}
</script>