import axios from '@/config/axios'
import auth from '@/config/user'
import { API_URL } from '../actions/config'

const state = {
    unreadMessages: [],
    isVisible: false,
};

const getters = {
    getIsVisible:       (state) => state.isVisible,
    getUnreadMessages:  (state) => state.unreadMessages,
};

const actions = {

    async fetchUnreadNotifications ({commit}){   
        const endpoint = API_URL + 'api/v1/employees/notifications/unread/'+ auth.currentUser.EmpID;
        await axios.get(endpoint).then((response) => {
            commit('setUnreadNotifications', response.data.unreadNotifications);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
    },

    async clearNotifications ({commit}){   
        const endpoint = API_URL + 'api/v1/employees/notifications/clear/'+ auth.currentUser.EmpID;
        await axios.get(endpoint).then(() => {
            commit('setUnreadNotifications', []);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
    },
    changeVisibleStatus ({commit}){        
        commit('setIsVisibleStatus', !state.isVisible)
    }
};

const mutations = {
    setIsVisibleStatus:     (state, params) => (state.isVisible = params),
    setUnreadNotifications: (state, params) => (state.unreadMessages = params),
};

export default {
    state,
    getters,
    actions,
    mutations
};