<template>
    <tr>
        <td v-for="item in cols" :key="item">
            <div class="text-gray-700 animate-pulse p-2 py-3">
                  <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    props:{
        cols:{
            default:1,
            type:Number
        }
    }
}
</script>
