<template>
    <div class="flex">
        <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1 bg-white p-2 rounded-[0.5rem] shadow-sm max-w-6xl">

            <employee @toggle="updateFilters"/>
        
            <div class="border-l-2 border-gray-200 p-2 px-3 flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 my-auto">
                <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                </svg>

                <multiple-dropdown :options="getLocations" v-model="form.site">
                    Site...
                </multiple-dropdown>
            </div>
            
            <div class="border-l-2 border-gray-200 p-2 px-3 flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 my-auto">
                    <path fill-rule="evenodd" d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z" clip-rule="evenodd" />
                </svg>
                
                <multiple-dropdown :options="departmentNames" v-model="form.department">
                    Departments...
                </multiple-dropdown>
            </div>

            <div class="border-l-2 border-gray-200 p-2 px-3 flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 my-auto">
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
                
                <multiple-dropdown :options="getAllCampaigns" v-model="form.campaign">
                    Campaign...
                </multiple-dropdown>
            </div>

            <div class="border-l-2 border-gray-200 p-2 px-3 flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 my-auto">
                <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                </svg>

                <multiple-dropdown :options="status_options" v-model="form.status">
                    Status...
                </multiple-dropdown>
            </div>

            <button @click="clear" class="flex bg-gray-600 p-2 px-3 rounded-xl gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill my-auto" viewBox="0 0 16 16">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                </svg>
                Reset
            </button>
            
        </div>
    </div>
</template>
<script>
import Debounce                     from 'debounce';
import { mapGetters, mapActions }   from 'vuex';
import Employee                     from './employee';
import permissions                  from '@/config/mixins'
import MultipleDropdown             from '@/views/Utilities/MultipleDropdown.vue'
export default {
    data(){
        return{
            CurrentPermissions:'',
            filtersLoaded: false,
            form:{
                site: [],
                employee: '',
                status: ['pending'],
                department: [],
                campaign: [],
                reset: false
            },
            status_options: ['completed', 'pending', 'cancelled'],
        }
    },
    mixins:[permissions],
    components:{
        Employee,
        MultipleDropdown
    },
    computed:{
        ...mapGetters([
            'getLocations',
            'getAllCampaigns',
            'getDepartments'
        ]),
        isOperations () {
            return this.itemContains('Supervisor') || this.itemContains('AccountManager');
        },
        departmentNames(){
            return this.getDepartments.map((department) => department?.label)
        }
    },
    methods:{
        ...mapActions([
            'fetchSites',
            'fetchCampaigns',
            'fetchDepartments'
        ]),
        itemContains(n) {
            return this.hasPermission(n)
        },
        clear:function(){
            this.form.site = [];
            this.form.employee = '';
            this.form.status = [];
            this.form.department = [];
            this.form.campaign = [];
            this.form.reset = true;
        },
        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value?.toUpperCase();
        },350),
    },
    watch:{
        form:{
            handler(){
                this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    mounted(){
        this.$emit('toggle', this.form);
        window.addEventListener('click', this.closeMenu);
    },
    created(){
        if(this.isOperations) this.form.status = ['completed', 'pending', 'cancelled'];
        this.filtersLoaded = false;
        this.fetchSites();
        this.fetchDepartments();
        this.fetchCampaigns().then(() => {
            this.filtersLoaded = true;
        })
    }

}
</script>