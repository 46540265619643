import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
   lastEntry: [],
   allEntries: [],
};
const getters = {
    getAllEntries:  (state) => state.allEntries,
};
const actions = {

    async storeNewEntry ({ commit }, data){        
        const endpoint = API_URL + 'api/v2/files/newsfeed/newEntry';
        const response = await axios.post(endpoint, data).then((response)=>{
            if(response.status === 200){
                commit('setEntryObject', response.data.newEntry);
            }
            return response;
        }).catch((error) => {
            commit('setFileStatus',false);
            return error.response;
        });    
        return response;
    },

    async fetchAllEntries ({commit}){   
        const endpoint = API_URL + 'api/v2/files/newsfeed/getEntries';
        await axios.get(endpoint).then((response) => {
            commit('setAllEntries', response.data.newsfeeds);
        }, error => {
            console.error("Something wrong while getting entries. Error: "+ error)
        });
    },
};
const mutations = {
    setEntryObject: (state, data) => (state.lastEntry = data),
    setAllEntries:  (state, data) => (state.allEntries = data)  
};
export default {
    state,
    getters,
    actions,
    mutations
};