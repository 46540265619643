<template>
  <div class="main w-full  m-0">
    <b-container fluid>
     
      <b-row>
        <b-col align-self="center" class="mt-2 mb-5">
          <div>
            <div>
              <div id="EditTable" class="userTable">
                <b-container fluid class="TableSearch">
                  <b-row>
                    <b-col>
                      <p style="font-style:italic">"Here are listed all the agents of your team"</p>
                    </b-col>
                  </b-row>
                  <b-row v-if="this.itemsContains('SystemAdministrator') || this.itemsContains('AccountManager')" >
                    <b-col>
                      <component :key="keyTeamComponent" @loadTableByTeam='setTeamSelected' :is="'teamComponent'"></component>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col align-self="center" class="mt-2 rounded shadow-sm" md="12" lg="12" xl="12">
                      <component v-if="this.TodayIs" :loadInfoByTeam="this.teamSelected" :is="'hoursReviewTableFriday'"></component>
                      <component v-else :loadInfoByTeam="this.teamSelected" :is="'hoursReviewTable'"></component>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>    
    </b-container>
  </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<script>
import {mapState, mapActions } from 'vuex'
import hoursReviewTable from "@/views/components/Clocker/tables/hoursReviewTable"
import hoursReviewTableFriday from "@/views/components/Clocker/tables/hoursReviewTableFriday"
import teamComponent from "@/views/components/Dropdown/teams"
// import moment from 'moment'
import auth   from "@/config/user"
export default {
  data() {
    return {
      userPermissions: null,
      keyword: "",
      fullPage: false,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: "dots",
      isDisabled: true,
      keyTeamComponent: 0,
      teamSelected: '',
      TodayIs: null,
    };
  },
  components: {
   hoursReviewTable, 
   hoursReviewTableFriday, 
   teamComponent
  },
  computed: {
    ...mapState(["clockerUsers"]),
  },
  created(){
    this.userPermissions = this.getPermissions();
    this.getDay();
  },
  methods: {
      ...mapActions(['fetchMyApprovalHoursByTeam']),

      getPermissions: function(){
        let tokenInfo = auth.currentUser.roles;
        this.CurrentPermissions = tokenInfo;
      },

      getUsername: function(){
        let EmpID = auth.currentUser.EmpID;
        if (EmpID) { 
          this.username = EmpID; 
        }
      },

      itemsContains(n) {
        return this.CurrentPermissions.indexOf(n) > -1
      },
      setTeamSelected(value){
        this.fetchMyApprovalHoursByTeam(value).then(()=>{
          this.keyHoursReviewTable ++;
        });
        
      },
      getDay(){
        let currentDate = new Date();
        let newDayIs = new Date('06-04-2021');
        currentDate.setHours(0, 0, 0, 0);
        this.TodayIs = currentDate.valueOf() == newDayIs.valueOf();

          // this.TodayIs = moment().isoWeekday();
          // v-if="this.TodayIs == 5"
      }
  },
  mounted() {
    let title = 'Supervisor Hour Review';
    this.$emit('set-title',title);
  }
};
</script>
<style lang="scss" scoped>
.EditTable {
  display: none;
}
.margins{
  margin-right: 3em;
}
.md-field {
  max-width: 300px;
}
.TableSearch {
  margin: 2em 0 0 0 !important;
}
.material-icons.viewIcon {
  font-size: 2em;
  cursor: pointer;
}
.btn-primary.disabled,
.btn-primary:disabled {
  cursor: no-drop;
}
</style>