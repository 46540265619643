<template>
    <transition name="fade">
        <div id="NotificationsComponent" @blur="close" style="display:none" class="positionMenu bg-white absolute flex flex-col max-w-lg w-4/12 z-40 rounded-lg shadow-lg"> 
            <div class="xl:px-3 grid grid-cols-2 items-center rounded-lg z-10 py-1 px-5">
                <span class="text-left text-xl font-bold">Notifications</span>
                <span v-if="getUnreadMessages.length" class="justify-self-end text-right rounded-lg">
                    <button class="underline hover:bg-gray-100 p-2" @click="clear" v-b-tooltip.hover.top="'Clear All'">Clear All</button>
                </span>
            </div>
            <hr class="p-0 m-0 mx-auto w-11/12 border-2">
            <div v-if="getUnreadMessages.length" class="rounded-lg pb-2 max-h-80 overflow-y-auto">
                <div v-for="notification in getUnreadMessages" :key="notification.id">
                    <div v-if="notification.data.link">
                        <router-link :to="{ name: notification.data.link.name, params: notification.data.link.params  }" class="grid grid-cols-6 group hover:bg-gray-200 py-2 p-1 no-underline hover:no-underline text-gray-700 hover:text-gray-700">
                            <div class="mx-auto col-start-1">
                                <div class="h-12 w-12 border-opacity-40 border-transparent rounded-full bg-gray-400 flex">
                                    <i class="bi bi-link-45deg text-gray-50 text-2xl m-auto"></i>
                                </div>
                            </div>
                            <div class="ml-2 pr-2 col-span-5 col-start-2 flex">
                                <span class="text-left">{{notification.data.message}}</span>
                            </div>
                        </router-link>
                    </div>
                    <div v-else class="grid grid-cols-6 group hover:bg-gray-200 py-2 p-1 no-underline hover:no-underline text-gray-700 hover:text-gray-700">
                        <div class="mx-auto col-start-1">
                            <div class="h-12 w-12 border-opacity-40 border-transparent rounded-full bg-gray-400 flex">
                                <i class="bi bi-bell-fill text-gray-50 text-2xl m-auto"></i>
                            </div>
                        </div>
                        <div class="ml-2 pr-2 col-span-5 col-start-2 flex">
                            <span class="text-left">{{notification.data.message}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="getUnreadMessages.length != 0" class="items-center flex flex-row w-full hover:bg-gray-200 cursor-pointer p-1">
                <span class="self-center mx-auto py-2">
                    <a href="#" class="text-sm self-center">See all recent notifications</a>
                </span>
            </div> -->
            <div v-else class="rounded-lg bg-white pb-2">
                <div class="items-center flex flex-row w-full cursor-pointer p-1">
                    <span class="self-center mx-auto py-2">
                        You don't have new notifications.
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
 import auth                                 from "@/config/user"
export default {
    data(){
        return{
            username: '',
        }
    },
    computed:{
        ...mapState([
            "notifications"
        ]),
        ...mapGetters([
            "getUnreadMessages",
        ]),
    },
    methods:{
        ...mapActions([
          'fetchUnreadNotifications',
          'clearNotifications',
        ]),
        toggleNotifications: function(){
            this.changeNotificationStatus()
        },
        getUsername: function(){
            let EmpID = auth.currentUser.EmpID;
            if (EmpID) { 
                this.username = EmpID; 
            }
        },
        close: function(event){
            console.log(event);
        },
        clear: function(){
            this.clearNotifications();
        },
    },
}

</script>
<style scoped>
.positionMenu{
    top: 4rem;
    right: 12rem;
}
</style>