<template>
    <div class="relative">
        <span v-if="this.getComponentMessages.length" class="absolute block right-1 px-1 bg-red-600 text-white text-xs font-bold rounded-full">
            {{ this.getComponentMessages.length }}
        </span>
        <button v-on:click="this.toggleNotifications" class="text-xl p-1 mx-2 hover:text-c-primary" v-b-tooltip.hover.top="'Messages Center'">
            <i class="bi bi-chat-left-text"></i>
        </button>
        <div class="absolute top-full right-0 bg-white rounded shadow-md min-w-[300px] overflow-auto max-h-72" id="message-notifications">
            <div class="messages-list divide-y w-full" v-if="this.getComponentMessages.length && this.componentIsReady">
                <div id="messagesContainer" class="hover:bg-gray-100 hover:cursor-pointer pr-3" v-for="msg in this.getComponentMessages" :key="msg.message_mast_id">
                    <div v-if="msg.Message_Status == MessageStatus.ACTIVE" @click.prevent="msg.Message_Status == MessageStatus.ACTIVE ? goView(msg.message_mast_id) : goView()" :id="msg.message_mast_id" class="massage-item text-left py-3">
                        <div class="d-flex">
                            <div class="flex-1 d-flex px-3">
                                <i :class="(msg.Message_Type == MessageStatus.INCOMPLETE ? 'bi bi-person bg-green-100 text-green-600':'bi bi-broadcast bg-red-100 text-red-600')+' inline-block w-6 h-6 text-sm rounded-full text-center mr-3'"></i>
                                <div>
                                    <p class="text-base leading-none mb-0">{{msg.Title}}</p>
                                    <h4 class="text-xs leading-none text-gray-400 mb-0">{{msg.CreatedBy}}</h4>
                                    <span class="text-xs block text-gray-400 hidden">{{msg.CreatedAt}}</span>
                                </div>
                            </div>
                            <div class="flex-none">
                                <span class="inline-block w-4 h-4 rounded-full bg-gradient-to-b from-cyan-500 to-blue-700 align-middle mr-2"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="this.componentIsReady && (this.getComponentMessages.length === '0')">
                <p class="text-center text-gray-400 my-3">Loading...</p>
            </div>
            <div v-else>
                <p class="text-center text-gray-400 my-3">No messages found</p>
            </div>
        </div>
    </div>
</template>
<script>
    //import auth from "@/config/user"
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: { },
        data(){
            return {
                componentIsReady: false,
                loadingMsg: true,
                all: 0,
                MessageStatus: {
                    ACTIVE: 1,
                    INCOMPLETE: 2,
                    INACTIVE: 3,
                },
            }
        },
        validations: {
            
        }, 
        computed:{
            ...mapState(['messages']),
            ...mapGetters(['getComponentMessages'])
        },
        created(){
            this.fetchMessagesOnComponent().then(()=>{
                this.componentIsReady = true;
            });
        },
        methods:{
            ...mapActions([
                'fetchMessagesOnComponent',
            ]),
            goLink:function(){
                this.$router.push({ name: 'msg.create' });
            },
            goView:function(id){
                if(id) {
                    var notificationElement = document.getElementById("message-notifications");
                    notificationElement.style.display = 'none';
                    window.location.href = '/messages/view/'+id;
                }
            },
            toggleNotifications: function(){
                var notificationElement = document.getElementById("message-notifications");
                if(notificationElement.style.display === 'none' || notificationElement.style.display === ''){
                    notificationElement.style.display = 'block';
                }else{
                    notificationElement.style.display = 'none';
                }
            },
        },
        mounted(){
            let title = 'Messages Center';
            this.$emit('set-title',title);
        }
    };
</script>
<style scoped>
    .bi::before {
        line-height: 1.8;
    }

    #message-notifications{
        display: none;
    }
</style>