<template>
    <div>
        <div v-if="getCommentsTermination && getCommentsTermination.length">
            <div class="space-y-4 max-h-[400px] px-2 overflow-auto">
                <div class="relative bg-amber-100 rounded-[3px] drop-shadow-[0_5px_2px_rgba(0,0,0,0.1)] p-3" v-for="item in getCommentsTermination" :key="item.ID">
                    <h5 class="flex justify-between items-center text-sm text-amber-900 border-b-[1px] border-amber-900/10 pb-2 mb-2">
                        <span class="font-semibold"><i class="bi bi-person-fill mr-1"></i>{{ item.CreatedBy }}</span>
                        <div>
                            <a v-if="item.File" class="inline-block bg-amber-800 hover:bg-amber-600 rounded-full text-white leading-[1.6] px-3 !no-underline mr-4" target="_blank" :href="getLink(item.File)"><i class="bi bi-folder-symlink-fill align-middle"></i> File</a>
                            <span class="text-amber-800">{{ item.CreatedAt }}</span>
                        </div>
                    </h5>
                    <div v-if="item.Comment" class="text-left text-gray-800 leading-none">{{ item.Comment }}</div>
                </div>
            </div>
        </div>
        <div v-else>
            <p class="text-gray-300 italic text-center">No Comments</p>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        termination_id: {
            required: true,
            type: Number
        }
    },
    computed: {
        ...mapGetters([
            'getCommentsTermination'
        ]),
    },
    methods: {
        ...mapActions([
            'fetchCommentsTermination'
        ]),
        getLink (name) {
            if(name?.startsWith("http")) return name;
            return 'https://centris-documents.website-us-southeast-1.linodeobjects.com/linode_resignations/' + name;
        },
    },
    created () {
        this.fetchCommentsTermination(this.termination_id);
    }
}
</script>