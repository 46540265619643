<template>
<div class="main py-2">
  <b-container fluid class="containerMargins">
      <b-row >
        <b-col align-self="center" class="mt-2">
          <div>
              <adjustmentsRequest></adjustmentsRequest> 
          </div>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>
<script>
import adjustmentsRequest from '@/views/components/Clocker/tables/adjustmentsRequest';
export default {
  name: '',
  components: { adjustmentsRequest },
  mounted(){
    let title = 'Adjustments Dashboard';
    this.$emit('set-title',title);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
