<template class="w-full h-full">
    <div class="m-4 justify-content-center text-left">
        <form-viewer @afterAnswer="afterAnswer" :key="keyWord" @alreadyAnswered="alreadyAnswered" :formSelected="2"/>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FormViewer from '../../formbuilder/visualization/formViewer.vue';
export default {
    components: {
        FormViewer
    },
    data(){
        return {
            AnswersData:    [],
            keyWord:   0
        }
    },
    computed: {
        ...mapState([]),
        ...mapGetters([])
    },
    methods: {
        ...mapActions([]),

        afterAnswer: function(){
            console.log("reached")
            this.keyWord++
        },
        alreadyAnswered: function(){
            console.log("reached")
        }
    },
    mounted() {
        let title = 'Maintenance Form'
        this.$emit('set-title',title);
    }
}
</script>
<style scoped>

</style>