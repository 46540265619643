<template>
<div>
      <transition name="slide-fade">
        <div class="" v-show="this.showContent">
            <slot></slot>
        </div>
      </transition>
    <a class="text-blue-600 hover:underline pt-2" @click="open= !open" href="#">
        Read {{this.showContent?'Less':'More'}}     
    </a>
</div>
</template>
<script>
export default {
    data(){
        return {
            open:false
        }
    },
    computed:{
        showContent:function(){
            return this.open;
        }
    },

}
</script>
<style scoped>
/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>