import axios from '@/config/axios';
import moment from "moment";

const state = {
    details:[],
    error:false
};

const getters = {
    training: (state) => state.details,
    
    anyError: (state)=> state.error,

    date_start: (state) => {
        let time = new moment(state.details?.start_date);

        return time.format('MMMM DD');
    },

    date_end: (state) => {
        let time = new moment(state.details?.end_date);

        return time.format('MMMM DD');
    },

    time_start: (state) => {
    
        let time = state.details.schedule_start?.split(':');
        time.pop();

        return time.join(':');
    },

    time_end: (state) => {
        
        let time = state.details.schedule_end?.split(':');
        time.pop();

        return time.join(':');
    },
};

const actions = {
    async fetchTrainingDetailsById({commit}, id) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/fetchById/${id}`);
            commit('SET_TRAINING_DETAILS', response.data.training);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    }
};

const mutations ={
    SET_TRAINING_DETAILS: (state, details) => { state.details = details },
    SET_ERROR: (state, status) => { state.error = status }
};

export default {
    state,
    getters,
    actions,
    mutations
};