<template>
    <div class="flex h-full w-full" v-if="is_ready">
        <div class="w-1/6 py-3 pl-3">
            <div class="flex flex-col justify-between h-full">
                <div
                    v-for="hour in hours"
                    :key="hour"
                    class="text-xs text-gray-500 border-t-2 border-gray-400 text-right"
                >
                    {{ hour }}
                </div>
            </div>
        </div>

        <div
            v-if="shiftIsNotEmpty"
            class="relative w-5/6 bg-gray-100 border-l-2 border-gray-400 h-full"
        >
            <div
                v-for="shift in shifts"
                :key="shift.id"
                class="absolute mx-2 bg-gradient-to-r from-blue-400/100 via-blue-400/40 to-blue-400/70 rounded-xl opacity-70 shadow-md overflow-hidden"
                :style="getShiftStyle(shift)"
            >
                <div v-if="shift.events.length" class="relative h-full w-full">
                    <div
                        :text="event.event"
                        v-for="event in shift.events"
                        :key="event.id"
                        class="absolute bg-gradient-to-r from-c-secondary/40 to-c-secondary/100 rounded-lg w-full cursor-pointer hover:border-c-secondary hover:border-[2px]"
                        :style="getEventStyle(event, shift)"
                        @mousemove="moveTooltip($event, event)"
                        @mouseleave="hideTooltip"
                    ></div>
                </div>
            </div>
            <div
                v-if="tooltip_visible"
                class="absolute bg-gray-800/50 text-white text-xs rounded shadow-lg p-2 z-40"
                :style="tooltip_position"
            >
                <p>{{ hovered_event.event }}</p>
                <span
                    >from {{ hovered_event.start_date }} {{ hovered_event.start_time }} {{ hoveredEnd }}</span
                >
            </div>
        </div>
        <data
            v-else
            class="relative w-5/6 bg-gray-100 border-l-2 border-gray-400 h-full flex justify-center items-center"
        >
            <span
                class="text-gray-400 italic p-2 bg-gray-200 rounded-xl font-normal text-sm drop-shadow-md"
            >
                No shifts found
            </span>
        </data>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        shifts: Array,
    },
    data() {
        return {
            hours: Array.from({ length: 24 }, (_, i) => `${i}:00`),
            tooltip_visible: false,
            hovered_event: null,
            tooltip_position: { top: "0px", left: "0px" },
            is_ready: false,
        };
    },
    computed: {
        ...mapGetters(["getCurrentTime"]),
        hoveredEnd() {
            return this.hovered_event.end_time
                ? `to ${this.hovered_event.end_date} ${this.hovered_event.end_time}`
                : `and is on-going`;
        },
        shiftIsNotEmpty() {
            return this.shifts.length > 0;
        },
    },
    methods: {
        ...mapActions(["getTimeFromServer"]),
        getShiftStyle(shift) {
            const startPercent = this.calculateTimePosition(shift.start_time);
            const endPercent = shift.end_time
                ? this.calculateTimePosition(shift.end_time)
                : this.calculateTimePosition(this.callCurrentTime());

            return {
                top: `${startPercent}%`,
                height: `${endPercent - startPercent}%`,
                left: "0",
                right: "0",
            };
        },
        getEventStyle(event, shift) {
            const currentTimeInSeconds = this.timeToSeconds(
                this.callCurrentTime()
            );

            const shiftStartInSeconds = this.timeToSeconds(shift.start_time);
            const shiftEndInSeconds = shift.end_time
                ? this.timeToSeconds(shift.end_time)
                : currentTimeInSeconds;
            const shiftDurationInSeconds =
                shiftEndInSeconds - shiftStartInSeconds;

            const eventStartInSeconds = this.timeToSeconds(event.start_time);
            const eventEndInSeconds = event.end_time
                ? this.timeToSeconds(event.end_time)
                : currentTimeInSeconds;

            const startPercent =
                ((eventStartInSeconds - shiftStartInSeconds) /
                    shiftDurationInSeconds) *
                100;
            const endPercent =
                ((eventEndInSeconds - shiftStartInSeconds) /
                    shiftDurationInSeconds) *
                100;

            return {
                top: `${startPercent}%`,
                height: `${endPercent - startPercent}%`,
            };
        },
        callCurrentTime() {
            return `${this.getCurrentTime.hours}:${this.getCurrentTime.minutes}:${this.getCurrentTime.seconds}`;
        },
        calculateTimePosition(time) {
            const [hoursMinutesSeconds] = time.split(" ");
            const [hours, minutes, seconds] = hoursMinutesSeconds
                .split(":")
                .map(Number);
            return ((hours * 3600 + minutes * 60 + seconds) / 86400) * 100;
        },
        timeToSeconds(time) {
            const [hours, minutes, seconds] = time.split(":").map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        },
        moveTooltip(event, hovered_event) {
            const tooltipOffsetX = 10;
            const tooltipOffsetY = 10;
            this.hovered_event = hovered_event;
            this.tooltip_visible = true;

            const timelineContainer = document
                .querySelector(".relative.w-5\\/6")
                .getBoundingClientRect();
            const tooltipWidth = 150;
            const tooltipHeight = 50;

            let left = event.pageX - timelineContainer.left + tooltipOffsetX;
            let top = event.pageY - timelineContainer.top + tooltipOffsetY;

            if (left + tooltipWidth > timelineContainer.width) {
                left = timelineContainer.width - tooltipWidth - tooltipOffsetX;
            }

            if (top + tooltipHeight > timelineContainer.height) {
                top = timelineContainer.height - tooltipHeight - tooltipOffsetY;
            }

            this.tooltip_position = {
                top: `${top}px`,
                left: `${left}px`,
            };
        },
        hideTooltip() {
            this.tooltip_visible = false;
            this.hovered_event = null;
        },
    },
    created() {
        this.getTimeFromServer().then(() => {
            this.is_ready = true;
        });
    },
};
</script>