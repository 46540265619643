<template>
  <button class="bg-blue-50 text-xs text-blue-300 p-2 px-3 rounded-sm hover:text-blue-500 focus:outline-none cursor-pointer"
    @click.prevent="redirectToResource"
  >
    <slot>
    Go to video
    </slot>
  </button>
</template>

<script>
export default {
  name: "CardButton",
  props:{
    redirect:{
      type:Boolean,
      default:false
    },
    link:{
      default:'home',
      type:String
    }
  },
  methods:{
    redirectToResource:function(){
      if(this.redirectEnabled){
        this.$router.push({name:this.link});
      }
    }
  },
  computed:{
   redirectEnabled:function(){
     return this.redirect;
   }
  },
  mounted(){
    
  }
}
</script>

<style scoped>

</style>