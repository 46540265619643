<template>
<div class="main">
    <!-- DISCONTINUED -->
  <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
  <b-container class="containerMargins">
      <b-row>
          <b-col align-self="center" class="mt-2 rounded shadow-sm mb-3" offset-md="2" md="10" lg="10" xl="10">
              <h1 class="">My Hours Review Detailed</h1> 
          </b-col>
      </b-row>
      <b-row v-if="isLoading" class="loading">
          <b-col class="mb-4" offset-md="2">
              <p class="font-italic font-weight-bold">"We are loading all our components..."</p>
              <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
              <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
              <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          </b-col>
      </b-row>
      <b-row v-if="!isLoading" class="mb-3">

            <!-- TODO: Adding validations for if they have one with disagree turn grey -->
            <b-col offset-md="2" align-self="center">
                <button v-if="!this.isDisagree" @click='approveAllDay' class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded" >Approve all day</button>
                <button style="cursor: no-drop" v-else disabled @click='approveAllDay' :id="'approved'" class="bg-green-300 text-white px-6 py-2 rounded" >Approve all day</button>
            </b-col>
            
            <b-col>
                <button class="bg-slate-600 hover:bg-slate-700 text-white px-6 py-2 rounded" style="background-color: #2B1A36" v-b-modal="'reportMissingEvent'" >Report Missing Event</button>
                <b-modal :id="'reportMissingEvent'" hide-footer hide-header>
                    <div>
                        <missingEventModal :approvalID="this.approval_id"></missingEventModal> 
                    </div>
                </b-modal>
            </b-col>

        </b-row>
      <b-row v-if="!isLoading" >
        <b-col align-self="center" class="mt-2" offset-md="2" md="10" lg="10" xl="10">
          <div>
              <table class="table-sm table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-center" scope="col">Activity</th>
                        <th class="text-center" scope="col">Duration</th>
                        <th class="text-center" scope="col">Start Time</th>
                        <th class="text-center" scope="col">End Time</th>
                        <th class="text-center" scope="col">After Adjustment</th>
                        <th class="text-center" scope="col">Comment</th>
                        <th class="text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody v-if="getAgentHourReviewTableDetails.length">
                <tr :id="index" v-for="(currentRow, index) in getAgentHourReviewTableDetails" :key="index">
                    <td class="text-capitalize">{{currentRow.Event}}</td>
                    <td class="text-capitalize">{{currentRow.Duration }}</td>
                    <td class="text-capitalize">{{currentRow.CreatedAt | moment("YYYY-MM-DD HH:mm:ss")}}</td>
                    <td class="text-capitalize">{{currentRow.UpdatedAt | moment("YYYY-MM-DD HH:mm:ss")}}</td>
                    <td class="text-capitalize">{{currentRow.Adjusted}}</td>
                    <td class="text-capitalize">{{currentRow.Comment ? currentRow.Comment : 'No Comment'}}</td>
                    <td class="text-capitalize">
                        <div v-if="currentRow.Status == 'NOT-FOUND'">
                            <button :id="'disagreeRequest'+index" class="bg-amber-400 hover:bg-amber-600 text-white px-6 py-2 rounded" v-b-modal="'disagreeRequest'+index">Disagree</button>
                            <b-modal :id="'disagreeRequest'+index" hide-footer hide-header>
                                <div>
                                    <disagreeRequestModal :id="'disagreeRequest'+index" :requestInfo="currentRow"></disagreeRequestModal> 
                                </div>
                            </b-modal>
                        </div>
                        <div v-else>
                            <button class="bg-gray-200 px-6 py-2 rounded" disabled>No Actions</button>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="7">No records found.</td>
                </tr>
                </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import disagreeRequestModal from '@/views/components/Clocker/modals/disagreeRequest';
import missingEventModal from '@/views/components/Clocker/modals/missingEvent';
import Loading          from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: { disagreeRequestModal, missingEventModal, Loading },
  data(){ 
      return{ 
          isLoaderActive: false,
          fullPage: true,
          color: "#F2F2F2",
          bgcolor: "#000000",
          loader: 'dots',

          approval_id: 0,
          isLoading: true,
          isDisagree: false,
       } 
  },
  computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters(['getAgentHourReviewTableDetails'])
    },
    created(){
        let row = this.$route.params.rowObj;
        if(!row){
            this.$fire({
                type: "error",
                title: "Oops! we found some issues, you will be redirected to the last page",
                showConfirmButton: true,
            }).then(() => {
              this.$router.go(-1);
            });
        }
        this.fetchMyHoursDetailed(row).then(()=>{
            if(this.getAgentHourReviewTableDetails.length){
                this.approval_id = this.getAgentHourReviewTableDetails[0].Approval_id;
            }else{
                this.approval_id = row.Approval_id;
            }
            this.isLoading = false;
        });
    },
    methods:{
        ...mapActions([
            'fetchMyHoursDetailed',
            'approveAllDayAgent',
        ]),
        pad: function(num) {
            return ("0"+num).slice(-2);
        },
        hhmmss: function(secs) {
          var minutes = Math.floor(secs / 60);
          secs = secs%60;
          var hours = Math.floor(minutes/60)
          minutes = minutes%60;
          return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
        },
        approveAllDay: function(){
            this.isLoaderActive = true;
            this.approveAllDayAgent(this.approval_id).then( ()=>{
                this.isLoaderActive = false;
                this.$fire({
                  type: 'success',
                  title: 'Approve all day successfully',
                  showConfirmButton: false,
                  timer: 1500
                }).then(()=>{ 
                    this.$router.go(-1);                
                }); 
            });
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
