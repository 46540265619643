<template>
    <div class="max-w-md mx-auto md:max-w-7xl">
        <div class="flex flex-row max-w-lg bg-white shadow-lg rounded-lg p-3 my-3 mx-auto align-items-center justify-content-center">
            <special-searchbar :key="resetKey" @toggle="filterUpdate"/>
            <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700"  @click.prevent="clearFilters">
                Clear
            </button>
        </div>

        <div class="flex justify-content-center mb-2">
            <span class="text-xs italic">
                {{this.getDaysOfWeek()}}
            </span>
        </div>
        
        <approved-days-table :weekData="this.form" @toggle="keepQueryAlive"/>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import ApprovedDaysTable from '@/views/components/Payroll/management/tables/approved-days-table'
import SpecialSearchbar  from '@/views/components/Payroll/management/filters/approved-searchbar'

export default {
    data() {
        return {
            resetKey: 0,
            form:{
                refresh :false
            }
        }
    },
    components: { ApprovedDaysTable, SpecialSearchbar },
    computed: {
        ...mapState(['payroll']),
        ...mapGetters([]),
    },
    methods: {
        ...mapActions(['filterApprovedDays']),

        clearFilters: function(){
            this.resetKey ++;
        },
        filterUpdate:function(form){
            this.form = form;
            this.keepQueryAlive();
        },
        keepQueryAlive:function(){
            this.filterApprovedDays(this.form);
            this.getDaysOfWeek();
        },
        getDaysOfWeek: function(){      
            if(this.form?.weekSelected === null || this.form?.weekSelected === undefined){
                let d = new Date();
                d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
                d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
                let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
                let week = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
                let y = d.getUTCFullYear();
                let w = week > 1 ? week - 1 : week;

                let simple = new Date(y, 0, 1 + (w - 1) * 7);
                let dow = simple.getDay();
                let ISOweekStart = new Date(y, 0, 1 + (w - 1) * 7);
                let ISOweekEnd = new Date(y, 0, 1 + (w - 1) * 7);
                if (dow <= 4){
                    ISOweekStart.setDate(ISOweekStart.getDate() - ISOweekStart.getDay() + 1 - 1);
                    ISOweekEnd.setDate(ISOweekEnd.getDate() + 6 - simple.getDay() + 1 - 1);
                }else{
                    ISOweekStart.setDate(ISOweekStart.getDate() + 8 - ISOweekStart.getDay() - 1);
                    ISOweekEnd.setDate(ISOweekEnd.getDate() + 14 - ISOweekEnd.getDay() - 1);
                }
                    
                let start = ISOweekStart.toISOString().slice(0,10);
                let end = ISOweekEnd.toISOString().slice(0,10);
                return "From " + start + " to " + end;
            }else{
                let y = this.form.yearSelected;
                let w = this.form.weekSelected;

                let simple = new Date(y, 0, 1 + (w - 1) * 7);
                let dow = simple.getDay();
                let ISOweekStart = new Date(y, 0, 1 + (w - 1) * 7);
                let ISOweekEnd = new Date(y, 0, 1 + (w - 1) * 7);
                if (dow <= 4){
                    ISOweekStart.setDate(ISOweekStart.getDate() - ISOweekStart.getDay() + 1 - 1);
                    ISOweekEnd.setDate(ISOweekEnd.getDate() + 6 - simple.getDay() + 1 - 1);
                }else{
                    ISOweekStart.setDate(ISOweekStart.getDate() + 8 - ISOweekStart.getDay() - 1);
                    ISOweekEnd.setDate(ISOweekEnd.getDate() + 14 - ISOweekEnd.getDay() - 1);
                }
                    
                let start = ISOweekStart.toISOString().slice(0,10);
                let end = ISOweekEnd.toISOString().slice(0,10);
                return "From " + start + " to " + end;
            }
        }
    },
    watch:{
        form:{
        handler:function(){
            this.filterApprovedDays(this.form);
        },
        deep:true      
        }
    },
    beforeMount(){
        this.$emit('set-title','Approved Days Management');
    }
}
</script>
