<template>
    <div class="bg-white rounded-xl drop-shadow-md p-4 text-left max-h-[280px] overflow-auto">
        <h2 class="text-2xl mb-6">My Surveys</h2>
        <div class="space-y-3" id="surveysBox">
            <button class="relative w-full items-start bg-slate-50 hover:bg-slate-100 text-left rounded-xl space-x-4 p-6 overflow-hidden hidden" 
            @click="$router.push({ name:'surveys.viewSatisfaction' })">
                <div>
                    <h5 class="text-xs uppercase text-c-primary leading-none mb-1">Survey</h5>
                    <h3 class="text-xl leading-none mb-1">Satisfaction Survey</h3>
                </div>
                <i class="absolute top-0 -right-2 -rotate-3 text-8xl bi bi-clipboard-check text-gray-400 opacity-10"></i>
            </button>

            <button class="relative w-full flex items-start bg-slate-50 hover:bg-slate-100 text-left rounded-xl space-x-4 p-6 overflow-hidden" 
            @click="$router.push({ name:'surveys.viewMedicalSurvey' })">
                <div>
                    <h5 class="text-xs uppercase text-c-primary leading-none mb-1">Survey</h5>
                    <h3 class="text-xl leading-none mb-1">Medical Survey</h3>
                </div>
                <i class="absolute top-0 -right-2 -rotate-3 text-8xl bi bi-clipboard-check text-gray-400 opacity-10"></i>
            </button>

            <div class="hidden text-center">
                <span class="m-auto text-gray-300 content-center">
                    There is no active surveys at the moment
                </span>
            </div>

            <button v-for="survey in my_surveys" :key="survey.ID" class="relative w-full flex items-start bg-slate-50 hover:bg-slate-100 text-left rounded-xl space-x-4 p-6 overflow-hidden" @click="$router.push({ name:'surveys.view', params: { id: survey.ID } })">
                <div>
                    <h5 class="text-xs uppercase text-c-primary leading-none mb-1">{{survey.type}}</h5>
                    <h3 class="text-xl leading-none mb-1">{{survey.title}}</h3>
                    <p v-if="survey.duration > 0" class="text-gray-500 mb-0"><i class="bi bi-stopwatch"></i> {{ survey.duration }} min(s).</p>
                </div>
                <i class="absolute top-0 -right-2 -rotate-3 text-8xl bi bi-clipboard-check text-gray-400 opacity-10"></i>
            </button>
            <p></p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data () {
        return {
            my_surveys: []
        }
    },
    computed: {
        ...mapGetters([
            'getSurveysV2'
        ])
    },
    methods: {
        ...mapActions([
            'fetchMyActiveSurveys'
        ])
    },
    created(){
        this.fetchMyActiveSurveys().then(() =>{
            this.my_surveys = this.getSurveysV2;
        })
    }
}
</script>
<style scoped>
#surveysBox::-webkit-scrollbar {
    display: none;
}
</style>