<template>
<div>
  <div id="EditTable" class="userTable">
    <b-container fluid class="TableSearch">
      <b-row>
        <b-col align-self="center">
          <table class="table-fixed light-table shadow-sm bg-white rounded-md overflow-hidden">
            <thead class="bg-gray-100">
              <tr>
                <th>ID</th>
                <th>Reported By</th>
                <th>Employee</th>
                <th>Campaign</th>
                <th class="w-[20px]">Info</th>
                <th>Queue</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Created At</th>
                <th>Priority</th>
                <th>Assigned To</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="getTickets.length">
              <tr :id="ticket.ID" v-for="ticket in getTickets" :key="ticket.ID">
                  <td :title="ticket.ID" class="text-sm capitalize text-clip overflow-hidden">{{ticket.ID}}</td>
                  <td :title="ticket.ReportedBy" class="text-sm capitalize text-clip overflow-hidden">{{ticket.ReportedBy}}</td>
                  <td :title="ticket.AffectedEmployee" class="text-sm capitalize text-clip overflow-hidden">{{ticket.AffectedEmployee}}</td>
                  <td :title="ticket.Campaign" class="text-sm capitalize text-clip overflow-hidden">{{ticket.Campaign}}</td>
                  <td>
                    <vs-tooltip :text="'(' + ticket.IssueDate + ') ' + ticket.TicketDescription" class="flex">
                      <i class="bi text-xl bi-info-circle-fill text-blue-500"></i>
                    </vs-tooltip>
                  </td>
                  <td class="text-sm capitalize text-clip overflow-hidden">{{ticket.queueName}}</td>
                  <td :title="ticket.categoryName" class="text-sm capitalize text-clip overflow-hidden">{{ticket.categoryName}}</td>
                  <td :title="ticket.subCategoryName" class="text-sm capitalize text-clip overflow-hidden">{{ticket.subCategoryName}}</td>
                  <td :title="ticket.CreatedAt" class="text-sm capitalize">{{ticket.CreatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
                  <td :title="ticket.PriorityLabel" class="text-sm capitalize text-clip overflow-hidden">{{ticket.PriorityLabel == null ? "Not Specified" : ticket.PriorityLabel}}</td>
                  <td :title="ticket.EmpID" class="text-sm capitalize text-clip overflow-hidden">{{ticket.EmpID == null ? "Not Assigned" : ticket.EmpID}}</td>
                  <td :title="ticket.isClose">
                    <div class="flex text-center justify-content-center">
                      <i v-if="ticket.isClose" class="bi text-xl bi-x-circle-fill text-red-500"></i>
                      <i v-else class="bi text-xl bi-check-circle-fill text-green-500"></i>
                    </div>
                  </td>
                  <td class="flex flex-row flex-1 space-x-2">
                    <i class="bi bi-pen-fill text-gray-300" v-if="ticket.isClose == 1"></i>
                    <vs-tooltip v-else text="Edit Ticket">
                      <i class="bi bi-pen-fill" v-b-modal="'editTicket'+ticket.ID"></i>
                    </vs-tooltip>
                    <vs-tooltip v-if="ticket.isClose == '1'" text="Open Ticket">
                      <i @click="openTicket(ticket.ID)" class="bi bi-unlock-fill"></i>
                    </vs-tooltip>
                    <vs-tooltip v-else text="Close Ticket">
                      <i class="bi bi-lock-fill" v-b-modal="'closeTicket'+ticket.ID"></i>
                    </vs-tooltip>
                  </td>
                  <!-- Modals -->
                    <b-modal :ref="'editModal'+ticket.ID" :id="'editTicket'+ticket.ID" hide-footer centered>
                      <EditTicket @closeModal='hideModal(ticket.ID)' :queueId="parseInt(ticket.QueueID)" :thicketId="ticket.ID"></EditTicket>
                    </b-modal>
                    <b-modal :ref="'closeModal'+ticket.ID" :id="'closeTicket'+ticket.ID" hide-footer centered>
                      <CloseTicket @closeModal='hideModal(ticket.ID)' :ticketId="ticket.ID"></CloseTicket>
                    </b-modal>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">No records found.</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</div>
</template>
<script>
    import EditTicket   from'@/views/components/Tickets/modals/editTicket';
    import CloseTicket  from'@/views/components/Tickets/modals/closeTicket';
    import {mapGetters,mapState, mapActions } from 'vuex'
    export default {

    components: { 
        EditTicket,
        CloseTicket
    },

    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
      }
    },

    computed:{
      ...mapState(['tickets']),
      ...mapGetters(['getTickets'])
    },

    created(){
    //   this.$store.dispatch('fetchtickets');
    },

    methods:{
      ...mapActions(['fetchTicketsPerQueue', 'changeStatus']),

      hideModal: function(data){
        let referencia = 'editModal'+data;
        let referencia2 = 'closeModal'+data;
        this.$refs[referencia][0].hide(data);
        this.$refs[referencia2][0].hide(data);
        this.$emit('updateTable', true);
      },

      openTicket(data){
        this.$confirm("You'll be able to edit the specs later", "Do you want to re-open this ticket?", "question").then((result) => {
          if (result){
            let objData = { ticketSelected: data }
            this.changeStatus(objData).then(()=>{
              this.$emit('toggle');
              this.$fire({
                type: "success",
                title: "Ticket Re-Opened",
                showConfirmButton: false,
                timer: 1300,
              }).then(()=>{
                this.$emit('updateTable', true);                
              }); 
            });
          }
        })
      },
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>