<template>
    <div class="px-2 rounded-full text-xs my-2 mx-2" 
        :class="statusColor"
    >
        <slot>Required</slot>
    </div>   
</template>

<script>
export default {
   props:{
       dirty:{
           default:false ,
           type:Boolean
       },
       valid:{
           default:false ,
           type:Boolean
       }
   },
   computed:{
       isValid:function(){
           return this.valid;
       },
       isDirty:function(){
           return this.dirty;
       },
       statusColor:function(){
           return !this.isDirty ? 'text-gray-500 bg-gray-100' : this.isValid ? 'bg-green-200 text-green-500' : 'bg-red-200 text-red-500';
       }
   }
}
</script>