<template>
    <div class="w-full h-full">
        <form class="w-full h-full" @submit.prevent="sendSchedule">
            <div v-if="getLoadingSchedules || loading" class="text-center mx-auto flex flex-row my-2 w-full">
                <div class="text-center p-3 rounded-md mx-auto my-3 bg-white w-64" v-for="x in 4" :key="x">
                    <span class="h1 font-bold block">
                        <div class="p-2 py-4 m-auto">
                            <div class="space-y-3 animate-pulse">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-300 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-300 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-300 rounded"></div>
                            </div>
                        </div>
                    </span>
                    <span class="text-sm font-medium tracking-wide uppercase">
                        <div class="space-y-3 animate-pulse">
                            <div class="h-2 bg-slate-300 rounded col-span-1"></div>
                        </div>
                    </span>
                </div>
            </div>
            <div v-else class="text-center mx-auto flex flex-row my-2 w-full">
                <div class="text-center p-3 rounded-md mx-auto my-3 bg-white w-64">
                    <span class="h1 font-bold block" :class="{ 'text-red-600': shiftIsOverTwelveHours }">
                        {{ isDayOff ? "Day Off" : secToTime(summaryShiftDuration) }}
                    </span>
                    <span class="text-sm font-medium tracking-wide uppercase">
                        Total Shift
                    </span>
                    <div :class="{ 'invisible': !shiftIsOverTwelveHours }" class="text-xs font-sans text-red-600">
                        Shift should not be over 12 hours!
                    </div>
                </div>

                <div class="text-center p-3 rounded-md mx-auto my-3 bg-white w-64">
                    <span class="h1 font-bold block" :class="{ 'text-red-600': eventsAreBiggerThanShift }">
                        {{ isDayOff ? "--:--" : secToTime(summaryEventsDuration) }}
                    </span>
                    <span class="text-sm font-medium tracking-wide uppercase">
                        Total Activities
                    </span>
                    <div :class="{ 'invisible': !eventsAreBiggerThanShift }" class="text-xs font-sans text-red-600">
                        Events are bigger than the shifts!
                    </div>
                </div>

                <div class="text-center p-3 rounded-md mx-auto my-3 bg-white w-64">
                    <span class="h1 font-bold block" :class="{ 'text-red-600': breakRules }">
                        {{ isDayOff ? "--:--" : secToTime(summaryBreakDuration) }}
                    </span>
                    <span class="text-sm font-medium tracking-wide uppercase">
                        Total Break
                    </span>
                    <div :class="{ 'invisible': !breakRules }" class="text-xs font-sans text-red-600">
                        Break duration is over the allowed by the shift duration!
                    </div>
                </div>

                <div class="text-center p-3 rounded-md mx-auto my-3 bg-white w-64">
                    <span class="h1 font-bold block" :class="{ 'text-red-600': lunchRules }">
                        {{ isDayOff ? "--:--" : secToTime(summaryLunchDuration) }}
                    </span>
                    <span class="text-sm font-medium tracking-wide uppercase">
                        Total Lunch
                    </span>
                    <div :class="{ 'invisible': !lunchRules }" class="text-xs font-sans text-red-600">
                        Lunch duration is over the allowed by the shift duration!
                    </div>
                </div>
            </div>

            <div class="my-2">
                <div class="flex flex-row">
                    <a href="https://centriswikiprobesled1.wordpress.com/schedule-change/"
                        class="cursor-pointer text-left text-ellipsis text-nowrap text-xs font-medium my-auto text-body hover:bg-gray-100 px-2 rounded"
                        target="_blank">
                        <i class="bi bi-info-circle-fill"></i>
                        Check Rules and Policies
                    </a>

                    <button
                        class="flex flex-row align-center font-semibold bg-green-600 text-white p-2 rounded-md text-xs ml-auto space-x-1"
                        title="Add event not listed in the table, if table is empty, it will create a shift"
                        type="button" @click.prevent="addEvent" :class="{ 'bg-gray-400': !scheduleChangesIsEmpty }">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span class="my-auto uppercase">
                            Add Event
                        </span>
                    </button>
                </div>
            </div>

            <table class="min-w-full table-auto bg-white divide-y divide-blue-50">
                <thead class="bg-gray-50">
                    <tr>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            emp id
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            event
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            date
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            start time
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            end time
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            payable
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            billable
                        </th>
                        <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">

                        </th>
                    </tr>
                </thead>
                <tbody v-if="getLoadingSchedules || loading">
                    <tr v-for="i in 2" :key="i">
                        <td class="p-2 py-4 m-auto" v-for="e in 7" :key="e">
                            <div class="space-y-3 animate-pulse">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-300 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-300 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-300 rounded"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-if="scheduleIsEmpty">
                        <td class="p-2 py-4 m-auto uppercase text-sm italic" scope="">
                            Events not Found
                        </td>
                    </tr>
                    <tr v-for="(event, index) in getSchedule" :key="index" :id="event.id"
                        :hidden="event.event === 'deleted'">
                        <td class="p-2 py-4 m-auto uppercase">
                            {{ event.EmpID }}
                        </td>
                        <td class="p-2 py-4 m-auto uppercase w-48">
                            {{ event.event }}
                            <select v-if="event.event === null" class="p-2 border-b-2 border-blue-300 w-full"
                                v-model="getSchedule[index].event" required @change="selectEvent(index, $event)">
                                <option :value="null" hidden>Choose an event...</option>
                                <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index"
                                    :label="key">
                                    <option v-for="(item, index) in elements" :billable="item.Billable"
                                        :payable="item.Payable" :value="item.break_name" :key="index" class="uppercase">
                                        {{ item.break_name }}
                                    </option>
                                </optgroup>
                            </select>
                        </td>
                        <td class="p-2 py-4 m-auto">
                            {{ event.date }}
                        </td>
                        <td class="p-2 py-4 m-auto">
                            <vue-timepicker required :value="getSchedule[index].startAt"
                                class="outline-none rounded-lg shadow-md p-2 text-bold bg-white"
                                :class="{ 'border-2 border-red-400': outOfShift(getSchedule[index]) }"
                                :second-range="[0]" :disabled="!scheduleChangesIsEmpty" drop-direction="auto" lazy
                                @change="updateStartDuration(index, $event)" :id="'timePicker' + [index]"
                                format="HH:mm:ss" hide-clear-button></vue-timepicker>
                        </td>
                        <td class="p-2 py-4 m-auto">
                            <vue-timepicker required :value="getSchedule[index].endAt"
                                class="outline-none rounded-lg shadow-md p-2 text-bold bg-white"
                                :class="{ 'border-2 border-red-400': outOfShift(getSchedule[index]) || getSchedule[index].endAt < getSchedule[index].startAt }"
                                :second-range="[0]" :disabled="!scheduleChangesIsEmpty" drop-direction="auto" lazy
                                @change="updateEndDuration(index, $event)" :id="'timePicker' + [index]"
                                format="HH:mm:ss" hide-clear-button></vue-timepicker>
                        </td>
                        <td class="p-2 py-4 m-auto">
                            <span class="uppercase p-2 rounded-md" :class="{
            'bg-green-200': event.payable === 'TRUE',
            'bg-red-200': event.payable === 'FALSE'
        }">
                                {{ event.payable }}
                            </span>
                        </td>
                        <td class="p-2 py-4 m-auto">
                            <span class="uppercase p-2 rounded-md" :class="{
            'bg-green-200': event.billable === 'TRUE',
            'bg-red-200': event.billable === 'FALSE'
        }">
                                {{ event.billable }}
                            </span>
                        </td>
                        <td class="p-2 py-4 m-auto">
                            <button title="Discard event" type="button" @click.prevent="deleteEvent(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer"
                                    viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                        clip-rule="evenodd" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="text-xs text-red-600 text-left my-1">
                * You can identify when an activity event is out of the shift duration, it will be marked with a red
                border. In case an event has an end time, earlier than the start time, it also will be in red.
            </div>

            <div class="text-left my-1 flex space-x-4">
                <div>
                    <div class="custom-control custom-switch mt-2 mb-2">
                        <input v-model="toggle" type="checkbox" class="custom-control-input" id="customSwitch1">
                        <label class="custom-control-label w-64 text-left font-semibold" for="customSwitch1"
                            v-if="!toggle">
                            This request is NOT temporal
                        </label>
                        <label class="custom-control-label w-64 text-left font-semibold" for="customSwitch1" v-else>
                            I want this request to be temporal
                        </label>
                    </div>
                </div>
                <div v-if="toggle">
                    <div :class="{ 'border-2 border-red-400 relative w-[210px]': (toggle && temporalDate === null) }">
                        <date-picker id="datesId" v-model="temporalDate"
                            input-class="w-full mx-auto border-b-2 px-3 block focus:border-gray-700 py-2"
                            placeholder="Select a date" valueType="format" :editable="false" format="YYYY-MM-DD"
                            :disabled-date="onlyThisDOW">
                        </date-picker>
                    </div>
                    <div class="text-xs text-left my-1" :class="{ 'text-red-600': (toggle && temporalDate === null) }">
                        * Select the date this temporal change will be reverted
                    </div>
                </div>
            </div>

            <div class="my-2">
                <div class="flex">
                    <button
                        class="flex flex-row align-center font-semibold bg-gray-500 text-white p-2 rounded-md text-xs ml-auto space-x-1"
                        :disabled="getLoadingSchedules || loading || !scheduleChangesIsEmpty"
                        :class="{ 'bg-gray-400': !scheduleChangesIsEmpty }"
                        :title="'Send Request to change the schedule for ' + dow + 's, effective day: ' + effective_date"
                        type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                        </svg>
                        <span class="my-auto uppercase whitespace-wrap w-40">
                            {{ (getLoadingSchedules || loading) ? 'Loading' : 'Submit Schedule Change Request' }}
                        </span>
                    </button>
                </div>
            </div>

        </form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import VueTimepicker from 'vue2-timepicker'
import DatePicker from 'vue2-datepicker';
export default {
    components: {
        VueTimepicker,
        DatePicker
    },
    data() {
        return {
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            original: [],
            loading: true,
            temporalDate: null,
            toggle: false,
        }
    },
    computed: {
        ...mapGetters([
            'getSchedule',
            'getLoadingSchedules',
            'getAllEventsPerCampaign',
            'getScheduleChanges',
            'infoEmployee'
        ]),

        // helpers

        dow() {
            let [year, month, day] = this.effective_date?.split('-');
            let d = new Date(parseInt(year), parseInt(month)-1, parseInt(day));
            if (d.getDay() === 0) {
                return this.days[6];
            }

            return this.days[d.getDay() - 1];
        },

        effective_date() {
            return this.$route?.params?.date;
        },

        scheduleChangesIsEmpty() {
            return this.getScheduleChanges?.length === 0;
        },

        scheduleIsEmpty() {
            return this.getSchedule?.length === 0;
        },

        // Summaries

        summaryEventsDuration: function () {
            let timer = 0;
            let diff = 0;
            this.getSchedule.forEach(function (valor) {
                if (valor.event?.toLowerCase() !== 'shift' && valor.event?.toLowerCase() !== 'deleted') {
                    let start = 'July 1, 1999, ' + valor.startAt;
                    let end = 'July 1, 1999, ' + valor.endAt;
                    diff = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
                    timer += diff;
                }
            });
            return timer;
        },

        summaryBreakDuration: function () {
            let timer = 0;
            let diff = 0;
            this.getSchedule.forEach(function (valor) {
                if (valor.event?.toLowerCase() === 'break') {
                    let start = 'July 1, 1999, ' + valor.startAt;
                    let end = 'July 1, 1999, ' + valor.endAt;
                    diff = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
                    timer += diff;
                }
            });
            return timer;
        },

        summaryLunchDuration: function () {
            let timer = 0;
            let diff = 0;
            this.getSchedule.forEach(function (valor) {
                if (valor.event?.toLowerCase() === 'lunch') {
                    let start = 'July 1, 1999, ' + valor.startAt;
                    let end = 'July 1, 1999, ' + valor.endAt;
                    diff = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
                    timer += diff;
                }
            });
            return timer;
        },

        summaryShiftDuration: function () {
            let timer = 0;
            let diff = 0;
            this.getSchedule.forEach(function (valor) {
                if (valor.event?.toLowerCase() === 'shift') {
                    let start = 'July 1, 1999, ' + valor.startAt;
                    let end = 'July 1, 1999, ' + valor.endAt;
                    diff = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
                    timer += diff;
                }
            });
            return timer;
        },

        // rules

        shiftIsOverTwelveHours() {
            return this.summaryShiftDuration > 43200;
        },

        eventsAreBiggerThanShift() {
            return this.summaryShiftDuration < this.summaryEventsDuration;
        },

        breakRules() {
            /*
            break rules according to shift duration:
            Si el shift es >= a 10 HRS, breaks son 3 * 15 = 45 MINS;
            si el shift es >= a 7 HRS, breaks son 2 * 15 = 30 MINS;
            si el shift es >= a 5 HRS, breaks es 1 * 15 = 15 MINS;
            si no, breaks es 0 * 15 = 0 MINS
            */
            let breaks = 0;
            if (this.summaryShiftDuration >= 18000) {
                breaks = 1;
            }
            if (this.summaryShiftDuration >= 25200) {
                breaks = 2;
            }
            if (this.summaryShiftDuration >= 36000) {
                breaks = 3;
            }
            let break_duration = 900;
            return (breaks * break_duration) < this.summaryBreakDuration;
        },

        lunchRules() {
            // lunch rules according to shift duration
            let lunches = this.summaryShiftDuration >= 23400 ? 1 : 0;
            let lunch_duration = 1800 * 2;
            return (lunches * lunch_duration) < this.summaryLunchDuration;
        },

        offsetEvents() {
            // events are out the range of the shifts
            let errors = 0;
            let shifts = this.getSchedule.filter(s => s.event?.toLowerCase() === 'shift');
            let activities = this.getSchedule.filter(a => a.event?.toLowerCase() !== 'shift' && a.event?.toLowerCase() !== 'deleted');

            activities.forEach((activity) => {
                let isNotInsideShift = [];
                shifts.forEach((shift) => {
                    isNotInsideShift.push(!(activity.startAt >= shift.startAt && activity.endAt <= shift.endAt));
                });
                if (!(isNotInsideShift.some(e => e === false))) errors += 1;
            })

            return errors > 0;
        },

        biggerEndTimes() {
            // some events have a earlier end time than start time
            let errors = 0;
            this.getSchedule.forEach(function (value) {
                if (value.endAt < value.startAt) errors += 1;
            });

            return errors > 0;
        },

        isDayOff() {
            // There is no activities, and just shift in day off
            let shifts = [];
            this.getSchedule.forEach(function (value) {
                if (value.event?.toLowerCase() === 'shift') {
                    shifts.push(value);
                }
            });
            return (shifts?.length === 1 && shifts[0]?.startAt === "00:00:00" && shifts[0]?.endAt === "00:00:00");
        },
    },
    methods: {
        ...mapActions([
            'fetchSchedule',
            'fetchAllEvents',
            'saveScheduleChanges'
        ]),

        // helpers

        onlyThisDOW(d) {
            let [year, month, day] = this.effective_date?.split('-');
            let selected_date = new Date(parseInt(year), parseInt(month)-1, parseInt(day));
            let two_weeks = new Date(selected_date); two_weeks.setDate(two_weeks.getDate() + 14);

            return (this.getDayOfWeek(d) !== this.dow || d <= selected_date || d > two_weeks);
        },

        getDayOfWeek (date) {
            let date2 = new Date(date);
            let d = date2.getDay();
            if (d === 0) {
                return this.days[6];
            }

            return this.days[d - 1];
        },

        secToTime(t) {
            let hours = parseInt(t / 3600);
            let minutes = parseInt((t - (hours * 3600)) / 60);
            let result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);

            return result;
        },

        // rules

        outOfShift(event) {
            // Points out the elements that are outside the time frame of or all shifts
            if (event.event?.toLowerCase() === 'shift') return;
            let shifts = this.getSchedule.filter(n => n.event?.toLowerCase() === 'shift');
            let assets = 0;

            shifts.forEach(function (item) {
                if (item.startAt <= event.startAt && event.endAt <= item.endAt) {
                    assets += 1;
                }
            });
            return !(assets > 0);
        },

        // actions

        deleteEvent: function (index) {
            if (this.getSchedule[index].id) {
                this.getSchedule[index].event = 'deleted';
            } else {
                this.getSchedule.splice(index, 1);
            }
        },

        addEvent() {
            if (!this.scheduleChangesIsEmpty) {
                this.$fire({
                    type: 'warning',
                    title: 'There is pending changes for this day, please cancel them and try again',
                    showConfirmButton: false,
                    timer: 3800,
                });
                return;
            }
            let ev = this.getSchedule?.length === 0 ? 'Shift' : null;
            let bi = this.getSchedule?.length === 0 ? 'TRUE' : null;
            let py = this.getSchedule?.length === 0 ? 'TRUE' : null;
            let arr = {
                'EmpID': this.$route.params.empid,
                'dow': this.dow,
                'date': this.$route.params.date,
                'event': ev,
                'startAt': "00:00:00",
                'endAt': "00:00:00",
                'billable': bi,
                'payable': py
            };

            this.getSchedule.push(arr);
        },

        updateStartDuration: function (index, value) {
            if (this.getSchedule[index].event === null) return;
            this.getSchedule[index].startAt = value.displayTime;
        },

        updateEndDuration: function (index, value) {
            if (this.getSchedule[index].event === null) return;
            this.getSchedule[index].endAt = value.displayTime;
        },

        selectEvent(index, event) {
            this.getSchedule[index].billable = event.target.selectedOptions[0].getAttribute("billable");
            this.getSchedule[index].payable = event.target.selectedOptions[0].getAttribute("payable");
        },

        sendSchedule: function () {
            event.preventDefault();
            if (JSON.stringify(this.getSchedule) === JSON.stringify(this.original)) return;
            if (this.toggle && this.temporalDate === null) return;
            if (this.eventsAreBiggerThanShift || this.shiftIsOverTwelveHours || this.offsetEvents || this.biggerEndTimes || this.breakRules || this.lunchRules) {
                this.$fire({
                    type: 'warning',
                    title: 'There are some pending errors, please review your request',
                    showConfirmButton: true
                });
                return;
            }
            let obj = {
                empid: this.$route.params.empid,
                date: this.$route.params.date,
                is_day_off: this.isDayOff,
                req: this.getSchedule
            };
            this.saveScheduleChanges(obj).then(() => {
                if (this.toggle) {
                    let temporal = {
                        empid: this.$route.params.empid,
                        date: this.temporalDate,
                        is_day_off: this.isDayOff,
                        req: this.original
                    };
                    this.saveScheduleChanges(temporal).then(() => {
                        this.$fire({
                            type: 'success',
                            title: 'Schedule Changes Sent!',
                            showConfirmButton: true
                        }).then(() => {
                            this.$emit('toggle', true);
                        })
                    }).catch((error) => {
                        console.log(error);
                    })
                } else {
                    this.$fire({
                        type: 'success',
                        title: 'Schedule Changes Sent!',
                        showConfirmButton: true
                    }).then(() => {
                        this.$emit('toggle', true);
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
        },
    },

    created() {
        this.loading = true;
        let obj = {
            empid: this.$route.params.empid,
            date: this.$route.params.date
        };
        this.fetchAllEvents(obj.empid);
        this.fetchSchedule(obj).then(() => {
            this.loading = false;
            this.original = JSON.parse(JSON.stringify(this.getSchedule));
        });
    },
}
</script>
<style lang="scss" scoped>
.material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
}

.btn-primary.disabled,
.btn-primary:disabled {
    cursor: no-drop;
}
</style>