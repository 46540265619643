<template>
    <div class="flex flex-col space-y-4 mb-4">
        <div class="main overflow-hidden space-x-4">
            <div id="current_file" class="w-1/2 pdf_page">
                <span class="uppercase font-semibold text-2xl mx-auto">
                    Current file
                </span>
                <div v-if="currentIsNull" class="flex h-full">
                    <span class="font-semibold m-auto align-center p-4 rounded-lg bg-gray-50 text-gray-700 shadow-md">
                        There is no file in here
                    </span>
                </div>
                <pdf-viewer v-else :pdf="current_file"></pdf-viewer>
            </div>
            <div id="new_file" class="w-1/2 pdf_page">
                <span class="uppercase font-semibold text-2xl  mx-auto">
                    New File
                </span>
                <div v-if="newIsNull" class="flex h-full">
                    <span class="font-semibold m-auto align-center p-4 rounded-lg bg-gray-50 text-gray-700 shadow-md">
                        There is no file in here
                    </span>
                </div>
                <pdf-viewer v-else :pdf="new_file"></pdf-viewer>
            </div>
        </div>
        <div class="flex space-2" v-if="isOpen">
            <button @click="declineRequest" class="p-2 px-3 rounded-lg text-white uppercase font-sans font-semibold text-xs mx-auto bg-gray-800">
                decline
            </button>
            <button @click="approveRequest" class="p-2 px-3 rounded-lg text-white uppercase font-sans font-semibold text-xs mx-auto bg-gray-800">
                Approve
            </button>
        </div>
    </div>
</template>

<script>
import PdfViewer from '@/views/components/files/pdf_viewer';
import { mapActions, mapGetters } from 'vuex';
export default{
    components: {
        PdfViewer
    },
    data(){
        return {
            current_file: '',
            new_file: ''
        }
    },
    methods: {
        ...mapActions([
            'fetchDocumentRequestDetails',
            'changeDocumentRequestStatus',
            'fetchDocumentsListDetails',
        ]),

        approveRequest: function () {
            this.$confirm("You're about to approve this request", "Do you want to continue?", "question")
            .then((result) => {
                if (result){
                    let obj = {
                        id: this.$route.params.id,
                        status: 'approved'
                    };
                    
                    this.changeDocumentRequestStatus(obj).then(()=>{
                        this.$fire({
                            type:'success',
                            title:'Request approved successfully.',
                            showConfirmButton:false,
                            timer: 1500
                        });
                        this.$router.push({name:`documents.management`});
                    })
                    .catch((error)=>{
                        let message =  error.response?.data?.message ?? 'Something went wrong';
                        let indexEnd = message.indexOf('(SQL:');
                        let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                        this.$fire({
                            type: 'warning',
                            title: 'Something went wrong',
                            text:`${substringed}`,
                            showConfirmButton: true,                
                        });
                        this.loading = false;
                    })
                }
            })
        },
        declineRequest: function () {
            this.$confirm("You're about to decline this request", "Do you want to continue?", "question")
            .then((result) => {
                if (result){
                    let obj = {
                        id: this.$route.params.id,
                        status: 'declined'
                    };
                    
                    this.changeDocumentRequestStatus(obj).then(()=>{
                        this.$fire({
                            type:'success',
                            title:'Request declined successfully.',
                            showConfirmButton:false,
                            timer: 1500
                        });
                        this.$router.push({name:`documents.management`});
                    })
                    .catch((error)=>{
                        let message =  error.response.data.message ?? 'Something went wrong';
                        let indexEnd = message.indexOf('(SQL:');
                        let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                        this.$fire({
                            type: 'warning',
                            title: 'Something went wrong',
                            text:`${substringed}`,
                            showConfirmButton: true,                
                        });
                        this.loading = false;
                    })
                }
            })
        }
    },
    computed: {
        ...mapGetters([
            '_employee_file',
            '_employee_file_details'
        ]),
        currentIsNull () {
            return this.current_file === null;
        },
        newIsNull () {
            return this.new_file === null;
        },
        isOpen () {
            return this._employee_file?.status === "pending"
        }
    },
    created(){
    },
    mounted(){
        this.fetchDocumentRequestDetails(this.$route.params.id).then(() => {
            this.current_file = this._employee_file.current_file;
            this.new_file = this._employee_file.new_file;
            this.fetchDocumentsListDetails(this._employee_file.document_type).then(() => {
                let title = this._employee_file_details?.label;
                this.$emit('set-title',title);
            })
        })
    },
}</script>
<style scoped>
.main {
    min-height: 720px;
    width: 100%;
    padding: 0.5em;
    display: flex !important;
    /* flex-flow: row; */
}
</style>