<template>
    <div class="todayActivity p-8 relative">
        <div class="text-right bg-red-200">
            <button @click="closeModal" class="absolute top-2 right-2">
                <i class="bi bi-x"></i>
            </button>
        </div>
        <h1 class="text-4xl mb-3 text-center">Start your Shift</h1>
        <div v-if="is_loading" class="text-center">
            <loader />
        </div>
        <div v-else class="">
            <div class="relative text-center">
                <div class="block space-y-2">
                    <div v-if="!onlyHasOneLob">
                        <span class="font-medium text-center"
                            >Select an Lob to continue</span
                        >
                        <multiselect
                            class="flex justify-content-center text-left"
                            v-model="lob_selected"
                            placeholder="Select an Lob"
                            label="label"
                            track-by="id"
                            :options="getLob"
                            :multiple="false"
                            :allow-empty="false"
                            :clear-on-select="false"
                            :preserve-search="false"
                            deselect-label=""
                        ></multiselect>
                    </div>
                    <div class="text-left">
                        <div class="text-right">
                            <input
                                v-model="home_switch"
                                type="checkbox"
                                class="checkbox-custom mr-2"
                                id="customSwitch1"
                            />
                            <label class="cursor-pointer" for="customSwitch1">
                                I am working from HOME.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3">
                    <button
                        :disabled="lob_selected === null"
                        type="button"
                        @click="startShift"
                        class="bg-blue-600 disabled:bg-gray-400 hover:bg-blue-700 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold flex mx-auto"
                    >
                        <i class="bi bi-stopwatch flex my-auto mr-1"></i> Clock
                        In
                    </button>
                </div>
            </div>
            <div
                class="absolute bottom-0 left-0 text-[1000%] leading-none text-gray-500 opacity-10"
            >
                <transition name="fade" mode="out-in">
                    <i
                        v-if="home_switch"
                        key="home-icon"
                        class="bi bi-house-door"
                    ></i>
                    <i v-else key="building-icon" class="bi bi-building"></i>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import { ClientJS } from "clientjs";
import { mapGetters, mapState, mapActions } from "vuex";
import auth from "@/config/user";
import Multiselect from "vue-multiselect";
import Loader from "@/views/components/Loader";
export default {
    components: {
        Multiselect,
        Loader,
    },
    data() {
        return {
            is_loading: true,
            campaign_selected: null,
            lob_selected: null,
            home_switch: false,
        };
    },
    computed: {
        ...mapState(["clockerUtility", "employees"]),
        ...mapGetters(["infoEmployee", "getIP", "getLob", "getAllCampaigns"]),
        onlyHasOneLob() {
            return this.getLob.length === 1;
        },
    },
    methods: {
        ...mapActions([
            "clockInAgent",
            "loadMyProfileByEmpId",
            "getIPFromClient",
            "fetchLobsByEmployee",
        ]),
        startShift: function () {
            var client = new ClientJS();
            let objectData = {
                emp_id: auth.currentUser.EmpID.toUpperCase(),
                ClientIP: this.getIP,
                ActionTriggered: "ClockIn",
                UserAgent: client.getUserAgent(),
                DeviceOS: client.getOS(),
                DeviceFingerprint: client.getFingerprint(),
                lob: this.lob_selected.id,
                is_wfh: this.home_switch,
            };
            this.is_loading = true;

            this.clockInAgent(objectData)
                .then(() => {
                    this.$fire({
                        type: "success",
                        title: "A shift has started",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(() => {
                        this.closeModal();
                        this.is_loading = false;
                    });
                })
                .catch((error) => {
                    this.is_loading = false;
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `Details: ${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true,
                    }).then(() => {
                        this.closeModal();
                        this.is_loading = false;
                    });
                });
        },

        closeModal() {
            this.$emit("toggle", true);
        },
    },
    created() {
        this.getIPFromClient();
        let idEmployee = auth.currentUser.EmpID;
        this.loadMyProfileByEmpId(idEmployee).then(() => {
            this.fetchLobsByEmployee(this.infoEmployee.EmpID).then(() => {
                if (this.onlyHasOneLob) {
                    this.lob_selected = this.getLob[0];
                }
                this.is_loading = false;
            });
        });
    },
};
</script>
<style scoped>
.checkbox-custom {
    width: 20px;
    height: 20px;
    color: dodgerblue;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #ccd3d8;
    transition: background 300ms;
    cursor: pointer;
}
.checkbox-custom::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
}
.checkbox-custom:checked::before {
    box-shadow: none;
    background-color: dodgerblue;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>