import axios from '@/config/axios'

const state = {
   details:{
        Id:null,
        SSN:null,
        Clinic:null,
        Infonavit:false,
        RFC:null,
        StartDate:null,
        SDI:null,
        Birthday:null,
        BirthPlace:null,
        'Mother Name':null,
        'Father Name':null,
        Address1:null,
        Phone1:null,
        Marital_Status:"",
        Bank_Benef:null,
        Relationship_benef:null,
        Benef_RFC:null,
        Benef_address:null,
        Benef_Name:null,
   },
   status:'pending'
};

const getters = {
   getImssInformation:(state) => state.details
};

const actions = {
    async storeImssDetails({ commit },data){        
        commit('setImssStatus','saving');
        const response  = await axios.post(`api/v2/recruitment/candidates/${data.Id}/setImssDetails`,data).then((res)=>{
            commit('setImssDetails',res.data.imssDetails);
            commit('setImssStatus','saved');
         }); 

         return response;        
    },

    async fetchImssDetails({ commit },id){        
        commit('setImssStatus','saving');
        const response  = await axios.get(`api/v2/recruitment/candidates/${id}/getImssDetails`).then((res)=>{
            commit('setImssDetails',res.data.imssDetails);
            commit('setImssStatus','saved');
         }); 

         return response;        
    },


}

const mutations = {
    setSocialSecurityNumber:(state, number) =>(state.details.SSN = number),
    setClinicName:(state, name) =>(state.details.Clinic = name),
    setInfonavitStatus:(state, status) =>(state.details.Infonavit = status),
    setCandidateRFC:(state, RFC) =>(state.details.RFC = RFC),
    setStartDate:(state, date) =>(state.details.StartDate = date),
    setImssSDI:(state, SDI) =>(state.details.SDI = SDI),
    setCandidateBday:(state, day) =>(state.details.Birthday = day),
    setCandidateBirthPlace:(state, place) =>( state.details.BirthPlace = place),
    setFatherName:(state, name) =>(state.details['Father Name'] = name),
    setMotherName:(state, name) =>(state.details['Mother Name'] = name),
    setCandidateAddress:(state, address) =>(state.details.Address1 = address),
    setCandidatePhone:(state, phone) =>(state.details.Phone1 = phone),
    setMaritalStatus:(state, status) =>(state.details.Marital_Status = status),
    setBankBeneficiary:(state,beneficiary) =>(state.details.Bank_Benef= beneficiary),
    setRelationshipBeneficiary:(state, relationship) =>(state.details.Relationship_benef = relationship),
    setBeneficiaryRFC:(state,RFC) =>(state.details.Benef_RFC =RFC),
    setBeneficiaryAdress:(state,address) => (state.details.Benef_address = address),
    setImssStatus:(state,status) => (state.status = status),
    setBeneficiaryName:(state,name) => (state.details.Benef_Name = name),  
    setImssId:(state,id) => (state.details.Id = id),
    setImssDetails:(state,details) => (state.details = details), 
};

export default {
    state,
    getters,
    actions,
    mutations
};