<template>
  <div>
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 text-left" id="modal-title">Add a new note for the termination of {{ employee }}</h3>
                </div>
              </div>
              <div class="space-y-4 p-3">
                <div class="text-left">
                  <span class="text-sm text-gray-500">Write the note you want to append to this already applied termination</span>
                  <textarea v-model="textbox" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2" row="4"></textarea>
                  <div class="flex flex-row w-full">
                      <pill :dirty="$v.textbox.$dirty" :valid="!$v.textbox.$invalid">
                        Min: 10 chars., max of 5000 chars.
                      </pill>
                  </div>
                </div>
                <div class="text-left">
                  <span class="text-sm text-gray-500">Upload a file (optional)</span>
                  <upload-files @changed="handleImages" :isMultiple='multiple_files' :fileTypes="file_types" :maxSize="max_file_size"/>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto my-auto"
              @click="answer(true)">
                Continue
              </button>

              <button class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto my-auto"
              @click="answer(false)">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Pill from '@/views/mockups/ValidationPill';
import UploadFiles  from '@/views/Utilities/upload_files/index'
  export default {
    props: {
      data: {
        default: () => {
          return {
              text: 'Are you sure you want to proceed? This action cannot be undone.',
              icon: 'warning',
              employee: '',
              id: 0,
          }
        }
      },
      employee: {
        default: 'Unknown',
        type: String
      }
    },
    data () {
      return {
        count: 0,
        textbox: null,
        file: null,

        multiple_files: false,
        file_types: ['application/pdf'],
        max_file_size: 5,
      }
    },
    validations () {
        return {
            textbox: {
              required,
              minLength: minLength(10),
              maxLength: maxLength(5000)
            }
        }
    },
    components: {
      Pill,
      UploadFiles
    },
    methods: {
      answer (status) {
        if (status) {
          this.$v.textbox.$touch();
          if(this.$v.textbox.$invalid) return;
        }
        let obj = {
          status: status,
          textbox: this.textbox,
          file: this.file
        }
        this.$emit('toggle', obj);
      },

      handleImages(files){
        this.file = files[0];
      },
    }
  }
  </script>