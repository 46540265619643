<template>
     <div class="flex flex-row align-items-center justify-content-start group flex-1 border-r-2 border-gray-200">
         <svg class="w-6 h-6 group-hover:text-gray-500 group-active:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
         <input type="number" v-model="idNumber" class="p-2 flex-1 rounded-md hover:text-gray-600 focus:text-gray-600" placeholder="Search by Incidence ID...">
         <button type="button"  class="text-red-300 px-2" v-if="idNumber !== null" @click="clear">
           <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         </button>
      </div>
</template>

<script>
import debounce from 'debounce'
export default {
    data(){
        return {
            idNumber:null,
        }
    },
    methods:{
        clear:function(){
            this.idNumber = null;
        }
    },
    watch:{
        idNumber:debounce(function(){
            this.$emit('toggle',{key:'idNumber',value:this.idNumber});
        },200)
    }
}
</script>