<template>
    <transition name="slide-fade">
       
     <div class="fixed w-full h-full left-0 top-0 flex items-center justify-center" style="background-color:rgba(0,0,0,0.75) !important;" >
        
        <div class="relative bg-white shadow-md rounded-sm px-4 py-3 w-1/3 border-t-4 border-red-400 overflow-auto max-h-3xl">
          <button class="absolute right-2 top-2 hover:text-red-600" @click.prevent="$emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h4 class="text-md font-semibold text-left">
            Notify Error 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-400 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </h4>
          <form class="w-full border-t border-gray-100 py-3 px-2 flex flex-row flex-wrap" @submit.prevent="notify">
                <div class="w-full">
                    <label class="block text-gray-700 text-sm font-bold  text-left mb-2" for="username">
                        Select the event date  
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                        </svg>
                    </label>
                    <select class="w-full border-b-2 border-gray-300 focus:border-blue-500" required v-model="form.date">
                        <option hidden value="">Select an option</option>
                        <option v-for="(item,index) in reportableDates" :key="index" >
                            {{item}}
                        </option>
                    </select>
                </div>
                <div class="w-1/2 px-2 mt-2">
                    <label class="block text-gray-700 text-sm font-bold  text-left mt-2" for="username">
                    Enter the correct amount of payable hours 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                        </svg>
                    </label>
                     <vue-timepicker class="w-full border-b-2 block border-gray-200 outline-none focus:border-blue-500" input-width="100%" required v-model="hours"/>
                </div>
                 <div class="w-1/2 px-2 mt-2">
                    <label class="block text-gray-700 text-sm font-bold  text-left mt-2" for="username">
                        Select a reason from the list
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                        </svg>
                    </label>
                    <select class="w-full border-b-2 border-gray-300 focus:border-blue-500" required v-model="form.reason">
                           <option value="" hidden>Select a Reason</option>
                           <option value="misuse">Agent tool misuse</option>
                           <option value="internet">Internet issue</option>
                           <option value="clocker">Issue with clocker</option>
                           <option value="other">Other</option>
                        </select>
                </div>
                <div class="w-full mt-2">
                    <label class="block text-gray-700 text-sm font-bold  text-left mt-2" for="username">
                        Describe the problem with the given date below.
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                    </svg>
                    <textarea rows="4" class="w-full border-b-2 border-gray-200 focus:border-blue-500" maxlength="500" v-model="form.description" required/>
                    <small class="ml-2 text-gray-500 font-semibold">
                        {{form.description.length}} of 500 characters.
                    </small>
                </label>
                </div>
               
                <div class="w-full pt-2 flex items-center justify-end">
                <button class="ml-auto px-3 py-2 rounded-sm shadow-md bg-gray-700 text-white" :class="disableButton ?'cursor-not-allowed':''" :disabled="disableButton" @click="$emit('click')">
                    Notify
                </button>
                </div>
          </form>
        </div>
    </div>
    </transition>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import VueTimepicker from 'vue2-timepicker'
export default {
    components:{
        VueTimepicker
    },
    data(){ return{
        hours:{
            HH:'00',
            mm:'00'
        },
        form:{
            date:"",
            hours:'00:00',
            reason:"",
            description:""
        },
        disableButton:false,
    }},
    computed:{
        ...mapGetters(['getApprovalButtons']),
         reportableDates:function(){
             let dates = this.getApprovalButtons , requestableDates=[];
           // let requestableStatus = ['pending','validation','no_data'], requestableDates=[], self=this;
           Object.keys(dates).forEach(function(date){
                let agentStatus =dates[date][0].AgentStatus.toLowerCase();
                if(agentStatus =='pending' || agentStatus =='validation' || agentStatus =='no-data') requestableDates[requestableDates.length] = date;
     
            }); 
           
            return  requestableDates;

        },
    },
    methods:{
        ...mapActions(['notifyHoursError']),
       
        notify:async function(){
           
           
            this.disableButton = true;
            try{
                const {value:confirmation} = await  this.$fire({
                        type:'question',
                        title:'Are you sure you want to proceed?.',
                        showCancelButton:true,
                        confirmButtonText:'Yes proceed',
                        cancelButtonText:'Cancel'
                    });

                if(!confirmation) {
                    return this.disableButton = false;
 
                }

                const response = await  this.notifyHoursError(this.form);

                if(response){
                     this.$emit('close')
                    this.$fire({
                        type:'Success',
                        title:'Notification stored successfully.'
                    })
                   .then(()=>{
                       this.$router.go(0)
                    });
                }
            }catch(e){
                   this.$fire({
                        type:'Error',
                        title:'Something went wrong.'
                    });  
            }
        }
        
    },
    watch:{
        hours:{
            handler:function(){
               
                if(!this.hours.HH && ! this.hours.mm) {
                
                this.hours.HH ='00'
                this.hours.mm ='00'
                }
                this.form.hours = this.hours.HH + ':' + this.hours.mm;
            },
            deep:true
        }
    }
}
</script>

<style lang="css" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

input[type="time"]:-webkit-datetime-edit-ampm-field {
    -webkit-display: none;
    -moz-display: none;
    -o-display: none;
    -ms-display:none;
    display:none;
}


</style>