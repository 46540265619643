<template>
    <div>
        <div v-if="showCard" class="bg-white p-3 py-5 rounded-b-lg shadow-md">
            <form class="flex flex-col mx-2 space-y-2" @submit.prevent="addItem">
<!-- {{attributes}} -->
                <div class="text-left">
                    <span class="flex flex-row text-lg font-sans font-semibold text-gray-600 space-x-0 tracking-tight">List the attributes of this item and give a value for them.</span>
                    <span class="flex flex-row text-xs font-sans font-medium text-gray-400 space-x-0 tracking-tight">I.e. Attribute Label: YearOfBuy, and Attribute Value: 2016.</span>
                </div>
                
                <div v-for="(input, index) in example" :key="`attribute-${index}`">
                    <div v-show="input.Deleted_At === null" class="flex flex-row">
                        <div class="w-1/2 text-left text-sm justify mx-2 mb-3">
                            <label for="label">Attribute Label:</label>
                            <div class="inline relative">
                                <input type="text" id="label" placeholder="Write Here..." autocomplete="off" v-model="input.Attribute_Label" maxlength="100"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 focus:border-blue-300 border-b-2 mx-auto"
                                required pattern="[a-zA-Z0-9 ]+"
                                />    
                            </div>
                        </div>

                        <div class="w-1/2 text-left text-sm justify mx-2 mb-3">
                            <label for="label">Attribute Value:</label>
                            <div class="inline relative">
                                <input type="text" id="label" placeholder="Write Here..." autocomplete="off" v-model="input.Attribute_Value" maxlength="100"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 focus:border-blue-300 border-b-2 mx-auto"
                                required pattern="[a-zA-Z0-9 ]+"
                                />
                            </div>
                        </div>
                        <div class="flex space-x-2 m-auto">
                            <svg v-show="attributes.length === 0 && (example.length === (index+1) || example_active === 1)" @click="addField(index)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                            </svg>

                            <svg v-show="example_active > 1 || attributes.length > 0" @click="removeAttribute(index)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>
                
                <div v-for="(input, index) in attributes" :key="`newattribute-${index}`" class="flex flex-row">
                    <div class="w-1/2 text-left text-sm justify mx-2 mb-3">
                        <label for="label">Attribute Label:</label>
                        <div class="inline relative">
                            <input type="text" id="label" placeholder="Write Here..." autocomplete="off" v-model="input.attribute_label" maxlength="100"
                            class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 focus:border-blue-300 border-b-2 mx-auto"
                            required pattern="[a-zA-Z0-9 ]+"
                            />    
                        </div>
                    </div>

                    <div class="w-1/2 text-left text-sm justify mx-2 mb-3">
                        <label for="label">Attribute Value:</label>
                        <div class="inline relative">
                            <input type="text" id="label" placeholder="Write Here..." autocomplete="off" v-model="input.attribute_value" maxlength="100"
                            class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 focus:border-blue-300 border-b-2 mx-auto"
                            required pattern="[a-zA-Z0-9 ]+"
                            />
                        </div>
                    </div>
                    <div class="flex space-x-2 m-auto">
                        <svg v-show="attributes.length === (index+1)" @click="addField(index)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                        </svg>

                        <svg v-show="example_active > 0 || attributes.length > 1" @click="removeField(index)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>

                <div>
                    <span class="text-red-700 text-xs font-sans font-medium text-gray-400 space-x-0 tracking-tight whitespace-nowrap">*</span>
                    <span class="text-xs font-sans font-medium text-gray-400 space-x-0 tracking-tight whitespace-nowrap">Requested Format: Please use alphanumeric characters only, avoid symbols or accents</span>
                </div>
                
                <div class="flex flex-row">
                    <button
                    :disabled="loading"
                    class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2 mx-auto disabled:cursor-not-all">
                        <span v-if="loading" class="uppercase font-sans font-semibold text-xs">
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                        </span>
                        <span v-else class="uppercase font-sans font-semibold text-xs">
                            Edit Attributes
                        </span>
                    </button>
                </div>
            </form>
        </div>

        <div v-else class="flex justify-content-center flex-col my-auto">
            We are loading the required information. Please wait
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
            </svg>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    components: {
    },
    data() {
       return{
           showCard: false,
           loading: false,

           attributes: [],
           example: [],
       } 
    },
    computed:{
        ...mapState([
            "inventory"
        ]),
        ...mapGetters([
            'getItemData'
        ]),

        example_active: function(){
            const count = this.example.filter(obj => {
                if (obj.Deleted_At === null) return true;
                return false;
            }).length;
            return count;
        }
    },
    methods: {
      ...mapActions(['editItemAttributes', 'getInventoryItemInfo']),

        addField(index) {
            let array = {
                attribute_label: '',
                attribute_value: ''
            };
            this.attributes.splice(index+1, 0, array);
        },

        removeField(index,) {
            this.$fire({
               type:'question',
               title:'Confirm this action',
               text :`Are you sure you want to remove this attribute`,
               showCancelButton: true,
                confirmButtonText: 'Yes, remove it',
                cancelButtonText: 'No, please cancel',
            }).then((result)=>{
                if(result.value) this.attributes.splice(index, 1);
            })
        },

        removeAttribute(index) {
            this.$fire({
               type:'question',
               title:'Confirm this action',
               text :`Are you sure you want to remove this attribute`,
               showCancelButton: true,
                confirmButtonText: 'Yes, remove it',
                cancelButtonText: 'No, please cancel',
            }).then((result)=>{
                if(result.value) this.example[index].Deleted_At = 'Yes';
            })
        },
      
        addItem(){
            event.preventDefault();
            this.loading = true;
            let obj = {
                id: this.getItemData.Catalog_ID,
                editAttributes: this.example,
                newAttributes: this.attributes,
            }
            this.editItemAttributes(obj).then(() => {
                this.$fire({
                    type:'success',
                    title:'Item Edited Successfully!',
                    showConfirmButton:false,
                    timer: 1500
                }).then(() => {
                    this.$emit('toggle', true);
                })
            }).catch((error)=> {
                let message =  error?.response?.data?.msg ?? 'Something went wrong';
                let indexEnd = message?.indexOf('(SQL:');
                let substringed = message?.substring(0,indexEnd);
                this.$fire({
                    type: 'warning',
                    title: 'Something went wrong',
                    text:`${substringed}`,
                    showConfirmButton: true,                
                });
            })
        },

        loadingEnds: function(){
            this.showCard = true;
        }
    },

    created(){
        this.showCard = false;
        this.itemId = this.$route.params.id;
        this.getInventoryItemInfo(this.itemId).then(()=>{
            this.example = this.getItemData.attributes;
            if(this.example?.length < 1){
            this.attributes = [{
                    attribute_label: '',
                    attribute_value: ''
                }];
            }
            this.showCard = true;
        })
    },

}
</script>
<style scoped>
#serial_number, #confirm_serial_number { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>