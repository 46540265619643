<template>
<div class="w-full relative block">

    <div class="flex flex-row max-w-7xl bg-white shadow-lg rounded-lg p-3 my-3 mx-auto align-items-center justify-content-start">
        <special-searchbar :key="resetKey" @toggle="filterUpdate"/>
        <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700" @click.prevent="clearFilters">
            Clear filters
        </button>
    </div>

    <special-table :key="ListKey" @updateAll="keepQueryAlive" class="text-center justify-center items-center flex"></special-table>
</div>
</template>
<script>
    import SpecialTable         from '@/views/components/Payroll/special/elements/allSpecialRequestsTable';
    import SpecialSearchbar  from '@/views/components/Payroll/special/searchBar/specialSearchBar.vue'
    import {mapGetters,mapState, mapActions } from 'vuex'
    export default {
        components: { 
            SpecialTable,
            SpecialSearchbar,
        },
        data(){
            return{
                ListKey: 0,
                // Searchbar Key
                resetKey: 0,
                form:{
                    refresh :false
                }
            }
        },
        computed: {
            ...mapState([
            'payroll'
            ]),
            ...mapGetters([
                ])
        },
        created(){
            this.filterSpecialReq(this.form); // testing
        },
        methods: {
            ...mapActions([
                'filterSpecialReq'
                ]),

            closeModal: function(){
            this.$refs['AddNewSurvey'].hide()
            this.ListKey++;
            },

            // ForceRender: function(){
            //     this.ListKey++;
            // },

            // Searchbar methods
            filterUpdate:function(form){
                this.form = form;
            },

            keepQueryAlive:function(){
                this.filterSpecialReq(this.form);
            },

            clearFilters: function(){
                this.resetKey ++;
            },

            // end searchbar methods
        },
        watch:{
            form:{
            handler:function(){        
                this.filterSpecialReq(this.form);
            },
            deep:true      
            }
        },
      mounted(){
        let title = 'Special Payroll Management';
        this.$emit('set-title',title);
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>