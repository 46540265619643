import axios from '@/config/axios';

const state = {
    list:[],
    error:false,
    activities:[]
};

const getters = {
    _activitiesList: (state)=> state.list,
    _activities: (state) => state.activities
};

const actions = {
    async fetchTrainingActivities({commit}) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/activities`)
            commit('SET_ACTIVITIES', response.data.list);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },

    async fetchTrainingActivitiesById({commit},id) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetch-activities`)
            commit('SET_ACTIVITIES_LIST', response.data.activities);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },

    async saveTrainingActivities({commit},formData) {
        try {
            const response = await axios.post(`api/v2/recruitment/trainings/saveAttrition`,formData)
            commit('SET_ACTIVITIES_LIST', response.data.attrition);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },

    async deleteTrainingActivities({commit},id){
        const response = await axios.delete(`api/v2/recruitment/trainings/attrition/${id}/delete`);
        commit('SET_ACTIVITIES_LIST', response.data.attrition);
    },

    async editTrainingActivities({commit} ,formData){
        const response = await axios.put(`api/v2/recruitment/trainings/attrition/${formData.id}/update`,formData);
        commit('SET_ACTIVITIES_LIST', response.data.attrition);
    },
};

const mutations = {
    SET_ACTIVITIES: (state, activities) => { state.activities = activities },
    SET_ACTIVITIES_LIST: (state, list) => { state.list = list },
    SET_ERROR: (state, status) => { state.error = status },
};

export default {
    state,
    getters,
    actions,
    mutations
};
