<template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class="bg-white px-2 focus:text-gray-600 active:text-gray-600" v-model="selectedSite">  
          <optgroup label = "Select a Site">
            <option hidden value="0">Choose a Site</option>
            <option value="AGS">AGS</option>
            <option value="MTY">MTY</option>
            <option value="QRO">QRO</option>    
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error:null,
            selectedSite : 0
        }
    },
    computed:{
        ...mapGetters([]),
    },
    created(){
        this.error =false
    },
    methods:{
        ...mapActions([])
    },
    watch:{
      selectedSite: Debounce(function(){
          this.$emit('toggle',{
              key:'site',
              value :this.selectedSite
          });
      },200)     

    }
}
</script>
