<template>
    <div class="fixed z-50" v-if="show">
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity ease-in-out duration-300 backdrop-blur-sm"
        ></div>
        <div class="fixed inset-0 w-screen">
            <div
                class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
                <div
                    class="relative rounded-2xl bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg"
                >
                    <div class="text-right" v-if="isClosable" @click="close">
                        <button class="mt-2 mr-2.5">
                            <i class="bi bi-x"></i>
                        </button>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    computed: {
        isClosable() {
            return this.$listeners.close;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        }
    },
};
</script>