<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID"  :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <input class="form-check-input" type="checkbox" :value="chkboxValue" :id="elementID">
            <label class="form-check-label" :for="elementID">{{checkboxText}}</label>
        </div>
    </div>
</template>
<script>
    export default { 
    props:{
        checkboxText: {
            type:       String,
            required:   true,
        },
        chkboxValue: {
            type:       String,
            required:   true,  
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },

        classToAdd:{
            type:       String,
            default:    '',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        },
    },
}
</script>
