<template>   
    <div class="w-full h-full relative flex flex-row align-items-start justify-content-center">
       
        <div class="bg-white shadow-sm rounded-sm p-3 mt-2 lg:w-1/3 sm:w-full">    
            <form class="w-full flex flex-row  flex-wrap" @submit.prevent="submit">
               
                <div class="relative mb-2 md:w-1/2 sm:w-full px-2 flex flex-col group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Purchase Order Name 
                    </label>        
                    <input type="text" class="border-b border-gray-200 px-3 py-1 focus:border-blue-400" v-model="label" required>                                                        
                </div>
                <div class="relative mb-2 sm:w-full md:w-1/2 px-2  mt-2 flex flex-col group">
                 <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                       Campaign
                    </label>     
                    <select class="border-b border-gray-200 px-2 pt-1 focus:border-blue-400" v-model="campaign" required>
                        <option hidden value="">Select a campaign...</option>
                        <option v-for="(campaign,index) in getCampaigns" :key="index" :value="campaign.id">
                            {{campaign.label}}
                        </option>
                    </select>                                                       
                </div>
                <div class="relative mb-2 sm:w-full md:w-1/2 px-2 flex flex-col group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                       Training date
                    </label>        
                    <input type="date" :min="minDate" class="border-b border-gray-200 px-3 py-1 focus:border-blue-400" required v-model="date">                                                        
                </div>
                <div class="relative mb-2 sm:w-full md:w-1/2 px-2  mt-2 flex flex-col group">
                 <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                       Site Location
                    </label>     
                    <select class="border-b border-gray-200 px-2 pt-1 focus:border-blue-400" v-model="location" required>
                        <option hidden value="">Select a location...</option>
                        <option value="AGS">Aguascalientes</option>
                        <option value="MTY">Monterrey</option>
                    </select>                                                       
                </div>               
                 <div class="relative mb-2 sm:w-full md:w-1/2 px-2 flex flex-col group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                       Required  Agents
                    </label>        
                    <input type="number" min="1" class="border-b border-gray-200 px-3 py-1 focus:border-blue-400" required v-model="agents">                                                        
                </div>
                 <div class="relative mb-2 sm:w-full md:w-1/2 px-2 flex flex-col group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                       Weekly hours
                    </label>        
                    <input type="number" min="1" max="48" class="border-b border-gray-200 px-3 py-1 focus:border-blue-400" required v-model="hours">                                                        
                </div>
                
                 <button type="buttton" class="mt-2 bg-red-300 hover:bg-red-500 ml-auto text-white px-3 py-2 rounded-sm" @click.prevent="$router.go()" v-if="!status">
                    Clear
                </button>     
                <button class="mt-2 bg-gray-700 ml-2 text-white px-3 py-2 rounded-md shadow-md" v-if="!status">
                    Update
                </button>      
            </form>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    data(){
        return{
            minDate:this.$moment().add(1,'days').format('YYYY-MM-DD')
        }
    },
    computed:{
        ...mapGetters(['getCurrentPurchaseOrder','getCampaigns']),
        label:{
            get(){
                return this.getCurrentPurchaseOrder.label;
            },
            set(value){
                this.$store.commit('SET_PO_LABEL',value);
            }
        },       
        campaign:{
            get(){
                return parseInt(this.getCurrentPurchaseOrder.campaign_id);
            },
            set(value){
                this.$store.commit('SET_CURRENT_CAMPAIGN',value);
            }
        },
        agents:{
            get(){
                return this.getCurrentPurchaseOrder.required_agents;
            },
            set(value){
                this.$store.commit('SET_REQUIRED_AGENTS',value);
            }
        },
        hours:{
            get(){
                return this.getCurrentPurchaseOrder.weekly_hours;
            },
            set(value){
                this.$store.commit('SET_WEEKLY_HOURS',value);
            }
        },
        location:{
            get(){
                return this.getCurrentPurchaseOrder.site_location;
            },
            set(value){
                this.$store.commit('SET_SITE_LOCATION',value);
            }
        },
        date:{
            get(){
                return this.getCurrentPurchaseOrder.training_date;
            },
            set(value){
                this.$store.commit('SET_TRAINING_DATE',value);
            }
        },
        status:function(){
            return this.getCurrentPurchaseOrder.status;
        }
    },
    methods:{
       ...mapActions(['updatePurchaseOrder','fetchCampaignsByDepartmentName','getPurchaseOrderById']),
       submit:function(){
           this.updatePurchaseOrder(this.getCurrentPurchaseOrder).
           then(()=>{
               this.$fire({
                  type: 'success',
                  title:'The purchase order has been updated.',
                  showConfirmButton: false,
                  timer: 1500
               });
           }).then(()=>{
               setTimeout(()=>{
                   this.$router.push({name:'recruitment.po'})
               },1500)
           });
       }
    },
    mounted(){
        this.$emit('set-title','Edit Purchase Order');        
    },
    beforeMount(){
        this.fetchCampaignsByDepartmentName('Operations');
        this.getPurchaseOrderById(this.$route.params.id);
    }

}
</script>
