<template>
    <div class="todayActivity px-4 py-8">
        <h1 class="text-4xl font-thin mb-3 text-center">Today's Activity</h1>
        <div v-if="is_loading" class="text-center">
            <loader />
        </div>
        <div v-else class="space-y-3">
            <div class="h-[500px] mx-auto bg-gray-50 rounded-xl p-2">
                <time-line :shifts="getClockerHours" />
            </div>
            <div class="text-center">
                <button
                    @click="goToToday"
                    class="bg-c-alt text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                >
                    <div class="flex m-auto uppercase font-semibold">
                        Need to make changes ?
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/views/components/Loader";
import auth from "@/config/user";
import TimeLine from "@/views/clocker/adjustments/components/TimeLine";
export default {
    components: {
        Loader,
        TimeLine,
    },
    data() {
        return {
            is_loading: true,
        };
    },
    computed: {
        ...mapGetters(["getTodayActivity", "getClockerHours"]),
    },
    props: {
        profileInfo: Object,
    },
    methods: {
        ...mapActions(["loadTodayActivities", "fetchClockerHours"]),

        goToToday() {
            this.$emit('close');
            this.$router.push({ name: 'clocker.today' });
        },
    },
    created() {
        this.fetchClockerHours(auth.currentUser.EmpID).then(() => {
            this.is_loading = false;
        });
    },
};
</script>
<style scoped>
#todayActivities___BV_modal_outer_ {
    overflow: hidden;
    z-index: 20001 !important;
}
</style>