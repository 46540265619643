<template>
 <!-- This example requires Tailwind CSS v2.0+ -->
 <div>
     <modal v-if="showModal" @close="closeEditModal" :employee="adjRequest.employee" :date ="adjRequest.date"/>
    <!-- #TopsideBannerStart-->
    <div class="relative mx-auto mt-3 mb-3 max-w-5xl flex flex-row items-center justify-center">
        <strong>Color schema:</strong>
        
    <color title="Pending, in revision or validating." hex="#ff3d00"/>
    <color title="Approved or day off." hex="#8bc34a"/>
    <color title="No data available." hex="#ff3d00">
        <template slot="icon">
        <span class="bg-white border-2 border-gray-100 rounded-full shadow  px-2 mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
        </span>
        </template>
    </color>
        
    </div>
    <!-- #TopsideBannerEnd-->
    <div class="mx-auto w-10/12  bg-white shadow-md  rounded-lg mb-5">
    <!--#TableActionBarStart-->
    <div class="mb-3 py-2 flex flex-row items-center justify-start pl-3 pt-3">
        <button :class="!currentWeek == true ?'px-3 py-2 shadow bg-gray-700 ml-2 rounded-sm text-white':'px-3 py-2 text-gray-400 rounded-sm hover:underline'"  @click="loadPreviousWeek">
            Previous Week
        </button>
        <button :class="currentWeek == true ?'px-3 py-2 shadow bg-gray-700 ml-2 rounded-sm text-white':'px-3 py-2 text-gray-400 rounded-sm hover:underline'" @click="loadCurrentWeek">
            Current Week
        </button>
        <h4 class="text-sm ml-3 mt-2" v-if="isGranted('SystemAdministrator','AccountManager')">Team supervisor: {{supervisor}}</h4>
        <div class="w-1/2 ml-auto flex flex-row mr-4" v-if="isGranted('SystemAdministrator','AccountManager')">
        <team-filter class="ml-auto" @clear="clearFilter" @filter="filterTeams" :key="resetKey"/>
        </div>
    </div>
    <!--#TableActionBarEnd-->
    <div v-if="loading" class="relative flex flex-row items-center justify-start p-3 text-md">
        We are loading your team information. Please wait
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
    </svg>
    </div>
    <div  class="relative overflow-x-auto">
    <table v-if="getSAH.length > 0" class="min-w-full divide-y divide-gray-200" >
        <thead class="bg-gray-50">
        <tr>    
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">L.O.B</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sunday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tuesday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Wednesday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thursday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Friday</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Saturday</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
        </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200" v-if="!loading">
        <tr v-for="(item, index) in getSAH " :key="index">
            <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <avatar :employeeId="item.employee_id" class="w-10 h-10 text-2xl"/>
                </div>
                <div class="ml-2">
                <div class="text-sm font-medium text-gray-900">{{item.employee_id}}</div>
                </div>
            </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{item.campaign}}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{item.lob}} </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'sunday')" day="sunday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'monday')" day="monday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'tuesday')" day="tuesday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'wednesday')" day="wednesday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'thursday')" day="thursday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'friday')" day="friday"/></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><time-formatter :id="item.employee_id" @edit="showEditModal" @navigate="goToPreview(item.employee_id,...arguments)" :prev="!currentWeek" :val="getValue(item, 'saturday')" day="saturday"/></td>
                <td class="px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold bg-blue-100 border-b border-gray-100">{{getTotal(item)}}</td>
        </tr>

        <!-- More people... -->
            
        </tbody>

            <body-placeholder v-else/>
    </table>

    </div>

    </div>
    <div v-if="(getSAH.length <= 0 || getSAH.length == undefined) && !loading  " class="relative mx-auto px-2">
        
        <img src="../../assets/nein.png" class="w-48 h-48 mx-auto" alt="Not Found"/>
            <h4 class="text-xl text-gray-400 mt-2">Mr Yeti has eaten your team, please request more team members via ticket</h4>
    </div>
 </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import TeamFilter from './components/TeamSearchbar'
import TimeFormatter from './components/TimeFormatter'
import Color from './components/ColorPreview'
import BodyPlaceholder from './components/TableBodyPlaceholder'
import Modal from './components/SupervisorAdjustmentModal'
import Avatar from '@/views/components/Utilities/avatar'
export default {
    components:{TeamFilter, TimeFormatter, Color, BodyPlaceholder, Modal, Avatar},
    data(){
        return{
            currentWeek : true, 
            loading:false, 
            resetKey:0, 
            supervisor:null, 
            showModal:false, 
            editKey:0, 
            adjRequest:{
                employee:'',
                date:''
            },
            dates:{
              sunday:0,
              monday:1,
              tuesday:2,
              wednesday:3,
              thursday:4,
              friday:5,
              saturday:6
            },
        }
    },
    created(){
        this.supervisor = this.emp_id;
        this.loading = true;
        this.$emit('set-title', 'Supervisor Hours Review');
        this.fetchSAH({id:this.supervisor,previous:!this.currentWeek})
        .then(()=> this.loading =false);

         Object.keys(this.dates).forEach((date) => {
             this.dates[date] = this.$moment().startOf('week').add(this.dates[date], 'days').format('YYYY-MM-DD');
         })
      
    },
    methods:{
        ...mapActions(['fetchSAH']),
        loadCurrentWeek(){
            if(!this.currentWeek){
                 this.loading = true;
                 this.currentWeek = true;
                this.cleanDates();
                 Object.keys(this.dates).forEach((date) => {
                    this.dates[date] = this.$moment().startOf('week').add(this.dates[date], 'days').format('YYYY-MM-DD');
                })

                 this.fetchSAH({id:this.supervisor, previous:!this.currentWeek}).then(()=>{
                    this.loading = false;
                 });
               
                
            }

        },
        loadPreviousWeek(){
             
            if(this.currentWeek){
                this.loading = true;
                this.currentWeek = false;
                this.cleanDates();
                Object.keys(this.dates).forEach((date) => {
                    this.dates[date] = this.$moment().startOf('week').add(this.dates[date]-7, 'days').format('YYYY-MM-DD');
                })

                 this.fetchSAH({id:this.supervisor, previous:!this.currentWeek}).then(()=>{
                      this.loading = false;
                 });
               
                
            }
        },
        cleanDates: function(){
              this.dates['sunday'] = 0;
              this.dates['monday'] = 1;
              this.dates['tuesday'] = 2;
              this.dates['wednesday'] = 3;
              this.dates['thursday'] = 4;
              this.dates['friday'] = 5;
              this.dates['saturday'] = 6;
        },
        clearFilter:function(){
            this.resetKey ++;
        },
        filterTeams:function({id}){
            this.supervisor = id;
            this.resetKey ++;
           
            this.loading = true;
            this.fetchSAH({id:this.supervisor, previous:!this.currentWeek}).then(()=>{ 
                this.loading = false;
            });
           
        },
        goToPreview:function( empid, date,){
            this.$router.push({path:`/payroll/hours-review/supervisor/${empid}/${date}`});
        },
        getTotal(item){
            let values = {
                sunday:JSON.parse(item.sunday)?.value ?? 0 ,
                monday:JSON.parse(item.monday)?.value ?? 0,
                thursday:JSON.parse(item.thursday)?.value ?? 0,
                wednesday:JSON.parse(item.wednesday)?.value ?? 0,
                tuesday:JSON.parse(item.tuesday)?.value ?? 0,
                friday:JSON.parse(item.friday)?.value ?? 0,
                saturday:JSON.parse(item.saturday)?.value ?? 0,
            }, total = 0;

            Object.keys(values).forEach(element => {
               
                total = parseFloat(total) + parseFloat(values[element]);
            })
            return total.toFixed(2);
        },
        getValue(item,day)
        {
            let dailyItem = item[day];

            let fallbackItem ={empty:true};

             return JSON.parse(dailyItem) || fallbackItem;
                
        },
        showEditModal:function({day, id}){
            this.showModal =  true;
            this.adjRequest.employee = id;
            this.adjRequest.date = this.dates[day];
        },
        closeEditModal:function(){
            this.showModal =  false;
            this.adjRequest.employee = '';
            this.adjRequest.date = '';
            this.editKey ++;
        }
    },
    computed:{
    ...mapGetters(['getSAH']),
    },
    
}
</script>

