<template>
<div class="main py-2">
    <!-- DISCONTINUED -->
  <b-container class="containerMargins">

      <b-row v-if="isLoading" class="loading">
          <b-col class="mb-4" offset-md="2">
              <p class="font-italic font-weight-bold">"We are loading all our components..."</p>
              <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
              <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
              <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          </b-col>
      </b-row>
      <b-row v-if="!isLoading" >
        <b-col align-self="center" class="mt-2" >
          <div>
              <table class="table-sm table table-striped">
                <thead class="thead-dark">
                <tr>
                    <th class="text-center" scope="col">Date</th>
                    <th class="text-center" scope="col">Campaign</th>
                    <th class="text-center" scope="col">LOB</th>
                    <th class="text-center" scope="col">Team</th>
                    <th class="text-center" scope="col">Payable</th>
                    <th class="text-center" scope="col">Supervisor Status</th>
                    <th class="text-center" scope="col">Agent Status</th>
                    <th class="text-center" scope="col">Action</th>
                </tr>
                </thead>
                <tbody v-if="getAgentHourReviewTable.length">
                <tr :id="index" v-for="(currentRow, index) in getAgentHourReviewTable" :key="index">
                    <td class="text-capitalize">{{currentRow.date | moment("YYYY-MM-DD")}}</td>
                    <td class="text-capitalize">{{currentRow.Campaign}}</td>
                    <td class="text-capitalize">{{currentRow.Lob}}</td>
                    <td class="text-capitalize">{{currentRow.Team}}</td>
                    <td class="text-capitalize">{{hhmmss(currentRow.Payable)}}</td>
                    <td class="text-capitalize">{{currentRow.SupervisorStatus}}</td>
                    <td class="text-capitalize">{{currentRow.AgentStatus}}</td>
                    <td class="text-capitalize">
                        <div>
                            <button @click="detailsRequest(currentRow)" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full">Details</button>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="8">No records found.</td>
                </tr>
                </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
export default {
  components: {  },
  data(){
      return{
          isLoading: true,
         
      }
  },
  computed:{
      ...mapState([
          'clockerUsers'
        ]),
      ...mapGetters([
          'getAgentHourReviewTable'
        ])
    },
    created(){
        this.fetchTableAgentHourReview().then(()=>{
            this.isLoading = false;
        });
    },
    methods:{
        ...mapActions(['fetchTableAgentHourReview']),
        detailsRequest: function(data){
            this.$router.push({
                name: 'myHoursDetails', 
                params: { 
                    rowObj: data
                }
            });
        },
        pad: function(num) {
            return ("0" + num).slice(-2);
        },
        hhmmss: function(secs) {
          var minutes   = Math.floor(secs / 60);
          secs          = secs%60;
          var hours     = Math.floor(minutes/60)
          minutes       = minutes%60;
          return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
        }
    },
    mounted(){
      let title = 'My hours review';
      this.$emit('set-title',title);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
