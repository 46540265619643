<template>
<div>
  <loading :active.sync="getLoadingTable" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading> 
  <div id="adjustmentRequests" class="adjustmentsTable">
    <b-container>
        <b-row class="ml-3">
            <b-col>
                <label class="mr-2"  for="inline-form-custom-select-pref">Search ID:</label>
                <b-input-group v-if="!displayLoadingEmployee">
                    <b-form-input v-if="!reqIDExist" v-model="ReqID" @keyup.enter="filterSearch" placeholder="Search byID" id='filterKeyword' type="text"></b-form-input>
                </b-input-group>
                <div v-if="!reqIDExist">
                    <div v-if="displayLoadingEmployee" class="mt-3">
                        <div class="d-flex align-items-center ">
                            <b-spinner class="ml-5"></b-spinner><strong class="ml-2">Searching ID...</strong>
                        </div>
                    </div>
                </div>
                <div v-if="reqIDExist">
                    <label><strong>ID:</strong> {{ this. ReqID}}</label>
                </div>
            </b-col>
            <b-col align-self="center">
              <label class="mr-sm-2" for="inline-form-custom-select-pref">Reason:</label>
              <b-form-select 
                class="mb-2 mr-sm-2 mb-sm-0" 
                v-model='filterReason'
                @change="filterbyReason"
                :options="[
                  { text: 'Select a reason...', value: null },
                  { text:'Clocker Issues', value: 'clocker'}, 
                  { text:'Agent misuse', value:'misuse' },
                  { text:'Internet Issues', value:'internet' },
                  { text:'Not tracked', value:'missed' }
                ]">
              </b-form-select>
            </b-col>
            <b-col align-self="center">
              <label class="mr-sm-2" for="inline-form-custom-select-pref">Status:</label>
              <b-form-select 
                class="mb-2 mr-sm-2 mb-sm-0" 
                v-model='filterStatus'
                @change="filterbyStatus"
                :options="[
                  { text: 'Select a status...', value: null },
                  { text:'In Review', value: 'In_revision'}, 
                  { text:'Canceled',  value:'cancelled' },
                  { text:'Completed', value:'completed' }
                ]">
              </b-form-select>
            </b-col>
             <b-col align-self="center">
               <label class="mr-sm-2" for="inline-form-custom-select-pref">Campaign: </label>
               <b-form-select 
                  @change="filterbyCampaign"
                  v-model="Campaign"
                  class="mb-2 mr-sm-2 mb-sm-0" 
                  :options="CampaignData">
                   <b-form-select-option :value="null">Please select an option</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row> 
        <b-row class="ml-3 mt-3 mb-3">
          <b-col align-self="center">
            <button @click="clearFilters" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full">Clear Filters</button>
          </b-col>            
        </b-row>
        <b-row>
        <b-col align-self="center">
            <table class="table-sm table table-striped mb-5">
            <thead class="thead-dark">
              <tr>
                <th class="text-center" scope="col">Request ID</th>
                <th class="text-center" scope="col">Employee ID</th>
                <!-- <th class="text-center" scope="col">Requested By</th> -->
                <th class="text-center" scope="col">Event Date</th>
                <th class="text-center" scope="col">Campaign</th>
                <th class="text-center" scope="col">Site</th>
                <th class="text-center" scope="col">Event Type</th>
                <th class="text-center" scope="col">Event Duration</th>
                <th class="text-center" scope="col">Reason</th>
                <th class="text-center" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody v-if="getWatchmenRequests.length">
                <tr :id="'row'+index" v-for="(currentRow, index) in getWatchmenRequests" :key="index">
                    <td class="text-capitalize">{{currentRow.Request_id}}</td>
                    <td class="text-capitalize">{{currentRow.Emp_id}}</td>
                    <!-- <td class="text-capitalize">{{currentRow.RequestedBy}}</td> -->
                    <td class="text-capitalize">{{currentRow.EventDate}}</td>
                    <td class="text-capitalize">{{currentRow.Campaign}}</td>
                    <td class="text-capitalize">{{currentRow.Site_Location}}</td>
                    <td class="text-capitalize">{{currentRow.EventType}}</td>
                    <td class="text-capitalize">{{currentRow.EventDuration}}</td>
                    <td class="text-capitalize">{{currentRow.Reason}}</td>
                    <td style="cursor:pointer">
                      <div v-if="currentRow.RequestStatus == 'completed'">
                        <button @click="undoRequest(currentRow)" class="bg-amber-400 hover:bg-blue-600 px-6 py-2 rounded">Undo</button>
                      </div>
                      <div v-else-if="currentRow.RequestStatus == 'cancelled'">
                        <button class="bg-gray-200 px-6 py-2 rounded" disabled>No Actions</button>
                      </div>
                      <div v-else>
                        <button  v-b-modal="'completeRequest'+currentRow.Request_id" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Change Status</button>
                         <b-modal ref="{{'modalComplete'+currentRow.Request_id}}" :id="'completeRequest'+currentRow.Request_id" hide-footer hide-header>
                          <div>
                            <completeRequest @requestCompleted="reloadData" :id="'completeRequest'+currentRow.Request_id" :currentIndex="index" :currentRequest="currentRow.Request_id" :requestInfo="currentRow"></completeRequest> 
                          </div>
                        </b-modal>
                        
                      </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9">No records found.</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>

</div>
</template>
<script>
  import {mapGetters,mapState, mapActions } from 'vuex'
  import completeRequest from '@/views/components/Clocker/modals/completeRequest.vue';
   import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',

        displayLoadingEmployee: false,
        reqIDExist: false,
        ReqID: null,

        filterStatus: null,
        filterReason: null,
        filterReqID:  null,
        Campaign: null,
        eventDate: null,
        RequestedAt: null,
        CampaignData: [],
      }
    },
    components: {
      completeRequest,Loading
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters([
          'getWatchmenRequests',
          'getLoadingTable',
          'getReqIDExist',
        ])
    },
    created(){
        this.$store.dispatch('fetchWatchmentRequests').then(()=>{
          let Campaign = [];
          this.getWatchmenRequests.map(function(request){
            Campaign.push(request.Campaign);
          });
          const newArr = Campaign.filter((el, index) => Campaign.indexOf(el) === index)
          this.CampaignData = newArr;
        });
    },
    methods:{
      ...mapActions([
        'undoRequestAction',
        'filterMyRequests',
        'isValidID',
      ]),
      filterSearch() {
          this.displayLoadingEmployee = true;
          this.isValidID(this.ReqID).then(()=>{
              this.displayLoadingEmployee = false;
              this.reqIDExist = true;
                let filteredBy = {
                    filter: 'IDRequest',
                    value: this.ReqID
                  }
                  this.filterMyRequests(filteredBy);              
          });
      },
      filterbyCampaign: function(){
         if(this.Campaign != null){
          let filteredBy = {
            filter: 'Campaign',
            value: this.Campaign
          }
          this.filterMyRequests(filteredBy);
        }
      },
      filterbyStatus: function(value){
          let filteredBy = {
            filter: 'statusRequest',
            value: value
          }
          this.filterMyRequests(filteredBy);
      },
      filterbyReason: function(value){
          let filteredBy = {
            filter: 'reasonRequest',
            value: value
          }
          this.filterMyRequests(filteredBy);
      },
      clearFilters: function(){
        this.displayLoadingEmployee   = false;
        this.reqIDExist               = false;
        this.ReqID                    = null;
        let filteredBy = {
            filter: 'clear',
            value: 0
          }
          this.filterMyRequests(filteredBy).then(()=>{
            this.filterStatus = null;
            this.filterReason = null;
            this.filterReqID = null;
            this.Campaign     = null;
            this.eventDate    = null;
            this.RequestedAt  = null;
          });
      },
      undoRequest: function(data){
        event.preventDefault();
        let requestInfo = {
          newStatus: 'In_revision',
          requestObj: data
        }
        this.undoRequestAction(requestInfo).then(()=>{
            this.$fire({
              type: 'success',
              title: 'Undo completed',
              showConfirmButton: false,
              timer: 1500
            }).then(()=>{
              this.$router.go(0);                
            }); 
        });
      },
      reloadData: function(data){
        this.$root.$emit("bv::hide::modal", "completeRequest"+data.request);
        document.getElementById('row'+data.index).style.display = 'none';
      },
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
  .btn-secondary.disabled, .btn-secondary:disabled {
    cursor: no-drop;
  }
  .underline{
      background-color: #007bff !important;
      color: white;
  }
  .b-calendar .b-calendar-grid {
        width: 100% !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
</style>