<template>
  <div class="flex bg-white shadow-sm rounded-[10px] p-4 m-1">
    <div class="absolute top-0 left-11 rounded-b-lg bg-c-primary shadow-md text-xs font-semibold text-white uppercase whitespace-nowrap px-3 py-2">New Topic!</div>
    <div v-if="!imgPreview" class="flex-none h-36 w-36 bg-cover bg-center bg-gray-200 rounded-[10px] overflow-hidden" :style="'background-image: url(' + newsfeedImage + ')'"></div>
    <div v-else class="flex-none w-1/3 text-center overflow-hidden" v-for="(img, idx) in imgPreview" :key="idx"><img class="content-center min-h-full " :src="img" /></div>
    <div class="pl-6 text-left">
      <h3 class="text-lg font-semibold text-c-secondary leading-none">{{entryTitle}}</h3>
      <p class="mt-3 mb-0 leading-tight">{{entryDescription}}</p>
    </div>
  </div>
</template>
<script>
export default {
    props: {
      newsfeedImage: {
        type: String,
        required: true
      },
      entryDescription:{
        type: String,
        required: true
      },
      entryTitle:{
        type: String,
        required: true
      },
      imgPreview:{
        type: Array,
        required: false
      }
    }
}
</script>