<template>
    <div class="mx-2">
        <div v-if="getRSALoading" class="flex my-2 justify-content-center text-center">
            <div class="w-full p-3 mx-auto rounded-lg justify-content-center text-center flex">
                <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
            </div>
        </div>
        <div v-else class="flex m-2 my-3 justify-content-center text-center">
            <div class="flex-1 mx-2">
                <info-card />
            </div>

            <div :class="{'w-1/3 mx-2' : this.deviceStatus && this.deviceStatus !== 'NOT-AVAILABLE'}">
                <assign  v-if="this.deviceStatus === 'AVAILABLE'" @closeAssign="closeAll()" :deviceNumer="this.deviceNumer" @loaded="loading=false"/>
                <dispose v-if="this.deviceStatus === 'IN-USE'"    @closeAssign="closeAll()"/>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import Assign  from '@/views/components/campaigns/RSA/elements/rsa_assign'
import Dispose from '@/views/components/campaigns/RSA/elements/rsa_dispose'
import InfoCard from '@/views/components/campaigns/RSA/elements/rsa_infocard.vue'

export default {
    components: {
        Assign,
        Dispose,
        InfoCard
    },
    data() {
        return {
            loading: true,
            employeeId: null,
            assignView: false,
            disposeView: false
        }
    },
    computed:{
        ...mapGetters(['getDeviceInfo', 'getRSALoading']),
        deviceNumer: function() {
            return this.getDeviceInfo?.deviceId
        },
        deviceStatus: function() {
            return this.getDeviceInfo?.status?.toUpperCase()
        },
        deviceType: function() {
            return this.getDeviceInfo?.itemType?.toUpperCase()
        }
    },
    methods:{
        ...mapActions(['checkoutDevice']),
        showAssign: function() {
            this.assignView = true;
        },
        showDispose: function() {
            this.disposeView = true;
        },
        closeAll: function() {
            this.$emit('updateAll', true);
            this.disposeView = false;
            this.assignView = false;
        },
    },
    created() {
        
    }
}
</script>
<style scope>
.my-blue{
    background-color: #415FBC
}
</style>