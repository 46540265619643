<template>
    <div class="fixed z-20 w-full h-full top-0 left-0 flex flex-row items-center justify-center">
        <div class="absolute top-0 left-0 dropback w-full h-full bg-blue-200 opacity-50 z-20" @click="close"></div>
        <div class="relative z-30 p-4 rounded-md shadow-faded bg-white w-1/3">
            <button class="absolute -right-2 -top-2 w-5 h-5 bg-red-300 text-white hover:bg-red-500 z-10 flex items-center ring-2 ring-gray-500 rounded-md justify-center shadow-faded" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
            <h4 class="text-xl font-semibold text-gray-500 text-left ">Employee adjustment request</h4>
            <!--#FormStart-->
            <div class="text-left px-2" v-if="!loading">
                <label class="text-left"> No Data Reason</label>
                <select class="w-full border-2 border-gray-300 px-3 py-2 rounded-sm" required v-model="reason">
                    <option hidden value="">Select an option</option>
                    <option value="DayOff">Day off</option>
                    <option value="Untracked Shift">Untracked shift</option>
                    <option value="Unreported Incident">Unreported Incident</option>
                    <option v-for="(item, index) in  getIncidencesList" :key="index" :value="item.type">Incidence requested: {{ item.type }} {{ item.id }}</option>
                </select>
                <div v-if="isUntrackedShift">
                    <!-- Ingresa el numero de horas     -->
                        <div class="flex flex-col text-center justify-content-center my-4">
                            <span>
                            To track the missing events for this day you'll need to go to the detailed day and edit it as needed
                            </span>
                            <router-link :to="{ name: 'DetailedHours', params: { usuario: 'supervisor', empid: employee, date: date }  }">
                                <button class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2">
                                    Take me there!
                                </button>
                            </router-link>
                        </div>
                </div>
            </div>
            <div v-else class="text-left px-3">
                Loading component 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 animate-bounce inline-block" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                    </svg>
            </div>

            <!--#FooterStart-->
            <div class="text-right px-2" v-if="!loading && !isUntrackedShift">
                <button class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2" :disabled="saving" @click="save">
                    <span v-if="saving">
                        Saving 
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                            <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                    </span>

                        <span v-else>Save</span>
                    </button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {    
    data(){
        return { 
            value: null, 
            reason: '', 
            subcategory_id:10, 
            loading: false, 
            loadingTickets:false,
            selectedStatus: '',
            saving:false
        }
    }, 

    props:{
        employee:{
            type:String
        },
        date:String
    },

    methods:{
        ...mapActions([
            'fetchTicketsByEmployeeId',
            'updateEmployeeSupervisorStatus',
            'fetchActiveIncidences',
        ]),

        close(){
            this.$emit('close');
        },

        save:function(){
            if(this.reason === null) return;
            // let status;
            // if(this.hasDayOff || this.hasUnreportedIncident){
            //     status = this.hasDayOff ? 'DayOff' : 'Unreported Incident';
            // }
            this.saving = true;
            this.updateEmployeeSupervisorStatus({employee: this.employee, date: this.date, status: this.reason})
                .then(()=>{
                    this.saving = false;
                    this.$fire({
                        type: 'success',
                        title: "The changes was made successfully.",
                        showConfirmButton: true 
                    }).then(()=>{
                    this.$router.go(0);
                });
            });
        },
    },

    computed:{
        ...mapGetters([
            'getIncidencesList'
        ]),
        reasonIsNumeric:function(){
            return this.reason !=='' && !isNaN(this.reason);
        },

        hasDayOff:function(){
            return this.reason === 'Day Off';
        },

        hasUnreportedIncident:function(){
            return this.reason === 'Unreported Incident';
        },
        
        isUntrackedShift:function(){
            return this.reason === 'Untracked Shift';
        },
    },
   
    created(){
        this.loading = true;
       this.fetchActiveIncidences({ employee: this.employee, date: this.date }).then(()=>{
            this.loading = false;
        });
    },
   
}
</script>

<style>
    .shadow-faded{
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
</style>