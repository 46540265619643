import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    fileName:           null,
    status:             null,
    images:             [],
    fileForm:           null,
};
const getters = {
    getFileName:        state => state.fileName,
    getFileStatus:      state => state.status,
    getImages:          state => state.images,
    getFileForm:        state => state.fileForm,
};
const actions = {

    persistFormData({commit}, data){
        commit('setFormData', data);
    },


    async uploadImgProof ({ commit }, data){        
        const endpoint = API_URL + 'api/v2/files/proof/images/upload/tiedImgToTicket';
        const response = await axios.post(endpoint, data).then((res)=>{
            if(res.status === 200){
                commit('setFileStatus',true);
                commit('setFileName',res.data.name)
            }
            return res;
        }).catch((error) => {
            commit('setFileStatus',false);
            return error.response;
        });    
        return response;
    },

    async uploadImgDashboard ({ commit },data){        
        const endpoint = API_URL + 'api/v2/files/dashboard/images/upload';
        const response = await axios.post(endpoint,data).then((res)=>{
            if(res.status === 200){
                commit('setFileStatus',true);
                commit('setFileName',res.data.name)
            }
            return res;
        }).catch((error) => {
            commit('setFileStatus',false);
            return error.response;
        });    
        return response;
    },


    async fetchDashboardManagerImages({ commit }){
        const endpoint = API_URL + 'api/v2/files/dashboard/manager/getImages';
        const response = await axios.get(endpoint).then((res)=>{
            if(res.status === 200){
                commit('setArrayImages',res.data.images)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },

    async fetchDashboardImages({ commit }){
        const endpoint = API_URL + 'api/v2/files/dashboard/getImages';
        const response = await axios.get(endpoint).then((res)=>{
            if(res.status === 200){
                commit('setArrayImages',res.data.images)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },

    async fetchTicketProofImages({ commit }, ticketInfo){
        const endpoint = API_URL + 'api/v2/files/proof/'+ticketInfo+'/getImages';
        const response = await axios.get(endpoint).then((res)=>{
            if(res.status === 200){
                commit('setArrayImages',res.data.images)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },

    async fetchMessageImages({ commit }, message_id){
        const endpoint = API_URL + 'api/v4/messages/'+ message_id + '/images';
        const response = await axios.get(endpoint).then((res)=>{
            if(res.status === 200){
                console.log(res.data.images);
                commit('setArrayImages',res.data.images)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },


    async uploadImgRequestProof({ commit }, data){
        const endpoint = API_URL + '/api/v3.1/files/proofs/missing-event-request';
        const response = await axios.post(endpoint,data,{headers: { 'Content-Type': 'multipart/form-data'}});
        commit('setFileName',data.img);
        return response;
    },

    async uploadImgInventory({ commit }, data){
        const endpoint = API_URL + '/api/v3.1/files/proofs/inventory-document';
        const response = await axios.post(endpoint,data,{headers: { 'Content-Type': 'multipart/form-data'}});
        commit('setFileName',data.img);
        return response;
    },

    async deleteImage({ commit }, fileName){
        const endpoint = API_URL + 'api/v2/files/dashboard/images/deleteImage';
        const response = await axios.post(endpoint, fileName).then((res)=>{
            if(res.status === 200){
                commit('setArrayImages',res.data.images)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },


}

const mutations = {
    setFileName: (state,name) => {
        state.fileName = name;
    },
    setFileStatus: (state,status) => {
        state.status = status;
    },
    setArrayImages: (state, images) =>{
        state.images = images;
    },
    setFormData: (state, data) =>{
        state.fileForm = data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};  