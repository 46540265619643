<template>
  <div class="flex flex-row flex-wrap px-10 py-4 align-items-start justify-content-center">
      <drag-and-drop></drag-and-drop>
  </div>
</template>
<script>
import DragAndDrop from "@/views/Utilities/CSVDragAndDrop";
export default {
  name: "",
  components: {
    'drag-and-drop':DragAndDrop
  },
  methods:{
  },
  data(){
    return {
      title:'Upload the base schedules',
      CurrentPermissions:[],
      location: JSON.parse(sessionStorage.getItem('location'))
    }
  },
  computed:{
    
  },
  mounted(){
    this.$emit('set-title',this.title)
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
