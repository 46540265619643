<template>
    <div class="absolute top-0 left-0 w-full h-full bg-transparent flex items-center justify-center">
        <div class="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-50 z-50" @click="closeModal" ></div>
        <form @submit.prevent="newResource" class="relative bg-white rounded-sm w-5/12 px-4 py-3 z-50">
            <h4 class="text-left">New Resource</h4>
            <div class="mt-2 text-left">
                <label class="text-left pl-2 text-sm">Title:</label>
                <input v-model="form.title" required class="w-full border-b-2 border-gray-400 px-3"/>
            </div>
            <div class="mt-2 text-left">
                <label class="text-left pl-2 text-sm">Description:</label>
                <textarea v-model="form.description" required class="w-full border-b-2 border-gray-400 px-3" maxlength="255"> </textarea>
            </div>
            <div class="mt-2 text-left w-1/2 inline-block pr-2">
                <label class="text-left pl-2 text-sm">Url:</label>
                <input v-model="form.url" required class="w-full border-b-2 border-gray-400 px-3" />
            </div>
             <div class="mt-2 text-left w-1/2 inline-block pl-2">
                <label class="text-left pl-2 text-sm">Category:</label>
                <select v-model="form.category" required class="w-full border-b-2 border-gray-300 px-3 py-1 capitalize">
                    <option value="" hidden>Select a category...</option>
                    <option :value="x.id" v-for="x in categories" :key="x.id" class="capitalize">{{x.name}}</option>
                </select>
            </div>
             <div class="mt-2 text-left">
                <label class="text-left pl-2 text-sm">Image:</label>
                <input id="resource_image" required type="file" class="w-full border-b-2 border-gray-400 px-3"/>
            </div>
            
            <div class="flex items-center justify-end mt-4">
                <button class="ml-auto px-3 py-3 rounded-sm bg-gray-700 text-white shadow-md">Save Resource</button>
            </div>
        </form>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
export default {
    props:{
        item:{
            type:Object,
            default:  ()=> {}
        },
        categories:Array
    },
    data(){ 
        return{
            form:{
                title:'',
                description:'',
                url:'',
                category:'',
                image:'',
                locations:[]
            }
        }
    },
    methods:{
        ...mapActions(['createResource']),
        async newResource(){
            
            const file = document.getElementById('resource_image')?.files[0];

            if(!file['type'].includes('image') && file.size > 5242880){
                return this.$fire({title:"Please provide a valid image jpg/png file below 5mb size.", type:"warning"});
            }
            
            let frm = new FormData();

            frm.set('title',this.form.title);
            frm.set('description',this.form.description);
            frm.set('link',this.form.url);
            frm.set('category',this.form.category);
            frm.set('site',[]);
            frm.set('image',file);
            
            await this.createResource(frm);
            
            this.$fire({title:"Resource created successfully", type:"success"})
            .then(()=>{
                this.$router.go(0);
            })
        },
        closeModal:function(){
            this.$emit('reset');
        }
       
    },
    computed:{
    
    },
    mounted(){
    }
}
</script>