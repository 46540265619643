import axios from '@/config/axios'

const state = {
   details:{
        Id:null,
        NomipaqID:null
   },
   status:'pending'
};

const getters = {
   getCandidatePayrollDetails:(state) => state.details
};

const actions = {
    async storeCandidatePayrollDetails({ commit },data){        
        commit('setPayrollStatus','saving');
        const response  = await axios.post(`api/v2/recruitment/candidates/${data.Id}/setPayrollDetails`,data).then((res)=>{
            commit('setCandidatePayrollDetails',res.data.details);
            commit('setPayrollStatus','saved');
         }); 

         return response;        
    },

    async fetchCandidatePayrollDetails({ commit },id){        
        commit('setPayrollStatus','saving');
        const response  = await axios.get(`api/v2/recruitment/candidates/${id}/getPayrollDetails`).then((res)=>{
            commit('setCandidatePayrollDetails',res.data.details);
            commit('setPayrollStatus','saved');
         }); 

         return response;        
    },


}

const mutations = {   
    setCandidatePayrollDetails:(state,details) => (state.details = details), 
    setPayrollStatus:(state,status) => (state.status = status),
    setCandidatePayrollId:(state,id) => (state.details.NomipaqID = id)
};

export default {
    state,
    getters,
    actions,
    mutations
};