<template>
    <div class="bg-white rounded-[10px] shadow-sm py-10 text-left w-1/2">
        <div v-if="!loadingView">
            <div class="flex border-bottom px-10 pb-4">
                <i :class="(getMessage.Message_Type == 2 ? 'bi bi-person bg-green-100 text-green-600':'bi bi-broadcast bg-red-100 text-red-600')+' inline-block text-center w-10 h-10 text-[150%] rounded-full mr-3'"></i>
                <div>
                    <h4 class="mb-0">{{ getMessage.Title }}</h4>
                    <span class="block text-gray-600">From: {{getMessage.CreatedBy}}</span>
                    <span class="text-gray-400">Visible from: {{getMessage.StartTime  | moment("MM-DD-YYYY")}} - To: {{getMessage.EndTime  | moment("MM-DD-YYYY")}}</span>
                </div>
            </div>
            <div class="border-bottom px-10 py-4">
                <span class="block text-gray-600 mb-3">Message: </span>
                <p class="mb-0">{{ getMessage.Body }}</p>
            </div>
            <div class="border-bottom px-10 py-4">
                <p class="mb-0 text-gray-400">- Attachments</p>
                <div class="w-1/6 items-center m-1 hover:shadow-lg rounded-lg cursor-pointer group min-w-[150px]">
                    <div class="bg-transparent relative group-hover:border-black-800 rounded-lg p-3 border-[1px] border-gray-200 hover:border-gray-100 mx-auto">
                        <img :src="getImages" thumbnail alt="Image not found" v-b-modal="'imgModal'+ this.idx">
                    </div>
                    <b-modal size="lg" ref="displayImage" :id="'imgModal'+ this.idx" hide-footer centered no-close-on-backdrop>
                        <div class="flex">
                            <div class="rounded-lg p-3 border-[1px] border-gray-200 mx-auto">
                                <img :src="getImages" thumbnail fluid-grow alt="Image not found" v-b-modal="'displayImage'">
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
            
            <div class="px-10 pt-4 text-center">
                <button class="border-2 border-gray-600 hover:border-gray-700 text-gray-600 px-6 py-2 rounded-full" @click.prevent="goBack()">Go Back</button>
                <button v-if="getMessage.AckRequired === 1 && (getMessage.accion_type !== 'acknowledge')" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full ml-4" @click.prevent="pressAcknowledge()"><i class="bi bi-check-lg mr-2"></i>Acknowledge</button>
            </div>
        </div>
        <div v-else class="px-10">Loading...</div>
    </div>
</template>
<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: { },
        data(){
            return {
                loadingView: true,
                idmessage: null,
                idx: 1,
            }
        },
        validations: {
        }, 
        computed:{
            ...mapState(['message','files']),
            ...mapGetters(['getMessage','getImages'])
        },
        created(){
            this.loadingView = true;
            this.idmessage = this.$route.params.id;
            this.viewMessage(this.idmessage).then(()=>{
                this.fetchMessageImages(this.idmessage).then(()=>{
                    this.loadingView = false;
                });
            }).catch(()=>{
                this.status = 'error';
                    this.$fire({
                        type: 'warning',
                        title: 'Not allowed',
                        text: 'You are not allowed to see this message',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(()=>{
                        this.$router.push({ name: 'msg.center' });
                    })
            });
            this.setAsViewed(this.idmessage);
        },
        methods:{
            ...mapActions([
                'viewMessage',
                'setAcknowledge',
                'setAsViewed',
                'fetchMessageImages',
            ]),
            goBack:function(){
                this.$router.push({ name: 'msg.center' });
            },
            pressAcknowledge:function(){
                this.$confirm("", "Are you agree?", "question").then((result) => {
                    if (result){                
                        this.setAcknowledge(this.idmessage).then((resp) =>{
                           if(!resp.error){
                                this.$fire({
                                    type: 'success',
                                    title: 'Successfully notified',
                                    showConfirmButton: false,
                                    timer: 1000,
                                }).then(()=>{
                                    this.$router.push({ name: 'msg.center' });
                                })
                            }
                        }).catch(()=>{
                            this.status = 'error';
                                this.$fire({
                                    type: 'warning',
                                    title: 'Something wrong',
                                    text: 'Please report to a system administrator',
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            
                        })
                    }
                })

            }
        },
        mounted(){
            let title = 'Message';
            this.$emit('set-title',title);
        }
    };
</script>