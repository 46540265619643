<template>
    <div class="block bg-white shadow-sm rounded-md mx-5 items-center justify-center box-border overflow-x-auto" 
    
    v-if="this.showTable">
       <table class="block w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >EmpID</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Campaign</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >LOB</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Site</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Date</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Std Billable</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Grv Billable</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Std Payable</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Grv Payable</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Created By</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Created At</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Status</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Reason</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center">Details</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center" >Approve</th>
            <th class="p-3 uppercase text-xs font-medium text-gray-500 text-center">Reject</th>
          </tr>
        </thead>
        <tbody v-if="getSpecialList.length > 0">
          <tr :id="item.id" v-for="(item, index) in this.getSpecialList" :key="item.id" class="whitespace-nowrap">
              <td class="p-3">{{item.Emp_id}}</td>
              <td class="p-3">{{item.Campaign}}</td>
              <td class="p-3">{{item.LOB}}</td>
              <td class="p-3">{{item.Site}}</td>
              <td class="p-3">{{item.event_date | moment("YYYY-MM-DD")}}</td>
              <td class="p-3 bg-gray-100">{{secondsToDecimals(item.PayrollStdBill, item.stdbill)}}</td>
              <td class="p-3 bg-gray-300">{{secondsToDecimals(item.PayrollGravBill, item.gravbill)}}</td>
              <td class="p-3 bg-gray-100">{{secondsToDecimals(item.PayrollStdPay, item.stdpay)}}</td>
              <td class="p-3 bg-gray-300">{{secondsToDecimals(item.PayrollGravPay, item.gvypay)}}</td>
              <td class="p-3">{{item.RequestedBy}}</td>
              <td class="p-3">{{item.Requested_datetime | moment("YYYY-MM-DD HH:mm")}}</td>
              <td class="p-3">{{item.Status}}</td>
              <td class="p-3 whitespace-normal">{{item.Reason ? item.Reason : 'Not Specified'}}</td>
              <td class="p-3 mt-1 align-items-center justify-content-center" style="cursor: pointer;">
                  <div class="flex flex-auto align-items-center justify-content-center" v-b-modal="'seeMessageModal'+index">
                      See <svg class="w-6 h-6 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
                  </div>
                  <b-modal :ref="'seeMessageModal'+index" :id="'seeMessageModal'+index" centered hide-footer>
                    {{item.Comment}}
                  </b-modal>
              </td>
              <td class="p-3 mt-1 align-items-center justify-content-center">
                  <div v-if="item.Status === 'PENDING'" class="flex flex-auto align-items-center justify-content-center">
                      <vs-tooltip text="Approve"><svg  style="cursor: pointer;" @click="ApproveItem(item.Request_id)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></vs-tooltip>
                  </div>
                  <div v-else class="flex flex-auto align-items-center justify-content-center">
                      <svg  style="cursor: default;" class="w-6 h-6" fill="none" stroke="#676767" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                  </div>
              </td>
              <td class="p-3 align-items-center justify-content-center">
                    <div v-if="item.Status === 'PENDING'" class="flex flex-auto align-items-center justify-content-center">
                        <vs-tooltip text="Reject"><svg  style="cursor: pointer;" @click="DeclineItem(item.Request_id)" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg></vs-tooltip>
                    </div>
                    <div v-else class="flex flex-auto align-items-center justify-content-center">
                        <svg  style="cursor: default;" class="w-6 h-6" fill="#676767" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
                    </div>
               </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="p-3 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="16">No records found.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="text-center py-5">
      <p class="font-italic font-weight-bold">Loading...</p>
      <p class="font-italic font-weight-bold">
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </p>              
        
    </div>
</template>
<script>

  import {mapGetters,mapState, mapActions } from 'vuex'
  export default {
      data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        showTable: false,
        newDate: [],

        SurveysSelected: null,
        SurveyFilter: null,
        SurveysActive: null,
        SurveysInactive: null,
        toggle: true,

        buttonLoading: false,
        buttonText: "Agree",
      }
    },
    components: {
      // Loading,
    },
    computed:{
      ...mapState(["payroll"]),
      ...mapGetters(['getSpecialList'])
    },
    created(){
        this.SurveysSelected = this.getSpecialList;
        this.showTable = true;
    },
    methods:{
      ...mapActions([
        'changeSpecialRequestStatus',
      ]),


      ApproveItem(data){
        this.$confirm("It will no longer be in the list", "Do you want to approve this request?").then((result) => {
            if (result){
                let objData = { idSelected: data, newStatus: 'APPROVED' }
                this.changeSpecialRequestStatus(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Incidence Approved",
                      showConfirmButton: false,
                      timer: 2300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
            })
      },

      DeclineItem(data){
        this.$confirm("It will no longer be in the list", "Do you want to reject this request?").then((result) => {
            if (result){
                let objData = { idSelected: data, newStatus: 'REJECTED' }
                this.changeSpecialRequestStatus(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Incidence Rejected",
                      showConfirmButton: false,
                      timer: 2300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
            })
      },

      secondsToDecimals(payroll, request){
        if (payroll === null || payroll === undefined) {
          payroll = 0;
        }
          let SECONDS = request - payroll;
          let sign = Math.sign(SECONDS) >= 0 ? '' : '-';
          let time = Math.abs(SECONDS) / 3600;
          return sign+time.toFixed(2);
      }

    },
  }

</script>
<style lang="scss" scoped>
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>