<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID"  :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <label v-if="multipleLabel" class="text-left text-xs ml-2 block group-focus:text-gray-600">{{multipleLabel}}</label>
            <div v-for="(option, idx) in optionsToAdd"  :value="option.values" :key="'dp'+idx" :id="elementID" class="form-control" :class="classToAdd">
                <label class="items-center px-1 flex">
                    <input type="checkbox" class="cursor-pointer focus:ring-blue-400 h-6 w-6 rounded">
                <span class="ml-2 text-gray-500">{{option.label}}</span>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    export default { 
    props:{
        multipleLabel:{
            type:       String,
            default:    null,
            required:   true,
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        classToAdd:{
            type:       String,
            default:    'form-control',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        },
        optionsToAdd:{
            type:       Array,
            required:   true,
        }
    },
}
</script>
