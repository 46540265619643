<template>
<div class="space-y-3">
        <modal v-if="showModal" @close="closeModal" @toggle="setLinkedQuestion" :origin_question="origin_question" :all_questions="survey_questions" :index="origin_index"/>
        <form id="InformationForm" action="#" autocomplete="off" class="bg-white border-t-4 border-l-4 border-t-blue-400 border-l-red-400 rounded-lg" @submit.prevent="nextModule()">   
            <div class="space-y-4 p-4">
                <!-- Title -->
                <div class="space-y-1">
                    <div>
                        <input required id="titleId" v-model="survey_info.survey_title" type="text" name="survey_title"
                        class="px-2 py-1 w-full border-b-2 focus:border-blue-400 text-xl font-bold font-sans capitalize focus:placeholder-gray-700"
                        :class="{'border-green-400' : titleIsFilled}"
                        placeholder="Title of this feedback"
                        maxlength="50">
                    </div>
                    <div class="flex flex-row text-xs text-gray-400">
                        <p class="basis-1/2 text-left">
                            <span class="text-red-400 mr-1">*</span>
                            Write a title in less than 50 characters
                        </p>
                        <p class="basis-1/2 text-right" :class="{'text-red-400' : titleLength >= 40 }">
                            {{50 - titleLength}}
                        </p>
                    </div>
                </div>
                <!-- Using -->
                <div class="space-y-1">
                    <div class="flex">
                        <div class="w-full items-center mt-1 flex align-items-center justify-content-start rounded-md shadow-md basis-1/2">
                            <multiselect id="usingId" :disabled="this.survey_info.survey_applyfrom_checkbox" 
                            v-model="survey_info.survey_applyfrom" :custom-label="customFormat" 
                            :options="this.getCampaignList" :multiple="true" 
                            :close-on-select="false" :clear-on-select="false" 
                            :preserve-search="true" placeholder="Select who can apply this" label="Campaign" track-by="RecID"></multiselect>
                        </div>

                        <div class="flex flex-row basis-1/2 px-3">
                            <input v-model="survey_info.survey_applyfrom_checkbox" type="checkbox" id="allUseId"
                            @change="cleanApplyFrom()"
                            class="h-4 w-4 my-auto">
                            <span class="ml-1 font-sans text-sm font-bold text-gray-600 my-auto flex-1 text-left">Everyone can use this feedback</span>
                        </div>
                    </div>

                    <p class="flex flex-row text-xs text-gray-400">
                        <span class="text-red-400 mr-1">*</span>
                        Please specify the groups will be using this feedback.
                    </p>
                </div>
                <!-- Apply To -->
                <div class="space-y-1">
                    <div class="flex">
                        <div class="w-full items-center mt-1 flex align-items-center justify-content-start rounded-md shadow-md basis-1/2">
                            <multiselect id="applyId" :disabled="this.survey_info.survey_applyto_checkbox" 
                            v-model="survey_info.survey_applyto" :custom-label="customFormat" 
                            :options="this.getCampaignList" :multiple="true" 
                            :close-on-select="false" :clear-on-select="false" 
                            :preserve-search="true" placeholder="Select the campaigns to apply" label="Campaign" track-by="RecID"></multiselect>
                        </div>

                        <div class="flex flex-row basis-1/2 px-3">
                            <input v-model="survey_info.survey_applyto_checkbox" type="checkbox" id="allApplyId"
                            @change="cleanApplyTo()"
                            class="h-4 w-4 my-auto">
                            <span class="ml-1 font-sans text-sm font-bold text-gray-600 my-auto flex-1 text-left">Everyone will be evaluated</span>
                        </div>
                    </div>

                    <p class="flex flex-row text-xs text-gray-400 mt-1">
                        <span class="text-red-400 mr-1">*</span>
                        Please select the groups will be evaluated by this feedback.
                    </p>
                </div>
                <!-- Next button -->
                <!-- <div class="text-right">
                    <button type="submit"
                    :disabled="!informationIsFilled"
                    :class="{ 'cursor-default bg-gray-400' : !informationIsFilled, 'bg-blue-600 hover:bg-blue-800' : informationIsFilled }"
                    class="uppercase justify-center w-32 py-2 px-5 shadow-md text-sm font-medium rounded-md text-white">
                        Next
                    </button>
                </div> -->
            </div>
        </form>

        <form id="QuestionsForm" action="#" autocomplete="off" class="bg-white border-t-4 border-l-4 border-t-blue-400 border-l-red-400 rounded-lg" @submit="sendSurvey">
            <div class="space-y-4 p-4">
                <!-- Question format -->
                <div v-for="(input, index) in survey_questions" :key="`questionInput-${index}`" class="my-3 align-items-center justify-content-start w-full p-2 py-3 shadow-md border-l-2 border-gray-600 rounded-r-md">

                    <div class="flex flex-row align-items-center justify-content-star">
                        <label class="text-gray-600 font-bold text-sm mr-2">{{index+1}}. Question:</label>
                        <input v-model="input.question" :id="'question'+index" placeholder="Type your question..." class="bg-transparent ml-2  px-2 flex-1 border-b-2 border-gray-300 focus:border-blue-400  focus:placeholder-gray-700" type="text">

                        <select required :id="'type'+index" v-model="input.question_type" class="mx-2 bg-transparent text-gray-400 hover:text-gray-600 w-32">
                            <optgroup label="Question type">                
                                <option hidden value="">Type..</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="dropdown">Dropdown</option>
                                <option value="date">Date</option>
                                <option value="time">Time</option>
                                <option value="duration">Duration</option>
                                <option value="multiple">Multiple Answers</option>
                            </optgroup>
                        </select>


                        <div class="space-x-1 flex flex-row mx-1 my-auto">
                            <svg @click="addQuestionField(index, survey_questions)" xmlns="http://www.w3.org/2000/svg" 
                            class="h-6 w-6 cursor-pointer text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                            <svg v-show="survey_questions.length > 1" @click="removeQuestionField(index, survey_questions)" xmlns="http://www.w3.org/2000/svg" 
                            class="h-6 w-6 cursor-pointer text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                            <svg @click="showLinkModal(index, survey_questions)" xmlns="http://www.w3.org/2000/svg" 
                            class="h-5 w-5 text-gray-400 hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" 
                            :class="{  'opacity-0 cursor-default' : index === 0, 'cursor-pointer' : !(index === 0), 'text-blue-600' : input.conditions !== '' }"
                            stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                            </svg>
                        </div>
                    </div>

                    <!-- Dropdown and multi option -->
                    <div v-if="input.question_type == 'dropdown' || input.question_type == 'multiple'" class="mx-3">
                        <div v-for="(question_answers, inndex) in input.question_answers" :key="`answerInput-${inndex}`" class="flex flex-row my-2 mx-4">
                            <label class="text-gray-600 font-bold text-sm flex flex-row whitespace-nowrap my-auto">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 rotate-180" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                                </svg>
                            </label>
                            <input :id="index+'answer'+inndex" v-model="question_answers.answer" 
                            :placeholder="'Option ' + (inndex+1)" class="bg-transparent border-b-2 px-2 border-gray-300 focus:border-blue-400  focus:placeholder-gray-600 w-64" type="text">

                            <input v-if="!question_answers.voidpoints" :id="index+'points'+inndex" v-model="question_answers.points" 
                            placeholder="Points" 
                            class="max-w-14 w-16 bg-transparent mx-2 border-b-2 border-gray-300 px-2 focus:border-blue-400  focus:placeholder-gray-600" type="text">

                            <label class="flex my-auto mx-2">
                                <input v-model="question_answers.voidpoints" type="checkbox" :id="index+'voidpoints'+inndex"
                                class="h-4 w-4">
                                <span class="ml-1 font-bold text-sm text-gray-600 whitespace-nowrap">No value</span>
                            </label>

                            <div class="space-x-1 flex flex-row mx-1 my-auto">
                                <svg @click="addAnswerField(question_answers, index)" xmlns="http://www.w3.org/2000/svg" 
                                class="h-5 w-5 cursor-pointer text-green-600" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                                </svg>

                                <svg v-show="survey_questions[index].question_answers.length > 1" @click="removeAnswerField(inndex, index)"  xmlns="http://www.w3.org/2000/svg" 
                                class="h-5 w-5 cursor-pointer text-red-600" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Instruction -->
                <p class="flex flex-row text-xs text-gray-400">
                    <span class="text-red-400 mr-1">*</span>
                    Please, list the questions will be part of your your survey.
                </p>
                <!-- Submit button -->
                <div class="text-right">
                    <button type="submit"
                    :disabled="sending"
                    :class="{ 'cursor-wait bg-blue-400' : sending, 'hover:bg-blue-700' : !sending}"
                    class="w-32 py-2 shadow-md rounded-md  bg-blue-600  p-auto">
                        <span class="text-sm font-medium text-white uppercase justify-center" v-if="sending">
                            Sending...
                        </span>
                        <span class="text-sm font-medium text-white uppercase justify-center" v-else>
                            Save
                        </span>
                    </button>
                </div>
            </div>
        </form>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Multiselect                          from 'vue-multiselect'
import modal from '@/views/feedbacks/modals/linkedQuestion.vue'
    export default {
        components: { 
            Multiselect,
            modal
        },
        data(){
            return{
                survey_info : {},
                survey_questions: [{ 
                    question: "",
                    question_type : "",
                    conditions: "",
                    question_answers: [{ answer: "", points: "", voidpoints: false }]
                }],
                questions: 1,
                sending: false,
                showModal: false,
                origin_question: [],
                origin_index: null

            }
        },
        computed: {
            ...mapState(["surveys, feedbacks"]),
            ...mapGetters([ 'getCampaignList', 'getFeedbackInfo' ]),

            titleIsFilled: function(){
                return this.survey_info.survey_title !== null && this.survey_info.survey_title !== '' && this.survey_info.survey_title !== undefined;
            },

            titleLength: function (){
                return this.survey_info?.survey_title?.length ?? 0;
            },

            informationIsFilled:function (){
                return (this.survey_info?.survey_title !== null && this.survey_info?.survey_title !== '' && this.survey_info?.survey_title !== undefined) &&
                ((this.survey_info?.survey_applyto !== undefined && this.survey_info?.survey_applyto?.length > 0) || ((this.survey_info?.survey_applyto_checkbox !== undefined && this.survey_info?.survey_applyto_checkbox === true))) &&
                ((this.survey_info?.survey_applyfrom !== undefined && this.survey_info?.survey_applyfrom?.length > 0) || ((this.survey_info?.survey_applyfrom_checkbox !== undefined && this.survey_info?.survey_applyfrom_checkbox === true)));
            }
        },
        props:{
          
        },
        methods:{
            ...mapActions(['createFeedbackType',
            'fetchCampaignsGroups',]),

            cleanApplyFrom(){
                this.survey_info.survey_applyfrom = [];
            },

            cleanApplyTo(){
                this.survey_info.survey_applyto = [];
            },
            
            customFormat({Campaign, Location, Title}){
                return `${Campaign} (${Location}) - [${Title}]`;
            },

            addQuestionField(value, survey_questions) {
                let array = { 
                    question: "", 
                    question_type: "", 
                    // question_linked: "",
                    // question_linked_answer: "",
                    conditions: "",
                    question_answers: [{ answer: "", points: "", voidpoints: false }] 
                };
                survey_questions.splice(value+1, 0, array);
            },

            removeQuestionField(index, survey_questions) {
                survey_questions.splice(index, 1);
            },

            showLinkModal:function(index, survey_questions){
                if(index === 0) return;
                this.showModal =  true;
                this.origin_question = survey_questions[index];
                this.origin_index = index;
            },

            closeModal: function (){
                this.showModal =  false;
            },

            setLinkedQuestion: function(value){
                this.survey_questions[value.parent_question].conditions = JSON.stringify(value.conditions);
                this.closeModal();
            },

            addAnswerField(value, index) {
                this.survey_questions[index].question_answers.push({ answer: "", points: "", voidpoints: false });
            },

            removeAnswerField(inndex, index) {
                this.survey_questions[index].question_answers.splice(inndex, 1);
            },

            sendSurvey () {
                event.preventDefault();

                // Starts validation from values inside QUESTIONS
                let key = 0;
                let counter = [];
                [].forEach.call(this.survey_questions, element => {
                    if(element.question === "" ){
                            document.getElementById('question'+key).classList.add('border-2', 'border-red-500')
                            counter.push({question_label : 'empty'})
                    }else{
                        document.getElementById('question'+key).classList.remove('border-2', 'border-red-500')
                    }
                    
                    if(element.question_type === ""){
                            document.getElementById('type'+key).classList.add('border-2', 'border-red-500')
                            counter.push({question_type : 'empty'})
                    }else{
                        document.getElementById('type'+key).classList.remove('border-2', 'border-red-500')
                    }

                    if(element.question_type === "dropdown" || element.question_type === "multiple"){
                        let answerKey = 0;
                        [].forEach.call(this.survey_questions[key].question_answers, element => {
                        // For Answers
                            if(element.answer === undefined || element.answer === null || element.answer === ""){
                                    document.getElementById(key+'answer'+answerKey).classList.add('border-2', 'border-red-500')
                                    counter.push({answer : "Q-"+(answerKey+1)+",A-"+(key+1)})
                            }else{
                                document.getElementById(key+'answer'+answerKey).classList.remove('border-2', 'border-red-500')
                            }
                        // For the points
                            if(element.voidpoints == false && (element.points === undefined || element.points === null || element.points === "")){
                                    document.getElementById(key+'points'+answerKey).classList.add('border-2', 'border-red-500')
                                    counter.push({points : "Q-"+(answerKey+1)+",A-"+(key+1)})
                            }else if(element.voidpoints == false){
                                document.getElementById(key+'points'+answerKey).classList.remove('border-2', 'border-red-500')
                            }
                            answerKey++;
                        });
                    }

                    key++;
                });
                // Ends validation from values inside QUESTIONS

                // Starts validation from values inside INFORMATION
                if(this.survey_info.survey_title === undefined || this.survey_info.survey_title === null || this.survey_info.survey_title === "" ){
                    document.getElementById('titleId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_title : "empty"})
                }else{
                    document.getElementById('titleId').classList.remove('border-2', 'border-red-500')
                }

                if(this.survey_info.survey_applyto_checkbox){ this.survey_info.survey_applyto = this.getCampaignList; }
                if(!this.survey_info.survey_applyto_checkbox && (this.survey_info.survey_applyto === undefined || this.survey_info.survey_applyto === null || this.survey_info.survey_applyto === "" || this.survey_info.survey_applyto === [])){
                    document.getElementById('applyId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_applyto : "empty"})
                }else{
                    if(!this.survey_info.survey_applyto_checkbox){
                        document.getElementById('applyId').classList.remove('border-2', 'border-red-500')
                    }
                }

                if(this.survey_info.survey_applyfrom_checkbox){ this.survey_info.survey_applyfrom = this.getCampaignList; }
                if(!this.survey_info.survey_applyfrom_checkbox && (this.survey_info.survey_applyfrom === undefined || this.survey_info.survey_applyfrom === null || this.survey_info.survey_applyfrom === "" || this.survey_info.survey_applyfrom === [])){
                    document.getElementById('usingId').classList.add('border-2', 'border-red-500')
                    counter.push({survey_applyfrom : "empty"})
                }else{
                    if(!this.survey_info.survey_applyfrom_checkbox){
                        document.getElementById('usingId').classList.remove('border-2', 'border-red-500')
                    }
                }
                // Ends validation from values inside INFORMATION
                if (counter.length > 0) {
                    this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true,
                        confirmButtonColor: '#5E57E5',
                        allowOutsideClick: false,
                        background: '#FFFFFFD9',
                    })
                }else{
                    this.sending = true;
                    let objRequest = {
                        survey_info:        this.survey_info,
                        survey_questions:   this.survey_questions,
                        survey_apply:       this.survey_info.survey_applyto,
                        survey_using:       this.survey_info.survey_applyfrom
                    }
                    // console.log(objRequest)
                    this.createFeedbackType(objRequest).then(() =>{
                        this.$fire({
                            type: 'success',
                            title: 'The survey has been saved successfully',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            this.$router.go(-1);
                        });
                    }).catch((error) =>{
                        console.log(error.response.data.msg);
                    })
                }
            },
           
        },
        created() {
            this.fetchCampaignsGroups();
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
</style>