<template>
  <div>
    <!-- Report an issue -->
    <b-container fluid> 
      <b-row>
        <b-col>
          <!-- Site selector -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <a class="text-danger">*</a>Site:
            </label>
            <div class="col-sm-10">
              <b-spinner v-if="loaderSites"></b-spinner>
              <select v-else id='siteSelect' class="custom-select d-block w-100" @change="loadCampaigns" v-model="selectedLocation"
              :disabled="stepper < 1">
                <option hidden :value="null">Select a Site...</option>
                <option v-for="item in getLocations" :value="item" :key="item">{{item}}</option>
              </select>
            </div>
          </div>
          <!-- Campaign selector -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <a class="text-danger">*</a>Campagin:
            </label>
            <div class="col-sm-10">
              <b-spinner v-if="loaderCampaign"></b-spinner>
              <select v-else id='campaignSelect' class="custom-select d-block w-100" @change="loadLOB" v-model="selectedCampaign"
              :disabled="stepper < 2">
                <option hidden :value="null">Select a Campaign...</option>
                <option v-for="item in getCampaigns" :value="item" :key="item">{{item}}</option>
              </select>
            </div>
          </div>
          <!-- LOB selector -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <a class="text-danger">*</a>LOB:
            </label>
            <div class="col-sm-10">
              <b-spinner v-if="loaderLOB"></b-spinner>
              <select v-else id='lobSelect' class="custom-select d-block w-100" @change="displayTables" v-model="selectedLOB"
              :disabled="stepper < 3">
                <option hidden :value="null">Select an LOB...</option>
                <option v-for="item in getLob" :value="item.label" :key="item.id">{{item.label}}</option>
              </select>
            </div>
          </div>
          <!-- Clear -->
          <div class="form-group row">
            <div class="col flex">
              <button id='clear' class="px-3 p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white ml-auto" :disabled="stepper < 1"
              @click="clearFilters()">
                Clear Filters
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Payable First -->
      <!-- Displaye colors mean -->
      <b-row v-if="AMVariables">
        <vs-tabs alignment="left"  :color="'rgb(102, 116, 255)'" class="z-20">
          <div>
            <vs-tab icon="reply" label="Last Week">
              <!-- Last Week Summary -->
              <component :key="LWSummaryKey" :queryInfo="this.AMVariables" :is="'LWSummary'"></component>
              <vs-tabs alignment="center" :color="'rgb(255, 70, 80)'">
                <vs-tab @click="forceRerender('LWPT')" icon="payment" label="Payable">
                  <component :key="LWPayableTableKey" @refreshComponent="forceRerender('LWPT')" :queryInfo="this.AMVariables"  :is="'LWPayableTable'"></component>
                </vs-tab>
             
             
                <vs-tab @click="forceRerender('LWBT')" icon="monetization_on" label="Billable">
                  <component :key="LWBillableTableKey" @refreshComponent="forceRerender('LWBT')" :queryInfo="this.AMVariables" :is="'LWBillableTable'"></component>
                </vs-tab>  
                            
              </vs-tabs>
            </vs-tab>
            <!-- Se alimentara de Clocker_Events, al seleccionar un date mostrara la de supervisor hour review -->
            <!-- O Agregar el Team en el Supervisor Hour Review -->
            <!-- Modulo Supervisor Hour Reviews -->
            <vs-tab icon="arrow_downward" label="Current Week" class="z-20">
              <component :key="CWSummaryKey" :queryInfo="this.AMVariables" :is="'CWSummary'"></component>
              <vs-tabs  alignment="center" :color="'rgb(255, 70, 80)'">
                <vs-tab @click="forceRerender('CWPT')" icon="payment" label="Payable">
                  <component :key="CWPayableTableKey" :queryInfo="this.AMVariables" :is="'CWPayableTable'" @refreshComponent="forceRerender('CWPT')"></component>
                </vs-tab>
                <vs-tab @click="forceRerender('CWBT')" icon="monetization_on" label="Billable">
                  <component :key="CWBillableTableKey" :queryInfo="this.AMVariables" :is="'CWBillableTable'" @refreshComponent="forceRerender('CWBT')"></component>
                </vs-tab>
              </vs-tabs>
            </vs-tab>
          </div>
        </vs-tabs>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters,mapState, mapActions } from 'vuex'
import LWSummary              from "./LWSummaryTable"
import LWBillableTable        from "./LWBillableTable"
import LWPayableTable         from "./LWPayableTable"

import CWSummary              from "./CWSummaryTable"
import CWPayableTable         from "./CWPayableTable"
import CWBillableTable        from "./CWBillableTable"
import auth                   from "@/config/user"

export default {
  components:{
    LWSummary,
    LWBillableTable,
    LWPayableTable,

    CWSummary,
    CWPayableTable,
    CWBillableTable,
  },
  data() {
    return {
      loaderSites: true,
      loaderCampaign: true,
      loaderLOB: true,
      stepper: 1,

      displayLoader: false,
      loadTables: null,

      LWPT: true,
      LWBT: false,

      deparmentSelected: "Operations",
      AMVariables: null,

      LWSummaryKey: 0,
      LWPayableTableKey: 0,
      LWBillableTableKey: 0,

      CWSummaryKey: 0,
      CWPayableTableKey: 0,
      CWBillableTableKey: 0,

      selectedLocation: null,
      selectedCampaign:null,
      selectedLOB: null,
  
    }
  },
  computed:{
    ...mapState(['AccountManagerClocker']),
    ...mapGetters([
        'getCampaigns',
        'getLocations',
        'getLob',
        'infoEmployee',
      ]),
    currentUserCampaign:function(){ 
      return this.infoEmployee.Campaign      
    },
    // defaultCampaign:{
    //   get(){
    //     return this.selectedCampaign
    //   },
    //   set(value){
    //     this.selectedCampaign = value
    //   }      
    // },
  },
  methods:{
    ...mapActions([
      'loadMyProfileByEmpId',
      'fetchSites',
      'fetchCampaignBySite',
      'fetchLobByCampaignSite'
    ]),
    loadCampaigns:function(){
      this.selectedCampaign = null;
      this.loaderCampaign = true;
      this.selectedLOB = null;
      this.loaderLOB = true;
      this.stepper = 2;
      this.fetchCampaignBySite(this.selectedLocation).then(() => {
        this.loaderCampaign = false;
        this.loaderLOB = false;
      })
    },
    loadLOB: function(){
      this.selectedLOB = null;
      this.loaderLOB = true;
      this.stepper = 3;
      let objectData = {
        location: this.selectedLocation,
        campaign: this.selectedCampaign
      };
      this.fetchLobByCampaignSite(objectData).then(() => {
        this.loaderLOB = false;
      })
    },
    loadVariables: function(){
      let dataInfo = {
        Lob:      this.selectedLOB,
        Campaign: this.selectedCampaign,
        Location: this.selectedLocation,
      }
      this.AMVariables = JSON.stringify(dataInfo);
    },
    displayTables: function(){
      this.loadVariables();
      this.displayLoader = true;
      this.forceRerender('LWPT');
      this.forceRerender('LWBT');
      this.forceRerender('CWPT');
      this.forceRerender('CWBT');
      
    },
    forceRerender: function(componentName){
      switch(componentName){
        case 'LWPT':
          this.LWPayableTableKey +=1;
          this.LWSummaryKey      +=1; 
        break
        case 'LWBT':
          this.LWBillableTableKey +=1;
          this.LWSummaryKey       +=1;
        break
        case 'CWPT':
          this.CWPayableTableKey +=1;
          this.CWSummaryKey       +=1;
        break
        case 'CWBT':          
          this.CWBillableTableKey +=1;
          this.CWSummaryKey       +=1;
        break
      }
    },

    clearFilters:function(){
      this.selectedLocation = null;
      this.selectedCampaign = null;
      this.selectedLOB = null;
      this.stepper = 1;
      localStorage.removeItem('hoursdata')
    },

    localInformation: function(){
      var myData = JSON.parse(localStorage.getItem('hoursdata'));
      this.selectedLocation = myData.value.Location;
      this.loadCampaigns();
      this.selectedCampaign = myData.value.Campaign;
      this.loadLOB()
      this.selectedLOB = myData.value.Lob;
      this.loadVariables();
      this.forceRerender(myData.Window);
    },

    expireLocal: function(key){
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
  },
  created(){
    let idEmployee = auth.currentUser.EmpID;
    this.loadMyProfileByEmpId(idEmployee);
    this.fetchSites().then(() => {
      this.loaderSites = false;
      this.loaderCampaign = false;
      this.loaderLOB = false;
      const load = this.expireLocal('hoursdata');
      if (load !== null) this.localInformation();
    });
  },
  
}
</script>

<style lang="scss">
@keyframes blink {  
  0% { color: white; }
  100% { color: black; }
}
@-webkit-keyframes blink {
  0% { color: white; }
  100% { color: black; }
}
.blink {
  -webkit-animation: blink 1.35s linear infinite;
  -moz-animation: blink 1.35s linear infinite;
  animation: blink 1.35s linear infinite;
} 
.orange{
  background-color: #ffc08c;
}
.red{
  background-color: #ff8c8c;
}
.green{ 
  background-color: #cae1aa; 
}
</style>