<template>
    <div>
        <div class="flex justify-center">
            <h3 class="font-bold">Comments</h3>
        </div>
        <div class="px-5 pb-4 grid grid-cols-1 gap-3">
            <div v-for="comment in comments" :key="comment.id" class="bg-yellow-300 p-3 rounded-tr-2xl border-1 border-yellow-400">
                <p class="font-semibold tracking-tight line-clamp-4 leading-tight">
                    {{ comment.comment }}
                </p>
                <div class="flex flex-col">
                    <span class="text-xs font-light">
                        — {{ comment.created_by }}
                    </span>
                    <span class="text-xs font-light">
                        {{ comment.created_at }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props: {
        comments: {
            type: Array,
            default: () => []
        }
    }
}
</script>