<template>
    <div class="p-8">
        <div class="flex justify-center">
            <h3 class="font-bold">Adding new event to this shift</h3>
        </div>
        <div v-if="!loading">
            <div class="flex justify-center items-center relative mb-3">
                <div
                    class="inline-flex justify-center space-x-3 items-center rounded-full bg-gray-50 p-2 px-3"
                >
                    <div class="flex flex-col justify-center items-center">
                        <span class="font-semibold">
                            {{ shift.start_date }}
                        </span>
                        <span class="font-semibold">
                            {{ shift.start_time }}
                        </span>
                    </div>
                    <span class="h-4 w-4 rounded-full bg-c-alt"> </span>
                    <div>
                        <div
                            class="min-w-[100px] border-b border-x-secondary border-gray-200"
                        ></div>
                    </div>
                    <span
                        class="relative flex h-4 w-4"
                        v-if="isOngoing(shift.end_time)"
                    >
                        <span
                            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600/70 opacity-75"
                        ></span>
                        <span
                            class="relative inline-flex rounded-full h-4 w-4 bg-green-500/70"
                        ></span>
                    </span>
                    <span v-else class="h-4 w-4 rounded-full bg-c-alt"></span>
                    <div class="flex flex-col justify-center items-center">
                        <span class="font-semibold" v-if="shift.end_date">
                            {{ shift.end_date }}
                        </span>
                        <span class="font-semibold uppercase">
                            {{ endTime(shift.end_time) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="block space-y-3">
                <div class="space-y-1">
                    <small class="text-gray-500">Activity to start</small>
                    <multiselect
                        class="flex justify-content-center text-left"
                        v-model="activity_selected"
                        placeholder="Select an activity to start"
                        group-values="events"
                        group-label="type"
                        track-by=""
                        label="label"
                        :options="getEventsByLob"
                        :multiple="false"
                        :group-select="false"
                        :allow-empty="false"
                        :clear-on-select="false"
                        :preserve-search="false"
                        deselect-label=""
                    ></multiselect>
                </div>

                <div class="space-y-4">
                    <small class="text-gray-500"
                        >If this event is on-going please do not fill the end
                        time</small
                    >
                    <div
                        class="flex gap-3 items-center justify-center"
                        v-if="!justOneDate"
                    >
                        <div class="relative w-1/2">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                            >
                                Start Date
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <date-picker
                                    v-model="start_date"
                                    placeholder="YYYY-MM-DD"
                                    value-type="format"
                                    format="YYYY-MM-DD"
                                    :input-class="['border-none']"
                                    :disabled-date="
                                        disabledBeforeStartAndAfterEnd
                                    "
                                    :clearable="false"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="relative w-1/2">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                            >
                                End Date
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <date-picker
                                    v-model="end_date"
                                    placeholder="YYYY-MM-DD"
                                    value-type="format"
                                    format="YYYY-MM-DD"
                                    :input-class="['border-none']"
                                    :disabled-date="
                                        disabledBeforeStartAndAfterEnd
                                    "
                                >
                                </date-picker>
                            </div>
                        </div>
                    </div>

                    <div class="flex gap-3 items-center justify-center">
                        <div class="relative">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                            >
                                Start time
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <i class="bi bi-clock mr-1 text-gray-500"></i>
                                <vue-timepicker
                                    class="peer"
                                    v-model="start_time"
                                    format="HH:mm"
                                    hide-clear-button
                                    />
                                    <!-- :hour-range="availableStartHourRange"
                                    :minute-range="availableStartMinuteRange" -->
                            </div>
                        </div>
                        <div class="relative">
                            <div
                                class="absolute uppercase text-xs bg-white -top-2 left-4 text-gray-400"
                            >
                                End time
                            </div>
                            <div class="py-2 px-3 rounded-xl border-[1px]">
                                <i class="bi bi-clock mr-1 text-gray-500"></i>
                                <vue-timepicker
                                    v-model="end_time"
                                    format="HH:mm"
                                    />
                                    <!-- :hour-range="availableEndHourRange"
                                    :minute-range="availableEndMinuteRange" -->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="commentIsRequired" class="space-y-1">
                    <small class="text-gray-500"
                        >This is required to add a comment for this
                        modification. Please be specific</small
                    >
                    <textarea
                        v-model="comment"
                        class="w-full p-3 resize-none rounded-lg border-[1px]"
                        placeholder="Write a comment"
                        maxlength="255"
                        rows="5"
                    ></textarea>
                    <small
                        class="text-gray-500 text-xs ml-2"
                        :class="{ 'text-red-500': commentIsInvalid }"
                    >
                        {{ commentLength }} / 255.
                    </small>
                </div>
                <div class="text-center">
                    <button
                        :disabled="
                            activity_selected === null 
                            || commentIsInvalid 
                            || startTimeIsInvalid
                            || endIsNotRight
                        "
                        type="button"
                        class="bg-blue-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                        @click="createEvent"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <loader />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueTimepicker from "vue2-timepicker";
import Loader from "@/views/components/Loader";
import DatePicker from "vue2-datepicker";
export default {
    components: {
        VueTimepicker,
        Loader,
        DatePicker
    },
    props: {
        shift: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            start_time: null,
            end_time: null,
            start_date: this.shift.start_date,
            end_date: this.shift.end_date,
            activity_selected: null,
            comment: null,
        };
    },
    watch: {
        end_time: {
            handler(newValue) {
                if (
                    typeof newValue === "object" &&
                    newValue?.HH === "" &&
                    newValue?.mm === ""
                ) {
                    this.end_time = null;
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["getEventsByLob"]),

        justOneDate() {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const startDate = new Date(this.shift.start_date + "T00:00:00");
            const endDate = this.shift.end_date
                ? new Date(this.shift.end_date + "T00:00:00")
                : now;
            return startDate.getDate() === endDate.getDate();
        },

        endIsNotRight() {
            return (
                !this.justOneDate 
                &&
                (
                    (this.end_date !== null && (this.end_time === null || this.end_time === "")) 
                        ||
                    (this.end_date === null && (this.end_time !== null && this.end_time !== ""))
                )
            );
        },

        startTimeIsInvalid() {
            return !this.start_time || !this.isValidTime(this.start_time);
        },

        commentIsInvalid() {
            return (
                this.comment === null ||
                this.comment.trim() === "" ||
                this.comment === undefined ||
                this.comment === "" ||
                this.comment?.length < 5
            );
        },

        commentLength() {
            return this.comment?.length ?? 0;
        },

        commentIsRequired() {
            return true; //this.activity_selected?.comment_required === 1;
        },

        availableStartHourRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(24).keys()];
            const endHour = Number(this.end_time.HH);
            return [...Array(endHour + 1).keys()];
        },

        availableStartMinuteRange() {
            if (!this.isValidTime(this.end_time)) return [...Array(60).keys()];

            const endHour = Number(this.end_time.HH);
            const endMinute = Number(this.end_time.mm);

            const startHour = this.isValidTime(this.start_time)
                ? Number(this.start_time.HH)
                : null;

            if (startHour === endHour) {
                return [...Array(endMinute + 1).keys()];
            }

            return [...Array(60).keys()];
        },

        availableEndHourRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(24).keys()];

            const startHour = Number(this.start_time.HH);
            return [...Array(24).keys()].filter((hour) => hour >= startHour);
        },

        availableEndMinuteRange() {
            if (!this.isValidTime(this.start_time))
                return [...Array(60).keys()];

            const startHour = Number(this.start_time.HH);
            const startMinute = Number(this.start_time.mm);

            const endHour = this.isValidTime(this.end_time)
                ? Number(this.end_time.HH)
                : null;

            if (startHour === endHour) {
                return [...Array(60).keys()].filter(
                    (minute) => minute >= startMinute
                );
            }

            return [...Array(60).keys()];
        },
    },
    methods: {
        ...mapActions(["fetchEventsByLob", "createClockerHours"]),
        isOngoing(status) {
            return status === null;
        },

        endTime(end_time) {
            return end_time === null ? "on-going" : end_time;
        },

        isValidTime(time) {
            return (
                time &&
                time.HH !== undefined &&
                time.mm !== undefined &&
                !isNaN(Number(time.HH)) &&
                !isNaN(Number(time.mm))
            );
        },

        timeHelper(time) {
            return time === "" ? "00" : time;
        },

        createEvent() {
            this.loading = true;
            this.createClockerHours({
                shift_id: this.shift.id,
                emp_id: this.shift.emp_id,
                event: this.activity_selected.id,
                start_time: this.start_time
                    ? this.start_time.HH +
                      ":" +
                      this.timeHelper(this.start_time.mm)
                    : this.start_time,
                end_time: this.end_time
                    ? this.end_time.HH + ":" + this.timeHelper(this.end_time.mm)
                    : this.end_time,
                start_date: this.start_date,
                end_date: this.end_time === "" ? null : this.end_date,
                comment: this.comment,
            })
                .then(() => {
                    this.$fire({
                        type: "success",
                        title: "Success",
                        text: "Event successfully created!",
                        showConfirmButton: true,
                    }).then(() => {
                        this.$emit("close");
                    });
                })
                .catch((error) => {
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        disabledBeforeStartAndAfterEnd(date) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const startDate = new Date(this.shift.start_date + "T00:00:00");
            const endDate = this.shift.end_date
                ? new Date(this.shift.end_date + "T00:00:00")
                : now;
            return date < startDate || date > endDate;
        },
    },
    created() {
        this.fetchEventsByLob(this.shift.lob_id).then(() => {
            this.loading = false;
        });
    },
};
</script>