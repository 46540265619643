import axios from '@/config/axios'


const state = {
    positions_list:[],
    status:'default',
    currentPosition:{}
};
const getters = {
    getPositions: (state) => state.positions_list, 
    getPositionStatus:(state) => state.status,
    getCurrentPosition: (state) => state.currentPosition   
};
const actions = {
    async savePosition ({commit}, formData){       
        commit('setStatus','saving'); 
        await axios.post('api/v2/open-positions/new',formData).then((response)=>{
            commit('setPositions',response.data.positions);
        }).then(()=>{
            commit('setStatus','saved');
        });
    },
    async fetchPositions ({commit}, formData){       
        commit('setStatus','loading'); 
        await axios.post('api/v2/open-positions',formData).then((response)=>{
            commit('setPositions',response.data.positions);
        }).then(()=>{
            commit('setStatus','loaded');
        }).catch((error)=>{
            commit('setStatus','error');

            return error;
        });
    },
    async deletePosition ({commit}, id){       
        await axios.post(`api/v2/open-positions/${id}/delete`).then((response)=>{
            commit('setPositions',response.data.positions);
        });
    },
    async fetchPositionById ({commit}, id){       
        commit('setStatus','loading'); 
        await axios.get(`api/v2/open-positions/${id}`).then((response)=>{
            commit('setCurrentPosition',response.data.position);
        }).then(()=>{
            commit('setStatus','loaded');
        });
    },
    async updatePosition ({commit}, formData){       
        commit('setStatus','saving'); 
        await axios.post(`api/v2/open-positions/${formData.id}/update`,formData).then((response)=>{
            commit('setPositions',response.data.positions);
        }).then(()=>{
            commit('setStatus','saved');
        });
    },
};
const mutations = {
    setPositions: (state, positions) => (state.positions_list = positions),
    setCurrentPosition: (state, position) =>(state.currentPosition = position),
    setStatus:(state, status) => (state.status = status)
};

export default {
    state,
    getters,
    actions,
    mutations
};