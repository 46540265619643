<template>
  <div class="flex flex-row flex-wrap px-15 py-4 align-items-start justify-content-center">
    <!--
    <simple-card title="Agent Clocker Training" link="course.clocker" image="clocker">
      Watch this video with information about how to use Clocker
      <template slot="button_text">
      Go to video
      </template>
    </simple-card>
    -->
    <simple-card title="COD Training" image="feedback-course" link="course.cod">
      Check the training video for the use of the Feedback module
    </simple-card>

    <simple-card title="Internal Spam" image="spam-cover" link="course.spam">
      Start analyzing your emails and discover if they could be consider as "spam" 
      <template slot="button_text">
      Go to presentation
      </template> 
    </simple-card>

    <div class="flex flex-row" v-if="itemsContains('SystemAdministrator') || itemsContains('Supervisor') || itemsContains('AccountManager')">
    <simple-card title="Supervisor hours review training" link="course.supervisor.hoursreview" image="supervisorHoursReview" v-if="itemsContains('SystemAdministrator') || itemsContains('Supervisor')">
      Watch this video with information about how to review your hours
      <template slot="button_text">
         Go to video
      </template>
    </simple-card>

    <simple-card title="Account Manager hours review training" link="course.am.hoursreview" image="supervisorHoursReview" v-if="itemsContains('AccountManager') || itemsContains('Supervisor')">
      Watch this video with information about how to review your hours
      <template slot="button_text">
         Go to video
      </template>
    </simple-card>
    </div>

    <simple-card title="Hours review training" link="course.agent.hoursreview" image="supervisorHoursReview">
      Watch this video with information about how to review your hours
      <template slot="button_text">
        Go to video
      </template>
    </simple-card>

    <simple-card v-if="validLocation('MTY')" title="Creative Organizational Policy" link="course.creative.org" image="org_creative2">
      Take a look at our organizations policies
      <template slot="button_text">
        Go to video
      </template>
    </simple-card>
    <simple-card v-if="validLocation('AGS|QRO')" title="Core Organizational Policy" link="course.core.org" image="org_creative2">
      Take a look at our organizations policies
      <template slot="button_text">
        Go to video
      </template>
    </simple-card>
    <simple-card title="EMS" link="course.emsreview" image="ems">
      Watch this video with information about EMS.
      <template slot="button_text">
        Go to video
      </template>
    </simple-card>
    <simple-card title="Ticketing System" link="course.ticketingsystem" image="supervisorHoursReview">
      Check this video to know how the ticketing system can help you report issues.
      <template slot="button_text">
        Go to video
      </template>
    </simple-card>
  </div>
</template>
<script>
import SimpleCard from "@/views/Utilities/SimpleCard";
import auth       from "@/config/user"

export default {
  name: "",
  components: {
    SimpleCard
  },
  methods:{
    getPermissions: function(){
      this.CurrentPermissions = auth.currentUser.roles;
    },
    itemsContains(n) {
      return this.CurrentPermissions.indexOf(n) > -1
    }
  },
  data(){
    return {
      title:'Courses',
      CurrentPermissions:[],
    }
  },
  computed:{
    fromMonterrey:function(){
      return this.location.includes('MTY') > 0 || this.itemsContains('SystemAdministrator');
    },

    fromAgs:function(){
      return this.location.includes('AGS') > 0 || this.itemsContains('SystemAdministrator');
    }

  },
  mounted(){
    this.$emit('set-title',this.title)
    this.getPermissions()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
