<template>

    <div v-if="isLoading">
        <div class="flex h-full w-full text-center justify-content-center align-items-center">
            <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
        </div>
    </div>

    <div v-else>

        <div class="d-block text-center">
            <h2 class="font-sans text-4xl tracking-wide font-bold text-gray-700 mt-2">Close Ticket {{this.ticketId}}</h2>
        </div>

        <div class=" mt-2 md:mt-0 md:col-span-6">

            <form @submit.prevent="closeThisTicket">
                <div class="bg-gray-50 px-4 py-4" >
                    <label for="selectPriority" class="text-gray-600 font-semibold text-lg pr-1">Select a reason to close this ticket:</label>
                    <select id="selectPriority" v-model="cancelReason" @change="dirty = false"
                    class="pl-1 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 mb-1">
                        <option hidden value="null">Select a Reason</option>
                        <option v-for="item in getCloseTicketReasons" :key="item.Id" :value="item.Reason" :id="item.Id">{{item.Reason}}</option>
                    </select>
                </div>
                
                <div class="px-4 py-2 text-right md:px-6">
                    <button type="submit"
                    :disabled="this.dirty || this.sending"
                    class="uppercase inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600" 
                    :class="{ 'cursor-default': this.sending || this.dirty, 'bg-blue-600 hover:bg-blue-700' : !this.sending && !this.dirty }">
                    {{ this.sending ? 'Sending...' : 'Send' }}
                    </button>
                </div>
            </form>

        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
    export default {
        name: "EditTicket",
        data(){
            return{
                dirty:          true,
                sending:        false,
                isLoading:      true,
                cancelReason:   null,
            }
        },
        computed: {
            ...mapState(['tickets']),
            ...mapGetters([
                'getTicketInfo',
                'getCloseTicketReasons',
            ]),
        },
        props:{
          ticketId: Number,
        },
        methods:{
            ...mapActions([
                'fetchCloseTicketReasons',
                'fetchThisTicketInfo',
                'changeStatus'
            ]),

            closeThisTicket(){
                this.sending = true;
                let objData = {
                    ticketSelected:   this.ticketId,
                    reasonSelected:   this.cancelReason
                }
                // console.log(objData);
                this.changeStatus(objData).then(()=>{
                    this.$fire({
                        type: "success",
                        title: "Ticket Closed",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(()=>{
                        this.$emit('closeModal', true);                
                    }); 
                }).catch(()=>{
                    this.$fire({
                        type: 'warning',
                        title:'Oops! Something went wrong',
                        text: 'Seems a child ticket is still open, please wait until all of them are closed',
                        showConfirmButton: true
                    });
                    this.sending = false;                   
                })
            }          
    },
        created() {
            this.fetchCloseTicketReasons().then(()=>{
                this.isLoading = false;
            });
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
    ::placeholder {
        text-transform: capitalize;
    }
</style>