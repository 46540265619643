<template>
<div class="main">

<b-container fluid="sm mt-3">
  <b-row>
    <b-col md="12" lg="12" xl="12" class="mx-auto">
      <div class="mb-4 overflow-hidden rounded-lg shadow-lg rounded-sm border border-white">
        <div class="rounded overflow-hidden">
          <slot name="video"></slot>
        </div>
      </div>
      <div class="my-3 text-left px-3">
        <a class="relative left-0 px-2 py-2 text-center rounded-sm bg-white text-muted shadow-sm" style="cursor:pointer"
          :href="backLink"
        >
           <span class="material-icons">
              subdirectory_arrow_left
           </span>
           Go back
        </a>
      </div>
    </b-col>
    <b-col md="8" sm="12" class="mx-auto">
      <div class="pl-4 mb-4">
        <h1 class="text-center h-2 font-base">{{caption}}</h1>
      </div>
    </b-col>
  </b-row>

</b-container>

  <slot></slot>

</div>
</template>

<script>
  export default {
    name:'Courses',
    data(){
      return {}
    },
    props:{
      caption:{
        default:'Dummy title',
        type:String
      },
      backLink:{
        type:String,
        default:'/employees/resources'
      }
    }
  }
</script>