<template>
<div class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="row text-center justify-center items-center mt-2">
                    <button v-b-modal="'CreateSurvey'" 
                    class="uppercase inline-flex justify-center py-1 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <div class="uppercase font-sans font-bold text-white text-center justify-center items-center m-1">Create a new survey</div>
                    </button>

                    <b-modal size="lg" ref="AddNewSurvey" :id="'CreateSurvey'" hide-footer centered no-close-on-backdrop>
                        <addsurveymodal @closeAddModal='closeModal'></addsurveymodal>
                    </b-modal>
                </div>

                <component :key="ListKey" @updateAll="ForceRender" :is="'surveytable'" class="text-center justify-center items-center mt-2 w-full"></component>

            </div>
        </div>
    </div>
</div>
</template>
<script>
    import surveytable        from'@/views/components/Management/surveys/tables/survey_table';
    import addsurveymodal     from'@/views/components/Management/surveys/modals/create_survey';
    import {mapGetters,mapState, mapActions } from 'vuex'
    export default {
        components: { 
            surveytable,
            addsurveymodal 
        },
        data(){
            return{
                ListKey: 0
            }
        },
        computed: {
            ...mapState([
            'employees'
            ]),
            ...mapGetters([
                ])
        },
        created(){
        },
        methods: {
            ...mapActions([
                ]),

            closeModal: function(){
            this.$refs['AddNewSurvey'].hide()
            this.ListKey++;
            },

            ForceRender: function(){
                this.ListKey++;
            }
        },
      mounted(){
        let title = 'Survey Management';
        this.$emit('set-title',title);
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>