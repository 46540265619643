<template>
<div>
   <youtube :video-id="videoId" ref="youtube" :mute="isMuted" @ready="onReady" :player-vars="playerVars"></youtube>
</div>
</template>

<script>
export default {
    data(){
        return{
            videoId:'CM_A4f9qqzo',
             playerVars: {
                autoplay: 1,
                 mute:true
             },
            
        }
    },
    methods:{
        onReady(){
             this.$refs.youtube.player.playVideo()
        },
       
    },
    computed:{
        isMuted:function(){ return this.muted;}
    }
}
</script>

<style>

</style>