<template>
    <b-container v-if="this.showTable" class="py-3 rounded-md">
      <table class="divide-y divide-gray-200 border-b border-gray-200 w-full shadow-md">
        <thead class="bg-gray-50">
          <tr>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">ID</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Title</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Created by</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Created at</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Status</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col"></th>
          </tr>
        </thead>
        <tbody v-if="getMyFeedbacks.length" class="bg-white divide-y divide-gray-200">
          <tr :id="feedback.id" v-for="feedback in getMyFeedbacks" :key="feedback.id">
              <td class="p-2">{{feedback.id}}</td>
              <td class="p-2">{{feedback.feedbackName}}</td>
              <td class="p-2">{{feedback.createdBy}}</td>
              <td class="p-2">{{feedback.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
              <td class="p-2 mt-1 flex flex-auto align-items-center justify-content-center">
                    <div v-if="!feedback.isActive" class="align-items-center justify-content-center rounded-full h-4 w-4 text-white bg-gradient-to-r from-red-700 to-red-300"></div>
                    <div v-else class="align-items-center justify-content-center rounded-full h-4 w-4 text-white bg-gradient-to-r from-green-700 to-green-300"></div>
              </td>
              <td class="p-2 align-items-center justify-content-center">
                <vs-tooltip class="align-items-center justify-content-center flex" v-if="!feedback.isActive" text="Enable Feedback"><svg @click="openThis(feedback.id, feedback.feedbackName)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" style="cursor:pointer"></path></svg></vs-tooltip>
                <vs-tooltip class="align-items-center justify-content-center flex" v-else text="Disable Feedback"><svg @click="closeThis(feedback.id, feedback.feedbackName)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" style="cursor:pointer"></path></svg></vs-tooltip>
              </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="9">No records found.</td>
          </tr>
        </tbody>
      </table>
    </b-container>

    <div v-else class="text-center py-5">
      <p class="font-italic font-weight-bold">Loading...</p>
      <p class="font-italic font-weight-bold">
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </p>              
        
    </div>
</template>
<script>

  import {mapGetters,mapState, mapActions } from 'vuex'
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        showTable: false,
      }
    },
    components: {
    },
    computed:{
      ...mapState(['feedbacks']),
      ...mapGetters(['getMyFeedbacks'])
    },
    created(){
      this.$store.dispatch('fetchFeedbacks').then(()=>{
        this.showTable = true;
      })
    },
    methods:{
      ...mapActions(['fetchFeedbacks',
      'changeFActive']),

      closeThis(data, feedbackName){
        this.$confirm("It won't be shown at the Profile's Feedback section", "Do you want to disable the "+feedbackName+" feedback?", "question").then((result) => {
            if (result){
                let objData = { surveySelected: data }
                this.changeFActive(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Feedback Disabled",
                      showConfirmButton: false,
                      timer: 2300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
        })
      },

      openThis(data, feedbackName){
        this.$confirm("It will be shown at the Profile's Feedback section", "Do you want to enable the "+feedbackName+" feedback?", "question").then((result) => {
            if (result){
                let objData = { surveySelected: data }
                this.changeFActive(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Feedback Enabled",
                      showConfirmButton: false,
                      timer: 1300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
        })
      }

    },
  }

</script>
<style lang="scss" scoped>
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>