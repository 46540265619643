<template>
<div>
    <button v-if="hasNoData" @click="emitValue" class="pending">
       {{status}}
    </button>
    
    <div v-else>
    <span v-if="isEmpty" >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
        </svg>
    </span>

  
    <div v-else>
         <a href="/detailedDay" @click.prevent.stop ="navigateToPreview" v-if="value > 0" :class="textColor" target="_blank">
            {{value}}
        </a>
       <a href="/detailedDay" @click.prevent.stop ="navigateToPreview" v-else :class="textColor" target="_blank">
            {{status}}
       </a>
    </div>
    </div>
</div>
</template>
<!--
1.- Calcular la fecha  actual o de la semana pasada basado en si el flag previous viene como true.



-->
<script>
export default {
    data(){
        return {
            startOfWeek:null,
            weekDayNumbers:{
                sunday:0,
                monday:1,
                tuesday:2,
                wednesday:3,
                thursday:4,
                friday:5,
                saturday:6,
            },
            requiredDate:null,
            actionPendingTexts:[//Aqui se agregaran los status que requieren al usuario realizar una accion.Deben ser minusculas para funcionar.
                'no-data','pending','in-revision'
            ],
            currentDate:null,
        }
    },
    props:{
        val:{
            type:Object,
        },
        prev:{
            default:false,
            type:Boolean
        },
        day:{
            type:String,
            
        },
        id:{
            type:String
        }
    },
    computed:{
         value:function(){
            return parseFloat(this.val.value).toFixed(2);
        },
        status:function(){
            return this.val.status;
        },
        isNumeric:function(){
            if(this.isEmpty) return false;

            return !isNaN(this.value);
        },
        isEmpty:function(){
            return this.val?.empty;
        },
        isApproved:function(){
            return this.status == 'approved' || this.status == 'DayOff';
        },
        isPending:function(){
            let list =[
                'Validation',
                'In_revision',
                'Pending',
                'No-Data'
            ];
            return list.find(item => item == this.status) ;
        },

        hasNoData:function(){
            const modalizableEvents=[
                'no-data','unjustified abscence','dayoff'
            ]; 
            return modalizableEvents.filter(item => item.toLowerCase() == this.status?.toLowerCase()).length > 0;
        },
        textColor:function(){
        
            if(this.isApproved) return 'done';

            if(this.isPending) return 'pending';

            return 'default';
        },
       
        
    },
    methods:{
        resolveStartOfWeek:function() {
            let baseDate = this.$moment();
            if(this.prev){
                baseDate.subtract(7, 'd');
            }
            this.startOfWeek = baseDate.startOf('week');
          
        },
        resolveRequiredDay:function(day){
            let targetDays = this.weekDayNumbers[day.toLowerCase()  ] ?? 0;
             
            if(targetDays == 0) return this.requiredDate = this.startOfWeek.format('Y-MM-DD');

            return this.requiredDate = this.startOfWeek.add(targetDays,'d').format('Y-MM-DD');

        },
        navigateToPreview:function(){
            this.$emit('navigate',this.requiredDate);
        },
        emitValue:function(){
            this.$emit('edit',{id:this.id,day:this.day});
        }
    },
    created(){
    
       this.resolveStartOfWeek();
       this.resolveRequiredDay(this.day);
       this.currentDate = this.$moment();
    }
}
</script>

<style scoped>
    .done{ 
        color:#8bc34a !important;
    }

    .default{
        color:#616161 !important;
    }

    .pending{
        color:#ff3d00 !important;
    }
</style>