<template>
    <div class="bg-white w-1/2 mx-auto p-3 py-5 rounded-lg shadow-md">
        <form v-if="showCard" class="font-semibold flex flex-col" @submit="addItem">
            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="site">Site Location:</label>
                <div class="inline relative">
                    <select name="site" id="site" v-model="site"
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': (submitted && $v.site.$error ) && ( site === null ),
                    'border-blue-300': !((submitted && $v.site.$error) && ( site === null )) }">
                        <option hidden value="null">Select a Site...</option>
                        <option v-for="item in getLocations" :value="item" :key="item">{{item}}</option>
                    </select>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                    <span v-if="submitted && !$v.site.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="serial_number">Serial Number:</label>
                <div class="inline relative">
                    <input name="serial_number" id="serial_number" v-model="serial_number" pattern="[A-Za-z0-9]+" maxlength="32" placeholder="Serial Number..."
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': (submitted && $v.serial_number.$error) && ( serial_number === null ),
                    'border-blue-300': !((submitted && $v.serial_number.$error) && ( serial_number === null )) }"/>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                    <span v-if="!$v.serial_number.minLength"
                    class="font-light text-sm text-red-700">
                        Password must have at least {{ $v.serial_number.$params.minLength.min }} alphanumeric characters
                    </span>
                    <span v-if="submitted && !$v.serial_number.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="confirm_serial_number">Confirm Serial Number:</label>
                <div class="inline relative">
                    <input name="confirm_serial_number" id="confirm_serial_number" v-model="confirm_serial_number" pattern="[A-Za-z0-9]+" maxlength="32" onpaste="return false;" ondrop="return false;" autocomplete="off" placeholder="Serial Number..."
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': 
                    ((submitted & (confirm_serial_number === null || confirm_serial_number === '')) || 
                    (!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN) || (!$v.confirm_serial_number.minLength)),
                    'border-blue-300': 
                    !((submitted & (confirm_serial_number === null || confirm_serial_number === '')) || 
                    (!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN) || (!$v.confirm_serial_number.minLength)) }"/>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                    <span v-if="!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN" 
                    class="text-sm font-light flex flex-row text-red-700">
                        Serial numbers does not match!
                    </span>
                    <span v-if="!$v.confirm_serial_number.minLength"
                    class="font-light text-sm text-red-700">
                        Password must have at least {{ $v.confirm_serial_number.$params.minLength.min }} alphanumeric characters
                    </span>
                    <span v-if="submitted & (confirm_serial_number === null || confirm_serial_number === '')" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="use_type">Use Location:</label>
                <div class="inline relative">
                    <select name="use_type" id="use_type" v-model="use_type"
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': (submitted && $v.use_type.$error) && ( use_type === null ),
                    'border-blue-300': !((submitted && $v.use_type.$error) && ( use_type === null )) }">
                        <option value="on-site">On-Site</option>
                        <option value="off-site">Off-Site</option>
                    </select>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> Specify if this equipment will be used outside or within Centris facilities</span>
                    <span v-if="submitted && !$v.use_type.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="use">Use:</label>
                <div class="inline relative">
                    <select name="use" id="use" v-model="use"
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': (submitted && $v.use.$error) && ( use === null ),
                    'border-blue-300': !((submitted && $v.use.$error) && ( use === null )) }">
                        <option hidden value="null">Select a Use...</option>
                        <optgroup label="Department">
                            <option v-for="item in catalog_departments" :key="item" :value="item">{{item}}</option>
                        </optgroup>
                        <optgroup label="Campaign">
                            <option v-for="item in catalog_campaigns" :key="item" :value="item">{{item}}</option>
                        </optgroup>
                        <optgroup  label="Warehouse">
                            <option value="warehouse">Warehouse</option>
                        </optgroup>
                        <!-- <optgroup  label="Other">
                            <option value="benefactor">Benefactor</option>
                        </optgroup> -->
                    </select>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                    <span v-if="submitted && !$v.use.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="manufacturer">Manufacturer:</label>
                <div class="inline relative">
                    <select name="manufacturer" id="manufacturer" v-model="manufacturer"
                    @change="refreshModels()"
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    :class="{'border-red-600': (submitted && $v.manufacturer.$error) && ( manufacturer === null ),
                    'border-blue-300': !((submitted && $v.manufacturer.$error) && ( manufacturer === null )) }">
                        <option hidden value="null">Select a Manufacturer...</option>
                        <option v-for="item in catalog_manufacturers" :key="item.Manufacturer_ID" :value="item.Manufacturer_ID">{{item.Manufacturer_Label}}</option>
                        <option value="newManuf">New Manufacturer</option>
                    </select>
                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                    <span v-if="submitted && !$v.manufacturer.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                </div>
            </div>

            <div v-if="manufacturer === 'newManuf'">
                <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                    <label for="new_manufacturer">New Manufacturer:</label>
                    <div class="inline relative">
                        <input name="new_manufacturer" id="new_manufacturer" v-model="new_manufacturer" placeholder="Write Here..."
                        class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                        :class="{'border-red-600': (submitted && manufacturer === 'newManuf' && $v.new_manufacturer.$error) && ( new_manufacturer === null ),
                        'border-blue-300': !((submitted && manufacturer === 'newManuf' && $v.new_manufacturer.$error) && ( new_manufacturer === null )) }"/> 
                        <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                        <span v-if="submitted && !$v.new_manufacturer.required && manufacturer === 'newManuf'" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                    </div>
                </div>

                <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                    <label for="model">New Model:</label>
                    <div class="inline relative">
                        <input name="new_model" id="new_model" v-model="new_model" placeholder="Write Here..."
                        class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                        :class="{'border-red-600': (submitted && manufacturer === 'newManuf' && $v.new_model.$error) && ( new_model === null ),
                        'border-blue-300': !((submitted && manufacturer === 'newManuf' && $v.new_model.$error) && ( new_model === null )) }"/>
                        <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                        <span v-if="submitted && !$v.new_model.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                    <label for="model">Model:</label>
                    <div class="inline relative">
                        <select name="model" id="model" v-model="model"
                        :disabled="manufacturer === null || catalog_models === []"
                        class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                        :class="{'border-red-600': (submitted && $v.model.$error) && ( model === null ),
                        'border-blue-300': !((submitted && $v.model.$error) && ( model === null )) }">
                            <option v-if="manufacturer === null || catalog_models === []" hidden value="null">Select a Manufacturer First</option>
                            <option v-else hidden value="null">Select a model...</option>
                            <option v-for="item in catalog_models" :key="item.Model_ID" :value="item.Model_ID">{{item.Model_Label}}</option>
                            <option value="newModl">New Model</option>
                        </select>
                        <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                        <span v-if="submitted && !$v.model.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                    </div>
                </div>

                <div v-if="model === 'newModl'">
                    <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                        <label for="model">Model:</label>
                        <div class="inline relative">
                            <input name="new_model" id="new_model" v-model="new_model" placeholder="Write Here..."
                            class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                            :class="{'border-red-600': (submitted && model === 'newModl' && $v.new_model.$error) && ( new_model === null ),
                            'border-blue-300': !((submitted && model === 'newModl' && $v.new_model.$error) && ( new_model === null )) }"/>
                            <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                            <span v-if="submitted && !$v.new_model.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                        <label for="category">Category:</label>
                        <div class="inline relative">
                            <select name="category" id="category" v-model="category"
                            :disabled="catalog_types === []"
                            class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                            :class="{'border-red-600': (submitted && $v.category.$error) && ( category === null ),
                            'border-blue-300': !((submitted && $v.category.$error) && ( category === null )) }">
                                <option hidden value="null">Select a Category...</option>
                                <option v-for="item in catalog_types" :key="item.Item_Type_ID" :value="item.Item_Type_ID">{{item.Item_Type_Label}}</option>
                            </select>
                            <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                            <span v-if="submitted && !$v.category.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="flex flex-col w-11/12 text-left justify mx-auto mb-3">
                <label for="label">Label:</label>
                <div class="inline relative">
                    <input name="label" id="label" v-model="label" placeholder="Write Here..."
                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                    />    
                </div>
            </div>
            
            
            <div>
                <button type="submit"
                :disabled="loading"
                class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2">
                    <span v-if="loading">
                        Saving  
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                        </svg>
                    </span>
                    <span v-else>
                        Add and Clean
                    </span>
                </button>
            </div>
        </form>
        <div v-else class="flex justify-content-center flex-col my-auto">
            We are loading the required information. Please wait
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
            </svg>
        </div>
    </div>
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
       return{
           showCard: false,
           submitted: false,
           loading: false,
           site: null,
           serial_number: null,
           confirm_serial_number: null,
           use_type: 'on-site',
           use: null,
           manufacturer: null,
           model: null,
           category: null,
           label: null,

           new_manufacturer: null,
           new_model: null,

           catalog_manufacturers: [],
           catalog_models: [],
           catalog_types: [],
           catalog_department: [],
           catalog_campaign: []
       } 
    },
    validations() {
        if(this.manufacturer === 'newManuf'){
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4)},
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                new_manufacturer: { required },
                new_model:      { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
        }if (this.model === 'newModl') {
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4) },
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                model:          { required },
                new_model:      { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
            
        }else {
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4) },
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                model:          { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
        }
  },
  methods: {
      ...mapActions(['fetchNewItemProperties', 'fetchSites', 'addNewItemToInventory']),
        addItem(){
        event.preventDefault();
        this.submitted = true;

        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        let addingType = null;
        if(this.new_manufacturer === null && this.new_model === null){
            addingType = 'normal'
        }else if(this.new_manufacturer === null && this.new_model !== null){
            addingType = 'new_model'
        }else{
            addingType = 'new_manufacturer'
        }
        this.loading = true;
        let Obj = {
            site: this.site,
            serial_number: this.serial_number?.toUpperCase(),
            use_type: this.use_type,
            use: this.use,
            manufacturer: this.manufacturer,
            model: this.model,
            type: this.category,
            label: this.label === null ? this.serial_number : this.label,
            new_manufacturer: this.new_manufacturer,
            new_model: this.new_model,
            addingType: addingType
        };
        this.addNewItemToInventory(Obj).then(()=>{
            this.$fire({
                type:'success',
                title:'New item added.',
                showConfirmButton:false,
                timer: 1500
            });
            this.clearVariables();
        })
        .catch((error)=>{
            let message =  error.response.data.message ?? 'Something went wrong';
            let indexEnd = message.indexOf('(SQL:');
            let substringed = message.substring(0,indexEnd);
            this.$fire({
                type: 'warning',
                title: 'Something went wrong',
                text:`${substringed}`,
                showConfirmButton: true,                
            });    
        })
        },
        refreshModels(){
            this.type = null;
            this.new_type = null;
            this.model = null;
            this.new_model = null;
            this.new_manufacturer = null;
            this.catalog_models = this.getNewItemsProperties.models.filter(model => model.Manufacturer_ID === this.manufacturer);
        },
        clearVariables(){
            this.submitted = false;
            this.loading = false;
            this.site = null;
            this.serial_number = null;
            this.confirm_serial_number = null;
            this.use_type = 'on-site';
            this.use = null;
            this.manufacturer = null;
            this.model = null;
            this.label = null;
            this.new_manufacturer = null;
            this.new_model = null;
            this.category = null;
        }
    },
    created(){
        this.fetchSites();
        this.fetchNewItemProperties().then(() => {
            this.catalog_manufacturers = this.getNewItemsProperties.manufacturers;
            this.catalog_types = this.getNewItemsProperties.types;
            this.catalog_campaigns = this.getNewItemsProperties.campaigns;
            this.catalog_departments = this.getNewItemsProperties.departments;
            this.showCard = true;
        })
    },
    watch: {
        serial_number(value) {
            if (this.serial_number === null) return;
            this.serial_number = value?.toUpperCase().replace(/\s/gi && /[^a-zA-Z0-9]+/gi, '');
        },
        confirm_serial_number(value) {
            if (this.confirm_serial_number === null) return;
            this.confirm_serial_number = value?.toUpperCase().replace(/\s/gi && /[^a-zA-Z0-9]+/gi, '');
        }
    },
    computed:{
        ...mapGetters(['getNewItemsProperties', 'getLocations',]),
    },
}
</script>
<style scoped>
#serial_number, #confirm_serial_number { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>