<template>
  <div class="main py-3">
    <div class="sticky-top">
    </div>
    <loading :active.sync="getFlagLoadingSuggestion" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <b-container fluid>
        <b-row class="mt-3">
            <b-col>
                <button class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded suggestion-button" v-b-modal="'sendSuggestion'" icon="send"><span class="material-icons mr-2">send</span> Crear Nuevo</button>
                <div class="relative w-full h-4"></div>
                <b-modal ref="sendNewSuggestion"  id="sendSuggestion" hide-footer title="Buzón de Sugerencias y Quejas">
                    <div>
                    <SendSuggestion @toggle="closeSuggestionModal"
                    :profileInfo="infoEmployee"></SendSuggestion>
                    </div>
                </b-modal>
            </b-col>              
        </b-row>   
    </b-container>

    <div class="container-fluid">
        <div class="row margins">
            <div class="col-6">
               <div class="row">
                   <div class="col-12">
                       <MySuggestionList @suggestion_selected='loadSuggestion'></MySuggestionList>
                   </div>
               </div>
            </div>

            <div class="col-6">
                <div class="row" v-if="suggestionLoaded == true">
                    <div class="col-12 overflow-auto mb-2">
                        <div class="card mb-3" v-for="suggestion in getSuggestion"  v-bind:key="suggestion.id">
                            <h5 class="card-header">Número de Reporte: {{suggestion.id}} </h5>

                            <div class="card-body overflow-auto">
                                <p class="text-left card-text"><span class="font-weight-bold">Empleado: </span>{{suggestion.EmpID}}</p>
                                <p v-if="suggestion.Sugg_Type == 'Complain'" class="text-left card-text"><b>Clasificación: </b>Queja</p>
                                <p v-if="suggestion.Sugg_Type == 'Suggestion'" class="text-left card-text"><b>Clasificación: </b>Sugerencia</p>
                                <!-- <p class="text-left card-text"><b>Clasificación: </b>{{suggestion.Sugg_Type}}</p> -->
                                <!-- <p v-if="suggestion.Sugg_At == null" class="text-left card-text"><b>Who: </b>  N/A</p>
                                <p v-else class="text-left card-text"><b>Who: </b>  {{suggestion.Sugg_At}}</p> -->
                                <div class="form-group">
                                    <label><b>Descripción</b></label>
                                    <p>{{suggestion.Description}}</p>
                                </div>
                            </div>
                            <div class="card-footer">
                            <small v-if="suggestion.viewed_at != null && suggestion.updated_at != null" class="text-muted"><i>Resuelto el:  {{suggestion.updated_at | moment('YYYY/MM/DD, h:mm:ss a')}}</i></small>
                            <small v-if="suggestion.viewed_at != null && suggestion.updated_at == null" class="text-muted"><i>En Revisión desde: {{suggestion.viewed_at | moment('YYYY/MM/DD, h:mm:ss a')}}</i></small>
                            <small v-if="suggestion.viewed_at == null && suggestion.updated_at == null" class="text-muted"><i>Abierto</i></small>
                            </div>
                        </div>

                        <div class="mb-4">
                            <span class="text-left font-weight-bold"><a class="text-danger">*</a>Comentarios: </span>
                            <div v-if="this.commentsLoaded == null">
                                <i>Su mensaje será respondido en brevedad.</i>
                            </div>
                            <div v-else>
                                <div class="p-3 mb-2 bg-light text-dark" >{{this.commentsLoaded}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  </div>
</template>
<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState, mapGetters, mapActions}   from 'vuex';
    import Loading                              from 'vue-loading-overlay';
    import MySuggestionList                     from "@/views/components/Suggestion/my_suggestion_list"
    import { required }                         from 'vuelidate/lib/validators';
    import SendSuggestion                       from '@/views/components/Modal/send_suggestion'
    import auth                                 from "@/config/user"
    export default {
        name: "",
        components: { SendSuggestion, MySuggestionList, Loading },
        data(){
            return{
                fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isDisabled: true,
                isAcknowledge: false,

                suggestionLoaded: false,
                agentComment: '',
                commentsLoaded: '',
                audit_id: '',
                title:'Sugerencias y Quejas'
            }
        },
        validations: {
            agentComment: { required },
        }, 
        computed:{
            ...mapState(['feedbacks', 'employees']),
            ...mapGetters(['getMySuggestions','getSuggestion','getFlagLoadingSuggestion', 'infoEmployee']) 
        },
        created() {
            this.loadInfoEmployee();
        },
        methods:{
            ...mapActions(['fetchSuggestionByAuditID','addComments', 'loadMyProfileByEmpId']),

            loadSuggestion: function(data){
                this.suggestionLoaded = false;
                this.agentComment = '';
                this.audit_id = ''
                this.fetchSuggestionByAuditID(data).then(() => {
                    this.suggestionLoaded = true;
                    this.audit_id = data;
                    this.commentsLoaded = this.getSuggestion[0].agent_comment;
                }, error => {
                    console.error("Error fetching the suggestion: "+ error)
                });
                
            },

            loadInfoEmployee(){
                let idEmployee = auth.currentUser.EmpID;
                this.loadMyProfileByEmpId(idEmployee).then(() => {
                    this.hideProgress = true;
                }, error => {
                    console.error("Error fetching the projects: "+ error)
                });
            },

            closeSuggestionModal: function(){
                this.$refs['sendNewSuggestion'].hide()
                location.reload();
            }

        },
        mounted() {
          this.$emit('set-title',this.title);
        }
    };

</script>
<style>
    .margins{
        margin-top: 1em !important;
        margin-left: 16% !important; 
        margin-right: 16% !important;
    }
    .card-body {
        height: 450px !important;
    }
    .material-icons {
    display: inline-flex;
    vertical-align: top;
    align-items: center; 
    }
    .suggestion-button {
    font-size:16px;
    line-height:24px;
    display: inline-flex;
    vertical-align: top;
    }
</style>