<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID"  :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <label v-if="dropdownLabel" class="text-left text-xs ml-2 block group-focus:text-gray-600">{{dropdownLabel}}</label>
            <select :id="elementID" class="form-control" :class="classToAdd">
                <option :value="null" hidden>Choose an option...</option>
                <option class="cursor-pointer" v-for="(option, idx) in optionsToAdd"  :value="option.values" :key="'dp'+idx">{{option.label}}</option>
            </select>  
        </div>
    </div>
</template>
<script>
    export default { 
    props:{
        dropdownLabel:{
            type:       String,
            default:    null,
            required:   true,
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        classToAdd:{
            type:       String,
            default:    'form-control',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        },
        optionsToAdd:{
            type:       Object,
            required:   true,
        }
    },
}
</script>
