<template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class="bg-white px-2 hover:text-gray-600" v-model="selectedIncidence">  
          <optgroup label = "Select a Queue">
            <option hidden value="0">Choose a Queue</option>
            <option v-for="(item,index) in getCatalog" :key="index" :value="item.Id">{{item.IncidenceLabel}}</option>
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error: null,
            selectedIncidence : 0
        }
    },
    computed:{
        ...mapGetters(['getCatalog']),
    },
    created(){
       this.fetchIncidencesCatalog().then(()=>{
           this.error =false
        });
    },
    methods:{
        ...mapActions(['fetchIncidencesCatalog'])
    },
    watch:{
      selectedIncidence: Debounce(function(){
          this.$emit('toggle',{
              key:'incidenceId',
              value :this.selectedIncidence
          });
      },200)     

    }
}
</script>
