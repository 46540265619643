<template>
  <div class="m-auto w-9/12">
    <div v-if="isLoading" class="row">
      <div class="text-center col">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div v-else class="my-3 rounded-md bg-white p-3">
      <button class="mb-3 flex rounded-md bg-gray-700 p-2 text-white"
              @click.prevent="$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
             viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
        </svg>
      </button>
      <form @submit.prevent="editInfoSubmit()">
        <div class="flex w-full flex-col">
          <div class="ml-3 flex text-left font-sans font-semibold text-md">
            Fill out the form below
          </div>
          <div>
            <span class="pt-3 font-medium">Personal Information</span>
            
            <div class="grid grid-cols-2 gap-2">
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('firstName')">
                <input
                  type="text"
                  class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2
                                w-full "
                  v-model="form['firstName']"
                  placeholder="Write here..."
                />
                <span
                  class="text-xs text-gray-400 mr-auto absolute top-0 left-3
                                whitespace-nowrap "
                >
                  First Name
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('middleName')">
                <input
                  type="text"
                  class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2
                                w-full "
                  v-model="form['middleName']"
                  placeholder="Write here..."
                />
                <span
                  class="text-xs text-gray-400 mr-auto absolute top-0 left-3
                                whitespace-nowrap "
                >
                  Middle Name
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('firstLastName')">
                <input
                  type="text"
                  class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2
                                w-full "
                  v-model="form['firstLastName']"
                  placeholder="Write here..."
                />
                <span
                  class="text-xs text-gray-400 mr-auto absolute top-0 left-3
                                whitespace-nowrap "
                >
                  First Last Name
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('secondLastName')">
                <input
                  type="text"
                  class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2
                                w-full "
                  v-model="form['secondLastName']"
                  placeholder="Write here..."
                />
                <span
                  class="text-xs text-gray-400 mr-auto absolute top-0 left-3
                                whitespace-nowrap "
                >
                  Second Last Name
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('gender')">
                <edit-employee-dropdown
                  column="gender"
                  v-model="form['gender']"
                  placeholder="Select..."
                />
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Gender</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('dateOfBirth')">
                <input type="date"
                       class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                       v-model="form['dateOfBirth']"
                       placeholder="yyyy-mm-dd"/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Date of Birth</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('stateOfBirth')">
                <select
                  v-model="form['stateOfBirth']"
                  @change="loadCities(form['stateOfBirth'], 'birth')"
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                >
                  <option value="null" selected disabled>Choose a state...</option>
                  <option
                    v-for="state in stateList"
                    :value="state.stateName"
                    :key="state.stateName"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">State of Birth</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('cityOfBirth')">
                <div
                  v-if="showLoadingCity.birth"
                  class="w-full flex flex-row justify-center items-center"
                >
                  <loading/>
                  <span class="text-sm text-gray-400">
                    Loading cities...
                  </span>
                </div>
                <div v-if="selectState.birth" class="w-full h-[40px]">
                  <label class="text-center content-center size-full text-gray-400">
                    Select a state first
                  </label>
                </div>
                <div v-if="showSelectCity.birth" class="w-full">
                  <select
                    class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                    id="cityUser"
                    v-model="form['cityOfBirth']"
                  >
                    <option value="null" hidden>Choose a city...</option>
                    <option
                      v-for="city in cityOptions.birth"
                      :value="city.cityName"
                      :key="city.cityName"
                    >
                      {{ city.cityName }}
                    </option>
                  </select>
                </div>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">City of Birth</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('nationality')">
                <edit-employee-dropdown
                  column="nationality"
                  v-model="form['nationality']"
                  placeholder="Select..."
                />
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Nationality</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('curp')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['curp']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">CURP</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('rfc')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['rfc']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">RFC</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('ssn')">
                <input type="number"
                       class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                       v-model="form['ssn']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">SSN</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('umf')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['umf']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Imss Clinic</span>
              </div>
            </div>
          </div>
          <div>
            <div class="pt-3 font-medium">Contact Information</div>
            <div class="grid grid-cols-2 gap-2">
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('personalEmail')">
                <input type="email"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['personalEmail']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Personal Email</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('workEmail')">
                <input type="email"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['workEmail']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Work Email</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('phoneOne')">
                <the-mask
                  class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                  v-model="form['phoneOne']" placeholder="Write here..."
                  :mask="['(###) ###-####', '(###) ###-####']"/>
                <span
                  class="absolute top-0 left-3 mr-auto  text-gray-400 text-xs">Main Phone</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('phoneTwo')">
                <the-mask
                  class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                  v-model="form['phoneTwo']" placeholder="Write here..."
                  :mask="['(###) ###-####', '(###) ###-####']"/>
                <span
                  class="absolute top-0 left-3 mr-auto  text-gray-400 text-xs">
                                    Secondary Phone
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('emergencyContactName')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['emergencyContactName']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Emergency Contact Name
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('emergencyContactPhone')">
                <the-mask type="text"
                          class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                          v-model="form['emergencyContactPhone']"
                          placeholder="Write here..."
                          :mask="['(###) ###-####', '(###) ###-####']"/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Emergency Contact Phone Number</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('emergencyContactEmail')">
                <input type="text"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['emergencyContactEmail']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Emergency Contact Email</span>
              </div>
            </div>
          </div>
          <div>
            <div class="pt-3 font-medium">Other</div>
            
            <div class="grid grid-cols-2 gap-2">
              
              <div class="relative  px-3 pt-3 pb-1" :hidden="!is_visible('kids')">
                <input type="number"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['kids']" placeholder="Write here..."
                       min="0"/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Number of Children (Zero if None)
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('bilingual')">
                <select
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                  v-model="form['bilingual']">
                  <option value="null" hidden>Select...</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Is Bilingual</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1"
                   :hidden="!is_visible('maritalStatus')">
                <select
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                  v-model="form['maritalStatus']">
                  <option value="null" hidden>Select...</option>
                  <option value="SINGLE">Single</option>
                  <option value="MARRIED">Married</option>
                  <option value="DOMESTIC PARTNERSHIP">Domestic Partnership</option>
                  <option value="DIVORCED">Divorced</option>
                  <option value="SEPARATED">Separated</option>
                  <option value="WIDOWED">Widowed</option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Marital Status</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('education')">
                <select
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                  v-model="form['education']">
                  <option value="null" hidden>Select...</option>
                  <option value="ELEMENTARY">Elementary/Primaria</option>
                  <option value="MIDDLE SCHOOL">Middle School/Secundaria</option>
                  <option value="HIGH SCHOOL">High School/Preparatoria</option>
                  <option value="UNDERGRADUATE">Undergraduate/Licenciatura</option>
                  <option value="GRADUATE">Graduate/Maestria</option>
                  <option value="POSTGRADUATE">Postgraduate/Doctorado</option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Education</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('specialty')">
                <edit-employee-dropdown
                  column="specialty"
                  v-model="form['specialty']"
                  placeholder="Select..."
                />
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Specialty</span>
              </div>
              
              <div class="relative  px-3 pt-3 pb-1" :hidden="!is_visible('fatherName')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['fatherName']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Father's Name
                </span>
              </div>
              
              <div class="relative  px-3 pt-3 pb-1" :hidden="!is_visible('motherName')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['motherName']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Mother's Name
                </span>
              </div>
            </div>
          
          </div>
          <div>
            <div class="pt-3 font-medium">Payment</div>
            
            <div class="grid grid-cols-2 gap-2">
              <div class="relative  px-3 pt-3 pb-1"
                   :hidden="!is_visible('paymentMethod')">
                <edit-employee-dropdown
                  column="paymentMethod"
                  v-model="form['paymentMethod']"
                  placeholder="Select..."
                />
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Payment Method
                </span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('sdi')">
                <input type="number"
                       step="0.01"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['sdi']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">SDI</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('sd')">
                <input type="number"
                       step="0.01"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['sd']" placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">SD</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('eligibleForLoan')">
                <select
                  @change="form['requestedLoan'] = null"
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                  v-model="form['eligibleForLoan']">
                  <option value="null" hidden>Select...</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Eligible For Loan</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('requestedLoan')">
                <select
                  class="w-full border-b-2 px-2 py-1  hover:border-blue-400 focus:border-blue-400"
                  v-model="form['requestedLoan']">
                  <option v-if="form['eligibleForLoan'] === null" value="null" hidden>
                    Select if eligible first...
                  </option>
                  <option v-else value="null" hidden>
                    Select...
                  </option>
                  <template v-if="form['eligibleForLoan'] === 'YES'">
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </template>
                  <option v-if="form['eligibleForLoan'] === 'NO'" value="NA">NA</option>
                </select>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Requested Loan</span>
              </div>
            </div>
          </div>
          <div>
            <div class="pt-3 font-medium">Beneficiary</div>
            
            <div class="grid grid-cols-2 gap-2">
              <div class="relative  px-3 pt-3 pb-1"
                   :hidden="!is_visible('beneficiaryName')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['beneficiaryName']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Beneficiary Name</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('beneficiaryPhone')">
                <the-mask type="text"
                          class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                          v-model="form['beneficiaryPhone']"
                          placeholder="Write here..."
                          :mask="['(###) ###-####', '(###) ###-####']"/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Beneficiary Phone Number</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('beneficiaryEmail')">
                <input type="text"
                       class="w-full border-b-2 px-2 lowercase hover:border-blue-400 focus:border-blue-400"
                       v-model="form['beneficiaryEmail']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Beneficiary Email</span>
              </div>
              
              <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('beneficiaryAddress')">
                <input type="text"
                       class="w-full border-b-2 px-2  hover:border-blue-400 focus:border-blue-400"
                       v-model="form['beneficiaryAddress']"
                       placeholder="Write here..."/>
                <span
                  class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                  Beneficiary Address
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="pt-3 font-medium">Work-Related Information</div>
          
          <div class="grid grid-cols-2 gap-2">
            <div class="relative px-3 pt-3 pb-1"
                 :hidden="!is_visible('hiredDate')">
              <input type="date"
                     class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                     v-model="form['hiredDate']"
                     placeholder="yyyy-mm-dd"/>
              <span
                class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                Hired Date
              </span>
            </div>
            
            <div class="relative px-3 pt-3 pb-1"
                 :hidden="!is_visible('startDate')">
              <input type="date"
                     class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                     v-model="form['startDate']"
                     placeholder="yyyy-mm-dd"/>
              <span
                class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                Start Date
              </span>
            </div>
            
            <div class="relative px-3 pt-3 pb-1"
                 :hidden="!is_visible('certificationDate')">
              <input type="date"
                     class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                     v-model="form['certificationDate']"
                     placeholder="yyyy-mm-dd"/>
              <span
                class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                Certification Date
              </span>
            </div>
            
            <div class="relative px-3 pt-3 pb-1"
                 :hidden="!is_visible('contractSigningDate')">
              <input type="date"
                     class="w-full border-b-2 px-2 hover:border-blue-400 focus:border-blue-400"
                     v-model="form['contractSigningDate']"
                     placeholder="yyyy-mm-dd"/>
              <span
                class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">
                Contract Signing Date
              </span>
            </div>
            
            <div class="relative px-3 pt-3 pb-1" :hidden="!is_visible('scheduledHours')">
              <edit-employee-dropdown
                type="number"
                column="scheduledHours"
                v-model="form['scheduledHours']"
                placeholder="Select..."
              />
              <span
                class="absolute top-0 left-3 mr-auto whitespace-nowrap  text-gray-400 text-xs">Scheduled Hours</span>
            </div>
          </div>
        </div>
        <div>
          <div class="pt-3 font-medium">Address</div>
          
          <div class="mt-3 ml-3 flex text-left font-sans font-semibold text-md">
            Please look up your address, select from the list the address closest to
            where you live, once you have selected, click on "set address".
          </div>
          <div class="flex flex-row text-left justify-content-center">
            <div class="flex flex-1 flex-col">
              <div class="relative mx-3 mb-2 flex flex-row" label="Address:"
                   description="*Search your address">
                <GmapAutocomplete
                  class="w-full border-b-2 px-2 pt-3 hover:border-blue-400 focus:border-blue-400"
                  @place_changed='setPlace'
                  placeholder="Write your address here"/>
                <span
                  class="absolute top-0 left-0 mr-auto whitespace-nowrap text-gray-400 text-xs">Address</span>
                <button
                  class="m-1 ml-3 whitespace-nowrap rounded-lg bg-blue-500 p-2 px-3 text-xs  text-white hover:bg-blue-900"
                  @click.prevent='addMarker'>Set address
                </button>
              </div>
              <GmapMap :center='center' :zoom='16'
                       style='width:100%;  height: 300px;'>
                <GmapMarker :key="index" v-for="(m, index) in markers"
                            :position="m.position" @click="center=m.position"/>
              </GmapMap>
            </div>
          </div>
          <div class="ml-3 flex text-left font-sans text-xs text-red-500">
            * Note: Changes will not be applied until you hit the "save" button at the
            bottom of the screen.
          </div>
        </div>
        
        <div class="mt-4 flex">
          <button
            class="mx-auto rounded-lg bg-red-700 p-2 px-5 text-xs font-semibold  text-white hover:bg-red-800"
            type="submit">Save
          </button>
        </div>
      
      </form>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import {TheMask} from "vue-the-mask";
import EditEmployeeDropdown from "@/views/components/Dropdown/EditEmployeeDropdown.vue";
import loading from "@/views/mockups/loading.vue";

export default {
  components: {
    loading,
    EditEmployeeDropdown,
    TheMask,
  },
  data() {
    return {
      // City Select
      cityOptions: {birth: {}, address: {}},
      selectState: {birth: true, address: true},
      showLoadingCity: {birth: false, address: false},
      showSelectCity: {birth: false, address: false},
      
      center: {
        lat: 25.6737936,
        lng: -100.3931177
      },
      markers: [],
      places: [],
      form: {},
      isLoading: true,
      my_profile_fillable: [
        'firstName',
        'middleName',
        'firstLastName',
        'secondLastName',
        'gender',
        'dateOfBirth',
        'stateOfBirth',
        'cityOfBirth',
        'nationality',
        'curp',
        'rfc',
        'ssn',
        'umf',
        'personalEmail',
        'workEmail',
        'phoneOne',
        'phoneTwo',
        'emergencyContactName',
        'emergencyContactPhone',
        'emergencyContactEmail',
        'kids',
        'bilingual',
        'maritalStatus',
        'education',
        'specialty',
        'fatherName',
        'motherName',
        'beneficiaryName',
        'beneficiaryPhone',
        'beneficiaryEmail',
        'beneficiaryAddress',
      ],
      edit_profile_fillable: [
        'firstName',
        'middleName',
        'firstLastName',
        'secondLastName',
        'gender',
        'dateOfBirth',
        'stateOfBirth',
        'cityOfBirth',
        'nationality',
        'curp',
        'rfc',
        'ssn',
        'umf',
        'personalEmail',
        'workEmail',
        'phoneOne',
        'phoneTwo',
        'emergencyContactName',
        'emergencyContactPhone',
        'emergencyContactEmail',
        'kids',
        'bilingual',
        'maritalStatus',
        'education',
        'specialty',
        'fatherName',
        'motherName',
        'paymentMethod',
        'sdi',
        'sd',
        'eligibleForLoan',
        'requestedLoan',
        'beneficiaryName',
        'beneficiaryPhone',
        'beneficiaryEmail',
        'beneficiaryAddress',
        'hiredDate',
        'startDate',
        'certificationDate',
        'contractSigningDate',
        'scheduledHours',
      ]
    }
  },
  computed: {
    ...mapState([
      "employees",
    ]),
    ...mapGetters([
      "infoEmployee",
      "getEditableInfo",
      "stateList",
      "getCities",
    ]),
    admin_view() {
      return !!this.$route.params.id;
    },
    
    user_view() {
      return !this.$route.params.id;
    },
    
  },
  methods: {
    ...mapActions([
      "sendEdit",
      "fetchCitiesByState",
      "fetchStates",
      "fetchEditableEmployeeInfo"
    ]),
    loadCities: function (selectedState, dropdownId, initial=false) {
      this.selectState[dropdownId] = false;
      this.showLoadingCity[dropdownId] = true;
      this.showSelectCity[dropdownId] = false;
      let stateId = this.stateList.filter(x => x.stateName === selectedState)[0].id;
      this.fetchCitiesByState(stateId).then(() => {
        this.cityOptions[dropdownId] = JSON.parse(JSON.stringify(this.getCities));
        this.showLoadingCity[dropdownId] = false;
        this.showSelectCity[dropdownId] = true;
        
        if ( initial === true ){
          return;
        }
        const modelVariables = {
          birth: 'cityOfBirth',
          address: 'city',
        };
        
        this.form[modelVariables[dropdownId]] = null;
      });
    },
    is_visible(label) {
      return (this.user_view && this.my_profile_fillable.includes(label)) || (this.admin_view && this.edit_profile_fillable.includes(label));
    },
    
    setPlace(place) {
      this.form['GoogleMapsLocation'] = place;
    },
    
    addMarker() {
      if (this.form['GoogleMapsLocation']) {
        const marker = {
          lat: this.form['GoogleMapsLocation']?.geometry?.location?.lat(),
          lng: this.form['GoogleMapsLocation']?.geometry?.location?.lng(),
        };
        this.markers.push({
          position: marker
        });
        this.places.push(this.form['GoogleMapsLocation']);
        this.center = marker;
        
        this.pluckElements();
      }
    },
    
    pluckElements: function () {
      const address_components = this.form['GoogleMapsLocation'].address_components;
      let components = {};
      address_components.map((value) => {
        value.types.map((value2) => {
          components[value2] = value.long_name;
          if (value2 === 'country')
            components.country_id = value.short_name;
          if (value2 === 'administrative_area_level_1')
            components.state_code = value.short_name;
        })
      })
      
      let street_complete = null;
      if (typeof components.route !== "undefined" && typeof components.street_number !== "undefined") {
        street_complete = components.route + " " + components.street_number;
      } else if (typeof components.route !== "undefined") {
        street_complete = components.route;
      } else {
        street_complete = this.form['GoogleMapsLocation'].name;
      }
      this.form['addressOne'] = street_complete;
      this.form['addressTwo'] = components.sublocality;
      this.form['zipCode'] = components.postal_code;
      this.form['state'] = components.administrative_area_level_1;
      this.form['city'] = components.locality;
      this.form['latitude'] = this.form['GoogleMapsLocation']?.geometry?.location?.lat();
      this.form['longitude'] = this.form['GoogleMapsLocation']?.geometry?.location?.lng();
    },
    
    editInfoSubmit() {
      event.preventDefault();
      this.isLoading = true;
      let infoEdit = {
        employeeId: this.infoEmployee.EmpID,
        profile: Object.entries(this.form),
      };
      this.sendEdit(infoEdit).then((response) => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$fire({
            type: 'success',
            title: 'Changes saved',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$router.go(-1);
          });
        }
      }).catch((response) => {
        this.fire({
          type: 'error',
          title: 'Something went wrong',
          text: response,
          showConfirmButton: true
        })
      });
    },
  },
  created() {
    if (this.infoEmployee?.length <= 0) this.$router.go(-1);
    
    Promise.all([
      this.fetchEditableEmployeeInfo(this.infoEmployee.EmpID),
      this.fetchStates()
    ]).then(
      () => {
        this.form = JSON.parse(JSON.stringify(this.getEditableInfo));
        this.isLoading = false;
        
        if ( this.form.stateOfBirth !== '' ){
          this.loadCities(this.form.stateOfBirth, 'birth', true)
        }
      }
    );
  }
}
</script>
<style scoped>

::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder { /* Recent browsers */
  text-transform: none;
}

</style>