<template>  
    <div class="SendSuggestionContainer">
        <loading id='ModalLoader' :active.sync="getLoadingModal" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <div class="d-block text-center">
            <h3>Nueva queja o sugerencia</h3>
        </div>
        <div>
            <form @submit.prevent="saveSuggestion" class="space-y-2">
                <div class="text-center">
                    <label>
                        <p class="text-muted mr-5 ml-5">
                        <small>La siguiente información está protegida y solamente el equipo de Engagement podrá acceder a ella.</small>
                        </p>
                    </label>
                </div>
                <div class="relative flex flex-column align-start justify-center px-2">
                    <label for="type" class="text-gray-600 text-left text-sm ml-2 block">Seleccione el tipo de mensaje</label>
                    <multiselect @select="assignType" :options="suggestion_types" v-model="temporalSelection"
                    :allow-empty="false"
                    deselect-label="Press enter to select"
                    label="display"
                    class="w-full border-b-2 px-3 block multiselect-select"></multiselect>
                </div>

                <div class="relative flex flex-column align-start justify-center px-2">
                    <label for="type" class="text-gray-600 text-left text-sm ml-2 block">Describa la situación:e</label>
                    <textarea v-model="NewSuggestion.SuggDescription"
                    class="w-full border-b-2 py-2 px-3 block focus:border-gray-700 resize-none"
                    ></textarea>
                </div>

                <div class="flex">
                    <button type="submit" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full mx-auto">
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    </div>  
</template>
<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import { required }                         from 'vuelidate/lib/validators';
import { mapState, mapGetters, mapActions } from "vuex";
import Loading                              from 'vue-loading-overlay';
import moment                               from 'moment';
import auth                                 from "@/config/user"
import Multiselect                          from 'vue-multiselect'
export default {
    components: { Loading, Multiselect },
    validations: {
        SuggType:        { required },
        SuggDescription: { required },
    },
    data(){
        return {
            submitted: false,
            fullPage: true,
            color: "#F2F2F2",
            bgcolor: "#000000",
            loader: 'dots',
            temporalSelection: null,

            agent_selected: null,
            campaign: '',
            auditor: 0,

            NewSuggestion: {
                SuggType:        null,
                SuggDescription: null,
                SuggAt:          null
            },

            suggestion_types: [
                {display: 'Queja', value: 'Complain'},
                {display: 'Sugerencia', value: 'Suggestion'}
            ],
        };
    },
    created(){
        this.loadInfoEmployee();
    },
    computed: {
        ...mapState(['employees','feedbacks']), // previously clockerUsers
        ...mapGetters(['getLoadingModal','infoEmployee', 'getFlagLoadingSuggestion'])
    },
    methods:{
    ...mapActions(['loadMyProfileByEmpId', 'createSuggestion']),

    assignType (value) {
        this.NewSuggestion.SuggType = value.value;
    },

    customFormatter(date) {
        return moment(date).format('DD/MM/YYYY');
    },

    loadInfoEmployee(){
        let idEmployee = auth.currentUser.EmpID;
        this.loadMyProfileByEmpId(idEmployee).then(() => {
            this.loadingFlag = false;
        }, error => {
            console.error("Error fetching the projects: "+ error)
        });
    },

    agentSelected: function(data){        
        this.agent_selected = data;
    },

    saveSuggestion:function (){      
        if(this.NewSuggestion.SuggDescription != null 
        && this.NewSuggestion.SuggDescription != '' 
        && this.NewSuggestion.SuggType != null) {
                this.$store.commit('setLoadingSuggFlag',true);
                let suggestionData = {
                    audit_form:{
                        form: 'sugg_box',
                        empID: this.infoEmployee.EmpID,
                        campaign: this.infoEmployee.Campaign,
                        auditor: 'Engagement',
                    },
                    suggestionInfo: {
                        SuggEmp: this.infoEmployee.EmpID,
                        SuggCampaign: this.infoEmployee.Campaign,
                        SuggType: this.NewSuggestion.SuggType,
                        SuggAt: this.agent_selected,
                        SuggSite: this.infoEmployee.Location,
                        SuggReport: this.infoEmployee.ReportTo,
                        SuggGender: this.infoEmployee.Gender,
                        SuggDescription: this.NewSuggestion.SuggDescription,
                    },
                }
                this.createSuggestion(suggestionData).then((response) => {
                    this.$store.commit('setLoadingSuggFlag',false);
                    if(response.status == 201){
                        this.$fire({
                            type: 'success',
                            title: 'Su mensaje se ha enviado',
                            showConfirmButton: false,
                            timer: 1300
                        }).then(()=>{
                            this.$emit('toggle');       
                        });
                    }else{
                        this.$fire({
                            type: 'error',
                            title: 'This module is temporary unabled, please report this issue with the SysAdmin.',
                            showConfirmButton: false,
                            timer: 1300
                        })
                    }
                }, error => {
                    console.error("Error: " + error)
                });
        }else{
                this.$store.commit('setLoadingSuggFlag',false);
                this.$fire({
                    type: 'error',
                    title: 'Oops! Hace falta información',
                    showConfirmButton: false,
                    timer: 1300
                });
                }
            },

  }
};
</script>
<style scoped>
.multiselect {
    width: 91% !important;
    padding: 0;
}
.multiselect-select :deep(.multiselect__tags) {
    border: 0px;
}
.multiselect-select :deep(.multiselect__option--highlight) {
    background: #6074ff;
    outline: none;
}
.multiselect-select :deep(.multiselect__option--highlight):after {
    content: attr(data-select);
    background: none;
}
.multiselect-select :deep(.multiselect__option--disabled) span {
    font-size: small;
    font-weight: 600;
}
.multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight) {
    background: #6074ff;
}
.multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight):after {
    background: none;
    content: attr(data-deselect);
}
</style>