<template>
<div :v-show="isAuthenticated" class="absolute top-0 bottom-0 left-0 right-0" id="layout-wrapper">
  <!--<dropback :show="sidebarIsVisible" @click.native="toggleSidebar"></dropback>-->
  <!--Content layout-->
  <clocker-component v-if="!!this.username"></clocker-component>
  <sidebar :hidden="!sidebarIsVisible" @toggle="toggleSidebar"></sidebar>

  <main class="absolute top-0 bottom-0 right-0 overflow-auto bg-gray-100" :class="userIsActive ? 'left-60' : 'left-0'">
    <header class="sticky my-3 mx-10 top-0 left-0 right-0 z-40 drop-shadow-lg" v-if="!!this.username">
      <navbar v-show="userIsActive" :username="username" @toggle="toggleSidebar"></navbar>
      <notifications></notifications>
    </header>
    <div id="page_start"></div>
      <h1 class="text-3xl text-left font-semibold leading-tight text-gray-900 mx-10"  v-if="!!this.username">{{title}}</h1>
      <div :class="userIsActive ? 'mx-10' : ''">
      <slot></slot>
    </div>
  </main>

</div>
</template>
<script>
import debounce from 'debounce'
import NotificationMenu from "@/views/notifications/layout";
import Sidebar from "@/views/Utilities/Sidebar";
import Navbar from "@/views/Utilities/Navbar";
//import Alert from "@/views/Utilities/Alert";
import ClockerComponent from "@/views/clocker/main";
//import Dropback from "@/views/Utilities/Dropback";
import auth       from "@/config/user"
export default {
  name: "Layout",
  data(){
    return{
      showSidebar: true,
      route:this.$route.name,
      isAuthenticated: false,
    }
  },
  components: {
    'notifications': NotificationMenu,
    ClockerComponent,
    Sidebar,
    Navbar,
    //Dropback
  },
  methods:{
    toggleSidebar:debounce(function(){
      this.showSidebar = !this.showSidebar;
    },200),
  },
  computed:{
    sidebarIsVisible:function(){
      // If user's status is active, it will show the nav bar, in other case, not
      return this.showSidebar && this.userIsActive;
    },
    userIsActive: function(){
      return auth.currentUser?.status?.toLowerCase() === 'active';
    },
    userIsPredismissal: function(){
      return auth.currentUser?.status?.toLowerCase() === 'predismissal';
    },
  },
  props:{
    username:{
      type:String,
      default:''
    },
    title:{
       default:'Dasboard',
      type:String
    }
  },
  created(){
    var hasToken = auth.currentUser?.token;
    if(hasToken !== ''|| hasToken !== undefined){
      this.isAuthenticated = true;
    }
  },
  watch:{
    $route:function(){
      // Toggle moves back to hide(false)/show(true) when you move between routes
      this.showSidebar = true;
    }
  }
}
</script>
<style>
main{
  scroll-behavior: smooth !important;
}
.text-red-150 {
  color: #ff454f;
}
</style>