<template>
    <div>
        <splide :slides="getImages" :options="options">
            <splide-slide v-for="slide in getImages" :key="slide.id">
                <b-link :href="slide.url_to_redirect" target="_blank">
                    <img class="rounded-lg shadow-md slides-images mx-auto min-h-52" :src="slide.imgURL">
                </b-link>
            </splide-slide>
        </splide>
    </div>
</template>
<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import auth               from "@/config/user"
  export default {
    data() {
      return {
        location : 'home',
        options: {
          rewind      : true,
          perPage     : 1,
          gap         : '1rem',
          autoplay    : true,
          pauseOnHover: false,
          arrows      : 'slider',
        },
      }
    },
    computed:{
        ...mapState(["fileState"]),
        ...mapGetters(['getImages']),
        isMonterrey:function(){
            return this.location.includes('MTY');
        },
        isAguas:function(){
            return this.location.includes('AGS');
        },
    },
    mounted(){
        this.location = auth.currentUser.location;
        this.fetchArrayImages();
    },
    methods:{
        ...mapActions(['fetchDashboardImages']),
        fetchArrayImages: function(){
            this.fetchDashboardImages();
        }
    },
    props:{
      user:Object
    }
  }
</script>
<style scoped>
.min-h-52 {
  height: 280px !important;
}
</style>