<template>
<div class="bg-white shadow-sm rounded-md w-full p-2 flex flex-row items-center mb-3" >
    <div class="flex-1">
    <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full p-2">    
        <multiselect v-model="trainee" :options="getTrainees" placeholder="Select a trainee" label="name" track-by="name" :searchable="true"/>
    </div>   
    </div>
    <div class="w-32 flex h-full br-red-200 flex-row justify-end items-center">
        <button class="px-3 py-2 shadow-sm rounded-sm text-white" 
        type="button" :disabled="!traineeSelected" :class="traineeSelected ? 'bg-gray-700': 'bg-gray-400 cursor-not-allowed' " @click="selected">
            Add Trainee
        </button>
    </div>
</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import debounce from 'debounce'

export default {
    components:{
      
    },
    data(){
        return{
            trainee:null,
            traineeKey:0
        }
    },
    computed:{
        ...mapGetters(['getTrainees']),
        traineeSelected:function(){
            if(!this.trainee) return false;
            return Object.keys(this.trainee).length > 0
        },
    },
    methods:{
        ...mapActions(['fetchTrainees']),
        selected:debounce(function(){
           this.$emit('toggle',this.trainee);
        },500),
    },
    created(){
        this.fetchTrainees(
            this.$route.params.id
        );
    }
}
</script>