<template>
    <tbody class="bg-white divide-y divide-blue-50">
    <!--#Data-placeholder-->
    <tr v-if="getHoursManagmentStatus.payableHours" >
        <td >
            <div class="text-gray-700 animate-pulse p-2 py-3">
                  <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse">
                  <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse">
                   <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse">
                  <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse w-full">
                   <span class="bg-gray-100 py-1 px-7"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse">
                  <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
            <td>
            <div class="text-gray-700 animate-pulse">
                 <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
        </td>
    </tr>
    <!--#Data-display-->
    <tr v-else>
        <td v-for="date,index in getDateRange" :key="index" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 space-y-3">
            <div class="p-2 px-3 flex flex-col">
                <div class="flex flex-col">
                    <span class="text-xl font-semibold flex flex-col">
                        {{filterHours(date)}}
                        <span class="text-xs font-medium font-sans">
                            Payable time
                        </span>
                    </span>
                </div>
                <div class="flex flex-col m-2">
                    <span class="text-md font-semibold flex flex-col">
                        {{filterShift(date)}}
                        <span class="text-xs font-medium font-sans">
                            Shift Duration
                        </span>
                    </span>
                </div>
                <button class="flex mx-auto bg-gray-700 shadow-[10px] hover:bg-gray-800 p-2 px-3 rounded-md"
                :class="{'invisible' : !isAccessible(date)}"
                :disabled="!isAccessible(date)"
                @click="sendMeHoursDetails(date)">
                    <span class="text-xs font-semibold font-sans text-white">
                        See details
                    </span>
                </button>
            </div>
        </td>
    </tr>
    
    </tbody>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'

export default {
    props:{
        loadPrevious:{
            default:false,
            type:Boolean,
        }
    },
     computed:{
        ...mapGetters(['getPayableHours','getHoursManagmentStatus','getDateRange' ,'getShift']),
    },
    methods:{
        ...mapActions(['fetchWeeklyPayableHours', 'fetchWeeklyShift', 'getUsername']),
        filterHours:function(date){
            let res = this.getPayableHours.find(element => element.Event_Date == date)?.Total_Hours;
            return res  ?? 'No record';
        },
        filterShift:function(date){
            let res = this.getShift.find(element => element.Event_Date == date)?.Total_Hours;
            return res ?? 'No record' ;
        },
        sendMeHoursDetails: function(date){
            this.$router.push({path:`/payroll/hours-review/employee/my-profile/${date}`});
        },
        isAccessible(date){
            let today = this.$moment().format('YYYY-MM-DD');
            return today > date;
        }
    },
   
    created(){
        this.fetchWeeklyPayableHours(this.loadPrevious);
        this.fetchWeeklyShift(this.loadPrevious);
    }
}
</script>