<template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class="bg-white px-2 focus:text-gray-600 active:text-gray-600" v-model="selectedTitle">  
          <optgroup label = "Select a title">
            <option hidden value="0">Choose a Title</option>
            <option v-for="(item,index) in getTitlesAtRequests" :key="index" :value="item.Title">{{item.Title}}</option>    
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error:null,
            selectedTitle : 0
        }
    },
    computed:{
        ...mapGetters(['getTitlesAtRequests']),
    },
    created(){
       this.fetchTitlesAtRequests().then(()=>{
           this.error =false
        });
    },
    methods:{
        ...mapActions(['fetchTitlesAtRequests'])
    },
    watch:{
      selectedTitle: Debounce(function(){
          this.$emit('toggle',{
              key:'title',
              value :this.selectedTitle
          });
      },200)     

    }
}
</script>
