import { render, staticRenderFns } from "./emergency_contact.vue?vue&type=template&id=4ee59bd6&scoped=true&"
import script from "./emergency_contact.vue?vue&type=script&lang=js&"
export * from "./emergency_contact.vue?vue&type=script&lang=js&"
import style0 from "./emergency_contact.vue?vue&type=style&index=0&id=4ee59bd6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee59bd6",
  null
  
)

export default component.exports