<template>
    <div class="m-auto">
        <form class="bg-white shadow-sm relative rounded-lg p-6 mb-6" @submit.prevent="sendRequest()">
            <h5 class="bg-blue-100 text-blue-600 text-sm rounded-lg py-3 mb-6" v-if="this.$route.params.id && this.$route.params.idmsl">Edit the form to make changes a request of medical sick leave for employee {{ employeeId }}</h5>
            <h5 class="bg-blue-100 text-blue-600 text-sm rounded-lg py-3 mb-6" v-else>Please Fill the form to submit a request of medical sick leave for employee {{ employeeId }}</h5>
            <div class="flex space-x-6 mb-6">
                <div class="flex-1 space-y-6">
                    <div class="flex space-x-6">
                        <div class="relative flex-1 flex flex-column align-start justify-center">
                            <label for="classification" class="text-gray-400 text-left text-sm ml-2 block">Classification</label>
                            <multiselect v-model="classification_selected" :options="classification_options"
                            class="w-full border-b-2 px-3 block multiselect-select" :allow-empty="false"
                            label="IncidenceLabel" track-by="Id" :show-labels="false" placeholder="Select a classification"
                            deselect-label="Press enter to select"
                            @input="$v.form_data.classification.$touch()">
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">{{ props.option.IncidenceLabel }}</span>
                                    </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc text-ellipsis">
                                        <span class="option__title font-semibold block">{{ props.option.IncidenceLabel }}</span>
                                        <span class="option__small text-xs w-full flex-1 text-ellipsis whitespace-normal">{{ props.option.Description }}</span>
                                    </div>
                                </template>
                            </multiselect>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form_data.classification.$dirty" :valid="!$v.form_data.classification.$invalid"></pill>
                            </div>
                        </div>
                        <div class="relative flex flex-column align-start justify-center">
                            <label for="start_date" class="text-gray-400 text-left text-sm ml-2 block">Start date</label>
                            <date-picker v-model="form_data.start_date"
                            input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2"
                            :disabled-date="dateRange"
                            @change="$v.form_data.start_date.$touch()"
                            placeholder="Select a date" value-type="format" format="YYYY-MM-DD"></date-picker>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form_data.start_date.$dirty" :valid="!$v.form_data.start_date.$invalid"></pill>
                            </div>
                        </div>
                    </div>
                    <div class="flex space-x-6">
                        <div class="relative flex flex-column align-start justify-center">
                            <label for="days_of_incapacitation" class="text-gray-400 text-left text-sm ml-2 block">Amount of authorized sick leave days</label>
                            <input class="w-full border-b-2 py-2 px-3 block focus:border-gray-700" 
                            type="number" v-model="form_data.days_of_incapacitation" 
                            @keyup="$v.form_data.days_of_incapacitation.$touch()">                                           
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form_data.days_of_incapacitation.$dirty" :valid="!$v.form_data.days_of_incapacitation.$invalid">
                                    Min: 1
                                </pill>
                            </div>
                        </div>
                        <div class="relative flex-1 flex flex-column align-start justify-center">
                            <label for="type" class="text-gray-400 text-left text-sm ml-2 block">Periodicity</label>
                            <multiselect v-model="form_data.periodicity" :options="periodicity_options" placeholder="Select a classification" :allow-empty="false"
                            class="w-full border-b-2 px-3 block multiselect-select"
                            deselect-label="Press enter to select"
                            @input="$v.form_data.periodicity.$touch()"></multiselect>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form_data.periodicity.$dirty" :valid="!$v.form_data.periodicity.$invalid"></pill>
                            </div>
                        </div>
                    </div>
                    <div class="flex space-x-6">
                        <div class="relative flex-1 flex flex-column align-start justify-center">
                            <label for="confirmation" class="text-gray-400 text-left text-sm ml-2 block">Write employee id of employee</label>
                            <input class="uppercase w-full border-b-2 py-2 px-3 block focus:border-gray-700" 
                            maxlength="150" type="text" v-model="confirmation" 
                            @keyup="$v.confirmation.$touch()">
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.confirmation.$dirty" :valid="!$v.confirmation.$invalid">
                                    User does not match
                                </pill>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <a v-if="this.$route.params.id && this.$route.params.idmsl" :href="this.getLink()" target="_blank" class="block bg-blue-600 hover:bg-blue-700 text-white !no-underline rounded-lg px-6 py-2 mb-6"><i class="bi bi-folder2-open mr-2"></i> Open file</a>
                    <div>
                        <label for="upload" class="text-gray-400 text-left text-sm ml-2 block">Upload Official Document</label>
                        <div class="px-6 py-1 mb-3 rounded-md bg-blue-100 text-blue-700 text-sm">File size max. 1 MB</div>
                        <upload-files @changed="handleImages" :isMultiple='multiple_files' :fileTypes="file_types">
                        </upload-files>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.form_data.file.$dirty" :valid="!$v.form_data.file.$invalid"></pill>
                        </div>
                    </div>
                    <div class="mt-3" v-if="hasRole('HR')">
                        <label class="flex items-center text-xs mb-0 uppercase">
                            <input type="checkbox" class="focus:ring-blue-400 h-4 w-4 rounded mr-2" :true-value="1" :false-value="0" v-model="form_data.file_delivered">
                            Official Document Delivered?
                        </label>
                    </div>
                </div>
            </div>

            <button v-if="this.$route.params.id && this.$route.params.idmsl" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full" type="submit"><i class="bi bi-download mr-2"></i>Save</button>
            <button v-else class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" type="submit"><i class="bi bi-send mr-2"></i>Send Request</button>

        </form>
    </div>
</template>
<script>
import Multiselect  from 'vue-multiselect'
import DatePicker   from 'vue2-datepicker';
import uploadFiles  from '@/views/Utilities/upload_files/index'
import { required, minValue } from 'vuelidate/lib/validators';
import Pill from '@/views/mockups/ValidationPill';
import permissions from '@/config/mixins';
import { mapActions, mapGetters } from 'vuex';
export default {
    mixins:[
        permissions
    ],
    components: {
        Multiselect,
        DatePicker,
        'upload-files' : uploadFiles,
        Pill
    },
    data () {
        return {
            id_msl: 0,
            multiple_files: false,
            file_types: ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'],
            confirmation: null,
            classification_selected: null,
            form_data: {
                classification: null,
                start_date: null,
                days_of_incapacitation: 0,
                periodicity: null,
                file: [],
                file_delivered: 0,
                employee: null,
                status: 'pending'
            },
            periodicity_options: [
                'Non-recurring',
                'Recurring',
            ],
            classification_options: [],
            status_options: [
                'pending',
                'approved',
                'declined'
            ]
        }
    },
    mounted () {
        if(this.$route.params.id && this.$route.params.idmsl) {
            document.getElementById('pro-loading').style.display = 'flex';
        }
    },
    created () {
        this.fetchMslList().then(()=>{
            this.classification_options = this.getMSLList;
            if(this.$route.params.id && this.$route.params.idmsl) {
                this.fetchMedicalRequestDetails(this.$route.params.idmsl).then(() => {
                    this.form_data.classification = this.getMSLDetails?.classification_id;
                    this.form_data.start_date = this.getMSLDetails?.start_date;
                    this.form_data.days_of_incapacitation = this.getMSLDetails?.days_duration;
                    this.form_data.periodicity = this.getMSLDetails?.periodicity;
                    this.form_data.file = this.getLink();
                    this.form_data.file_delivered = this.getMSLDetails?.file_delivered;
                    this.form_data.employee = this.getMSLDetails?.employee;
                    this.form_data.status = this.getMSLDetails?.status;
                    this.classification_selected = this.getMSLList.find(obj => obj?.Id == this.form_data?.classification);
                });
            }
            document.getElementById('pro-loading').style.display = 'none';
        });

        this.form_data.employee = this.$route.params.id;
        if(this.hasRole('HR')) this.form_data.status = 'approved';
    },
    validations () {
        return {
            form_data: {
                classification: {
                    required
                },
                start_date: {
                    required
                },
                days_of_incapacitation: {
                    required,
                    minValue: minValue(1)
                },
                periodicity: {
                    required
                },
                file: {
                    required,
                },
                status: {
                    required
                }
            },
            confirmation: {
                required,
                confirmed: (str) => {
                    return str.toLowerCase() === this.form_data.employee.toLowerCase()
                }
            },
        }
    },
    computed: {
        ...mapGetters([
            'getMSLDetails',
            'getMSLList',
        ]),
        employeeId () {
            return this.form_data.employee?.toUpperCase();
        },
    },
    watch: {
        classification_selected(newVal) {
            this.form_data.classification = newVal?.Id;
        }
    },
    methods: {
        ...mapActions([
            'storeMedicalLeave',
            'editMedicalLeave',
            'uploadImgRequestProof',
            'fetchMedicalRequestDetails',
            'fetchMslList',
        ]),

        getLink () {
            let url = this.getMSLDetails?.file;
            if(this.getMSLDetails?.file?.startsWith("http")) return url;
            return 'https://centris.website-us-southeast-1.linodeobjects.com/linode_requests/' + url;
        },

        justUppercase(option)
        {
            return option?.toUpperCase();
        },

        hasRole(n) {
            return this.hasPermission(n)
        },

        dateRange(date)
        {
            if(this.hasRole('HR')) return;
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Not after today nor before 4 days past
            return date > today || date < new Date(today.getTime() - 4 * 24 * 3600 * 1000);
        },

        handleImages(files){
            this.$v.form_data.file.$touch();
            this.form_data.file = files[0];
        },

        async sendRequest()
        {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$fire({
                    type: 'error',
                    title: 'Please review the form, there is missing information',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                })
                return;
            }

            let dataObject = new FormData();
            dataObject.append('image', this.form_data.file);
            dataObject.append('file_delivered', this.form_data.file_delivered);
            dataObject.append('classification', this.form_data.classification);
            dataObject.append('start_date', this.form_data.start_date);
            dataObject.append('days_of_incapacitation', this.form_data.days_of_incapacitation);
            dataObject.append('periodicity', this.form_data.periodicity);
            dataObject.append('employee', this.form_data.employee);
            dataObject.append('status', this.form_data.status);

            if(this.$route.params.id && this.$route.params.idmsl) {
                this.edit(dataObject);
            } else {
                this.new(dataObject);
            }
        },

        async new (dataObject) 
        {
            document.getElementById("pro-loading").style.display = "flex";
            await this.storeMedicalLeave(dataObject).then((res) => {
                this.$fire({
                    type: 'success',
                    title: res?.message
                })
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err?.response?.data?.message
                })
            })
            document.getElementById("pro-loading").style.display = "none";
            this.$router.go(-1);
        },

        edit (dataObject) 
        {
            dataObject.append('id', this.$route.params.idmsl);
            document.getElementById("pro-loading").style.display = "flex";
            this.editMedicalLeave(dataObject).then((res) => {
                this.$fire({
                    type: 'success',
                    title: res?.message
                })
                document.getElementById("pro-loading").style.display = "none";
                this.$router.go(-1);
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err?.message
                })
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .mx-datepicker {
        width: 100% !important;
    }
    .multiselect {
        width: 91% !important;
        padding: 0;
    }
    .multiselect-select :deep(.multiselect__tags) {
        border: 0px;
    }
    .multiselect-select :deep(.multiselect__option--highlight) {
        background: #6074ff;
        outline: none;
    }
    .multiselect-select :deep(.multiselect__option--highlight):after {
        content: attr(data-select);
        background: none;
    }
    .multiselect-select :deep(.multiselect__option--disabled) span {
        font-size: small;
        font-weight: 600;
    }
    .multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight) {
        background: #6074ff; 
        /* red: #FF6A6A; */
    }
    .multiselect-select :deep(.multiselect__option--selected.multiselect__option--highlight):after {
        background: none;
        content: attr(data-deselect);
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>