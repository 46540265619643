<template>
    <form @submit.prevent="send" autocomplete="off">
        <div class="px-4 pt-5 space-y-5">
            <div class="flex items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                    <i class="bi bi-key text-3xl my-auto text-blue-600"></i>
                </div>
                <div class="flex-1 my-auto ml-4 text-left">
                    <h3 class="text-base font-semibold leading-6 text-gray-900 text-left" id="modal-title">Please confirm your identity writing your password</h3>
                </div>
            </div>

            <div class="text-left">
                <label for="password" class="text-xs text-left">Password</label>
                <div class="relative p-2 bg-transparent border-b-2 border-blue-400" 
                :class="{ 
                    'border-red-500' : $v.password.$invalid || error,
                }">
                    <input :type="show ? 'text' : 'password'" v-model="password"
                    class="bg-transparent w-full"  :disabled="loading">
                    <div class="absolute inset-y-0 right-0 flex items-center text-sm pr-2">
                        <i class="bi bi-eye-fill  hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'block': !show, 'hidden':show }"></i>
                        <i class="bi bi-eye-slash-fill hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'hidden': !show, 'block':show }"></i>
                    </div>
                </div>
                <span class="text-xs text-red-400" :class="{'hidden' : !error}">Wrong password!</span>
            </div>
        </div>
        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" v-if="!loading">
            <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto my-auto">
            Continue
            </button>
        </div>
        <div v-else class="flex">
            <i class="bi bi-boxes text-3xl animate-spin mx-auto my-3"></i>
        </div>
    </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
export default {
    data () {
        return {
            password: '',
            loading: false,
            show: false,
            error: false
        }
    },
    validations () {
        return {
            password: {
              required,

            },
        }
    },
    computed:{
        ...mapGetters([
            
        ]),
    },
    methods: {
        ...mapActions([
            'validateIdentity'
        ]),

        async send () {
            event.preventDefault();
            this.$v.$touch();
            if(this.$v.$invalid) return;
            this.loading = true;
            const res = await this.validateIdentity(this.password);
            this.loading = false;

            if (!res) {
                this.error = true;
                return;
            }
            this.$emit('handleIdentity', res);
        },
    }
}
</script>