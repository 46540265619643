import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    terminations: [],
    status: false,
    details: [],
    categories: [],
    comments: []
};

const getters = {
    getTerminations: (state) => state.terminations,
    getDetailsTermination: (state) => state.details,
    getStepper: (state) => state.terminations,
    getTerminationCategories: (state) => state.categories,
    getCommentsTermination: (state) => state.comments,
};

const actions = {
    async fetchTerminations ({commit}, data){   
        const endpoint = API_URL + 'api/v4/terminations/filtered';
        const response = await axios.post(endpoint, data);
        commit('setTerminations', response.data.msg);
    },
    async requestTermination({commit}, data){
        const endpoint = API_URL + 'api/v4/terminations/request';
        const response = await axios.post(endpoint, data).then((res) =>{
            commit('setTermination', true); 
            return res.data;
        });
        return response;
    },
    async updateTermination({commit}, data){
        const endpoint = API_URL + 'api/v4/terminations/update';
        const response = await axios.post(endpoint, data).then((res) =>{
            commit('setTermination', true); 
            return res.data;
        });
        return response;
    },
    async getTerminationRequest({commit}, employee){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/terminations/request/' + employee;
        const response = await axios.get(endpoint).then((res) => {
            commit('setDetails', res.data.msg[0]);
            return res.data;
        })
        return response;
    },
    async fetchTerminationsStepper ({commit}, data){   
        const endpoint = API_URL + 'api/v4/terminations/stepper';
        const response = await axios.post(endpoint, data);
        commit('setTerminations', response.data.msg);
    },
    async addTerminationComment ({commit}, data){
        commit('setLoading', true);  
        const endpoint = API_URL + 'api/v4/terminations/add-comment';
        const response = await axios.post(endpoint, data);
        commit('setLoading', false);
        return response;
    },
    async fetchTerminationCategories ({commit}){
        const endpoint = API_URL + 'api/v4/terminations/get-categories';
        const response = await axios.get(endpoint);
        commit('setCategories', response.data.categories);
    },
    async fetchCommentsTermination({commit}, id){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/terminations/comments/' + id;
        const response = await axios.get(endpoint).then((res) => {
            commit('setComments', res.data.msg);
            return res.data;
        })
        return response;
    },
};

const mutations = {
    setTerminations: (state, terminations) => (state.terminations = terminations),
    setTermination: (state, status) => (state.status = status),
    setDetails: (state, details) => (state.details = details),
    setCategories: (state, categories) => (state.categories = categories),
    setComments: (state, comments) => (state.comments = comments),
};

export default {
    state,
    getters,
    actions,
    mutations
};