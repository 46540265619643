<template>
    <div class="w-64">
        <div class="flex flex-row align-items-center justify-content-start group flex-1 px-2 w-full">
           <svg class="w-6 h-6 group-hover:text-gray-500 group-active:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
           <input type="text" name="employee" id="employee" v-model="employee" pattern="[A-Za-z0-9]+" class="p-2 flex-1 rounded-md hover:text-gray-600 focus:text-gray-600" placeholder="Search Employee...">
           <button type="button"  class="text-red-300" v-if="employee !== null" @click="clear">
               <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
           </button>
       </div>
    </div>
</template>

<script>
import debounce from 'debounce'
export default {
   data(){
       return {
           employee: '',
       }
   },
   methods:{
       clear:function(){
           this.employee = '';
       }
   },
   watch:{
       employee:debounce(function(){
           this.$emit('toggle',{key:'employee',value: this.employee?.replace(/\s/g,'')});
       },1000)
   }
}
</script>
<style scoped>
#employee { 
   text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
   text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
   text-transform: none;
}
</style>