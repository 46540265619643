<template>
    <div>
        <modal :comment="comment_selected" :type="modal_type" @toggle="closeEditModal" v-if="open_modal"/>
        <div class="flex justify-end mb-6">
            <button class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full" @click.prevent="open_modal=true"><i class="bi bi-journal-bookmark-fill mr-2"></i>Add New</button>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="group relative bg-yellow-100 px-4 py-6 rounded-[10px] shadow-sm" v-for="i in getTrainingNotes" :key="i.id"
            :class="{'opacity-50' : i.deleted_at}">
                <div class="flex absolute top-6 right-4 justify-end space-x-3 text-xl pb-6 pl-6 mb-2 bg-gradient-to-bl from-yellow-100 from-40% to-transparent" v-if="i.deleted_at"><i class="bi bi-trash3-fill"></i></div>
                <div class="hidden group-hover:flex absolute top-6 right-4 justify-end space-x-3 text-xl pb-6 pl-6 mb-2 bg-gradient-to-bl from-yellow-100 from-40% to-transparent" v-if="!i.deleted_at">
                    <a @click.prevent="confirmDelete(i)" href="#" class="inline-block !no-underline !text-gray-900 hover:opacity-50"><i class="bi bi-trash3"></i></a>
                    <a @click.prevent="openEditModal(i)" href="#" class="inline-block !no-underline !text-gray-900 hover:opacity-50"><i class="bi bi-pencil-square"></i></a>
                </div>
                <div class="hidden group-hover:flex absolute top-6 right-4 justify-end space-x-3 text-xl pb-6 pl-6 mb-2 bg-gradient-to-bl from-yellow-100 from-40% to-transparent" v-else>
                    <a @click.prevent="confirmRecover(i)" href="#" class="inline-block !no-underline !text-gray-900 hover:opacity-50"><i class="bi bi-arrow-counterclockwise"></i></a>
                </div>
                <p class="text-right text-gray-500 leading-[1.2] mb-3 text-xs">{{ i.created_at }} by {{ i.created_by }}</p>
                <p class="text-left text-gray-900 leading-[1.2] m-0">{{ i.comment }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import Modal from '@/views/recruitment/pages/trainings/modals/TrainingNotesModal';
export default {
    components: {
        Modal
    },
    data(){
        return {
            type: 'training_note',
            modal_type: 'new',
            comment_selected: '',
            note_id_selected: '',
            open_modal: false,
            notes: []
        }
    },
    computed: {
        ...mapGetters([
            'getTrainingNotes'
        ])
    },
    methods:{
        ...mapActions([
            'fetchTrainingNotes',
            'updateTrainingNote',
            'sendTrainingNote',
            'deleteTrainingNote',
            'restoreTrainingNote',
        ]),

        async confirmDelete (index) {
            this.$confirm("You can recover it before sending", "Do you want to delete this?", "question").then((result) => {
                if (result){                
                    this.note_id_selected = index.id;
                    this.deleteNote();
                }
            })
        },

        async deleteNote () {
            await this.deleteTrainingNote(this.note_id_selected);
            await this.keepAlive();
        },

        confirmRecover (index) {
            this.$confirm("", "Do you want to restore this note?", "question").then((result) => {
                if (result){                
                    this.note_id_selected = index.id;
                    this.recoverNote();
                }
            })
        },

        async recoverNote () {
            await this.restoreTrainingNote(this.note_id_selected);
            await this.keepAlive();
        },

        openEditModal (note) {
            this.note_id_selected = note.id;
            this.comment_selected = note.comment;
            this.modal_type = 'edit';
            this.open_modal = true;
        },

        async closeEditModal (note) {
            if(note.confirmation) {
                if(note.modal_type === 'edit'){
                    let obj = {
                        note_id: this.note_id_selected,
                        title: note.title,
                        comment: note.comment
                    }
                    await this.updateTrainingNote(obj);
                } else {
                    await this.sendTrainingNote(note);
                }
                await this.keepAlive();
            }
            this.note_id_selected = '';
            this.comment_selected = '';
            this.modal_type = 'new';
            this.open_modal = false;
        },

        keepAlive () {
            let obj = {
                class_id: this.$route.params.id,
                note_type: this.type
            }
            this.fetchTrainingNotes(obj);
        }
    },
    created(){
        this.keepAlive();
    }
}
</script>