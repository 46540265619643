<template>
    <select v-model="form.site" class="bg-white w-full p-3 rounded-lg shadow-sm mb-2" required >
        <option value="">All Sites</option>
        <option v-for="item in getLocations" :value="item" :key="item">{{item}}</option>
    </select>
</template>
<script>
import Debounce         from 'debounce';
import {mapGetters, mapActions} from 'vuex';
import permissions from '@/config/mixins'

export default {
    data(){
        return{
            filtersLoaded: false,
            form:{
                site:'',
            }
        }
    },
    mixins:[permissions],
    computed:{
        ...mapGetters([
            'getLocations',
        ])
    },
    methods:{
        ...mapActions([
            'fetchSites',
        ]),

        updateFilters:Debounce(function(res){    
            this.form[res.key] = res.value?.toUpperCase();
        },350),
    },
    watch:{
        form:{
            handler(){
                this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    mounted(){
        this.$emit('toggle',this.form);
    },
    created(){
        this.filtersLoaded = false;
        this.fetchSites().then(() => {
            this.filtersLoaded = true;
        })
    }

}
</script>