<template>
    <div class="transferModalContainer">
        <div class="d-block text-center">
            <h3>Transfer Employee</h3>
        </div>
        <div v-if="isLoading" class="row">
            <div class="col text-center">
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>
        <div v-else>
            <form @submit="transferSubmit">
                <div id="current_information" class="flex flex-row space-x-2 w-full">
                    <div class="pt-3 relative w-1/4">
                        <input disabled class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full" placeholder="not filled" readonly :value="profileInfo.Campaign">
                        <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Campaign</span>
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input disabled class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full" placeholder="not filled" readonly :value="profileInfo.LOB">
                        <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">LOB</span>
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input disabled class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full" placeholder="not filled" readonly :value="profileInfo.ReportTo">
                        <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Report To</span>
                    </div>
                    <div class="pt-3 relative w-1/4">
                        <input disabled class="text-sm text-gray-600 font-sans font-medium bg-gray-100 p-2 rounded-md text-center uppercase w-full" placeholder="not filled" readonly :value="profileInfo.Title">
                        <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase whitespace-nowrap">Title</span>
                    </div>
                </div>

                <div class="space-y-2">
                    <div name="transfer_type">
                        <div class="relative mb-2 sm:w-full   px-2 flex flex-col group">
                            <label class="text-gray-700 font-semibold text-left text-md ml-2 block group-focus:text-gray-600">
                                Transfer Type
                            </label>
                            <div class="flex flex-row">
                                <div class="col flex text-center justify-content-center align-middle p-1">
                                    <input type="radio" v-model="transferData.transferType" name="transfer_type" class="my-auto mr-1" value="Supervisor" required @change="loadSupervisors"> Supervisor<br>
                                </div>
                                <div class="col flex text-center justify-content-center align-middle p-1">
                                    <input type="radio" v-model="transferData.transferType" name="transfer_type" class="my-auto  mr-1" value="Program" required @change="loadSupervisors"> Program
                                </div>
                                <div class="col flex text-center justify-content-center align-middle p-1">
                                    <input type="radio" v-model="transferData.transferType" name="transfer_type" class="my-auto  mr-1" value="Project" required @change="loadSupervisors"> Project
                                </div>
                            </div>
                        </div>
                    </div>

                    <div name="effective_date" v-if="isDated">
                        <div class="relative mb-2 sm:w-full   px-2 flex flex-col group">
                            <label class="text-gray-700 font-semibold text-left text-md ml-2 block group-focus:text-gray-600">
                            Effective date
                            </label>
                            <input type="date" :min="minDate" class="mx-2 border rounded-md border-gray-200 px-3 py-1 focus:border-blue-400" required v-model="effective_date">
                        </div>
                    </div>

                    <div id="ticket_id" v-if="isTransferProgram">
                        <div class="row">
                            <label class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Select the Ticket ID <label class="text-red-500">*</label>
                            </label>
                        </div>
                        <div class="row mb-3">
                            <div class="col flex text-center justify-content-center align-middle p-1">
                                <multiselect :disabled="!showTicketSearch" class="flex justify-content-center text-left mx-4" v-model="transferData.ticket_id" :custom-label="customFormat" :options="filteredTickets" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="false" placeholder="Select a Ticket" label="ID" track-by="ID"></multiselect>
                            </div>
                        </div>
                    </div>



                    <!-- If Program is selected -->
                    <div v-if="transferData.transferType === 'Program'">
                        <!-- Sites -->
                        <div name="sites">
                            <div class="relative mb-2 sm:w-full px-2 flex flex-col group">
                            <span
                                class="text-gray-700 font-semibold text-left text-md ml-2 block group-focus:text-gray-600">
                                Site
                            </span>
                                <div>
                                    <select @change="LoadLob"
                                            class="border w-full rounded-full py-2 px-3"
                                            v-model="transferData.site" required>
                                        <option value="" hidden>Select a Site</option>
                                        <option v-for="site in getSites[0].children.filter(x => x.isActive)" :key="site.id" :value="site">{{site.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Departments -->
                        <div name="departments">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Departments
                            </span>
                            <div>
                                <select @change="loadCampaigns"
                                        class="border w-full rounded-full py-2 px-3"
                                        v-model="transferData.department" required>
                                    <option value="" hidden>Select a Department</option>
                                    <option v-for="department in getOrganizationStructure[0].children.filter(x => x.isActive)"
                                            :key="department.id" :value="department" >{{department.label}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Campaigns -->
                        <div name="campaign">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Campaign
                            </span>
                            <div>
                                <select @change="LoadLob" :disabled="!transferData.site"
                                class="border w-full rounded-full py-2 px-3"
                                v-model="transferData.campaign" required>
                                    <option value="" hidden>Select a Campaign</option>
                                    <option v-for="campaign in getCampaigns" :key="campaign.id" :value="campaign" >{{campaign.label}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- New LOB -->
                        <div name="lob">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                LOB
                            </span>
                            <div>
                                <select :disabled="!displayLob"
                                :class="{ 'bg-gray-100' : !displayLob }"
                                class="border w-full rounded-full py-2 px-3"
                                v-model="transferData.lob" required>
                                    <option v-if="displayLob && getLob.length === 0" value="" hidden>No LOBs match Campaign and Site</option>
                                    <option v-else value="" hidden>Select an LOB</option>
                                    <option v-for="lob in getLob" :key="lob.id" :value="lob">{{lob.label}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Report To -->
                        <div name="report_to">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Report To
                            </span>
                            <div>
                                <select :disabled="!displayReportTo"
                                :class="{ 'bg-gray-100' : !displayReportTo }"
                                class="border w-full rounded-full py-2 px-3"
                                v-model="transferData.reportTo" required>
                                    <option value="" hidden>Select a Report To...</option>
                                    <option v-for="reportTo in getReportTo" :key="reportTo.Team_id" :value="reportTo.Emp_id">{{reportTo.Emp_id}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- If Supervisor is selected -->
                    <div name="report_to" v-if="transferData.transferType === 'Supervisor'">
                        <!-- Report To -->
                        <div name="report_to">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Report To
                            </span>
                            <div>
                                <select :disabled="!displayReportTo"
                                :class="{ 'bg-gray-100' : !displayReportTo }"
                                class="border w-full rounded-full py-2 px-3"
                                v-model="transferData.reportTo" required>
                                    <option value="" hidden>Select a Report To...</option>
                                    <option v-for="reportTo in getReportTo" :key="reportTo.Team_id" :value="reportTo.Emp_id">{{reportTo.Emp_id}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- If Project is selected -->
                    <div name="project" v-if="transferData.transferType === 'Project'">
                        <div name="change_project">
                            <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                                Project
                            </span>
                            <div>
                                <select v-model="transferData.project" class="border w-full rounded-full py-2 px-3" required>
                                    <option value="" disabled hidden selected>Select a Project</option>
                                    <option value="No_Project">No Project</option>
                                    <option v-for="project in getProjectDefinitions" :key="project.id" :value="project.label">{{project.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- Comment Transfer -->
                    <div name="comment">
                        <span class="text-gray-700 font-semibold text-left text-md ml-3 block group-focus:text-gray-600">
                            Comment
                        </span>
                        <textarea v-model="transferData.Comment" placeholder="Please add a comment..." rows="3" max-rows="3" class="w-full border rounded-md py-2 px-3 resize-none" required></textarea>
                    </div>

                </div>

                <button type="submit" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full">Save</button>
            </form>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import auth                               from "@/config/user"
export default {
    data(){
        return{
            striped: true,
            animate: true,
            percentage: 0,
            isHide: false,
            callbacks: 0,
            transferData: {
                transferType: "",
                site: "",
                department: "",
                campaign: "",
                lob: "",
                reportTo: "",
                ticket_id: null,
                project: "",
                Comment: "",
            },

            filteredTickets: [],
            showTicketSearch: false,

            isLoading: true,
            displayTitle:    false,
            displayLob:      false,
            displayReportTo: false,
            isLoadingTitle: false,
            isLoadingReportTo: false,
            isLoadingLOB: false,
            effective_date:this.$moment().format('YYYY-MM-DD'),
            minDate:this.$moment().add('days', 1).format('YYYY-MM-DD')
        }
    },
    computed:{
        ...mapState([
            "definitions",
            "employees"
        ]),
        ...mapGetters([
            "getCampaigns",
            "getTitles",
            "getReportTo",
            "getLob",
            "getTickets",
            "getProjectDefinitions",
            "getSites",
            "getOrganizationStructure"
        ]),
        isTransferProgram(){
            return this.transferData.transferType?.toLowerCase() === 'program';
        },
        isDated(){
            return ['program', 'supervisor'].includes(this.transferData.transferType?.toLowerCase());
        },
    },
    props:{
        profileInfo: Object,
    },
    methods:{
        ...mapActions([
            "fetchEmployeeTickets",
            "fetchCampaignsByDepartmentName",
            "fetchLobByCampaignSite",
            "fetchReportToByCampaign",
            "fetchReportToByCampaignName",
            "sendTransfer",
            "fetchProjectsByCampaignName",
            "setProject",
            "fetchSiteDefinitions",
            "fetchOrganizationStructure"
        ]),

        loadSupervisors: function(){
            this.displayReportTo = false;
            if(this.transferData.transferType === "Supervisor"){
                this.displayReportTo = false;
                this.fetchReportToByCampaignName(this.profileInfo.Campaign).then(()=>{
                    this.displayReportTo = true;
                });
            }else{
                this.transferData.reportTo = "";
            }
        },

        loadReportTo: function(){
            this.displayReportTo = false;
            this.fetchReportToByCampaign(this.transferData.campaign.id).then( ()=>{
                this.displayReportTo = true;
            });
        },

        LoadLob: function(){
            if ( [this.transferData.site, this.transferData.campaign].includes("") ) {
                return;
            }

            this.displayLob = false;
            this.fetchLobByCampaignSite({
                location: this.transferData.site.id, 
                campaign: this.transferData.campaign.id
            }).then(()=>{
               this.displayLob = true;
            });
            this.loadReportTo();
        },

        loadCampaigns() {
            this.fetchCampaignsByDepartmentName(this.transferData.department.label)
        },

        customFormat({ID, subCategoryName, ReportedBy}){
            return `ID: ${ID} , ${subCategoryName} reported by ${ReportedBy}
            `;
        },

        filterItems: function (items){
            const result = items.filter(item =>
                item?.TicketSubCategoryId == '43'
            );
            this.filteredTickets = result;
            this.showTicketSearch = true;
        },

        transferSubmit(){
            event.preventDefault();

            if(!this.transferData.ticket_id && this.isTransferProgram){
                this.$fire({
                    type: 'warning',
                    title: 'Missing information',
                    text: 'Select the ticket related to this transfer, if does not exist, please submit one',
                    timer: 3500
                })
                return;
            }

            this.isLoading = true;
            if ( this.transferData.transferType === 'Project' ) {
                const payload = {
                    id: this.profileInfo.RecID,
                    project: this.transferData.project,
                }
                this.setProject(payload).then( (response)=>{
                this.isLoading = false;
                if(response.status === 200){
                    this.$fire({
                        type: 'success',
                        title: 'The employee has been transfer successfully',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        this.$emit('closeTransfer', true);
                    });
                }
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: 'Something went wrong',
                    text: 'Please report this issue to a system administrator',
                    showConfirmButton: true
                })
            }).finally(
                    () => {this.isLoading = false;}
                );
                return;
            }

            let EmployeeID = auth.currentUser.EmpID;
            let infoTransfer = {
                RecID:           this.profileInfo.RecID,
                employee_id:     this.profileInfo.EmpID,
                transfer_type:   this.transferData.transferType,
                site:            !this.isTransferProgram ? this.profileInfo.Site : this.transferData.site.label,
                department:      !this.isTransferProgram ? this.profileInfo.Department : this.transferData.department.label,
                campaign:        !this.isTransferProgram ? this.profileInfo.Campaign : this.transferData.campaign.label,
                title:           this.profileInfo.Title,
                lob:             !this.isTransferProgram ? this.profileInfo.LOB : this.transferData.lob.label,
                report_to:       this.transferData.reportTo,
                ticket_id:       this.transferData.ticket_id?.ID ?? null,
                comment:         this.transferData.Comment,
                effective_date:  this.effective_date,
                rehirable:       'Yes', //attrition helper
                voluntary:       'Voluntary',
                type:            'Transfer',
                reason:          this.transferData.transferType,
                sub_reason:      this.transferData.transferType,
                editBy:         EmployeeID
            };

            this.sendTransfer(infoTransfer).then( (response)=>{
                this.isLoading = false;
                if(response.status == 200){
                    this.$fire({
                        type: 'success',
                        title: 'The employee has been transfer successfully',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        this.$emit('closeTransfer', true);
                    });
                }
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: 'Something went wrong',
                    text: 'Please report this issue to a system administrator',
                    showConfirmButton: true
                })
            });
       },
    },
    created () {
        Promise.all([
            this.fetchOrganizationStructure(),
            this.fetchSiteDefinitions(),
            this.fetchEmployeeTickets(this.profileInfo.EmpID),
            this.fetchProjectsByCampaignName(this.profileInfo.Campaign),
            ]).then(
            () => {
                this.isLoading = false;
                this.filterItems(this.getTickets);
            }
        )
    }
}
</script>
<style lang="scss" scoped>
.formTransfer{
    display: block !important;
}
.hide{
    display: none;
}

.text-xxs{
    font-size: 0.6rem;
}
.text-sm{
    font-size: 0.9rem;
}
.spiner_small{
    width: 1rem;
    height: 1rem;
}
</style>