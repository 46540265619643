<template>
    <div class="main p-4">
        <div class="grid grid-cols-2 gap-3">
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium lowercase bg-transparent text-center w-full" placeholder="not filled" :value="workEmail">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Work Email</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Title">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Title</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Department">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Department</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Campaign">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Campaign</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.LOB">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">LOB</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Team">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Team</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.ReportTo">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Reporting to</span>
                </div>
            </div>
            
            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium bg-transparent text-center w-full" placeholder="not filled" :value="hireDate">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Hire Date</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="startDate">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Start Date</span>
                </div>
            </div>

            <div class="flex">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="certificationDate">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Certification Date</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="terminationDate">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Termination Date</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="lastHireDate">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Last Hire Date</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Rehirable">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Rehirable</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.Voluntary">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Voluntary</span>
                </div>
            </div>

            <div class="flex" :hidden="!ADMIN_VIEW">
                <div class="border-input px-3 pt-3 pb-1 relative w-full">
                    <input disabled class="text-sm text-gray-600 font-sans font-medium uppercase bg-transparent text-center w-full" placeholder="not filled" :value="infoEmployee.NomipaqID">
                    <span class="text-xxs text-gray-500 mr-auto absolute top-1 left-3 uppercase">Nomipaq</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters}   from 'vuex'
export default {
    props: {
        role:{
            default: 'e'
        }
    },
    computed: {
        ...mapGetters(['infoEmployee','loadingFlag']),
        
        workEmail(){
            return this.infoEmployee.Email;
        },

        hireDate(){
            return this.infoEmployee.HireDate?.slice(0, 10);
        },

        lastHireDate(){
            return this.infoEmployee.LastHireDate?.slice(0, 10);
        },

        certificationDate(){
            return this.infoEmployee.CertificationDate?.slice(0, 10);
        },

        startDate(){
            return this.infoEmployee.StartDate?.slice(0, 10);
        },

        terminationDate(){
            return this.infoEmployee.TerminationDate?.slice(0, 10);
        },

        ADMIN_VIEW (){
            return this.role === 'a';
        },

        USER_VIEW (){
            return this.role === 'e';
        }
    },
}
</script>
<style scoped>
.border-input{
    border-bottom: 1px solid rgb(150, 150, 150);
}
.text-xxs{
    font-size: 0.6rem;
}
.text-sm{
    font-size: 0.9rem;
}
.main{
    width: 100%;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: uppercase;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: uppercase;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: uppercase;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: uppercase;
}
::placeholder { /* Recent browsers */
    text-transform: uppercase;
}
</style>
