<template>
<div class="main">
  <b-container fluid>
      <b-row>
          <b-col align-self="center" class="mt-2 rounded shadow-sm">
              <h1 class="">Mail Lists Management</h1> 
          </b-col>
      </b-row>
      <b-row >
        <b-col align-self="center" class="mt-2" >
          <div>
              <div>
              <loading :active.sync="getFlag" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
              <div id="EditTable" class="userTable">
                <b-container fluid class="TableSearch">
                  <b-row>
                    <b-col>
                      <p style="font-style:italic">“After click the send button you will send an email with this table”</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col align-self="center" class="mt-2 rounded shadow-sm" md="12" lg="12" xl="12">
                    <!-- <b-input-group class="mt-3 mb-3" size="sm">
                      <b-form-input v-model="keyword" @keyup.enter="filterSearch" placeholder="Search by First Name" id='filterKeyword' type="text"></b-form-input>
                      <button type="button" :disabled='isDisabled' @click='clearSearch' class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Clear Search</button>  
                    </b-input-group> -->
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center" scope="col">EmpID</th>
                            <th class="text-center" scope="col">First Name</th>
                            <th class="text-center" scope="col">Last Name</th>
                            <th class="text-center" scope="col">Project</th>
                            <th class="text-center" scope="col">L.O.B</th>
                            <th class="text-center" scope="col">Location</th>
                            <th class="text-center" scope="col">Created Date</th>
                          </tr>
                        </thead>
                        <tbody v-if="getNewHires.length">
                          <tr :id="employee.RecID" v-for="employee in getNewHires" :key="employee.RecID">
                              <td>{{employee.EmpID}}</td>
                              <td>{{employee.FirstName}}</td>
                              <td>{{employee.LastName1}}</td>
                              <td>{{employee.Project}}</td>
                              <td>{{employee.LOB}}</td>
                              <td>{{employee.Location}}</td>
                              <td>{{employee.created_at | moment("MMMM Do YYYY, h:mm a")}}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="7">No records found.</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                     <b-col align-self="end">
                       <button class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded mt-3" @click="sendList">Send Notifications</button>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        data() {
            return {
                keyword: '',
                fullPage: true,
                color: "#F2F2F2",
                bgcolor: "#000000",
                loader: 'dots',
                isDisabled: true,
            }
        },
        components: {
            Loading
        },
        computed:{
            ...mapState(['mail']),
            ...mapGetters(['getNewHires','getFlag'])
        },
        created(){
            this.$store.dispatch('fetchNewHiresNotifications');
        },
        methods:{
            ...mapActions(['sendNewHires']),
            sendList: function(){ 
                this.sendNewHires(this.getNewHires);
            }
        },
    }
</script>
<style scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>
