<template>
  <a href="#" class="block text-left text-ellipsis text-nowrap hover:bg-gray-100 px-3 py-2 rounded" :class="isActive ? 'bg-gradient-to-r from-blue-800 to-blue-500 text-white':'text-body'" @click.prevent="goToTarget">
    <span class="inline-block">
      <slot></slot>
    </span>
    <span class="inline-block ml-2">{{title}}</span>
    <span v-if="badge>0" class="flex items-center justify-center w-5 h-5 rounded-full bg-red-600 font-semibold text-white text-xs float-right">{{ badge }}</span>
  </a>
</template>

<script>
export default {
name: "SidebarLink",
  props:{
    active:{
      default:false,
      type: Boolean
    },
    link:{
      default:'home',
      type: String
    },
    title:{
      default:'Home',
      type: String
    },
    badge: {
      default: 0,
      type: Number
    }
  },
  computed:{
    isActive:function(){
      return this.$route.name === this.link;
    }
  },
  methods:{
    goToTarget:function(){
      this.$router.push({name:this.link});
    }
  },
  data(){
    return {
    }
  }
}
</script>