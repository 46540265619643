<template>
      <table class="min-w-full table-auto bg-white divide-y divide-blue-50">
              <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-blue-50" v-if="activeHeader">
                        
                    </th>
                    <th v-for="date,index in dates" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div class="text-center">
                        <span class="font-semibold text-gray-700 text-sm">
                            {{getDateRange[index]}}
                        </span>
                        <span class="block text-gray-700 font-xs font-medium mt-1 uppercase">
                            {{date}}
                        </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <slot></slot>
        </table>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            dates:['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
        }
    },
    props:{
        activeHeader: {
            default: false,
        }
    },
    computed:{
        ...mapGetters(['getDateRange'])
    },
}
</script>