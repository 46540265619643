<template>
  <div class="main">
    <div class="container-fluid">
       
      <div class="row">
        <div class="col-12 shadow bg-white rounded p-3 mb-5">
          <div class="row">
            <div class="col-12">
              <AMHoursReviewTable></AMHoursReviewTable>
            </div>
          </div>          
        </div>
      </div>      
    </div>
  </div>
</template>
<script>
import AMHoursReviewTable from '@/views/components/Clocker/tables/AMHoursReviewTable';

export default {
  components: {
    AMHoursReviewTable 
  },
  mounted(){
    let title = 'Account Manager Hours Review';
    this.$emit('set-title',title);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>