<template>
<div class="relative inline-block text-left">
    <button type="button" class="inline-flex justify-center w-full px-3 py-2" 
    @click="isOpen = !isOpen" id="multiple-dropdown" aria-haspopup="true" aria-expanded="true">
        <slot>Select...</slot>
        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z" clip-rule="evenodd" />
        </svg>
    </button>
    
    <transition name="bounce">
        <div class="absolute left-0 rounded-md shadow-lg bg-white p-3 max-h-64 max-w-md w-56 overflow-y-auto overflow-x-hidden z-10" v-show="isOpen" role="menu">
            <div class="text-right my-2">
                <button class="active:text-gray-700" @click="selection">
                    {{ action }} All ({{ quantity }})
                </button>
            </div>
            <div class="grid grid-cols-1 gap-1">
                <label class="flex items-center space-x-2 cursor-pointer pointer-events-auto" v-for="(item, index) in options" :key="index">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer
                    accent-centris-secondary"
                    :value="item" v-model="selected">
                    <span class="text-gray-700 truncate flex-1 uppercase" :title="item">{{ item }}</span>
                </label>
            </div>
        </div>
    </transition>
</div>
</template>
<script>
export default {
    data() {
        return {
            isOpen: false,
            selected: this.value
        }
    },
    props: {
        options: {
            required: true,
            default: [
                "option A",
                "option B",
                "option C"
            ]
        },
        value: {
            required: false,
            default: function() {
               return [];
            }
        },
    },
    computed:{
        action () {
            return this.selected?.length > 0 ? 'Deselect' : 'Select';
        },
        quantity (){
            return this.selected?.length;
        }
    },
    methods: {
        closeMenu (event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
                this.$emit('input', this.selected);
            }
        },
        selection () {
            if(this.selected?.length > 0) {
                this.selected = [];
            } else {
                this.selected = this.options;
            }
        }
    },
    watch:{
        isOpen(value) {
            if (value) {
                this.$nextTick(() => {
                window.addEventListener('click', this.closeMenu);
                });
            } else {
                window.removeEventListener('click', this.closeMenu);
            }
        },
        
    },
    mounted(){
        window.addEventListener('click', this.closeMenu);
    },
}
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in .3s;
}
.bounce-leave-active {
  animation: bounce-in .3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>