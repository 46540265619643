<template>
<div>
  <!-- DISCONTINUED -->
  <div v-if="this.items.length < 1">
    <table class="table table-sm table-hover mb-5 mt-5">
            <thead class="thead-dark">
              <tr>
                  <th class="text-center">Employee</th>
                  <th class="text-center">Campaign</th>
                  <th class="text-center">L.O.B</th>
                  <th class="text-center">Team</th>
                  <th class="text-center">1 Day Ago</th>
                  <th class="text-center">2 Days Ago</th>
                  <th class="text-center">3 Days Ago</th>
                  <th class="text-center">4 Days Ago</th>
                </tr>
            </thead>
            <tbody>
              <tr><td colspan="9">No records found.</td></tr>
            </tbody>
          </table>
  </div>
  <div v-else>
    <b-table :head-variant="headVariant" :busy="isBusy" id="table-transition-example" :items="items" :fields="fields" striped small primary-key="emp_id" hover :tbody-transition-props="transProps" caption-top>}
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
      <template v-slot:head(emp_id)="data">
        <span class="text-center">{{ data.label}}</span>
      </template>
      <template v-slot:cell(emp_id)="data">
        <span class="font-weight-bold">{{data.value}}</span>
      </template>
      <template v-slot:cell(Now_1)="data">
        <div v-if="data.value == 'Pending' || data.value == 'approved' || data.value == 'In_revision' || data.value == 'Validation'">
          <b-link class="font-weight-bold text-capitalize" :class="{ 'text-danger': data.value == 'Pending', 'text-success': data.value=='approved', 'text-warning': data.value=='In_revision', 'text-primary': data.value=='Validation' }" v-on:click="detailedHours(data)">{{ data.value }}</b-link>
        </div>
        <div v-if="data.value == 'No-Data'">
          <b-link size="sm" class="font-weight-bold text-capitalize text-danger" @click="info(data, data.index, $event.target)">{{ data.value }}</b-link>
        </div>
        <div class="font-weight-bold text-capitalize text-muted" v-if="data.value != 'No-Data' && data.value != 'Pending' && data.value != 'approved' && data.value != 'In_revision' && data.value != 'Validation'">
          <label>{{ data.value }}</label>
        </div>
      </template>
      <template v-slot:cell(Now_2)="data">
        <div v-if="data.value == 'Pending' || data.value == 'approved' || data.value == 'In_revision' || data.value == 'Validation'">
          <b-link class="font-weight-bold text-capitalize" :class="{ 'text-danger': data.value == 'Pending', 'text-success': data.value=='approved', 'text-warning': data.value=='In_revision', 'text-primary': data.value=='Validation' }" v-on:click="detailedHours(data)">{{ data.value }}</b-link>
        </div>
        <div v-if="data.value == 'No-Data'">
          <b-link size="sm" class="font-weight-bold text-capitalize text-danger" @click="info(data, data.index, $event.target)">{{ data.value }}</b-link>
        </div>
        <div class="font-weight-bold text-capitalize text-muted" v-if="data.value != 'No-Data' && data.value != 'Pending' && data.value != 'approved' && data.value != 'In_revision' && data.value != 'Validation'">
          <label>{{ data.value }}</label>
        </div>
      </template>
      <template v-slot:cell(Now_3)="data">
        <div v-if="data.value == 'Pending' || data.value == 'approved' || data.value == 'In_revision' || data.value == 'Validation'">
          <b-link class="font-weight-bold text-capitalize" :class="{ 'text-danger': data.value == 'Pending', 'text-success': data.value=='approved', 'text-warning': data.value=='In_revision', 'text-primary': data.value=='Validation' }" v-on:click="detailedHours(data)">{{ data.value }}</b-link>
        </div>
        <div v-if="data.value == 'No-Data'">
          <b-link size="sm" class="font-weight-bold text-capitalize text-danger" @click="info(data, data.index, $event.target)">{{ data.value }}</b-link>
        </div>
        <div class="font-weight-bold text-capitalize text-muted" v-if="data.value != 'No-Data' && data.value != 'Pending' && data.value != 'approved' && data.value != 'In_revision' && data.value != 'Validation'">
          <label>{{ data.value }}</label>
        </div>
      </template>
      <template v-slot:cell(Now_4)="data">
        <div v-if="data.value == 'Pending' || data.value == 'approved' || data.value == 'In_revision' || data.value == 'Validation'">
          <b-link class="font-weight-bold text-capitalize" :class="{ 'text-danger': data.value == 'Pending', 'text-success': data.value=='approved', 'text-warning': data.value=='In_revision', 'text-primary': data.value=='Validation' }" v-on:click="detailedHours(data)">{{ data.value }}</b-link>
        </div>
        <div v-if="data.value == 'No-Data'">
          <b-link size="sm" class="font-weight-bold text-capitalize text-danger" @click="info(data, data.index, $event.target)">{{ data.value }}</b-link>
        </div>
        <div class="font-weight-bold text-capitalize text-muted" v-if="data.value != 'No-Data' && data.value != 'Pending' && data.value != 'approved' && data.value != 'In_revision' && data.value != 'Validation'">
          <label>{{ data.value }}</label>
        </div>
      </template>
    </b-table>
    <b-modal :id="infoModal.id" title="Incidences Module" hide-footer @hide="resetInfoModal">
      <div>
        <NoDataModal :agentInfo="infoModal"></NoDataModal>
      </div>
    </b-modal> 
  </div>
</div>
</template>
<script>
  import {mapGetters,mapState } from 'vuex'
  import 'vue-loading-overlay/dist/vue-loading.css';
  import NoDataModal from '@/views/components/Modal/NoDataModal'
  import moment       from 'moment';
  export default {
    components: {
      NoDataModal
    },
    data() {
      return {
        TodayIs: null,
        
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        headVariant: 'dark',
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        showModal: false,
        isBusy: true,

        yesterday: null,
        twodaysago: null,
        threedaysago: null,
        fourdaysago: null,
        infoModal: {
          id: 'info-modal',
          title: '',
          content:'',
          ModalCampaign: null,
          ModalEmpID: null,
          ModalDate: null,
          ModalDateLabel: null,
        },
        transProps: {
          // Transition name
          name: 'flip-list'
        },
        items: [],

        fields: [
          { key: 'emp_id',    label: 'Employee', sortable: true, class: 'text-center' },
          { key: 'Campaign',  label: 'Campaign' , class: 'text-center' },
          { key: 'LOB',       label: 'L.O.B' , class: 'text-center',sortable: true},
          { key: 'Team',      label: 'Team', class: 'text-center'},
          { key: 'Now_1',     label: '1 Day Ago' , class: 'text-center'},        
          { key: 'Now_2',     label: '2 Days Ago' , class: 'text-center'},
          { key: 'Now_3',     label: '3 Days Ago', class: 'text-center' },
          { key: 'Now_4',     label: '4 Days Ago', class: 'text-center' },
        ]

      }
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters(['getApprovalHours','getLoadingTable'])
    },
    created(){
      this.yesterday = moment().add(-1, 'days').format("MMM Do");
      this.twodaysago = moment().add(-2, 'days').format("MMM Do");
      this.threedaysago = moment().add(-3, 'days').format("MMM Do");
      this.fourdaysago = moment().add(-4, 'days').format("MMM Do");
      // 
      this.$store.dispatch('fetchMyApprovalHours').then(()=>{
        this.isBusy = false;
      });
    },
    watch:{
      getApprovalHours: function(arrayObject){
        this.items = arrayObject.events;
        this.isBusy = false;
      },
      getLoadingTable: function(loadStatus){
        this.isBusy = loadStatus;
      }
    },
    methods:{
      detailedHours: function(data){
        this.$router.push({  path: '/clocker/supervisor/'+data.item.emp_id +'/'+ data.field.key +'/detailedDay'});
      },
      info(item, index, button) {
        this.infoModal.title          = `Row index: ${index}`
        this.infoModal.content        = JSON.stringify(item, null, 2)
        this.infoModal.ModalEmpID     = item.item.emp_id
        this.infoModal.ModalDate      = item.field.key
        this.infoModal.ModalDateLabel = item.field.label
        this.infoModal.ModalCampaign  = item.item.Campaign
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },

      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  } 
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
  .underline{
      background-color: #007bff !important;
      color: white;
  }
  table#table-transition-example .flip-list-move {
    transition: transform 1s;
  }
  .table thead th {
    text-align: center !important;
  }
</style>