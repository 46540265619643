<template>  
    <div class="p-3 bg-white rounded-md">

        <div v-if="isLoading" class="bg-white round-md shadow-md p-3">
            <div class="text-center">
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <div v-else class="bg-white round-md shadow-md p-3">

            <form v-if="showFeedback" action="#" autocomplete="off" @submit.prevent="sendTheAnswers">
                <!-- Title and description of the survey -->
                <div class="text-center justify-center items-center d-block bg-gray-100">
                    <h2 class="font-sans text-4xl tracking-wide font-bold text-gray-700 capitalize">{{getFeedbackInfo.feedbackName}}</h2>
                </div>
                
                <!-- Agent information -->
                <div class="flex text-center justify-center items-center">
                    <div class="bg-green-100 p-3 rounded-md text-center justify-center items-center d-block mt-2 mb-2 text-gray-700">Feedback to: {{profileInfo}}</div>
                </div>
                
                <!-- questions here -->
                <div v-for="(question, Qindex) in getFQuestionsInfo" :key="Qindex" :id="question.id" class="my-3 align-items-center justify-content-start ml-4 mr-4">
                    
                    <div v-if="isVisible(question, Qindex)">
                        <div class="mb-1 bg-gray-100 border-2 border-gray-200" :id="'question'+Qindex">
                            {{question.questionLabel}}
                        </div>
                        <!-- Text -->
                        <div v-if="question.answerType == 'text'" class="flex flex-col mb-3">
                            <input required type="text" @change="editTextAnswer(Qindex, $event)" :id="'answer'+Qindex" value="" placeholder="Type your answer..." 
                            class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 my-1">
                        </div>
                        <!-- Number -->
                        <div v-if="question.answerType == 'number'" class="flex flex-col mb-3">
                            <input required type="number" @change="editTextAnswer(Qindex, $event)" :id="'answer'+Qindex" value="" placeholder="Type your answer..." 
                            class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 my-1">
                        </div>
                        <!-- Duration -->
                        <div v-if="question.answerType == 'duration'" class="flex flex-col mb-3">
                            <div :id="'answer'+Qindex" class="flex my-1">
                            <vue-timepicker @change="editDurationAnswer(Qindex, $event)" :id="'answer'+Qindex"
                            class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 mr-2" format="HH:mm:ss" close-on-complete></vue-timepicker>
                            </div>
                        </div>
                        <!-- Dropdown -->
                        <div v-if="question.answerType == 'dropdown'" class="flex flex-col mb-3">
                            <select required @change="editDropAnswer(Qindex, $event)" :id="'answer'+Qindex"
                            class="ml-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 h-7 my-1">
                                <optgroup label="Select an answer...">                
                                    <option hidden value="0">Select an answer...</option>
                                    <option v-for="(Answer, index) in question.question_options" :value="Answer.points" :key="index">{{Answer.optionLabel}}</option>
                                </optgroup>
                            </select>
                        </div>
                        <!-- Date -->
                        <div v-if="question.answerType == 'date'" class="flex flex-col mb-3">
                            <input required type="date" @change="editDateAnswer(Qindex, $event)" :id="'answer'+Qindex" class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700">
                        </div>
                        <!-- Time -->
                        <div v-if="question.answerType == 'time'" class="flex flex-col mb-3">
                            <input required type="time" @change="editDateAnswer(Qindex, $event)" :id="'answer'+Qindex" class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700">
                        </div>
                        <!-- Checkbox -->
                        <div v-if="question.answerType == 'multiple'" class="mb-3">
                            <div class="flex">
                                <div v-for="(Answer, index) in question.question_options" :value="Answer.id" :key="index" class="flex px-2">
                                    <label :for="'answer'+Qindex" class="items-center px-1 flex">
                                        <input type="checkbox" :value="Answer.optionLabel" @change="editCheckboxAnswer(Qindex, index, $event)"
                                        :id="'answer'+Qindex+'subAnswer'+index"
                                        class="focus:ring-blue-400 h-4 w-4 rounded">
                                    <span class="ml-2 text-gray-500">{{Answer.optionLabel}}</span>
                                    </label>
                                </div>
                            </div>
                            <label :id="'answer'+Qindex" class="size-xs text-gray-300">Select at least one of the options</label>
                        </div>
                    </div>

                </div>
                <!-- ends questions -->

                <button type="submit" class="p-2 uppercase inline-flex justify-center py-1 px-5 border-none shadow-md text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Save
                </button>

            </form>

        </div>

    </div>  
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueTimepicker from 'vue2-timepicker'
export default {
    components: { VueTimepicker },
    data(){
        return{
            isLoading:          true,
            showFeedback:       true,
            ReminingDays:       0,
            displayTitle:       false,
            displayLob:         false,
            displayReportTo:    false,
            feedback_answers: [],
        }
    },
    computed:{
        ...mapState([
            "feedbacks"
        ]),
        ...mapGetters([
            "getFeedbackInfo",
            "getFQuestionsInfo",
            "getFQuestionsArray",
        ]),
    },
    props:{
        profileInfo: String,
        feedback_selected: Number
    },
    methods:{
        ...mapActions([
            "fetchFeedbackInfo",
            "fetchFQuestionsInfo",
            "fetchFQuestionsArray",
            "sendFeedbackAnswers",
        ]),

        isVisible: function(question, index){
            if(question.parentingQuestion === null) return true;
            let parent_question = JSON.parse(question.parentingQuestion);
            let finding = this.feedback_answers.find(element => element.question_id == parent_question.parent_id );
            if(finding === undefined || finding?.answer_id === null || finding?.answer_id === '') return false;

            let found_type = finding.answer_type;
            let found_answer = finding.answer_id;
            
            if(found_type === 'text' || found_type === 'dropdown'){
                let value = 0;
                let clean_answer = found_answer?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]+/gi, '');
                    parent_question.conditions.forEach((word) => {
                        if(clean_answer.includes(word?.toLowerCase())) {
                            value++;
                        }
                    });
                let result = value > 0;
                if(result) { 
                this.feedback_answers[index].sub_question = null 
                }else{ 
                this.feedback_answers[index].sub_question = 0 
                }
                return result;
            }else if(found_type === 'multiple'){
                let apply = 0;
                found_answer.forEach(function(word){
                    parent_question.conditions.forEach(function(val){
                        if(val?.toLowerCase() === word.value?.toLowerCase() && word.status === 'true'){
                            apply++;
                        }
                    })
                })

                let result = apply > 0;
                if(result) { 
                this.feedback_answers[index].sub_question = null 
                }else{ 
                this.feedback_answers[index].sub_question = 0 
                }
                return result;
            }else if(found_type === 'duration' || found_type === 'date' || found_type === 'time' || found_type === 'number'){
                let parts = parent_question.conditions;
                let result = false;
                
                if(found_type === 'number'){
                    result = found_answer >= parseInt(parts[0]) && found_answer <= parseInt(parts[1]);
                }else{
                    result = found_answer >= parts[0] && found_answer <= parts[1];
                }

                if(result) { 
                this.feedback_answers[index].sub_question = null 
                }else{ 
                this.feedback_answers[index].sub_question = 0 
                }
                return result;        
            }
            return false;
        },

        editDropAnswer: function(Qindex, event) {
            let target= event.target;
            let pointsValue = event.target.value;
            let questionId = target.options[target.selectedIndex].text;

            this.feedback_answers[Qindex]['answer_id'] = questionId;
            this.feedback_answers[Qindex]['points'] = pointsValue;
        },

        editTextAnswer: function(Qindex, event) {
            let questionId = event.target.value;
            this.feedback_answers[Qindex]['answer_id'] = questionId;
        },

        editDurationAnswer: function(Qindex, event) {
            let questionId = event.displayTime;
            this.feedback_answers[Qindex]['answer_id'] = questionId;
        },

        editDateAnswer: function(Qindex, e) {
            let questionId = e.target.value;
            this.feedback_answers[Qindex]['answer_id'] = questionId;
        },

        editCheckboxAnswer: function(Qindex, index, e) {    
            if(e.target.checked){
                this.feedback_answers[Qindex]['answer_id'][index]['status'] = 'true';
            }else{
                this.feedback_answers[Qindex]['answer_id'][index]['status'] = 'false';
            }
        },

        sendTheAnswers(){
            let key = 0;
            let counter = [];
            let empty   = [];
            [].forEach.call(this.feedback_answers, element => {
                if(element.sub_question === null){
                    if(element.answer_id === null || element.answer_id === undefined || element.answer_id === ""){
                            document.getElementById('answer'+key).classList.add('border-2', 'border-red-500')
                            counter.push({answer : 'answer'+key})
                    }else{
                        document.getElementById('answer'+key).classList.remove('border-2', 'border-red-500')
                    }

                    if(element.answer_type === 'duration' && element.answer_id != ""){
                        if(element.answer_id.substring(0, 2) === 'HH' || element.answer_id.substring(3, 5) === 'mm' || element.answer_id.substring(6) === 'ss'){
                            document.getElementById('answer'+key).classList.add('border-2', 'border-red-500')
                            counter.push({answer : 'answer'+key})
                        }else{
                            document.getElementById('answer'+key).classList.remove('border-2', 'border-red-500')
                        }
                    }

                    if(element.answer_type === 'multiple'){
                        [].forEach.call(element.answer_id, e => {
                            if(e.status === "true"){ empty.push({answer : e.value}) }
                        })

                        if(empty.length < 1){
                                    document.getElementById('answer'+key).classList.add('text-red-500', 'bg-yellow-300', 'rounded-md', 'px-1')
                                    counter.push({answer : element.answerType})
                        }else{
                            document.getElementById('answer'+key).classList.remove('text-red-500', 'bg-yellow-300', 'rounded-md', 'px-1')
                        }
                    }
                }

                empty   = [];
                key++;
            });
            
            if(counter.length > 0){
                event.preventDefault();
                this.$fire({
                        type: 'warning',
                        title: "Oops!",
                        text: "There's some missing information",
                        showConfirmButton: true,
                        confirmButtonColor: '#009688',
                        allowOutsideClick: false,
                        background: '#FFFFFFD9',
                    })
            }else{
                event.preventDefault();
                this.isLoading = true;
                let objData = {
                    feedback_id:    this.getFeedbackInfo.id,
                    feedback_name:  this.getFeedbackInfo.feedbackName,
                    employee_id:    this.profileInfo,
                    feedback_answers: this.feedback_answers,
                };
                // console.log(objData)
                this.sendFeedbackAnswers(objData).then( ()=>{
                    this.$fire({
                            type: 'success',
                            title: 'Feedback Sent!',
                            // text: "Thank you for your ",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            this.isLoading = false;
                            this.$emit('closeIt', true);                 
                        });
                }).catch(()=>{
                    this.$fire({
                            type: 'warning',
                            title: 'Oops! Something went wrong. Please try again',
                            text: 'If the problem persist, please report this issue at the watchmen chat',
                            showConfirmButton: true
                        }).then(()=>{
                            this.isLoading = false;                 
                        });
                });
            }

        },

    },

    created() {
        let feedback_id = this.feedback_selected
        this.fetchFeedbackInfo(feedback_id);
        this.fetchFQuestionsInfo(feedback_id);
        this.fetchFQuestionsArray(feedback_id).then((res) => {
            this.feedback_answers = res;
            this.isLoading  = false;
        }, error => {
            console.error("Error fetching the projects: "+ error)
        });
    },

    updated(){
        
    },

  mounted() {
    console.log("mounted")
  }

}
</script>
<style lang="scss" scoped>
.formEdit{
    display: block !important;
}
.hide{
    display: none;
}
.hideSelectCity,
.hideLabelCity {
  display: none;
}
.invalid-feedback{
        font-weight: bold;
    }
.mystyle{
    border: 1px;
    border-color:brown;
}
</style>