<template>
 <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1">

      <employee-filter @toggle="updateFilters"/>
      
      <reason-list @toggle="updateFilters"/>

      <status-list  @toggle="updateFilters"/>

      <campaign-list  @toggle="updateFilters"/>

      <site-filter  @toggle="updateFilters"/>

      <title-list @toggle="updateFilters"/>
    
  </div>
                                           
    
</template>
<script>
import Debounce from 'debounce';
import CampaignList from './CampaignList.vue';
import StatusList from './StatusList';
import ReasonList from './ReasonList';
import EmployeeFilter from './EmployeeFilter';
import SiteFilter from './SiteFilter';
import TitleList from './TitleList';

export default {
    data(){
        return{
            form:{
                employeeId:null,
                reason:0,
                status:0,
                campaign:0,
                site: 0,
                title: 0
            }
        }
    },
    methods:{
        clear:function(){
            this.form.employeeId = null;
        },
        updateFilters:Debounce(function(res){        
            this.form[res.key] = res.value;        
        },200)
    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle',this.form);
            },
            deep:true
        }
    },
    components:{
        CampaignList,
        StatusList,
        ReasonList,
        EmployeeFilter,
        SiteFilter,
        TitleList
    },
    mounted(){
        this.$emit('toggle',this.form);
    }

}
</script>
        ;;