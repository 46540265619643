<template>
  <div>
    <div v-if="isExpanded" @click="toggleExpanded"
         class="fixed inset-0 flex flex-row items-center justify-center z-[999]">
    </div>
    <div class="relative flex flex-col select-none z-[999]" ref="selectBox">
      <div class="flex flex-row items-center h-fit min-h-6 w-full rounded bg-gray-50 px-1
    text-start border-[1px] hover:cursor-pointer flex-wrap max-h-[4.25rem] overflow-auto"
           @click.prevent="toggleExpanded">
      <span v-if="valueArray.length === 0">
        Attach
      </span>
        <div v-for="option in valueArray" :key="`multi-dropdown-chips-${option.id}`"
             class="flex flex-row group cursor-pointer bg-[#007bff] rounded-xl h-5 justify-between
           items-center m-[0.062rem] hover:bg-slate-600 transition-colors duration-300 ease-in-out"
             @click.stop="toggleValue(option)">
        <span class="px-2 text-nowrap text-white">
          {{ option.label }}
        </span>
          <div class="relative size-4 mr-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5"
                 stroke="white"
                 class="size-4 absolute opacity-100 group-hover:opacity-0 transition-opacity duration-300 ease-in-out">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"
                 class="size-4 absolute opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <path fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </div>
      </div>
      <ul v-if="isExpanded"
          class="absolute h-fit max-h-44 w-full overflow-y-scroll bg-white drop-shadow-md rounded-lg {{}}"
          :class="optionsOffset">
        <li v-for="option in options" :key="`multi-dropdown-${option.id}`"
            class="flex h-6 cursor-pointer flex-row text-start group space-x-1 hover:bg-gray-50"
            @click.prevent="toggleValue(option)">
          <div class="h-full w-1.5"
               :class="{'bg-transparent': !valueIsSelected(option), 'bg-[#007bff]': valueIsSelected(option)}"/>
          <span class="group:hover:bg-gray-100 w-full text-nowrap"
                :class="{'bg-gray-100': valueIsSelected(option), 'text-[#E03A44]': option.isActive === 0}">{{ option.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        options: {
            required: true,
            type: Array,
        },
        starting: {
            type: Array,
            default: () => [],
        }
    },
    data () {
        return {
            selectBoxHeight: 0,
            isExpanded: false,
            valueArray: this.starting.map(x => x),
            actionsMade: {
                added: [],
                removed: [],
            },
            dropdownPosition: 'top'
        };
    },
    computed: {
        valueArrayIds () {
            return this.valueArray.map(x => x.id);
        },
        optionsOffset () {
            if ( this.dropdownPosition === 'top' ) {
                return `${this.dropdownPosition}-[${this.selectBoxHeight}px]`;
            } else {
                return `bottom-[${this.selectBoxHeight-16}px]`;
            }
        },
    },
    mounted () {
        this.updateSelectBoxHeight();
    },
    watch: {
        valueArray () {
            this.updateSelectBoxHeight();
        },
        starting () {
            this.valueArray = this.starting.map(x => x);
        }
    },
    methods: {
        valueIsSelected (value) {
            return this.valueArrayIds.includes(value.id);
        },
        toggleValue (value) {
            if ( this.valueArrayIds.includes(value.id) ) {
                this.valueArray = this.valueArray.filter(x => x.id !== value.id);

                if ( this.starting.map(x => x.id).includes(value.id) ) {
                    this.actionsMade.removed.push(value);
                } else {
                    this.actionsMade.added = this.actionsMade.added.filter(x => x.id !== value.id);
                }
            } else {
                this.valueArray.push(value);

                if ( !this.starting.map(x => x.id).includes(value.id) ) {
                    this.actionsMade.added.push(value);
                } else {
                    this.actionsMade.removed = this.actionsMade.removed.filter(x => x.id !== value.id);
                }
            }
            this.$emit('input', this.actionsMade);
        },
        toggleExpanded () {
            this.isExpanded = !this.isExpanded;
        },
        updateSelectBoxHeight () {
            this.$nextTick(() => {
                this.selectBoxHeight = this.$refs.selectBox.clientHeight;

                const dropdown = this.$refs.selectBox;
                const rect = dropdown.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                if (spaceBelow < 192 && spaceAbove > spaceBelow) {
                    this.dropdownPosition = 'bottom';
                } else {
                    this.dropdownPosition = 'top';
                }
            })
        },
    },
};
</script>