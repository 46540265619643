import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state={
    headcount:[],
    headcountLoading:false
};
const getters={
    getHeadcount:(state) => state.headcount,
    getHeadcountLoading:(state) => state.headcountLoading
};
const actions={
    async fetchHeadcount ({commit}, filter){   
        const endpoint = API_URL + `api/v4/headcount/headcount-list/${filter}`;
        await axios.get(endpoint).then((response) => {
            commit('setHeadcount', response.data);
        }, error => {
            console.error("Something went wrong: "+ error)
        });
    },

    async editHeadcount ({ commit }, data){   
        commit('setHeadcountLoading', true);     
        const endpoint = API_URL + 'api/v4/headcount/edit';
        const response = await axios.post(endpoint, data).then((response)=>{
            return response;
        }).catch((error) => {
            return error.response;
        });    
        commit('setHeadcountLoading', false);
        return response;
    },
};
const mutations={
    setHeadcount: (state, data)=>(state.headcount=data),
    setHeadcountLoading: (state, data)=>(state.headcountLoading=data),
};
export default {
    state,getters,actions,mutations
}
