<template>
    <div class="border-l-2 border-gray-200 h-full">
        <select required class="bg-white px-2 hover:text-gray-600" v-model="statusSelected">  
          <optgroup label = "Select a Status">
            <option hidden value="0">Choose a Status</option>
            <option value = "PENDING">Pending</option>
            <option value = "REJECTED">Rejected</option>
          </optgroup>
        </select>
      </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Debounce from 'debounce';
export default {
    data(){
        return{ 
            error: null,
            statusSelected : 'PENDING'
        }
    },
    computed:{
        ...mapGetters(['getCatalog']),
    },
    created(){
       this.fetchIncidencesCatalog().then(()=>{
           this.error =false
        });
    },
    methods:{
        ...mapActions(['fetchIncidencesCatalog'])
    },
    watch:{
      statusSelected: Debounce(function(){
          this.$emit('toggle',{
              key:'status',
              value :this.statusSelected
          });
      },200)     

    }
}
</script>
