<template>
<div id="app" class="fixed top-0 bottom-0 left-0 right-0">
  <div class="newloading" id="pro-loading" style="display: none;">
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <img src="/img/centris-logo.png" alt="Logo">
  </div>
  <app-layout :username="username" :title="title">
    <transition name="fade">
    <router-view @loadCredentials="getCredentials" @set-title="setTitle"></router-view>
  </transition>
  </app-layout>
</div>
</template>
<script>
  import Layout from "@/views/Utilities/Layout";
  import auth   from "@/config/user"
  import { mapActions, mapGetters, mapState } from "vuex";
  export default {
    name: 'App',
    components: {
      'app-layout':Layout
    },
    data(){
      return{
        username: '',
        title:''
      }
    },
    computed:{
          ...mapState([
              "notifications",
              "session"
          ]),
          ...mapGetters([
              "getUnreadMessages",
              "getAuthObject"
          ]),
      },
      methods:{
        ...mapActions([
            'changeNotificationStatus',
            'fetchUnreadNotifications',
        ]),

        getUsername: function(){
          let EmpID = auth.currentUser.EmpID;
          if (EmpID) { this.username = EmpID; }
        },

        getCredentials: function(){
          this.getUsername();
          location.reload();
          this.$router.push('/dashboard');
        },

        setTitle:function(title){
          this.title =title;
          
        },
        
    },
    mounted(){
      this.getUsername();
    },
    refresh(){

    }
  };
</script>
<style>

@font-face {
  font-family: "Averta";
  src: local("Averta"),
  url(./fonts/Averta.otf) format("truetype");
}
@font-face {
  font-family: "Averta-Light";
  src: local("Averta-Light"),
  url(./fonts/Averta-Light.otf) format("truetype");
}
@font-face {
  font-family: "Averta-Thin";
  src: local("Averta-Thin"),
  url(./fonts/Averta-Thin.otf) format("truetype");
}
@font-face {
  font-family: "Averta-Semibold";
  src: local("Averta-Semibold"),
  url(./fonts/Averta-Semibold.otf) format("truetype");
}
@font-face {
  font-family: "Averta-Bold";
  src: local("Averta-Bold"),
  url(./fonts/Averta-Bold.otf) format("truetype");
}
.font-bold {
  font-family: "Averta-Bold" !important;
}
.font-semibold {
  font-family: "Averta-Semibold" !important;
}
.font-thin {
  font-family: "Averta-Thin" !important;
}
.font-light {
  font-family: "Averta-Light" !important;
}
.font-normal {
  font-family: "Averta";
}
.font-normal {
  font-family: "Averta" !important;
}
* {
  font-family: "Averta";
}
.card-title{
  font-family: Averta-Semibold;
}
p{
  font-family:Averta;
}
h1{
  font-family:Averta-Bold;
}
h2,h3,h4,h5,h6{
  font-family: Averta-Semibold;
}
body{
  height: max-content;
}
strong{
  font-family:Averta-Semibold;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
.normalTop{ 
	top: 4.6em;
}
.main-container{
  z-index: 900 !important;
}
.clocker-container{
  z-index: 900 !important;
}
.vs-tooltip { 
  z-index: 99999 !important; 
}
.pac-container{
    z-index: 2001 !important;
    width: 45% !important;
}
.modal-content {
  border: none !important;
  border-radius: 15px !important;
  box-shadow: 0 0 25px rgba(0,0,0,0.2) !important;
  overflow: hidden !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  margin: 0 !important;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider.status {
  background-color: #dc2626;
}
.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider.status {
  background-color: #16a34a;
}
input:focus + .slider.status {
  box-shadow: 0 0 1px #16a34a;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* Loading */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #ddd;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.loader-mini {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.loader-mini::after {
  content: '';  
  width: 96px;
  height: 3px;
  background: #ddd;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.5px;
  box-sizing: border-box;
  animation: hitZak 0.6s ease-in-out infinite alternate;
}

@keyframes hitZak {
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
}

/* New loading */
.newloading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #fff;
}
.newloading > img {
  position: absolute;
  max-width: 300px;
  padding: 40px;
  opacity: 0.8;
  animation: zoom-in-zoom-out 6s ease infinite;
}
.newloading .loader {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0.6;
    background: linear-gradient(#e83c50, #3f4c9b, #5baee2);
    animation: animateloading 2s linear infinite;
}
@keyframes animateloading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}
.newloading .loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translateX(-50%);
    background: linear-gradient(#e83c50, #3f4c9b, #5baee2);
}
.newloading .loader span:nth-child(1) {
    filter: blur(5px);
}
.newloading .loader span:nth-child(2) {
    filter: blur(10px);
}
.newloading .loader span:nth-child(3) {
    filter: blur(25px);
}
.newloading .loader span:nth-child(4) {
    filter: blur(50px);
}
.newloading .loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #fff;
    border-radius: 50%;
}
</style>