export const MAX_FILE_SIZE = 10000;
export const VALID_FILE_TYPES =[
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf'
];
export const CSV_FILE_TYPES =[
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const FILE_SIZE_EXCEPTION = `Incorrect file size. The maximum size allowed is: + ${MAX_FILE_SIZE}.`;
export const FILE_TYPE_EXCEPTION =`Incorrect file type. Allowed file types: '${VALID_FILE_TYPES.join(', ')}'`;

export const CSV_TYPE_EXCEPTION =`Incorrect file type. Allowed file types: '${CSV_FILE_TYPES.join(', ')}'`;

