<template>
<div class="row justify-content-center">
    <div v-if="displayBillableTable"> 
        <p class="font-italic font-weight-bold blink">"We're loading the Billable information, be patient..."</p>
    </div>
    <div v-else class="col-auto">
        <h1>Current Week Billable</h1>
        <!--#pendingRequests-->
         <table class="table table-sm table-hover mb-5 mt-5">
            <thead class="thead-light">
            <tr>
                <th class="text-center" scope="col"></th>
                <th class="text-center" scope="col">Sunday</th>
                <th class="text-center" scope="col">Monday</th>
                <th class="text-center" scope="col">Tuesday</th>
                <th class="text-center" scope="col">Wednesday</th>
                <th class="text-center" scope="col">Thursday</th>
                <th class="text-center" scope="col">Friday</th>
                <th class="text-center" scope="col">Saturday</th>
            </tr>
            </thead>
            <tbody v-if="this.getCWRequests.length" style="font-size: 14px;">
                <tr style="cursor:pointer;" :id="'row'+index" v-for="(currentRow, index) in getCWRequests" :key="index">
                    <td class="text-capitalize"># of pending requests</td>
                    <td class="text-capitalize">{{currentRow.Sunday}}</td>
                    <td class="text-capitalize">{{currentRow.Monday}}</td>
                    <td class="text-capitalize">{{currentRow.Tuesday}}</td>
                    <td class="text-capitalize">{{currentRow.Wednesday}}</td>
                    <td class="text-capitalize">{{currentRow.Thursday}}</td>
                    <td class="text-capitalize">{{currentRow.Friday}}</td>
                    <td class="text-capitalize">{{currentRow.Saturday}}</td>
                </tr> 
            </tbody>
            <tbody v-else>
                <tr><td colspan="7">No records found.</td></tr>
            </tbody>
        </table> 
        <!--#pendingRequestsEnd-->
        <div class="mb-1 mt-1 w-full">
            <div class="flex text-left">
                <span class="px-2 orange mr-1"></span> Pending Hours to be reviewed
            </div>
        </div>
        <table class="table table-sm table-hover mb-5 mt-5">
        <thead class="thead-dark">
        <tr>
            <th class="text-center" scope="col">Employee ID</th>
            <th class="text-center" scope="col">Role</th>
            <th class="text-center" scope="col">LOB</th>
            <th class="text-center" scope="col">Location</th>
            <th class="text-center" scope="col">Sunday</th>
            <th class="text-center" scope="col">Monday</th>
            <th class="text-center" scope="col">Tuesday</th>
            <th class="text-center" scope="col">Wednesday</th>
            <th class="text-center" scope="col">Thursday</th>
            <th class="text-center" scope="col">Friday</th>
            <th class="text-center" scope="col">Saturday</th>
            <th class="text-center" scope="col">Total</th>
        </tr>
        </thead>
        <tbody v-if="this.getCWBillable.length" style="font-size: 14px;">

            <tr  :id="'date'+index" v-for="(currentRow, index) in getAMHRCurrentWeekDates" :key="'date'+index">
                <td colspan="4"></td>
                <td>
                    <div v-if="requestsPerDay[0].Sunday != 0 && statusPerDay[0] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[0] !== 'Approve'" 
                        @click="approveDay(currentRow.sunday_date)" 
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[0] !== 'Approve', 'bg-green-500' : statusPerDay[0] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[0]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Monday != 0 && statusPerDay[1] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[1] !== 'Approve'" 
                        @click="approveDay(currentRow.monday_date)"  
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[1] !== 'Approve', 'bg-green-500' : statusPerDay[1] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[1]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Tuesday != 0 && statusPerDay[2] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[2] !== 'Approve'" 
                        @click="approveDay(currentRow.tuesday_date)"
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[2] !== 'Approve', 'bg-green-500' : statusPerDay[2] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[2]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Wednesday != 0 && statusPerDay[3] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[3] !== 'Approve'" 
                        @click="approveDay(currentRow.wednesday_date)"
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[3] !== 'Approve', 'bg-green-500' : statusPerDay[3] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[3]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Thursday != 0 && statusPerDay[4] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[4] !== 'Approve'" 
                        @click="approveDay(currentRow.thursday_date)"
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[4] !== 'Approve', 'bg-green-500' : statusPerDay[4] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[4]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Friday != 0 && statusPerDay[5] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[5] !== 'Approve'" 
                        @click="approveDay(currentRow.friday_date)"
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[5] !== 'Approve', 'bg-green-500' : statusPerDay[5] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[5]}}</button>
                    </div>
                </td>
                <td>
                    <div v-if="requestsPerDay[0].Saturday != 0 && statusPerDay[6] === 'Approve'">
                        <button class="rounded-2xl py-2 px-3 text-black text-xs bg-yellow-300 cursor-default" disabled>Pending</button>
                    </div>
                    <div v-else>
                        <button 
                        :disabled="statusPerDay[6] !== 'Approve'" 
                        @click="approveDay(currentRow.saturday_date)"
                        :class="{'bg-gray-400 cursor-default' : statusPerDay[6] !== 'Approve', 'bg-green-500' : statusPerDay[6] === 'Approve'}" 
                        class="rounded-2xl py-2 px-3 text-white text-xs">{{statusPerDay[6]}}</button>
                    </div>
                </td> 
            </tr>
            <tr style="cursor:pointer;" :id="'row'+index" v-for="(currentRow, index) in getCWBillable" :key="index">
                <td class="-text-capitalize">{{currentRow.emp_id}}</td>
                <td class="text-capitalize">{{currentRow.Title}}</td>
                <td class="text-capitalize">{{currentRow.Lob}}</td>
                <td class="text-capitalize">{{currentRow.Site_Location}}</td>
                <td :class="{ 'orange': (currentRow.mody_status === 'PENDING') }" class="text-capitalize">                    
                    <span v-if="statusPerDay[0] !== 'Approve' && statusPerDay[0] !== 'Closed'"><b>{{currentRow.Sunday === '0.00' ? currentRow.sunday_status : currentRow.Sunday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.sunday_date }  }">
                        {{currentRow.Sunday === '0.00' ? currentRow.sunday_status : currentRow.Sunday}}
                    </router-link>
                </td>
                <td :class="{ 'orange': (currentRow.monday_status === 'PENDING') }" class="text-capitalize">
                    <span v-if="statusPerDay[1] !== 'Approve' && statusPerDay[1] !== 'Closed'"><b>{{currentRow.Monday === '0.00' ? currentRow.monday_status : currentRow.Monday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.monday_date }  }">
                        {{currentRow.Monday === '0.00' ? currentRow.monday_status : currentRow.Monday}}
                    </router-link>
                </td>
                
                <td :class="{ 'orange': (currentRow.tuesday_status === 'PENDING') }" class="text-capitalize">
                     <span v-if="statusPerDay[2] !== 'Approve' && statusPerDay[2] !== 'Closed'">{{currentRow.Tuesday === '0.00' ? currentRow.tuesday_status : currentRow.Tuesday}}</span>
                    <router-link v-else :disabled="statusPerDay[2]" class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.tuesday_date }  }">
                        {{currentRow.Tuesday === '0.00' ? currentRow.tuesday_status : currentRow.Tuesday}}
                    </router-link>
                </td>
                <td :class="{ 'orange': (currentRow.wednesday_status === 'PENDING') }" class="text-capitalize">
                    <span v-if="statusPerDay[3] !== 'Approve' && statusPerDay[3] !== 'Closed'"><b>{{currentRow.Wednesday === '0.00' ? currentRow.wednesday_status : currentRow.Wednesday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.wednesday_date }  }">
                        {{currentRow.Wednesday === '0.00' ? currentRow.wednesday_status : currentRow.Wednesday}}
                    </router-link>
                </td>
                <td :class="{ 'orange': (currentRow.thursday_status === 'PENDING') }" class="text-capitalize">
                    <span v-if="statusPerDay[4] !== 'Approve' && statusPerDay[4] !== 'Closed'"><b>{{currentRow.Thursday === '0.00' ? currentRow.thursday_status : currentRow.Thursday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.thursday_date }  }">
                        {{currentRow.Thursday === '0.00' ? currentRow.thursday_status : currentRow.Thursday}}
                    </router-link>
                </td>
                <td :class="{ 'orange': (currentRow.friday_status === 'PENDING') }" class="text-capitalize">
                    <span v-if="statusPerDay[5] !== 'Approve' && statusPerDay[5] !== 'Closed'"><b>{{currentRow.Friday === '0.00' ? currentRow.friday_status : currentRow.Friday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id,date: currentRow.friday_date }  }">
                        {{currentRow.Friday === '0.00' ? currentRow.friday_status : currentRow.Friday}}
                    </router-link>
                </td>
                <td :class="{ 'orange': (currentRow.saturday_status === 'PENDING') }" class="text-capitalize">
                    <span v-if="statusPerDay[6] !== 'Approve' && statusPerDay[6] !== 'Closed'"><b>{{currentRow.Saturday === '0.00' ? currentRow.saturday_status : currentRow.Saturday}}</b></span>
                    <router-link v-else class="font-weight-bold text-capitalize" :to="{ name: 'DetailedHours', params: { usuario: 'account-manager', empid: currentRow.emp_id, date: currentRow.saturday_date }  }">
                        {{currentRow.Saturday === '0.00' ? currentRow.saturday_status : currentRow.Saturday}}
                    </router-link>
                </td>
                <td class="text-capitalize font-weight-bold">{{currentRow.total}}</td>
            </tr> 
        </tbody>
        <tbody v-else>
            <tr><td colspan="11">No records found.</td></tr>
        </tbody>
    </table>    
    </div>
</div>
</template>
<script>
import { mapGetters,mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            fullData: null,
            displayBillableTable: true,
            progressValue: 0,
            maxProgressValue: 120,
            statusPerDay: [],
        }
    },
    props:{
        queryInfo: {
            type: String,
            required: true,
        },
    },
    computed:{
        ...mapState(['AccountManagerClocker']),  
        ...mapGetters([
            'getCWBillable',
            'getAMHRCurrentWeekDates',
            'getCWApprovedDates',
            'getCWRequests'
        ]),
    },
    watch:{
        getCWApprovedDates: function(val){
            this.statusPerDay = Object.values(val);
        },
        getCWRequests: function(Requests){
            this.requestsPerDay = Object.values(Requests);
        }
    },
    methods:{
        ...mapActions(['approveAllDay','fetchCurrentWeekBillable','fetchAMHRCurrentWeekDates','fetchCWApprovedDayStatus','fetchCurrentWeekRequests']),
        approveDay: function (data){
            let qInfo = JSON.parse(this.queryInfo);
            let approveData = { 
                'approvedDate': data, 
                'campaign': qInfo.Campaign, 
                'location': qInfo.Location 
            }
            this.$confirm("Do you want to continue?", "You're about to Approve all day", "warning").then((result) => {
                if (result){
                    this.infoLoaded = false;
                    this.approveAllDay(approveData).then(()=>{
                        this.$fire({
                            type: "success",
                            title: "Day Approved Successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(()=>{
                             this.$emit('refreshComponent', true);
                        });
                    });
                }
            });
        }
    },
    created(){
        var objectData = JSON.parse(this.queryInfo);
        Object.assign(objectData, {Window:'CWBT'})
        this.fullData = objectData;
        this.fetchCWApprovedDayStatus(objectData).then();
        this.fetchCurrentWeekRequests(objectData);
        this.fetchCurrentWeekBillable(objectData).then(()=>{
            const now = new Date()
            const item = {
                value: objectData,
                expiry: now.getTime() + (60000 * 60 * 5),
            }
            localStorage.setItem('hoursdata', JSON.stringify(item))
            this.fetchAMHRCurrentWeekDates();
            this.displayBillableTable = false;
            this.componentLoaded = false;
            this.$emit('CWBillableTableUpdate', 'CWBillableTableUpdate');
        });  
    },
}
</script>
<style scoped lang="scss">
.green{ 
  background-color: #cae1aa; 
}
.orange { 
    background-color: #ffc08c;
}
</style>