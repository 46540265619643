<template>
    <div class="flex bg-white shadow-sm rounded-lg max-h-[650px] overflow-hidden mb-10">
        <!-- New Ticket Modal -->
        <b-modal size="lg" ref="AddNewTicket" :id="'OpenNewTicket'" hide-footer centered no-close-on-backdrop>
            <div>
                <addticketmodal @closeModal='closeModal'></addticketmodal>
            </div>
        </b-modal>

        <!-- components -->
        <div class="relative max-h-fit min-w-[350px] border-r overflow-auto">
            <div class="sticky top-0 bg-gradient-to-b from-white from-60% to-white/0 p-4">
                <button v-b-modal="'OpenNewTicket'" class="p-2 shadow-sm rounded-lg bg-c-primary w-full text-white">Open a new ticket</button>
            </div>
            <div class="">
                <component :key="ListKey" @ticket_selected="openTicket" :is="'ticketsTable'"></component>
            </div>
        </div>
        <!-- Ticket Viewer -->
        <div v-if="this.showViewer" class="max-h-fit flex-1">
            <component @updateAll='updateAll' :key="ViewKey" :ticketSelected="this.ticketSelected" :userType="this.thisUserType" :is="'ticketViewer'"></component>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapState, mapActions }   from 'vuex'
    import ticketsTable                         from'@/views/components/Tickets/tables/my_tickets_table';
    import ticketViewer                         from'@/views/components/Tickets/tables/my_ticket_viewer'
    import addticketmodal                       from'@/views/components/Tickets/modals/addNewTicketModal';
    import auth                                 from "@/config/user"
    export default {
        components: { 
            ticketsTable,
            addticketmodal,
            ticketViewer
        },
        data(){
            return{
                ListKey: 0,
                ViewKey: 0,
                ticketSelected: null,
                showViewer: false,
                thisUserType: "user"
            }
        },
        computed: {
            ...mapState([
            'employees'
            ]),
            ...mapGetters([
            'infoEmployee'
            ])
        },
        created(){
            let idEmployee = auth.currentUser.EmpID;
            this.loadMyProfileByEmpId(idEmployee);
        },
        methods: {
        ...mapActions([
        'loadMyProfileByEmpId'
        ]),

            closeModal: function(){
                this.$refs['AddNewTicket'].hide()
                this.ListKey++;
                this.ViewKey++;
                this.showViewer = false;
            },
            
            openTicket: function(data){
                this.ViewKey++;
                this.ticketSelected = data;
                this.showViewer = true;
            },

            updateAll: function(){
                this.ListKey++;
                this.ViewKey++;
                this.showViewer = false;
            }
        },

        mounted(){
            let title = 'My Tickets';
            this.$emit('set-title',title);
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ::-webkit-scrollbar {
    width: 0.325rem;
    padding:5px;
    }

    ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 50px !important;
    background-color: #bdbdbd;
    }

    ::-webkit-scrollbar-track {
    border-radius:5px;
    background-color:rgba(0,0,0,0.075);
    }
</style>