<template>
    <div class="w-full h-full relative flex flex-row align-items-start justify-content-center">
        <div class="bg-white shadow-sm rounded-sm p-3 mt-2 lg:w-1/3 sm:w-full">
            <div>
                <p>Hi <b>{{this.EmpID}}!</b>,</p>
                <p>We notice that it's your first time in the EMS.</p>
                <p>So, for your safety, we need to change your password.</p>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left">
                    <a class="text-danger">*</a>New Password:
                </label>
                <div class="col-sm-12">
                    <input :class="{'is-invalid': submitted && $v.newPassword.$error }"  type="password"  v-model="newPassword" class="form-control" placeholder="New Password"/>
                    <div v-if="submitted && !$v.newPassword.required" class="invalid-feedback" >This field is required!</div>
                    <div v-if="submitted && !$v.newPassword.lowerLetter" class="invalid-feedback" >Use at least one lower letter!</div>
                    <div v-if="submitted && !$v.newPassword.upperLetter" class="invalid-feedback" >Use at least one upper letter!</div>
                    <div v-if="submitted && !$v.newPassword.oneNumber" class="invalid-feedback" >Use at least one number!</div>
                    <div v-if="submitted && !$v.newPassword.speChars" class="invalid-feedback" >Use at least one of this special characters: [!, @, #, $, %, &, *]</div>
                    <div v-if="submitted && !$v.newPassword.minimunLength" class="invalid-feedback" >The length need to be at least 8 characters</div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left">
                    <a class="text-danger">*</a>Confirm Password:
                </label>
                <div class="col-sm-12">
                    <input :class="{'is-invalid': submitted && $v.confirmPassword.$error }"      type="password"  v-model="confirmPassword" class="form-control" placeholder="Confirm New Password"/>
                    <div v-if="submitted && !$v.confirmPassword.required & !$v.confirmPassword.sameAsPassword" class="invalid-feedback" >Passwords doesn't match!</div>
                </div>
            </div> 
            <button type="button" @click="saveNewPassword" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
            <button type="button" @click="logOut" class="bg-gray-200 px-6 py-2 rounded">Log out</button>
        </div>
    </div>
</template>
<script>
  const   lowerLetter     = (value) => new RegExp('^(?=.*[a-z])').test(value);
  const   upperLetter     = (value) => new RegExp('^(?=.*[A-Z])').test(value);
  const   oneNumber       = (value) => new RegExp('^(?=.*[0-9])').test(value);
  const   speChars        = (value) => new RegExp('^(?=.*[!@#\\$%\\^&\\*])').test(value);
  const   minimunLength   = (value) => new RegExp('^(?=.{8,})').test(value);
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { required, sameAs }   from 'vuelidate/lib/validators';
  import {mapState, mapActions } from 'vuex'
  import auth   from "@/config/user"
  export default {
    data() {
      return {
          submitted: false,
          title:'Reset Password',
          newPassword: "",
          confirmPassword: "",
          EmpID: null,
      }
    },
    computed:{
      ...mapState(['session']),
    },
    validations: {
        newPassword:      { 
            required,
            lowerLetter,
            upperLetter,
            oneNumber,
            speChars,
            minimunLength,
        },
        confirmPassword:  { 
            sameAsPassword: sameAs('newPassword'),
        },
    },
    methods: {
         ...mapActions(['logoutUser','changePassword']),
        saveNewPassword(){
            event.preventDefault();
            this.submitted = true;
            this.$v.$touch();
            var userData = {
                employeeID: auth.currentUser.EmpID,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword
            }
            this.changePassword(userData).then(()=>{
                this.$fire({
                    type: "success",
                    title: "You change your password successfully",
                    showConfirmButton: false,
                    timer: 1300,
                }).then(() => {
                     this.logoutUser().then(() => {
                        this.isLoading = false;
                        setTimeout(window.location.reload.bind(window.location), 500);
                    });
                });
            });
        },
        logOut(){
            this.logoutUser().then(() => {
                this.isLoading = false;
                setTimeout(window.location.reload.bind(window.location), 500);
            });
        },
    },
    mounted(){
        this.EmpID = auth.currentUser.EmpID;
        this.$emit('set-title',this.title);
    },
  }
</script>
<style scoped>

</style>