<template>
    <div>
        <div class="px-4 pt-5 space-y-5">
            <div class="flex items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg h-6 w-6 text-blue-600" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                    </svg>
                </div>
                <div class="flex-1 my-auto ml-4 text-left">
                    <h3 class="text-base font-semibold leading-6 text-gray-900 text-left" id="modal-title">Edit Headcount for campaign {{ info.campaign }} and line of business {{ info.lob }}</h3>
                </div>
            </div>

            <div class="text-left">
                <span class="text-sm text-gray-500">Approved Full Time Equivalent</span>
                <input type="number" v-model="approved_fte" placeholder="0" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 p-2 no-arrows">
                <div class="flex flex-row w-full">
                    <pill :dirty="$v.approved_fte.$dirty" :valid="!$v.approved_fte.$invalid">
                    Min: 1.
                    </pill>
                </div>
            </div>
            
            <div class="text-left">
                <span class="text-sm text-gray-500">Full Time Equivalent Size</span>
                <input type="number" v-model="fte_size" placeholder="0" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2 no-arrows">
                <div class="flex flex-row w-full">
                    <pill :dirty="$v.fte_size.$dirty" :valid="!$v.fte_size.$invalid">
                    Min: 30, Max: 48.
                    </pill>
                </div>
            </div>
            
            <div class="text-left">
                <span class="text-sm text-gray-500">Effective Date</span>
                <date-picker id="datesId" class="!block !w-full" input-class="w-full block text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 p-2" v-model="effective_date" 
                    :disabled-date="ableOnlyFuture"
                    placeholder="Select a date" valueType="format" :editable="false" :custom-formatter="customFormatter">
                </date-picker>
                <div class="flex flex-row w-full">
                    <pill :dirty="$v.effective_date.$dirty" :valid="!$v.effective_date.$invalid">
                    Required
                    </pill>
                </div>
            </div>

            <div class="text-left">
                <span class="text-sm text-gray-500">Write a comment for this headcount</span>
                <textarea v-model="comment" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2" row="4"></textarea>
                <div class="flex flex-row w-full">
                    <pill :dirty="$v.comment.$dirty" :valid="!$v.comment.$invalid">
                    Min: 10 chars., max of 5000 chars.
                    </pill>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" v-if="!getHeadcountLoading">
            <button class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto my-auto"
            @click="send">
            Continue
            </button>

            <button class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto my-auto" @click="closeModal">
            Cancel
            </button>
        </div>
    </div>
</template>
<script>
import { required, minLength, maxLength, minValue, maxValue} from 'vuelidate/lib/validators';
import Pill from '@/views/mockups/ValidationPill';
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        DatePicker,
        Pill,
    },
    props: {
        info: {
            default: () => {
                return {
                    campaign: 'CENTRIS-STAFF',
                    lob: 'CENTRIS-STAFF',
                }
            }
        },
    },
    data () {
        return {
            comment: '',
            approved_fte: '',
            fte_size: '',
            effective_date: ''
        }
    },
    validations () {
        return {
            comment: {
              required,
              minLength: minLength(10),
              maxLength: maxLength(5000)
            },
            approved_fte: {
                required,
                minValue: minValue(1)
            },
            fte_size: {
                required,
                minValue: minValue(30),
                maxValue: maxValue(48)
            },
            effective_date: {
                required
            }
        }
    },
    computed:{
        ...mapGetters([
            'getHeadcountLoading'
        ]),
    },
    methods: {
        ...mapActions([
            'editHeadcount'
        ]),
        closeModal () {
            this.$emit('toggle', true)
        },

        customFormatter(date){
            return moment(date).format("YYYY-MM-DD");
        },

        send () {
            this.$v.$touch();
            if(this.$v.$invalid) return;
            let info={
                'Campaign':this.info.campaign, 
                'LOB':this.info.lob, 
                'Approved_FTEs':this.approved_fte, 
                'FTE_Size':this.fte_size, 
                'EffectiveDate':this.effective_date, 
                'Comment':this.comment
            };
            this.editHeadcount(info).then(()=>{
                this.closeModal()
            }); 
        },

        ableOnlyFuture(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date <= today;
        },
    }
}
</script>