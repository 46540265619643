<template>
  <div class="mb-5 pb-5">
    <table-search /> 
  </div>
</template>
<script>
import TableSearch from '@/views/components/Table/employee_search';
export default {
  name: '',
  data(){
    return {
      title:'Search Employee'
    }
  },
  components: {  TableSearch },
  mounted(){
    this.$emit('set-title',this.title);
  }
}
</script>