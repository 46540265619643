import axios from '@/config/axios'
import { API_URL } from '../../actions/config'

const state = {
    indicatorValues: {
        'conformance' : "0.0000",
        'scheduled_time': "00:00:00",
        'worked_time': "00:00:00",
        'hours_review': "0",
        'open_tickets': 0,
        'follow_up_tickets': 0,
        'courses_pending': 0,
        'incidences_pending': 0,
    }
};

const getters = {
   getIndicatorValues: state => state.indicatorValues,
};

const actions = {
    async fetchComformanceIndicator({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/conformance/'+ employeeInfo.campaign +'/' + employeeInfo.EmpID;
        await axios.get(endpoint).then((response)=>{
            commit('setConformanceIndicator',response.data.conformance_value);
        });
    },
    async fetchScheduledTime({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/schedule/'+ employeeInfo.campaign +'/'+ employeeInfo.EmpID +'/duration';
        await axios.get(endpoint).then((response)=>{
            commit('setScheduledTimeIndicator',response.data.scheduled_time);
        });
    },
    async fetchWorkedTime({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/workedTime/'+ employeeInfo.campaign +'/' + employeeInfo.EmpID;
        await axios.get(endpoint).then((response)=>{
            commit('setWorkedTimeIndicator',response.data.worked_time);
        });
    },
    async fetchHoursReview({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/hoursReview/'+ employeeInfo.EmpID
        await axios.get(endpoint).then((response)=>{
            commit('setHoursReviewIndicator',response.data.hoursReview);
        });
    },
    async fetchOpenTickets({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/openTickets/'+ employeeInfo.EmpID
        await axios.get(endpoint).then((response)=>{
            commit('setOpenTicketsIndicator',response.data.open_tickets);
        });
    },
    async fetchFollowUpTickets({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/followUpTickets/'+ employeeInfo.EmpID
        await axios.get(endpoint).then((response)=>{
            commit('setFollowUpTickets',response.data.follow_up_tickets);
        });
    },
    async fetchCoursesPending({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/coursesPending/'+ employeeInfo.EmpID
        await axios.get(endpoint).then((response)=>{
            commit('setCoursesPending',response.data.courses_pending);
        });
    },
    async fetchIncidencesPending({commit}, employeeInfo){
        let endpoint = API_URL + 'api/v2/indicators/incidencesPending/'+ employeeInfo.EmpID
        await axios.get(endpoint).then((response)=>{
            commit('setIncidencesPending',response.data.incidences_pending);
        });
    },
};
const mutations = {
    setConformanceIndicator: (state, value) => {
        state.indicatorValues.conformance = value;
    },
    setScheduledTimeIndicator: (state, value) => {
        state.indicatorValues.scheduled_time = value;
    },
    setWorkedTimeIndicator: (state, value) => {
        state.indicatorValues.worked_time = value;
    },
    setHoursReviewIndicator: (state, value) => {
        state.indicatorValues.hours_review = value;
    },
    setOpenTicketsIndicator: (state, value) => {
        state.indicatorValues.open_tickets = value;
    },
    setFollowUpTickets: (state, value) => {
        state.indicatorValues.follow_up_tickets = value;
    },
    setCoursesPending: (state, value) => {
        state.indicatorValues.courses_pending = value;
    },
    setIncidencesPending: (state, value) => {
        state.indicatorValues.incidences_pending = value;
    },
    
};
export default {
    state,
    getters,
    actions,
    mutations
};
