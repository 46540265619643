import axios        from '@/config/axios'
import {API_URL}    from '@/store/actions/config'

const state = {
    currentElement: '',
    formJson:       [],
    formSaved:      [],
    formContent:    [],
    formInfo:       [],
    formAnswer:     [],
    answersSent:    false,
};

const getters = {
    getCurrentElement:  (state) => state.currentElement,
    getCurrentForm:     (state) => state.formJson,
    getFormContent:     (state) => state.formContent,
    getFormInfo:        (state) => state.formInfo,
    getFormAnswer:      (state) => state.formAnswer
};

const actions = {
    setElementSelected ({ commit }, elementName ){ 
        commit('elementSelected', elementName);
    },
    addElementToForm ({ commit }, elementJson ){  
        commit('pushElement', elementJson);
    },
    async storeForm({commit}, formData){
        await axios.post('api/v2/formBuilder/new', formData).then((response)=>{
            commit('setFormSaved', response.data);
        });
    },
    // Para la visualizacion de la info
    async fetchFormContent ( {commit}, data){
        const endpoint = API_URL + `api/v2/formBuilder/forms/${data}`;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setFormContent', res.data.content);
            commit('setFormInfo', res.data.info);
            return res;
        });
        return response;
    },

    async sendFormAnswers({commit}, data){
        await axios.post('api/v2/formBuilder/answers', data).then((res)=>{
            commit('setAnswersSent', true);
            return res;
        });
    },
};

const mutations = {
    setCurrentElement:  (state, elementName)    => { state.currentElement = elementName; },
    setFormSaved:       (state, response)       => { state.formSaved = response; },
    pushElement:        (state, jsonForm)       => { 
        state.formJson.push(jsonForm); 
    },
    setFormContent:     (state, formContent)    => { state.formContent = formContent; },
    setFormInfo:        (state, formInfo)       => { state.formInfo = formInfo; },
    setFormAnswer:      (state, formAnswer)     => { state.formAnswer = formAnswer;},
    setAnswersSent:     (state, answersSent)    => { state.answersSent = answersSent;}
};

export default {
    state,
    getters,
    actions,
    mutations
};