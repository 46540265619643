<template>
    <b-container v-if="this.showTable" class="py-3 rounded-md">
      <div class="custom-control custom-switch mt-2 mb-2">
          <input v-model="toggle" @change="toggleChange" type="checkbox" class="custom-control-input" id="customSwitch1">
          <label v-if="!toggle" class="custom-control-label" for="customSwitch1">Inactive Surveys</label>
          <label v-else class="custom-control-label" for="customSwitch1">Active Surveys</label>
      </div>
      <table class="divide-y divide-gray-200 border-b border-gray-200 w-full shadow-md">
        <thead class="bg-gray-50">
          <tr>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">ID</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Title</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Description</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Period</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Interval Days</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Created by</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Created at</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col">Status</th>
            <th class="py-3 px-2 uppercase text-xs font-medium text-gray-500 text-center" scope="col"></th>
          </tr>
        </thead>
        <tbody v-if="getSurveys.length" class="bg-white divide-y divide-gray-200">
          <tr :id="survey.id" v-for="survey in SurveysSelected" :key="survey.id">
              <td class="p-2">{{survey.id}}</td>
              <td class="p-2">{{survey.Title}}</td>
              <td class="p-2">{{survey.Description}}</td>
              <td class="p-2">{{survey.Active_From | moment("YYYY-MM-DD")}} - {{survey.Active_To == null ? "Undefined" : survey.Active_To | moment("YYYY-MM-DD")}}</td>
              <td class="p-2">{{survey.IntervalDays}} Day(s)</td>
              <td class="p-2">{{survey.Created_by}}</td>
              <td class="p-2">{{survey.Created_at | moment("YYYY-MM-DD HH:mm")}}</td>
              <td class="p-2 align-items-center justify-content-center">
                  <div :class="{ hide: survey.Status}" class="align-items-center justify-content-center rounded-full ml-3 h-4 w-4 text-white bg-gradient-to-r from-red-700 to-red-300"></div>
                  <div :class="{ hide: !survey.Status}" class="align-items-center justify-content-center rounded-full ml-3 h-4 w-4 text-white bg-gradient-to-r from-green-700 to-green-300"></div>
              </td>
              <td class="p-2">
                <vs-tooltip v-if="!survey.Status" text="Enable Survey"><svg @click="openThis(survey.id)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" style="cursor:pointer"></path></svg></vs-tooltip>
                <vs-tooltip v-else text="Disable Survey"><svg @click="closeThis(survey.id)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" style="cursor:pointer"></path></svg></vs-tooltip>
              </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="py-3 px-2 uppercase bg-white text-xs font-medium text-gray-700 text-center" colspan="9">No records found.</td>
          </tr>
        </tbody>
      </table>
    </b-container>

    <div v-else class="text-center py-5">
      <p class="font-italic font-weight-bold">Loading...</p>
      <p class="font-italic font-weight-bold">
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </p>              
        
    </div>
</template>
<script>

  import {mapGetters,mapState, mapActions } from 'vuex'
  export default {
    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        showTable: false,

        SurveysSelected: null,
        SurveyFilter: null,
        SurveysActive: null,
        SurveysInactive: null,
        toggle: true,
      }
    },
    components: {
      // Loading,
    },
    computed:{
      ...mapState(['surveys']),
      ...mapGetters(['getSurveys','getLoadingTable'])
    },
    created(){
      this.$store.dispatch('fetchSurveys').then(()=>{
        this.filterItems(this.getSurveys);
      })
    },
    methods:{
      ...mapActions(['fetchSurveys',
      'changeActive']),

      toggleChange: function(){
        this.SurveyFilter === 1 ? this.SurveysSelected = this.SurveysInactive : this.SurveysSelected = this.SurveysActive;
        this.SurveyFilter === 1 ? this.SurveyFilter = 0 : this.SurveyFilter = 1;
      },

      filterItems: function (items){
        const result = items.filter(item => item.Status === 1);
        this.SurveysActive = result;
        this.SurveysSelected = this.SurveysActive;
        this.SurveyFilter = 1;

        const result2 = items.filter(item => item.Status === 0);
        this.SurveysInactive = result2;

        this.showTable = true;
      },

      closeThis(data){

        this.$confirm("It won't be shown at the Profile's Survey section", "Do you want to disable Survey ID: "+data+"?", "question").then((result) => {
            if (result){
                let objData = { surveySelected: data }
                this.changeActive(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Survey Disabled",
                      showConfirmButton: false,
                      timer: 2300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
        })

      },

      openThis(data){
        this.$confirm("It will be shown at the Profile's Survey section", "Do you want to enable Survey ID: "+data+"?", "question").then((result) => {
            if (result){
                let objData = { surveySelected: data }
                this.changeActive(objData).then(()=>{
                    this.$fire({
                      type: "success",
                      title: "Survey Enabled",
                      showConfirmButton: false,
                      timer: 1300,
                    }).then(()=>{
                    this.$emit('updateAll', true);                
                    }); 
                });
            }
        })
      }

    },
  }

</script>
<style lang="scss" scoped>
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>