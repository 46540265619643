<template>
    <div class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1 m-auto mx-2">

      <year @toggle="updateFilters"/>

      <week  class="ml-2" @toggle="updateFilters"/>
    
    </div>
                                           
    
</template>
<script>
import Debounce         from 'debounce';
import year         from './filter-by-year.vue';
import week         from './filter-by-week.vue';

export default {
    data(){
        return{
            form:{
                yearSelected:   null,
                weekSelected:   null,
            }
        }
    },
    methods:{
        updateFilters:Debounce(function(res){
            this.form[res.key] = res.value;
            this.$emit('toggle', this.form);
        },500)

    },
    components:{
        year,
        week
    },
    mounted(){
    }

}
</script>