<template>
    <div class="py-3">
        <modal :show="show_edit_modal" @close="hideModals()">
            <event-edition :event="edit_content" :shift="edit_content" @close="hideModals()" />
        </modal>
        <modal :show="show_comments_modal" @close="hideModals()">
            <event-comments :comments="comments_content" />
        </modal>
        <modal :show="show_new_modal" @close="hideModals()">
            <add-event :shift="new_content" @close="hideModals()" />
        </modal>
        <modal :show="show_shift_modal" @close="hideModals()">
            <add-shift :employee="employee" @close="hideModals()" />
        </modal>
        <modal :show="show_delete_modal" @close="hideModals()">
            <delete-event :event="delete_content" @close="hideModals()" />
        </modal>
        <div class="space-y-3">
            <div class="flex justify-end">
                <button
                    class="bg-c-primary disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="showNewShiftModal"
                >
                    Add New Shift
                </button>
            </div>
            <div v-if="events.length && events.length === 0">Not found</div>
            <div v-else></div>
            <div v-if="thereIsEvents">
                <div v-for="shift in events" :key="shift.id">
                    <accordion-simple>
                        <template v-slot:title>
                            <div class="relative">
                                <div
                                    class="font-bold text-gray-700/15 text-5xl absolute -left-6 -top-8 text-nowrap"
                                >
                                    ID {{ shift.id }}
                                </div>
                            </div>
                            <div
                                class="flex justify-between items-center w-full relative"
                            >
                                <span class="flex gap-2">
                                    {{ shift.lob }} Shift
                                    <div
                                        class="rounded-full px-2 py-1 bg-green-600/70 border-1 border-green-400 text-white text-sm font-semibold text-center justify-center flex"
                                    >
                                        {{ secondsToTime(shift.duration) }}
                                    </div>
                                </span>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="space-y-2">
                                <div class="flex justify-end gap-2">
                                    <vs-tooltip text="See comments">
                                        <button
                                            v-if="
                                                shift.comments.length &&
                                                shift.comments.length > 0
                                            "
                                            @click="
                                                showCommentsModal(
                                                    shift.comments
                                                )
                                            "
                                            class="h-7 w-7 bg-gray-200 flex justify-center items-center rounded-full group hover:bg-gray-300"
                                        >
                                            <i
                                                class="bi bi-chat-square-dots-fill opacity-40 group-hover:opacity-100 mt-1"
                                            ></i>
                                        </button>
                                    </vs-tooltip>
                                    <vs-tooltip text="Edit Shift">
                                        <button
                                            @click="showEditModal(shift)"
                                            class="h-7 w-7 bg-gray-200 flex justify-center items-center rounded-full group hover:bg-gray-300"
                                        >
                                            <i
                                                class="bi bi-pen-fill opacity-40 group-hover:opacity-100"
                                            ></i>
                                        </button>
                                    </vs-tooltip>
                                    <vs-tooltip text="Delete Shift">
                                        <button
                                        @click="showDeleteModal(shift)"
                                            class="h-7 w-7 bg-red-300 flex justify-center items-center rounded-full group hover:bg-red-500 text-gray-50"
                                        >
                                            <i
                                                class="bi bi-trash3-fill opacity-40 group-hover:opacity-100"
                                            ></i>
                                        </button>
                                    </vs-tooltip>
                                </div>
                                <div
                                    class="flex justify-center items-center relative"
                                >
                                    <div
                                        class="inline-flex justify-center space-x-3 items-center rounded-full bg-gray-50 p-2 px-4"
                                    >
                                        <div class="flex flex-col justify-center items-center">
                                            <span class="font-semibold">
                                                {{shift.start_date}}
                                            </span>
                                            <span class="font-semibold">
                                                {{ shift.start_time }}
                                            </span>
                                        </div>
                                        <span
                                            class="h-4 w-4 rounded-full bg-c-alt"
                                        >
                                        </span>
                                        <div>
                                            <div
                                                class="min-w-[200px] border-b border-x-secondary border-gray-200"
                                            ></div>
                                        </div>
                                        <span
                                            class="relative flex h-4 w-4"
                                            v-if="isOngoing(shift.end_time)"
                                        >
                                            <span
                                                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600/70 opacity-75"
                                            ></span>
                                            <span
                                                class="relative inline-flex rounded-full h-4 w-4 bg-green-500/70"
                                            ></span>
                                        </span>
                                        <span v-else class="h-4 w-4 rounded-full bg-c-alt"></span>
                                        <span class="uppercase font-semibold"></span>
                                        <div class="flex flex-col justify-center items-center">
                                            <span class="font-semibold" v-if="shift.end_date">
                                                {{shift.end_date}}
                                            </span>
                                            <span class="font-semibold">
                                                {{ endTime(shift.end_time) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-sm text-gray-500">
                                    This shift was tracked by the LOB:
                                    {{ shift.lob }} as the agent was working
                                    {{ isWfh(shift.is_wfh) }}.
                                </span>
                                <events-time-line
                                    :events="shift.events"
                                    :shift="shift"
                                    @refresh="$emit('refresh')"
                                />
                                <div class="flex justify-center mt-4">
                                    <button
                                        class="bg-c-primary disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                                        @click="showNewEventModal(shift)"
                                    >
                                        Add New Event
                                    </button>
                                </div>
                            </div>
                        </template>
                    </accordion-simple>
                </div>
            </div>
            <div v-else>
                <div class="bg-white rounded-xl p-4">
                    <illustration width="48" class="opacity-70">
                        <template slot="headline">No shifts found</template>
                        <template slot="subline">
                            There is no shift to show at the moment. You can try it later.
                        </template>
                    </illustration>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from "@/views/Utilities/Modal";
import AccordionSimple from "@/views/Utilities/AccordionSimple";
import EventsTimeLine from "@/views/clocker/adjustments/components/EventsTimeLine";
import EventEdition from "@/views/clocker/adjustments/components/EventEdition";
import EventComments from "@/views/clocker/adjustments/components/EventComments";
import AddEvent from "@/views/clocker/adjustments/components/AddEvent";
import AddShift from "@/views/clocker/adjustments/components/AddShift";
import Illustration from "@/views/mockups/IllustrationTemplate";
import DeleteEvent from "@/views/clocker/adjustments/components/DeleteEvent"
export default {
    components: {
        Modal,
        AccordionSimple,
        EventsTimeLine,
        EventEdition,
        EventComments,
        AddEvent,
        AddShift,
        DeleteEvent,
        Illustration,
    },
    props: {
        events: Array,
        employee: {
            type: String,
            required: false,
            default() {
                return this.$route.params.emp_id;
            },
        },
    },
    data() {
        return {
            show_edit_modal: false,
            edit_content: [],
            show_comments_modal: false,
            comments_content: [],
            show_new_modal: false,
            new_content: [],
            show_shift_modal: false,
            delete_content: [],
            show_delete_modal: false
        };
    },
    computed: {
        thereIsEvents() {
            return this.events?.length > 0;
        },
    },
    methods: {
        isWfh(wfh) {
            return wfh ? "at home" : "on site";
        },

        isOngoing(status) {
            return status === null;
        },

        endTime(end_time) {
            return end_time === null ? "on-going" : end_time;
        },

        secondsToTime(seconds) {
            if (seconds === null) return this.endTime(seconds)?.toUpperCase();

            let h = Math.floor(seconds / 3600);
            let m = Math.floor((seconds % 3600) / 60);
            let s = seconds % 60;

            return `${h}h ${m}m ${s}s`;
        },

        showEditModal(event) {
            this.edit_content = event;
            this.show_edit_modal = true;
        },

        showCommentsModal(comments) {
            this.comments_content = comments;
            this.show_comments_modal = true;
        },

        showNewEventModal(shift) {
            this.new_content = shift;
            this.show_new_modal = true;
        },

        showNewShiftModal() {
            this.show_shift_modal = true;
        },

        showDeleteModal(shift){
            this.delete_content = shift;
            this.show_delete_modal = true;
        },

        hideModals() {
            this.show_edit_modal = false;
            this.edit_content = [];
            this.show_comments_modal = false;
            this.comments_content = [];
            this.show_new_modal = false;
            this.new_content = [];
            this.show_shift_modal = false;
            this.show_delete_modal = false;
            this.delete_content = [];
            this.$emit("refresh");
        },
    },
};
</script>