<template>
    <div>
        <return-off-site v-if="isOffSite"></return-off-site>
        <return-on-site v-else></return-on-site>
    </div>
</template>
<script>
import returnOffSite from '@/views/inventory/Forms/return.vue';
import returnOnSite from '@/views/inventory/Forms/returnOnSite.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        returnOffSite,
        returnOnSite
    },
    data(){
        return {
        }
    },
    computed:{
        ...mapGetters([
            'getItemData'
        ]),

        isOffSite(){
            return this.getItemData.Use_Type === 'off-site'
        }
    },
    mounted(){
        let title = 'Inventory: Return Item';
        this.$emit('set-title',title);
    },
}
</script>
