<template>
<div class="h-[96%]">
    <vault-confirmation class="w-96 mx-auto" @handleIdentity="(res) => { this.identityConfirmed = res }" v-if="!identityConfirmed"/>
    <div v-else class="h-full">
        <div class="justify-right items-right text-right mt-3 px-3">
            <button class="bg-blue-500 text-white rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" @click="() => { mode = 'new' }">
                add new <i class="bi bi-plus-circle-fill"></i>
            </button>
        </div>
        <passwords-list :key="reload" @selection="selectAPassword"/>
        <div class="bg-gray-200 rounded-lg p-4 m-2 my-2 space-y-4" v-if="isEditMode">
            <form autocomplete="off" class="grid grid-cols-2 gap-3">
                <div class="text-left">
                    <label for="password" class="text-xs text-left">Application Name</label>
                    <div class="relative p-2 bg-gray-300 border-b-2" 
                    :class="{ 
                        'bg-transparent border-blue-400 transition-all duration-300' : editButton & !$v.password_selected.label.$invalid, 
                        'bg-transparent border-red-500 transition-all duration-300' : editButton & $v.password_selected.label.$invalid,
                        'rounded-lg' : !editButton,
                    }">
                        <input type="text" :readonly="!editButton" v-model="password_selected.label"
                        class="bg-transparent">
                    </div>
                </div>
                <div class="text-left">
                    <label for="password" class="text-xs text-left">Site (optional)</label>
                    <div class="relative p-2 bg-gray-300 border-b-2 overflow-hidden text-ellipsis" 
                    :class="{ 
                        'bg-transparent border-blue-400 transition-all duration-300' : editButton & !$v.password_selected.site.$invalid, 
                        'bg-transparent border-red-500 transition-all duration-300' : editButton & $v.password_selected.site.$invalid,
                        'rounded-lg' : !editButton,
                    }">
                        <input id="inputSite" part="input" aria-label="Password" aria-invalid="false" tabindex="0" type="text" v-model="password_selected.site"
                        class="bg-transparent w-full" v-if="editButton">
                        <a v-else :href="password_selected.site" target="_blank" class="truncate"><i class="bi bi-box-arrow-up-right mr-2"></i>{{ password_selected.site }}</a>
                    </div>
                </div>
                <div class="text-left">
                    <label for="password" class="text-xs text-left">Username</label>
                    <div class="relative p-2 bg-gray-300 border-b-2 overflow-hidden text-ellipsis" 
                    :class="{ 
                        'bg-transparent border-blue-400 transition-all duration-300' : editButton & !$v.password_selected.username.$invalid, 
                        'bg-transparent border-red-500 transition-all duration-300' : editButton & $v.password_selected.username.$invalid,
                        'rounded-lg' : !editButton,
                    }">
                        <input id="inputUsername" part="input" aria-label="Password" aria-invalid="false" tabindex="0" type="text" :readonly="!editButton" v-model="password_selected.username"
                        class="bg-transparent w-full truncate">
                        <div class="absolute inset-y-0 right-0 flex items-center text-sm pr-2" v-if="!editButton">
                            <i class="bi bi-clipboard hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="copyToClipboard('inputUsername')"></i>
                        </div>
                    </div>
                    <span class="text-xs" :class="{'hidden' : !username_copied}">Copied to clipboard!</span>
                </div>
                <div class="text-left">
                    <label for="password" class="text-xs text-left">Password</label>
                    <div class="relative p-2 bg-gray-300 border-b-2" 
                    :class="{ 
                        'bg-transparent border-blue-400 transition-all duration-300' : editButton & !$v.password_selected.password.$invalid, 
                        'bg-transparent border-red-500 transition-all duration-300' : editButton & $v.password_selected.password.$invalid,
                        'rounded-lg' : !editButton,
                    }">
                        <input id="inputPassword" part="input" aria-label="Password" aria-invalid="false" tabindex="0" :type="show ? 'text' : 'password'" :readonly="!editButton" v-model="password_selected.password"
                        class="bg-transparent">
                        <div class="absolute inset-y-0 right-0 flex items-center text-sm pr-2">
                            <i class="bi bi-eye-fill  hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'block': !show, 'hidden':show }"></i>
                            <i class="bi bi-eye-slash-fill hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'hidden': !show, 'block':show }"></i>
                            <i class="bi bi-clipboard hover:bg-gray-100/30 px-2 py-1 rounded-full" v-if="!editButton" @click="copyToClipboard('inputPassword')"></i>
                        </div>
                    </div>
                    <span class="text-xs" :class="{'hidden' : !password_copied}">Copied to clipboard!</span>
                </div>
            </form>
            <div class="text-left space-x-2" v-if="!loading && editButton">
                <button type="button" class="bg-gray-50 rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" @click="switchEditMode('cancel')">
                    Cancel
                </button>
                <button type="button" class="bg-red-500 text-white rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" v-if="editButton" @click="changePassword()">
                    send
                </button>
            </div>
            <div class="text-left space-x-2" v-else-if="!loading && !editButton">
                <button type="button" class="bg-gray-50 rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" @click="switchEditMode()">
                    Edit
                </button>
                <button type="button" class="bg-red-500 text-white rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" @click="deletePassword">
                    Delete <i class="bi bi-trash-fill"></i>
                </button>
            </div>
            <div class="mb-4" v-else>
                <div class="animate-spin">
                    <i class="bi bi-boxes text-3xl"></i>
                </div>
            </div>
        </div>
        <store-vault @toggle="() => { reload++ }" v-if="isNewMode" />
    </div>
</div>
</template>
<script>
import deepcopy from 'lodash/cloneDeep';
import { required, url } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import PasswordsList from '@/views/employees/profile/views/components/passwordsList';
import StoreVault from '@/views/employees/profile/views/components/addNewPassword';
import VaultConfirmation from '@/views/employees/profile/views/modals/vaultConfirmation';
// import debounce from 'debounce';
export default {
    components:{
        PasswordsList,
        StoreVault,
        VaultConfirmation
    },
    data() {
        return {
            reload: 0,
            show: false,
            editButton: false,
            mode: 'none',
            loading: false,
            username_copied: false,
            password_copied: false,
            password_selected: null,
            password_backup: null,
            identity_status: false,
            identityConfirmed: false,
        }
    },
    validations () {
        return {
            password_selected: {
                label: {
                    required
                },
                site: {
                    url
                },
                username: {
                    required
                },
                password: {
                    required
                }
            }
        }
    },
    watch: {
        password_selected: {
            handler(){
                this.$v.$touch()
            },
            deep: true
        }
    },
    computed: {
        passwordIsSelected () {
            return this.password_selected !== null;
        },
        isEditMode () {
            return this.mode === 'edit'
        },
        isNewMode () {
            return this.mode === 'new'
        },
    },
    methods: {
        ...mapActions([
            'editPasswordVault',
            'deletePasswordVault'
        ]),
        copyToClipboard(element) {
            const el = document.getElementById(element);
            el.select();
            document.execCommand('copy');

            if (element === "inputPassword") {
                this.username_copied = false;
                this.password_copied = true;
            } else {
                this.username_copied = true;
                this.password_copied = false;
            }
        },
        selectAPassword(item){
            this.editButton = false;
            this.mode = 'edit';
            this.password_backup = deepcopy(item);
            this.password_selected = deepcopy(this.password_backup);
            this.username_copied = false;
            this.password_copied = false;
        },
        switchEditMode (mode) {
            if (mode === 'cancel') {
                this.password_selected = deepcopy(this.password_backup);
            }
            this.editButton = !this.editButton;
        },
        changePassword () {
            if(this.$v.$invalid) return;
            this.switchEditMode();
            this.loading = true;
            this.editPasswordVault(this.password_selected).then(()=>{
                this.loading = false;
                this.reload++;
            });
        },
        deletePassword () {
            this.$confirm("This action is irreversible", "Do you want to delete this password?", "question").then((result) => {
                if(result){
                    this.loading = true;
                    this.deletePasswordVault(this.password_selected.id).then(()=>{
                        this.mode = 'none';
                        this.loading = false;
                        this.reload++;
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
.input-border-animation {
    border-bottom-width: 0;
}

.input-border-animation:focus,
.input-border-animation:active {
    border-bottom-width: 2px;
    border-color: #163edd; /* Change this color to your desired border color */
}
</style>