import axios from '@/config/axios';

const state = {
    list: [],
    status: null,
    requests_list: [],
    file: null,
    list_details: null,
    filters: [],
    message: null,
};
const getters = {
    _documents_filters:         (state) => state.filters,
    _employee_file:             (state) => state.file,
    _employee_file_details:     (state) => state.list_details, 
    _employee_file_list:        (state) => state.list,
    _employee_file_requests:    (state) => state.requests_list,
    _employee_file_message:     (state) => state.message
};
const actions = {
    async fetchDocumentsFilters({commit}) {
        try {
            const response = await axios.get(`api/v4/files/filters`);
            commit('SET_FILTERS', response.data);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchDocumentsList({commit}, employee) {
        try {
            const response = await axios.get(`api/v4/files/list/filter/${employee}`);
            commit('SET_LIST', response.data.list);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchDocumentsListDetails({commit}, id) {
        try {
            const response = await axios.get(`api/v4/files/list/${id}`);
            commit('SET_LIST_DETAILS', response.data.details);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchDocumentRequestDetails({commit}, data) {
        try {
            const response = await axios.get(`api/v4/files/documents/requests/${data}`);
            commit('SET_DOCUMENT', response.data);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchEmployeeDocument({commit}, data) {
        try {
            const response = await axios.get(`api/v4/files/employees/${data.employee_id}/documents/${data.document_id}`);
            commit('SET_DOCUMENT', response.data.document);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchEmployeeDocsRequests({commit}, data) {
        try {
            const response = await axios.get(`api/v4/files/employees/${data.employee_id}/documents/${data.document_id}/requests`);
            commit('SET_REQUESTS_LIST', response.data.history);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async fetchDocumentsRequests({commit}, data) {
        try {
            const response = await axios.post(`api/v4/files/documents/requests`, data);
            commit('SET_REQUESTS_LIST', response.data.requests);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async changeDocumentRequestStatus({commit}, data) {
        try {
            const response = await axios.get(`api/v4/files/documents/requests/${data.id}/status/${data.status}`);
            commit('SET_REQUESTS_LIST', response.data);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async uploadFileDocument({commit}, data) {
        try {
            const response = await axios.post(`api/v4/files/employees/${data.get('employee_id')}/documents/${data.get('document_id')}/upload`, data);
            commit('SET_REQUESTS_LIST', response.data);
        }
        catch (error) {
            commit('SET_ERROR', error);
        }
    },
};

const mutations = {
    SET_FILTERS: (state, filters) => { state.filters = filters },
    SET_LIST: (state, list) => { state.list = list},
    SET_LIST_DETAILS: (state, list_details) => { state.list_details = list_details},
    SET_DOCUMENT: (state, file) => { state.file = file },
    SET_REQUESTS_LIST: (state, requests_list) => { state.requests_list = requests_list },
    SET_ERROR: (state, status) => { state.error = status },
    SET_MESSAGE: (state, status) => { state.message = status }
};

export default {
    state,
    actions,
    getters,
    mutations,
};