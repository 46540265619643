import axios        from 'axios'
import auth   from "@/config/user"
import {API_URL}    from '@/store/actions/config'
const  http = axios.create({
    baseURL: API_URL ,
    headers: {
        Accept: 'application/json'
    }
});
http.interceptors.request.use(function(config){
    const auth_token = auth.currentUser.token;
    config.headers['Authorization'] = (auth_token !== undefined) ? `Bearer ${auth_token}`: 'noToken';
    config.headers['Accept']        = 'application/json';
    return config;
}, function(err) {
    return Promise.reject(err);
});

http.interceptors.response.use(
    function(response){ 
        return response;
    }, 
    function(error){
        switch(error?.response?.status){
            case 401:
                if(error.response.data.type == 'login'){
                    return Promise.reject(error);     
                }else{
                    sessionStorage.clear();
                    window.location.href = '/';
                }  
            break;
            case 500:
               /* this.$fire({
                    type: 'error',
                    title: 'We have an error, please report to the administrator',
                    showConfirmButton: true
                });*/
                return Promise.reject(error);
            default:
               /* this.$fire({
                    type: 'error',
                    title: 'We have an error, please report to the administrator',
                    showConfirmButton: true
                });*/
                return Promise.reject(error);
        }   
    }
);

export default http;