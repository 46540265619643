import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    surveyList:     [],
    surveyInfo:     [],
    questionsInfo:  [],
    questionsArray: [],
    optionsInfo:    [],
    surveyAnswers:  [],
    table_loading:  true,
    surveyStatus:   false,
    campaignList:   []
};
const getters = {
    getSurveys:         (state) => state.surveyList,
    getSurveyInfo:      (state) => state.surveyInfo,
    getQuestionsInfo:   (state) => state.questionsInfo,
    getQuestionsArray:  (state) => state.questionsArray,
    getOptionsInfo:     (state) => state.optionsInfo,
    getSurveyStatus:    (state) => state.surveyStatus,
    getCampaignList:    (state) => state.campaignList
};
const actions = {
    async fetchSurveys({ commit }){    
        const endpoint = API_URL + 'api/v2/surveys/surveyList';
        await axios.get(endpoint).then( (response) =>{
            commit('setSurveyList',response.data.data);
            commit('setLoadingTable',false);
        });
    },

    async fetchMySurveys({ commit }){    
        const endpoint = API_URL + 'api/v2/surveys/mySurveyList';
        await axios.get(endpoint).then( (response) =>{
            commit('setSurveyList',response.data.data);
            commit('setLoadingTable',false);
        });
    },

    async fetchSurveyInfo ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/surveys/surveyInfo';
        const response = await axios.post(endpoint,{
            survey_id: data,
        });
        commit('setSurveyInfo', response.data[0]);
    },

    async fetchQuestionsInfo ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/surveys/questionsInfo';
        const response = await axios.post(endpoint,{
            survey_id: data,
        })
        commit('setQuestionsInfo', response.data);
    },

    async fetchQuestionsArray ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/surveys/questionsArray';
        const response = await axios.post(endpoint,{
            survey_id: data,
        })
        commit('setQuestionsArray', response.data);
        return response.data
    },

    async fetchOptionsInfo ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/surveys/optionsInfo';
        const response = await axios.post(endpoint,{
            survey_id: data,
        })
        commit('setOptionsInfo', response.data);
    },

    async fetchSurveyStatus ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/surveys/surveyStatus';
        const response = await axios.post(endpoint,data).then((result) => {
            commit('setSurveyStatus', true);
            return result;
        });
        return response;
    },

    async createSurvey ({commit}, data ){        
        const endpoint = API_URL + 'api/v2/surveys/addSurvey'
        const response = await axios.post(endpoint, data);
        commit('setSurveyList',null);
        return response
    },

    async sendSurveyAnswers({commit}, data ){        
        const endpoint = API_URL + 'api/v2/surveys/addEntries'
        const response = await axios.post(endpoint, data);
        commit('setSurveyAnswers', null);
        return response.data
    },

    async changeActive({commit}, data){
        const endpoint = API_URL + 'api/v2/surveys/changeActive';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setSurveyStatus', true);     
            return res;
        });
        return response;
    },

    async fetchCampaignsGroups({ commit }){    
        const endpoint = API_URL + 'api/v2/surveys/campaignsSeparation';
        await axios.get(endpoint).then( (response) =>{
            commit('setCampaignList',response.data.data);
        });
    },

};
const mutations = {
    setSurveyList:      (state, surveys)        => (state.surveyList        = surveys),
    setSurveyInfo:      (state, surveyInfo)     => (state.surveyInfo        = surveyInfo),
    setQuestionsInfo:   (state, questionsInfo)  => (state.questionsInfo     = questionsInfo),
    setQuestionsArray:  (state, questionsArray) => (state.questionsArray    = questionsArray),
    setOptionsInfo:     (state, optionsInfo)    => (state.optionsInfo       = optionsInfo),
    setSurveyStatus:    (state, surveyStatus)   => (state.surveyStatus      = surveyStatus),
    setLoadingTable:    (state, newFlag)        => (state.table_loading     = newFlag),
    setSurveyAnswers:   (state, surveyAnswers)  => (state.surveyAnswers     = surveyAnswers),
    setCampaignList:    (state, campaignList)   => (state.campaignList      = campaignList)
};
export default {
    state,
    getters,
    actions,
    mutations
};