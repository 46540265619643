<template>
  <div class="rounded-xl relative w-full overflow-hidden mx-auto py-2 bg-gray-100 p-3">
      <!-- file area -->
      <dropzone v-on:file-dragged="setFile" v-if="!fileIsLoaded"></dropzone>

      <div v-else class="relative ring-2 border-8 border-white rounded-md shadow-sm">
          <button @click="reset" class="absolute -top-4 border-4 border-white -right-4 shadow-md z-20 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
          </button>
          <img :src="fileUrl" class="w-full h-44" alt="proof_image"/>
      </div>
      <!-- details area -->
  </div>
</template>

<script>
import Dropzone from "@/views/Utilities/Dropzone";
import {VALID_FILE_TYPES} from '@/store/actions/file';
import {mapGetters,mapActions} from 'vuex';
export default {
name: "DragAndDrop",
  components: {
    Dropzone,
  },
  data(){
    return {
      file:null,
      maxSize:3000000,
      convertRate:1048576,
      fileUrl:null
    }
  },
  methods:{
    ...mapActions(['uploadFile']),
    setFile:function(file){
        if(file.size > this.maxSize) {
          this.$fire({
            type: "warning",
            title: "File size is too large",
            showConfirmButton: true
          })
          return;
        }
        this.file = file;
        this.fileUrl=URL.createObjectURL(this.file);
        this.$emit('fileSelected',this.file);
    },
    reset:function(){
        this.fileUrl = null;
        this.file = null;
        this.$emit('clear');
    },
    
   
  },
  computed:{
    ...mapGetters(['getFileName']),
    fileIsLoaded:function(){
      return this.file !== null && this.file instanceof File;
    },
    validType:function(){
      return !!VALID_FILE_TYPES.includes(this.file.type);
    },
    validSize:function(){
      return this.file.size <= this.maxSize;
    },
    validFile:function(){
      return this.fileIsLoaded && this.validSize && this.validType;
    },
    isPdf:function(){
      return this.file.type ==='application/pdf';
    },
     
  },
}
</script>

