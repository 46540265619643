<template>
    <tr>
        <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
            <select required @change="setDescription" v-model="form.activity_id" class="border-b-4 border-gray-200 rounded-sm focus:border-blue-400 bg-gray-50 px-3 py-2 w-full text-sm text-gray-400">
                <option hidden value="">Select an activity...</option>
                <option :value="activity.id" v-for="activity,index in _activities" :key="'activities'+ index">{{activity.label}}</option>
            </select>
        </td>
    
        <td scope="col" class="w-1/2 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
            <span v-if="!customMode">
                {{form.description}}
            </span>
            <div v-else>
                <input placeholder="Enter a description here..." v-model="description" class="border-b-4 border-gray-200 rounded-sm focus:border-blue-400 bg-gray-50 px-3 py-2 w-full text-sm text-gray-400">
            </div>
        </td>
        <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
            {{emp_id}}
        </td>
        <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
            <div class="relative w-full flex items-center justify-end">
                <button @click.prevent="submit" type="button" class="relative bg-green-400 hover:bg-green-600 text-white px-3 py-2 rounded-sm shadow-sm ml-auto">
                    Save
                </button>
            </div>
        </td>
    </tr>
    </template>
    
    <script>
    import {mapActions, mapGetters} from 'vuex'
    // Pinia > Vuex
    export default {
        data(){
            return{
                customMode:false,
                form:{
                    description:"",
                    author:this.emp_id,
                    training_id: this.$route.params.id,
                    activity_id:""
                }
            }
        },
        computed:{
            ...mapGetters([
                "_activities",
                "_activitiesList",
            ]),
        },
        methods:{
            ...mapActions(['fetchTrainingActivities']),
            setDescription(){
                console.log(this.form.activity_id);
                const description = this._activities.find( itm => itm.id === this.form.activity_id).label;
                if(description.toLowerCase() === 'other'){
                    this.description = "";
                    return this.customMode = true;                
                }
                this.form.description = description;
                this.customMode = false;   
            },
            submit(){
                 if(this.form.activity_id === ""){
                    this.$fire({type:'warning', text:'Please fill all the required fields in order to proceed.'})
                }
                if(this.form.description === ""){
                    this.$fire({type:'warning', text:'Please fill all the required fields in order to proceed.'})
                }
                alert(JSON.stringify(this.form));
               
            
            }
        },
        mounted(){
            this.fetchTrainingActivities();
        }
    }
    </script>
    
    <style>
    </style>