import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    reportContent: []
};

const getters = {
   getReportContent: state => state.nextEvent,
};

const actions = {
    async fetchBillingReport({commit}, formData){

        let endpoint = API_URL + 'api/mrt/reports/billing/' + formData.fromDate +'/'+formData.toDate+'/'+formData.site+'/'+ formData.campaign;
        await axios.get(endpoint).then((response)=>{
            commit('setReportData',response.status);
        });
    },
};
const mutations = {
    setReportData: (state, content) => {
        state.reportContent = content;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};
