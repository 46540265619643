<template>
  <div class="terminationModalContainer">
    <loading id='ModalLoader' :active.sync="getLoadingModal" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <div class="d-block text-center">
      <h3>Change Password</h3>
    </div>
    <div>
      <b-form ref="content" @submit="changePassword">
        <div class="mt-3 text-center">
          <label>Current User: <b>{{ this.employee }}</b></label>
        </div>
        <div class="flex flex-col justify-center mt-3 mb-6">
          <div class="flex space-x-5">
            <label class="m-0" for="reason">Reason:</label>
            <div class="flex-1">
              <select id="reason" :class="{'border-red-600': (submitted && $v.reason.$error) && ( this.reason == null ), 'border-blue-300': !((submitted && $v.reason.$error) && ( this.reason == null )) }" v-model="reason"
              class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 border-b-2 h-7">
                <option value="null">Please select a reason...</option>
                <option value="fts">First time setup</option>
                <option value="afp">Forgotten Password</option>
              </select>
              <span v-if="submitted && !$v.reason.required" class="animate-ping absolute top-1 right-4 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
            </div>
          </div>
          <div v-if="submitted && !$v.reason.required" class="flex">
            <span class="ml-1 my-auto text-red-500 text-xs">Please, select a reason</span>
          </div>
        </div>
        <div class="flex space-x-5">
          <div class="flex-1">
            <p class="uppercase text-sm text-slate-800 text-left mb-0">New password</p>
            <input :class="{'border-red-500 border-b-2': submitted && $v.newPassword.$error }" @input="scorepassword" v-model="newPassword" maxlength="21" type="password" placeholder="..." class="border w-full rounded-lg px-6 py-2 text-lg">
            <div v-if="submitted && !$v.newPassword.required" class="animate-ping absolute top-1 right-4 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></div>
            <div v-if="submitted && !$v.newPassword.required">This field is required</div>
            <p class="uppercase text-sm text-slate-500 text-right mb-0 mt-1">{{ newPassword.length }}/21</p>
          </div>
          <div class="flex-1">
            <p class="uppercase text-sm text-slate-800 text-left mb-0">Confirm Password</p>
            <input :class="{'border-red-500 border-b-2': submitted && $v.confirmPassword.$error }" v-model="confirmPassword" maxlength="21" type="password" placeholder="..." class="border w-full rounded-lg px-6 py-2 text-lg">
            <div v-if="submitted && !$v.confirmPassword.required & !$v.confirmPassword.sameAsPassword" class="animate-ping absolute top-1 right-4 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></div>
            <div v-if="submitted && !$v.confirmPassword.required & !$v.confirmPassword.sameAsPassword">Password does not match!</div>
          </div>
        </div>
        <div class="my-6 text-slate-400">
          <div class="relative w-full h-2 rounded-full bg-slate-100 overflow-hidden mb-3">
            <div class="absolute inset-0 bg-gradient-to-r from-red-600 via-yellow-400 to-green-600"></div>
            <div class="absolute inset-y-0 right-0 bg-slate-100" :class="'w-['+scoreporc+'%]'"></div>
          </div>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':newPassword.length >= 12}"><i class="bi" :class="newPassword.length >= 12 ? 'bi-check' : 'bi-x'"></i> Min. 12 characters</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_uppercase }"><i class="bi" :class="has_uppercase ? 'bi-check' : 'bi-x'"></i> Has a capital letter</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_lowercase }"><i class="bi" :class="has_lowercase ? 'bi-check' : 'bi-x'"></i> Has a lowercase letter</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_number }"><i class="bi" :class="has_number ? 'bi-check' : 'bi-x'"></i> Has a number</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':has_special }"><i class="bi" :class="has_special ? 'bi-check' : 'bi-x'"></i> Has a special character</p>
          <p class="m-0 leading-none text-left text-sm ml-2" :class="{'text-green-600':!hasnt_user }"><i class="bi" :class="!hasnt_user ? 'bi-check' : 'bi-x'"></i> Must not contain your username</p>
        </div>
        <div class="flex flex-col mt-2">
          <button type="submit" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full" v-if="scoreporc==0 && confirmPassword==newPassword">
            <span v-if="saving" >
                Saving
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 animate-bounce inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
            </span>
            <span v-else>
                Save
            </span>
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    components: {
        Loading
        },
  data() {
    return {
        submitted: false,
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        saving: false,
        reason: null,
        newPassword: "",
        confirmPassword: "",
        postData: [],
        terminationData: [],
        scoreporc: 100,
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        hasnt_user: false
    };
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters(['getLoadingModal'])
  },
  validations: {
    reason:           { required },
    newPassword:      { required },
    confirmPassword:  { 
      sameAsPassword: sameAs('newPassword')
    },
  },
  props: {
    employee: String
  },
  methods: {
    ...mapActions(["setNewPassword"]),
    scorepassword() {
      this.has_number = (/\d/).test(this.newPassword);
      this.has_lowercase = (/[a-z]/).test(this.newPassword);
      this.has_uppercase = (/[A-Z]/).test(this.newPassword);
      this.has_special = (/[ `!@#$%^&*()_+\-={};':"\\|,.<>?~]/).test(this.newPassword);
      this.hasnt_user = (this.newPassword.toLowerCase()).includes(this.employee.toLowerCase());

      var score = 0;
      score = score+(this.newPassword.length >= 12 ? 16.66:0);
      score = score+(this.has_number ? 16.66:0);
      score = score+(this.has_lowercase ? 16.66:0);
      score = score+(this.has_uppercase ? 16.66:0);
      score = score+(this.has_special ? 16.66:0);
      score = score+(this.hasnt_user ? 0:16.7);

      this.scoreporc = 100-score;
    },
    changePassword() {
      event.preventDefault();
      if(this.saving === true) return;
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const postData = {
        employeeID: this.employee,
        reason: this.reason,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };

      this.$confirm("This will change the password from EMS", "Do you want to continue?", "question").then((result) => {
        if (result){
          this.saving = true;
          // console.log(postData)
          this.setNewPassword(postData).then(response => {
            if (response.status == 200) {
              this.$fire({
                type: "success",
                title: "The password has been updated successfully",
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                location.reload();
                this.$emit('closePassword', true);
              });
            } else if(response.status == 400) {
              this.$fire({
                type: 'error',
                title: response.msg,
                showConfirmButton: true
              })
            } else if(response.status == 201) {
              this.$fire({
                type: 'error',
                title: response.data.msg,
                showConfirmButton: true
              })
            }
          });
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
</style>