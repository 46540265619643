<template>
    <div class="">
        <div class="flex flex-col max-w-5xl bg-white shadow-lg rounded-lg p-3 my-3 mx-auto align-items-center justify-content-start">
            <div class="flex flex-row w-full">
                <search-bar :key="resetKey" @toggle="filterUpdate"/>
                <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700"  @click.prevent="clearFilters">
                    Clear filters
                </button>
            </div>

            <span class="text-xs text-left flex w-full text-gray-400">
                Search by Serial Number, Label, or the benefactor of the item
            </span>
        </div>
        <div class="pt-3 pb-4">
            <TailwindPagination :data="getFilteredInventoryPagination" @pagination-change-page="getResults" :limit="limit" :keep-length="keepLength"/>
        </div>
        <items-table/>
    </div>
</template>
<script>
import ItemsTable from '@/views/inventory/tables/items'
import SearchBar from '@/views/inventory/searchbar/main';
import { mapActions, mapGetters } from "vuex";
import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
export default {
    components:{
        TailwindPagination,
        SearchBar,
        ItemsTable
    },
    data() {
        return{
            limit: 2,
            keepLength: true,
            resetKey: 0,
            form:{
                page: 1,
                refresh :false
            }
        }
    },
    computed: {
        ...mapGetters([
            'getFilteredInventoryPagination'
        ])
    },
    methods:{
        ...mapActions(['filterInventoryItems']),
        filterUpdate:function(form){
            form.page = 1;
            this.form = form;
            // this.filterInventoryItems(this.form);
        },
        clearFilters: function(){
            this.form.page = 1;
            this.resetKey ++;
        },
        getResults(page) {
            this.form.page = page;
        },
    },
    watch:{
        form:{
        handler:function(){        
            this.filterInventoryItems(this.form);
        },
        deep:true      
        }
    },
    mounted(){
        let title = 'Inventory';
        this.$emit('set-title',title);
    },
}
</script>