<template>
    <div>
        <div class="grid grid-cols-1 xl:grid-cols-1 gap-x-4">
            <dashboard-slider class="mb-3"></dashboard-slider>
        </div>
        <div class="bg-white rounded-[10px] shadow-sm pb-2 mb-[5rem]">
            <div class="flex items-center gap-x-6 px-4">
                <div><i class="bi bi-search text-gray-400 py-3"></i><input type="search" placeholder="Buscar..." class="pl-3 py-3" /></div>
                <div class="flex-1"></div>
                <div>
                    <label class="switch align-middle">
                        <input checked v-on:click="showMessages($event)" type="checkbox">
                        <span class="slider round"></span>
                    </label>
                    Acknowledged
                </div>
            </div>
            <!-- pages -->
            <div class="mt-2 flex-row flex text-center" v-if="!loadingMsg">
                <button :disabled="page === 1" class="ml-auto" :class="(page === 1 ? 'text-gray-200' : 'text-gray-600')" @click="filterPage(-1)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                    </svg>
                </button>
                <input disabled type="text" class="p-2 bg-transparent font-semibold text-center w-10 uppercase text-xs" :value="page">
                <button :disabled="page >= maxPage" class="mr-auto" :class="(page >= maxPage ? 'text-gray-200' : 'text-gray-600')" @click="filterPage(1)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
            <table class="w-full text-left">
                <thead class="bg-gray-50 border-top">
                    <th class="text-center pl-4">Type</th>
                    <th>Title</th>
                    <th>Date</th>
                    <th class="text-center">Acknowledged</th>
                </thead>
                <tbody v-if="getMessages.length && !loadingMsg">
                    <tr :id="msg.message_mast_id" v-for="msg in currentMessages" :key="msg.message_mast_id" @click.prevent="msg.Message_Status==1 ? goView(msg.message_mast_id):goView()" class="border-top bg-gray-50 hover:bg-gray-100 hover:cursor-pointer">
                        <td class="text-center pl-4" width="5%"><i :class="(msg.Message_Type==2 ? 'bi bi-person bg-green-100 text-green-600':'bi bi-broadcast bg-red-100 text-red-600')+' inline-block w-8 h-8 text-lg rounded-full'"></i></td>
                        <td><i :class="(msg.Priority==1 ? 'bi bi-flag-fill text-red-500 mr-2':'')"></i>{{msg.Title}}<span class="text-gray-300 ml-2">#{{msg.message_mast_id}}</span></td>
                        <td class="text-gray-400">{{msg.CreatedAt | moment("MM-DD-YYYY hh:mm")}}</td>
                        <td class="text-center" width="5%">
                            <i class="bi bi-check text-green-600" style="font-size: 150%;" v-if="msg.AckRequired"></i>
                            <i class="bi bi-x text-red-600" style="font-size: 150%;" v-else></i>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="loadingMsg">
                  <tr>
                    <td colspan="5" class="text-center text-gray-400">Loading...</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center text-gray-400">No records found.</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import slider   from '@/views/dashboard/slider/slider'
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: {
            'dashboard-slider': slider,
        },
        data(){
            return {
                loadingMsg: true,
                ack: 0,
                options: {
                    rewind      : true,
                    perPage     : 1,
                    gap         : '1rem',
                    autoplay    : true,
                    pauseOnHover: false,
                    arrows      : 'slider',
                },
                isAcknowledge: 0,
                itemsPerPage: 10,
                page: 1,
                maxPage: 0,
                currentMessages: []
            }
        },
        validations: {
            
        }, 
        computed:{
            ...mapState(['messages']),
            ...mapGetters(['getMessages'])
        },
        created(){
            this.loadingMsg = true;
            this.fetchMessages(1).then(()=>{
                this.maxPage = Math.ceil(this.getMessages?.length / this.itemsPerPage);
                this.filterPage(0);
                this.loadingMsg = false;
            });
        },
        methods:{
            ...mapActions([
                'fetchMessages'
            ]),
            filterPage(value){
                this.page+=value;
                let lastIndex = this.page * this.itemsPerPage;
                let firstIndex = lastIndex - this.itemsPerPage;
                this.currentMessages = this.getMessages.slice(firstIndex, lastIndex);
            },
            goView:function(id){
                if(id) {
                    this.$router.push({ path: '/messages/view/'+id });
                }
            },
            showMessages:function(event) {
                this.isAcknowledge = event.target.checked ? 1:0;
                this.loadingMsg = true;
                this.fetchMessages(this.isAcknowledge).then(()=>{
                    this.maxPage = Math.ceil(this.getMessages?.length / this.itemsPerPage);
                    this.filterPage(0);
                    this.loadingMsg = false;
                });
            }
        },
        mounted(){
            let title = 'Message Center';
            this.$emit('set-title',title);
        }
    };
</script>
<style>
    .splide__track {
        padding-bottom: 10px;
    }
    .splide__arrow {
        background: transparent !important;
    }
</style>
<style scoped>
    .bi::before {
        line-height: 1.8;
    }
    .bi.bi-check::before {
        line-height: 0.1;
    }
    .bi.bi-x::before {
        line-height: 0.1;
    }
    td {
        padding: 10px;
    }
</style>