<template>
<div>
  <loading :active.sync="displayLoader" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading> 
  <div class="space-y-4">
    <div id="search-bar space-y-3">
        <label class="text-left">Search employee in the list</label>
        <div v-if="!displayLoadingEmployee" class="flex flex-row">
          <div class="w-full">
            <input v-model="employeeID" @keyup.enter="filterSearch" placeholder="J.Doe1234" class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2"/>
            <span class="text-xs">Write the employee id (fully or partially) and press enter to make a search</span>
          </div>
          <button @click="clearFilters" class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700 flex flex-row whitespace-nowrap my-auto">

            <i class="bi bi-arrow-repeat mr-1"></i>
            Refresh 
          </button>
          <button v-b-modal="'clockInModal'" class="uppercase text-xs p-2 bg-blue-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-blue-700 flex flex-row whitespace-nowrap my-auto">
            <i class="bi bi-stopwatch mr-1"></i>
            Clock In an Agent
          </button>
          <b-modal id="clockInModal" :ref="'clockInRef'" hide-footer hide-header>
            <clockInModal @toggle="closeClockModal"></clockInModal> 
          </b-modal>
        </div>
        <div v-if="!agentExist">
          <div v-if="displayLoadingEmployee" class="d-flex align-items-center ">
              <b-spinner class="ml-5"></b-spinner><strong class="ml-2">Searching Employee...</strong>
          </div>
        </div>          
    </div>
    <div id="clocker-management-table">
      <table class="table-sm light-table">
        <thead>
          <tr>
            <th>RecID</th>
            <th>EmpID</th>
            <th>Campaign</th>
            <th>Team</th>
            <th>Current Activity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="getUsers.length">
          <tr :class="employee.EmpID" :id="'row'+index" v-for="(employee, index) in getUsers" :key="employee.RecID">
            <td>{{  employee.rec_id   }}</td>
            <td>{{  employee.EmpID    }}</td>
            <td>{{  employee.Campaign }}</td>
            <td>{{  employee.Team     }}</td>
            <td>{{  employee.Event    }}</td>
            <td>
              <div class="flex whitespace-nowrap">
                <vs-tooltip text="Clock-out">
                  <button :id="'clockOut'+index" @click="clockOut(employee.EmpID)"
                  class="bg-red-500 rounded-l-md text-white p-2 px-3">
                    <i class="bi bi-box-arrow-left"></i>
                  </button>
                </vs-tooltip>

                <vs-tooltip text="Start Activity">
                  <button v-b-modal="'startActivityModal'+index" class="bg-blue-500 text-white p-2  px-3">
                    <i class="bi bi-pause-circle-fill"></i>
                  </button>
                </vs-tooltip>
                
                <vs-tooltip text="End Activity"> 
                  <button :disabled="isDisabled(employee.Event)" @click="endActivity(employee.EmpID, index)"
                  class="bg-orange-500 rounded-r-md text-white p-2 px-3">
                    <i class="bi bi-x-circle-fill"></i>
                  </button>
                </vs-tooltip>

                <b-modal :ref="'startActivityRef'+index" :id="'startActivityModal'+index" hide-footer hide-header>
                  <startNewActivityModal @toggle="closeActivityModal(index)" :employee="employee.EmpID" :fromOrigin="'management'"></startNewActivityModal>
                </b-modal>
              </div>
            </td>          
          </tr>
        </tbody>
        <tbody v-else>
          <tr><td colspan="6" class="p-3">There's no users connected to clocker.</td></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>
<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapGetters,mapState, mapActions }   from 'vuex'
    import Loading                              from 'vue-loading-overlay';
    import startNewActivityModal      from '@/views/clocker/modals/startNewActivity'
    import clockInModal                         from '@/views/components/Clocker/modals/clockIn';
    import auth                                 from "@/config/user"

    export default {
    data() {
        return {
            displayLoader: true,
            fullPage: true, 
            color: "#F2F2F2",
            bgcolor: "#000000",
            loader: 'dots',

            CurrentPermissions: null,
            clockInEnabled: false,
            displayLoadingEmployee: false,
            agentExist: false,
            employeeID: null,
            // currentEmployeeID: null,
        }
    },
    components: {
      Loading,
      clockInModal,
      startNewActivityModal
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters(['getUsers'])
    },
    methods:{
      ...mapActions([
        'getUsersEventUtility',
        'isValidAgent',
        'filterUtilityByUser',
        'endNewActivity',
        'clockOutAgent'
      ]),
      closeActivityModal: function(index){
        this.$refs['startActivityRef'+index][0].hide();
        this.clearFilters();
      },

      closeClockModal: function(){
        this.$refs['clockInRef']?.hide();
        this.clearFilters();
      },

      getPermissions: function(){
        let tokenInfo = auth.currentUser.roles;
        this.CurrentPermissions = tokenInfo;        
      },

      clearFilters:function(){
        this.displayLoader = true; 
        this.employeeID = null;
        this.getUsersEventUtility().then(()=>{
          this.displayLoader = false;
        });
      },

      filterSearch: function(){
        this.displayLoadingEmployee = true;
        this.filterUtilityByUser(this.employeeID).then(()=>{
          this.displayLoadingEmployee = false;
        });
      },

      isDisabled: function(eventName){
        return (eventName == "shift") ? true : false;
      },

      endActivity: function(EmpID){
        let objData = { 
          EmpID: EmpID,
          url: window.location.pathname
        }
        this.$confirm("You're about to end the current activity of "+ EmpID +", Are you sure?").then(() => {
            this.displayLoader = true;
            this.endNewActivity(objData).then(()=>{
              this.displayLoader = false;
              this.clearFilters();
            }); 
        });
      },
      clockOut: function(EmpID){
        this.$confirm(
          "Press 'ok' if you want to continue", 
          "You're about to clock out "+ EmpID, 
          "warning"
        ).then((result) => {
          if (result){                
            this.clockOutAgent({ EmpID: EmpID }).then(()=>{
              this.$fire({
                type: 'success',
                title: 'The employee has been clocked out successfully',
                showConfirmButton: false,
                timer: 1500
              }).then(()=>{
                this.clearFilters();
              });
            });
          }
        })
      },
    },
    created(){
      this.getPermissions();
      this.getUsersEventUtility().then(()=>{
        this.displayLoader = false;
      });
    },
  }
</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
  .underline{
      background-color: #007bff !important;
      color: white;
  }
</style>