import auth from '@/config/user'

const permissions = {
    data(){
        return{
           
        }
    },
    computed:{
        permissions:function(){
            return auth.currentUser.roles;
        },
        location:function(){
            return auth.currentUser.location
        },
        emp_id:function(){
            return auth.currentUser.EmpID;
        },
        _isAdmin:function(){
            return this.hasPermission('SystemAdministrator');
        }
    },
    methods:{
        hasPermission:function(role){
            //Revisa que el usuario actual tenga el rol buscado.
            return this.permissions.indexOf(role) > -1;
        },
        isValidPermission:function(item){
            return (Object.prototype.toString.call(item) === '[object String]') && item !== '';
        },
        validateFromArray:function(list){
            //Si la cadena contiene | la convierte en un arreglo.
            let _this = this;

            return list.split('|').some(function(item){
               return  _this.hasPermission(item);
            });
        },
        isGranted:function(searchString){
            //Valida que se ingrese una cadena simple o separada por pipelines
            if(!this.isValidPermission(searchString)) return false;
            
           
            //Si contiene pipelines la convierte en un arreglo y valida individualmente.
            if(searchString.includes('|')) return this.validateFromArray(searchString);
            
            //
            return this.hasPermission(searchString);
        },
        hasLocation(location){
            if(location.includes('|')){
            
                location = location.split('|').map(x => x.toLowerCase())

                return location.includes(this.location.toLowerCase());
            }
            return location.toLowerCase() === this.location.toLowerCase();
        },

        validLocation(location){
            return this.hasLocation(location) || this._isAdmin;
        }

    
        
    }
  }
  export default permissions;