<template>
    <div>
        <div class="bg-white rounded-[10px] shadow-sm p-10 text-left">
            <div class="flex items-start space-x-4">
                <div class="border rounded">
                    <div class="flex">
                        <div class="flex-1 p-3">
                            <label class="text-xs mb-0 uppercase">Title</label>
                            <input type="text" class="w-full" v-model="title" placeholder="..." />
                        </div>
                        <div class="p-3 border-l" v-if="this.type_quiz=='survey'">
                            <label class="text-xs mb-0 uppercase">Interval (days)</label>
                            <input type="number" class="w-full" v-model="interval" placeholder="0" />
                        </div>
                        <div class="p-3 border-l" v-if="this.type_quiz=='quiz'">
                            <label class="text-xs mb-0 uppercase">Attempts</label>
                            <input type="number" class="w-full" v-model="attempts" placeholder="0" />
                        </div>
                    </div>
                    <div class="flex border-t">
                        <div class="p-3">
                            <label class="text-xs mb-0 uppercase">Date From <span class="uppercase" v-if="this.type_quiz=='quiz'">(Optional)</span></label>
                            <input type="date" class="w-full" v-model="date_from" placeholder="..." />
                        </div>
                        <div class="p-3 border-l">
                            <label class="text-xs mb-0 uppercase">Date To <span class="uppercase" v-if="this.type_quiz=='quiz'">(Optional)</span></label>
                            <input type="date" class="w-full" v-model="date_to" placeholder="..." />
                        </div>
                        <div class="p-3 border-l" v-if="this.type_quiz=='quiz'">
                            <label class="text-xs mb-0 uppercase">Duration (minutes)</label>
                            <input type="text" class="w-full" v-model="duration" placeholder="0" />
                        </div>
                    </div>
                </div>
                <div class="border rounded p-3 space-y-4 text-center">
                    <div v-for="(crit, index) in criteria" :key="index" class="flex flex-1">
                        <button class="text-xs uppercase my-auto p-1 rounded-md flex flex-row" :disabled="index === 0" @click="changeClause" :class="{'bg-gray-100 text-gray-300' : index > 1, 'bg-gray-500 text-white' : index === 1}">{{crit.CLAUSE}}<i class="bi bi-chevron-down ml-1" :hidden="index === 0 || index > 1"></i></button>
                        <div class="mx-2 flex-1">
                            <select class="border w-full rounded-full py-2 px-3" v-model="crit.FILTER" required @change="resetValue(index)">
                                <option value="null" hidden>Select criteria</option>
                                <option value="Location">Site</option>
                                <option value="Department">Department</option>
                                <option value="Campaign">Campaign</option>
                                <option value="Title">Job Title</option>
                            </select>
                        </div>
                        <button class="text-xs font-semibold uppercase my-auto p-1 rounded-md flex flex-row bg-gray-500 text-white" @click="changeOperator(index)" :hidden="crit.FILTER === null">
                            <div class="text-xs uppercase my-auto whitespace-nowrap" :hidden="crit.OPERATOR === '!='">is</div>
                            <div class="text-xs uppercase my-auto whitespace-nowrap" :hidden="crit.OPERATOR === '='">is not</div>
                            <i class="bi bi-chevron-down ml-1"></i>
                        </button>
                        <div class="mx-2 flex-1" v-if="crit.FILTER === 'Location'">
                            <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                <option value="null" hidden>Select criteria</option>
                                <option v-for=" (item, index) in getLocations" :value="item" :key="index">{{ item.toUpperCase() }}</option>
                            </select>
                        </div>
                        <div class="mx-2 flex-1" v-if="crit.FILTER === 'Department'">
                            <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                <option value="null" hidden>Select criteria</option>
                                <option v-for="(department, index) in getDepartments" :key="index" :value="department.label">{{department.label}}</option>
                            </select>
                        </div>
                        <div class="mx-2 flex-1" v-if="crit.FILTER === 'Campaign'">
                            <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                <option value="null" hidden>Select criteria</option>
                                <option v-for="(campaign, index) in getAllCampaigns" :key="index" :value="campaign">{{campaign}}</option>
                            </select>
                        </div>
                        <div class="mx-2 flex-1" v-if="crit.FILTER === 'Title'">
                            <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                <option value="null" hidden>Select criteria</option>
                                <option v-for="item in getTitles" :key="item" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <button class="text-gray-700 text-danger text-lg" @click="removeCriteria(index)" :hidden="index === 0"><i class="bi bi-trash3"></i></button>
                    </div>
                    <button class="bg-c-primary text-white px-6 py-1 ml-3 rounded-full" @click="addCriteria"><i class="bi bi-funnel"></i>Add Filter</button>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-[10px] shadow-sm p-10 text-left mt-6" v-if="this.type_quiz=='quiz'">
            <div class="border rounded">
                <div class="flex">
                    <div class="flex-1 p-3">
                        <label class="text-xs mb-0 uppercase">Instructions</label>
                        <textarea class="w-full resize-none" rows="5" v-model="instructions" placeholder="..."></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-[10px] shadow-sm p-10 text-left mt-6 mb-10">
            <draggable v-model="questions" group="questions" tag="ol" @start="drag=true" @end="drag=false" class="list-decimal pl-8">
                <li v-for="(input, index) in questions" :key="'question-'+index" :class="'group/question-'+index+' bg-white rounded-xl p-2 mb-6'">
                    <div class="flex">
                        <div class="flex-1">
                            <div class="flex border rounded">
                                <div class="flex-1 p-3">
                                    <label class="text-xs mb-0 uppercase">Question</label>
                                    <textarea class="w-full question-text" v-model="input.text" rows="4" placeholder="..."></textarea>
                                </div>
                                <div class="w-1/8 border-l">
                                    <div class="p-3">
                                        <label class="text-xs mb-0 uppercase">Value</label>
                                        <input type="number" min="0" v-model="input.value" class="w-full question-value" placeholder="0" />
                                    </div>
                                    <div class="p-3 border-t">
                                        <label class="text-xs mb-0 uppercase" :class="input.type=='' ? 'text-red-500':''">Type</label>
                                        <select v-model="input.type" class="w-full"  :class="input.type=='' ? 'text-red-500':''">
                                            <option value="" selected>Select...</option>
                                            <option value="text">Text</option>
                                            <option value="number">Number</option>
                                            <option value="dropdown">Dropdown</option>
                                            <option value="date">Date</option>
                                            <option value="time">Time</option>
                                            <option value="duration">Duration</option>
                                            <option value="multiple">Multiple</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 ml-10" v-if="input.type=='dropdown' || input.type=='multiple'">
                                <h4 class="text-sm uppercase">Answers</h4>
                                <draggable v-if="hasLength(input.answers)" :group="'answers-'+index" tag="ol" @start="drag=true" @end="drag=false" class="list-decimal divide-y border rounded overflow-hidden">
                                    <li v-for="(inp, ind) in input.answers" :key="'answer-'+index+'-'+ind" class="bg-white">
                                        <div class="flex px-3 py-2">
                                            <div><input :type="input.type=='multiple' ? 'checkbox':'radio'" :name="'check-'+index" v-bind:value="0" v-model="inp.correct" class="mr-3"></div>
                                            <input type="text" class="w-full" v-model="inp.text" placeholder="..." />
                                            <label class="flex border-l pl-6 m-0">Value<input type="number" min="0" class="w-full ml-6" v-model="inp.value" placeholder="..." /></label>
                                            <div class="pl-6"><a class="inline-block text-red-500 hover:text-red-700 cursor-pointer" @click="removeAnswer(input.answers,ind)"><i class="bi bi-trash text-xl align-middle leading-none mr-2"></i></a></div>
                                            <div class="cursor-move text-gray-300 hover:text-gray-800 ml-3"><i class="bi bi-arrow-down-up"></i></div>
                                        </div>
                                    </li>
                                </draggable>
                                <div class="text-center mt-6">
                                    <button class="bg-slate-600 hover:bg-slate-700 text-white px-6 py-1 rounded-full" type="button" @click="newAnswer(input.answers)"><i class="bi bi-plus text-2xl align-middle leading-none mr-2"></i>New Answer</button>
                                </div>
                            </div>
                        </div>
                        <div :class="'group-hover/question-'+index+':opacity-100 opacity-0 flex flex-col space-y-3 text-center ml-3 transition-all'">
                            <a class="text-gray-300 hover:text-gray-800 cursor-move"><i class="text-2xl bi bi-arrow-down-up"></i></a>
                            <a v-if="index>0" class="text-gray-300 hover:text-gray-800 cursor-pointer" @click="linkQuestion(index)"><i class="text-2xl bi bi-link"></i></a>
                            <a class="text-red-500 hover:text-red-700 cursor-pointer" @click="removeQuestion(index)"><i class="bi bi-trash text-xl align-middle leading-none"></i></a>
                        </div>
                    </div>
                    <ModalParentQuestion v-show="index==modalQuestion_index" :questions="questions" :question_index="index" @close-modal="modalQuestion_index=0" @data-parent="dataParent"></ModalParentQuestion>
                </li>
            </draggable>
            <div class="text-center mb-10">
                <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" type="button" @click="newQuestion()"><i class="bi bi-plus text-2xl align-middle leading-none mr-2"></i>New Question</button>
            </div>
            <div class="text-center">
                <button class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full" type="submit" @click="saveForm()"><i class="bi bi-check2 mr-2"></i>Save</button>
            </div>
        </div>
    </div>
</template>
<script>
    import draggable from 'vuedraggable'
    import ModalParentQuestion from './modalParentQuestion.vue'
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        components: {
            draggable,
            ModalParentQuestion
        },
        data(){
            return {
                criteria:[{ CLAUSE:'where', FILTER: null, OPERATOR: '=', VALUE: null }],
                modalQuestion_index: 0,
                title: '',
                interval: 0,
                attempts: 0,
                date_from: '',
                date_to: '',
                duration: '',
                instructions: '',
                type_quiz: 'quiz',
                site: '',
                campaign: '',
                job_title: '',
                site_options: [
                    'MTY',
                    'AGS',
                    'QRO',
                ],
                questions: [{
                    ID: 'new',
                    parent: 0,
                    text: '',
                    type: '',
                    value: 0,
                    conditions: [],
                    answers: []
                }]
            }
        },
        computed: {
            ...mapState([
            ]),
            ...mapGetters([
                'getLocations',
                'getDepartments',
                'getAllCampaigns',
                'getTitles',
                'getSurveysV2Details'
            ])
        },
        created() {
            this.fetchSites();
            this.fetchDepartments();
            this.fetchCampaigns();
            this.fetchTitles();
            if(this.$route.params.id) {
                this.fetchDetailsSurveys(this.$route.params.id).then(() => {
                    this.title = this.getSurveysV2Details?.title;
                    this.interval = this.getSurveysV2Details?.interval;
                    this.attempts = this.getSurveysV2Details?.attempts;
                    this.date_from = this.getSurveysV2Details?.date_from?.split(' ')[0];
                    this.date_to = this.getSurveysV2Details?.date_to?.split(' ')[0];
                    this.duration = this.getSurveysV2Details?.duration ?? '';
                    this.instructions = this.getSurveysV2Details?.instructions ?? '';
                    this.type_quiz = this.getSurveysV2Details?.type;
                    this.questions = this.getSurveysV2Details?.questions.sort((a, b) => a.index - b.index);
                    this.criteria = JSON.parse(this.getSurveysV2Details?.visibility);
                    document.getElementById('pro-loading').style.display = 'none';
                });
            } else {
                this.type_quiz = this.$route.params.type;
            }
        },
        methods: {
            ...mapActions([
                'storeSurveys',
                'editSurveys',
                'fetchSites',
                'fetchDepartments',
                'fetchCampaigns',
                'fetchTitles',
                'fetchDetailsSurveys'
            ]),
            hasLength(value){
                return value?.length > 0;
            },
            addCriteria() {
                let cls = this.criteria?.length > 2 ? this.criteria[1].CLAUSE : 'and';
                this.criteria.push({ CLAUSE: cls, FILTER: null, OPERATOR: '=', VALUE: null });
            },
            removeCriteria(i) {
                this.criteria.splice(i, 1);
            },
            changeClause() {
                for (var i = 1; i < this.criteria?.length; i++) {
                    let isOr = this.criteria[i].CLAUSE === 'or';
                    this.criteria[i].CLAUSE = isOr ? 'and' : 'or';
                }
            },
            changeOperator(i) {
                let is = this.criteria[i].OPERATOR === '=';
                this.criteria[i].OPERATOR = is ? '!=' : '=';
            },
            resetValue(i) {
                this.criteria[i].VALUE = null;
            },
            newQuestion() {
                this.questions.push({
                    ID: 'new',
                    parent: 0,
                    text: '',
                    type: '',
                    value: 0,
                    conditions: [],
                    answers: []
                });
            },
            removeQuestion(i) {
                this.questions.splice(i,1);
            },
            newAnswer(answers) {
                answers.push({ ID: 'new', text: '', correct: 0, value: 0 });
            },
            removeAnswer(answers,i) {
                answers.splice(i,1);
            },
            linkQuestion(index) {
                this.modalQuestion_index = index;
            },
            dataParent(value) {
                this.questions[this.modalQuestion_index].parent = value.parent + 1;
                this.questions[this.modalQuestion_index].conditions = value.conditions;
                this.modalQuestion_index = 0;
            },
            saveForm() {
                let dataObject = new FormData();
                dataObject.append('title', this.title);
                dataObject.append('interval', this.interval);
                dataObject.append('attempts', this.attempts);
                dataObject.append('date_from', this.date_from);
                dataObject.append('date_to', this.date_to);
                dataObject.append('duration', this.duration);
                dataObject.append('type', this.type_quiz);
                dataObject.append('instructions', this.instructions);
                dataObject.append('questions', JSON.stringify(this.questions));
                dataObject.append('applyto', JSON.stringify(this.criteria));
                if(this.$route.params.id) {
                    this.edit(dataObject);
                } else {
                    this.new(dataObject);
                }
            },
            async new (dataObject) {
                document.getElementById("pro-loading").style.display = "flex";
                await this.storeSurveys(dataObject).then((res) => {
                    this.$fire({
                        type: 'success',
                        title: res?.msg
                    })
                }).catch((err) => {
                    this.$fire({
                        type: 'error',
                        title: err?.msg
                    })
                })
                document.getElementById("pro-loading").style.display = "none";
                this.$router.go(-1);
            },
            edit (dataObject) {
                dataObject.append('id', this.$route.params.id);
                document.getElementById("pro-loading").style.display = "flex";
                this.editSurveys(dataObject).then((res) => {
                    this.$fire({
                        type: 'success',
                        title: res?.msg
                    })
                    document.getElementById("pro-loading").style.display = "none";
                    this.$router.go(-1);
                }).catch((err) => {
                    this.$fire({
                        type: 'error',
                        title: err?.msg
                    })
                })
            }
        },
        mounted() {
            if(this.$route.params.id) {
                document.getElementById('pro-loading').style.display = 'flex';
            }
            let title = this.$route.params.id ? 'Surveys - Edit':'Surveys - New '+this.type_quiz;
            this.$emit('set-title',title);
        }
    }
</script>
<style scoped>
</style>