<template>
    <div class="main bg-white drop-shadow-md rounded-2xl">
        <div class="w-1/2" v-if="fileExist">
            <pdf-viewer :pdf="pdf"></pdf-viewer>
        </div>
        <div class="flex-1 max-w-lg m-auto space-y-3">
            <form class="space-y-3" @submit.prevent="sendRequest()">
                <div class="text-left mx-3">
                    <span class="text-left font-semibold">
                        {{ instructions }}
                    </span>
                </div>
                <upload-files @changed="handleImages" :isMultiple='multiple_files' :fileTypes="file_types" :maxSize="max_file_size"
                :disabled="activeRequests > 0"/>
                <button type="submit" class="flex p-2 px-3 rounded-lg text-white uppercase font-sans font-semibold text-xs mx-auto bg-gray-800" :disabled="activeRequests > 0">
                    send
                </button>
            </form>
            <div class="space-y-3">
                <div class="text-left mx-3">
                    <span class="text-left font-semibold">
                        Last Requests
                    </span>
                </div>
                <div>
                    <requests :key="requestKey" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import PdfViewer from '@/views/components/files/pdf_viewer';
import UploadFiles  from '@/views/Utilities/upload_files/index'
import Requests from '@/views/documents/employee/requests_list'
export default {
    components: {
        PdfViewer,
        UploadFiles,
        Requests
    },
    data () {
        return {
            // constants for upload-file
            multiple_files: false,
            file_types: ['application/pdf'],
            max_file_size: 5,
            // end constants for upload-file
            requestKey: 0,
            file: null,
            employee_id: null,
            document_id: null,
            pdf: ''
        }
    },
    computed:{
        ...mapGetters([
            'infoEmployee',
            '_employee_file',
            '_employee_file_requests',
            '_employee_file_details',
        ]),

        fileExist () {
            return this._employee_file !== null && this._employee_file !== '';
        },
        activeRequests () {
            let filtered = [];
            if (Array.isArray(this._employee_file_requests)) {
                filtered = this._employee_file_requests.filter(
                (a) => a.status?.toLowerCase() === 'pending'
                );
            }
            return filtered.length;
        },
        instructions () {
            return !this.fileExist
            ? 'Seems there is no document uploaded yet. You can use the box below and submit a request to fill it'
            : 'If you want to replace the current file, please send a new request by adding a file in the box below and click send. Please note active request prevent you from sending another request';
        },
    },
    methods: {
        ...mapActions([
            'fetchEmployeeDocument',
            'uploadFileDocument',
            'fetchDocumentsListDetails'
        ]),
        handleImages(files){
            this.file = files[0];
        },
        async renderAll () {
            document.getElementById('pro-loading').style.display = 'flex';
            let obj = {
                employee_id: this.infoEmployee.RecID,
                document_id: this.$route.params.doc_type
            };
            await this.fetchEmployeeDocument(obj);
            this.pdf = this._employee_file;
            this.requestKey++;
            document.getElementById('pro-loading').style.display = 'none';
        },
        sendRequest(){
            document.getElementById('pro-loading').style.display = 'flex';
            if(this.file === null) return;
            let dataObject = new FormData();
            dataObject.append('image', this.file);
            dataObject.append('employee_id', this.employee_id);
            dataObject.append('document_id', this.document_id);
            this.uploadFileDocument(dataObject).then(() => {
                document.getElementById('pro-loading').style.display = 'none';
                this.$router.go(-1);
                // this.$router.push({name:`profileEmployee.documents`});
            });
        },
    },
    mounted () {
        this.employee_id = this.$route.params.rec_id;
        this.document_id = this.$route.params.doc_type;
        this.fetchDocumentsListDetails(this.document_id).then(() => {
            let title = 'Documents: ' + this._employee_file_details?.label;
            this.$emit('set-title',title);
        })
        this.renderAll();
    },
}
</script>
<style scoped>
.main {
    min-height: 780px;
    width: 100%;
    padding: 0.5em;
    display: flex !important;
    flex-flow: row;
}
</style>