<template>
<div class="main">
    <div class="container-fluid">

        <div class="flex flex-row max-w-7xl bg-white shadow-lg rounded-lg p-3 my-3 mx-auto align-items-center justify-content-start">
            <incidences-searchbar :key="resetKey" @toggle="filterUpdate"/>
            <button class="uppercase text-xs p-2 bg-gray-500 text-white shadow-md rounded-md ml-2 mx-2 hover:bg-gray-700"  @click.prevent="clearFilters">
                Clear filters
            </button>
        </div>
        <span class="text-xs text-gray-700">
            Searching declined or approved incidences, will be shown only those the date is no older than 14 days ago.
        </span>
        <div class="mt-3" :hidden="Object.keys(getIncidentPagination).length === 0">
            <TailwindPagination :data="getIncidentPagination" @pagination-change-page="getResults" :limit="limit" :keep-length="keepLength"/>
        </div>
        <component :key="ListKey" @updateAll="keepQueryAlive" :is="'thetable'" class="text-center justify-center items-center mt-2 w-full" component=""></component>

    </div>
</div>
</template>
<script>
    import thetable             from '@/views/components/Table/incidences_table';
    import IncidencesSearchbar  from '@/views/components/Incidences/IncidenceSearchBar/IncidenceSearchBar.vue'
    import {mapGetters,mapState, mapActions } from 'vuex'
    import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
    export default {
        components: {
            TailwindPagination,
            thetable,
            IncidencesSearchbar,
        },
        data(){
            return{
                limit: 2,
                keepLength: true,
                ListKey: 0,
                // Searchbar Key
                resetKey: 0,
                form:{
                    page: 1,
                    refresh :false
                }
            }
        },
        computed: {
            ...mapState([
            'employees'
            ]),
            ...mapGetters([
                'getIncidentPagination'
                ])
        },
        created(){
        },
        methods: {
            ...mapActions([
                'filterIncidencesReq'
                ]),

            closeModal: function(){
            this.$refs['AddNewSurvey'].hide()
            this.ListKey++;
            },

            // ForceRender: function(){
            //     this.ListKey++;
            // },

            // Searchbar methods
            filterUpdate:function(form){
                this.form = form;
            },

            keepQueryAlive:function(){
                this.filterIncidencesReq(this.form);
            },

            clearFilters: function(){
                this.resetKey ++;
            },

            // end searchbar methods

            getResults(page) {
              this.form.page = page;
              this.filterIncidencesReq(this.form);
            },
        },
        watch:{
            form:{
            handler:function(){        
                this.filterIncidencesReq(this.form);
            },
            deep:true      
            }
        },
      mounted(){
        let title = 'Pending Incidences Management';
        this.$emit('set-title',title);
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>