import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    teamsFilter: [],
    isDeleted: false,
};
const getters = {
    getTeamsFilter: (state) => state.teamsFilter,
    getDeletedStatusTeam: (state) => state.isDeleted,
};
const actions = {
    async newTeam ({commit}, data ){        
        const endpoint = API_URL + 'api/v3/teams/new'
        const response = await axios.post(endpoint, data).then((res) =>{
            commit('setTeamsFilter', null);
            return res.data;
        });
        return response
    },
    async fetchAllTeams ({commit}, data){   
        const endpoint = API_URL + 'api/v3/teams/filtered';
        const response = await axios.post(endpoint, data);
        commit('setTeamsFilter',response.data);
    },
    async deleteTeam ({commit}, id){   
        const endpoint = API_URL + 'api/v3/teams/delete/'+id;
        const response = await axios.get( endpoint).then((res) =>{
            commit('setDeletedStatusTeam', true);
            return res.data;
        });
        return response;
    }
};
const mutations = {
    setTeamsFilter: (state, teamsFilter) => (state.teamsFilter = teamsFilter),
    setDeletedStatusTeam: (state, status) => (state.isDeleted = status),
};
export default {
    state,
    getters,
    actions,
    mutations
};