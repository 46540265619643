<template>
    <div class="mt-5 bg-white border border-gray-100 rounded-2xl p-5 w-10/12 mx-auto" v-if="!loading">
        <div v-if="available">
            <div id="formTitle" class="text-4xl font-semibold">Medical Survey Form</div>
            <label class="inline-flex items-center cursor-pointer my-4">
                <span class="my-auto mb-1">English 🇺🇸</span>
                <input type="checkbox" value="" class="sr-only peer" @click="toggleLanguage">
                <div
                    class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mx-2">
                </div>
                <span class="my-auto mb-1">Spanish 🇲🇽 </span>
            </label>
            <label class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto text-left p-6"
                data-en="Every year we ask you to go to the medical service for your medical exam, this is to take care of your health and to have your medical history on hand for any situation that may arise. This questionnaire is to make your time and everyone's time more efficient. All your medical data is confidential and only the medical staff has access to your information. It is important that you are honest in your answers and if you have any questions regarding the questionnaire ask your supervisor, trainer, or doctor (Staff) Thank you for your support!"
                data-es="Todos los años te pedimos pasar al servicio médico para tu examen médico, esto es para cuidar de tu salud y tener a la mano tu historial médico por cualquier situación que se presente. Este cuestionario es para hacer más eficiente tu tiempo y el de todos. Te recuerdo que todos tus datos médicos son confidenciales y solo el personal médico tiene acceso a tu información. Es importante que sea sincero en las respuestas y si tiene alguna duda respecto al cuestionario pregunte a su supervisor, entrenador o doctor (Staff) ¡Gracias por tu apoyo!">
                Every year we ask you to go to the medical service for your medical exam, this is to take care of your
                health and to have your medical history on hand for any situation that may arise. This questionnaire is
                to make your time and everyone's time more efficient. All your medical data is confidential and only the
                medical staff has access to your information. It is important that you are honest in your answers and if
                you have any questions regarding the questionnaire ask your supervisor, trainer, or doctor (Staff)"
                Thank you for your support!</label>
            <form id="medicalSurveyForm" @submit.prevent="handleSubmit" autocomplete="off" class="space-y-5 p-6">
                <!-- Section 1: Identification -->
                <h2 data-en="Section 1: Identification" data-es="Sección 1: Identificación">Section 1: Identification
                </h2>
                <div class="grid grid-cols-2 gap-6">
                    <div class="mb-3 flex gap-3">
                        <label for="civilStatus"
                            class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto w-1/2 text-left"
                            data-en="Civil Status" data-es="Estado Civil">Civil Status</label>
                        <select id="civilStatus"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5"
                            name="civilStatus" v-model="form.civilStatus" required>
                            <option value="" hidden>Select</option>
                            <option value="Single">Single/Soltero</option>
                            <option value="Married">Married/Casado</option>
                            <option value="Domestic_Partnership">Domestic Partnership/Union libre</option>
                            <option value="Divorced">Divorced/Divorciado</option>
                            <option value="Separated">Separated/Separado</option>
                            <option value="Widowed">Widowed/Viudo</option>
                        </select>
                    </div>
                    <div class="mb-3 flex gap-3">
                        <label for="schoolLevel"
                            class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto w-1/2 text-left"
                            data-en="School Level" data-es="Nivel Escolar">School Level</label>
                        <select id="schoolLevel"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5"
                            name="schoolLevel" v-model="form.schoolLevel" required>
                            <option value="" hidden>Select</option>
                            <option value="Primary">Elementary/Primaria</option>
                            <option value="JuniorHigh">Secondary/Secundaria</option>
                            <option value="Highschool">High school/Preparatoria</option>
                            <option value="Graduate">Graduate/Licenciatura</option>
                            <option value="Postgraduate">Postgraduate/Maestria o Doctorado</option>
                        </select>
                    </div>
                    <div class="mb-3 flex gap-3">
                        <label for="emergencyContact"
                            class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto w-1/2 text-left"
                            data-en="Emergency Contact Number" data-es="Telefono de Contacto de Emergencia">Emergency
                            Contact
                            Number</label>
                        <input type="text" id="emergencyContact"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5"
                            name="emergencyContact" v-model="form.emergencyContactNumber" pattern="\d{10}" required>
                        <div class="invalid-feedback" id="contactError" style="display: none;"
                            data-en="Please enter a valid 10-digit number."
                            data-es="Por favor ingrese un número válido de 10 dígitos.">Please enter a valid 10-digit
                            number.
                        </div>
                    </div>
                    <div class="mb-3 flex gap-3">
                        <label for="NameToEmergencyContact"
                            class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto w-1/2 text-left"
                            data-en="Name of the Emergency Contact" data-es="Nombre del contacto de emergencia">Name of
                            the Emergency Contact</label>
                        <input type="text" id="NameToEmergencyContact"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5"
                            name="NameToEmergencyContact" v-model="form.emergencyContactName" required>
                    </div>
                    <div class="mb-3 flex gap-3">
                        <label for="relationshipToEmergencyContact"
                            class="block mb-2 text-md font-medium leading-none text-gray-900 my-auto w-1/2 text-left"
                            data-en="Relationship to Emergency Contact"
                            data-es="Parentesco con el contacto de emergencia">Relationship to Emergency Contact</label>
                        <input type="text" id="relationshipToEmergencyContact"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5"
                            name="relationshipToEmergencyContact" v-model="form.emergencyContactRelationship" required>
                    </div>
                </div>

                <!-- Section 2: Family History -->
                <h2 data-en="Section 2: Family History" data-es="Sección 2: Historia Familiar">Section 2: Family History
                </h2>
                <div class="mb-5">
                    <div class="gap-3">
                        <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                            data-en="Father's Medical History" data-es="Historial Médico del Padre">Father's Medical
                            History</label><br>
                        <div class="grid grid-cols-2">
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherHypertension"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[] shrink-0" value="Hipertensión arterial (presión alta)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherHypertension" class="flex gap-3 text-left my-auto"
                                    data-en="Arterial Hypertension (High blood Pressure)"
                                    data-es="Hipertensión arterial (presión alta)">Arterial Hypertension (High blood
                                    Pressure)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherDiabetes"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Diabetes (azúcar en sangre)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherDiabetes" class="flex gap-3 text-left my-auto"
                                    data-en="Diabetes (azúcar en sangre)" data-es="Diabetes (azúcar en sangre)">Diabetes
                                    (azúcar
                                    en sangre)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherObesity"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Overweight or obesity"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherObesity" class="flex gap-3 text-left my-auto"
                                    data-en="Overweight or obesity" data-es="Sobrepeso u obesidad">Overweight or
                                    obesity</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherDyslipidemia"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Dyslipidemias (high triglycerides or cholesterol)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherDyslipidemia" class="flex gap-3 text-left my-auto"
                                    data-en="Dyslipidemias (high triglycerides or cholesterol)"
                                    data-es="Dislipidemias (trigliceridos o colesterol alto)">Dyslipidemias (high
                                    triglycerides
                                    or cholesterol)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherKidney"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Kidney Disease (kidney failure, kidney stones)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherKidney" class="flex gap-3 text-left my-auto"
                                    data-en="Kidney Disease (kidney failure, kidney stones)"
                                    data-es="Enfermedad renal (insuficiencia renal, piedras en riñones)">Kidney Disease
                                    (kidney
                                    failure, kidney stones)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherEpilepsy"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Epilepsy (seizures)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherEpilepsy" class="flex gap-3 text-left my-auto"
                                    data-en="Epilepsy (seizures)" data-es="Epilepsia (convulsiones)">Epilepsy
                                    (seizures)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherThyroid"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Thyroid Disease (hyper or hypothyroidism)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherThyroid" class="flex gap-3 text-left my-auto"
                                    data-en="Thyroid Disease (hyper or hypothyroidism)"
                                    data-es="Enfermedad tiroidea (hiper o hipotiroidismo)">Thyroid Disease (hyper or
                                    hypothyroidism)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherAsthma"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Asthma" v-model="form.fatherMedicalHistory">
                                <label for="fatherAsthma" class="flex gap-3 text-left my-auto" data-en="Asthma"
                                    data-es="Asma">Asthma</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherCancer"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Cancer" v-model="form.fatherMedicalHistory">
                                <label for="fatherCancer" class="flex gap-3 text-left my-auto" data-en="Cancer"
                                    data-es="Cáncer">Cancer</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherDepression"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Depression" v-model="form.fatherMedicalHistory">
                                <label for="fatherDepression" class="flex gap-3 text-left my-auto" data-en="Depression"
                                    data-es="Depresión">Depression</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherAnxiety"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="Anxiety" v-model="form.fatherMedicalHistory">
                                <label for="fatherAnxiety" class="flex gap-3 text-left my-auto" data-en="Anxiety"
                                    data-es="Ansiedad">Anxiety</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherNA"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]" value="None" v-model="form.fatherMedicalHistory">
                                <label for="fatherNA" class="flex gap-3 text-left my-auto" data-en="None"
                                    data-es="Ninguna">None</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="fatherHeart"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="fatherHistory[]"
                                    value="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    v-model="form.fatherMedicalHistory">
                                <label for="fatherHeart" class="flex gap-3 text-left my-auto"
                                    data-en="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    data-es="Cardiopatías (problemas del corazón como infartos, arritmias, soplos, insuficiencia cardiáca)">Heart
                                    Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart
                                    failure)</label>
                            </div>
                        </div>
                    </div>
                    <div :class="submitted && !$v.form.fatherMedicalHistory.required ? 'flex' : 'hidden'" class="mt-3">
                        <span class="ml-1 my-auto text-red-500 text-xs">Please, select at least one.</span>
                    </div>
                </div>

                <div class="mb-5">
                    <div class="gap-3">
                        <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                            data-en="Mother's Medical History" data-es="Historial Médico de la Madre">Mother's Medical
                            History</label><br>
                        <div class="grid grid-cols-2">
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherHypertension"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Hipertensión arterial (presión alta)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherHypertension" class="flex gap-3 text-left my-auto"
                                    data-en="Arterial Hypertension (High blood Pressure)"
                                    data-es="Hipertensión arterial (presión alta)">Arterial Hypertension (High blood
                                    Pressure)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherDiabetes"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Diabetes (azúcar en sangre)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherDiabetes" class="flex gap-3 text-left my-auto"
                                    data-en="Diabetes (azúcar en sangre)" data-es="Diabetes (azúcar en sangre)">Diabetes
                                    (azúcar
                                    en sangre)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherObesity"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Overweight or obesity"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherObesity" class="flex gap-3 text-left my-auto"
                                    data-en="Overweight or obesity" data-es="Sobrepeso u obesidad">Overweight or
                                    obesity</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherDyslipidemia"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Dyslipidemias (high triglycerides or cholesterol)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherDyslipidemia" class="flex gap-3 text-left my-auto"
                                    data-en="Dyslipidemias (high triglycerides or cholesterol)"
                                    data-es="Dislipidemias (trigliceridos o colesterol alto)">Dyslipidemias (high
                                    triglycerides
                                    or cholesterol)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherKidney"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Kidney Disease (kidney failure, kidney stones)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherKidney" class="flex gap-3 text-left my-auto"
                                    data-en="Kidney Disease (kidney failure, kidney stones)"
                                    data-es="Enfermedad renal (insuficiencia renal, piedras en riñones)">Kidney Disease
                                    (kidney
                                    failure, kidney stones)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherEpilepsy"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Epilepsy (seizures)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherEpilepsy" class="flex gap-3 text-left my-auto"
                                    data-en="Epilepsy (seizures)" data-es="Epilepsia (convulsiones)">Epilepsy
                                    (seizures)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherThyroid"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Thyroid Disease (hyper or hypothyroidism)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherThyroid" class="flex gap-3 text-left my-auto"
                                    data-en="Thyroid Disease (hyper or hypothyroidism)"
                                    data-es="Enfermedad tiroidea (hiper o hipotiroidismo)">Thyroid Disease (hyper or
                                    hypothyroidism)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherAsthma"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Asthma" v-model="form.motherMedicalHistory">
                                <label for="motherAsthma" class="flex gap-3 text-left my-auto" data-en="Asthma"
                                    data-es="Asma">Asthma</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherCancer"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Cancer" v-model="form.motherMedicalHistory">
                                <label for="motherCancer" class="flex gap-3 text-left my-auto" data-en="Cancer"
                                    data-es="Cáncer">Cancer</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherDepression"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Depression" v-model="form.motherMedicalHistory">
                                <label for="motherDepression" class="flex gap-3 text-left my-auto" data-en="Depression"
                                    data-es="Depresión">Depression</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherAnxiety"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="Anxiety" v-model="form.motherMedicalHistory">
                                <label for="motherAnxiety" class="flex gap-3 text-left my-auto" data-en="Anxiety"
                                    data-es="Ansiedad">Anxiety</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherNA"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]" value="None" v-model="form.motherMedicalHistory">
                                <label for="motherNA" class="flex gap-3 text-left my-auto" data-en="None"
                                    data-es="Ninguna">None</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="motherHeart"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="motherHistory[]"
                                    value="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    v-model="form.motherMedicalHistory">
                                <label for="motherHeart" class="flex gap-3 text-left my-auto"
                                    data-en="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    data-es="Cardiopatías (problemas del corazón como infartos, arritmias, soplos, insuficiencia cardiáca)">Heart
                                    Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart
                                    failure)</label>
                            </div>
                        </div>
                        <div :class="submitted && !$v.form.motherMedicalHistory.required ? 'flex' : 'hidden'"
                            class="mt-3">
                            <span class="ml-1 my-auto text-red-500 text-xs">Please, select at least one.</span>
                        </div>
                    </div>
                </div>

                <div class="mb-5">
                    <div class="gap-3">
                        <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                            data-en="Grandmother's Medical History" data-es="Historial Médico de la Abuela(s)">Grandmother's
                            Medical
                            History</label><br>
                        <div class="grid grid-cols-2">
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="GrandmotherHypertension"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="GrandmotherHistory[]" value="Hipertensión arterial (presión alta)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherHypertension" class="flex gap-3 text-left my-auto"
                                    data-en="Arterial Hypertension (High blood Pressure)"
                                    data-es="Hipertensión arterial (presión alta)">Arterial Hypertension (High blood
                                    Pressure)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherDiabetes"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Diabetes (azúcar en sangre)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherDiabetes" class="flex gap-3 text-left my-auto"
                                    data-en="Diabetes (azúcar en sangre)" data-es="Diabetes (azúcar en sangre)">Diabetes
                                    (azúcar
                                    en sangre)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherObesity"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Overweight or obesity"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherObesity" class="flex gap-3 text-left my-auto"
                                    data-en="Overweight or obesity" data-es="Sobrepeso u obesidad">Overweight or
                                    obesity</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherDyslipidemia"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]"
                                    value="Dyslipidemias (high triglycerides or cholesterol)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherDyslipidemia" class="flex gap-3 text-left my-auto"
                                    data-en="Dyslipidemias (high triglycerides or cholesterol)"
                                    data-es="Dislipidemias (trigliceridos o colesterol alto)">Dyslipidemias (high
                                    triglycerides
                                    or cholesterol)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherKidney"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Kidney Disease (kidney failure, kidney stones)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherKidney" class="flex gap-3 text-left my-auto"
                                    data-en="Kidney Disease (kidney failure, kidney stones)"
                                    data-es="Enfermedad renal (insuficiencia renal, piedras en riñones)">Kidney Disease
                                    (kidney
                                    failure, kidney stones)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherEpilepsy"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Epilepsy (seizures)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherEpilepsy" class="flex gap-3 text-left my-auto"
                                    data-en="Epilepsy (seizures)" data-es="Epilepsia (convulsiones)">Epilepsy
                                    (seizures)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherThyroid"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Thyroid Disease (hyper or hypothyroidism)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherThyroid" class="flex gap-3 text-left my-auto"
                                    data-en="Thyroid Disease (hyper or hypothyroidism)"
                                    data-es="Enfermedad tiroidea (hiper o hipotiroidismo)">Thyroid Disease (hyper or
                                    hypothyroidism)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherAsthma"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Asthma" v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherAsthma" class="flex gap-3 text-left my-auto" data-en="Asthma"
                                    data-es="Asma">Asthma</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherCancer"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Cancer" v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherCancer" class="flex gap-3 text-left my-auto" data-en="Cancer"
                                    data-es="Cáncer">Cancer</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherDepression"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Depression"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherDepression" class="flex gap-3 text-left my-auto"
                                    data-en="Depression" data-es="Depresión">Depression</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherAnxiety"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="Anxiety"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherAnxiety" class="flex gap-3 text-left my-auto" data-en="Anxiety"
                                    data-es="Ansiedad">Anxiety</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherNA"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]" value="None" v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherNA" class="flex gap-3 text-left my-auto" data-en="None"
                                    data-es="Ninguna">None</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandmotherHeart"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandmotherHistory[]"
                                    value="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    v-model="form.grandmotherMedicalHistory">
                                <label for="grandmotherHeart" class="flex gap-3 text-left my-auto"
                                    data-en="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    data-es="Cardiopatías (problemas del corazón como infartos, arritmias, soplos, insuficiencia cardiáca)">Heart
                                    Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart
                                    failure)</label>
                            </div>
                        </div>
                        <div :class="submitted && !$v.form.grandmotherMedicalHistory.required ? 'flex' : 'hidden'"
                            class="mt-3">
                            <span class="ml-1 my-auto text-red-500 text-xs">Please, select at least one.</span>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <div class="gap-3">
                        <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                            data-en="Grandfather's Medical History" data-es="Historial Médico del Abuelo(s)">Grandfather's
                            Medical
                            History</label><br>
                        <div class="grid grid-cols-2">
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherHypertension"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Hipertensión arterial (presión alta)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherHypertension" class="flex gap-3 text-left my-auto"
                                    data-en="Arterial Hypertension (High blood Pressure)"
                                    data-es="Hipertensión arterial (presión alta)">Arterial Hypertension (High blood
                                    Pressure)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherDiabetes"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Diabetes (azúcar en sangre)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherDiabetes" class="flex gap-3 text-left my-auto"
                                    data-en="Diabetes (azúcar en sangre)" data-es="Diabetes (azúcar en sangre)">Diabetes
                                    (azúcar
                                    en sangre)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherObesity"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Overweight or obesity"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherObesity" class="flex gap-3 text-left my-auto"
                                    data-en="Overweight or obesity" data-es="Sobrepeso u obesidad">Overweight or
                                    obesity</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherDyslipidemia"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]"
                                    value="Dyslipidemias (high triglycerides or cholesterol)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherDyslipidemia" class="flex gap-3 text-left my-auto"
                                    data-en="Dyslipidemias (high triglycerides or cholesterol)"
                                    data-es="Dislipidemias (trigliceridos o colesterol alto)">Dyslipidemias (high
                                    triglycerides
                                    or cholesterol)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherKidney"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Kidney Disease (kidney failure, kidney stones)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherKidney" class="flex gap-3 text-left my-auto"
                                    data-en="Kidney Disease (kidney failure, kidney stones)"
                                    data-es="Enfermedad renal (insuficiencia renal, piedras en riñones)">Kidney Disease
                                    (kidney
                                    failure, kidney stones)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherEpilepsy"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Epilepsy (seizures)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherEpilepsy" class="flex gap-3 text-left my-auto"
                                    data-en="Epilepsy (seizures)" data-es="Epilepsia (convulsiones)">Epilepsy
                                    (seizures)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherThyroid"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Thyroid Disease (hyper or hypothyroidism)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherThyroid" class="flex gap-3 text-left my-auto"
                                    data-en="Thyroid Disease (hyper or hypothyroidism)"
                                    data-es="Enfermedad tiroidea (hiper o hipotiroidismo)">Thyroid Disease (hyper or
                                    hypothyroidism)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherAsthma"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Asthma" v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherAsthma" class="flex gap-3 text-left my-auto" data-en="Asthma"
                                    data-es="Asma">Asthma</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherCancer"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Cancer" v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherCancer" class="flex gap-3 text-left my-auto" data-en="Cancer"
                                    data-es="Cáncer">Cancer</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherDepression"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Depression"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherDepression" class="flex gap-3 text-left my-auto"
                                    data-en="Depression" data-es="Depresión">Depression</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherAnxiety"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="Anxiety"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherAnxiety" class="flex gap-3 text-left my-auto" data-en="Anxiety"
                                    data-es="Ansiedad">Anxiety</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherNA"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]" value="None" v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherNA" class="flex gap-3 text-left my-auto" data-en="None"
                                    data-es="Ninguna">None</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="grandfatherHeart"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="grandfatherHistory[]"
                                    value="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    v-model="form.grandfatherMedicalHistory">
                                <label for="grandfatherHeart" class="flex gap-3 text-left my-auto"
                                    data-en="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    data-es="Cardiopatías (problemas del corazón como infartos, arritmias, soplos, insuficiencia cardiáca)">Heart
                                    Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart
                                    failure)</label>
                            </div>
                        </div>
                        <div :class="submitted && !$v.form.grandfatherMedicalHistory.required ? 'flex' : 'hidden'"
                            class="mt-3">
                            <span class="ml-1 my-auto text-red-500 text-xs">Please, select at least one.</span>
                        </div>
                    </div>
                </div>

                <!-- Section 3: Personal Non-Pathological History -->
                <h2 data-en="Section 3: Personal Non-Pathological History"
                    data-es="Sección 3: Historia Personal No Patológica">Section 3: Personal Non-Pathological History
                </h2>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Do you smoke?" data-es="¿Fumas?">Do you smoke?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="smokeYes" name="smoke"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.smoke" required>
                        <label for="smokeYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="number" id="smokeQuantity"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="smokeQuantity" placeholder="How many per day?" data-en="How many per day?"
                            data-es="¿Cuántos al día?" :disabled="form.smoke !== 'Yes'" required
                            v-model="form.smokeQuantity">
                        <input type="text" id="smokeDuration"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2 min-w-[250px]"
                            name="smokeDuration" placeholder="How long have you been smoking?"
                            data-en="How long have you been smoking?" data-es="¿Hace cuánto empezaste a fumar?"
                            :disabled="form.smoke !== 'Yes'" required v-model="form.smokeDuration">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="smokeNo" name="smoke"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.smoke" required>
                        <label for="smokeNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Do you drink alcohol?" data-es="¿Tomas alcohol?">Do you drink alcohol?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="alcoholYes" name="alcohol"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.alcohol" required>
                        <label for="alcoholYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <select id="alcoholFrequency"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="alcoholFrequency" :disabled="form.alcohol !== 'Yes'" :required="form.alcohol === 'Yes'"
                            v-model="form.alcoholFrequency">
                            <option value="" hidden>Frequency</option>
                            <option value="Daily">Daily/Diario</option>
                            <option value="Occasional">Occasional/Ocasionalmente</option>
                            <option value="Rarely">Rarely/Raramente</option>
                        </select>
                        <input type="text" id="alcoholDuration"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2 min-w-[250px]"
                            name="alcoholDuration" placeholder="How long have you been drinking?"
                            data-en="How long have you been drinking?" data-es="¿Hace cuánto empezaste a tomar?"
                            :disabled="form.alcohol !== 'Yes'" required v-model="form.alcoholDuration">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="alcoholNo" name="alcohol"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.alcohol" required>
                        <label for="alcoholNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Do you use any drugs?" data-es="¿Consumes alguna droga?">Do you use any
                        drugs?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="drugsYes" name="drugs"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.drugs" required>
                        <label for="drugsYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <select id="drugsFrequency"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="drugsFrequency" :disabled="form.drugs !== 'Yes'" :required="form.drugs === 'Yes'"
                            v-model="form.drugsFrequency">
                            <option value="" hidden>Frequency</option>
                            <option value="Daily">Daily/Diario</option>
                            <option value="Occasional">Occasional/Ocasionalmente</option>
                            <option value="Rarely">Rarely/Raramente</option>
                        </select>
                        <input type="text" id="drugsType"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="drugsType" placeholder="What do you use?" data-en="What do you use?"
                            data-es="¿Qué consumes?" :disabled="form.drugs !== 'Yes'" :required="form.drugs === 'Yes'"
                            v-model="form.drugsType">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="drugsNo" name="drugs"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.drugs" required>
                        <label for="drugsNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Are you allergic to anything?" data-es="¿Eres alérgico a algo?">Are you allergic to
                        anything?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="allergyYes" name="allergy"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.allergy" required>
                        <label for="allergyYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="allergyDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2 min-w-[200px]"
                            name="allergyDetails" placeholder="What are you allergic to?"
                            data-en="What are you allergic to?" data-es="¿A qué?" :disabled="form.allergy !== 'Yes'"
                            :required="form.allergy === 'Yes'" v-model="form.allergyDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="allergyNo" name="allergy"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.allergy" required>
                        <label for="allergyNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Do you take any medication for a condition?"
                        data-es="¿Tomas algún medicamento por alguna condición?">Do you take any medication for a
                        condition?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="medicationYes" name="medication"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.medication" required>
                        <label for="medicationYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="medicationDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="medicationDetails" placeholder="Which one?" data-en="Which one?" data-es="¿Cuál?"
                            :disabled="form.medication !== 'Yes'" :required="form.medication === 'Yes'"
                            v-model="form.medicationDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="medicationNo" name="medication"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.medication" required>
                        <label for="medicationNo" class="flex gap-3 text-left my-auto" data-en="No"
                            data-es="No">No</label>
                    </div>
                </div>

                <!-- Section 4: Personal Pathological History -->
                <h2 data-en="Section 4: Personal Pathological History"
                    data-es="Sección 4: Historia Patológica Personal">
                    Section 4: Personal Pathological History</h2>
                <div class="mb-5">
                    <div class="gap-3">
                        <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                            data-en="Have you ever been diagnosed with any of these diseases? (Multiple choice)"
                            data-es="¿Te han diagnosticado alguna vez una enfermedad como estas? (Opción múltiple)">Have
                            you
                            ever been diagnosed with any of these diseases? (Multiple choice)</label><br>
                        <div class="grid grid-cols-2">
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseHypertension"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Hipertensión arterial (presión alta)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseHypertension" class="flex gap-3 text-left my-auto"
                                    data-en="Arterial Hypertension (High blood Pressure)"
                                    data-es="Hipertensión arterial (presión alta)">Arterial Hypertension (High blood
                                    Pressure)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseDiabetes"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Diabetes (azúcar en sangre)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseDiabetes" class="flex gap-3 text-left my-auto"
                                    data-en="Diabetes (azúcar en sangre)" data-es="Diabetes (azúcar en sangre)">Diabetes
                                    (azúcar
                                    en sangre)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseObesity"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Overweight or obesity"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseObesity" class="flex gap-3 text-left my-auto"
                                    data-en="Overweight or obesity" data-es="Sobrepeso u obesidad">Overweight or
                                    obesity</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseDyslipidemia"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Dyslipidemias (high triglycerides or cholesterol)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseDyslipidemia" class="flex gap-3 text-left my-auto"
                                    data-en="Dyslipidemias (high triglycerides or cholesterol)"
                                    data-es="Dislipidemias (trigliceridos o colesterol alto)">Dyslipidemias (high
                                    triglycerides
                                    or cholesterol)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseKidney"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Kidney Disease (kidney failure, kidney stones)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseKidney" class="flex gap-3 text-left my-auto"
                                    data-en="Kidney Disease (kidney failure, kidney stones)"
                                    data-es="Enfermedad renal (insuficiencia renal, piedras en riñones)">Kidney Disease
                                    (kidney
                                    failure, kidney stones)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseEpilepsy"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Epilepsy (seizures)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseEpilepsy" class="flex gap-3 text-left my-auto"
                                    data-en="Epilepsy (seizures)" data-es="Epilepsia (convulsiones)">Epilepsy
                                    (seizures)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseThyroid"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Thyroid Disease (hyper or hypothyroidism)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseThyroid" class="flex gap-3 text-left my-auto"
                                    data-en="Thyroid Disease (hyper or hypothyroidism)"
                                    data-es="Enfermedad tiroidea (hiper o hipotiroidismo)">Thyroid Disease (hyper or
                                    hypothyroidism)</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseAsthma"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Asthma" v-model="form.diagnostedDiseases">
                                <label for="disseaseAsthma" class="flex gap-3 text-left my-auto" data-en="Asthma"
                                    data-es="Asma">Asthma</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseCancer"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Cancer" v-model="form.diagnostedDiseases">
                                <label for="disseaseCancer" class="flex gap-3 text-left my-auto" data-en="Cancer"
                                    data-es="Cáncer">Cancer</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseDepression"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Depression" v-model="form.diagnostedDiseases">
                                <label for="disseaseDepression" class="flex gap-3 text-left my-auto"
                                    data-en="Depression" data-es="Depresión">Depression</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseAnxiety"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="Anxiety" v-model="form.diagnostedDiseases">
                                <label for="disseaseAnxiety" class="flex gap-3 text-left my-auto" data-en="Anxiety"
                                    data-es="Ansiedad">Anxiety</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseNone"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]" value="None" v-model="form.diagnostedDiseases">
                                <label for="disseaseNone" class="flex gap-3 text-left my-auto" data-en="None"
                                    data-es="Ninguna">None</label>
                            </div>
                            <div class="flex gap-3 text-left items-center">
                                <input type="checkbox" id="disseaseHeart"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                                    name="disseaseHistory[]"
                                    value="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    v-model="form.diagnostedDiseases">
                                <label for="disseaseHeart" class="flex gap-3 text-left my-auto"
                                    data-en="Heart Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart failure)"
                                    data-es="Cardiopatías (problemas del corazón como infartos, arritmias, soplos, insuficiencia cardiáca)">Heart
                                    Diseases (heart problems like heart attacks, arrhythmias, murmurs, heart
                                    failure)</label>
                            </div>
                        </div>
                        <div :class="submitted && !$v.form.diagnostedDiseases.required ? 'flex' : 'hidden'"
                            class="mt-3">
                            <span class="ml-1 my-auto text-red-500 text-xs">Please, select at least one.</span>
                        </div>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Have you had surgery before? (Cesarean sections are considered surgeries)"
                        data-es="¿Te han operado antes? (Las cesáreas también se consideran cirugías)">Have you had
                        surgery
                        before? (Cesarean sections are considered surgeries)</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="surgeryYes" name="surgery"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.surgery" required>
                        <label for="surgeryYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="surgeryDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="surgeryDetails" placeholder="Which one?" data-en="Which one?" data-es="¿Cuál?"
                            :disabled="form.surgery !== 'Yes'" :required="form.surgery === 'Yes'"
                            v-model="form.surgeryDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="surgeryNo" name="surgery"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model=form.surgery required>
                        <label for="surgeryNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Have you ever broken a bone?" data-es="¿Te has fracturado algún hueso?">Have you ever
                        broken a bone?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="fractureYes" name="fracture"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model=form.fracture required>
                        <label for="fractureYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="fractureDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2 min-w-[300px]"
                            name="fractureDetails" placeholder="Which one and when? What treatment?"
                            data-en="Which one and when? What treatment?" data-es="¿Qué y cuándo? ¿Qué tratamiento?"
                            :disabled="form.fracture !== 'Yes'" :required="form.fracture === 'Yes'"
                            v-model="form.fractureDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="fractureNo" name="fracture"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model=form.fracture required>
                        <label for="fractureNo" class="flex gap-3 text-left my-auto" data-en="No"
                            data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Have you ever had a seizure?" data-es="¿Te has convulsionado alguna vez?">Have you ever
                        had
                        a seizure?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="seizureYes" name="seizure"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.seizure" required>
                        <label for="seizureYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="seizureDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2 min-w-[300px]"
                            name="seizureDetails" placeholder="When and was treatment provided?"
                            data-en="When and was treatment provided?" data-es="¿Cuándo y se dio tratamiento?"
                            :disabled="form.seizure !== 'Yes'" :required="form.seizure === 'Yes'"
                            v-model="form.seizureDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="seizureNo" name="seizure"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.seizure" required>
                        <label for="seizureNo" class="flex gap-3 text-left my-auto" data-en="No" data-es="No">No</label>
                    </div>
                </div>
                <div class="mb-3 flex gap-3">
                    <label class="block mb-2 text-md font-medium leading-none text-gray-900 text-nowrap my-auto"
                        data-en="Have you ever had a work accident?"
                        data-es="¿Has tenido algún accidente de trabajo?">Have
                        you ever had a work accident?</label><br>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="accidentYes" name="accident"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="Yes" v-model="form.accident" required>
                        <label for="accidentYes" class="flex gap-3 text-left my-auto" data-en="Yes"
                            data-es="Sí">Yes</label>
                        <input type="text" id="accidentDetails"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-2"
                            name="accidentDetails" placeholder="Which one?" data-en="Which one?" data-es="¿Cuál?"
                            :disabled="form.accident !== 'Yes'" :required="form.accident === 'Yes'"
                            v-model="form.accidentDetails">
                    </div>
                    <div class="flex gap-3 text-left items-center">
                        <input type="radio" id="accidentNo" name="accident"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus-none shrink-0"
                            value="No" v-model="form.accident" required>
                        <label for="accidentNo" class="flex gap-3 text-left my-auto" data-en="No"
                            data-es="No">No</label>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary" id="submitButton">{{ submitButtonText }}</button>
            </form>
        </div>
        <span v-else class="text-red-700 bg-red-300 font-semibold border-red-700 rounded-full px-2 py-1">
            {{ message }}
        </span>
    </div>
    <div v-else>
        Loading
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            showContactError: false,
            submitButtonText: 'Submit',
            languageSwitcherText: '🇺🇸',
            loading: true,
            available: false,
            message: '',
            submitted: false,

            form: {
                civilStatus: '',
                schoolLevel: '',
                emergencyContactNumber: '',
                emergencyContactName: '',
                emergencyContactRelationship: '',
                fatherMedicalHistory: [],
                motherMedicalHistory: [],
                grandmotherMedicalHistory: [],
                grandfatherMedicalHistory: [],
                smoke: '',
                smokeQuantity: '',
                smokeDuration: '',
                alcohol: '',
                alcoholFrequency: '',
                alcoholDuration: '',
                drugs: '',
                drugsFrequency: '',
                drugsType: '',
                allergy: '',
                allergyDetails: '',
                medication: '',
                medicationDetails: '',
                diagnostedDiseases: [],
                surgery: '',
                surgeryDetails: '',
                fracture: '',
                fractureDetails: '',
                seizure: '',
                seizureDetails: '',
                accident: '',
                accidentDetails: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'getSurveysV2Details'
        ])
    },
    validations: {
        form: {
            fatherMedicalHistory: { required },
            motherMedicalHistory: { required },
            grandmotherMedicalHistory: { required },
            grandfatherMedicalHistory: { required },
            diagnostedDiseases: { required }
        },
    },
    methods: {
        ...mapActions([
            'storeMedicalSurvey',
            'checkMedicalSurvey'
        ]),
        validateContact() {
            const valid = /^\d{10}$/.test(this.form.emergencyContact);
            this.showContactError = !valid;
        },
        handleSubmit() {
            event.preventDefault();
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if (!this.showContactError) {
                this.loading = true;
                this.storeMedicalSurvey(this.form).then(() => {
                    this.$fire({
                        type: 'success',
                        title: 'Success',
                        text: 'Your medical survey has been submitted successfully'
                    }).then(() => {
                        this.$router.push({ name: 'dashboard' });
                    })
                }).catch((error) => {
                    this.loading = false;
                    this.$fire({
                        type: 'error',
                        title: 'Something went wrong',
                        text: `Details: ${error.response.data.message ?? 'Unexpected error'}`,
                        showConfirmButton: true,
                    })
                })
            }
        },
        toggleLanguage() {
            const isEnglish = this.languageSwitcherText === '🇺🇸';
            const elements = document.querySelectorAll('[data-en]');
            elements.forEach(el => {
                const textKey = isEnglish ? 'es' : 'en';
                el.textContent = el.getAttribute(`data-${textKey}`);
            });
            this.languageSwitcherText = isEnglish ? '🇲🇽' : '🇺🇸';
            this.submitButtonText = isEnglish ? 'Enviar' : 'Submit';
        },

    },
    created() {
        this.loading = true;
        this.checkMedicalSurvey().then(() => {
            this.message = this.getSurveysV2Details?.message;
            this.available = this.getSurveysV2Details?.available;
            this.loading = false;
        });
    }
}
</script>