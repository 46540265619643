import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    citiesList: []
};
const getters = {
    getCities: (state) => state.citiesList,    
};
const actions = {
    async fetchCitiesByState ({commit}, stateSelected){        
        const endpoint = API_URL + 'api/v1/states/' + stateSelected + '/cities'
        await axios.get(endpoint).then((response)=>{
            commit('setCitiesList',response.data.StateInfo);
        });
    }
};
const mutations = {
    setCitiesList: (state, params) => (state.citiesList = params),
};

export default {
    state,
    getters,
    actions,
    mutations
};