<template>
    <div class="mx-auto text-left max-w-4xl px-10 my-4 py-6"> 
        <div class="flex justify-between items-center">
            <span class="font-light text-gray-600"></span>            
        </div>
        <div class="mt-2">
        <p class="text-2xl text-gray-700 hover:text-gray-600 text-center font-semibold" >
            <slot name='headline'>
                Looks like someone stole our open positions.
            </slot>
        </p>
        <img :src="img" class="mx-auto" :class="w">

        <p class="text-1xl text-gray-700 mt-3 hover:text-gray-600 text-center text-gray-500 font-semibold" >
              <slot name='subline'>
                Come back later to see if we get it back.
            </slot>
        </p>
        </div>
    </div> 
</template>
<script>
export default {
    props:{
        media:{
            type: String,
            default:"out_of_inspiration"
        },
        width:{
            type: String,
            default:"50"
        },
    },
    computed:{
        img:function(){
            return require(`@/assets/${this.media}.svg`)
        },

        w:function(){
            return `w-${this.width}`;
        }
    }
}
</script>