<template>
    <div v-if="getMyFeedbacks.length > 0">
        <div class="custom-control custom-switch mt-2 mb-2">
            <input v-model="toggle" type="checkbox" class="custom-control-input" id="customSwitch1">
            <label v-if="!toggle" class="custom-control-label" for="customSwitch1">All Feedbacks</label>
            <label v-else class="custom-control-label" for="customSwitch1">Pendings Only</label>
        </div>

        <ul v-if="toggle" class="list-group">
            <div v-if="this.Filtered.length > 0">
                <li class="d-flex justify-content-between align-items-center mb-2"  v-for="feedback in this.Filtered"
                    v-bind:key="feedback.id"
                    @click="onFocus">
                    <button type="button" :class="{ 'bg-gray-200': feedback.viewed_at != null, 'bg-blue-600 hover:bg-blue-700 text-white': feedback.viewed_at == null }" @click="selectFeedback(feedback)" class="align-items-center px-6 py-2 rounded">
                        <span class="float-left">Feedback Date: {{feedback.created_at | moment("MMMM Do YYYY")}} </span>
                        <span :class="{ hide: feedback.viewed_at != null}" class="float-right badge badge-primary badge-pill">New</span>
                    </button>
                </li>
            </div>
            <div v-else class="text-center">
                <div class="alert alert-danger flex flex-auto align-items-center justify-content-center pt-4" role="alert">
                    <p class="text-center font-semibold">You don't have any pending feedbacks.</p>
                </div>
            </div>
        </ul>

        <ul v-else class="list-group">
            <li class="d-flex justify-content-between align-items-center mb-2"  v-for="feedback in this.NonFiltered"
                v-bind:key="feedback.id"
                @click="onFocus">
                <button type="button" :class="{ 'bg-gray-200': feedback.viewed_at != null, 'bg-blue-600 hover:bg-blue-700 text-white': feedback.viewed_at == null }" @click="selectFeedback(feedback)" class="align-items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">
                    <span class="float-left">Feedback Date: {{feedback.created_at | moment("MMMM Do YYYY")}} </span>
                    <span :class="{ hide: feedback.viewed_at != null}" class="float-right badge badge-primary badge-pill">New</span>
                </button>
            </li>
        </ul>
    </div>
    <div v-else class="text-center">
        <div class="alert alert-danger flex flex-auto align-items-center justify-content-center pt-4" role="alert">
            <p class="text-center font-semibold">You don't have any feedback.</p>
        </div>
    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    export default {
        data() {
            return{
                NonFiltered: [],
                Filtered: [],
                FilterSelected: [],

                toggle: true,
            }
        },
        created(){
            this.fetchAuditsByAgent().then(()=>{
                this.NonFiltered = this.getMyFeedbacks;
                this.filterItems(this.NonFiltered);
            })
        },
        computed:{
            ...mapState(['feedbacks']),
            ...mapGetters(['getMyFeedbacks']) 
        },
        methods: {
          ...mapActions(['fetchAuditsByAgent']),

          selectFeedback: function (data) {
            this.$emit('feedback_selected', data);
          },

          onFocus: function (e) {
            let target = e.target;
            document.querySelectorAll('li').forEach(
                element => element.classList.remove('active')
            );
            target.closest("li").classList.add('active');
          },

          filterItems: function (items){
            const result = items.filter(item => item.agent_comment === null);
            this.Filtered = result;
          },

          changeToggle: function (){
              this.toggle = !this.toggle;
          },
        }
    }
</script>
<style scoped>
.btn .badge {
    top: 3px !important;
}
.primary{
    border: 2px solid #007bff !important;
}
.secondary{
 border-color: #6c757d;
}
li.active{
  border:solid dodgerblue 1px !important;
}
</style>