<template>
    <div>
        <modal @toggle="addNewSubject" v-if="show_modal"/>
        <div class="text-center">
            <button @click="show_modal = true" class="bg-green-600 text-white rounded-full leading-none px-2 py-1 space-x-2 uppercase font-semibold">Add a new Subject for this class<i class="bi bi-plus "></i></button>
        </div> 
        <div class="flex space-x-5 px-1 py-2 mb-10 overflow-auto">
            <div v-for="i in getTrainingScores" :key="i.id" class="bg-gradient-to-b from-amber-50 to-white border-4 border-white shadow-sm rounded-xl px-6 pt-8 pb-3 mt-10">
                <div class="w-24 h-24 bg-gradient-to-t from-orange-500 to-amber-300 border-2 border-white text-white text-4xl tracking-tighter flex items-center justify-center shadow-sm rounded-full mx-auto -mt-16 mb-3">{{ i.average }}<span class="text-sm">%</span></div>
                <h3 class="text-lg text-slate-800 text-center font-semibold leading-none mb-0 uppercase">{{ i.full_name }}</h3>
                <div class="text-slate-600 space-y-1 my-3">
                    <div class="flex items-center justify-between leading-none space-x-5 whitespace-nowrap"
                    v-for="score in i.scores" :key="score.subject">
                    <div>{{ score.subject }}</div>
                    <div class="font-semibold">
                        <input class="text-right bg-transparent no-arrows w-full" type="number" min="0" max="100" placeholder="0"
                        inputmode="numeric" pattern="[0-9]*" :disabled="loading"
                        :class="{'text-red-500' : score.score > 100}" v-model="score.score" @input="changeScoreDebounced(score)">%</div>
                    </div>
                </div>
                <!-- <div class="text-center">
                    <button @click="showModal(i)" class="bg-green-600 text-white rounded-full text-xl leading-none px-2 py-1"><i class="bi bi-plus "></i></button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Debounce from 'debounce';
import modal from './modals/AddNewScoreModal';
export default {
    components: {
        modal
    },
    data () {
        return {
            loading: false,
            show_modal: false,

            training_id: this.$route.params.id
        }
    },
    computed: {
        ...mapGetters([
            'getTrainingScores'
        ]),
    },
    methods:{
        ...mapActions([
            'fetchTrainingScores',
            'updateTrainingScore',
            'addTrainingSubject',
        ]),
        
        changeScoreDebounced: Debounce(function (score) {
            if(score.score === "" || score.score > 100) return;
            this.changeScore(score);
        }, 2000),

        changeScore (score) {
            this.loading = true;
            let obj = {
                training_class_id: this.training_id,
                id: score.id,
                score: parseInt(score?.score, 10)
            }
            this.updateTrainingScore(obj).then(()=>{
                this.fetchTrainingScores(this.training_id).then(() => {
                    this.loading = false;
                });
            });
        },

        async addNewSubject (subject) {
            if(subject.confirmation) {
                this.loading = true;
                let obj = {
                    training_class_id: this.training_id,
                    subject: subject.subject,
                }
                this.addTrainingSubject(obj).then(()=>{
                    this.fetchTrainingScores(this.training_id).then(() => {
                        this.loading = false;
                    });
                })
            }
            this.show_modal = false;
        },
    },
    beforeMount () {
        this.$emit('set-title','Certification Scores');
    },
    created() {
        this.fetchTrainingScores(this.training_id);
    }
}
</script>