<template>
    <div class="w-full h-full py-4">
         <div class="max-w-3xl w-auto p-3 px-2  mx-auto flex flex-row items-center justify-start">
            <training-details/>
         </div>
         <div class="max-w-7xl mx-auto">
 
             <div class="flex flex-col">
                 <div class="overflow-x-auto shadow-md sm:rounded-lg">
                     <div class="inline-block min-w-full align-middle">
                         <div class="overflow-hidden ">
                             <table class="min-w-full divide-y divide-gray-200 table-fixed table-even dark:divide-gray-700">
                                 <thead class="bg-gray-100 dark:bg-gray-700">
                                     <tr>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                         Trainee_id
                                         </th>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                             Comment
                                         </th>
                                         <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                             Author
                                         </th>
                                         
                                         <th scope="col" class="p-4 text-right">
                                             Actions
                                         </th>
                                     </tr>
                                 </thead>
                                 <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700" v-if="!loading">
                                 
                                     <edit-attrition-risk :item="item"  v-for="item in list" :key="item.id"  @delete="deleteRow" @update="updateRow"/>
 
                                     <new-attrition-risk v-if="list.length < training['trainees_number']" :key="'atr' + resetKey" @new="resetFactoryKey"/>
                                     
                                 </tbody>
                                 <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"  v-if="loading">
                                 
                                     <tr class="hover:bg-gray-100 dark:hover:bg-gray-700 animate-pulse" v-for="index in 4" :key="index"> 
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left ">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                                             <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                         </td>
                                         <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                             <div class="flex flex-row items-center justify-end">
                                                 <div class="relative h-2 w-full bg-gray-400 rounded-full"></div>
                                             </div>
                                         </td>
                                     </tr>
 
                                     
                                 </tbody>
                             </table>
                             <div class="flex flex-row items-center justify-end pr-3 py-3">
                                 <button class="bg-gray-700 px-3 py-2 text-white rounded-sm shadow-sm" @click="$router.push({name:'recruitment.trainings.staff'})">
                                     Next
                                 </button>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
    </div>
     <!---
     1.- Al cargar el componente traernos todos los miembros del training que esten en attrition.
     2.- Esos miembros los vamos  a guardar en local storage. 
     3.- Al agregar o quitar un componente se modificara en local storage.
     4.- Agregar un boton para guardar bloque y para avanzar al siguiente step.
    -->
 </template>
 
 <script>
 import {mapActions, mapGetters} from 'vuex'
// Pinia > Vuex
 import TrainingDetails from '../../components/misc/trainings/TrainingDetails.vue'
 import NewAttritionRisk from '../../components/misc/trainings/NewAttritionRisk.vue'
 import EditAttritionRisk from '../../components/misc/trainings/EditAttritionRisk.vue'
 export default {
     components:{
         NewAttritionRisk, 
         TrainingDetails,
         EditAttritionRisk
     },
     data(){
         return{
             loading:true,
             resetKey:0,
         }
     },
     methods:{
         ...mapActions([
                'fetchAttritionRisk',
                'deleteAttritionRisk',
                'editAttritionRisk'
            ]),
         resetFactoryKey:function(){
             this.resetKey ++; 
         },
         deleteRow:async function(id){
             await  this.delete(id);
             this.resetKey ++;
             await this.fetchAttritionRisk(this.$route.params.id);
             
         },
         updateRow:async function(form){
            await this.edit(form);
             this.resetKey ++;
             await this.fetchAttritionRisk(this.$route.params.id);
         }
     },
     computed:{
        ...mapGetters([
            '_list',
            'training' //training is from details
        ]),
         
         list:function(){
             return this._list;
         },
     },
      async created(){
         this.$emit('set-title','Training Attrition Risk');  
         await this.fetchAttritionRisk(this.$route.params.id);
         this.loading = false;
     },
 }
 </script>
 
 <style>
 .shadow-soft{
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }
 </style>