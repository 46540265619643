import axios from '@/config/axios'

const state = {
   filters:{
       purchase_order:null,
       site_location:""
   },
   training_list:[],
   attendance:[],
   status:true,
   training:{
    trainer_empID:"",
    trainees_number:0,                
    department_id:"",
    campaign_id:"",
    lob_id:"",
    location:"",        
    start_date:null,
    end_date:null,
    schedule_start:null,
    schedule_end:null
   },
   default:{
    trainer_empID:"",
    trainees_number:0,                
    department_id:"",
    campaign_id:"",
    lob_id:"",
    location:"",        
    start_date:null,
    end_date:null,
    schedule_start:null,
    schedule_end:null
   },
   trainees_list:[],
   daily:[],
   events:[],
   attrition:[],
   attrition_trainees:[],
   staff:[],
   staff_list:[],
   training_activities:[],
   engagements:[],
   engagement_trainees:[],
   daily_status:'await',
   hours: [],
   notes: [],
   clocker_events: [],
   scores: []
};

const getters = {
    getTrainingList: (state) => state.training_list,
    getTrainingFilters:(state) => state.filters,
    getCurrentTraining:(state) => state.training,
    getTrainingStatus:(state) => state.status,
    getTrainingAttendance: (state) => state.attendance,
    getTrainees:(state) => state.trainees_list,
    getTrainingDaily:(state) => state.daily,
    getTrainingEvents:(state) => state.events,
    getTrainingAttrition:(state) => state.attrition,
    getAttritionTrainees:(state) => state.attrition_trainees,
    getTrainingStaff:(state) => state.staff,
    getTrainingStaffList:(state) => state.staff_list,
    getTrainingActivities: (state) => state.training_activities,
    getTrainingEngagements:(state) => state.engagements,
    getEngagementTrainees:(state) => state.engagement_trainees,
    getTrainingDailyStatus:(state) => state.daily_status,
    getTrainingHours: (state) => state.hours,
    getTrainingNotes: (state) => state.notes,
    getTrainingClockerEvents: (state) => state.clocker_events,
    getTrainingScores: (state) => state.scores,
};

const actions = {
    async fetchTrainingList({ commit }){        
        commit('setTrainingStatus',true);
        const response = await axios.get('api/v2/recruitment/trainings');
        commit('setTrainingList',response.data.trainings);
        commit('setTrainingStatus',false);
    },
    async filterTrainingList({ commit },formData){        
        commit('setTrainingStatus',true);
        const response = await axios.post('api/v2/recruitment/trainings/filterTrainings',formData);
        commit('setTrainingList',response.data.trainings);
        commit('setTrainingStatus',false);
    },
    async storeTraining({commit},form){
        commit('setTrainingStatus',true);
        const response = await axios.post('api/v2/recruitment/trainings/new',form);
        commit('setTrainingList',response.data.trainings);
        commit('setTrainingStatus',false);
        return response;
    }, 
    async dropTraining({commit},id){
        commit('setTrainingStatus',true);
        const response = await axios.delete(`api/v2/recruitment/trainings/${id}/drop`);
        commit('setTrainingList',response.data.trainings);
        commit('setTrainingStatus',false);
        return response;
    }, 
    async filterPurchaseOrders({commit},filters){
        const response = await axios.get('api/v2/recruitment/purchase-orders/filter',{params:
            filters
        });
        commit('setPurchaseOrders',response.data.list);
        return response;
    },   
   
    async fetchTrainingById({commit}, id){
        commit('setTrainingStatus','loadingTraining');
        const response = await axios.get(`api/v2/recruitment/trainings/fetchById/${id}`).then((res)=>{
            commit('setCurrentTraining',res.data.training);
        });
        return response;

    },
    async updateTraining({commit}, form){
        commit('setTrainingStatus','saving');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.id}/update`,form).then((res)=>{
            commit('setCurrentTraining',res.data.training);
        }).then(()=>{
            commit('setTrainingStatus','saved');
        });
        return response;

    },
    async fetchTrainingAttendance({commit}, id){
        commit('setTrainingStatus','loading');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/attendance`).then((res)=>{
            commit('setTrainingAttendance',res.data.list);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;

    },
    async fetchTrainees({commit},id){
        commit('setTrainingStatus','loadingTrainees');
        const response = await axios.get(`api/v2/recruitment/trainings/fetchTrainees/${id}`).then((res)=>{
            commit('setTraineesList',res.data.trainees);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;

    },
    async fetchDailyAssist({commit},id){
        commit('setTrainingStatus','loadingDaily');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchDailyAssist`).then((res)=>{
            commit('setDaily',res.data.daily);
            commit('setTrainingDailyStatus',res.data.status);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;

    },
    async pushCandidateToTraining({commit},form){
        commit('setTrainingStatus','savingCandidateTraining');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.training_class_id}/addTrainee`,form).then((res)=>{
            commit('setTraineesList',res.data.trainees);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;
    },
    async linkTraineeToDaily({commit},dataset){
        commit('setTrainingStatus','loadingDaily');
        const response = await axios.post(`api/v2/recruitment/trainings/${dataset.training_class_id}/daily`,dataset).then((res)=>{
            commit('setDaily',res.data.daily);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;

    },  
    async clockInTrainee({commit},dataset){
        commit('setTrainingStatus','clockinIn');
        const response = await axios.post(`api/v2/recruitment/trainings/trainees/clock-in`,dataset).then((res)=>{
            commit('setTrainingStatus','completed');
            return res;
        });
        return response;

    },
    async clockOutTrainee({commit},EmpID){
        commit('setTrainingStatus','clockinIn');
        const response = await axios.post(`api/v2/recruitment/trainings/trainees/clock-out`,{EmpId:EmpID}).then((res)=>{
            commit('setTrainingStatus','completed');
            return res;
        });
        return response;

    },
    async fetchTrainingEvents({commit},formData){
        commit('setTrainingStatus','loadingEvents');
        const response = await axios.post(`api/v2/recruitment/trainings/trainees_events`,formData).then((res)=>{
            commit('setTrainingEvents',res.data.events);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async saveTrainingStaff({commit},formData){
        commit('setTrainingStatus','savingTrainingStaff');
        const response = await axios.post(`api/v2/recruitment/trainings/saveTrainingStaff`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async saveTrainingActivity({commit},formData){
        commit('setTrainingStatus','savingActivity');
        const response = await axios.post(`api/v2/recruitment/trainings/saveTrainingActivity`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async fetchTrainingActivity({commit},id){
        commit('setTrainingStatus','loadingActivities');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchTrainingActivity`).then((res)=>{
            commit('setTrainingActivities',res.data.activities);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },

    async fetchTrainingEngagements({commit},id){
        commit('setTrainingStatus','loadingEngagements');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchEngagements`).then((res)=>{
            commit('setTrainingEngagements',res.data.engagements);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async fetchEngagementTrainees({commit},id){
        commit('setTrainingStatus','loadingEngagementTrainees');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchEngagementTrainees`).then((res)=>{
            commit('setEngagementTrainees',res.data.trainees);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },

    async saveTrainingEngagement({commit},formData){
        commit('setTrainingStatus','loadingEngagementTrainees');
        const response = await axios.post(`api/v2/recruitment/trainings/saveEngagement`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async saveTrainingDaily({commit},formData){
        commit('setTrainingStatus','savingDaily');
        const response = await axios.post(`api/v2/recruitment/trainings/saveTrainingDaily`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },

    async checkTrainingStatus({commit},id){
        commit('setTrainingStatus','savingDaily');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/dailyTrainingStatus`).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    
    
    async startEvent({commit},formData){
        commit('setTrainingStatus','loadingEvents');
        const response = await axios.post(`api/v2/recruitment/trainings/trainees_events/startGroupActivity`,formData).then((res)=>{
            return res;
        });
        return response;

    },

    async fetchTrainingAttrition({commit},id){
        commit('setTrainingStatus','loadingAttritions');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/attrition`).then((res)=>{
            commit('setTrainingAttrition',res.data.attrition);
            return res;
        });
        return response;

    },
    async fetchAttritionPendingTrainees({commit},id){
        commit('setTrainingStatus','loadingAttritionsTrainees');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/attrition-trainees`).then((res)=>{
            commit('setTrainingAttritionTrainees',res.data.trainees);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;

    },
    async saveTrainingAttrition({commit},formData){
        commit('setTrainingStatus','loadingAttritionsTrainees');
        const response = await axios.post(`api/v2/recruitment/trainings/saveAttrition`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },
    async deleteTrainingAttrition({commit},id){
        commit('setTrainingStatus','loadingAttritionsTrainees');
        const response = await axios.delete(`api/v2/recruitment/trainings/attrition/${id}/delete`).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async deleteFromTraining({commit},form){
        commit('setTrainingStatus','loadingAttritionsTrainees');
        const response = await axios.delete(`api/v2/recruitment/trainings/delete-from-training`,{params:form}).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchTrainingStaff({commit},id){
        commit('setTrainingStatus','loadingTrainingStaff');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchTrainingStaff`).then((res)=>{
            commit('setTrainingStaff',res.data.staff);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchTrainingStaffList({commit}){
        commit('setTrainingStatus','loadingTrainingStaff');
        const response = await axios.get(`api/v2/recruitment/trainings/fetchTrainingStaffList`).then((res)=>{
            commit('setTrainingStaffList',res.data.staff);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async saveTrainingHours({commit},formData){
        commit('setTrainingStatus','savingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/saveTrainingHours`,formData).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchTrainingHours({commit},id){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchTrainingHours`).then((res)=>{
            commit('setTrainingHours',res.data.hours);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async updateClockerWithTrainingHours({commit},id){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/updateClockerWithTrainingHours`, id).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async sendTrainingNote({commit}, form){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.class_id}/send-training-note`, form).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async updateTrainingNote({commit}, form){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.note_id}/update-training-note`, form).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchTrainingNotes({commit}, form){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.class_id}/get-training-notes`, form).then((res)=>{
            commit('setTrainingStatus',false);
            commit('setTrainingNotes', res.data.notes);
            return res;
        });
        return response;
    },
    
    async deleteTrainingNote({commit}, id){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${id}/delete-training-note`).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },
    
    async restoreTrainingNote({commit}, id){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${id}/restore-training-note`).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchAttendance({commit},id){
        commit('setTrainingStatus','loadingDaily');
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetch-attendance`).then((res)=>{
            commit('setTrainingAttendance',res.data.attendance);
        }).then(()=>{
            commit('setTrainingStatus','completed');
        });
        return response;
    },

    async updateTrainingAttendance({commit}, data){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${data?.id}/update-attendance`, data).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async fetchTrainingClockerEvents({commit},id){
        const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetch-training-events`).then((res)=>{
            commit('setClockerEvents',res.data.events);
        });
        return response;
    },

    async fetchTrainingScores({commit}, id){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${id}/fetch-training-scores`).then((res)=>{
            commit('setTrainingScores', res.data.scores);
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },    
    
    async updateTrainingScore({commit}, form){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.id}/update-training-scores`, form).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },

    async addTrainingSubject({commit}, form){
        commit('setTrainingStatus','loadingTrainingHours');
        const response = await axios.post(`api/v2/recruitment/trainings/${form.training_class_id}/add-training-subject`, form).then((res)=>{
            commit('setTrainingStatus',false);
            return res;
        });
        return response;
    },
}

const mutations = {
    setTrainingList: (state, list) => (state.training_list = list),
    setCurrentTraining:(state, training) => (state.training = training),
    resetTrainings:(state) =>{
        state.training = state.defaultTraining;
        state.status ='pending';
    },
    setCurrentTrainingProperty:(state,property, value)=>(state.training[property] = value),
    setTrainingParent:(state, parent_id) =>(state.training.po_id = parent_id),
    setTrainingStatus:(state,status) => (state.status = status), 
    setTrainingRoom:(state,room) =>  (state.training.training_room = room),
    setTrainingCertificationDate:(state,certificationDate) => (state.training.certification_date = certificationDate),
    setTrainerId:(state,id) => (state.training.trainer_id = id),
    setTrainingAttendance:(state,attendance)=>(state.attendance = attendance),
    setTraineesList:(state,trainees)=>(state.trainees_list = trainees),
    setDaily:(state, daily) =>(state.daily = daily),
    setTrainingEvents:(state, events) => (state.events = events),
    setTrainingAttrition:(state,attrition) => (state.attrition = attrition),
    setTrainingAttritionTrainees:(state, trainees) => (state.attrition_trainees = trainees),
    setTrainingStaff:(state, staff) => (state.staff = staff),
    setTrainingStaffList:(state, list) => (state.staff_list = list),
    setTrainingActivities:(state,activities) => (state.training_activities = activities),
    setTrainingEngagements:(state,engagements) => (state.engagements = engagements),
    setEngagementTrainees:(state,trainees) => (state.engagement_trainees = trainees),
    setTrainingDailyStatus:(state,dailyStatus) => (state.daily_status = dailyStatus),
    setTrainingHours:(state, hours) => (state.hours = hours),
    setTrainingNotes:(state, notes) => (state.notes = notes),
    setClockerEvents:(state, clocker_events) => (state.clocker_events = clocker_events),
    setTrainingScores:(state, scores) => (state.scores = scores),
};

export default {
    state,
    getters,
    actions,
    mutations
};