<template>
<div>
  <loading :active.sync="getLoadingTable" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
  <div id="EditTable" class="userTable">
    <b-container fluid class="TableSearch">
      <b-row>
        <b-col align-self="center">
          <b-input-group class="mt-3 mb-3" >
            <b-form-input v-model="keyword" @keyup.enter="filterSearch" placeholder="Search by EmpID" id='filterKeyword' type="text"></b-form-input>
            <button id='searchButton' type="button" :disabled='isDisabled' @click='clearSearch' class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Clear Search</button>  
          </b-input-group>
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-center" scope="col">EmpID</th>
                <th class="text-center" scope="col">First Name</th>
                <th class="text-center" scope="col">Last Name</th>
                <th class="text-center" scope="col">Campaign</th>
                <th class="text-center" scope="col">L.O.B</th>
                <th class="text-center" scope="col">Location</th>
                <th class="text-center" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody v-if="getUsers.length">
              <tr :id="employee.RecID" v-for="employee in getUsers" :key="employee.RecID">
                  <td>{{employee.EmpID}}</td>
                  <td>{{employee.first_name}}</td>
                  <td>{{employee.LastName1}}</td>
                  <td>{{employee.Project}}</td>
                  <td>{{employee.LOB}}</td>
                  <td>{{employee.Location}}</td>
                  <td>
                    <span :id="'span'+employee.RecID" class="material-icons viewIcon" v-b-modal="'chagePassword'+employee.RecID"  >loop</span>
                    <b-modal :id="'chagePassword'+employee.RecID" hide-footer>
                      <div>
                        <ClockerPassword :id="'clocker_password'+employee.RecID" :infoEmployee="employee" ></ClockerPassword>
                      </div>
                    </b-modal>
                  </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">No records found.</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</div>
</template>
<script>

  import {mapGetters,mapState, mapActions } from 'vuex'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import ClockerPassword from '@/views/components/Modal/change_password'
  export default {
    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
      }
    },
    components: {
      Loading, ClockerPassword
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters(['getUsers','getLoadingTable'])
    },
    created(){
      this.$store.dispatch('fetchUsers');
    },
    methods:{
      ...mapActions(['fetchUsers', 'clockerSearch']),
      filterSearch() {
          this.clockerSearch(document.getElementById('filterKeyword').value);
          this.isDisabled = false;
      },
      gotoProfile(val){
          this.$router.push({ 
              name: 'profileEmployee.personal', 
              params: { id: val.RecID}
            });
      },
      clearSearch(){
          this.$store.dispatch('fetchUsers');
          this.isDisabled = true;
          document.getElementById('filterKeyword').value = '';
      }
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>