import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    pagination: {},
    newItemsProperties: [],
    itemData: [],
    filteredInventoryItems: [],
    inventoryLoading: false,
    inventoryEmployee: [],
};
const getters = {
    getFilteredInventoryPagination: state => state.pagination,
    getInventoryLoading:            state => state.inventoryLoading,
    getNewItemsProperties:          state => state.newItemsProperties,
    getFilteredInventoryItems:      state => state.filteredInventoryItems,
    getItemData:                    state => state.itemData,
    getInventoryEmployee:           state => state.inventoryEmployee
};
const actions = {

    async fetchNewItemProperties({ commit }){
        const endpoint = API_URL + 'api/v2/inventory/new-item-properties';
        const response = await axios.get(endpoint).then((res)=>{
            if(res.status === 200){
                commit('setNewItemsProperties',res.data)
            }
            return res;
        }).catch((error) => {
            return error.response;
        });    
        return response;
    },

    async addNewItemToInventory({ commit }, data){
        const endpoint = API_URL + 'api/v2/inventory/add-new-item';
        const response = await axios.post(endpoint, data).then((res)=>{
            commit('setItemData',res.item)
            return res;
        })  
        return response;
    },

    async editItemToInventory({ commit }, data){
        const endpoint = API_URL + 'api/v2/inventory/edit-item';
        const response = await axios.post(endpoint, data).then((res)=>{
            commit('setItemData',res.item)
            return res;
        })  
        return response;
    },

    async filterInventoryItems({ commit },formData){    
        commit('setInventoryLoading', true);
        const response = await axios.post(`api/v2/inventory/filtered-inventory-items?page=${formData.page}`, formData);
        commit('setFilteredInventoryItems', response.data.items);
        commit('setFilteredInventoryPagination', {meta: response.data.meta, links: response.data.links});
        commit('setInventoryLoading', false);
    },

    async getInventoryItemInfo({ commit },formData){                
        const response = await axios.get('api/v2/inventory/get-item-information/'+formData);
        commit('setItemData', response.data.item);
    },

    async assignItem({ commit },formData){    
        commit('setInventoryLoading', true);            
        await axios.post('api/v2/inventory/assign-inventory-item',formData).then((res)=>{
            commit('setInventoryLoading', false);
            return res;
        }) 
    },

    async letterUploaded({ commit },formData){    
        commit('setInventoryLoading', true);            
        await axios.post('api/v2/inventory/assign-executor-to-item',formData).then((res)=>{
            commit('setInventoryLoading', false);
            return res;
        }) 
    },

    async returnItem({ commit },formData){    
        commit('setInventoryLoading', true);            
        await axios.post('api/v2/inventory/return-item',formData).then((res)=>{
            commit('setInventoryLoading', false);
            return res;
        }) 
    },

    async returnItemOnSite({ commit },formData){    
        commit('setInventoryLoading', true);            
        await axios.post('api/v2/inventory/return-item-on-site',formData).then((res)=>{
            commit('setInventoryLoading', false);
            return res;
        }) 
    },

    async evaluateReturnedItem({ commit },formData){    
        commit('setInventoryLoading', true);            
        await axios.post('api/v2/inventory/release-executor-from-item',formData).then((res)=>{
            commit('setInventoryLoading', false);
            return res;
        }) 
    },

    async markItemAsLost({ commit }, data){
        const endpoint = API_URL + 'api/v2/inventory/mark-item-as-lost';
        const response = await axios.post(endpoint, data).then((res)=>{
            commit('setItemData',res.item)
            return res;
        })  
        return response;
    },

    async editItemAttributes({ commit }, data){
        const endpoint = API_URL + 'api/v2/inventory/edit-item-attributes';
        const response = await axios.post(endpoint, data).then((res)=>{
            commit('setInventoryLoading', false)
            return res;
        })  
        return response;
    },

    async fetchInventoryEmployee({commit}, empId){
        const endpoint = API_URL + 'api/v4/inventory/employee/'+empId;
        await axios.get( endpoint).then((res) =>{
            commit('setInventoryEmployee', res.data.msg);  
        }); 
    },
}

const mutations = {
    setFilteredInventoryPagination: (state, pagination) => (state.pagination = pagination),
    setNewItemsProperties: (state, items) => {
        state.newItemsProperties = items;
    },
    setItemData: (state, item) => { 
        state.itemData = item;
    },
    setFilteredInventoryItems: (state, items) => {
        state.filteredInventoryItems = items;
    },
    setInventoryLoading: (state, items) => {
        state.inventoryLoading = items;
    },
    setInventoryEmployee: (state, list) => (state.inventoryEmployee = list)
};

export default {
    state,
    getters,
    actions,
    mutations
};  