<template>
    <div>
        <notes-modal @toggle="modalConfirmation" v-if="show_notes_modal"/>
        <attendance-modal @toggle="modalConfirmation" v-if="show_attendance_modal"/>
        <activity-modal @toggle="modalConfirmation" v-if="show_activity_modal"/>
        <div class="relative inline-block text-left">
            <button type="button" class="bg-gray-700 text-white text-sm px-3 py-2 rounded-sm flex gap-2 shadow-sm" id="menu-button" aria-expanded="true" aria-haspopup="true" @click="toggleTrainingNotes" :disabled="disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-bookmark-fill my-auto" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                </svg>
                Class Notes
                <svg class="-mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
            </button>
    
            <transition name="menu-fade" mode="out-in">
                <div class="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1" v-if="training_notes">
                <div class="py-1" role="none">
                    <button class="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 active:bg-gray-200" @click="openTrainingNotesModal">
                        Add Note
                    </button>
                    <button class="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 active:bg-gray-200" @click="openStaffAttendanceModal">
                        Staff Attendance
                    </button>
                    <button class="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 active:bg-gray-200" @click="openAddActivityModal">
                        Add Activity
                    </button>
                </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import NotesModal from '@/views/recruitment/pages/trainings/modals/TrainingNotesModal';
import AttendanceModal from '@/views/recruitment/pages/trainings/modals/TrainingStaffAttendanceModal';
import ActivityModal from '@/views/recruitment/pages/trainings/modals/TrainingActivityModal';
export default {
    components: {
        NotesModal,
        AttendanceModal,
        ActivityModal
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            training_notes: false,

            show_notes_modal: false,
            show_attendance_modal: false,
            show_activity_modal: false
        }
    },
    methods: {
        ...mapActions([
            'sendTrainingNote'
        ]),
        toggleTrainingNotes () {
            this.training_notes = !this.training_notes;
        },

        openTrainingNotesModal () {
            this.show_notes_modal = true;
            this.toggleTrainingNotes();
        },

        openStaffAttendanceModal () {
            this.show_attendance_modal = true;
            this.toggleTrainingNotes();
        },

        openAddActivityModal () {
            this.show_activity_modal = true;
            this.toggleTrainingNotes();
        },

        modalConfirmation (data) {
            if (!data.confirmation) {
                this.show_notes_modal = false;
                this.show_attendance_modal = false;
                this.show_activity_modal = false;
                return;
            }
            this.sendTrainingNote(data).then(() => {
                this.show_notes_modal = false;
                this.show_attendance_modal = false;
                this.show_activity_modal = false;
            })
        }
    },
}
</script>
<style scoped>
.menu-fade-enter-active, .menu-fade-leave-active {
  transition: opacity 150ms ease-out, transform 150ms ease-out;
}
.menu-fade-enter, .menu-fade-leave-to /* .menu-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: scale(0.95);
}
</style>