<template>
 <div  class="flex flex-row align-items-center justify-content-start text-gray-300 flex-1">

      <id-filter @toggle="updateFilters"/>
       
       <div class="mx-2 border-r-2 border-gray-200 p-2 px-3">
           <select v-model="form.site" required >
                <option value="" hidden>Select a Site</option>
                <option value="MTY">MONTERREY</option>
                <option value="QRO">QUERETARO</option>
                <option value="AGS">AGUASCALIENTES</option>
                <option value="">All SITES</option>
            </select>
       </div>

       <div class="mx-2 border-r-2 border-gray-200 p-2 px-3">
           <select v-model="form.incidenceStatus" required >
                <option value="" hidden>Select a Status</option>
                <option value="APPROVED">Approved</option>
                <option value="DECLINED">Declined</option>
                <option value="PENDING">Pending</option>
            </select>
       </div>
      

      <incidence-list  class="ml-2" @toggle="updateFilters"/>
    
  </div>
                                           
    
</template>
<script>
import Debounce         from 'debounce';
import IncidenceList    from './incidenceList.vue';
import IdFilter         from './idFilter';

export default {
    data(){
        return{
            form:{
                page: 1,
                idNumber: null,
                incidenceId:  null,
                site:'',
                incidenceStatus:'PENDING'
            }
        }
    },
    methods:{
        clear:function(){
            this.form.idNumber = null;
        },

        updateFilters:Debounce(function(res){
            this.form.page = 1;
            this.form[res.key] = res.value;
        },350),
    },
    watch:{
        form:{
            handler(){
             this.$emit('toggle', this.form);
            },
            deep:true
        }
    },
    components:{
        IdFilter,
        IncidenceList
    },
    mounted(){
        this.$emit('toggle',this.form);
    }

}
</script>