<template>
<div class="w-full">
     <material-loader class="px-3" v-if="isLoading"/>
    <select v-else class="border-b-2 w-full border-gray-300 focus:border-gray-700 px-2" v-model="current" @change="emitStatus" required>
        <option hidden value="null">Select a department...</option>
        <option v-for="(item,index) in getDepartments" :key="index" :value="item.id">
            {{item.label}}
        </option>
    </select>
</div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import MaterialLoader from '@/views/mockups/MaterialLoader'
export default {
    components:{
        MaterialLoader
    },
    data(){
        return{
            department:""
        }
    },
    props:{
        val:[String,Number]
    },
    methods:{
        ...mapActions(['fetchDepartments']),
        emitStatus: function(){
           this.$emit('toggle',this.department);
        }
    },
    computed:{
        ...mapGetters(['getDepartments','getDepartmentStatus']),
        isLoading:function(){
            return this.getDepartmentStatus;
        },
        current:{
            get(){
                return this.department;
            },
            set(value){
                this.department = value; 
            }
        }
    },
    created(){
        this.fetchDepartments().then(()=>{
            this.department = this.val
        }).then(()=>{
            this.$emit('ready',this.department);
        });
    }
}
</script>