import { render, staticRenderFns } from "./AMHourReviewTemplate.vue?vue&type=template&id=1656d2f7&scoped=true&"
import script from "./AMHourReviewTemplate.vue?vue&type=script&lang=js&"
export * from "./AMHourReviewTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1656d2f7",
  null
  
)

export default component.exports