<template>
   
    <div class="pb-3 px-4" >
     <div>
      
       <div class="flex flex-row flex-wrap">
            <!-- #RegisterForStart-->
            <div class="relative flex flex-row items-center justify-start w-full">
                    <span class="text-sm ml-1">Register for: </span>
                <div class="relative px-auto bg-gray-100 ml-2 p-0.5 rounded-full px-2 h-6 flex flex-row items-center justify-center">
                    <button type="button" class="transition-all ease-in-out" :class="getSwitchStyle(chooseMe)" @click="chooseMyself">
                        myself
                    </button>
                    <button type="button"  class="transition-all ease-in-out ml-2" :class="getSwitchStyle(!chooseMe)" @click="chooseRecruiter">
                        another recruiter
                    </button>
                </div>
            </div>
            <!-- #RegisterForStart-->
             <Transition name="fade">
                  <div class="w-full pr-2 text-left mt-2" v-show="!chooseMe">
                
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Recruiter id:</label>
                    <select v-model="form.recruiter_id"  class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 ">
                        <option :value="recruiter.id" v-for="(recruiter,index) in getRecruitersList" :key="index">
                            {{recruiter.name}}
                        </option>
                    </select>
                
                </div>
             </Transition>
            <div class="w-1/2 pr-2 text-left mt-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Name:*</label>
                <input v-model="form.name"  :class="$v.form.name.$invalid && $v.form.$dirty ? errorClass: ''" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                 <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">This field is required.</small>
            </div>
             <div class="w-1/2 text-left pl-2 mt-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Middle name:</label>
                <input v-model="form.middle_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
               
            </div>
            <div class="w-1/2 pr-2 text-left mt-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">First Last Name:*</label>
                <input :class="$v.form.first_last_name.$invalid && $v.form.$dirty ? errorClass: ''" v-model="form.first_last_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                 <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">This field is required.</small>
            </div>
             <div class="w-1/2 pl-2 text-left mt-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Second Last Name:</label>
                <input v-model="form.second_last_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
            </div>
            <div class="sm:w-full md:w-1/2 text-left mt-2 pr-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Site:*</label>
                <select :class="$v.form.site_id.$invalid && $v.form.$dirty ? errorClass: ''" class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 "   v-model="form.site_id">
                    <option hidden value="">Select a location...</option>
                    <option value="MTY">Monterrey</option>}
                    <option value="QRO">Queretaro</option>
                    <option value="AGS">Aguascalientes</option>
                    <option value="LB">Longview</option>
                </select>
                <small class="text-red-400 ml-2 text-xs" v-if="$v.form.site_id.$invalid && $v.form.$dirty">This field is required.</small>
            </div>
            <div class="mt-2 sm:w-full md:w-1/2 text-left pl-2">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Source:*</label>
                <my-source :source="form.source_id"  :error="$v.form.site_id.$invalid && $v.form.$dirty" @change="setSource"/>
                <small class="text-red-400 ml-2 text-xs" v-if="$v.form.source_id.$invalid && $v.form.$dirty">This field is required.</small>
            </div>
            <Transition name="fade">
           <div class="sm:w-full text-left mt-2"  v-show="hasReferral">
                <label class="px-2 text-gray-500 text-xs font-thin m-0">Referral id:*</label>
                
                <multiselect :options="getTeamMembers" v-model="referralObject" label="name" :allow-empty="false"/>
                 <small class="text-red-400 ml-2 text-xs" v-if="$v.form.referral_id.$invalid && $v.form.$dirty">This field is required.</small>
                
            </div>
            </Transition>
       </div>
     </div>
    
      
    </div>
   
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import MySource from '../../components/dropdowns/CandidateSource'
import debounce from 'debounce'
import {required, requiredIf} from 'vuelidate/lib/validators'
export default {
    components:{MySource},
    data(){
        return {
            loadingReferrals:false,
            loadingRecruiters:false,
            form:{
                middle_name:'',
                second_last_name:'',
                name:'',
                first_last_name:'',
                site_id:'',
                source_id:'',
                recruiter_id:'',
                referral_id:'',
                status_id:11
            },
            referralObject:{},
            chooseMe:true
        }
    },  
    methods:{
        ...mapActions(['fetchRecruiters','fetchCandidateStatus','fetchTeamMembers']),
         getSwitchStyle:function(active = true){
            return active ? 'text-green-300  bg-green-100 ring-2 ring-green-200 rounded-sm px-2 text-xs' :'text-gray-400 text-xs cursor-pointer hover:text-gray-600'
        },
        chooseMyself:debounce(function(){
            if(this.chooseMe === true) return ;
            
            this.chooseMe = true;
            this.form.recruiter_id =this.emp_id;
        },600),
        chooseRecruiter:debounce(function(){
            if(this.chooseMe === false) return ;

            this.chooseMe = false;
        },600),
        beforeSubmit:function(){
           this.$v.$touch();  
        },
        setSource:function(value){
            this.form.source_id = value;
            if(value != 2){
                 this.form.referral_id = '';
                 this.referralObject = {};
            }
           
        }
    },
    computed:{
        ...mapGetters(['getRecruitersList','getTeamMembers','getStatusList']),
       
        errorClass:function(){
            return 'bg-red-50 border-red-200';
        },
        hasReferral:function(){
            return this.form.source_id == 2;
        },
        isLoading:function(){
            return this.loadingReferrals || this.loadingRecruiters;
        }
       
    },
    created(){
        this.loadingRecruiters = true;
        this.loadingReferrals  = true;
        this.fetchRecruiters();
        this.fetchCandidateStatus();
        this.form.recruiter_id = this.emp_id;
        this.fetchTeamMembers()
    },
    validations:{
        form:{
            name:{required},
            first_last_name:{required},
            source_id:{required},
            site_id:{required},
            recruiter_id:{required},
            referral_id: {required: requiredIf(function() {
                    return this.form.source_id == 2 ;
            }) 
        }

        }
    },
    watch:{
        form:{
            deep:true,
            handler:function(){
                 this.$emit('update',this.form);
            }
        },
        referralObject:function(newVal){
            this.form.referral_id = newVal?.id;
        }
    }
}
</script>

<style scoped>
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>