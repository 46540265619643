import axios from '@/config/axios'
import auth from '@/config/user'
import { API_URL } from '@/store/actions/config'

const state = {
    score: 0,
    employee_id: '',
    examStatus: 'Pending',
    feedbackStatus: 'Pending'
};

const getters = {
    getScore:   (state) => state.score,
    getExamStatus:  (state) => state.examStatus,
    getFeedbackStatus: (state) => state.feedbackStatus
};

const actions = {
    
    async checkExamStatus({ commit }, exam_type){
        const endpoint = API_URL + 'api/v1/exams/check';
        await axios.post(endpoint,{
            EmpID: auth.currentUser.EmpID,
            exam_name: exam_type
        }).then(res => {
            if(res.data.score == 0 && res.data.updated_at == null){
                commit('setStatus', 'already_started');
            }else{
                commit('setScore', res.data.score);
                commit('setStatus', 'already_answered');
            }
            
        })
        .catch(err => {
            if (err.response) {
                commit('setStatus', 'not_initialized');
            } else if (err.request) {
            // client never received a response, or request never left
            } else {
            // anything else
            }
        });
    },

    async startExam({ commit } , exam_type){
        const endpoint = API_URL + 'api/v1/exams/start';
        const response = await axios.post(endpoint,{
            EmpID: auth.currentUser.EmpID,
            exam_name: exam_type
        });
        if(response.status == 200){
            commit('setStatus', 'Initialized');
            return response.status;
        }
    },

    async storeExamScore({ commit }, ObjectExam){
        commit('setFlagStatus',true);
        commit('setStatus','loading');
        const endpoint = API_URL + 'api/v1/exams';
        const response = await axios.post(endpoint,{
            EmpID: auth.currentUser.EmpID,
            exam_answer: ObjectExam.exam_answers,
            exam_name: ObjectExam.exam_name
        });
        if(response.status == 200){
            commit('setScore', true);
            return response;
        }
    },

    // Actions for the Feedback module
    async storeFeedback({ commit }, ObjectExam){
        commit('setFlagStatus',true);
        commit('setStatus','loading');
        const endpoint = API_URL + 'api/v1/storeFeedback';
        const response = await axios.post(endpoint,{
            EmpID: auth.currentUser.EmpID,
            feedback: ObjectExam.feedback,
            feedback_name: ObjectExam.feedback_name,
            store_type: ObjectExam.store_type
        });
        if(response.status == 200){
            return response;
        }
    },

    async checkFeedbackStatus({ commit }, feedback_name){
        const endpoint = API_URL + 'api/v1/checkFeedback';
        await axios.post(endpoint,{
            EmpID: auth.currentUser.EmpID,
            feedback_name: feedback_name
        }).then(res => {            
            if(res.data.Created_at != null){
                commit('setFeedbackStatus', 'already_answered');
            }else{
                commit('setFeedbackStatus', 'not_answered');
            }
        })
        .catch(err => {
            if (err.response) {
                commit('setFeedbackStatus', 'not_initialized');
            } else if (err.request) {
            // client never received a response, or request never left
            } else {
            // anything else
            }
        });
    },

};
const mutations = {
    setStatus:  (state, newStatus) => (state.examStatus = newStatus),
    setFeedbackStatus:  (state, newStatus) => (state.feedbackStatus = newStatus),
    setScore:   (state, newScore) => (state.score = newScore),
};
export default {
    state,
    getters,
    actions,
    mutations
};