import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    selectsDefaultNull: 0,
    stateList: [],
    stateSelected: 0,
};

const getters = {
    stateList: (state) => state.stateList,
    DefaultNull: (state) => state.selectsDefaultNull,
    stateSelected: (state) => state.stateSelected,
};

const actions = {
    async fetchStates({ commit }){        
        const endpoint = API_URL + 'api/v1/states';
        const response = await axios.get(endpoint);
        commit('setStates',response.data.states);
    },
    selectState ({ commit }, stateSelected ){
        commit ('setStateSelected', stateSelected)
    }
};

const mutations = {
    setStateSelected: (state, stateSelected) => (state.stateSelected = stateSelected),
    setStates: (state, stateList) => (state.stateList = stateList),
};

export default {
    state,
    getters,
    actions,
    mutations
};