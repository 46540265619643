<template>
  <div class="flex flex-col root-container">
    <create-modal
        v-if="(showCreationModal && !relationshipMode) || (showCreationModal && forceCreate)"
        @close="closeModal"
        :node="activeElement" @update="updateDefinitions"/>
    <relationship-modal v-if="showCreationModal && relationshipMode && !forceCreate"
                        @close="closeModal" @update="updateDefinitions"
                        :node="activeElement" @force="forceCreation"/>
    <edit-modal v-if="showEditModal" :node="activeElement" @close="closeEditModal"
                @update="updateDefinitions"/>
    <div class="flex h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-sm">
      <div class="flex shrink-0 flex-row bg-[#f8fafc] h-[3.75rem] items-center justify-around">
        <div class="flex shrink-0 flex-row justify-evenly w-[23rem]">
          <button @click.prevent="toggleHideInactive" :disabled="loading"
                  :class="{'bg-[#d1d5db] text-[#4b5563]': !hideInactive, 'bg-[#1e40af] text-white': hideInactive}"
                  class="h-8 shrink-0 rounded-xl text-center w-[8rem] hover:ring disabled:bg-gray-300">
            <span v-if="!hideInactive">Hide</span><span v-else>Show</span> Inactive
          </button>
          <button
              :disabled="loading || activePath.length <= 1 || activeElement.type === 'category'"
              @click.prevent="editElement"
              class="flex items-center justify-center bg-[#1e40af] shrink-0 h-8 w-24 text-center text-white rounded-xl
                      hover:ring disabled:bg-gray-300">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 class="size-6">
              <path
                  d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z"/>
              <path
                  d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z"/>
            </svg>
            <span>Edit</span>
          </button>
          <button
              :disabled="disableAddNew"
              @click.prevent="addRow"
              class="flex items-center justify-center bg-[#2563eb] shrink-0 h-8 w-24 text-center text-white rounded-xl
                      hover:ring disabled:bg-gray-300">
            <i class="bi bi-plus"></i>
            <span>Add New</span>
          </button>
        </div>
        <div class="flex h-full w-full items-center justify-start">
          <section
              class="flex h-4/6 w-11/12 items-center overflow-auto rounded-xl border-2 bg-white px-4">
            <div v-for="(segment, index) in activePath" :key="`path-${segment.type}${segment.id}`"
                 class="flex text-nowrap">
              <button class="enabled:hover:underline capitalize enabled:text-[#007bff]"
                      :disabled="segment.id === activeElement.id || loading"
                      @click.prevent="slicePath(segment.id)">{{ segment.label }}
              </button>
              <div v-if="index !== activePath.length - 1" class="mx-2">/</div>
            </div>
          </section>
        </div>
      </div>
      <div class="flex h-full w-full overflow-hidden">
        <div class="flex h-full shrink-0 flex-col overflow-y-scroll pb-8 text-sm w-[23rem]">
          <div class="sticky top-0 bg-white">
            <div v-if="showFilters" @click="toggleFilters"
                 class="fixed inset-0 flex flex-row items-center justify-center z-[998]">
            </div>
            <button :disabled="loading"
                    class="relative flex h-8 w-full shrink-0 select-none flex-row content-center items-center px-3 text-left space-x-2 z-[999] hover:cursor-pointer hover:bg-gray-200"
                    @click="toggleFilters">
              <i class="bi bi-filter"></i>
              <span>
                Filter by <span class="capitalize text-[#007bff]">{{ selectedFilter }}</span>
              </span>
            </button>
            <ul class="absolute top-8 mb-0 w-full rounded-b-lg bg-white drop-shadow-md z-[999]"
                v-show="showFilters">
              <li v-for="([filter, iconClass], index,) in viewFilters"
                  :key="`filters-${index}`" @click="chooseFilter(filter)"
                  class="flex h-8 flex-row items-center rounded-lg px-3 text-left space-x-2 hover:cursor-pointer hover:bg-gray-200">
                <i :class="iconClass"></i><span class="capitalize">{{ filter }}</span>
              </li>
            </ul>
          </div>
          <nav class="flex flex-col">
            <ul class="my-1 flex-col text-left mx-1.5" dir="ltr">
              <tree-node v-for="child in nodeTree"
                         :key="`tree-${selectedFilter}-${child.type}-${child.id}`"
                         :node="child" :main="main" :disabled="loading"/>
            </ul>
          </nav>
        </div>
        <div class="w-full flex-col overflow-auto min-w-96">
          <div>
            <div :hidden="Object.keys(this.activeElement).length === 0"
                 class="sticky top-0 flex h-12 items-center bg-gray-100 pr-2 space-x-4 group">
              <div class="h-full w-1" :class="{'bg-[#ff434e]': !activeElement.isActive}"/>
              <div class="flex h-full grow flex-row items-center text-left space-x-2">
                <span>{{ activeElement.label }}</span>
                <span class="capitalize bg-[#2563eb] rounded-xl px-2 h-6 text-white text-sm
                  content-center text-center">
                  {{ activeElement.type }}
                </span>
              </div>
              <template v-if="activeElement.type !== 'category'">
                <button v-if="activeElement.isActive" :disabled="loading"
                        class="opacity-0 group-hover:opacity-100 w-12 h-full transition-opacity
                      duration-150 ease-in-out"
                        @click.prevent="deactivateElement(activeElement)">
                  <i class="bi bi-trash text-[#ff434e]"></i>
                </button>
                <button v-else class="h-full w-12" :disabled="loading"
                        @click.prevent="reactivateElement(activeElement)">
                  <i class="bi bi-arrow-counterclockwise"/>
                </button>
              </template>
            </div>
            <div>
              <table class="w-full">
                <tbody>
                <template v-if="activeElement.children">
                  <tr v-for="(child, index) in filteredEditableElement"
                      :key="`table-${child.type}${child.id}`"
                      class="h-12 w-full table-auto justify-evenly group">
                    <td class="w-1 border-y-2"
                        :class="childStatusBorder(child)"/>
                    <td class="h-12 border-y-2 text-center">
                      <div class="h-full content-center px-2">{{ index + 1 }}</div>
                    </td>
                    <td class="border-y-2 pr-3 pl-2 text-left">
                      <div class="w-full rounded-lg px-2 focus:ring">{{ child.label }}</div>
                    </td>
                    <td class="border-y-2 text-left">
                      <div
                          class="capitalize bg-[#2563eb] rounded-xl px-2 w-fit h-6 box-border
                        text-white text-xs content-center text-center">
                        {{ child.type }}
                      </div>
                    </td>
                    <td class="h-12 border-y-2 px-1 text-right">
                      <template v-if="child.type !== 'category'">
                        <button v-if="child.isActive" :disabled="loading"
                                class="opacity-0 group-hover:opacity-100 w-12 h-full
                              transition-opacity duration-150 ease-in-out"
                                @click.prevent="deactivateElement(child)">
                          <i class="bi bi-trash text-[#ff434e]"></i>
                        </button>
                        <button v-else class="h-full w-12" :disabled="loading"
                                @click.prevent="reactivateElement(child)">
                          <i class="bi bi-arrow-counterclockwise"/>
                        </button>
                      </template>
                    </td>
                  </tr>
                  <tr class="h-12"></tr>
                </template>
                <tr v-else class="h-12 content-center text-left">
                  <td class="pl-[66px]">Nothing to see here...</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import TreeNode from '../components/Organization/Tree.vue';
import CreateModal from "@/views/components/Organization/CreateModal.vue";
import RelationshipModal from "@/views/components/Organization/RelationshipModal.vue";
import EditModal from "@/views/components/Organization/EditModal.vue";

export default {
    components: { EditModal, RelationshipModal, CreateModal, TreeNode },
    data () {
        return {
            loading: false,
            main: this,
            activePath: [],
            nodeTree: {},
            expanded: [],
            forceCreate: false,
            showCreationModal: false,
            showEditModal: false,
            showFilters: false,
            hideInactive: true,
            selectedFilter: 'departments',
            viewFilters: [
                ['sites', 'bi bi-geo-alt-fill'],
                ['departments', 'bi bi-diagram-3-fill'],
                ['titles', 'bi bi-person-badge-fill'],
                ['campaigns', 'bi bi-person-circle'],
            ],
        };
    },
    beforeMount () {
        this.$emit('set-title', 'Organization Management');
    },
    computed: {
        ...mapGetters([
            'getOrganizationStructure',
            'getCampaignDefinitions',
            'getSites',
            'getTitleDefinitions',
        ]),
        disableAddNew () {
            return (
                this.loading ||
                Object.keys(this.activeElement).length === 0 ||
                this.activeElement?.children === undefined ||
                this.activeElement.children.length > 0 &&
                this.activeElement.children.filter(x => x.type !== 'category').length === 0
            );
        },
        activeElement () {
            if ( this.activePath.length === 0 ) {
                return [];
            }

            let target = this.nodeTree;

            for ( const segment of this.activePath ) {
                try {
                    const nextStep = target?.children ?? target;
                    const idMap = nextStep.map(x => x.id);
                    const index = idMap.indexOf(segment.id);

                    if ( index !== -1 ) {
                        target = nextStep[index];
                    }
                } catch {
                    break;
                }
            }

            return target;
        },
        identifier () {
            return (this.activeElement.type === 'category') ? this.activeElement.id.split('-').reverse()[0] : this.activeElement.type;
        },
        relationshipMode () {
            return ['campaigns', 'titles', 'site'].includes(this.identifier);
        },
        filteredEditableElement () {
            let children = this.activeElement.children;
            if ( this.hideInactive ) {
                children = children.filter(x => x.isActive);
            }
            return children;
        },
        updateStrategies () {
            return {
                'departments': [this.fetchOrganizationStructure, this.getOrganizationStructure],
                'campaigns': [this.fetchCampaignDefinitions, this.getCampaignDefinitions],
                'sites': [this.fetchSiteDefinitions, this.getSites],
                'titles': [this.fetchTitleDefinitions, this.getTitleDefinitions],
            };
        },
    },
    created () {
        this.updateDefinitions();
    },
    methods: {
        ...mapActions([
            'fetchOrganizationStructure',
            'fetchCampaignDefinitions',
            'fetchSiteDefinitions',
            'fetchTitleDefinitions',
            'setDefinitionActiveStatus'
        ]),
        toggleHideInactive () {
            this.hideInactive = !this.hideInactive;
        },
        toggleFilters () {
            this.showFilters = !this.showFilters;
        },
        chooseFilter (filterName) {
            this.toggleFilters();
            this.selectedFilter = filterName;
            this.updateDefinitions();
        },
        updateDefinitions (keep = false) {
            this.loading = true;
            if ( keep === false ) {
                this.activePath = [];
                this.expanded = [];
                this.nodeTree = {};
            }
            this.updateStrategies[this.selectedFilter][0]().then(
                () => {
                    this.nodeTree = this.updateStrategies[this.selectedFilter][1];
                    this.loading = false;
                }
            );
        },
        closeModal () {
            this.showCreationModal = false;
            this.forceCreate = false;
        },
        childStatusBorder (element) {
            return {
                'bg-[#ff434e] border-[#ff434e]': !element.isActive,
            }
        },
        slicePath (id) {
            const index = this.activePath.map(x => x.id).indexOf(id);

            this.activePath = this.activePath.slice(0, index + 1);
        },
        deactivateElement (element) {
            this.$fire({
                title: `Are you sure you want to deactivate ${element.label}?`,
                text: "You can reactivate it in the future.",
                type: "warning",
                cancelButtonColor: "#f3f4f6",
                showCancelButton: true,
                confirmButtonColor: "#3b82f6",
                confirmButtonText: "Yes, deactivate it!",
                reverseButtons: true,
                customClass: {
                    cancelButton: 'custom-cancel-button',
                },
            }).then((result) => {
                if ( result.value !== true ) {
                    return;
                }

                this.loading = true;
                const payload = {
                    id: element.id,
                    type: element.type,
                    isActive: 0,
                };
                this.setDefinitionActiveStatus(payload).then(
                    (response) => {
                        this.loading = false;
                        if ( response.status === 200 ) {
                            element.isActive = 0;
                            this.updateDefinitions(true);
                            this.$fire({
                                title: "Deactivated!",
                                text: `${element.label} has been deactivated.`,
                                type: "success",
                                confirmButtonColor: "#3b82f6",
                            });
                        } else {
                            this.$fire({
                                title: "Something went wrong.",
                                text: `Please try again or contact an administrator.`,
                                type: "error",
                                confirmButtonColor: "#3b82f6",
                            });
                        }
                    }
                );
            });
        },
        reactivateElement (element) {
            this.$fire({
                title: `Are you sure you want to reactivate ${element.label}?`,
                type: "warning",
                cancelButtonColor: "#f3f4f6",
                showCancelButton: true,
                confirmButtonColor: "#3b82f6",
                confirmButtonText: "Yes, reactivate it!",
                reverseButtons: true,
                customClass: {
                    cancelButton: 'custom-cancel-button',
                },
            }).then((result) => {
                    if ( result.value !== true ) {
                        return;
                    }

                    this.loading = true;
                    const payload = {
                        id: element.id,
                        type: element.type,
                        isActive: 1,

                    };
                    this.setDefinitionActiveStatus(payload).then(
                        (response) => {
                            this.loading = false;
                            if ( response.status === 200 ) {
                                element.isActive = 1;
                                this.updateDefinitions(true);
                                this.$fire({
                                    title: "Reactivated!",
                                    text: `${element.label} has been reactivated.`,
                                    type: "success",
                                    confirmButtonColor: "#3b82f6",
                                });
                            } else {
                                this.$fire({
                                    title: "Something went wrong.",
                                    text: `Please try again or contact an administrator.`,
                                    type: "error",
                                    confirmButtonColor: "#3b82f6",
                                });
                            }
                        }
                    )
                }
            )
        },
        closeEditModal () {
            this.showEditModal = false;
        },
        editElement () {
            this.showEditModal = true;
        },
        addRow () {
            this.showCreationModal = true;
        },
        forceCreation () {
            this.forceCreate = true;
        },
    },
};
</script>
<style>
.root-container {
    height: calc(100vh - 155px);
}

.custom-cancel-button {
    color: #2C3E50 !important;
}
</style>