<template>  
    <div class="SendSuggestionContainer">
        <loading id='ModalLoader' :active.sync="getLoadingModal" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <div class="d-block text-center">
            <h3>This cell counts with no data</h3>
    </div>
    <div>
      <b-form ref="content" @submit="submitRequest">
        <div class="mt-3 ml-3 mr-3 text-center">
          <label>
            Agent: {{this.agentInfo.ModalEmpID}}
            <br>Date: {{this.agentInfo.ModalDateLabel}}<br>
            <p class="text-muted mr-5 ml-5">
            <small>Provide information about this day</small>
            </p>
          </label>
        </div>

            <!-- Event -->
            <b-form-group class="mt-2" label="Event Type: " description="*Select why this day is no-data">
                <div class="col-sm-14">
                    <select v-model="NoDataReason.Event" class="form-control" id="setEventType">
                        <option value="null">Choose an option...</option>
                        <option value="Dismissed">Dismissed</option>
                        <option value="DayOff">Day-Off</option>
                        <option value="Vacations">Vacations</option>
                        <option value="Suspension">Suspension</option>
                        <option value="Winter">Winter</option>
                        <option value="PTO">Paid Time Off</option>
                        <option value="LOA">Leave of Absence</option>
                        <option value="Absence">Unjustified Absence</option>
                        <option value="Untracked">Untracked shift</option>
                    </select>
                </div>
            </b-form-group>

            <!-- Reason -->
            <b-form-group v-if="NoDataReason.Event == 'Absence' || NoDataReason.Event == 'PTO' || NoDataReason.Event == 'LOA' || NoDataReason.Event == 'Untracked'"  class="mt-2" label="Reason: " description="*Specify the reason">
                <div v-if="NoDataReason.Event == 'Absence' || NoDataReason.Event == 'PTO'" class="col-sm-14">
                    <b-form-textarea max v-model="NoDataReason.Reason" id="setReason" rows="5" max-rows="5" placeholder="Write here..."></b-form-textarea>
                </div>
                <div v-if="NoDataReason.Event == 'LOA'" class="col-sm-14">
                    <select v-model="NoDataReason.Reason" class="form-control" id="setReason">
                        <option value="null">Choose an option...</option>
                        <option value="Medical">Medical Sick Leave</option>
                        <option value="Paternity">Paternity</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Bereavement">Bereavement</option>
                        <option value="VTO">Voluntary Time Off</option>
                    </select>
                </div>
                <div v-if="NoDataReason.Event == 'Untracked'" class="col-sm-14">
                    <select v-model="NoDataReason.Reason" class="form-control" id="setReason">
                        <option value="null">Choose an option...</option>
                        <option value="Misuse">Agent Misuse</option>
                        <option value="Internet">Internet Issue</option>
                        <option value="IT">IT Issue</option>
                        <option value="Clocker">Clocker Issue</option>
                        <option value="Training">Agent in Training</option>
                    </select>
                </div>
            </b-form-group>

            <!-- Shift details -->
            <b-form-group v-if="NoDataReason.Event == 'Untracked'" class="mt-2" description="*Provide the shift information">
                <div class="form-group">
                    <label>Total Shift Duration:</label>
                    <vue-timepicker v-model="NoDataReason.shift" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                </div>
                <div class="form-group">
                    <label>Total Break Duration:</label>
                    <vue-timepicker v-model="NoDataReason.break" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                </div>
                <div class="form-group">
                    <label>Total Lunch Duration:</label>
                    <vue-timepicker v-model="NoDataReason.lunch" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                </div>
            </b-form-group>

            <b-row class="mt-4 ml-5 mr-5" align-h="center">
                <button type="submit" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full">Save</button>
            </b-row>

            </b-form>
        </div>
    </div>  
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required } from 'vuelidate/lib/validators';
import VueTimepicker from 'vue2-timepicker'
import moment       from 'moment';
export default {
    components: { Loading, VueTimepicker },
    data(){
        return {
        submitted: false,
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        NoDataReason: {
            Event: null,
            Reason: null,
            shift: null,
            break: null,
            lunch: null
        },
    };
  },
        computed: {
        ...mapState(["clockerUsers"]),
        ...mapGetters(['getLoadingModal'])
    },
        validations: {
        Event:        { required },
    },
    props: {
        profileInfo: Object,
        agentInfo: Object
    },
    methods:{
        ...mapActions(["sendNoDataReason"]),
        
        submitRequest() {
        event.preventDefault();
        // Set a reason for every event
        if(this.NoDataReason.Event == 'Dismissed' || this.NoDataReason.Event == 'Suspension' || this.NoDataReason.Event == 'DayOff' || this.NoDataReason.Event == 'Vacations' || this.NoDataReason.Event == 'Winter'){
                    this.NoDataReason.Reason = this.NoDataReason.Event;
                }
        // verify no null values
            if( (this.NoDataReason.Event == null) || 
            (this.NoDataReason.Reason == null) ||
            (this.NoDataReason.Event == 'Untracked' && (this.NoDataReason.shift == null || this.NoDataReason.break == null || this.NoDataReason.lunch == null))
            ) {
                this.$fire({
                type: 'error',
                title: 'Oops! There is some missing information, try again',
                showConfirmButton: true})
            } else {
            let objRequest = {
                EmpID: this.agentInfo.ModalEmpID,
                event_date: this.agentInfo.ModalDate,
                campaign: this.agentInfo.ModalCampaign,
                ReqEvent: this.NoDataReason.Event,
                ReqReason: this.NoDataReason.Reason,
                ReqShift: this.NoDataReason.shift,
                ReqBreak: this.NoDataReason.break,
                ReqLunch: this.NoDataReason.lunch
            };
                    this.sendNoDataReason(objRequest).then((res) =>{
                if (res.status == 200){
                    this.$fire({
                        type: 'success',
                        title: 'The request has been store successfully',
                        showConfirmButton: false,
                        timer: 1500
                        }).then(()=>{ 
                            this.$router.go(0);                 
                        }); 
                } else {
                    this.$fire({
                        type: 'error',
                        title: 'Oops! there are some errors, try again',
                        showConfirmButton: true
                        })
                }
            });
            }
    },
        customFormatter(date) {
                return moment(date).format('HH:mm');
            },
  }
};
</script>
<style lang="scss" scoped>
@import '~vue2-timepicker/dist/VueTimepicker.css';
</style>