<template>
    <div class="bg-gray-200 rounded-lg p-4 m-2 mb-3 h-2/4 space-y-4">
        <form autocomplete="off" class="grid grid-cols-2 gap-3">
            <div class="text-left">
                <label for="password" class="text-xs text-left">Application Name</label>
                <div class="relative p-2 bg-transparent border-b-2 transition-all duration-300"
                :class="{ 
                    'border-blue-400' : !$v.label.$invalid, 
                    'border-red-400' : $v.label.$invalid,
                }">
                    <input type="text" v-model="label" class="bg-transparent w-full" placeholder="App Name..." :disabled="loading">
                </div>
            </div>
            <div class="text-left">
                <label for="password" class="text-xs text-left">Site (optional)</label>
                <div class="relative p-2 bg-transparent border-b-2 transition-all duration-300" 
                :class="{ 
                    'border-blue-400' : !$v.site.$invalid, 
                    'border-red-400' : $v.site.$invalid,
                }">
                <input type="text" v-model="site" class="bg-transparent w-full" placeholder="https://..." :disabled="loading">
                </div>
            </div>
            <div class="text-left">
                <label for="password" class="text-xs text-left">Username</label>
                <div class="relative p-2 bg-transparent border-b-2 transition-all duration-300"
                :class="{ 
                    'border-blue-400' : !$v.username.$invalid, 
                    'border-red-400' : $v.username.$invalid,
                }">
                    <input type="text" v-model="username" autocomplete="new-username" class="bg-transparent w-full" placeholder="User.." :disabled="loading">
                </div>
            </div>
            <div class="text-left">
                <label for="password" class="text-xs text-left">Password</label>
                <div class="relative p-2 bg-transparent border-b-2 transition-all duration-300"
                :class="{ 
                    'border-blue-400' : !$v.password.$invalid, 
                    'border-red-400' : $v.password.$invalid,
                }">
                    <input v-model="password" autocomplete="new-password" class="bg-transparent w-full" placeholder="Password..." :disabled="loading" :type="show ? 'text' : 'password'">
                    <div class="absolute inset-y-0 right-0 flex items-center text-sm pr-2">
                        <i class="bi bi-eye-fill  hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'block': !show, 'hidden':show }"></i>
                        <i class="bi bi-eye-slash-fill hover:bg-gray-100/30 px-2 py-1 rounded-full" @click="show = !show" :class="{'hidden': !show, 'block':show }"></i>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-left space-x-2" v-if="!loading">
            <button type="button" class="bg-red-500 text-white rounded-lg shadow-md p-2 px-3 font-semibold uppercase text-sm" @click="sendNewPassword">
                Send <i class="bi bi-send-fill"></i>
            </button>
        </div>
        <div class="mb-4" v-else>
            <div class="animate-spin">
                <i class="bi bi-boxes text-3xl"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { required, url} from 'vuelidate/lib/validators';
export default {
    data () {
        return {
            show: false,
            loading: false,
            label: '',
            site: '',
            username: '',
            password: '',
        }
    },
    validations () {
        return {
            label: {
                required
            },
            site: {
                url,
            },
            username: {
                required
            },
            password: {
                required
            },
        }
    },
    methods: {
        ...mapActions([
            'storePasswordVault'
        ]),
        sendNewPassword(){
            if(this.$v.$invalid) return;
            this.loading = true;
            let obj = {
                label: this.label,
                site: this.site,
                username: this.username,
                password: this.password
            };
            this.storePasswordVault(obj).then(() => {
                this.loading = false;
                this.label = null;
                this.site = null;
                this.username = null;
                this.password = null;
                this.$emit('toggle', true);
            })
        }
    },
}
</script>