<template>
    <div class="h-full flex">
        <div class="bg-white w-1/3 m-auto p-3 rounded-lg shadow-md">
            <form v-if="showCard" class="font-semibold flex flex-col" @submit="submitButton">
                <div class="my-2">

                    <div class="flex flex-col bg-gray-100 p-2 rounded-lg">
                        <span class="text-xl font-bold flex text-center justify-content-center">
                            Item Information 
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
                        </span>
                        <div class="flex text-center justify-content-center p-2 m-auto">
                            <div class="flex flex-col">
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                    Benefactor: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.benefactor}">{{this.benefactor || 'Not found'}}</span>
                                </span>
                                <span class="flex text-center justify-content-center mr-auto">
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    Custodian: <span class="border-b-2 border-color-gray-700 ml-2" :class="{'italic' : !this.custodian}">{{this.custodian || 'Not found'}}</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-center py-6 space-y-3">
                        <span>Evaluate the status of the item</span>
                        <div class="flex space-x-0">
                            <div class="hover:bg-red-700 hover:text-white text-gray-500 rounded-full p-1"
                            :class="{ 'bg-red-700 text-white' : this.rating === 1 }"
                            @click="rateTo(1)">
                                <svg focusable="false" data-prefix="far" data-icon="angry" class="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm0-144c-33.6 0-65.2 14.8-86.8 40.6-8.5 10.2-7.1 25.3 3.1 33.8s25.3 7.2 33.8-3c24.8-29.7 75-29.7 99.8 0 8.1 9.7 23.2 11.9 33.8 3 10.2-8.5 11.5-23.6 3.1-33.8-21.6-25.8-53.2-40.6-86.8-40.6zm-48-72c10.3 0 19.9-6.7 23-17.1 3.8-12.7-3.4-26.1-16.1-29.9l-80-24c-12.8-3.9-26.1 3.4-29.9 16.1-3.8 12.7 3.4 26.1 16.1 29.9l28.2 8.5c-3.1 4.9-5.3 10.4-5.3 16.6 0 17.7 14.3 32 32 32s32-14.4 32-32.1zm199-54.9c-3.8-12.7-17.1-19.9-29.9-16.1l-80 24c-12.7 3.8-19.9 17.2-16.1 29.9 3.1 10.4 12.7 17.1 23 17.1 0 17.7 14.3 32 32 32s32-14.3 32-32c0-6.2-2.2-11.7-5.3-16.6l28.2-8.5c12.7-3.7 19.9-17.1 16.1-29.8z"></path>
                                </svg>
                            </div>
                            <div class="hover:bg-yellow-700 hover:text-white text-gray-500 rounded-full p-1"
                            :class="{ 'bg-yellow-700 text-white' : this.rating === 2 }"
                            @click="rateTo(2)">
                                <svg focusable="false" data-prefix="far" data-icon="frown" class="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z"></path>
                                </svg>
                            </div>
                            <div class="hover:bg-yellow-500 hover:text-white text-gray-500 rounded-full p-1"
                            :class="{ 'bg-yellow-500 text-white' : this.rating === 3 }"
                            @click="rateTo(3)">
                                <svg focusable="false" data-prefix="far" data-icon="meh" class="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm8 144H160c-13.2 0-24 10.8-24 24s10.8 24 24 24h176c13.2 0 24-10.8 24-24s-10.8-24-24-24z"></path>
                                </svg>
                            </div>
                            <div class="hover:bg-blue-500 hover:text-white text-gray-500 rounded-full p-1"
                            :class="{ 'bg-blue-500 text-white' : this.rating === 4 }"
                            @click="rateTo(4)">
                                <svg focusable="false" data-prefix="far" data-icon="smile" class="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"></path>
                                </svg>
                            </div>
                            <div class="hover:bg-green-500 hover:text-white text-gray-500 rounded-full p-1"
                            :class="{ 'bg-green-500 text-white' : this.rating === 5 }"
                            @click="rateTo(5)">
                                <svg focusable="false" data-prefix="far" data-icon="grin-stars" class="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm105.6-151.4c-25.9 8.3-64.4 13.1-105.6 13.1s-79.6-4.8-105.6-13.1c-9.8-3.1-19.4 5.3-17.7 15.3 7.9 47.2 71.3 80 123.3 80s115.3-32.9 123.3-80c1.6-9.8-7.7-18.4-17.7-15.3zm-227.9-57.5c-1 6.2 5.4 11 11 7.9l31.3-16.3 31.3 16.3c5.6 3.1 12-1.7 11-7.9l-6-34.9 25.4-24.6c4.5-4.5 1.9-12.2-4.3-13.2l-34.9-5-15.5-31.6c-2.9-5.8-11-5.8-13.9 0l-15.5 31.6-34.9 5c-6.2.9-8.9 8.6-4.3 13.2l25.4 24.6-6.1 34.9zm259.7-72.7l-34.9-5-15.5-31.6c-2.9-5.8-11-5.8-13.9 0l-15.5 31.6-34.9 5c-6.2.9-8.9 8.6-4.3 13.2l25.4 24.6-6 34.9c-1 6.2 5.4 11 11 7.9l31.3-16.3 31.3 16.3c5.6 3.1 12-1.7 11-7.9l-6-34.9 25.4-24.6c4.5-4.6 1.8-12.2-4.4-13.2z"></path>
                                </svg>
                            </div>
                        </div>
                        <div v-if="showComment" class="m-auto w-64">
                            <textarea id="text-area-box" v-model="comment" name="Comment" class="w-full border-b-2 border-blue-500 resize-none text-sm flex flex-row bg-white shadow-md rounded-sm p-3" rows="2" maxlength="100" placeholder="Write here..."></textarea>
                            <span class="text-xs text-gray-600 text-left font-light">
                                <span class="text-red-700">*</span>
                                This comment is mandatory
                            </span>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <label class="text-left">Upload a file:</label>
                        <div class="w-full">
                            <drag-and-drop @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
                            <span class="text-xs text-gray-600 mt-1 text-left font-light">
                                <span class="text-red-700">*</span>
                                This file is mandatory. File size limit: 3 MB. 
                            </span>
                        </div>
                    </div>
                </div>      
                
                <div class="flex flex-col my-2">
                    <button type="submit"
                    :disabled="this.proof === null || this.rating === 0 || (this.showComment && commentIsEmpty)"
                    :class="{ 'bg-gray-300 pointer-default' : this.proof === null || this.rating === 0 || (this.showComment && commentIsEmpty), 
                    'bg-gray-700' : !(this.proof === null || this.rating === 0 || (this.showComment && commentIsEmpty)) }"
                    class="px-3 py-2 rounded-sm shadow-faded text-white uppercase text-sm">
                        <span v-if="loading">
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                        </span>
                        <span v-else>
                            Change Status
                        </span>
                    </button>
                </div>

            </form>
            <div v-else class="flex justify-content-center flex-col my-auto">
                We are loading the required information. Please wait
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop';
export default {
    components: {
        DragAndDrop
    },
    data() {
       return{
            showCard: false,
            submitted: false,
            loading: false,
            modalResetKey: 0,
            
            itemId: null,
            benefactor: null,
            custodian: null,
            proof: null,
            
            obj: null,
            rating: 0,
            showComment: false,
            comment: null,
       } 
    },
    validations() {
        if(this.showComment){
            return{
                proof:      { required },
                comment:    { required },
            }
        }else{
            return{
                proof:    { required },
            }
        }
    },
    props:{ 
        itemData: { 
            type:Object,
        }
    },
  methods: {
        ...mapActions(['returnItem', 'getInventoryItemInfo', 'uploadImgInventory']),
        rateTo(value){
            this.comment = null;
            this.showComment = false;
            this.rating = this.rating === value ? 0 : value;
            if (this.rating === 1 || this.rating === 5) this.showComment = true;
        },
        resetFileModal(){
            this.modalResetKey ++;
            this.proof = null;
        },
        setFileName(file){
            this.proof = file;
        },
        submitButton: function(){
        event.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.obj = {
            id:         this.itemId,
            rating:     this.rating,    
            comment:    this.comment
        };
        this.$confirm("You're about to return this item", "Do you want to continue?", "question").then((result) => {
          if (result){
            this.loading = true;
            this.submitted = true;
            this.uploadImage().then(() => {
                this.returnItem(this.obj).then(()=>{
                    this.$fire({
                        type:'success',
                        title:'Item returned successfully.',
                        showConfirmButton:false,
                        timer: 1500
                    });
                    this.$router.go(-1);
                })
                .catch((error)=>{
                    let message =  error.response.data.message ?? 'Something went wrong';
                    let indexEnd = message.indexOf('(SQL:');
                    let substringed = indexEnd >= 0 ? message.substring(0,indexEnd) : message;
                    this.$fire({
                        type: 'warning',
                        title: 'Something went wrong',
                        text:`${substringed}`,
                        showConfirmButton: true,                
                    });
                    this.loading = false;
                })
            })
          }
        })
        },
        uploadImage: async function(){
            if(this.proof instanceof File){
                try{
                    let form = new FormData()
                    form.append("image", this.proof);
                    const response = await this.uploadImgInventory(form);
                    this.obj.proof_id = response.data.id;
                }catch(error){
                    return this.$fire({
                        type: "error",
                        title: "Something went wrong with the file provided",
                        text: "Please try again with a different file type or report this issue to an administrator",
                        showConfirmButton: true,
                    });
                }
            }
        }
    },
    created(){
        this.itemId = this.$route.params.id;
        this.getInventoryItemInfo(this.itemId).then(() => {
            this.custodian = this.getItemData.Custodian;
            this.benefactor = this.getItemData.Benefactor;
            this.showCard = true;
        })
    },
    computed:{
        ...mapGetters([
            'employeeList',
            'getItemData'
        ]),
        commentIsEmpty:function(){ 
            return this.comment === null || this.comment?.trim() === '' || this.comment === undefined || this.comment?.length < 2;
        }
    },
}
</script>