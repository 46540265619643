<template>
<div class="h-full w-full">
    <div v-if="isImage" class="w-full my-auto">
        <img :src="proofLink" alt="image not found">
        <a :href="proofLink" class="w-32 mt-2 mx-auto transform text-left text-white text-xs flex flex-row bg-gray-700 px-3 py-2 rounded-sm" target="_blank">
            Open in tab
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
        </a>
    </div>
    <div v-if="isReason" class="w-full my-auto">
        <span class="font-semibold">Reason for not proof:</span>
        <textarea disabled  class="bg-gray-100 resize-none rounded-md p-2 text-xs h-full w-full" v-model="proof" rows="3"></textarea>
    </div>
</div>
</template>
<script>
  export default {
    data() {
      return {
        filesTypes: ['png', 'jpg', 'jpeg', 'pdf']
      }
    },
    props: {
        proof:{
            required: true,
            type: String
        }
    },
      created(){
    },
    computed:{
      proofLink:function(){
          if(this.proof.startsWith("http")) return this.proof;
          return 'https://ems.centrisinfo.com:8081/api/v2/files/' + this.proof;
      },
      isImage:function(){
        const fileType = this.proof.substr(this.proof.length - 3);
        return this.filesTypes.includes(fileType);
      },
      isReason:function(){
        // const filesTypes = ['png', 'jpg', 'jpeg', 'pdf'];
        const fileType = this.proof.substr(this.proof.length - 3);
        return !this.filesTypes.includes(fileType);
      },
    },
    methods:{
        
    },
  }

</script>
<style lang="scss" scoped>

</style>