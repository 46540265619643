<template>
    <div>
        <coming-soon/>
    </div>
</template>
<script>
import ComingSoon from '@/views/Utilities/ComingSoon'
export default {
    components: {
        ComingSoon
    }
}
</script>