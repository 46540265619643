<template>
    <div class="p-8">
        <div class="flex flex-col justify-center items-center">
            <h3 class="font-bold">Deleting {{ event.event }}</h3>
            <small class="text-red-600">This action cannot be undone.</small>
            <small class="text-red-600">{{ action_text }}</small>
        </div>
        <div v-if="!loading">
            <div class="space-y-1">
                <div class="flex flex-col">
                    <small class="text-gray-500 mt-4">Please provide a reason why this {{ event.event }} is being deleted.</small>
                </div>
                <textarea
                    v-model="comment"
                    class="w-full p-3 resize-none rounded-lg border-[1px]"
                    placeholder="Write a comment"
                    maxlength="255"
                    rows="5"
                ></textarea>
                <small
                    class="text-gray-500 text-xs ml-2"
                    :class="{ 'text-red-500': commentIsInvalid }"
                >
                    {{ commentLength }} / 255.
                </small>
            </div>
            <div class="text-center flex gap-3 justify-end">
                <button
                    type="button"
                    class="bg-gray-600 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    :disabled="commentIsInvalid"
                    type="button"
                    class="bg-red-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    @click="deleteHandler"
                >
                    Save
                </button>
            </div>
        </div>
        <div v-else class="text-center">
            <loader />
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import Loader from "@/views/components/Loader";
export default {
    components: {
        Loader,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            comment: null,
            action_text: this.event.event === 'shift' 
            ? "Deleting this shift will also delete all its events"
            : "",
            loading: false,
        };
    },
    computed: {
        commentIsInvalid() {
            return (
                this.comment === null ||
                this.comment.trim() === "" ||
                this.comment === undefined ||
                this.comment === "" ||
                this.comment?.length < 5
            );
        },

        commentLength() {
            return this.comment?.length ?? 0;
        },
    },
    methods: {
        ...mapActions(['deleteClockerHours']),
        deleteHandler() {
            this.loading = true;
            this.deleteClockerHours({
                id: this.event.id,
                comment: this.comment,
            }).then(() => {
                this.$fire({
                    type:'success',
                    title: 'Success',
                    text: 'Shift deleted successfully!',
                });
                this.$emit('close');
            }).finally(() => this.loading = false);
        },
    },
};
</script>