<template>
    <div v-if="getMySuggestions.length > 0">
        <ul class="list-group">
            <!--li class="bg-white shadow-sm py-2 rounded-sm mb-2">
              <div class="py-2 flex flex-row">
                <span class="border-l-4 text-sm border-red-500 mr-3 h-6">

                </span>
                <div class="ring-2 ring-red-500 rounded-full w-8 h-8">
                  <img src="http://placekitten.com/g/200/300"
                       class="h-8 w-8 rounded-full">
                </div>
                <div>
                  <h4 class="text-xs ml-2 text-gray-800 text-semibold text-left leading-3">CENTRIS-ADMIN</h4>
                  <h4 class="text-xs text-gray-400 text-medium text-left pl-3 leading-3">J.Davila12866</h4>
                </div>
              </div>
              <div class="text-xs text-gray-500 text-left ml-4 flex flex-row font-medium pl-2 mt-1">
                <svg class="w-4 h-4 text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                <span class="mr-2 ml-1 text-gray-400 text-semibold">12-04-2022</span>
                <svg class="w-4 h-4 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                <span class="text-gray-400 text-semibold">54</span>
              </div>
            </li>
          <li class="bg-white shadow-sm py-2 rounded-sm mb-2">
            <div class="py-2 flex flex-row">
                <span class="border-l-4 text-sm border-green-500 mr-3 h-6">

                </span>
              <div class="ring-2 ring-green-500 rounded-full w-8 h-8">
                <img src="http://placekitten.com/g/200/300"
                     class="h-8 w-8  rounded-full">
              </div>
              <div>
                <h4 class="text-xs ml-2 text-gray-800 text-semibold text-left leading-3">J.Davila12866</h4>
                <h4 class="text-xs text-gray-500 text-thin text-left pl-3 leading-3">Centris-admin</h4>
              </div>
            </div>
            <div class="text-xs text-gray-500 text-left ml-4 flex flex-row font-medium pl-2 mt-1 leading-3 text-semibold">
              <svg class="w-4 h-4 text-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
              <span class="mr-2 ml-1">12-04-2022</span>
              <svg class="w-4 h-4 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
              <span class="ml-1">54</span>
            </div>
          </li-->
            <li class="d-flex justify-content-between align-items-center mb-2" v-for="suggestion in getMySuggestions" v-bind:key="suggestion.id" >
                <button type="button" :class="{ 'bg-gray-200': suggestion.updated_at == null, 'bg-blue-600 hover:bg-blue-700 text-white': suggestion.updated_at != null}"   @click="selectSuggestion(`${suggestion.id}`)" class="align-items-center px-6 py-2 rounded">
                    <span class="float-left">Fecha: {{suggestion.created_at | moment("YYYY/MM/DD")}}</span>
                    <span :class="{ hide: suggestion.updated_at == null}" class="float-right badge badge-primary badge-pill">Resuelto</span>
                    <span :class="{ hide: suggestion.updated_at != null || suggestion.viewed_at == null}" class="float-right badge badge-secondary badge-pill"> Abierto </span>
                </button>
            </li>
        </ul> 
    </div>
    <div v-else class="text-center">
        <div class="alert alert-danger" role="alert">
            <p class="text-center">No tienes comentarios enviados.</p>
        </div>
    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    export default {
        created(){
            this.fetchSuggAuditsByAgent();
        },
        computed:{
            ...mapState(['feedbacks']),
            ...mapGetters(['getMySuggestions']) 
        },
        methods: {
            ...mapActions(['fetchSuggAuditsByAgent']),

            selectSuggestion: function(data){
                this.$emit('suggestion_selected', data);
            },
        }
    }
</script>
<style scoped>
.btn .badge {
    top: 3px !important;
}
.primary{
    border: 2px solid #007bff !important;
}
.secondary{
 border-color: #6c757d;
}
</style>