<template>
    <div class="">
        <p class="text-center text-gray-500">
            Fill the training details and press the "save" button in order to create a new training.
        </p>
        <div class="bg-white shadow-sm rounded-md p-10">
            <div class="flex space-x-6">
                <div class="flex-1 max-w-[500px]">
                    <h4 class="font-semibold text-gray-700 pl-2 text-left mb-4">Training Details</h4>
                    <div class="flex space-x-5 mb-3">
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Trainer            
                            </label>        
                            <trainers-list @toggle="setTrainer" :val="form.trainer_empID"/>        
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.trainer_empID.$dirty" :valid="!$v.form.trainer_empID.$invalid"/>
                            </div>             
                        </div>                 
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Agents #            
                            </label>        
                            <input class="w-full border-b-2 px-3 block focus:border-gray-700" min="1" type="number" v-model="form.trainees_number" @keyup="$v.form.trainees_number.$touch()">                                           
                                <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.trainees_number.$dirty" :valid="!$v.form.trainees_number.$invalid">
                                    Min: 1
                                </pill>
                            </div>   
                        </div>
                    </div>
                    <div class="flex space-x-5 mb-3">
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Deparment            
                            </label>        
                            <departments-list @toggle="setDepartment" :val="form.department_id"/>    
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.department_id.$dirty" :valid="!$v.form.department_id.$invalid"/>
                            </div>  
                                        
                        </div>     
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Campaign            
                            </label>        
                            <campaign-list :department="form.department_id" :val="form.campaign_id" @toggle="setCampaign"/>
                                <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.campaign_id.$dirty" :valid="!$v.form.campaign_id.$invalid"/>
                            </div>  
                                            
                        </div>
                    </div>
                    <div class="flex space-x-5 mb-3">
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                L.O.B            
                            </label>        
                            <lob-list :campaign="form.campaign_id" :val="form.lob_id" @toggle="setLob"/>        
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.lob_id.$dirty" :valid="!$v.form.lob_id.$invalid"/>
                            </div>       
                        </div>   
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Location         
                            </label>        
                            <locations @toggle="setLocation" :val="form.location"/>       
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.form.location.$dirty"  :valid="!$v.form.location.$invalid"/>
                            </div>
                                        
                        </div>
                    </div>
                    <div class="flex space-x-5">
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                Start Date         
                            </label>        
                            <input class="w-full border-b-2 px-3 block focus:border-gray-700" :min="baseDate" type="date" v-model="form.start_date" @keyup="$v.form.title.$touch()">        
                            <div class="relative w-full" v-if="$v.form.start_date.$dirty" >
                                <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.start_date.$invalid">This field is mandatory</p>
                                <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                            </div>             
                        </div> 
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">
                                End Date         
                            </label>        
                            <input class="w-full border-b-2 px-3 block focus:border-gray-700"  type="date" :min="form.start_date" v-model="form.end_date" @keyup="$v.form.title.$touch()">        
                            <div class="relative w-full" v-if="$v.form.start_date.$dirty" >
                                <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.end_date.$invalid">This field is mandatory</p>
                                <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                            </div>             
                        </div>
                    </div>
                </div>
                <div class="flex-1 flex flex-col border-l pl-6">
                    <h4 class="font-semibold text-gray-700 pl-2 text-left mb-4">Schedule Details</h4>
                    <div class="flex space-x-2">
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">Day of the week</label>        
                            <select v-model="dowToAdd"  class="custom-select mr-sm-2" id="dayOfWeek">
                                <option hidden value="null">Select a DOW</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>         
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400 text-left text-sm ml-2 block">Event</label>        
                            <select v-model="eventToAdd"  class="custom-select mr-sm-2" id="newScheduleRow">
                                <option hidden value="null">Select a type event</option>
                                <option value="Shift">Shift</option>
                                <option value="Break">Break</option>
                                <option value="Lunch">Lunch</option>
                            </select>
                        </div>
                        <div class="flex items-end">
                            <button class="mr-auto bg-gray-700 rounded-sm shadow-sm p-2 px-3 text-white" @click.prevent="addScheduleRow">
                                <span>Add Entry</span>
                            </button>
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap mb-2 mt-4">
                        <vue-timepicker class="form-control d-none"></vue-timepicker>     
                        <form id="scheduleNewHire" class="w-full">
                            <table id="scheduleTable" class="divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">DOW</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">is day off?</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event Type</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start time</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End time</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="!scheduleDays>0"  class="bg-white divide-y divide-gray-200">
                                        <th class="text-center" colspan="7">Add days to the schedule</th>
                                    </tbody>
                                    <tbody v-else class="bg-white divide-y divide-gray-200 bodyTable"></tbody>
                            </table>
                        </form>       
                    </div>
                </div>
            </div>
            
            <div class="flex flex-row flex-wrap mb-2 mt-4">
                <button class="ml-auto bg-gray-700 rounded-sm shadow-sm p-2 px-3 text-white" @click.prevent="submit">
                    <span v-if="saving" >Saving
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 animate-bounce inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                    </span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {required,between } from 'vuelidate/lib/validators'
import {mapActions} from 'vuex'
import  DepartmentsList from '@/views/mockups/DepartmentsList'
import CampaignList from '@/views/mockups/CampaingsList'
import LobList from '@/views/mockups/LobList'
import Locations from '@/views/mockups/Locations'
import TrainersList from '@/views/mockups/TrainersList'
import Pill from '@/views/mockups/ValidationPill'
import VueTimepicker from 'vue2-timepicker'
export default {
    components:{
        'departments-list':DepartmentsList,
        CampaignList,
        LobList,
        Locations,
        TrainersList,
        Pill,
        VueTimepicker
    },
    beforeMount(){
        this.$emit('set-title', 'New Training');
        this.baseDate = this.$moment().format('YYYY-MM-DD');
    },
    data(){
        return{
            form:{
                trainer_empID:"",
                trainees_number:0,                
                department_id: null,
                campaign_id: null,
                lob_id:"",
                location:"",        
                start_date:null,
                end_date:null,
                schedule_start:'08:00',
                schedule_end:'12:00',
                scheduleInfo: []
            },
            dowToAdd: null,
            eventToAdd: null,
            baseDate:null,
            scheduleDays: 0,
            saving:false
        }
    },
    validations:{
        form: {
            department_id:{
                required
            },
            campaign_id:{
                required
            },
            lob_id:{
                required
            },
            location:{
                required
            },
            trainer_empID:{
                required
            },
            trainees_number:{
                required,between:between(1,999)
            },  
            start_date:{
                required
            },
            end_date:{
                required
            },
            schedule_start:{
                required
            },
            schedule_end:{
                required
            },  
        }
    },
    methods:{
        ...mapActions(['storeTraining']),
        setDepartment:function(department){
           this.form.department_id = department;
        },
        setCampaign:function(campaign_id){
            this.form.campaign_id = campaign_id;
        },
        setLob:function(lob){
            this.form.lob_id = lob;
        },
        setLocation:function(location){
            this.form.location = location;
        },
        setTrainer:function(trainer){
            this.form.trainer_empID = trainer;
        },
        submit(){
    
            this.$v.form.$touch();

            if(this.timeIsLower) return  this.$fire({
                    type:'warning',
                    title:'The schedule end time must be greater than the schedule start time.',
                    showConfirmButton:true
            });

            if(this.$v.form.$invalid){
                this.$fire({
                    type:'warning',
                    title:'Fill the mandatory fields in order to proceed.',
                    showConfirmButton:true
                });
                return;
            }

            let isThereAnError = null;
            let arraySchedule = new Array();
            var tableRows = document.querySelectorAll("#scheduleTable > tbody > tr");
            if(tableRows.length > 0){
                tableRows.forEach(function(tableTR){
                    let insideElement = tableTR.getElementsByTagName('input');
                    let arrayDay = new Array();
                    let isDayOff = false;
                    insideElement.forEach(function(tableInputs){
                        if(tableInputs.type == 'checkbox'){
                            arrayDay.push(tableInputs.checked);
                            if(tableInputs.checked){ isDayOff = true;}
                        }else{
                            if((tableInputs.getAttribute('name') === 'startTime[]' || tableInputs.getAttribute('name') === 'endTime[]') && !isDayOff){
                                if(tableInputs.value === '' || tableInputs.value === undefined){
                                    isThereAnError = true;
                                    return false;
                                }
                            }
                            arrayDay.push(tableInputs.value);
                        }
                    });
                    arraySchedule.push(arrayDay);
                });
                this.form.scheduleInfo = arraySchedule;
            }
            if(isThereAnError){
                this.$fire({
                    type: "warning",
                    title: "Please, the start time and end time need to have values.",
                    showConfirmButton: true,
                });
                return false;
            }

            this.saving = true;
            this.storeTraining(this.form).then(()=>{
                 this.$fire({
                    type:'success',
                    title:'The training was stored successfully.',
                    showConfirmButton:true
                });
            })
            .catch((error)=>{
                 let message =  error.response.data.message ?? 'Something went wrong';
            
              this.$fire({
                  type: 'warning',
                  title:`Something went wrong.Error message: ${message}`,
                  showConfirmButton: true,                
              });    
            })
            .then(()=>{
                this.saving = false;
               this.$router.push({name:'trainings.index'})
            });
        },
        addScheduleRow(){
            if(!this.dowToAdd === null || !this.dowToAdd){
                this.$fire({
                type: "warning",
                title: "Please Select a day of week before",
                showConfirmButton: true,
                });
                return false;
            }
            if(this.eventToAdd === null || !this.eventToAdd){
                this.$fire({
                type: "warning",
                title: "Please Select an event before",
                showConfirmButton: true,
                });
                return false;
            }
            this.scheduleDays++;
            var tableBody =  document.getElementById("scheduleTable").getElementsByTagName('tbody')[0]; 
            var newRow = '<tr id="newRow"><td class="px-6 py-2 whitespace-nowrap">'+ this.dowToAdd +'<input name="scheduleDay[]"  type="hidden" value="'+ this.dowToAdd +'"></td><td class="px-6 py-2 whitespace-nowrap"><label class="m-0"><input type="checkbox" name="scheduleDayOff[]" value="true"> Is day off?</label></td><td class="px-6 py-2 whitespace-nowrap"><input name="scheduleEvent[]" type="hidden" value="'+ this.eventToAdd +'"><label>'+this.eventToAdd+'</label></td><td class="px-6 py-2 whitespace-nowrap"><input name="startTime[]" type="time"></td><td class="px-6 py-2 whitespace-nowrap"><input name="endTime[]" type="time" ></td><td><a style="cursor:pointer" class="deleteRow" id="deleteRow'+ this.scheduleDays +'" v-on:click="removeRow('+ this.scheduleDays +')" ><i class="bi bi-trash3 text-red-600 text-xl"></i></a></td></tr>';
            tableBody.insertRow(-1).innerHTML = newRow;
            document.querySelectorAll('.deleteRow').forEach( (button) => {
                button.addEventListener('click',this.removeRow);
            });
            this.dowToAdd = null;
            this.eventToAdd = null;
        },

        removeRow(data){
            console.log(data.target.parentNode.parentNode.parentNode);
            var index = data.target.parentNode.parentNode.parentNode.rowIndex;
            document.getElementById("scheduleTable").deleteRow(index);
            this.scheduleDays--;
        }
    },
    computed:{
        timeIsLower:function(){

            return this.form.schedule_end <= this.form.schedule_start;
        }
    }
}
</script>
