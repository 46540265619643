<template>
    <tbody class="bg-white divide-y divide-gray-200 animate-pulse" >
        <tr v-for="index in 5" :key="'test'+index">
            <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
                <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300">
                    
                </div>
                <div class="ml-2">
                <div class="text-sm font-medium text-gray-900">
                    <div class="relative w-full h-2 bg-gray-200 rounded-full"></div>
                </div>
                </div>
            </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div> </td>
             <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
              <td class="px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></td>
        </tr>

        <!-- More people... -->
    </tbody>

</template>

<script>
export default {

}
</script>

<style>

</style>