import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    newHiresList: [],
    loadingFlag: true,
    status: 'empty',
};

const getters = {
    getNewHires: (state) => state.newHiresList,
    getFlag: (state) => state.loadingFlag,
};

const actions = {
    async fetchNewHiresNotifications({ commit }){
        commit('setFlagStatus',true);
        commit('setStatus','loading');
        const endpoint = API_URL + 'api/v1/mail/newEmployees';
        await axios.get(endpoint).then((response) =>{
            commit('setNewHiresList',response.data);
            commit('setFlagStatus',false);
            commit('setStatus','loaded');
        });
    },

    async sendNewHires({commit}, newHires){
        //commit('setFlagStatus',true);
        const endpoint = API_URL + 'api/v1/mail/notifyNewHires';
        const response = await axios.post(endpoint,{
            newHires: newHires
        });
        if(response.status == 200){
            commit('setFlagStatus',false);
            return response.status;
        }    
    },
};
const mutations = {
    setNewHiresList:    (state, hiresList)  => (state.newHiresList = hiresList),
    setFlagStatus:      (state, status)     => (state.loadingFlag = status),
    setStatus:      (state, status)     => (state.status = status),
};
export default {
    state,
    getters,
    actions,
    mutations
};