import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    allEmails:      [],
    keywords:       [],
    emailStatus:    false,
};
const getters = {
    getAllEmails:       (state) => state.allEmails,
    getKeywords:        (state) => state.keywords
};
const actions = {
    async fetchWestmedEmails({commit}){
        const endpoint = API_URL + 'api/v2/campaigns/westmed/fetchEmails';
        await axios.get(endpoint).then((res) =>{
            commit('setAllEmails', res.data); 
        }); 
    },

    async changeEmailStatus({commit}, data){
        const endpoint = API_URL + 'api/v2/campaigns/westmed/changeEmailStatus';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setEmailStatus', true);     
            return res;
        });
        return response;
    },

    async fetchKeyWords({commit}){
        const endpoint = API_URL + 'api/v2/campaigns/westmed/fetchKeyWords';
        await axios.get(endpoint).then((res) =>{
            commit('setKeywords', res.data); 
        }); 
    },

};
const mutations = {
    setAllEmails:       (state, allEmails)      =>  (state.allEmails    = allEmails),
    setKeywords:        (state, keywords)       => (state.keywords      = keywords),
    setEmailStatus:     (state, emailStatus)    =>  (state.emailStatus  = emailStatus)
};
export default {
    state,
    getters,
    actions,
    mutations
};