<template>
  <div class="max-w-6xl mx-auto py-2">
    <div>
        <schedule-summary />
        <!-- <adjustmentsRequest></adjustmentsRequest>  -->
    </div>
  </div>
  </template>
  <script>
  // import adjustmentsRequest from '@/views/schedules/table/editEmployeeSchedule';
  import ScheduleSummary from '@/views/schedules/table/scheduleSummary';
  export default {
    name: '',
    components: { 
      // adjustmentsRequest, 
      ScheduleSummary 
    },
    mounted(){
      let title = 'Schedule Summary';
      this.$emit('set-title',title);
    }
  }
  </script>