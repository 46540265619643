<template>
    <div class="max-h-[450px] w-full overflow-y-scroll pr-2" v-if="!loading && isNotEmpty">
        <button v-for="suggestion in getMySuggestions" :key="suggestion.id" @click="selectSuggestion(`${suggestion.id}`)"
        :class="activeId == suggestion.id ? 'bg-blue-500 border-l-blue-500 text-white sticky top-0':'bg-white border-l-blue-500 hover:bg-gray-200 z-10'"
        class="relative w-full text-left whitespace-nowrap border-l-4 my-1 py-2 px-4 leading-[1.1] rounded-sm shadow-sm">
            <div class="flex flex-col font-medium font-sans">
                ID # {{suggestion.id}} 
                <small class="lining-nums mt-1">{{suggestion.created_at | moment("YYYY/MM/DD")}}</small>
            </div>
            <span :class="{ hide: suggestion.viewed_at !== null}" 
            class="absolute -top-1 -right-1 bg-blue-600 group-hover:bg-blue-700 rounded-full py-1 px-2 uppercase text-xs font-sans font-semibold text-yellow-200 flex flex-row">
                New 
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
            </span>
        </button>
    </div>
    <div v-else-if="!isNotEmpty" class="text-center p-3">
        <i>No hay información todavía...</i>
    </div>
    <div v-else class="w-full">
        <div
        class="w-full text-left whitespace-nowrap 
        border-l-4 border-blue-300 my-2 py-2 px-4 leading-[1.1] 
        bg-gray-50 rounded-md relative space-y-4">
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-16 w-full"></span>
            </div>
        </div>

        <div
        class="w-full text-left whitespace-nowrap 
        border-l-4 border-blue-300 my-2 py-2 px-4 leading-[1.1] 
        bg-gray-50 rounded-md relative space-y-4">
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-16 w-full"></span>
            </div>
        </div>

        <div
        class="w-full text-left whitespace-nowrap 
        border-l-4 border-blue-300 my-2 py-2 px-4 leading-[1.1] 
        bg-gray-50 rounded-md relative space-y-4">
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-16 w-full"></span>
            </div>
        </div>

        <div
        class="w-full text-left whitespace-nowrap 
        border-l-4 border-blue-300 my-2 py-2 px-4 leading-[1.1] 
        bg-gray-50 rounded-md relative space-y-4">
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-7 w-full"></span>
            </div>
            <div class="text-gray-700 animate-pulse">
                <span class="bg-gray-100 py-1 px-16 w-full"></span>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex';
    export default {
        data() {
            return{
                activeId: null,
                loading: true,
            }
        },
        props:{
            filter_data: {
                type: Object
            }
        },
        created(){
            this.fetchSuggAudits(this.filter_data).then(() => {
                this.loading = false;
            })
        },
        computed:{
            ...mapState(['feedbacks']),
            ...mapGetters(['getMySuggestions']),

            isNotEmpty(){
                return Object.keys(this.getMySuggestions)?.length > 0
            }
        },
        methods: {
            ...mapActions(['fetchSuggAudits']),

            selectSuggestion: function(data){
                this.activeId = data;
                this.$emit('suggestion_selected', data);
            },
        },
    }
</script>