<template>
<div class="relative lg:w-full bg-white rounded-sm shadow-sm p-2 pt-0">                            
    <div class="flex flex-row">
        
        <span class="ml-2 text-md">
            Files Tracking
        </span>
        <span class="text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clip-rule="evenodd" />
            </svg>
        </span>
    </div>
    <form class="p-2 pt-3 w-full" @submit.prevent="submit">
        <div class="flex items-start"  v-for="(item,index) in files" :key="index">
            <div class="ml-3 text-sm">
            <label for="offers" class="font-medium text-gray-700">
                {{item.name}}
            </label>                    
            </div>
            <div class="flex items-center align-start h-5 ml-2">
            <input  type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-red-600 rounded"                  
                :value="item.id"
                v-model="deliveries[index].status"         
            >
            </div>
        
        
        </div>       
        <div class="text-left pl-3">
        <small class="text-gray-500 text-sm font-semibold">
            {{completion}} 
        </small>
        </div>
        <div class="w-full py-2 flex flex-row items-center justify-end">
            <button :disabled="saving" type="submit" class="w-full bg-gray-700 py-3 px-3 text-white rounded-sm mt-4 text-white">
                
                <span v-if="!saving">Save</span>
                <span v-else>
                    Saving changes
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                    </svg>
                </span>
            </button>  
        </div>
    
    </form>
  
</div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    props:{
        files:{
            type:Array,
            default:()=> []
        }
    },
    data(){
        return {
            deliveries:this.files,
            saving:false
        }
    },
    computed:{
       completion:function(){
           const self = this;
           let total = self.deliveries.length, completed= self.deliveries.filter(item => item.status).length;
        return `${completed} out of ${total} files completed.`;
       }
    },
    methods:{
        ...mapActions(['fetchDeliveries','updateDeliveries']),
        submit(){
            const date = this.$moment().format('YYYY-MM-DD');
            const self = this;
            this.saving = true;
            let form = {
                files:{},
                id:this.$route.params.id,
            };
            this.deliveries.filter(item => item.status == true)
            .forEach(function(item){
               
                form.files[item.id] ={
                    updated_at:date,
                    updated_by:self.emp_id
                };
            });
            this.updateDeliveries(form).then(()=>{
                this.$fire({
                  type: 'success',
                  title:`The files has been updated.`,
                  showConfirmButton: false,
                  timer: 1500
              });    
            }).then(()=>{
                this.saving = false;
                this.$router.go(0);
            });           
        }       
    },   
}
</script>