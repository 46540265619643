import axios from '@/config/axios'
import auth from '@/config/user'
import { API_URL } from '../actions/config'

const state = {
    feedbackList:       [],     // List of Agent's feedback
    feedback:           [],     // Load only one feedback
    updateStatus:       false,
    isLoadingFeedback:  false,
    suggestionList:     [],     // Lists of Agent's suggestions
    suggestion:         [],     // Load only one suggestion
    isLoadingSuggestion:false,
    table_loading:      true,
    feedbacksStatus:     false,
    employeesList:      [],
    feedbackInfo:         [],
    questionsInfo:      [],
    questionsArray:     [],
    feedbackAnswers:    [],
};
const getters = {
    getMyFeedbacks:             (state) => state.feedbackList, 
    getFeedback:                (state) => state.feedback,
    getFlagLoadingFeedback:     (state) => state.isLoadingFeedback,
    getMySuggestions:           (state) => state.suggestionList,
    getSuggestion:              (state) => state.suggestion,
    getFlagLoadingSuggestion:   (state) => state.isLoadingSuggestion,
    getFeedbackLoadingTable:            (state) => state.table_loading,
    getfeedbacksStatus:         (state) => state.feedbacksStatus,
    getEmployeesList:           (state) => state.employeesList,

    getFeedbackInfo:            (state) => state.feedbackInfo,
    getFQuestionsInfo:          (state) => state.questionsInfo,
    getFQuestionsArray:         (state) => state.questionsArray,
};
const actions = {
    async fetchAuditsByAgent ({commit}){   
        commit('setLoadingFlag',true);     
        const endpoint = API_URL + 'api/v1/employees/' + auth.currentUser.EmpID + '/audits'
        await axios.get(endpoint).then((response) => {
            commit('setLoadingFlag', false); 
            commit('setFeedbackList',response.data);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
        
    },

    // fetch suggestions by agent
    async fetchSuggAuditsByAgent ({commit}){   
        commit('setLoadingSuggFlag',true);     
        const endpoint = API_URL + 'api/v1/employees/' + auth.currentUser.EmpID + '/suggestionaudits'
        await axios.get(endpoint).then((response) => {
            commit('setLoadingSuggFlag', false); 
            commit('setSuggestionList',response.data);
        }, error => {
            console.error("Error on state Suggestions: "+ error)
        });
        
    },
    // end fetch suggestions by agent

    // ENGAGEMENT, Buzon de quejas y sugerencias
    async fetchSuggAudits({ commit },formData){    
        commit('setLoadingSuggFlag',true);  
        const response = await axios.post('api/v1/employees/reviewsuggestion',formData);
        commit('setLoadingSuggFlag', false); 
        commit('setSuggestionList',response.data);
    },
    // end fetch ENGAGEMENT


    async fetchFeedbackByAuditID ({commit}, audit_id){      
        commit('setLoadingSuggFlag',true);  
        const endpoint = API_URL + 'api/v1/feedback/' + audit_id + '/get'
        await axios.get(endpoint).then((response) => {
            commit('setLoadingFlag', false); 
            commit('setFeedback',response.data);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
        
    },

    // fetch suggestions by auditID
    async fetchSuggestionByAuditID ({commit}, audit_id){      
        commit('setLoadingSuggFlag',true);  
        const endpoint = API_URL + 'api/v1/suggestion/' + audit_id + '/get'
        await axios.get(endpoint).then((response) => {
            commit('setLoadingSuggFlag', false); 
            commit('setSuggestion',response.data);
        }, error => {
            console.error("Error on state suggestion: "+ error)
        });
        
    },

    //fetch suggestions for review
    async fetchSuggestionByAuditIDforReview ({commit}, audit_id){      
        commit('setLoadingSuggFlag',true);  
        const endpoint = API_URL + 'api/v1/suggestion/' + audit_id + '/getforreview'
        await axios.get(endpoint).then((response) => {
            commit('setLoadingSuggFlag', false); 
            commit('setSuggestion',response.data);
        }, error => {
            console.error("Error on state suggestion: "+ error)
        });
        
    },

    async createFeedback ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/feedback/create'
        const response = await axios.post(endpoint,{
            audit_form: data.audit_form,
            callOneInfo: data.callOneInfo,
            callTwoInfo: data.callTwoInfo
        });
        commit('setFeedbackList',null);
        return response
    },

    // to create suggestions
    async createSuggestion ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/suggestion/create'
        const response = await axios.post(endpoint,{
            audit_form: data.audit_form,
            suggestionInfo: data.suggestionInfo,
        });
        commit('setSuggestionList',null);
        return response
    },

    async addComments ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/feedback/update'
        const response = await axios.post(endpoint,{
            feedbackData: data
        }).then(() => {
            commit('setLoadingFlag', false); 
            commit('setUpdateStatus',true);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
        
        return response
    },

    // update comments for suggestion
    async addSuggestionComments ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/suggestion/update'
        const response = await axios.post(endpoint,{
            suggestionData: data
        }).then(() => {
            commit('setLoadingSuggFlag', false); 
            commit('setUpdateStatus',true);
        }, error => {
            console.error("Error on state Feedback: "+ error)
        });
        
        return response
    },

    // *******************
    // Feedback Management
    // *******************

    async createFeedbackType ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/feedback/addFeedbackType'
        const response = await axios.post(endpoint, data);
        commit('setFeedbackList',null);
        return response
    },

    async updateFeedbackQuestions ({commit}, data ){        
        const endpoint = API_URL + 'api/v1/feedback/updateFeedbackQuestions'
        const response = await axios.post(endpoint, data);
        commit('setFeedbackList',null);
        return response
    },

    async fetchFeedbacks({ commit }){    
        const endpoint = API_URL + 'api/v1/feedback/feedbackList';
        await axios.get(endpoint).then( (response) =>{
            commit('setFeedbackList',response.data.data);
            commit('setLoadingTable',false);
        });
    },

    async changeFActive({commit}, data){
        const endpoint = API_URL + 'api/v1/feedback/changeActive';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setfeedbacksStatus', true);     
            return res;
        });
        return response;
    },

    async fetchMyFeedbackTypeList({ commit }){    
        const endpoint = API_URL + 'api/v1/feedback/myFeedbackTypeList';
        await axios.get(endpoint).then( (response) =>{
            commit('setFeedbackList',response.data.data);
            commit('setLoadingTable',false);
        });
    },

    async fetchApplyEmployees({commit}, data){
        const endpoint = API_URL + 'api/v1/feedback/employeesForFeedbackList';
        await axios.post( endpoint, data).then((res) =>{
            commit('setEmployeesList', res.data.msg);  
        }); 
    },

    async fetchFeedbackInfo ({ commit }, data ){
        const endpoint = API_URL + 'api/v1/feedback/feedbackInfo';
        const response = await axios.post(endpoint,{
            feedback_id: data,
        });
        commit('setFeedbackInfo', response.data[0]);
    },

    async fetchFQuestionsInfo ({ commit }, data ){
        const endpoint = API_URL + 'api/v1/feedback/feedbackQuestionsInfo';
        const response = await axios.post(endpoint,{
            feedback_id: data,
        })
        commit('setQuestionsInfo', response.data);
    },

    async fetchFQuestionsArray ({ commit }, data ){
        const endpoint = API_URL + 'api/v1/feedback/feedbackQuestionsArray';
        const response = await axios.post(endpoint,{
            feedback_id: data,
        })
        commit('setQuestionsArray', response.data);
        return response.data
    },

    async sendFeedbackAnswers({commit}, data ){        
        const endpoint = API_URL + 'api/v1/feedback/addFeedback'
        const response = await axios.post(endpoint, data);
        commit('setFeedbackAnswers', null);
        return response.data
    },
};

const mutations = {
    setFeedbackList:    (state, feedbackList)           => (state.feedbackList = feedbackList),
    setFeedback:        (state, feedback)               => (state.feedback = feedback),
    setUpdateStatus:    (state, updateStatus)           => (state.updateStatus = updateStatus),
    setLoadingFlag:     (state, isLoading)              => (state.isLoadingFeedback = isLoading),
    setSuggestionList:  (state, suggestionList)         => (state.suggestionList = suggestionList),
    setSuggestion:      (state, suggestion)             => (state.suggestion = suggestion),
    setLoadingSuggFlag: (state, isLoadingSuggestion)    => (state.isLoadingSuggestion = isLoadingSuggestion),
    setLoadingTable:    (state, newFlag)                => (state.table_loading     = newFlag),
    setfeedbacksStatus:  (state, feedbacksStatus)       => (state.feedbacksStatus      = feedbacksStatus),
    setEmployeesList:   (state, employeesList)          => (state.employeesList = employeesList),
    setFeedbackInfo:    (state, feedbackInfo)           => (state.feedbackInfo      = feedbackInfo),
    setQuestionsInfo:   (state, questionsInfo)          => (state.questionsInfo     = questionsInfo),
    setQuestionsArray:  (state, questionsArray)         => (state.questionsArray    = questionsArray),
    setFeedbackAnswers: (state, feedbackAnswers)        => (state.feedbackAnswers    = feedbackAnswers),
};

export default {
    state,
    getters,
    actions,
    mutations
};