<template>
<div class="relative w-full py-10 flex flex-col align-center justify-center">
     <div class="flex flex-row mx-auto" v-if="isLoading" >
        Loading components 
        <svg class="w-4 h-4 animate-spin ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z" clip-rule="evenodd"></path></svg>
    </div>
  
    <div class="flex justify-end w-full mb-10" v-if="canEdit && !isLoading">
        <a class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-1 rounded-full !no-underline cursor-pointer" @click.prevent="$router.push({name:'positions.create'})">
            New Position
        </a>
    </div>
    <div class="space-y-5" v-if="getPositions.length">
        <div class="relative flex text-left bg-white rounded-xl shadow-sm overflow-hidden" v-for="(item,index) in getPositions"  :key="index">
            <div v-if="item.image" :class="'w-1/3 bg-cover bg-center bg-[url('+item.image+')]'" v-b-modal="'imgModal-'+ index"></div>
            <b-modal v-if="item.image" size="lg" ref="displayImage" :id="'imgModal-'+ index" hide-footer centered no-close-on-backdrop>
                <img :src="item.image" class="w-full" v-b-modal="'displayImage'" />
            </b-modal>
            
            <div class="relative flex-1 p-10">
                <div class="absolute flex space-x-5 top-0 right-7">
                    <div class="flex items-center px-4 py-2 shadow-sm rounded-b-xl flex bg-red-600 text-white text-xs font-semibold">
                        {{ item.available }} OPENINGS AVAILABLE
                    </div>
                    <div class="px-4 pt-2 pb-1 space-x-5 shadow-sm rounded-b-xl flex bg-white" v-if="canEdit">
                        <a class="text-red-600 hover:text-red-300 cursor-pointer" @click.prevent="drop(item.id)"><i class="bi bi-trash3"></i></a>
                        <a class="text-blue-600 hover:text-blue-300 cursor-pointer" @click.prevent="$router.push({name:'positions.edit', params:{id:item.id}})"><i class="bi bi-pencil"></i></a>
                    </div>
                </div>
                <div class="flex justify-between items-center">
                    <span class="font-light text-gray-600">{{item.created_at}}</span>            
                </div>
                <div class="mt-2">
                    <h4 class="text-xl text-gray-700 capitalize font-semibold">{{item.title}}</h4>
                    <p class="mt-2 text-gray-600">{{item.description}}</p>
                </div>
                <div class="flex flex-col" v-if="item.qualifications.length > 0">
                    <collapse>
                        <h4 class="text-base text-gray-700 capitalize font-semibold">Qualifications</h4>
                        <ul>
                            <li v-for="(subitem, subindex) in item.qualifications" :key="subindex" class="pl-3">{{subitem.label}}</li>
                        </ul>
                    </collapse>
                </div>
            </div>
        </div>
    </div>
    <!--#PlaceholderCards-->
    <div class="mx-auto text-left max-w-4xl px-10 my-4 py-6" v-if="!getPositions.length && !isLoading">
        <div class="flex justify-between items-center">
            <span class="font-light text-gray-600"></span>            
        </div>
        <div class="mt-2">
            <p class="text-2xl text-gray-700 font-bold hover:text-gray-600 text-center text-purple-500 font-semibold" >
                Looks like someone stole our open positions.
            </p>
            <img src="@/assets/position_not_found.svg" class="w-50 mx-auto">

             <p class="text-1xl text-gray-700 font-bold hover:text-gray-600 text-center text-gray-500 font-semibold" >
                Come back later to see if we get it back.
            </p>
        </div>
       
    </div>
    
</div>
</template> 

<script>
import {mapGetters, mapActions} from'vuex'
import auth from '@/config/user'
import Collapse from '../components/collapse'
export default {
    data(){
        return{
            permissions:auth.currentUser.roles
        }
    },
    computed:{
        ...mapGetters(['getPositions','getPositionStatus']),
        isLoading:function(){
            return this.getPositionStatus ==='loading';
        },
        canEdit:function(){
           return this.permissions.indexOf('SystemAdministrator') !== -1 ||  this.permissions.indexOf('WF') !== -1;
        }
    },
    methods:{
        ...mapActions(['fetchPositions','deletePosition']),
        drop:function(id){
           this.$confirm(
                "Delete position?",
                 "You're about to delete a position.Do you want to continue?",
                  "warning"
                )
            .then(()=>{
                this.deletePosition(id).then(()=>{
                     this.$fire({
                        type: 'success',
                        title: 'The position has been deleted successfully.',
                        showConfirmButton: true
                     });  
                });
            }).catch(()=>{
                return;
            });
        }
    },
    created(){
        this.fetchPositions();
        this.$emit('set-title','Open positions');
    },
    components:{
        Collapse
    }
}
</script>