<template>
  <div class="main">
    <div class="m-2 justify-self-center p-2 rounded-lg w-full ">
      <section class="m-2 p-4">
        <div class="bg-white shadow mx-auto p-4">
          <div class="md:flex items-center m-2">
            <div class="md:w-1/3">
              <label>Site:</label>
            </div>
            <div class="md:2/3 w-full flex">
              <select required class="custom-select" v-model="selectedSite">
                <option :value="null" hidden>Choose a Site</option>
                <option value="AGS">AGS</option>
                <option value="MTY">MTY</option>
                <option value="QRO">QRO</option>
              </select>
            </div>
          </div>
          <div class="md:flex items-center m-2">
            <div class="md:w-1/3">
              <label>Campaign:</label>
            </div>
            <div class="md:2/3 w-full">
              <b-spinner v-if="loaderCampaign"></b-spinner>
              <select v-else id='campaignSelect' class="custom-select d-block w-100" v-model="defaultCampaign">
                <option :value="null" hidden>Select a Campaign...</option>
                <option v-for="(campaign, index) in getAllCampaigns" :value="campaign" :key="index">{{campaign}}</option>
              </select>
            </div>
          </div>
          <div class="md:flex items-center m-2">
            <div class="md:w-1/3">
              <label>From Date:</label>
            </div>
            <div class="md:2/3 w-full">
              <datepicker
                  v-model="fromDateSelected"
                  :format="customFormatter"
                  class="form-control cursor-pointer"
                  placeholder=" dd/mm/aaaa"
              ></datepicker>
            </div>
          </div>
          <div class="md:flex items-center m-2">
            <div class="md:w-1/3">
              <label>To Date:</label>
            </div>
            <div class="md:2/3 w-full">
              <datepicker
                  v-model="toDateSelected"
                  :format="customFormatter"
                  class="form-control cursor-pointer"
                  placeholder=" dd/mm/aaaa"
              ></datepicker>
            </div>
          </div>
          <div class="w-1/3 mt-2">
            <button class="relative ml-3 p-2 px-3 bg-blue-700 text-white rounded-sm shadow-sm" @click="runReport" :disabled="status === 'loading'">Run Report</button>
          </div>
        </div>
      </section>
      <section hidden class="w-full flex flex-col p-4">
        <table class="w-full divide-y divide-gray-300 shadow">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Site</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">EmpID</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">LOB</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Event</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Reg Time</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Gvy Time</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Sunday Time</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Billable</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Payable</th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Comments</th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200" >
            <tr>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">MTY</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">KEMPER</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="text-sm text-gray-900 capitalize text-left">A.ALCALA14793</div>
                </div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">AGENT</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">CLAIMS</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">Break</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">-1.76</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">0</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">0</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">-1.76</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">1.76</div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap">
                <div class="text-sm text-gray-900 capitalize text-left">None</div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapState, mapActions } from 'vuex'
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { API_URL } from '@/store/actions/config';


export default {
  name: '',
  computed:{
    ...mapState(['definitions','reports']),
    ...mapGetters(['getAllCampaigns']),
  },
  components: { Datepicker},
  methods:{
    ...mapActions(['fetchCampaigns','fetchBillingReport']),
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    runReport(){
      this.status = 'loading';
      let reportData = {
        'fromDate': moment(this.fromDateSelected).format("YYYY-MM-DD"),
        'toDate': moment(this.toDateSelected).format("YYYY-MM-DD"),
        'site': this.selectedSite,
        'campaign': this.defaultCampaign
      };
      let endpoint = API_URL + 'api/mrt/reports/billing/' + reportData.fromDate +'/'+reportData.toDate +'/'+reportData.site+'/'+ reportData.campaign;
      window.open(endpoint)
    },
  },
  data() {
    return {
      loaderCampaign: true,
      defaultCampaign: null,
      selectedSite: null,
      status: null,
      fromDateSelected: null,
      toDateSelected: null,
    }
  },
  mounted(){
    let title = 'Billing Report (Beta)';
    this.$emit('set-title',title);
    this.fetchCampaigns().then( ()=>{
      this.loaderCampaign = false;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
