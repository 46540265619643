<template>
    <div>
        <modal :show="show_comments_modal" @close="hideModals()">
            <event-comments :comments="comments_content" />
        </modal>
        <modal :show="show_edit_modal" @close="hideModals()">
            <event-edition :event="edit_content" :shift="shift" @close="hideModals()" />
        </modal>
        <modal :show="show_delete_modal" @close="hideModals()">
            <delete-event :event="delete_content" @close="hideModals()" />
        </modal>
        <div v-for="(event, index) in events" :key="event.id">
            <div class="flex space-x-2 space-y-2">
                <div class="flex flex-col mt-2 text-center min-w-[80px]">
                    <span class="text-xs">{{ event.start_date }}</span>
                    <span class="text-xs">{{ event.start_time }}</span>
                    <span class="text-xs font-semibold">{{
                        isOngoing(event.end_time) ? "" : "to"
                    }}</span>
                    <span class="text-xs">{{
                        isOngoing(event.end_time) ? "" : event.end_date
                    }}</span>
                    <span class="text-xs">{{
                        isOngoing(event.end_time) ? "" : event.end_time
                    }}</span>
                </div>
                <div class="">
                    <span
                        class="h-8 w-8 rounded-full bg-c-secondary flex justify-center items-center"
                    >
                        <i
                            v-if="event.is_wfh"
                            class="bi bi-house-door text-white"
                        ></i>
                        <i v-else class="bi bi-building text-white"></i>
                    </span>
                    <div
                        :class="{ 'opacity-0': isLastIndex(index) }"
                        class="border-l border-gray-200 h-14 w-[1px] mx-auto mt-1"
                    ></div>
                </div>
                <div class="w-full border-l-4 border-green-600/70 bg-gray-50">
                    <div class="flex mt-1 gap-2 ml-2 justify-between">
                        <div class="">
                            <div class="font-semibold">{{ event.event }}</div>
                            <div class="flex gap-2">
                                <span
                                    class="relative flex h-3 w-3 mt-1"
                                    v-if="isOngoing(event.duration)"
                                >
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600/70 opacity-75"
                                    ></span>
                                    <span
                                        class="relative inline-flex rounded-full h-3 w-3 bg-green-500/70"
                                    ></span>
                                </span>
                                {{ secondsToTime(event.duration) }}
                            </div>
                        </div>
                        <div class="flex gap-2 mr-2">
                            <vs-tooltip text="See comments">
                                <button
                                    v-if="
                                        event.comments.length &&
                                        event.comments.length > 0
                                    "
                                    @click="showCommentsModal(event.comments)"
                                    class="h-7 w-7 bg-gray-200 flex justify-center items-center rounded-full group hover:bg-gray-300"
                                >
                                    <i
                                        class="bi bi-chat-square-dots-fill opacity-40 group-hover:opacity-100 mt-1"
                                    ></i>
                                </button>
                            </vs-tooltip>
                            <vs-tooltip
                                :text="'Edit ' + event.event"
                                class="flex"
                            >
                                <button
                                    @click="showEditModal(event)"
                                    class="h-7 w-7 bg-gray-200 flex justify-center items-center rounded-full group hover:bg-gray-300"
                                >
                                    <i
                                        class="bi bi-pen-fill opacity-40 group-hover:opacity-100"
                                    ></i>
                                </button>
                            </vs-tooltip>
                            <vs-tooltip
                                :text="'Delete ' + event.event"
                                class="flex"
                            >
                                <button
                                    @click="showDeleteModal(event)"
                                    class="h-7 w-7 bg-red-300 flex justify-center items-center rounded-full group hover:bg-red-500 text-gray-50"
                                >
                                    <i
                                        class="bi bi-trash3-fill opacity-40 group-hover:opacity-100"
                                    ></i>
                                </button>
                            </vs-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from "@/views/Utilities/Modal";
import EventComments from "@/views/clocker/adjustments/components/EventComments";
import EventEdition from "@/views/clocker/adjustments/components/EventEdition";
import DeleteEvent from "@/views/clocker/adjustments/components/DeleteEvent";
export default {
    components: {
        Modal,
        EventComments,
        EventEdition,
        DeleteEvent,
    },
    props: {
        events: {
            type: Array,
            required: true,
        },
        shift: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            show_comments_modal: false,
            show_edit_modal: false,
            comments_content: [],
            edit_content: [],
            delete_content: [],
            show_delete_modal: false,
        };
    },
    methods: {
        isLastIndex(index) {
            return this.events.length - 1 === index;
        },

        isOngoing(status) {
            return status === null;
        },

        endTime(end_time) {
            return end_time === null ? "on-going" : end_time;
        },

        secondsToTime(seconds) {
            if (seconds === null) return this.endTime(seconds)?.toUpperCase();

            let h = Math.floor(seconds / 3600);
            let m = Math.floor((seconds % 3600) / 60);
            let s = seconds % 60;

            return `${h}h ${m}m ${s}s`;
        },

        showCommentsModal(comments) {
            this.comments_content = comments;
            this.show_comments_modal = true;
        },

        showEditModal(event) {
            this.edit_content = event;
            this.show_edit_modal = true;
        },

        showDeleteModal(event) {
            this.delete_content = event;
            this.show_delete_modal = true;
        },

        hideModals() {
            this.show_comments_modal = false;
            this.show_edit_modal = false;
            this.show_new_modal = false;
            this.comments_content = [];
            this.edit_content = [];
            this.show_delete_modal = false;
            this.delete_content = [];
            this.$emit("refresh");
        },
    },
};
</script>