<template>
    <div class="fixed inset-0 flex justify-center items-center z-50" v-if="question_index>0">
        <div class="absolute inset-0 bg-slate-900/70 z-0" @click="$emit('close-modal')"></div>
        <div class="bg-white rounded-xl drop-shadow-xl max-w-[600px] p-10 z-10">
            <h3 class="mb-10">Question Parent "{{ questions[question_index].text }}"</h3>
            <div v-if="hasConditions && ifSave">
                <p class="text-lg font-bold text-center">Current Conditions</p>
                <p class="text-center">{{ conditions[0] }}{{ conditions[1] ? ' - ':'' }}{{ conditions[1] ? conditions[1]:'' }}</p>
                <div class="mt-10 text-center">
                    <button class="text-xl inline-block bg-red-600 hover:bg-red-800 text-white px-6 py-1 mx-2 rounded-full !no-underline" @click="removeAllCondition">Remove Condition</button>
                    <button class="text-xl inline-block bg-slate-700 hover:bg-slate-800 text-white px-6 py-1 mx-2 rounded-full !no-underline" @click="$emit('close-modal')">Cancel</button>
                </div>
            </div>
            <div v-else>
                <select class="w-full px-3 py-2 border rounded" v-if="hasQuestions" v-model="selectQuestion">
                    <option value="-1">Select...</option>
                    <option v-for="(question) in questionFilter" :key="'question-'+question.index" :value="question.index">{{ question.text }}</option>
                </select>
                <p class="mt-3">Conditions</p>
                <div v-if="selectQuestion>=0">
                    <div v-if="questions[selectQuestion].type=='text'">
                        <div class="flex space-x-2">
                            <ul class="flex items-center space-x-1" v-if="hasConditions">
                                <li v-for="(text,index) in conditions" :key="'text-'+index" class="bg-c-primary text-white px-2 py-1 rounded">{{ text }}<a @click="removeCondition(index)" class="text-white/50 hover:text-white align-middle cursor-pointer ml-1"><i class="bi bi-x"></i></a></li>
                            </ul>
                            <input type="text" class="flex-1 border rounded px-3 py-1" v-model="dataText" placeholder="...">
                        </div>
                        <p class="text-sm text-gray-400 mt-2">* Save your keywords by using comma ','</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='number'">
                        <div class="flex space-x-5">
                            <input type="number" class="w-full border rounded px-3 py-1" v-model="dataNumber[0]" min="0" placeholder="0">
                            <input type="number" class="w-full border rounded px-3 py-1" v-model="dataNumber[1]" min="0" placeholder="0">
                        </div>
                        <p class="text-sm text-gray-400 mt-2">* Select the range of numbers will trigger the question, if it is one number, put the same on both sections.</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='dropdown'">
                        <multiselect class="multiselect-custom bg-white border py-2 rounded" v-model="dataDropdown" :taggable="true" :options="questions[selectQuestion].answers" track-by="text" label="text">
                        </multiselect>
                        <p class="text-sm text-gray-400 mt-2">* Select the range of numbers will trigger the question, if it is one number, put the same on both sections.</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='date'">
                        <div class="flex space-x-5">
                            <input type="date" class="w-full border rounded px-3 py-1" v-model="dataDate[0]" placeholder="...">
                            <input type="date" class="w-full border rounded px-3 py-1" v-model="dataDate[1]" placeholder="...">
                        </div>
                        <p class="text-sm text-gray-400 mt-2">* Select the range of dates will trigger this question, if it just one date, put the same on both sections.</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='time'">
                        <div class="flex space-x-5">
                            <input type="time" class="w-full border rounded px-3 py-1" v-model="dataTime[0]" placeholder="...">
                            <input type="time" class="w-full border rounded px-3 py-1" v-model="dataTime[1]" placeholder="...">
                        </div>
                        <p class="text-sm text-gray-400 mt-2">* Select the range of time will trigger this question, if it just one time, put the same on both sections.</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='duration'">
                        <div class="flex justify-center space-x-5">
                            <vue-timepicker class="flex-1 border rounded px-3 py-1" format="HH:mm:ss" v-model="dataDuration[0]" close-on-complete></vue-timepicker>
                            <vue-timepicker class="flex-1 border rounded px-3 py-1" format="HH:mm:ss" v-model="dataDuration[1]" close-on-complete></vue-timepicker>
                        </div>
                        <p class="text-sm text-gray-400 mt-2">* Select the range of dates will trigger this question, if it just one date, put the same on both sections.</p>
                    </div>
                    <div v-else-if="questions[selectQuestion].type=='multiple'">
                        <multiselect class="multiselect-custom bg-white border py-2 rounded" v-model="dataMultiple" :multiple="true" :taggable="true" :options="this.questions[this.selectQuestion].answers" track-by="text" label="text">
                        </multiselect>
                    </div>
                </div>
                <p v-else-if="selectQuestion==-1" class="text-center italic text-gray-400 m-0">Select question...</p>
                <p v-if="errorMsg" class="mx-6 text-center text-red-600 text-sm italic">{{ errorMsg }}</p>
                <div class="mt-10 text-center">
                    <button class="text-xl inline-block bg-blue-700 hover:bg-blue-800 text-white px-6 py-1 mx-2 rounded-full !no-underline" @click="saveParent()">Save</button>
                    <button class="text-xl inline-block bg-slate-700 hover:bg-slate-800 text-white px-6 py-1 mx-2 rounded-full !no-underline" @click="$emit('close-modal')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueTimepicker from 'vue2-timepicker';
import Debounce from 'debounce';
export default {
    components: { 
        VueTimepicker,
        Multiselect
    },
    props: {
        questions: [],
        question_index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            ifSave: false,
            selectQuestion: -1,
            dataText: '',
            dataNumber: [],
            dataDropdown: '',
            dataDate: [],
            dataTime: [],
            dataDuration: [],
            dataMultiple: [],
            conditions: [],
            errorMsg: ''
        }
    },
    computed: {
        questionFilter() {
            if(this.question_index) {
                return this.questions.filter((q,i) => {
                    q.index = i;
                    return i !== this.question_index && q.type!==''
                });
            } else {
                return [];
            }
        },

        hasConditions () {
            return this.conditions?.length > 0;
        },

        hasQuestions () {
            return true; //this.questions?.length > 0;
        }
    },
    watch: {
        dataText: Debounce(function(value) {
            if(value?.trim()?.replace(/[^a-zA-Z0-9 ]+/gi, '') === '' || value === '' || value === null || !value.includes(',')) return;
            this.conditions.push(value?.trim()?.replace(/[^a-zA-Z0-9 ]+/gi, ''));
            this.dataText = [];
        }, 200),
        dataNumber: Debounce(function(value) {
            if(value?.length < 2) return;
            this.errorMsg = '';
            this.conditions = [];
            let value1 = parseInt(value[0]);
            let value2 = parseInt(value[1]);
            if(value1 <= value2) {
                this.conditions = value;
                this.errorMsg = '';
            } else {
                this.errorMsg = '* First number must be lower or equal to the second one.';
            }
        },200),
        dataDate: Debounce(function(value) {
            this.errorMsg = '';
            this.conditions = [];
            if(value?.length > 0 && value[0] <= value[1]) {
                this.conditions = value;
                this.errorMsg = '';
            } else {
                this.errorMsg = '* First date must be lower or equal to the second one.';
            }
        },200),
        dataTime: Debounce(function(value) {
            this.errorMsg = '';
            this.conditions = [];
            if(value?.length > 0 && value[0] <= value[1]) {
                this.conditions = value;
                this.errorMsg = '';
            } else {
                this.errorMsg = '* First value must be lower or equal to the second one.';
            }
        },200),
        dataDuration: Debounce(function(value) {
            this.errorMsg = '';
            this.conditions = [];
            if(value?.length <= 1) return;
            let first = (value[0].HH || "00") + ":" + (value[0].mm || "00") + ":" + (value[0].ss || "00");
            let second = (value[1].HH || "00") + ":" + (value[1].mm || "00") + ":" + (value[1].ss || "00");
            if(first <= second) {
                this.conditions = value;
                this.errorMsg = '';
            } else {
                this.errorMsg = '* First duration must be lower or equal to the second one.';
            }
        },200),
        dataMultiple: Debounce(function(value) {
            this.errorMsg = '';
            this.conditions = value.map(item => item.text);
        },200),
        dataDropdown: Debounce(function(value) {
            this.conditions = [value?.text];
        }, 200)
    },
    methods: {
        removeAllCondition() {
            this.ifSave = false;
            this.conditions = [];
            var data = {
                parent: this.selectQuestion,
                conditions: this.conditions
            }
            this.$emit('data-parent', data);
        },
        removeCondition(i) {
            this.conditions.splice(i,1);
        },
        saveParent() {
            var data = {
                parent: this.selectQuestion,
                conditions: this.conditions
            }
            this.ifSave = true;
            this.$emit('data-parent', data);
        }
    }
}
</script>