<template>
    <div class="relative lg:w-full flex-1 overflow-auto bg-blue-50">
        <div class="p-2 w-full h-full grid grid-cols-8 grid-flow-col gap-4">
            <div class="m-2 col-start-1 row-start-1 row-span-4 col-span-3 overflow-hidden">
                <div class="w-full text-center bg-gray-100 border-2 shadow-md rounded-lg p-4">
                    <h3>Add new entry</h3>
                    <div>
                        <label class="w-full text-left">Title</label>
                        <div class="flex items-center mt-2 mb-6">
                            <svg class="w-4 h-4" fill="#fff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                            <input type='text' v-model="newsfeedTitle" placeholder="Enter the title of the note" class="w-full ml-2 pl-2 px-2 py-2 border rounded-lg text-gray-700 focus:outline-none"/>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <label class="w-full text-left">
                            <span>Duration</span>
                            <div class="flex items-center pr-2">
                                <div>
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" value="true" v-model="isPermanent">
                                        <span class="ml-2">Permanent Duration</span>
                                    </label>
                                </div>
                            </div>
                        </label>
                        
                        <div class="flex items-center mt-2 mb-6 pr-2 w-1/2">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                            <datepicker :class="{ 'disableElement':disableElement}" :disabled="disableElement" :disabled-dates="disabledDates" v-model="fromDate" :v-model='fromDate' class="ml-1 form-control" :format="customFormatter" placeholder="from date"></datepicker>             
                        </div>
                        <div class="flex items-center mt-2 mb-6 w-1/2">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                            <datepicker :class="{ 'disableElement':disableElement}" :disabled="disableElement" :disabled-dates="disabledDates" v-model="toDate" class="ml-1 form-control" :format="customFormatter" placeholder="to date"></datepicker>             
                        </div>
                    </div>
                    <div>
                        <label class="w-full text-left">Description</label>
                        <textarea v-model="newsfeedDescription" class="w-full h-32 min-h-full max-h-32 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"  maxlength="280"></textarea>
                    </div>
                    <div>
                        <upload-files @changed="handleImages" :isMultiple='isMultiple' :maxFiles="1" maxError="the max size of the files must be less than 2MB"></upload-files>
                    </div>
                    <div>
                        <button type="button" @click="save" class="w-full p-1 flex items-center justify-center border border-transparent rounded-md text-white text-lg bg-indigo-600 hover:bg-indigo-700">Save</button>
                    </div>
                </div>
            </div>
            <div class="col-start-5 col-span-3 row-span-3">
                <div class="m-2 p-2 ">
                    <h3>Preview Newsfeed</h3>
                    <p class="italic ">Start to type into the inputs to see the preview</p>
                </div>
                <hooper class="min-w-full  min-h-full p-4" :settings="hooperSettings">
                    <slide>
                        <newsfeed-slide v-if="this.newsfeedTitle" :entryTitle="this.newsfeedTitle" :imgPreview="this.images" :newsfeedImage="''" :entryDescription="this.newsfeedDescription"></newsfeed-slide>
                    </slide>
                </hooper> 
            </div>
        </div>


    </div>
</template>
<script>
import Datepicker       from "vuejs-datepicker";
import moment           from "moment";
import uploadFiles      from '@/views/Utilities/upload_files/index'
import { Slide, Hooper } from 'hooper';
import newsfeedSlide from '@/views/newsfeed/slide/index'
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    components: { 
        Datepicker, 
        'upload-files' : uploadFiles,
        Slide, 
        'newsfeed-slide': newsfeedSlide, 
        Hooper
    },
    data(){
        return {
            hooperSettings: {
                itemsToShow: 1,
                vertical: true,
                autoPlay: true,
            },
            title : "NewsFeed Manager",
            newEntry: [],
            newsfeedTitle: null,
            fromDate: null,
            toDate: null,
            newsfeedDescription: 'Default text',
            fileImage: null,
            images : null,
            isPermanent: null,
            dpLocation: null,
            maxSize : 6000000,
            convertRate : 1048576,
            btnClicked: false,
            isMultiple: false,
            disabledDates:{
                to: new Date(Date.now() - 8640000),
            },
            disableElement: false,
                        
        }
    },
    watch:{
        isPermanent: function(value){
            if(value){
                    this.disableElement = true;
            }else{
                    this.disableElement = false;
            }
        }
    },
    methods:{
        ...mapActions(['storeNewEntry']),
        customFormatter(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        readAsDataURL(file) {
            return new Promise(function (resolve, reject) {
                let fr = new FileReader();
                fr.onload = function () {
                    resolve(fr.result);
                };
                fr.onerror = function () {
                    reject(fr);
                };
                fr.readAsDataURL(file);
            });
        },

        handleImages(files){
            let readers = [];
            this.fileImage = files[0];
            readers.push(this.readAsDataURL(files[0]));
            Promise.all(readers).then((values) => {
                this.images = values;
            });
        },

        save(){
            if(this.newsfeedTitle == '' || this.newsfeedTitle == null){
                this.$fire({
                    type: "error",
                    title: "Oops! You missing to set a Title",
                    showConfirmButton: true,
                }).then(()=>{
                    return false;
                });  
            }
            if(this.newsfeedDescription == '' || this.newsfeedDescription == null){
                this.$fire({
                    type: "error",
                    title: "Oops! You missing to set a description",
                    showConfirmButton: true,
                }).then(()=>{
                    return false;
                });  
            }
            if(this.images == '' || this.images == null){
                this.$fire({
                    type: "error",
                    title: "Oops! You missing to set an image",
                    showConfirmButton: true,
                }).then(()=>{
                    return false;
                });  
            }
            if(!this.disableElement){
                if(this.fromDate == '' || this.fromDate == null){
                    this.$fire({
                        type: "error",
                        title: "Oops! You missing to set a from date",
                        showConfirmButton: true,
                    }).then(()=>{
                        return false;
                    });                 
                }
                if(this.toDate == '' || this.toDate == null){
                    this.$fire({
                        type: "error",
                        title: "Oops! You missing to set a to date",
                        showConfirmButton: true,
                    }).then(()=>{
                        return false;
                    });                 
                }


            }
            
            let entryFormData = new FormData();
            entryFormData.append('image',       this.fileImage);
            entryFormData.append('title',       this.newsfeedTitle);
            entryFormData.append('description', this.newsfeedDescription);
            entryFormData.append('fromDate', (this.fromDate) ? moment(this.fromDate).format("YYYY-MM-DD"):'');
            entryFormData.append('toDate',   (this.fromDate) ? moment(this.toDate).format("YYYY-MM-DD"):'');
            this.storeNewEntry(entryFormData).then((resp)=>{
                if(resp.data.error == false){
                    this.$fire({
                        type: 'success',
                        title: 'The new has been created successfully',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(()=>{
                        setTimeout(location.reload(), 3000)
                    })
                }
            });

        },
    },
    computed:{
        ...mapState['NewsFeed'],
        ...mapGetters['getAllEntries']
    },
     mounted(){
        this.$emit('set-title',this.title);
    },
}
</script>
<style scoped>
    .disableElement{
        background-color: #d8dadc;
        cursor: not-allowed;
    }
</style>