<template>
        <!-- Detailed day for AM HR and SUP HR -->
        <div class="w-11/12 mb-12 xl:mb-0 px-4 mx-auto my-auto py-3">

            <!-- Close Button -->
            <div class="flex flex-col">
                <div class="absolute right-10">
                    <button @click="closeModal()" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Title -->
            <div class="flex flex-col mb-6">
                <div class="mx-auto">
                    <div class="flex items-center">
                        <h3 class="font-semibold text-3xl text-gray-700">
                            Edit the Day
                        </h3>
                        <vs-tooltip class="mt-auto text-blue-300" position="right" text="This will help you send requests to change this agent's hours">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                        </vs-tooltip>
                    </div>
                </div>
            </div>

            <!-- Reason -->
            <div class="mb-6 flex flex-col">
                <div class="flex flex-row">
                    <span class="italic font-semibold text-gray-700 text-2xl">Step 1:</span> 
                    <span class="ml-2 my-auto text-gray-700 ">Select te reason of your requests</span>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-col mr-4">
                        <label class="text-gray-700 " for="requestReason">Reason for this adjustments:</label>
                        <select @change="validations()" required v-model="requestReason" name="requestReason" id="requestReason" class="p-2 rounded-md shadow-md border-b-2 border-gray-700">
                            <option value="null" hidden>Select a Reason...</option>
                            <option value="misuse">Agent tool misuse</option>
                            <option value="internet">Internet issue</option>
                            <option value="clocker">Issue with clocker</option>
                            <option value="other">Other</option>
                        </select>
                        <span class="text-xs text-gray-600 mt-1">
                            <span class="text-red-700">*</span>
                            Required Information. 
                        </span>
                    </div>
                    <div v-if="requestReason === 'other'" class="flex flex-col">
                        <label for="reason" class="text-gray-700">Specify reason:</label>
                        <textarea placeholder="Write the reason..." maxlength="50" @change="validations()" v-model="otherReason" name="reasonOther" id="" cols="30" rows="2" class="shadow-md rounded-lg resize-none p-2"></textarea>
                        <span class="text-xs text-gray-600 mt-1">
                            <span class="text-red-700">*</span>
                            Required Information. Character limit: 50 char. 
                        </span>
                    </div>
                </div>
            </div>

            <!-- Table -->
            <div class="flex flex-col w-full mb-6">
                <div v-if="this.showTable">
                    <div class="flex flex-row mb-1">
                        <span class="italic font-semibold text-gray-700 text-2xl">Step 2:</span> 
                        <span class="ml-2 my-auto text-gray-700 ">Change the day hours</span>
                        <vs-tooltip class="my-auto" position="right" text="Click on the boxes from column Standard Hours and Graveyard Hours to edit the event's durations">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                        </vs-tooltip>
                    </div>
                    <table class="divide-y divide-gray-200 w-full rounded-b-lg rounded-t-lg shadow-md">
                        <thead class="bg-gray-700">
                            <tr>
                                <th class="py-3 px-2 uppercase text-xs font-semibold text-white text-center whitespace-nowrap w-1/5 rounded-tl-lg" scope="col">
                                    Event
                                </th>
                                <th class="py-3 px-2 whitespace-nowrap" scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Standard Hours
                                        <div>
                                            <vs-tooltip text="Morning shift: from 5:00AM, to 8:00PM">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </vs-tooltip>
                                        </div>
                                    </div>
                                </th>
                                <th class="py-3 px-2 whitespace-nowrap" scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Graveyard Hours
                                        <div>
                                            <vs-tooltip text="Graveyard shift: from 8:00PM, to 5:00AM">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </vs-tooltip>
                                        </div>
                                    </div>
                                </th>
                                <th class="py-3 px-2 whitespace-nowrap" scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Category
                                    </div>
                                </th>
                                <th v-if="!isEmployee" class="py-3 px-2 whitespace-nowrap" scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Billable
                                    </div>
                                </th>
                                <th class="py-3 px-2 whitespace-nowrap" scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Payable
                                    </div>
                                </th>
                                <th class="py-3 px-2 whitespace-nowrap w-2/12 rounded-tr-lg " scope="col">
                                    <div class="flex flex-row uppercase text-xs font-semibold text-white text-center justify-content-center">
                                        Comment
                                        <div>
                                            <vs-tooltip text="Hover the mouse over the message icon to see the comments provided by the employee">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </vs-tooltip>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr class="text-center border-l-2 border-r-2" :id="index" v-for="(currentRow, index) in temporalTable" :class="{ 'table-primary font-weight-bold': currentRow.Event === 'Subtotal' }" :key="index">
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2 text-xs">
                                    <select v-if="currentRow.Event === ''" :id="'eventDropdown'+index" class="p-2 border-b-2 border-blue-300 w-full"
                                    v-model="request[index].event" @change="selectChanged(index, $event)">
                                        <option :value="''" hidden>Choose an event...</option>
                                        <optgroup v-for="(elements, key, index) in filteredEvents" :key="index" :label="key" >
                                            <option v-for="(event,index) in elements" :billable="event.Billable" :payable="event.Payable" :value="event.break_name" :key="index" class="uppercase">
                                                {{event.break_name}}
                                            </option>
                                        </optgroup>
                                    </select>
                                    <span class="uppercase text-xs" v-else>{{currentRow.Event}}</span>
                                </td>
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2">
                                    <vue-timepicker v-model="request[index].standard_time" lazy @change="updateRequest(index, 's')" class="outline-none rounded-lg shadow-md p-2 text-gray-700 text-bold" format="HH:mm" hide-clear-button></vue-timepicker>
                                </td>
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2">
                                    <vue-timepicker v-model="request[index].graveyard_time" lazy @change="updateRequest(index, 'g')" class="outline-none rounded-lg shadow-md p-2 text-gray-700 text-bold" format="HH:mm" hide-clear-button></vue-timepicker>
                                </td>
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2 text-center justify-content-center m-auto flex flex-col">
                                    <span v-if="currentRow.Billable > 0">
                                        <vs-tooltip position="left" title="Billable" text="Company can bill this time" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Billable >= 0,
                                            'text-red-900 bg-red-200' : currentRow.Billable < 0}">
                                                Bill
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                    <span v-else>
                                        <vs-tooltip position="left" title="Not Billable" text="Company can not bill the time spent on this activity" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Billable >= 0,
                                            'text-red-900 bg-red-200' : currentRow.Billable < 0}">
                                                Bill
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>

                                    <span v-if="currentRow.Payable > 0">
                                        <vs-tooltip position="left" title="Payable" text="Employee earns money during doing this activities" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Payable >= 0,
                                            'text-red-900 bg-red-200' : currentRow.Payable < 0}">
                                                Pay
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                    <span v-else>
                                        <vs-tooltip position="left" title="Not Payable" text="Employee does not get paid for this time" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Payable >= 0,
                                            'text-red-900 bg-red-200' : currentRow.Payable <= 0}">
                                                Pay
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                </td>
                                <td v-if="currentRow.Event !== 'Subtotal' && !isEmployee" class="p-2">{{ toTime(currentRow.Billable) }}</td>
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2">{{ toTime(currentRow.Payable) }}</td>
                                <td v-if="currentRow.Event !== 'Subtotal'" class="p-2">
                                    <vs-tooltip v-if="currentRow.Comment === 'correction'" text="Discard event created" class="text-center justify-content-center flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" @click="deleteRow(index)">
                                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                        </svg>
                                    </vs-tooltip>
                                    <vs-tooltip v-else :text="currentRow.Comment" class="text-center justify-content-center flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd" />
                                        </svg>
                                    </vs-tooltip>
                                </td>
                            </tr>
                            <tr v-if="temporalTable.length === 0" class="flex">
                                <td class=" my-2 mx-auto italic">No records found.</td>
                            </tr>
                            <tr class="text-center" colspan="7">
                                <td colspan="9" class="p-2 bg-gray-700 rounded-b-lg">
                                    <button class="flex flex-row mx-auto text-white font-bold relative one hover:transition-all hover:animate-none animate-bounce my-1" @click="addNewEvent()">
                                        <svg class="w-6 h-6 my-auto mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
                                        Add a new Event
                                        <span class="absolute -bottom-2 right-0 left-0 w-0 transition-all h-1 bg-red-500"></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody v-else>
                            
                        </tbody> -->
                    </table>
                </div>
            </div>

            <!-- Subtotal -->
            <div class="w-full text-center mb-6">
                <div class="rounded-lg shadow-md p-4 mb-4 w-full border-t-4 border-b-4 border-gray-700">
                    <div class="flex flex-row justify-content-center mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                            </svg>
                            <span class="my-auto uppercase text-xl font-semibold text-center">
                                Subtotal
                            </span>
                            <div>
                                <vs-tooltip text="Seconds are not editable, so the results may slightly vary">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                    </svg>
                                </vs-tooltip>
                            </div>
                    </div>
                    <div class="flex flex-row">
                        <div v-if="!isEmployee" class="flex flex-row mx-auto">
                            <span class="bg-green-200 bg-opacity-50 rounded-full px-4 py-2 w-min m-auto flex flex-row">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                Billable
                            </span>
                            <span class="p-2 w-min mr-auto text-lg">
                                {{toTime(billable)}}
                            </span>
                        </div>
                        <div class="flex flex-row mx-auto">
                            <span class="bg-green-200 bg-opacity-50 rounded-full px-4 py-2 w-min m-auto flex flex-row">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Payable
                            </span>
                            <span class="p-2 w-min mr-auto text-lg">
                                {{toTime(payable)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Upload 1 image -->
            <div class="w-full">
                <div class="flex flex-row mb-1">
                    <span class="italic font-semibold text-gray-700 text-2xl">Step 3:</span> 
                    <span class="ml-2 my-auto text-gray-700 ">Provide evidence for the requests, or the reason of not having proof</span>
                </div>
                <div class="flex flex-col">
                    <label class="flex items-center mr-auto">
                        <span class="mr-2">Include proof: </span>
                        <ToggleButton :active="requestProof" @toggle="clearProof" @fileSelected="setFileName" :id="1"/>
                    </label>     
                    <div class="w-full">
                        <div v-if="requestProof" class="w-full flex flex-col">
                            <drag-and-drop @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
                            <span class="text-xs text-gray-600 mt-1 text-left">
                                <span class="text-red-700">*</span>
                                File size limit: 3 MB. 
                            </span>
                        </div>
                        <div v-else class="w-full flex flex-col">
                            <label for="fileName" class="ml-1 py-1 text-gray-800">No proof reason:</label>
                            <textarea maxlength="255" class="resize-none w-full mx-auto rounded-lg border-dashed border-2 border-gray-300 p-2" rows="3" required @change="validations()" v-model="noProofReason"></textarea>
                            <span class="text-xs text-gray-600 mt-1">
                                <span class="text-red-700">*</span>
                                Required Information. Character limit: 255 char. 
                            </span>
                        </div>
                    </div> 
                </div>
            </div> 
            
            <!-- Actions -->
            <div class="flex flex-col mt-3">
                <!-- <div class="text-xs flex flex-col">
                    <span><span class="text-red-500 text-sm">*</span> You can hover the mouse over the info icons to read some informatioun about this tool.</span>
                </div> -->
                <div class="ml-auto flex flex-row">
                    <div :hidden="reason.message === ''" class="flex flex-row my-auto">
                        <vs-tooltip position="left" :text="reason.tooltip">
                            <span class="text-red-500 text-sm bg-red-200 rounded-full mr-2 bg-opacity-50 p-2">
                                {{reason.message}}
                            </span>
                        </vs-tooltip>
                    </div>
                    <button :disabled="!canBeSend" @click="sendHoursRequest()"
                    :class="{'cursor-default bg-gray-300' : !canBeSend , 'bg-gray-700' : canBeSend }" 
                    class="transform active:scale-75 transition-transform p-3 px-4 text-white text-sm rounded-full uppercase">
                        <span v-if="isLoading">
                            <svg class="w-6 h-6 animate-spin" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg>
                        </span>
                        <span v-else>
                            Save
                        </span>
                    </button>
                </div>
            </div>
        </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker';
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop';
import ToggleButton from '@/modules/hours-management/roles-management/components/toggle';
import {mapGetters, mapActions }    from 'vuex'
export default {
    components: { 
        VueTimepicker,
        DragAndDrop,
        ToggleButton
    },
    data() {
        return {
            filteredEvents: [],
            existingEvents: [],
            requestReason: null,
            otherReason: null,
            isLoading: false,
            billable: 0,
            payable: 0,
            showTable: false,
            temporalTable: [],
            request: [],
            requestProof: true,
            modalResetKey: 0,
            proof:null,
            noProofReason: null, 

            activitiesDuration: 0,
            shiftDuration: 0,

            standardActivities: 0,
            standardShift: 0,

            graveyardActivites: 0,
            graveyardShift: 0,

            includeImages: false,
            canBeSend: false,
            reason: {
                message: "No changes registered",
                tooltip: "Reset the component and contact system administrator if there's an error"
            }
        }
    },
    props:{
        employee: String,
        eventDate: String,
        approvalId: Number,
        componentPermissions: {
            required: true
        }
    },
    computed: {
        ...mapGetters(['getPayrollDetailedDay', 'getAllEventsPerCampaign']),
        isEmployee(){
            return this.componentPermissions === 'employee';
        }
    },
    methods: {
        ...mapActions(['fetchAllEvents', 'requestHoursReview', 'uploadImgRequestProof',]),
        toTime: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = new Date(Math.abs(t) * 1000).toISOString().substr(11, 5)
            return sign+time;
        },
        timeToSeconds: function(t){
            let parts = t.split(':');
            let seconds = (parts[0] * 60 * 60) + (parts[1] * 60)
            if (seconds === null || seconds === 'null') seconds = 0;
            return seconds;
        },
        deleteRow: function(index){
            this.$confirm("Do you want to delete this event?", "Discard Event Creation", "question").then((result) => {
                if (result){                
                    this.temporalTable.splice(index, 1);
                    this.request.splice(index, 1);
                    this.existingEvents.splice(index, 1);
                    this.filterEventsDropdown()
                    this.validations();
                }
            })
        },
        addNewEvent: function(){
            this.canBeSend = false;
            this.reason = {
                message: "Specify type and time for the new event(s) created",
                tooltip: "Reset the component and contact system administrator if there's an error"
            };

            this.request.push({ 
                payroll_id: "",
                event: "",
                standard: "0",
                standard_time: "00:00",
                graveyard: "0",
                graveyard_time: "00:00",
                type: 'correction',
                zchange: true,
            })

            this.temporalTable.push({ 
                Billable: 0,
                Event: "",
                Payable: 0,
                Payroll_id: "",
                grvhours: 0,
                stdhours: 0,
                Comment: 'correction'
            })
        },
        selectChanged: function(i, event){
            // Change the Category marker according to its billable and payable values
            let billSign = event.target.selectedOptions[0].getAttribute("billable");
            let paySign  = event.target.selectedOptions[0].getAttribute("payable");
            this.temporalTable[i].Payable = paySign === 'FALSE' ? -1 : 0;
            this.temporalTable[i].Billable = billSign === 'FALSE' ? -1 : 0;
            // Reset values of Standard and Graveyard to 0 every change
            this.request[i].standard  = 0; this.request[i].standard_time  = "00:00";
            this.request[i].graveyard = 0; this.request[i].graveyard_time = "00:00";
            this.temporalTable[i].stdhours  = 0; this.temporalTable[i].grvhours = 0;
        },
        clearVariables: function(){
            this.billable = 0;
            this.payable = 0;
            this.shiftDuration = 0;
            this.activitiesDuration = 0;
            this.standardShift = 0;
            this.standardActivities = 0;
            this.graveyardShift = 0;
            this.graveyardActivites = 0;
        },
        clearTable: function(){
            for (let i = 0; i < this.request.length; i++) {
                if(this.temporalTable[i].Event == ""){
                    this.request[i].event = '';
                }  
            }
        },
        updateRequest: function(index, type){
            if(this.request[index].event == "") return;

            this.existingEvents.push(this.request[index].event.toLowerCase());
            this.filterEventsDropdown();

            if(type === 's'){
                let seconds = this.timeToSeconds(this.request[index].standard_time)
                this.request[index].standard = seconds;
                this.temporalTable[index].stdhours = seconds;

                // Asignar valores a Billing y Payroll de acuerdo a lo que se haya editado
                let billingSign = this.temporalTable[index].Billable == 0 ? 1 : Math.sign(this.temporalTable[index].Billable);
                let payableSign = this.temporalTable[index].Payable  == 0 ? 1 : Math.sign(this.temporalTable[index].Payable);
                this.temporalTable[index].Billable = billingSign*(seconds + parseInt(this.temporalTable[index].grvhours));
                this.temporalTable[index].Payable = payableSign*(seconds + parseInt(this.temporalTable[index].grvhours));
            }else{
                let seconds = this.timeToSeconds(this.request[index].graveyard_time);
                this.request[index].graveyard = seconds;
                this.temporalTable[index].grvhours = seconds;

                // Asignar valores a Billing y Payroll de acuerdo a lo que se haya editado
                let billingSign = this.temporalTable[index].Billable == 0 ? 1 : Math.sign(this.temporalTable[index].Billable);
                let payableSign = this.temporalTable[index].Payable  == 0 ? 1 : Math.sign(this.temporalTable[index].Payable);
                this.temporalTable[index].Billable = billingSign*(seconds + parseInt(this.temporalTable[index].stdhours));
                this.temporalTable[index].Payable = payableSign*(seconds + parseInt(this.temporalTable[index].stdhours));
            }
            this.temporalTable[index].Event = this.request[index].event;
            this.request[index].zchange = true;
            this.clearTable();
            this.validations();
        },
        validateTime: function(){
            // clear variables everytime you do validations
            this.clearVariables();
            // This function allows to validate the Temporal Table and do the subtotal calculation
            [].forEach.call(this.temporalTable, element => {
                    // Calculate subtotal of billable and payable
                    this.billable = this.billable + (element.Event == 'shift' ? parseInt(element.Billable) : 0) 
                    + (Math.sign(element.Billable) > 0 || element.Event == 'shift' ? 0 : parseInt(element.Billable));
                    this.payable  = this.payable  + (element.Event == 'shift' ? parseInt(element.Payable)  : 0) 
                    + (Math.sign(element.Payable)  > 0 || element.Event == 'shift' ? 0 : parseInt(element.Payable));

                    // Calculation of shift and activities
                    this.shiftDuration = this.shiftDuration + (element.Event == 'shift' ? parseInt(element.stdhours) + parseInt(element.grvhours) : 0);
                    this.activitiesDuration = this.activitiesDuration + (element.Event != 'shift' ? parseInt(element.stdhours) + parseInt(element.grvhours) : 0);

                    // Calculation of std shift and std activities
                    this.standardShift = this.standardShift + (element.Event == 'shift' ? parseInt(element.stdhours) : 0);
                    this.standardActivities = this.standardActivities + (element.Event != 'shift' ? parseInt(element.stdhours) : 0);

                    // Calculation of grv shift and grv activities
                    this.graveyardShift = this.graveyardShift + (element.Event == 'shift' ? parseInt(element.grvhours) : 0);
                    this.graveyardActivites = this.graveyardActivites + (element.Event != 'shift' ? parseInt(element.grvhours) : 0);
            });

            // Validation than Shift is not smaller than Activities
            if(this.shiftDuration < this.activitiesDuration){
                this.reason = {
                    message: "Activities are bigger than the actual shift",
                    tooltip: "Seconds are being considered but not edited, if there's an error changing 1 minute should help reset seconds"
                }
            }
            
            // Validation standard shift is not smaller than standard activities
            if(this.standardShift < this.standardActivities){
                this.reason = {
                    message: "Standard Activities are bigger than the standard shift",
                    tooltip: "Seconds are being considered but not edited, if there's an error changing 1 minute should help reset seconds"
                }
            }

            // Validation graveyard shift is not smaller than graveyard activities
            if(this.graveyardShift < this.graveyardActivites){
                this.reason = {
                    message: "Graveyard Activities are bigger than the graveyard shift",
                    tooltip: "Seconds are being considered but not edited, if there's an error changing 1 minute should help reset seconds"
                }
            }

            if (this.shiftDuration >= this.activitiesDuration 
            && this.standardShift >= this.standardActivities
            && this.graveyardShift >= this.graveyardActivites){
                return false;
            }
            this.canBeSend = false;
            return true;
        },
        validateRequests: function(){
            let count = 0;
            let fullfilled = 0;
            [].forEach.call(this.request, element => {
                count = element.zchange === true ? count+1 : count;
                fullfilled = (element.event === '' || element.event === null) ? fullfilled+1 : fullfilled;
            });
            if (fullfilled > 0) {
                this.reason = {
                    message: "Specify type and time for the new event(s) created",
                    tooltip: "Reset the component and contact system administrator if there's an error"
                };
                return true;
            }
            if (count === 0) {
                this.reason = {
                    message: "No changes registered",
                    tooltip: "Reset the component and contact system administrator if there's an error"
                };
                return true;
            }
            return false;
        },
        validateReason: function(){
            // Validate reason is selected and is not empty
            if ((this.requestReason === 'other' && (this.otherReason?.trim() === '' || this.otherReason === null)) || this.requestReason?.trim() === '' || this.requestReason === null) {
                this.reason = {
                    message: "Reason for the adjustments is empty, please fill this information to continue",
                    tooltip: "Reset the component and contact system administrator if there's an error"
                };
                return true;
            }
            return false;
        },
        validateImages: function(){
            if (!this.includeImages && (this.noProofReason?.trim() === '' || this.noProofReason === null)) {
                this.reason = {
                    message: "Must incluide a proof image or a reason for not incluiding proof",
                    tooltip: "Reset the component and contact system administrator if there's an error"
                };
                return true;
            }
            return false;
        },
        validations: function(){
            this.reason = { message: "",};
            if(this.validateTime() || this.validateReason() || this.validateRequests() || this.validateImages()){
                this.canBeSend = false;
                return true;
            }else{
                this.canBeSend = true;
                this.reason = { message: "",};
            }
        },
        clearProof(){
            this.proof = null;
            this.noProofReason = null;
            this.requestProof = !this.requestProof;
            this.validations();
        },
        setFileName(file){
            this.proof = file;
            this.includeImages = true;
            this.validations();
        },
        resetFileModal(){
            this.modalResetKey ++;
            this.includeImages = false;
            this.validations();
        },
        closeModal: function(){
            this.$emit('closeModal');
        },
        filterEventsDropdown: function(){
            const result = Object.entries(this.getAllEventsPerCampaign).map(([key, value]) => {
                let filtered_events = value.filter((event) =>
                    !this.existingEvents.includes(event.break_name.toLowerCase())
                )
                return {[0]: key, [1]: filtered_events}
            });
            let entries = Object.fromEntries(result);
            const newFilterd = Object.entries(entries).filter(([key]) => (!this.existingEvents.includes(key.toLowerCase())));
            this.filteredEvents = Object.fromEntries(newFilterd);
        },
        sendHoursRequest: async function(){
            this.isLoading = true; this.canBeSend = false;
            let obj = {
                employee: this.employee,
                eventDate: this.eventDate,
                approvalId: this.approvalId,
                reason: this.requestReason === 'other' ? this.otherReason : this.requestReason,
                requests: this.request,
                noProofReason: this.noProofReason,
                columnAffected: this.isEmployee ? 'AgentStatus' : 'SupervisorStatus'
            }
            // console.log(obj);
            if(this.proof instanceof File){
                try{
                    let form = new FormData()
                    form.append("image", this.proof);
                    const response = await this.uploadImgRequestProof(form);
                    obj.proof_id = response.data.img;
                }catch(error){
                    return this.$fire({
                        type: "error",
                        title: "Something went wrong with the file provided",
                        text: "Please try again with a different file type or report this issue to an administrator",
                        showConfirmButton: true,
                    });
                }
            }

            this.requestHoursReview(obj).then(() => {
                this.isLoading = false;
                this.$fire({
                    type: 'success',
                    title: 'Request(s) sent!',
                    showConfirmButton: false,
                    timer: 2500,
                }).then(()=>{
                    this.closeModal();
                })
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: 'Something went wront',
                    text: "Please try again, if the problem persist, please report to a system administrator",
                    showConfirmButton: true,
                })
               this.loading = false;
            })
        },

    },
    created() {
        if(this.isEmployee) this.requestProof = false;
        this.showTable = false;

        // Temporary values for the table, helps me to filter the subtotal row out
        this.temporalTable = null;
        const filtered = this.getPayrollDetailedDay.filter(item =>
            item.Event.toLowerCase() !== 'subtotal'
        );
        this.temporalTable = JSON.parse(JSON.stringify(filtered));

        // Array for request only
        [].forEach.call(this.getPayrollDetailedDay, element => {
            if(element.Event.toLowerCase() !== 'subtotal'){
                let stdtime = new Date(Math.abs(element.stdhours) * 1000).toISOString().substr(11, 5);
                let grvtime = new Date(Math.abs(element.grvhours) * 1000).toISOString().substr(11, 5);
                // Calculate summary
                this.billable = this.billable + (element.Event == 'shift' ? parseInt(element.Billable) : 0) 
                + (Math.sign(element.Billable) > 0 || element.Event == 'shift' ? 0 : parseInt(element.Billable));
                this.payable  = this.payable  + (element.Event == 'shift' ? parseInt(element.Payable)  : 0) 
                + (Math.sign(element.Payable)  > 0 || element.Event == 'shift' ? 0 : parseInt(element.Payable));
                this.request.push({ 
                    payroll_id: element.Payroll_id,
                    event: element.Event,
                    standard: element.stdhours,
                    standard_time: stdtime,
                    graveyard: element.grvhours,
                    graveyard_time: grvtime,
                    type: "adjustment",
                    zchange: false,
                })
                this.existingEvents.push(element.Event.toLowerCase());
            }
        });
        this.fetchAllEvents(this.employee).then(()=>{
            this.filterEventsDropdown();
        });

        this.showTable = true;
    },
    mounted(){
        
    },
}
</script>
<style scoped>  
body {
  background-color: rgba(31, 41, 55); /* cool gray 800 */
  color: white;
}

.one:hover span:last-child {
  width: 100%;
}

.two:hover span {
  width: 50%;
}

.three:hover span {
  height: 100%;
}
</style>