<template>
<div class="fixed z-20 w-full h-full top-0 left-0 flex flex-row items-center justify-center">
    <div class="absolute top-0 left-0 dropback w-full h-full bg-blue-200 opacity-50 z-20 flex" @click="close"></div>
    <div class="relative z-30 p-3 rounded-md shadow-faded bg-white w-2/4 z-90 mx-auto">
        <form id="LinkedQuestion" action="#" autocomplete="off" @submit.prevent="link()">   
            <div class="space-y-4 p-3 flex flex-col my-4">
                <div class="space-y-1">

                    <div class="text-xl font-bold font-sans text-gray-600">
                        Question: {{origin_question.question}}
                    </div>

                    <select name="question_linked" id="question_linked" class="bg-transparent text-gray-400 hover:text-gray-600 font-sans my-3 w-80" v-model="question_selected" @change="checkType()">
                        <option hidden value="null">Select te question you want to link it with</option>
                        <option v-for="(question) in questions_options" :value="question.originalIndex" :key="question.originalIndex">{{question.question}}</option>
                    </select>

                    <!-- Options -->
                    <div v-if="question_selected !== null">

                        <div class="text-xl font-bold font-sans text-gray-600">
                            Criteria
                        </div>
                        
                        <div v-if="question_type === 'text' || question_type === 'multiple'" >
                            <input id="titleId" type="text" name="survey_title" v-model="writing"
                            class="px-2 py-1 w-full border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="Words can be found in text"
                            maxlength="50" >
                            <div class="flex flex-row text-xs text-gray-400">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Save your keywords by using comma ','
                                </p>
                            </div>
                            <!-- see conditions -->
                            <div v-if="conditionsIsNotEmpty" class="px-2 mb-3 flex flex-wrap">
                                <div v-for="(condition, index) in conditions" :key="index">
                                    <span class="px-2 py-1 bg-blue-400 hover:bg-blue-200 rounded-md text-white text-xs font-sans cursor-pointer
                                    flex flex-wrap m-1 justify-between items-center" 
                                    @click="deleteCondition(index)">
                                        {{condition}}

                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-xs" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                        </svg> 
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="question_type === 'number'" >
                            <input id="titleId" required type="number" name="survey_title" v-model="numberData[0]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="First Number" min="0">
                            <input id="titleId" required type="number" name="survey_title" v-model="numberData[1]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="Second Number" min="0">
                            <div class="flex flex-row text-xs text-gray-400 mx-auto mt-2">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Select the range of numbers will trigger the question, if it is one number, put the same on both sections.
                                </p>
                            </div>
                        </div>

                        <div v-if="question_type === 'dropdown'" >
                            <select name="options" id="options" v-model="dropdownData" class="bg-transparent text-gray-400 hover:text-gray-600 font-sans my-3 w-80">
                                <option value="null" hidden>Select an Option</option>
                                <option v-for="option in question_options" :value="option.answer" :key="option.answer">{{option.answer}}</option>
                            </select>
                            <div class="flex flex-row text-xs text-gray-400 mx-auto mt-2">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Select the range of numbers will trigger the question, if it is one number, put the same on both sections.
                                </p>
                            </div>
                        </div>

                        <div v-if="question_type === 'date'">
                            <input id="titleId" required type="date" name="survey_title" v-model="dateData[0]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="First Number" min="0">
                            <input id="titleId" required type="date" name="survey_title" v-model="dateData[1]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="Second Number" min="0">
                            <div class="flex flex-row text-xs text-gray-400 mx-auto mt-2">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Select the range of dates will trigger this question, if it just one date, put the same on both sections.
                                </p>
                            </div>
                        </div>

                        <div v-if="question_type === 'time'">
                            <input id="titleId" required type="time" name="survey_title" v-model="timeData[0]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="First Number" min="0">
                            <input id="titleId" required type="time" name="survey_title" v-model="timeData[1]"
                            class="px-2 py-1 mx-3 basis-1/2 border-b-2 focus:border-blue-400 font-sans focus:placeholder-gray-700"
                            placeholder="Second Number" min="0">
                            <div class="flex flex-row text-xs text-gray-400 mx-auto mt-2">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Select the range of time will trigger this question, if it just one time, put the same on both sections.
                                </p>
                            </div>
                        </div>

                        <div v-if="question_type === 'duration'">
                            <vue-timepicker v-model="durationData[0]"
                            class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 mr-2" 
                            format="HH:mm:ss" close-on-complete></vue-timepicker>

                            <vue-timepicker v-model="durationData[1]"
                            class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-green-700 focus:placeholder-gray-700 h-8 mr-2" 
                            format="HH:mm:ss" close-on-complete></vue-timepicker>

                            <div class="flex flex-row text-xs text-gray-400 mx-auto mt-2">
                                <p class="text-left my-auto">
                                    <span class="text-red-400">*</span>
                                    Select the range of dates will trigger this question, if it just one date, put the same on both sections.
                                </p>
                            </div>
                        </div>

                        <span v-if="conditionsIsNotEmpty && !isValid" class="text-xs text-red-400 font-sans font-bold my-2">
                            * First Value must be lower or equal to the second one.
                        </span>
                        
                    </div>
                    <!-- Linked -->
                    <div class="text-right">
                        <button type="submit"
                        :disabled="!isValid"
                        :class="{ 'cursor-default bg-gray-400' : !isValid, 'bg-blue-600 hover:bg-blue-800' : isValid }"
                        class="uppercase justify-center w-32 py-2 px-5 shadow-md text-sm font-medium rounded-md text-white">
                            Next
                        </button>
                    </div>
                    
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
import Debounce from 'debounce';
    export default {
        components: { 
            VueTimepicker
        },
        data(){
            return{
                sending: false,
                questions_options: null,
                question_selected: null,
                question_type: null,
                writing: null,
                isValid: false,
                dropdownData: null,
                numberData: [],
                dateData: [],
                timeData: [],
                durationData: [],
                conditions: [],
                evaluation_type: null,
                question_options: [],
            }
        },
        computed: {
            ...mapState(["surveys, feedbacks"]),
            ...mapGetters([ 'getCampaignList' ]),

            conditionsIsNotEmpty(){
                return this.conditions?.length > 0;
            },
        },
        props:{
            origin_question: {
                required: true, 
            },
            all_questions: {
                required: true,
            },
            index: {
                required: true
            }
        },
        methods:{
            ...mapActions(['createFeedbackType',
            'fetchCampaignsGroups']),

            close(){
                this.$emit('close');
            },

            checkType(){
                this.isValid = false;
                this.conditions = [];
                this.question_type = this.all_questions[this.question_selected].question_type;
                this.question_options = this.all_questions[this.question_selected].question_answers.filter(option => option.answer !== "");
            },

            deleteCondition(index){
                this.conditions.splice(index, 1);
            },

            link: function(){
                let objdata = {
                    parent_id: this.question_selected,
                    evaluation_type: this.evaluation_type,
                    conditions: this.conditions
                };
                let obj = {
                    conditions: objdata,
                    child_question: this.question_selected,
                    parent_question: this.index
                };
                // console.log(obj)
                this.$emit('toggle', obj);
            }
           
        },
        watch:{
            writing: Debounce(function(value){
                if(value?.trim()?.replace(/[^a-zA-Z0-9 ]+/gi, '') === '' || value === '' || value === null || !value.includes(',')) return;
                this.evaluation_type = 'match';
                this.conditions.push(value?.trim()?.replace(/[^a-zA-Z0-9 ]+/gi, ''));
                this.isValid = true;
                this.writing = null;
            },200),
            
            numberData: Debounce(function(value){
                if(value?.length > 0 && value[0] <= value[1]) {
                    this.isValid = true;
                }else{
                    this.isValid = false;
                }
                this.evaluation_type = 'range';
                this.conditions = value;
            },200),

            dateData: Debounce(function(value){
                if(value?.length > 0 && value[0] <= value[1]) {
                    this.isValid = true;
                }else{
                    this.isValid = false;
                }
                this.evaluation_type = 'range';
                this.conditions = value;
            },200),

            timeData: Debounce(function(value){
                if(value?.length > 0 && value[0] <= value[1]) {
                    this.isValid = true;
                }else{
                    this.isValid = false;
                }
                this.evaluation_type = 'range';
                this.conditions = value;
            },200),


            durationData: Debounce(function(value){
                this.conditions = [];
                if(value?.length <= 1) return;
                let first = (value[0].HH || "00") + ":" + (value[0].mm || "00") + ":" + (value[0].ss || "00");
                let second = (value[1].HH || "00") + ":" + (value[1].mm || "00") + ":" + (value[1].ss || "00");
                if(first <= second) {
                    this.isValid = true;
                }else{
                    this.isValid = false;
                }
                this.evaluation_type = 'range';
                this.conditions.push(first, second);
            },200),

            dropdownData: Debounce(function(value){
                this.isValid = true;
                this.conditions.push(value);
                this.evaluation_type = 'match';
            },200),

        },
        created() {
            this.questions_options = this.all_questions.filter((question, index) => {
                question.originalIndex = index;
                return question.question_type !== "" && question.question !== "" && index !== this.index
            });
        }
    }
</script>

<style lang="scss" scoped>
    .formTransfer{
        display: block !important;
    }
    .hide{
        display: none;
    }
</style>