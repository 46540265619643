<template>
<div class="w-full">
     <material-loader class="px-3" v-if="isLoading"/>
    <select v-else class="border-b-2 w-full border-gray-300 active:border-gray-700 px-2" v-model="current" required>
        <option hidden value="">Select a trainer...</option>
        <option v-for="(item,index) in employeeList" :key="index" :value="item.EmpID">
            {{item.EmpID}} 
        </option>
    </select>
   
</div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import MaterialLoader from '@/views/mockups/MaterialLoader'
export default {
    components:{
        MaterialLoader
    },
    data(){
        return{
            currentTrainer:""
        }
    },
    props:{
        val:String
    },
    methods:{
        ...mapActions(['fetchEmployeesByRole']),
    },
    computed:{
        ...mapGetters(['employeeList','loadingFlag']),
        isLoading:function(){
            return this.loadingFlag;
        },
        current:{
            get(){
                return this.currentTrainer
            },
            set(value){
                this.currentTrainer = value
            }
        }
    },
    watch:{
        currentTrainer:function(trainer){           
            this.$emit('toggle',trainer);          
        }
    },
    created(){
        this.fetchEmployeesByRole(
            'Trainer'
        );
        this.currentTrainer = this.val;
    }
}
</script>