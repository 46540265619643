<template>
    <div class="bg-white shadow-sm relative rounded-lg p-10 mb-10">
        <div class="flex space-x-10">
            <div class="space-y-10">
                <div class="bg-gray-50 p-6 rounded-xl">
                    <h2 class="text-xl text-gray-800 font-bold">{{ employee.full_name }}</h2>                    
                    <div>
                        <span class="inline-block text-xs bg-c-primary font-semibold uppercase text-white rounded-full py-1 px-6 mx-auto">{{ employee.title }}</span>
                    </div>
                    <div class="flex text-left space-x-5 mt-6" id="employee_details">
                        <div class="flex-1 flex items-center space-x-2">
                            <div class="bg-red-200 rounded-md icon flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                            </div>
                            <div class="leading-[1]">
                                <div class="text-sm text-gray-600 font-semibold font-sans uppercase leading-[1]">{{ employee.emp_id }}</div>
                                <span class="text-[80%] text-gray-400 font-semibold uppercase">Employee ID</span>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center space-x-2">
                            <div class="bg-red-200 rounded-md icon flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 m-auto text-red-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                            </div>
                            <div class="leading-[1]">
                                <div class="text-sm text-gray-600 font-semibold font-sans uppercase leading-[1]">{{ employee.location }}</div>
                                <span class="text-[80%] text-gray-400 font-semibold uppercase my-auto">Site</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="actions" class="flex space-x-2 justify-center">
                    <button v-if="requestIsOpen" @click="approve()" class="bg-green-600 text-white py-2 px-6 rounded-full uppercase">
                        <i class="bi bi-check-lg m-auto mr-1"></i>
                        Approve
                    </button>
                    <button v-if="requestIsOpen || request.status === 'approved' && request.file_delivered==0" @click="decline()" class="bg-red-500 text-white py-2 px-6 rounded-full uppercase">
                        <i class="bi bi-x-lg m-auto mr-1"></i>
                        Decline
                    </button>
                </div>
            </div>
            <div class="flex-1 space-y-6">
                <div class="flex justify-between">
                    <h3 class="text-xl font-bold text-left">MSL ID {{ this.$route.params.id }}</h3>
                    <a v-if="requestIsOpen" @click="edit()" class="inline-block rounded-full text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-6 py-2 cursor-pointer"><i class="bi bi-pencil mr-2"></i> Edit</a>
                </div>
                <div class="flex space-x-4">
                    <div class="flex-1 text-left">
                        <div class="text-gray-400 text-sm">Classification</div>
                        <div class="w-full border-b block border-gray-400 py-1 uppercase">
                            {{ request.classification }}
                        </div>
                    </div>
                    <div class="text-left">
                        <div class="text-gray-400 text-sm">Start Date</div>
                        <div class="w-full border-b block border-gray-400 py-1 uppercase">
                            {{ request.start_date }}
                        </div>
                    </div>
                    <div class="text-left">
                        <div class="text-gray-400 text-sm">Amount of authorized sick leave days</div>
                        <div class="w-full border-b block border-gray-400 py-1 uppercase">
                            {{ request.days_of_incapacitation }}
                        </div>
                    </div>
                </div>
                <div class="flex space-x-4">
                    <div class="flex-1 text-left">
                        <div class="text-gray-400 text-sm">Periodicity</div>
                        <div class="w-full border-b block border-gray-400 py-1 uppercase">
                            {{ request.periodicity }}
                        </div>
                    </div>
                    <div class="flex-1 text-left">
                        <div class="text-gray-400 text-sm">Request Status</div>
                        <div class="w-full border-b block border-gray-400 py-1 uppercase">
                            <i class="inline-block rounded-full w-3 h-3" :class="requestIsOpen ? 'bg-yellow-400':(request.status=='approved' ? 'bg-green-600':'bg-red-600')"></i>
                            {{ request.status }}
                        </div>
                    </div>
                </div>
                <div class="flex space-x-4">
                    <div class="flex-1 text-left">
                        <div class="text-gray-400 text-sm">File</div>
                        <div class="mt-2">
                            <a :href="request.file" target="_blank" class="text-center block bg-blue-600 hover:bg-blue-700 text-white !no-underline rounded-full px-6 py-2"><i class="bi bi-folder2-open mr-2"></i> Open File</a>
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="text-gray-400 text-sm">File Delivered</div>
                        <div class="w-full block py-1 pt-2 uppercase">
                            <i class="text-3xl bi" :class="request.file_delivered==1 ? 'bi-check-circle-fill text-green-600':'bi-x-circle-fill text-red-600'"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data () {
        return {
            id: this.$route.params.id,
            link_fake: 'https://programacion.net/files/article/20211124101110_php-mysql.png',
            employee: {
                full_name: 'John Doe',
                emp_id: 'J.Doe123',
                title: 'Software Architect',
                location: 'MTY',
            },
            request: {
                classification: '',
                start_date: '',
                days_of_incapacitation: 0,
                periodicity: '',
                file: '',
                file_delivered: 0,
                status: 'approved'
            }
        }
    },
    computed: {
        ...mapGetters([
            'getMSLDetails',
            'getLoadingMSL',
            'infoEmployee'
        ]),

        requestIsOpen () {
            return this.request.status === 'pending';
        }
    },
    methods: {
        ...mapActions([
            'fetchMedicalRequestDetails',
            'publicProfile',
            'approveMedicalRequest',
            'declineMedialRequest',
            'changeIncidenceStatus'
        ]),
        getLink () {
            let url = this.getMSLDetails?.file;
            if(this.getMSLDetails?.file?.startsWith("http")) return url;
            return 'https://centris.website-us-southeast-1.linodeobjects.com/linode_requests/' + url;
        },

        async approve () {
            if(this.request.file_delivered==0) {
                const { value: delivered } = await this.$fire({
                    type: 'question',
                    text: 'Continue the request',
                    title: 'File Delivered?',
                    showCancelButton: true,
                    focusCancel: true,
                    confirmButtonText: 'Yes, Delivered',
                    confirmButtonColor: '#16a34a',
                    cancelButtonText: 'No, Continue'
                });
                this.request.file_delivered = delivered ? 1:0;
            }


            const { value: confirmation } = await this.$fire({
                type: 'question',
                text: 'You are about to approve this request',
                title: 'Do you want to continue?',
                showCancelButton:true,
                focusCancel: true,
                confirmButtonText:'Yes, approve',
                cancelButtonText: 'Cancel'
            });

            if(!confirmation) return;

            document.getElementById('pro-loading').style.display = 'flex';
            let objData = {
                id: this.$route.params.id,
                fd: this.request.file_delivered
            }
            await this.approveMedicalRequest(objData).then((res) =>{
                if(res?.msl && res?.msl.Status=='pending') {
                    this.$fire({
                        type: 'warning',
                        title: 'MSL Pending',
                        timer: 1500,
                        showConfirmButton: false
                    });
                } else {
                    this.$fire({
                        type: 'success',
                        title: res?.message,
                        timer: 1500,
                        showConfirmButton: false
                    });
                }
                this.$router.push({name: 'msl.index'});
            }).catch((err) => {
                let list = err?.response?.data?.list ? err?.response?.data?.list:[];
                if(list.includes(0)) {
                    this.$fire({
                        type: 'error',
                        title: 'Error',
                        text: err?.response?.data?.message
                    })
                } else {
                    this.$fire({
                        type: 'question',
                        text: 'We can’t proceed because we found some active incidences.',
                        title: 'Do you want to cancel them?',
                        showCancelButton: true,
                        focusCancel: true,
                        confirmButtonText: 'Yes, Cancel',
                        confirmButtonColor: '#e83c50',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result?.value) {
                            err?.response?.data?.id.forEach(item => {
                                let objData = { idSelected: item, newStatus: 'DECLINED' }
                                this.changeIncidenceStatus(objData).then((res)=>{
                                    console.log(res);
                                }).catch((err) => {
                                    console.log(err);
                                });
                            });
                            this.approve();
                        }
                    });
                }
            })
            document.getElementById('pro-loading').style.display = 'none';
        },

        async decline () {
            const { value: confirmation } = await this.$fire({
                type: 'question',
                text: 'You are about to decline this request',
                title: 'Do you want to continue?',
                showCancelButton:true,
                focusCancel: true,
                confirmButtonText:'Yes, decline',
                confirmButtonColor: '#e83c50',
                cancelButtonText: 'Cancel'
            });

            if(!confirmation) return;

            document.getElementById('pro-loading').style.display = 'flex';
            await this.declineMedialRequest(this.$route.params.id).then((res) =>{
                this.$fire({
                    type: 'success',
                    title: res?.message,
                    timer: 1500,
                    showConfirmButton: false
                });
                this.$router.push({name: 'msl.index'});
            }).catch((err) => {
                this.$fire({
                    type: 'success',
                    title: err?.message
                });
            })
            document.getElementById('pro-loading').style.display = 'none';
        },

        edit() {
            this.$router.push({
                name: 'msl.edit',
                params: {
                    id: this.employee.emp_id,
                    idmsl: this.id
                } 
            });
        }
    },
    mounted () {
        document.getElementById('pro-loading').style.display = 'flex';
    },
    created () {
        this.fetchMedicalRequestDetails(this.$route.params.id).then(() => {
            this.request.classification = this.getMSLDetails?.classification;
            this.request.start_date = this.getMSLDetails?.start_date;
            this.request.days_of_incapacitation = this.getMSLDetails?.days_duration;
            this.request.periodicity = this.getMSLDetails?.periodicity;
            this.request.file = this.getLink();
            this.request.file_delivered = this.getMSLDetails?.file_delivered;
            this.request.status = this.getMSLDetails?.status;
            this.employee.emp_id = this.getMSLDetails?.employee;
            this.publicProfile(this.employee.emp_id).then(() => {
                this.employee.full_name = (this.infoEmployee?.full_name).trim();
                this.employee.title = this.infoEmployee?.title;
                this.employee.location = this.infoEmployee?.site;
                document.getElementById('pro-loading').style.display = 'none';
            });
        }).catch((res) => {
            this.$fire({
                type: 'error',
                text: res?.message
            })
            this.$router.push({name: 'msl.index'});
        });
    },
}
</script>
<style scoped>
.icon{
    width: 2.5rem !important;
    height: 2.5rem !important;
}
a {
    text-decoration: none !important;
}
</style>