import axios from '@/config/axios';

const state = {
    list:[],
    error:false,
    trainees:[]
};

const getters = {
    _attritionList: (state)=> state.list,
    _trainees: (state) => state.trainees
};

const actions = {
    async fetchAttrition({commit}, id) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/${id}/attrition`)
            commit('SET_ATTRITION_LIST',response.data.attrition);
        }
        catch (error) {
            commit('SET_ERROR',true);
        }
    },

    async fetchTraineesAttrition({commit},id) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/${id}/attrition-trainees`)
            commit('SET_ATTRITION_TRAINEES',response.data.trainees);
        }
        catch (error) {
            commit('SET_ERROR',true);
        }
    },

    async saveAttrition({commit},formData) {
        try {
            const response = await axios.post(`api/v2/recruitment/trainings/saveAttrition`,formData);
            commit('SET_ATTRITION_LIST',response.data.attrition);
        }
        catch (error) {
            commit('SET_ERROR',true);
        }
    },

    async deleteAttrition({commit},id){
        const response = await axios.delete(`api/v2/recruitment/trainings/attrition/${id}/delete`);
        commit('SET_ATTRITION_LIST',response.data.attrition);  
    },

    async editAttrition({commit},formData){
        const response = await axios.put(`api/v2/recruitment/trainings/attrition/${formData.id}/update`,formData);
        commit('SET_ATTRITION_LIST',response.data.attrition);  
    },
};

const mutation = {
    SET_ATTRITION_LIST: (state, list) => {state.list = list},
    SET_ATTRITION_TRAINEES: (state, trainees) => {state.trainees = trainees},
    SET_ERROR: (state, status) => {state.error = status},
};

export default {
    state,
    getters,
    actions,
    mutation,
};