<template>
<div>
    <h4 class="text-xl font-semibold text-gray-500 text-left ">Detailed Request</h4>
    <payroll-events :approval_id="approval_id.Approval_id" :employee="approval_id.Emp_id" @close="close"/>
</div>
</template>
<script>
import PayrollEvents from '@/views/components/Payroll/tables/payrollEventsByApproval'
export default {    
    data(){
        return { 
            value: null,
            loading: false,
        }
    },
    components: {
        PayrollEvents
    },
    props:{
        approval_id:{ 
            required: true,
            type: Object
        },
    },
    methods:{
       close(){
           this.$emit('close');
       },
    },
    computed:{
    },
   
    created(){
       
    },
   
}
</script>

<style>
    .shadow-faded{
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
</style>