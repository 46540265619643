<template>
    <div class="p-1">
        <div :id="'divContainer' + elementID" :key="elementKey" class="p-1 border-dashed border-4 border-light-blue-500">
            <label v-if="inputLabel" class="text-left text-xs ml-2 block group-focus:text-gray-600">{{inputLabel}}</label>
            <datepicker :id="'datePicker'+elementID" :format="customFormatter" class="form-control" placeholder="Pick a date"></datepicker>
        </div>
    </div>
</template>
<script>
    import Datepicker from "vuejs-datepicker";
    import moment       from 'moment';
    export default { 
    components:{
        'datepicker' : Datepicker
    },
    props:{
        inputLabel:{
            type:       String,
            default:    null,
            required:   true,
        },
        elementID:{
            type:       String,
            default:    "",
            required:   true,
        },
        classToAdd:{
            type:       String,
            default:    'form-control',
            required:   false,
        },
        elementKey:{
            type:       String,
            default:    "",
            required:   true,
        }
    },
    methods:{
        customFormatter(date) {
            return moment(date).format("MM/DD/YYYY");
        },
    }
}
</script>
