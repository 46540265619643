import axios from '@/config/axios';

const state = {
    staff:[],
    error:false,
    list:[]
};
const getters = {
    _staff: (state)=> state.staff,
    _list: (state) => state.list
};
const actions = {
    async fetchTrainingStaffById({commit},id) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/${id}/fetchTrainingStaff`);
            commit('SET_STAFF', response.data.staff);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },
    async fetchTrainingStaffMembers({commit}) {
        try {
            const response = await axios.get(`api/v2/recruitment/trainings/fetchTrainingStaffList`)
            commit('SET_STAFF_list', response.data.staff);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },
    async saveTrainingStaffMember({commit},formData) {
        try {
            const response = await axios.post(`api/v2/recruitment/trainings/saveTrainingStaff`,formData)
            commit('SET_STAFF', response.data.staff);
        }
        catch (error) {
            commit('SET_ERROR',true);
            console.log(error);
        }
    },
    async deleteTrainingStaff({commit},form){
        const response = await axios.delete(`api/v2/recruitment/trainings/delete-staff`,{ data: form });
        commit('SET_STAFF', response.data.staff);
    },
};

const mutations = {
    SET_STAFF: (state, staff) => { state.staff = staff },
    SET_STAFF_LIST: (state, list) => { state.list = list },
    SET_ERROR: (state, status) => { state.error = status }
};

export default {
    state,
    actions,
    getters,
    mutations,
};