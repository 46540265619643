<template>
  <button class="relative text-white px-3 py-2 rounded-sm shadow-md" :disabled="status == 'loading'" :class="bgcolor"  @click="$emit('toggle')">
      <span v-if="status =='loading'">
          Processing
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1 inline animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
        </svg>
      </span>
      <span  v-if="status =='error'">
            Request failed
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
            </svg>
      </span>
      <span  v-if="status =='success'">
          Request completed
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
      </span>
      <span v-if="!status">
          <slot>
              Save
          </slot>
      </span>
  </button>

</template>
<script>
export default{
    props:{
        status:String,
        default:null
    },

    computed:{
        bgcolor:function(){
            return this.status =='error' ? 'bg-red-500' : this.status =='success' ?'bg-green-500' : 'bg-gray-700';
        }
    }
}
</script>
