<template>
    <tr class="hover:bg-gray-100 dark:hover:bg-gray-700" v-if="!loading">
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left w-3/5">
            <div>
                <multiselect  v-model="form.staff" class="border-b-2 border-gray-200 active:border-gray-500" @input="setStaff" :options="_list" placeholder="Select a staff member..." label="name" track-by="name" />
            </div>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
               {{this.$moment().format("L")}}
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
            {{this.emp_id}}
        </td>

        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
            <div class="flex flex-row items-center justify-end">
                    <button @click.prevent="newStaff" :disabled="saving" class="px-3 py-2 bg-gray-700 rounded-sm text-white">
                        <span v-if="!saving">
                            Save
                        </span>

                         <span v-else>
                            Saving  
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 h-4 inline-block animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                        </span>
                        
                    </button>
            </div>
        </td>
    </tr>
     <tr class="hover:bg-gray-100 dark:hover:bg-gray-700" v-else>
        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
            <div>
               Loading component
               <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 animate-bounce inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                </svg>
            </div>
        </td>
            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white text-left w-1/2">
                <div class="relative h-2 w-full bg-gray-300 rounded-full"></div>
        </td>
        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white text-left">
            <div class="relative h-2 w-full bg-gray-300 rounded-full"></div>
        </td>

        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
            <div class="relative h-2 w-full bg-gray-300 rounded-full"></div>
        </td>
    </tr>
</template>

<script>
// Pinia > Vuex
import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return{
            form:{
                staff:"",
                
            },
            employee_id:null,
            loading:true,
            saving:false
        }
    },
    props:{
        options:{
            type:Array,
            default:()=> []
        }
    },
    computed:{
        ...mapGetters([
            '_list'
        ])
    },
    methods:{
         ...mapActions([
                'fetchTrainingStaffMembers','saveTrainingStaffMember'
            ]),
         newStaff:async function(){
             this.$v.$touch()
             if(this.employee_id === null){
                 return this.$fire({type:'warning',title:'Select a staff member to proceed.'})
             }
            this.saving = true;
            await this.saveTrainingStaffMember({emp_id:this.employee_id,training_id:this.$route.params.id});
            this.$fire({type:'success', title:"Staff member registered successfully."})
            this.saving = false;
         },
         setStaff({id}){
            
             this.employee_id = id;
         }
    },
     validations:{
        form: {
           trainee_id:{
               required
           },
           comment:{
               required
           },
        }
    },
    async created(){
       await this.fetchTrainingStaffMembers();
       this.loading = false;
    }
}
</script>