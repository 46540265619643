import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    roles:[],
    user:{}
};
const getters = {
    getUserRoles:(state) => state.roles,
    getUserProfile:(state) => state.user
}
   
const actions = {
    async fetchUserRoles({commit}){
        const endpoint = API_URL + 'api/v3.1/management/roles';
        const response = await axios.get(endpoint).then((res) =>{
            commit('setUserRoles', res.data); 
        }); 

        return response;
    },
    async fetchUserProfile({commit}, userId){
        const endpoint = API_URL + 'api/v3.1/management/roles/' + userId;
        const response = await axios.get(endpoint).then((res) =>{
            commit('setUserProfile', res.data); 
        }); 

        return response
    },
    async updateUserProfile({commit}, form){
        const endpoint = API_URL + 'api/v3.1/management/roles/' + form['emp_id'] +'/update';
        const response = await axios.post(endpoint,form );
        commit('setUserProfile', response.data)

        return response
    }
};
const mutations = {
    setUserRoles: (state, roles)    =>  (state.roles  = roles),
    setUserProfile:(state, profile) => (state.user = profile)
};
export default {
    state,
    getters,
    actions,
    mutations
};