import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    pagination:             {},
    incidences:             [],
    incidencesList:         [],
    catalog:                [],
    incidenceStatus:        false,
    table_loading:          true,
    editStatus:             false,
    statusMessage:          [],
    incidenceDetails:       null
};
const getters = {
    getIncidentPagination:      (state) => state.pagination,
    getIncidenceStatus:         (state) => state.incidenceStatus,
    getIncidencesList:          (state) => state.incidencesList,
    getLoadingTable:            (state) => state.table_loading,
    getCatalog:                 (state) => state.catalog,
    getStatusMessage:           (state) => state.statusMessage,
    getIncidenceDetails:        (state) => state.incidenceDetails
};
const actions = {

    async storeIncidence({commit}, objData){
        const endpoint = API_URL + 'api/v2/incidences/storeIncidence';
        let response = await axios.post( endpoint, objData).then((res) =>{
            commit('setIncidenceStatus', true);
            commit('setStatusMessage', res.data);
            return res //.data;
        }).catch((error) =>{
            commit('setStatusMessage', error.response.data);
            return error //.response.data
        })
        return response;
    },

    async filterIncidencesReq({commit},data){
        let formData =  Object.keys(data).length !== 0 && data.constructor === Object ? filterObject(data, item => item !== 0 && item !== null): data;
        let response = await axios.post(`api/v2/incidences/filters/filterIncidences?page=${data.page}`,formData)
        commit('setIncidenceList', response.data.msg);
        commit('setIncidentPagination', {meta: response.data.meta, links: response.data.links})
        return response;
    },

    async fetchIncidences({ commit }){    
        const endpoint = API_URL + 'api/v2/incidences/incidencesList';
        await axios.get(endpoint).then( (response) =>{
            commit('setIncidenceList',response.data.msg);
        });
    },

    async fetchAllIncidences({ commit }){    
        const endpoint = API_URL + 'api/v2/incidences/allIncidencesList';
        await axios.get(endpoint).then( (response) =>{
            commit('setIncidenceList',response.data.msg);
        });
    },

    async changeIncidenceStatus({commit}, data){
        const endpoint = API_URL + 'api/v2/incidences/changeIncidenceStatus';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setIncidenceStatus', true); 
            return res;
        });
        return response;
    },

    async retroactiveApproval({commit}, data){
        const endpoint = API_URL + 'api/v2/incidences/retroactiveApproval';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setIncidenceStatus', true);     
            return res;
        });
        return response;
    },

    async fetchIncidencesCatalog({ commit }){    
        const endpoint = API_URL + 'api/v2/incidences/incidencesCatalog';
        await axios.get(endpoint).then( (response) =>{
            commit('setCatalog',response.data.msg);
        });
    },

    async editIncidence({ commit }, objData){
        const endpoint = API_URL + 'api/v2/incidences/editIncidence';
        await axios.post( endpoint, objData).then(() =>{
            commit('setEditStatus', true);  
        }); 
    },

    async fetchActiveIncidences({ commit }, data){    
        const endpoint = API_URL + `api/v4/incidences/${data.employee}/${data.date}`;
        const response = await axios.get(endpoint).then((res) =>{
            commit('setIncidenceList', res.data.data);
        });
        return response;
    },
};
const mutations = {
    setIncidentPagination:  (state, pagination)             =>  (state.pagination = pagination),
    setIncidenceStatus:     (state, incidenceStatus)        =>  (state.incidenceStatus = incidenceStatus),
    setLoadingTable:        (state, table_loading)          =>  (state.table_loading = table_loading),
    setIncidenceList:       (state, incidencesList)         =>  (state.incidencesList = incidencesList),
    setIncidenceDetails:    (state, incidenceDetails)       =>  (state.incidenceDetails = incidenceDetails),
    setCatalog:             (state, catalog)                =>  (state.catalog = catalog),
    setEditStatus:          (state, editStatus)             =>  (state.editStatus = editStatus),
    setStatusMessage:       (state, statusMessage)          => (state.statusMessage = statusMessage),
};
export default {
    state,
    getters,
    actions,
    mutations
};
let filterObject = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key])).reduce( (res, key) => (res[key] = obj[key], res), {} );