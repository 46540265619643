<template>
    <div class="fixed inset-0 flex flex-row items-center justify-center z-[9000]">
        <div class="absolute inset-0 bg-slate-800 opacity-80" @click="close"></div>
        <div class="relative z-[9001] p-10 rounded-xl drop-shadow-lg bg-white w-2/5">
            <button class="absolute right-3 top-3 w-5 h-5 text-slate-300 hover:text-slate-700 flex items-center justify-center" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
            <div class="w-full h-full overflow-x-hidden">
                <!-- Aquí el componente -->
                <detailed-request :approval_id="approval_id" @close="close"/>
            </div>
        </div>
    </div>
</template>



<!----

    1.- Tener un selector de opciones basado en x opciones y se debe elegir una si o si.
    2.- Si se elige day off, se puede guardar sin problema.
    3.- En caso contrario debe existir un ticket que este ligado al empleado para la solicitud de cambio, de no existir no podemos guardar el cambio.
--->

<script>
import DetailedRequest from '@/views/components/Payroll/modals/viewApprovalRequest'
export default {    
    data(){
        return { 
            value: null,
            loading: false,
            saving:false
        }
    },
    components: {
        DetailedRequest
    },
    props:{
        approval_id:{ 
            required: true,
            type: Object
        },
    },
    methods:{
       close(){
           this.$emit('close');
       },
    },
    computed:{
    },
   
    created(){
    },
   
}
</script>

<style>
    .shadow-faded{
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
</style>