<template>  
    <div class="incidencesModalContainer">
        <!-- <div class="d-block text-center">
            <h3 class="font-sans text-4xl tracking-wide bg-gray-50 font-bold text-gray-700 mt-2">Incidences</h3>
        </div> -->
        <div v-if="isLoading" class="row">
            <div class="col text-center">
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>
        <b-form v-else @submit="sendIncidence">
            <h4 class="font-medium text-gray-700">Information:</h4>
            <div class="bg-gray-50 px-4 py-4 mb-2">
                <!-- Information -->
                    <div class="flex">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Agent Selected" disabled>
                        <input for="survey_description" class="flex-1 text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-white py-1 px-2 m-2" v-model="profileInfo.EmpID" disabled>
                    </div>
                    <div class="flex">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Incidence" disabled>
                        <select id="incidenceId" for="survey_description" class="flex-1 text-gray-600 text-lg shadow-md border-b-2 border-blue-500 rounded-md bg-white py-1 px-2 m-2" required v-model="information.selectedIncidence">
                            <optgroup label="Select an Incidence Type">
                                <option value="null" hidden>Select an Option</option>
                                <option v-for="item in getCatalog" :value="item.Id" :key="item.id">{{item.IncidenceLabel}}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="flex">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Status" disabled>
                        <select id="statusId" for="survey_description" class="flex-1 text-gray-600 text-lg shadow-md border-b-2 border-blue-500 rounded-md bg-white py-1 px-2 m-2" required v-model="information.selectedStatus">
                            <optgroup label="Select an Status">
                                <option value="null" hidden>Select an Status</option>
                                <option value="APPROVED">Approved</option>
                                <option value="DECLINED">Declined</option>
                                <option value="PENDING">Pending</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="flex">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Type" disabled>
                        
                        <div class="w-full text-center align-middle flex-1 text-gray-600 text-lg rounded-md py-1 px-2 m-2">
                            <div class="flex">
                                <div class="flex-1">
                                    <div class="flex text-center justify-content-center align-middle">
                                        <input type="radio"  v-model="information.timeType" name="incident_time_type" class="my-auto mr-1" value="Full" required> Full Day
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <div class="flex text-center justify-content-center align-middle">
                                        <input type="radio"  v-model="information.timeType" name="incident_time_type" class="my-auto  mr-1" value="Parcial" required> Partial Day
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex" v-if="information.timeType == 'Parcial'">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Duration" disabled>
                        <vue-timepicker id="durationTimeId" class="flex-1 border-b-2 border-blue-500 text-gray-600 text-lg shadow-md rounded-md bg-white py-1 px-2 m-2 z-20" v-model="information.duration" format="HH:mm" close-on-complete></vue-timepicker>
                    </div>


                    <div class="flex">
                        <input for="survey_description" class="flex-2 text-center text-gray-600 text-lg shadow-md border-1 border-gray-500 rounded-md bg-blue-200 py-1 px-2 m-2" value="Ticket" disabled>
                        <div id="ticketId" class="w-full rounded-md m-2">
                            <multiselect class="flex justify-content-center text-left" v-model="information.selectedTicket" :custom-label="customFormat" :options="this.getTickets" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="false" placeholder="Select a Ticket" label="Campaign" track-by="ID"></multiselect>
                        </div>
                    </div>
            </div>
            <h4 class="font-medium text-gray-700">Date(s):</h4>
            <div class="bg-gray-50 px-4 py-4">
                <div class="w-full flex flex-row align-items-center justify-content-center">
                    <date-picker id="datesId" class="rounded-lg overflow-hidden shadow-lg z-10" v-model="information.selectedDates" placeholder="Select a date(s)" valueType="format" :custom-formatter="customFormatter" inline multiple></date-picker>
                </div>
            </div>
            
            <div class="justify-content-center text-center mt-3">
                <button type="submit" :disabled="alreadyClicked" :class="{'hover:bg-green-700' : !alreadyClicked , 'cursor-default' : alreadyClicked}" class="uppercase bg-green-500 text-white p-3 text-sm rounded-md shadow-md justify-content-center text-center">
                    {{this.ButtonLabel}}
                </button>
            </div>

        </b-form>
    </div>  
</template>
<script>
import {mapGetters,mapState, mapActions }   from 'vuex'
// import auth                                 from "@/config/user"
import moment                               from "moment";
import DatePicker                           from 'vue2-datepicker';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-datepicker/index.css';
import Multiselect                          from 'vue-multiselect'
export default {
    components: { 
        DatePicker,
        Multiselect,
        VueTimepicker
    },
    data(){
        return{
            striped: true,
            animate: true,
            percentage: 0,
            isHide: false,
            callbacks: 0,
            transferData: {},
            isLoading: true,
            alreadyClicked: false,

            ButtonLabel: "Send Information",
            information: {
                selectedIncidence:  null,
                selectedDates:      null,
                selectedStatus:     null,
                selectedTicket:     null,
                timeType:           null,
                duration:           {
                    HH: "00",
                    mm: "00",
                }
            },
        }
    },
    computed:{
        ...mapState([
            "tickets",
            "incidences"
        ]),
        ...mapGetters([
            "getTickets",
            "getCatalog",
            "getStatusMessage"
            
        ])
    },
    props:{
        profileInfo: Object,
    },
    methods:{
        ...mapActions([
            "fetchEmployeeTickets",
            "storeIncidence",
            "fetchIncidencesCatalog"
        ]),
        
        customFormatter(date){
        console.log(date)
            return moment(date).format("YYYY-MM-DD");
        },
        customFormat({ID, subCategoryName}){
            return `ID: ${ID} , SubCategory: ${subCategoryName}`;
        },
        sendIncidence: function(){
            event.preventDefault();
            let counter = [];

            if(this.information.selectedDates === undefined || this.information.selectedDates === null || this.information.selectedDates === ""){
                document.getElementById('datesId').classList.add('border-2', 'border-red-500')
                counter.push({answer : "datesId"})
            }else{
                document.getElementById('datesId').classList.remove('border-2', 'border-red-500')
            }

            if(this.information.selectedTicket === undefined || this.information.selectedTicket === null || this.information.selectedTicket === ""){
                document.getElementById('ticketId').classList.add('border-2', 'border-red-500')
                counter.push({answer : "failed"})
            }else{
                document.getElementById('ticketId').classList.remove('border-2', 'border-red-500')
            }

            if(this.information.selectedStatus === undefined || this.information.selectedStatus === null || this.information.selectedStatus === ""){
                document.getElementById('statusId').classList.remove('border-b-2', 'border-blue-500')
                document.getElementById('statusId').classList.add('border-2', 'border-red-500')
                counter.push({answer : "failed"})
            }else{
                document.getElementById('statusId').classList.remove('border-2', 'border-red-500')
                document.getElementById('statusId').classList.add('border-b-2','border-blue-500')
            }

            if(this.information.selectedIncidence === undefined || this.information.selectedIncidence === null || this.information.selectedIncidence === ""){
                document.getElementById('incidenceId').classList.remove('border-b-2','border-blue-500')
                document.getElementById('incidenceId').classList.add('border-2', 'border-red-500')
                counter.push({answer : "failed"})
            }else{
                document.getElementById('incidenceId').classList.remove('border-2', 'border-red-500')
                document.getElementById('incidenceId').classList.add('border-b-2','border-blue-500')
            }

            if(this.information.timeType === 'Parcial' && (this.information.duration.HH === '00' && this.information.duration.mm === '00')){
                document.getElementById('durationTimeId').classList.add('text-red-600')
                // document.getElementById('durationTimeId').classList.remove('border-b-2','border-blue-500')
                // document.getElementById('durationTimeId').classList.add('border-2', 'border-red-500')
                counter.push({answer : "failed"})
                console.log("bad")
            }else if(this.information.timeType === 'Parcial' && (this.information.duration.HH != '00' && this.information.duration.mm != '00')){
                document.getElementById('durationTimeId').classList.remove('text-red-600')
                // document.getElementById('durationTimeId').classList.remove('border-2', 'border-red-500')
                // document.getElementById('durationTimeId').classList.add('border-b-2','border-blue-500')
            }

        if(counter.length > 0){
            this.$fire({
                type: "warning",
                title: "Missing information",
                text: "Oops! There's something you forgot to add",
                showConfirmButton: true
            })
        }else{
            this.ButtonLabel = "Sending...";
            this.alreadyClicked = true;
            let secsDuration = this.information.duration === null ? 0 : (this.information.duration.mm * 60) + (this.information.duration.HH * 60 * 60);
            let objData = {
                employeeId: this.profileInfo.EmpID,
                ticketId:   this.information.selectedTicket.ID,
                incidence:  this.information.selectedIncidence,
                dates:      this.information.selectedDates,
                status:     this.information.selectedStatus,
                duration:   secsDuration,
             }
            // console.log(objData)
            this.storeIncidence(objData).then((res) =>{
                this.$fire({
                    type: "success",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1300,
                }).then(() =>{
                    this.$emit('closeModal', true);
                })
            }).catch(() =>{
                if(this.getStatusMessage.code === 400){
                    let mySubString = this.getStatusMessage.message.substring(
                        this.getStatusMessage.message.indexOf(":") + 3, 
                        this.getStatusMessage.message.indexOf("]") - 1
                    );
                    this.$fire({
                        type:   "error",
                        title:  this.getStatusMessage.status,
                        text:   mySubString,
                        showConfirmButton: true,
                    })
                }else{
                    this.$fire({
                        type: "error",
                        title: "Ups! Something went wrong",
                        text: "Please try again or report to a system administrator",
                        showConfirmButton: true,
                    })
                }
                this.ButtonLabel = "Send Information";
                this.alreadyClicked = false;
            })
        }

            
        },
    },
    created() {
        this.fetchIncidencesCatalog();
        this.fetchEmployeeTickets(this.profileInfo.EmpID).then(()=>{
            this.isLoading = false;
        })
    }
}
</script>
<style lang="scss" scoped>
.formTransfer{
    display: block !important;
}
.hide{
    display: none;
}
.cell-format{
    color:#545454;
    background-color:White;
    border-width:2px;
    border-color: white;
    border-style:solid;
    height:100%;
    width:70%;
    padding-left:5px;
    font-size:small;
}
</style>