<template>
  <div class="relative mx-3 w-64 group box-content">
    <div v-if="this.image" class="mx-auto ring-4 rounded-sm ring-white mt-8">
      <img :src="require(`@/assets/${this.image}.jpg`)" class=" relative rounded-sm w-64 h-32" alt="card_image"/>
    </div>
    <div class="w-full -mt-16 px-3">
    <div class="bg-white shadow-md rounded-md p-3 mx-auto relative">
      <h4 class="text-sm font-semibold text-gray-900 text-left">
          <span class="material-icons pt-0.5" style="font-size:125%;">
            <slot name="icon">
              video_library
            </slot>
          </span> {{title}}
      </h4>
      <p class="text-xs text-gray-700 font-medium text-left">
        <slot>
          A video talking about how to use clocker
        </slot>
      </p>
      <div v-if="this.link !== 'home' " class="relative flex">
         <card-button :link="this.link" :redirect="true">
           <slot name="button_text">
             Go to video
           </slot>
         </card-button>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import CardButton from "@/views/Utilities/CardButton";
export default {
  name: "SimpleCard",
  components:{
    CardButton
  },
  props:{
    title:{
      type:String,
      default:'Card title'
    },
    link:{
      type:String,
      default:'home'
    },
    link_text:{
      type:String,
      default:''
    },
    image:String
  }
}
</script>

<style scoped>

</style>