<template>
    <div v-if="!error" class="max-w-5xl mx-auto mt-3  bg-white shadow-md rounded-lg mb-5 py-2">

        <UserProfile :profile="this.getUserProfile" @save="saveChanges" :key="profileKey"/>

        <div class="bg-white px-5 pb-3 " v-if="profileLoaded && !isLoading">
             <div class="w-full px-3 flex flex-row border-b border-blue-100 py-4 text-left text-gray-700 text-sm font-semibold items-center justify-around border-t mt-3 border-gray-100">
                <span class="text-left flex-1">Location</span>
                <select class="flex-1 p-2 px-3 rounded-md outline-none border-2 border-gray-200" v-model="location" @change="updateLocation">
                    <option value="AGS">AGUASCALIENTES</option>
                    <option value="MTY">MONTERREY</option>
                    <option value="QRO">QUERETARO</option>
                </select>
            </div>
            <div class="w-full px-3 flex flex-row border-b border-blue-100 py-4 text-left text-gray-700 text-sm font-semibold items-center justify-around" 
            v-for="(item, index) in getUserRoles" :key="index">
                <span class="text-left flex-1">{{item.name}}</span>
                <ToggleButton @toggle="updateRoles" :active="!!currentRoles.find(x => x.id === item.id)" :id="item.id"/>
            </div>
        </div>
        <div v-else>
            <div class="relative mt-3 flex flex-row items-center bg-white px-5 pt-3 mb-3 rounded-sm mx-auto animate-pulse">
                <div class="relative text-center w-full">
                    <div class="relative text-xl font-semibold bg-gray-300 w-full rounded-full h-4 mb-2">
                    
                    </div>
                    <div class="relative text-xl font-semibold bg-gray-300 w-full rounded-full h-4 mb-2">
                    
                    </div>
                    <div class="relative text-xl font-semibold bg-gray-300 w-96 rounded-full h-4 mb-2">
                    
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div v-else class="flex flex-column items-center justify-center">
        <h4 class="text-center text-gray-700 font-semibold my-3">The user is not valid or is not active anymore. 
            If you think this is an error, please contact the administrator.</h4>
        <div class="relative mx-auto w-auto mb-4 border-8 shadow-sm border-white">
           <TheGoat/>
        </div>
       <a href="/employees" class="mx-auto bg-gray-800 px-3 py-2 rounded-md shadow-sm text-white">Go back</a>
    </div>
</template>

<!--

    1.- Crear componente para mostrar todos los roles disponibles; xxx
    2.- Crear componente para buscar a un usuario y mostrar sus datos basicos una vez escogido.
    3.- Actualizar el primer componente para mostrar los roles activos de un usuario. xxx
    4.- Crear un componente para cambiar el location de un usuario.

    5. Botón en Edit Employee para acceder a esta ventana
    6. Solucionar el true de los toggles para marcar cuando un rol está activos 
    7. Una vista de error en caso de no encontrar al usuario mencionado


-->

<script>
import {mapGetters,mapActions} from 'vuex'
import ToggleButton from '../components/toggle'
import UserProfile from '../components/UserProfile'
import TheGoat from '../components/TheGoat'


export default {
    data(){
        return {
            currentRoles:[],
            isLoading: false,
            location:'QRO',
            error: false,
            profileKey: 0,
        }
    },
    components:{
        ToggleButton,
        UserProfile,
        TheGoat
    },
    async mounted(){
        this.$emit('set-title', 'Roles Management');
        this.fetchUserRoles();
        
        this.fetchUserProfile(this.$route.params.id ?? 'J.Davila12919').then(()=>{
            this.error = false;
            this.location = this.getUserProfile.location;
            this.currentRoles = this.getUserProfile.roles.map(role => ({id: role.id, status:'active'}))
        }).catch(() =>{
            this.error = true;
            console.log(this.$refs.player);
        });
        
    },
    computed:{
        ...mapGetters(['getUserRoles','getUserProfile']),
        profileLoaded:function(){
            return !!this.getUserProfile?.emp_id;
        },
        roles:function(){
            return this.currentRoles;
        },
    },
    methods:{
        ...mapActions(['fetchUserRoles','fetchUserProfile','updateUserProfile']),
        updateRoles({id, value}){
          let index  = this.currentRoles.findIndex(role => role.id === id), 
          isNotInCurrentRoles = index == -1,
          statusIsActive = value;
          
          if(isNotInCurrentRoles && statusIsActive) this.currentRoles.push({id:id, status:'active'});

          if(!statusIsActive && !isNotInCurrentRoles) this.currentRoles.splice(index, 1);
          

        },
        updateLocation({target:{value}}){
          this.location = value;
        },
        saveChanges:function(){
            this.isLoading = true;
            let form = {
                roles: this.currentRoles.map(role => role.id),
                location: this.location,
                emp_id: this.$route.params.id
            }

            this.updateUserProfile(form).then(()=>{
                this.$fire({title:'User profile updated'});
                this.fetchUserRoles();
                this.fetchUserProfile(this.$route.params.id ?? 'J.Davila12919').then(()=>{
                    this.profileKey++;
                    this.isLoading = false,
                    this.error = false;
                    this.location = this.getUserProfile.location;
                    this.currentRoles = this.getUserProfile.roles.map(role => ({id: role.id, status:'active'}))
                }).catch(() =>{
                    this.error = true;
                    this.$fire({
                        type: "error",
                        title: "Something went wrong",
                        text: "Please report to the system administrator",
                        showConfirmButton: false,
                        timer: 1300,
                    })
                });
            });
        },
    },

}
</script>

<style>

</style>