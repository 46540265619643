<template>
    <button class="text-xl hover:text-c-primary p-1 mx-3" @click="setStatus" v-b-tooltip.hover.top="'Clocker'">
        <span class="sr-only">View notifications</span>
        <i class="bi bi-clock"></i>
    </button>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    computed:{
        ...mapGetters(['getModalVisibility']),
        active:{
            get(){
                return this.getModalVisibility;
            },
            set(value){
                this.$store.commit('setModalVisibility',value);
            }
        }
    },
    methods:{
        setStatus(){
            if(!this.active){
                this.active = true;
            }
        }
    }
}
</script>