<template>
  <div class="absolute inset-0 flex flex-row" id="container">
    <div class="basis-full md:basis-6/12 lg:basis-4/12 xl:basis-3/12 relative h-full bg-white shadow p-16 grid grid-cols-1 place-content-center z-50">
      <div>
        <div class="text-center mb-16">
          <img src="../../../assets/centris-logo.png" class="brand_logo mx-auto" alt="Logo">
        </div>
        <div>
          <h4 class="mb-0">Welcome to EMS!</h4>
          <p>Sign in to continue.</p>
        </div>
        <loading :active.sync="isLoading" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <form @submit.prevent="submit">
          <div class="border" style="margin-bottom: -1px;">
            <input type="text" name="" v-model="EmpID" autocomplete="off" class="w-full px-3 py-2 input_user" value="" placeholder="username">
          </div>
          <div class="border">
            <input type="password" autocomplete="off" v-model="password" name="" class="w-full px-3 py-2 input_pass" value="" placeholder="password">
          </div>

          <div class="text-xs font-medium text-red-900 p-2 bg-red-200 rounded-md mt-4 flex flex-row" :hidden="error===''">
            <span class="">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
              </svg>
            </span>
            <span class="flex-1 text-left">
              {{error}}
            </span>
          </div>

          <div class="d-flex justify-content-center mt-3 login_container">
            <button type="submit" name="button" class="bg-c-primary px-4 py-2 rounded text-white login_btn">Login</button>
          </div>
        </form>
        <p class="text-gray versioning mt-16"><small class="vernumber">v{{this.version}}</small></p>
      </div>
    </div>
    <div class="basis-0 md:basis-6/12 lg:basis-8/12 xl:basis-9/12 relative h-full">
      <div class="absolute top-1/3 bottom-0 left-0 right-0 z-40">
        <h2 class="font-black text-4xl lg:text-6xl text-center text-white drop-shadow-xl w-2/3 mx-auto">Well done is better than well said<br/><small class="text-2xl">Benjamin Franklin</small></h2>
      </div>
      <div class="absolute top-0 bottom-0 left-0 right-0 z-30">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <!--<img src="img/undraw_add_notes_re_ln36.svg" alt="" class="absolute bottom-0 right-1/3 z-40">-->
      <div class="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-c-blue/30 to-blue-500/80 z-20"></div>
      <div class="absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center bg-login z-10"></div>
    </div>
  </div>
</template>
<script>
  import { ClientJS }         from 'clientjs';
  import {mapGetters, mapState, mapActions } from 'vuex'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import auth       from "@/config/user"
  export default {
    name: 'Login',
     data(){
      return{
          EmpID: '',
          password: '',
          ClientIP: '',
          fullPage: true,
          isLoading: false,
          color: "white",
          bgcolor: "#000000",
          version:2.11,
          error: ''
      }
    },
    computed: {
      ...mapState(['session']),
      ...mapGetters(['getAuthObject','getIP']),
      employeeIsActive: function(){
        return auth.currentUser.status.toLowerCase() === 'active';
      },
      employeeIsPredismissal: function(){
        return auth.currentUser.status.toLowerCase() === 'predismissal';
      }
    },
    components: {
      Loading
    },
    methods: {
      ...mapActions(['loginUser', 'getIPFromClient']),
      submit: async function() {  
        this.isLoading = true;
        var client = new ClientJS();
        try {
          var empObject = {
            EmpID:    this.EmpID.toUpperCase(),
            password: this.password,
            ClientIP: this.getIP,
            ActionTriggered: 'Login',
            UserAgent: client.getUserAgent(),
            DeviceOS: client.getOS(),
            DeviceFingerprint: client.getFingerprint(),
          }
          this.loginUser(empObject).then(() => {
            this.isLoading = false;
            this.error = '';
            this.$emit('loadCredentials', true)
          }).catch(error => {
              this.isLoading = false;
              this.error = error.response.data.message;
          });
        } catch (error) {
          this.isLoading = false;
          this.$fire({
            type: 'error',
            title: 'Sorry, wrong username or password',
            showConfirmButton: true
          });
        }
      },
      
      reviewLogin: function(){
        var hasToken = auth.currentUser.token ? true : false;
        // If the user is active, will redirect to dashboard, if not, to the predismissal survey
        if (hasToken && this.employeeIsActive) {
          this.$router.push('/dashboard')
        }
        else if (hasToken && this.employeeIsPredismissal)
        {
          this.$router.push('/employee/predismissal')
        }
      },
    },
    mounted(){
      this.reviewLogin();
      this.getIPFromClient();
      this.version = window.version;
    }
  }
</script>
<style scoped>
  .brand_logo {
    max-height: 55px;
  }
  .bg-login {
    background-image: url('../../../assets/background_2.jpg');
  }
  /* Animation */
  .circles{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }
  .circles li{
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;
      
  }
  .circles li:nth-child(1){
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
  }
  .circles li:nth-child(2){
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
  }
  .circles li:nth-child(3){
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
  }
  .circles li:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
  }
  .circles li:nth-child(5){
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
  }
  .circles li:nth-child(6){
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
  }
  .circles li:nth-child(7){
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
  }
  .circles li:nth-child(8){
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
  }
  .circles li:nth-child(9){
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
  }
  .circles li:nth-child(10){
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
  }
  @keyframes animate {
      0%{
          transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }
      100%{
          transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }
  }
</style>