<template>
  <div class="container-fluid">
    <div class="text-center mb-4">
        <h3>Clock-In an Agent</h3>
    </div>

    <div v-if="agentConfirmed == false" class="row text-center">
        <div class="col mb-3">
            <label>User to Clock-In:</label>
            <input type="text" v-model="employee" @keyup.enter="searchEmployee" placeholder="J.Doe1234" id='filterKeyword'
            class="text-sm text-gray-500 text-left border-b-2 w-full focus:border-blue-500 resize-none p-2"
            />
            <span class="text-xs">Write the employee id and press enter to validate</span>
        </div>
    </div>
    <div v-else-if="agentConfirmed == 'loading'">
        <div class="col text-center">
             <p class="font-italic font-weight-bold">"We're looking for the agent..."</p>
        </div>
    </div>      
    <div v-else>
        <div class="col text-center">
            <label>The Agent <strong>{{this.employee}}</strong> exists.</label>
        </div>
    </div>
    <div v-if="shiftProcess">
        <div v-if="shiftLoading">
            <div class="text-center mb-3">
                <p class="font-italic font-weight-bold">"Just a sec, we're looking for shifts..."</p>
            </div>
        </div>
        <div v-else>
            <div v-if="agentAlreadyInShift" class="col text-center">
                <label>Oops, The Agent <strong>{{employee}}</strong> has shift, you can't do a clock-in.</label>
            </div>
            <div v-else class="col text-center">
                <label>Looks like the agent <strong>{{employee}}</strong> doesn't have a shift. You can clock-in this agent</label>
            </div>
        </div>
    </div>
    <div v-if="is_loading" class="text-center">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-if="agentConfirmed && !shiftLoading && !is_loading">
      <div class="text-center space-x-3">
        <button v-if="showClear" type="button" @click="clearName" class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded">
            Clear
        </button>
        <button v-if="!agentAlreadyInShift" type="button" @click="startShift" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">
            Clock In
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ClientJS }         from 'clientjs';
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "myComponent",
  data() {
    return { 
        employee:   null,
        agentConfirmed: false,
        agentAlreadyInShift:  true,
        shiftLoading:   false,
        shiftProcess:   false,
        showClear:      false,
        is_loading:     false,
    };
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters([
        "infoEmployee",
        "getAgentExist",
        "getIP",
    ]),
  },
  methods: {
    ...mapActions([
        'isValidAgent', 
        'agentHasShift',
        'loadProfileInfo',
        'clockInAgent'
    ]),
    startShift: function(){
        var client = new ClientJS();
        let objectData = {
            EmpID:    this.employee?.toUpperCase(),
            Location: this.infoEmployee.Location,
            ClientIP: this.getIP,
            ActionTriggered: 'ClockIn',
            UserAgent: client.getUserAgent(),
            DeviceOS: client.getOS(),
            DeviceFingerprint: client.getFingerprint(),
        };

        this.is_loading = true;
        this.clockInAgent(objectData).then(()=>{
            this.is_loading = false;
            this.$fire({
                type: "success",
                title: "A shift has started",
                showConfirmButton: false,
                timer: 1300,
            }).then(()=>{
                this.$emit('toggle');
            });
        }).catch((error)=>{
            this.is_loading = false;
            this.$fire({
                type: 'warning',
                title:`Something went wrong`,
                text: `Details: ${error.response.data.message ?? 'Unexpected error'}`,
                showConfirmButton: true,                
            }).then(()=>{
                this.$emit('toggle');
            });
        }); 
    },
    clearName: function(){
        this.agentConfirmed = false;
        this.employee   = null;
        this.agentAlreadyInShift  = true;
        this.shiftLoading   = false;
        this.shiftProcess   = false;
        this.showClear      = false;
    },
    searchEmployee: function(){
        this.agentConfirmed = 'loading';
        this.shiftLoading = true;
        this.is_loading = true;
        this.isValidAgent(this.employee).then(()=>{
            if(this.getAgentExist){
                this.agentConfirmed = true;
                this.shiftProcess = true;
                this.agentHasShift(this.employee).then((result)=>{
                    this.shiftLoading   = false; 
                    this.showClear      = true;
                    this.is_loading = false;
                    this.agentAlreadyInShift = !(result.data.hasShift.length === 0);
                });
            }else{
                this.$fire({
                    type: "error",
                    title: "Agent not found",
                    text: "Looks like the agent does not exists or is not active anymore",
                    showConfirmButton: true,
                })
                this.is_loading = false;
                this.agentConfirmed = false;
            }
        });
    },
  },
  create(){
    this.loadProfileInfo(this.employee)
  }
};
</script>
<style lang="scss">
@import '~vue2-timepicker/dist/VueTimepicker.css';
.display-time {
  border: none !important;
  cursor: pointer !important;
  height: auto !important;
  padding: 0 !important;
  width: 100% !important;
}
</style>