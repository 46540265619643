<template>
    <div class="main max-w-7xl mx-auto">
        <div class="flex flex-col">
            <span class="block text-left text-2xl font-bold text-gray-900 font-sans uppercase mx-auto">
                Schedule for {{dow}}
            </span>
            <span class="block text-left font-semibold text-gray-900 font-sans mx-auto">
                Effective Date: {{effective_date}}
            </span>
        </div>

        <schedule-history @toggle="reload" :key="historyKey"/>

        <schedule-changes @toggle="reload" :key="changesKey" />
        
    </div>
</template>
<script>
import {mapActions} from 'vuex'
import ScheduleHistory from '@/views/schedules/table/scheduleHistory'
import ScheduleChanges from '@/views/schedules/table/scheduleChanges'
export default{
    components:{
        ScheduleHistory,
        ScheduleChanges
    },
    data() {
        return {
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            historyKey: 100,
            changesKey: 0,
        }
    },
    computed:{
        dow(){
            var d = new Date(this.$route.params.date);
            return this.days[d.getDay()];
        },

        effective_date(){
            return this.$route?.params?.date;
        },
        
    },
    methods: {
        ...mapActions([
            'loadMyProfileByEmpId'
        ]),

        reload(){
            this.changesKey++;
            this.historyKey--;
        }
    },
    created(){
        this.loadMyProfileByEmpId(this.$route?.params?.empid).catch(()=>{
            this.$fire({
                title: "We couldn't found this employee",
                text: "We will send you to the previous page, please try again or report to a system administrator",
                showConfirmButton: false,
                timer: 2500
            })
        });
    },
    mounted(){
        let title = 'Schedule Change';
        this.$emit('set-title',title);
    }
}
</script>
<style scoped>
.main {
    width: 100% !important;
    margin-bottom: 5rem !important;
}
</style>