<template>
    <div class="p-3 mx-3 rounded-xl h-full justify-content-center text-center bg-white">
        <div v-if="loading" class="flex justify-content-center text-center h-full">
            <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
        </div>
        <div class="my-2" v-else>
            <label class="px-2">Select an employee to assign this device:</label>
            <div class="w-full">
                <multiselect v-model="employeeId" @input="employeeSeleted" :custom-label="customFormat" :options="employeeList" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Select an employee" label="EmpID" track-by="EmpID" ></multiselect>
            </div>
            <div class="flex flex-col my-4">
                <span class="text-xl font-bold flex text-center justify-content-center">
                    Employee Info 
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                </span>
                <div class="flex text-center justify-content-center p-2 m-auto">
                    <div class="flex flex-col">
                        <span class="flex text-center justify-content-center mr-auto">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Campaign: <span class="border-b-2 border-color-gray-700" :class="{'italic' : !this.employeeId}">{{this.campaign || 'Not found'}}</span>
                        </span>
                        <span class="flex text-center justify-content-center mr-auto">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                            EmpID: <span class="border-b-2 border-color-gray-700" :class="{'italic' : !this.employeeId}">{{this.empid || 'Not found'}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <button class="mx-5 bg-gray-600 hover:bg-gray-700 text-white text-sm mt-2 p-2 px-3 rounded-md" @click="assign">
                    Assign
                </button>
                <span class="text-xs text-gray-600">
                    Select an employee to continue...
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import Multiselect  from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    data() {
        return { 
            loading: true,
            employeeId: null,
            campaign: 'Select an employee first',
            empid: 'Select an employee first',
            name: null,
            lastname: null
        }
    },
    computed:{
        ...mapGetters(['employeeList']),
    },
    props:{
        deviceNumer: String    
    },
    methods: {
        ...mapActions(['checkinDevice', 'fetchAllEmployees']),
        hide(){
            this.$emit('closeAssign', true);
        },
        customFormat({first_name, LastName1}){
            return `${first_name.toUpperCase()} ${LastName1.toUpperCase()}`;
        },
        employeeSeleted:function() {
            this.campaign = this.employeeId?.Campaign;
            this.empid = this.employeeId?.EmpID;
        },
        assign: function() {
            if(this.employeeId === undefined || this.employeeId === null) return;
            let objectData = {
                deviceId: this.deviceNumer,
                empId: this.employeeId?.EmpID
            };
            this.checkinDevice(objectData).then((res) => {
                this.$fire({
                    type: 'success',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    this.hide();
                });
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: "Something went wrong",
                    text: "Please report to a system administrator",
                    showConfirmButton: true,
                })
            });
        }
    },
    created(){
        // this.loading = false;
        this.fetchAllEmployees().then(() => {
            this.loading = false;
            this.$emit('loaded', true)
        })
    }
}
</script>
