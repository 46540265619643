<template>
  <!-- This example requires Tailwind CSS v2.0+ -->


        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Event</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Standard Hours</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Graveyard Hours</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Sunday Hours</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Billable</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Payable</th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Comment</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(currentRow,index) in options" :key="index" :class="{'bg-blue-200' :currentRow.Event === 'Subtotal'}">
                <td class="uppercase p-2">{{currentRow.Event}}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm" :class="{ 'text-transparent' : currentRow.Event === 'Subtotal'}" >{{ !!toggle ? toTime(currentRow.stdhours) : toDecimal(currentRow.stdhours) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm" :class="{ 'text-transparent' : currentRow.Event === 'Subtotal'}" >{{ !!toggle ? toTime(currentRow.grvhours) : toDecimal(currentRow.grvhours) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm" :class="{ 'text-transparent' : currentRow.Event === 'Subtotal'}" >{{ !!toggle ? toTime(currentRow.sundayhours) : toDecimal(currentRow.sundayhours) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm" >{{ !!toggle ? toTime(currentRow.Billable) : toDecimal(currentRow.Billable) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm" >{{ !!toggle ? toTime(currentRow.Payable) : toDecimal(currentRow.Payable) }}</td>
                <td class="px-6 py-4 text-sm uppercase" >{{ currentRow.Comment }}</td>              
            </tr>

            <tr v-if="!options.length || options.length < 0">
                <td colspan="12" class="text-center px-6 py-4">No records found.</td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
  


</template>

<script>
export default {
    props:['options','toggle'],
    computed:{
        isToggled:function(){
            return !!this.toggle;
        }
    },
    methods: {
        toTime: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = new Date(Math.abs(t) * 1000).toISOString().substr(11, 5)
            return sign+time;
        },
        toDecimal: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = Math.abs(t) / 3600;
            return sign+time.toFixed(2);
        },
    }
}
</script>

<style>

</style>