<template>
    <div class="p-10">
        <div class="relative border p-6 rounded-xl flex items-center space-x-3 text-center overflow-hidden mb-10">
            <div v-if="loading" class="absolute bg-white/80 inset-0"></div>
            <div>
                <input type="text" v-model="item.label" placeholder="Label..." class="w-full leading-[3] border-b-2">
            </div>
            <div class="flex-1">
                <input type="text" v-model="item.description" placeholder="Descrition..." class="w-full leading-[3] border-b-2">
            </div>
            <div>
                <select v-model="item.status" class="w-full py-3 border-b-2">
                    <option value="OPEN">OPEN</option>
                    <option value="CLOSED">CLOSED</option>
                </select>
            </div>
            <button @click="newItem" class="bg-green-600 text-white rounded-full px-6 py-2"><i class="bi bi-plus align-middle text-xl leading-none mr-2"></i>Add Item</button>
        </div>
        <table class="text-left w-full">
            <thead class="bg-slate-700 text-slate-800 text-white">
                <tr class="whitespace-nowrap">
                    <th width="20%">Label</th>
                    <th width="40%">Description</th>
                    <th width="20%">Status</th>
                    <th width="10%">Updated By</th>
                    <th width="10%">Updated At</th>
                </tr>
            </thead>
            <tbody class="animate-pulse divide-y" v-if="loading">
                <tr v-for="i in 3" :key="i" class="hover:bg-slate-100">
                    <td v-for="i in 5" :key="i"><div class="h-2 bg-slate-200 rounded my-2"></div></td>
                </tr>
            </tbody>
            <tbody class="divide-y" v-else-if="getItemsEmployee.length==0">
                <tr class="hover:bg-slate-100 hover:cursor-pointer">
                    <td colspan="5" class="text-center italic text-gray-400 py-2">No items</td>
                </tr>
            </tbody>
            <tbody class="divide-y" v-else-if="getItemsEmployee.length>0">
                <tr v-for="item in getItemsEmployee" :key="item.ID">
                    <td class="p-2 whitespace-nowrap">{{ item.Label }}</td>
                    <td class="p-2 leading-none">{{ item.Description }}</td>
                    <td class="p-2 whitespace-nowrap">
                        <select class="w-full py-1 border-b-2" @change="changeStatus($event.target.value,item.ID)">
                            <option value="OPEN" :selected="item.Status=='OPEN' ? 'selected':''">OPEN</option>
                            <option value="CLOSED" :selected="item.Status=='CLOSED' ? 'selected':''">CLOSED</option>
                        </select>
                    </td>
                    <td class="p-2 whitespace-nowrap">{{ item.UpdatedBy }}</td>
                    <td class="p-2 whitespace-nowrap">{{ item.UpdatedAt }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            loading: true,
            item: {
                id:             0,
                label:          null,
                description:    null,
                status:         'OPEN'
            }
        }
    },
    props: {
        employee: {
            default: null,
            type: String,
        }
    },
    computed: {
        ...mapGetters([
            'getItemsEmployee',
        ]),
    },
    methods: {
        ...mapActions([
            'getItemsRequest',
            'requestItem',
            'updateItem'
        ]),
        newItem() {
            this.loading = true;
            let objData = {
                EmpID: this.employee,
                Label: this.item.label,
                Description: this.item.description,
                Status: this.item.status
            }
            this.requestItem(objData).then(() => {
                this.getItemsRequest(this.employee).then(() => {
                    this.loading = false;
                });
                this.item = {
                    id: 0,
                    label: null,
                    description: null,
                    status: 'IN-USE'
                }
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err.message
                })
            })
        },

        changeStatus(status,id) {
            let objData = {
                ID: id,
                Status: status
            }
            this.updateItem(objData).then(() => {
                this.$fire({
                    type: 'success',
                    title: 'Changes Applied',
                    text: 'Status Saved',
                })
                this.getItemsRequest(this.employee).then(() => {
                    this.loading = false;
                });
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err.message
                });
            });
        },
    },
    created() {
        this.getItemsRequest(this.employee).then(() => {
            this.loading = false;
        });
    }
}
</script>