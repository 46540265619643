<template>
  <div class="flex flex-row space-x-6 mb-3">
    <modal v-if="showChangePassword" @close="closeModal" :width="'w-2/5'">
        <template slot="content">
            <change-password-modal :profileInfo="infoEmployee"></change-password-modal>
        </template>  
    </modal>
    <div class="flex-1 space-y-2">
      <employee-info></employee-info>
      <div v-if="checkPermission('GeneralPermissions')">
        <div class="flex flex-row space-x-4 mb-3 mt-4">
          <div class="basis-1/3">
            <div class="relative h-full bg-gradient-to-b from-yellow-300 to-yellow-600 text-white shadow-sm rounded-[10px] px-8 py-4">
              <i class="text-[250%] leading-none opacity-80 bi bi-cone-striped"></i>
              <div class="mt-2">
                <h2 class="text-2xl tracking-tighter leading-none mb-0">Under Construction</h2>
              </div>
              <div class="absolute inset-x-0 bottom-4 h-5 flex justify-between *:bg-white/40 *:w-3 *:h-10 *:-skew-x-12 overflow-hidden">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
          <indicator-component indicatorName="Days for your next birthday" :indicatorValue="daysBDLeft+''" iconName="bi-gift"></indicator-component>
          <indicator-component indicatorName="Days for your next anniversary" :indicatorValue="hireDay+''" iconName="bi-stopwatch"></indicator-component>
        </div>
        <div class="flex flex-row space-x-4">
          <indicator-component indicatorName="Scheduled Time" :indicatorValue="getIndicatorValues.scheduled_time" iconName="bi-clock-history"></indicator-component>
          <indicator-component indicatorName="Worked Time" :indicatorValue="getIndicatorValues.worked_time" iconName="bi-person-workspace"></indicator-component>
          <indicator-component indicatorName="Hour Review" :indicatorValue=" Math.trunc(getIndicatorValues.hours_review) + '%'" iconName="bi-person-check"></indicator-component>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-row space-x-4 mb-3 mt-4">
          <indicator-component indicatorName="Open Ticket(s)" :indicatorValue="getIndicatorValues.open_tickets + ' Ticket(s)'" iconName="bi-ticket-perforated-fill"></indicator-component>
          <indicator-component indicatorName="Tickets with follow-up" :indicatorValue="getIndicatorValues.follow_up_tickets + ' Ticket(s)'" iconName="bi-ticket-detailed-fill"></indicator-component>
          <indicator-component indicatorName="Pendings Courses" :indicatorValue="getIndicatorValues.courses_pending + ' Course(s)'" iconName="bi-mortarboard-fill"></indicator-component>
        </div>
        <div class="flex flex-row space-x-4">
          <indicator-component indicatorName="Days for your next birthday" :indicatorValue="this.daysBDLeft + ' day(s)'" iconName="bi-gift"></indicator-component>
          <indicator-component indicatorName="Pending Incidences" :indicatorValue="getIndicatorValues.incidences_pending + ' Incidence(s)'" iconName="bi-exclamation-diamond"></indicator-component>
          <indicator-component indicatorName="Days for your next anniversary" :indicatorValue="this.hireDay + ' day(s)'" iconName="bi-stopwatch"></indicator-component>
          
        </div>
      </div>
    </div>
    <div class="w-1/3">
      <employee-email-platform class="hidden"></employee-email-platform>
      <surveys class="mt-6" />
      <newsfeed class="mt-6 overflow-hidden"></newsfeed>
    </div>
  </div>
</template>
<script>
import EmployeeInfoComponent                from '@/views/dashboard/components/EmployeeInfoComponent'
import EmailPlatformComponent               from '@/views/dashboard/components/EmailPlatform'
import IndicatorComponent                   from '@/views/dashboard/components/indicators/IndicatorComponent'
//import MessagesCenter                       from '@/views/messages/components/MessagesCenter'
import modal                                from '@/views/components/Modal/modalBase.vue'
import ChangePasswordModal                  from '@/views/components/Modal/changePassword'
import NewsFeed                             from '@/views/newsfeed/index'
import auth                                 from "@/config/user";
import moment                               from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import permissions from '@/config/mixins'
import Surveys               from '@/views/surveys/availableSurveysComponent'

export default {
    mixins:[permissions],
    components: {
        'employee-info': EmployeeInfoComponent,
        'employee-email-platform': EmailPlatformComponent,
        'indicator-component': IndicatorComponent,
        //'messages-center': MessagesCenter,
        'newsfeed': NewsFeed,
        Surveys,
        modal,
        ChangePasswordModal
    },
    data() {
        return {
          daysBDLeft: "0.00",
          hireDay: "0.00",
          showChangePassword: false
        };
    },
    computed:{
        ...mapState(['employees','indicators']),
        ...mapGetters(['infoEmployee', 'getIndicatorValues'])
    },
    methods:{
      ...mapActions([
        'loadMyProfileByEmpId',
        //'fetchComformanceIndicator',
        'fetchScheduledTime',
        'fetchWorkedTime',
        'fetchHoursReview',
        'fetchOpenTickets',
        'fetchFollowUpTickets',
        'fetchCoursesPending',
        'fetchIncidencesPending',
        'setCheckPassword'
      ]),  
      checkPermission(n) {
        return this.hasPermission(n)
      },
      closeModal() {
        this.$fire({
          type: 'error',
          title: 'Ups! Required to change their password every 90 days.',
          showConfirmButton: true
        })
      }
    },
    created() {
        this.setCheckPassword({ employeeID: auth.currentUser.EmpID }).then(response => {
          if(response.data.error) {
            this.showChangePassword = true;
          }
        });

        this.loadMyProfileByEmpId(auth.currentUser.EmpID).then(()=>{
          let employee = {
            'campaign' : this.infoEmployee.Campaign,
            'EmpID' : this.infoEmployee.EmpID
          }

          if(!this.checkPermission('GeneralPermissions')){
            this.fetchOpenTickets(employee);
            this.fetchFollowUpTickets(employee);
            this.fetchCoursesPending(employee);
          }
          //this.fetchComformanceIndicator(employee);
          this.fetchScheduledTime(employee);
          this.fetchWorkedTime(employee);
          this.fetchHoursReview(employee);
          

          var currentDate = moment(new Date(), "YYYY-MM-DD");
          var birthday = moment(new Date(this.infoEmployee.Birthday), "YYYY-MM-DD").set('year', currentDate.year());
          if(birthday.diff(currentDate, 'days') < 0){
            birthday = moment(new Date(this.infoEmployee.Birthday), "YYYY-MM-DD").set('year', currentDate.year() + 1);
            this.daysBDLeft = birthday.diff(currentDate, 'days');
          }else{
            this.daysBDLeft = birthday.diff(currentDate, 'days');
          }

          var hireDate = moment(new Date(this.infoEmployee.HireDate), "YYYY-MM-DD").set('year', currentDate.year());
          if(hireDate.diff(currentDate, 'days') < 0){
            hireDate = moment(new Date(this.infoEmployee.HireDate), "YYYY-MM-DD").set('year', currentDate.year() + 1);
            this.hireDay = hireDate.diff(currentDate, 'days');
          }else{
            this.hireDay = hireDate.diff(currentDate, 'days');
          }          
        });
    },
    mounted(){
        let title = '';
        this.$emit('set-title',title);
    },
}
</script>