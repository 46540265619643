<template>
  <div class="border-l-2 border-gray-200">
      <select required class="bg-transparent hover:text-gray-600 focus:text-gray-600 w-32" v-model="selectedAssignation">  
        <optgroup label="Assignation status">
          <option hidden value="">Assignation Status</option>
          <option value="1">Not Assigned</option>
          <option value="0">Assigned</option> 
        </optgroup>
      </select>
    </div>
</template>

<script>
import Debounce from 'debounce';
export default {
  data(){
      return{ 
        selectedAssignation: ""
      }
  },
  watch:{
    selectedAssignation: Debounce(function(){
        this.$emit('toggle',{
            key: 'isAssigned',
            value: this.selectedAssignation
        });
    },200)     

  }
}
</script>
