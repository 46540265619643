import axios from '@/config/axios'

const state = {
 employeeDetails:[],
 dates_range:[],
 status:{
     employee:false,
     dates:false,
     hours:false,
     events:false,
     eventsByDate:false,
     range:false,
     shift:false,
     payableHours:false,
     scheduleDetails:false,
     conformanceDetails:false,
     buttons:false
 },
 currentWeekHours:0,
 lastWeekHours:0,
 shift:[],
 payableHours:[],
 events:[],
 eventsByDate:[],
 scheduleDetails:[],
 conformanceDetails:[],
 previousWeek:false,
 approvalButtons:[]
};

const getters = {
    getEmployeeDetails: (state) => state.employeeDetails,
    getHoursManagmentStatus:(state) => state.status,
    getCWHours:(state) => state.currentWeekHours,
    getLWHours:(state) => state.lastWeekHours,
    getDateRange: (state) => state.dates_range,
    getShift: (state) => state.shift,
    getWeeklyEvents: (state) => state.events,
    getNonPayableEventsByDate: (state) => state.eventsByDate,
    getPayableHours: (state) => state.payableHours,
    getScheduleDetails: (state) => state.scheduleDetails,
    getConformanceDetails: (state) => state.conformanceDetails,
    getApprovalButtons:(state) => state.approvalButtons,
    getPreviousWeekFlag:(state) => state.previousWeek
}
  

const actions = {
    async fetchEmployeeDetails({commit}){
        let response;
        commit('setHoursManagmentStatus','employee');
        try{
            const res = await axios.get('api/v3/hours-management/my-hours');
            commit('setEmployeeDetails', res.data.employeeDetails)
            response = res;
        }catch(error){
            response = error;
        }
        commit('unsetHoursManagmentStatus','employee');
        
        return response;
    },

    async fetchCWPayableHours({commit}){
        commit('setHoursManagmentStatus','hours');
        let response;
        try{
            const res = await axios.get(`api/v3/hours-management/weekly-hours`);
            commit('setCWHours', res.data.hours)
            response = res;
        }catch(error){
            response = error;
        }

        commit('unsetHoursManagmentStatus','hours');
        return response;
        
    },

    async fetchLWPayableHours({commit}){
        let data = {previousWeek : true},response;
         commit('setHoursManagmentStatus','hours');
        try{
            const res = await axios.get(`api/v3/hours-management/weekly-hours`,{params:data});
            commit('setLWHours', res.data.hours)
            response = res;
        }catch(error){
            response = error;
        }
        commit('unsetHoursManagmentStatus','hours');
        return response;
    },

    async fetchWeeklyDates({commit},previousWeek = false){
        let data = {},response;
        if(previousWeek)  data.previousWeek = true;

        commit('setHoursManagmentStatus','dates');
        try{
            const res = await axios.get(`api/v3/hours-management/weekly-dates`,{params:data});
            commit('setDateRange', res.data.range)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','dates');


        return response;
    },

    async fetchWeeklyShift({commit},previousWeek = false){
        commit('setHoursManagmentStatus','shift');
        let data = {},response;
        if(previousWeek)  data.previousWeek = true;

        try{
            const res = await axios.get(`api/v3/hours-management/weekly-resume`,{params:data});
            commit('setShift', res.data.resume)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','shift');


        return response;
    },
    async fetchWeeklyNonPayableEvents({commit},previousWeek = false){
        commit('setHoursManagmentStatus','events');
        let data = {},response;
        if(previousWeek)  data.previousWeek = true;
        try{
            const res = await axios.get(`api/v3/hours-management/weekly-events-by-name`,{params:data});
            commit('setNonPayableEvents', res.data.events)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','events');


        return response;
    },

    async fetchWeeklyNonPayableEventsByDate({commit},previousWeek = false){
        let data = {},response;
        if(previousWeek)  data.previousWeek = true;
        commit('setHoursManagmentStatus','eventsByDate');
       
        try{
            const res = await axios.get(`api/v3/hours-management/weekly-events-by-date`,{params:data});
            commit('setNonPayableEventsByDate', res.data.events)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','eventsByDate');


        return response;
    },

    async fetchWeeklyPayableHours({commit},previousWeek = false){
        let data = { } ;
        if(previousWeek) data.previousWeek = true;

        commit('setHoursManagmentStatus','payableHours');
        let response;
        try{
            const res = await axios.get(`api/v3/hours-management/payable-hours`,{params:data});
            commit('setPayableHours', res.data.resume)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','payableHours');


        return response;
    },

    async fetchScheduleDetails({commit},previousWeek = false){
        
       let data = {},response;
     

       if(previousWeek)  data.previousWeek = true;

        commit('setHoursManagmentStatus','scheduleDetails');
       
        try{
            const res = await axios.get(`api/v3/hours-management/schedule-details`,{params:data});
            commit('setScheduleDetails', res.data.schedule)
            response = res;
        }catch(error){
            response = error;
        }
        
        commit('unsetHoursManagmentStatus','scheduleDetails');


        return response;
    },

    async fetchConformanceDetails({commit},previousWeek = false){
        
        let data = {},response;
      
 
        if(previousWeek)  data.previousWeek = true;
 
         commit('setHoursManagmentStatus','conformanceDetails');
        
         try{
             const res = await axios.get(`api/v3/hours-management/weekly-conformance`,{params:data});
             commit('setConformanceDetails', res.data.conformance)
             response = res;
         }catch(error){
             response = error;
         }
         
         commit('unsetHoursManagmentStatus','conformanceDetails');
 
 
         return response;
     },

     async fetchApprovalButtons({commit},previousWeek = false){
        
        let data = {},response;
      
 
        if(previousWeek)  data.previousWeek = true;
 
         commit('setHoursManagmentStatus','buttons');
        
         try{
             const res = await axios.get(`api/v3/hours-management/approval-hours`,{params:data});
             commit('setApprovalButtons', res.data.hours)
             response = res;
         }catch(error){
             response = error;
         }
         
         commit('unsetHoursManagmentStatus','buttons');
 
 
         return response;
     },

     async approveDate({commit},day){
         commit('setHoursManagmentStatus','buttons');
        
         try{
             const res = await axios.post(`api/v3/hours-management/approve-day/${day}`);
             commit('unsetHoursManagmentStatus','buttons');
            return res;
            
         }catch(error){
            commit('unsetHoursManagmentStatus','buttons');
            return error;
         }
     },
     async notifyHoursError({commit},form){
        commit('setHoursManagmentStatus','notify');
       
        try{
            const res = await axios.post(`api/v3/hours-management/notify-exception`,form);
            commit('unsetHoursManagmentStatus','notify');
           return res;
           
        }catch(error){
           commit('unsetHoursManagmentStatus','notify');
           return error;
        }
    },
}

const mutations = {
  setEmployeeDetails: (state,details) =>(state.employeeDetails = details),
  setHoursManagmentStatus:(state, event,status = true) => (state.status[event] = status),
  unsetHoursManagmentStatus:(state, event,status = false) => (state.status[event] = status),
  setCWHours:(state,hours) => (state.currentWeekHours = hours),
  setLWHours:(state,hours) => (state.lastWeekHours = hours),
  setDateRange:(state,range) =>(state.dates_range = range),
  setPayableHours:(state,hours) => (state.payableHours = hours),
  setNonPayableEvents:(state,events) => (state.events = events),
  setNonPayableEventsByDate:(state,events) => (state.eventsByDate = events),
  setShift:(state,shift) => (state.shift = shift),
  setScheduleDetails:(state,schedule) => (state.scheduleDetails = schedule),
  setConformanceDetails:(state,conformance) => (state.conformanceDetails = conformance),
  setApprovalButtons:(state,hours) => (state.approvalButtons = hours),
  setPreviousWeekFlag:(state, status) => (state.previousWeek = status)

};

export default {
    state,
    getters,
    actions,
    mutations
};