<template>
<div class="overflow-y-scroll max-h-[300px]">
    <div v-if="hasRecords" class="relative before:absolute before:inset-0 before:z-10 before:transition 
    after:absolute after:inset-x-0.5 after:bottom-0.5 after:z-10 after:w-[calc(100%-4px)] after:h-24 
    after:bg-gradient-to-t after:from-white after:to-transparent">
        <div class="relative overflow-hidden w-full h-full rounded-xl">
            <div class="p-6 flex flex-col justify-between text-center rounded-xl">
                <ul class="flex flex-col text-left space-y-0.5">
                    <li v-for="(row, index) in history" :key="index" class="relative flex gap-x-4 pb-7 overflow-hidden">
                        <div class="mt-0.5 relative h-full">
                            <div class="absolute top-7 bottom-0 left-2.5 w-px h-96 -ml-px border-r border-dashed border-gray-200"></div>
                            <div v-if="row.status === 'pending'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-question-circle-fill text-blue-500" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                            </div>
                            <div v-if="row.status === 'approved'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle-fill text-green-500" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                </svg>
                            </div>
                            <div v-if="row.status === 'declined'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill text-orange-500" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                        </div>
                        <p class="h-full py-1.5 px-2.5 rounded-full text-xs font-medium text-gray-400 bg-white border-1 border-gray-200 shadow-sm">
                            <span class="font-semibold text-gray-600">{{ row.requested_by }}</span> 
                            requested a change at
                            <span class="font-semibold text-gray-600">{{ row.requested_at }}</span> 
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-else>
        <span class="text-gray-400 italic">
            No requests registered yet...
        </span>
    </div>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    data () {
        return {
            history: [
                {requested_at: '2023-05-16 17:24:45', requested_by: 'TEST', status: 'rejected'},
            ]
        }
    },
    computed: {
        ...mapGetters([
            'infoEmployee',
            '_employee_file_requests'
        ]),
        hasRecords () {
            return this.history && this.history.length > 0;
        }
    },
    methods: {
        ...mapActions([
            'fetchEmployeeDocsRequests'
        ]),
    },
    async mounted () {
        let obj = {
            employee_id: this.infoEmployee.RecID,
            document_id: this.$route.params.doc_type
        };
        await this.fetchEmployeeDocsRequests(obj).then(() => {
            this.history = this._employee_file_requests;
        });
    },
}
</script>
<style scoped>
/* For WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
}

table {
  box-shadow: 0px 8px 10px -10px rgba(0, 0, 0, 0.5) inset;
  z-index: 15;
}
</style>