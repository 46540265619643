<template>
  <div class="bg-white shadow-md rounded-xl shadow-md relative w-72 overflow-hidden px-3 py-3  pt-4 mx-auto">
      <div class="" v-if="fileIsLoaded">
        <div class="flex flex-row align-start justify-content-center mb-2">
          <span class="text-gray-700 ml-1 text-semibold inline">
            {{file.name}}
          </span>
          <span v-if="validFile" class="text-green-500 inline">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
          </span>
        </div>      
         <p class="text-gray-700 mt-2 font-semibold" v-if="validFile">
          The file seems to be valid. Press "save file" to upload it.
        </p>
        <p v-else class="text-gray-700 mt-2 font-semibold">
          File format or size is invalid. Only CSV's
          <span class="material-icons text-red-400">
            error
          </span>
        </p>
        <button class="bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" @click="upload" v-if="validFile">
            Save file
        </button>
        <button class="bg-gray-700 text-white p-2 rounded-md shadow-md mt-2" @click="reset" v-else>
          Upload new file
        </button>
      </div>
      <div v-else>
        <h4 class="text-gray-700 ml-2 font-semibold text-md">Upload your file</h4>
        <dropzone v-on:file-dragged="setFile" v-if="!fileIsLoaded"></dropzone>
        <p class="my-3 text-gray-400 text-center"> 
          <span class="text-gray-700 text-sm font-semibold"> Extensions allowed:</span>
          <span class="text-sm text-gray-500"> CSV</span>
        </p>
      </div>
  </div>
</template>
<script>
import Dropzone from "@/views/Utilities/Dropzone";
import {CSV_FILE_TYPES} from '@/store/actions/file';
import {mapGetters,mapActions} from 'vuex';
export default {
name: "DragAndDrop",
  components: {
    Dropzone,
  },
  data(){
    return {
      file:null,
      maxSize:6000000,
      convertRate:1048576
    }
  },
  methods:{
    ...mapActions(['uploadCSV']),
    setFile:function(file){
      this.file = file;
    },
    reset:function(){
      this.$emit('clear');
    },
    upload:function(){
        this.uploadCSV(this.file).then((res)=>{
          console.log(res);
        });
    }
    
   
  },
  computed:{
    fileIsLoaded:function(){
      return this.file !== null && this.file instanceof File;
    },
    validType:function(){
      return !!CSV_FILE_TYPES.includes(this.file.type);
    },
    validSize:function(){
      return this.file.size <= this.maxSize;
    },
    validFile:function(){
      return this.fileIsLoaded && this.validSize && this.validType;
    },
    isPdf:function(){
      return this.file.type ==='application/pdf';
    },
     ...mapGetters(['getFileName'])
  },
}
</script>

<style scoped>

</style>