<template>
        <!--#Data-placeholder-->
        <hours-table v-if="getHoursManagmentStatus.conformanceDetails">
            <tr>
                <td>
                    <div class="text-gray-700 animate-pulse py-3">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
                <td>
                    <div class="text-gray-700 animate-pulse">
                        <span class="bg-gray-100 py-1 px-7 w-full"></span>
                    </div>
                </td>
            </tr>
        </hours-table>

        <!--#Data-display-->
        <section v-else id="weekly-view" class="flex min-w-full overflow-auto">
            <div name="day_time" class="sticky left-0 z-20 bg-white pl-3 pr-2 mr-3 text-center">
                <div class="px-2 py-3 border-[1px] border-white">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">&nbsp;</span>
                        <div class="text-amber-600 uppercase">&nbsp;</div>
                    </div>
                </div>
                <div class="text-gray-700 column flex" v-for="(hour, index) in sched_base" :key="index">
                    <div class="text-xs font-semibold align-center whitespace-nowrap m-auto">{{hour}}</div>
                </div>
                <div class="absolute inset-y-0 left-full w-4 bg-gradient-to-r from-white to-transparent"></div>
            </div>

            <!-- sunday column -->
            <div name="sunday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[0]}}</span>
                        <div class="text-red-600 uppercase">Sunday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <!-- using schedule data -->
                    <div name="sunday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'sunday_schedule'+index" class="z-10">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="sunday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[0],'schedule_start')=='' || isZero(getDateRange[0],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-red-700 rounded-[10px] shadow-md shadow-red-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[0],'schedule_start')=='' || isZero(getDateRange[0],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[0],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[0],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[0],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[0],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- using clocker values -->
                    <div name="sunday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'sunday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="sunday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[0],'clock_in')=='' || isZero(getDateRange[0],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-red-500 rounded-[10px] shadow-md shadow-red-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[0],'clock_in')=='' || isZero(getDateRange[0],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[0],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[0],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[0],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-red-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[0])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[0],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[0],'out_attendance')}}</div>
                    </div>
                </div>
            </div>
            
            <!-- monday column -->
            <div name="monday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[1]}}</span>
                        <div class="text-blue-600 uppercase">Monday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="monday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'monday_schedule'+index" class="z-10">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="monday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[1],'schedule_start')==''|| isZero(getDateRange[1],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-blue-700 rounded-[10px] shadow-md shadow-blue-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[1],'schedule_start')==''|| isZero(getDateRange[1],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[1],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[1],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[1],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[1],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="monday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'monday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="monday_clocker_coloring"  :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[1],'clock_in')=='' || isZero(getDateRange[1],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-blue-500 rounded-[10px] shadow-md shadow-blue-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[1],'clock_in')=='' || isZero(getDateRange[1],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[1],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[1],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[1],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-blue-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[1])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[1],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[1],'out_attendance')}}</div>
                    </div>
                </div>
            </div>

            <!-- tuesday column -->
            <div name="tuesday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[2]}}</span>
                        <div class="text-green-600 uppercase">Tuesday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="tuesday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'tuesday_schedule'+index" class="z-10">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="tuesday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[2],'schedule_start')==''|| isZero(getDateRange[2],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-green-700 rounded-[10px] shadow-md shadow-green-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[2],'schedule_start')==''|| isZero(getDateRange[2],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[2],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[2],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[2],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[2],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="tuesday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'tuesday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="tuesday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[2],'clock_in')=='' || isZero(getDateRange[2],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-green-500 rounded-[10px] shadow-md shadow-green-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[2],'clock_in')=='' || isZero(getDateRange[2],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[2],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[2],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[2],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-green-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[2])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[2],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[2],'out_attendance')}}</div>
                    </div>
                </div>
            </div>

            <!-- wednesday column -->
            <div name="wednesday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[3]}}</span>
                        <div class="text-amber-600 uppercase">Wednesday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="wednesday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'wednesday_schedule'+index">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="wednesday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[3],'schedule_start')==''|| isZero(getDateRange[3],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-amber-700 rounded-[10px] shadow-md shadow-amber-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[3],'schedule_start')==''|| isZero(getDateRange[3],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[3],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[3],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[3],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[3],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="wednesday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'wednesday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="wednesday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[3],'clock_in')=='' || isZero(getDateRange[3],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-amber-500 rounded-[10px] shadow-md shadow-amber-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[3],'clock_in')=='' || isZero(getDateRange[3],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[3],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[3],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[3],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-amber-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[3])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[3],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[3],'out_attendance')}}</div>
                    </div>
                </div>
            </div>

            <!-- thursday column -->
            <div name="thursday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[4]}}</span>
                        <div class="text-violet-600 uppercase">Thursday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="thursday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'thursday_schedule'+index">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="thursday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[4],'schedule_start')==''|| isZero(getDateRange[4],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-violet-700 rounded-[10px] shadow-md shadow-violet-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[4],'schedule_start')==''|| isZero(getDateRange[4],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[4],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[4],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[4],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[4],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="thursday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'thursday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="thursday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[4],'clock_in')=='' || isZero(getDateRange[4],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-violet-500 rounded-[10px] shadow-md shadow-violet-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[4],'clock_in')=='' || isZero(getDateRange[4],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[4],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[4],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[4],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-violet-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[4])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[4],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[4],'out_attendance')}}</div>
                    </div>
                </div>
            </div>

            <!-- friday column -->
            <div name="friday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[5]}}</span>
                        <div class="text-pink-600 uppercase">Friday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="friday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'friday_schedule'+index">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="friday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[5],'schedule_start')==''|| isZero(getDateRange[5],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-pink-700 rounded-[10px] shadow-md shadow-pink-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[5],'schedule_start')==''|| isZero(getDateRange[5],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[5],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[5],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[5],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[5],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="friday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'friday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="friday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[5],'clock_in')=='' || isZero(getDateRange[5],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-pink-500 rounded-[10px] shadow-md shadow-pink-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[5],'clock_in')=='' || isZero(getDateRange[5],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[5],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[5],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[5],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-pink-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[5])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[5],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[5],'out_attendance')}}</div>
                    </div>
                </div>
            </div>

            <!-- saturday columns -->
            <div name="saturday_schedule_clocker">
                <!-- header -->
                <div class="py-1">
                    <div class="text-center leading-none">
                        <span class="font-semibold text-sm whitespace-nowrap">{{getDateRange[6]}}</span>
                        <div class="text-cyan-600 uppercase">Saturday</div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="header text-xs font-semibold uppercase">Scheduled Shift</div>
                        <div class="header text-xs font-semibold uppercase">Shift</div>
                    </div>
                </div>
                <!-- body -->
                <div class="flex flex-row border-[1px] border-white">
                    <div name="saturday_schedule" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'saturday_schedule'+index">
                            <div class="slot z-0 bg-gray-100 border-[1px] border-white"></div>
                        </div>
                        <div id="saturday_sched_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[6],'schedule_start')==''|| isZero(getDateRange[6],'schedule_start', 'schedule_stop') ? '!inset-0 !bg-transparent':'inset-x-0 bg-cyan-700 rounded-[10px] shadow-md shadow-cyan-700/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[6],'schedule_start')==''|| isZero(getDateRange[6],'schedule_start', 'schedule_stop') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[6],'schedule_start')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else-if="!(isZero(getDateRange[6],'schedule_start', 'schedule_stop'))" class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from 
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[6],'schedule_start')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[6],'schedule_stop')}}
                                        </span>
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        DAY OFF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="saturday_clocker" class="relative header">
                        <div v-for="(hour, index) in sched_base" :key="'saturday_clocker'+index">
                            <div class="slot z-0 bg-gray-50 border-[1px] border-white"></div>
                        </div>
                        <div id="saturday_clocker_coloring" :class="'absolute coloring z-10 '+(filterScheduleTime(getDateRange[6],'clock_in')=='' || isZero(getDateRange[6],'clock_in', 'clock_out') ? '!inset-0 !bg-transparent':'inset-x-0 bg-cyan-500 rounded-[10px] shadow-md shadow-cyan-500/50 m-1')">
                            <div class="flex h-full">
                                <div :class="'flex flex-col '+(filterScheduleTime(getDateRange[6],'clock_in')=='' || isZero(getDateRange[6],'clock_in', 'clock_out') ? 'bg-white rounded-[10px] shadow-md shadow-slate-300/50 py-3':'text-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]')+' px-3 m-auto'">
                                    <span v-if="filterScheduleTime(getDateRange[6],'clock_in')==''" class="text-sm mx-auto">
                                        No Record
                                    </span>
                                    <span v-else class="text-xs capitalize leading-[0.8] mx-auto flex flex-col mt-1">
                                        from
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[6],'clock_in')}}
                                        </span>
                                        to
                                        <span class="text-xs mx-auto">
                                            {{filterScheduleTime(getDateRange[6],'clock_out')}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="m-2">
                    <div class="inline-block rounded-[10px] bg-cyan-500 text-white px-4 py-3">
                        <div class="text-md whitespace-nowrap leading-none">{{filterConformance(getDateRange[6])}} %</div>
                        <div class="text-xs font-semibold uppercase">Conformance</div>
                        <div class="text-xs font-medium uppercase">Starting: {{filterSchedule(getDateRange[6],'in_attendance')}}</div>
                        <div class="text-xs font-medium uppercase">Leaving: {{filterSchedule(getDateRange[6],'out_attendance')}}</div>
                    </div>
                </div>
            </div>
        </section>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import HoursTable from '../components/HoursReviewTable'
export default {
    data(){
        return{
            sched_core: [
                { sched: 'sunday_sched_coloring', clocker: 'sunday_clocker_coloring'},
                { sched: 'monday_sched_coloring', clocker: 'monday_clocker_coloring'},
                { sched: 'tuesday_sched_coloring', clocker: 'tuesday_clocker_coloring'},
                { sched: 'wednesday_sched_coloring', clocker: 'wednesday_clocker_coloring'},
                { sched: 'thursday_sched_coloring', clocker: 'thursday_clocker_coloring'},
                { sched: 'friday_sched_coloring', clocker: 'friday_clocker_coloring'},
                { sched: 'saturday_sched_coloring', clocker: 'saturday_clocker_coloring'},
            ],
            sched_base: [],
        }
    },
    components:{
        HoursTable
    },
      props:{
        loadPrevious:{
            default:false,
            type:Boolean,
        }
    },
     computed:{
        ...mapGetters([
            'getConformanceDetails',
            'getHoursManagmentStatus',
            'getDateRange',
            'getScheduleDetails'
        ])
    },
    methods:{
        ...mapActions(['fetchConformanceDetails', 'fetchScheduleDetails']),

        filterConformance:function(date){
            const formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,      
                maximumFractionDigits: 2,
            });
            let res = this.getConformanceDetails.find(element => element.date == date);
            
            return (formatter.format(res?.Conformance) * 100).toFixed(2).replace(/[.,]00$/, "") ?? 'No record';
        },

        isZero(date, indexOne, indexTwo){
            let start = this.filterScheduleTime(date, indexOne);
            let stop = this.filterScheduleTime(date, indexTwo);
            return start === '00:00' && stop === '00:00';
        },

        filterScheduleTime:function(date, index){
            let res;
            try{
                res = this.getScheduleDetails[date][0][index] ?? '';
            }catch(e){
                res = '';
            }
            return res?.substring(0,5);
        },

        filterSchedule:function(date, index){
            let res;
            try{
                res = this.getScheduleDetails[date][0][index] ?? '';
            }catch(e){
                res = '';
            }
            return res;
        },

        timeToPixels: function(t){
            let parts = t.split(':');
            let seconds = (parts[0] * 60 * 60) + (parts[1] * 60)
            if (seconds === null || seconds === 'null') seconds = 0;
            return Math.round(seconds * 20 / 3600);
        },

        setCSSValues: function(){
            // Mapping the pixels for the colored boxes
            this.getDateRange.forEach((item, index) => {
                let id = this.sched_core[index];
                let array = {
                    schedule_start: '00:00:00',
                    schedule_stop: '00:00:00',
                    clock_in: '00:00:00',
                    clock_out: '00:00:00'
                }
                let object = [];
                object.push(array);
                let time = this.getScheduleDetails[item] ?? object;

                let sch_start   = this.timeToPixels(time[0].schedule_start ?? '00:00:00');
                let sch_stop    = this.timeToPixels(time[0].schedule_stop ?? '00:00:00');
                let clock_start = this.timeToPixels(time[0].clock_in ?? '00:00:00');
                let clock_stop  = this.timeToPixels(time[0].clock_out ?? '00:00:00');

                if(!(sch_start === 0 && sch_stop === 0)) { 
                    document.getElementById(id.sched).style.top = `${sch_start}px`;
                    document.getElementById(id.sched).style.bottom = `${480 - sch_stop}px`;
                }
                if(!(clock_start === 0 && clock_stop === 0)) { 
                    document.getElementById(id.clocker).style.top = `${clock_start}px`;
                    document.getElementById(id.clocker).style.bottom = `${480 - clock_stop}px`;
                }
            });
        }
    },
   
    created(){
        for(let hour = 0; hour < 24; hour++){
            this.sched_base.push(hour+":00 - "+(hour+1)+":00")
        }
        this.fetchConformanceDetails(this.loadPrevious);
        this.fetchScheduleDetails(this.loadPrevious).then(()=>{
            setTimeout(this.setCSSValues, 1500);
        });
    }
}
</script>
<style>
    .column {
        height: 20px !important;
    }
    .slot {
        height: 20px !important;
    }
    .header {
        width: 150px !important;
    }
</style>